<div class="form-modal">
	<mat-dialog-content>
		<a
			tabindex="0"
			(click)="onClose()"
			(keypress)="onClose()"
			class="close-modal"
			aria-label="Close organization edit form"
		>
			<img src="./assets/img/icons-svg/close-icon.svg" />
		</a>
		<div class="img-wrapper">
			<div class="clearfix"></div>
			<div class="title-wrapper">
				<h1>Clone Organization</h1>
			</div>
		</div>
		<popup-loader *ngIf="!isLoaded"></popup-loader>
		<div class="form-wrapper-80" *ngIf="isLoaded">
			<mat-form-field>
				<label for="parentId" translate>Source Org</label>
				<div>
					<mat-select
						[(ngModel)]="sourceOrgID"
						id="parentId"
						#sourceID="ngModel"
						required
					>
						<mat-option [value]="null">None</mat-option>
						<mat-option
							*ngFor="let org of organizations"
							[value]="org.id"
						>
							{{ org.name }}
						</mat-option>
					</mat-select>
				</div>
				<span
					class="text-danger"
					*ngIf="sourceID.touched && sourceID.errors?.required"
				>
					Please select a source organization
				</span>
			</mat-form-field>
		
			<div class="fixed-footer">
				<a
					tabindex="0"
					class="btn-primary btn-bold btn"
					(click)="doSave()"
					(keypress)="doSave()"
					>Clone</a
				>
			</div>
		</div>
	</mat-dialog-content>
</div>
