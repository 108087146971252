import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationAdminEditComponent } from 'app/components/admin/organization-admin-edit/organization-admin-edit.component';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';

@Component({
	selector: 'dashboard-relationship-manager',
	templateUrl: './dashboard-relationship-manager.component.html',
	styleUrls: ['./dashboard-relationship-manager.component.scss']
})
export class DashboardRelationshipManagerComponent implements OnInit {
	@Input() type;
	organizations: Organization[];
	allOrganizations: Organization[];
	activeOrganizations = [];
	allActiveOrganizations = [];
	isLoaded: number = 0;
	settings: any;
	popup: any;
	organizationsAuth: Array<object>;
	organizationResources: Array<object>;
	apiPath: any;
	constructor(
		private api: ApiService,
		private userService: UserService,
		private translate: TranslateService,
		private modalService: ModalService,
		private log: LogService
	) { }

	ngOnInit(): void {
		this.translate.stream('error').subscribe((res: any) => {
			this.popup = res;
		});
		this.log.event(AnalyticEvent.event.dashboardLoaded);
		this.loadMore();
		this.getAllOrganization();
	}

	onEdit(id) {
		this.modalService.setCloseOnClickAway(false);
		const modal = this.modalService.showComponent(OrganizationAdminEditComponent, id, 'fullscreen-dialog');
		modal.beforeClosed().subscribe((data: any) => {
			if (data) {
				const actIndex = this.activeOrganizations.findIndex((org: Organization) => org.id === id);
				if (actIndex !== -1) {
					this.activeOrganizations[actIndex] = { ...this.activeOrganizations[actIndex], ...data };
				}
			}
		})
	}

	loadMore() {
		this.api.get('admin/lastcreatedupdatedorgs').subscribe(
			(results: any) => {
				this.organizations = Organization.initializeArray(results);
				this.activeOrganizations = results;
				this.isLoaded++;

			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);

			}
		);
	}

	getAllOrganization() {
		this.api.get('getrmuserorgs').subscribe(
			(results: any) => {
				this.allOrganizations = Organization.initializeArray(results.data);
				this.allActiveOrganizations = this.allOrganizations.filter(org => org.active);
				this.isLoaded++;


			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isLoaded++;

			}
		);
	}
}
