
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../lib/api.service';
import { ModalService } from '../../../lib/modal.service';

import { UtilityService } from 'app/lib/utility.service';

@Component({
  selector: 'app-add-resources',
  templateUrl: './add-resources.component.html',
  styleUrls: ['./add-resources.component.scss']
})

export class AddResourcesComponent implements OnInit {
  resourceForm: FormGroup;
  isloaded = false;
  resources: any;
  urlPattern: String;
  isSubmitted = false;
  resourcesList = [];

  constructor(
    public dialogRef: MatDialogRef<AddResourcesComponent>,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private modalService: ModalService,
    private utility: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.resources = data.data;
    this.urlPattern = this.utility.urlValidationPattern();
    this.constructForm();
  }

  ngOnInit() {
  }

  /**
   * Construct form
   */
  constructForm() {
    this.resourceForm = this.formBuilder.group({
      id: [this.resources.id || null],
      title: [this.resources.title || "", [Validators.required]],
      contact: [this.resources.contact || "", Validators.pattern(this.utility.phoneValidationPattern())], // phone number
      alternateContact: [this.resources.alternateContact || "", Validators.pattern(this.utility.emailValidationPattern())],
      websiteTitle: [this.resources.websiteTitle || ""],
      website: [this.resources.website || "", Validators.pattern(this.utility.urlValidationPattern())],
      address: [this.resources.address || ""],
      internal: [this.resources.internal || ""],
      description: [this.resources.description || ""],
      active: [this.resources.active === 0 ? 0 : 1],
    }, { validators: this.utility.atLeastOneValidator(['contact', 'website', 'address', 'alternateContact']) });
  }

  // Save the form data
  doSave() {
    if (this.resourceForm.invalid) {
      return;
    }
    this.isSubmitted = true;
    const formData = this.resourceForm.value;
    this.resourceForm.value.active = this.resourceForm.value.active ? 1 : 0
    formData.resourcesetID = this.resources.resourceSetId;
    formData.organization = this.resources.organizationId;
    this.resourcesList.push(formData);
    if (this.resourceForm.valid) {
      this.dialogRef.close(this.resourcesList);
    }
  }

  // Save the form data and add another resource
  doSaveAndAdd() {
    if (this.resourceForm.invalid) {
      return;
    }
    this.isSubmitted = true;
    const formData = this.resourceForm.value;
    this.resourceForm.value.active = this.resourceForm.value.active ? 1 : 0
    formData.resourcesetID = this.resources.resourceSetId;
    formData.organization = this.resources.organizationId;
    this.resourcesList.push(formData);
    if (this.resourceForm.valid) {
      this.resourceForm.reset();
      this.isSubmitted = false;
      // reset form value
      this.resourceForm.setValue(
        {
          id: null,
          title: "",
          contact: "",
          alternateContact: "",
          websiteTitle: "",
          website: "",
          address: "",
          internal: "",
          description: "",
          active: 1
        }
      );
    };
  }

  // Close the Dialog
  onClose() {
    if (this.resourceForm.dirty || this.resourcesList.length > 0) {
      this.modalService
        .showConfirmation(
          "Discard Changes?",
          "Are you sure you want to close it without saving the changes?"
        )
        .afterClosed()
        .subscribe((result) => {
          if (!result) {
            return;
          }
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
