import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-scale',
  templateUrl: './number-scale.component.html',
  styleUrls: ['./number-scale.component.scss']
})
export class NumberScaleComponent implements OnInit {
  selectedValue :any;
  @Input() scaleIndex;
  @Input() scaleOptionValues;
  @Output() onCompleted = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  
  }

  selectButtonEvent(event,selectedOptions){
    selectedOptions['isSelected'] = 'true';
   this.selectedValue = event.srcElement.id;
    $('#btn-group_'+this.scaleIndex).find('*').each(function(e){
      $(this).removeClass('btn-scale');
    })
    document.getElementById(this.selectedValue).classList.add('btn-scale');
    this.onCompleted.emit({
        index : this.scaleIndex ,
        options:selectedOptions
      });
  }

}
