<div id="dashboard" class="page-section">
    <div class="page-section-header">
        <h2 translate>Dashboard</h2>
    </div>
    <div class="page-section">
		<div class="page-section-header">
			<h4 translate>editedOrgListing.title</h4>
		</div>
        <dashboard-relationship-manager [type]="'updatedOrganization'"></dashboard-relationship-manager>

	</div>

    <div class="page-section">
		<div class="page-section-header">
			<h4 translate>organizationListing.rmTitle</h4>
		</div>
        <dashboard-relationship-manager [type]="'allOrganization'"></dashboard-relationship-manager>

	</div>
	</div>
