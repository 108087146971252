import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { Moodcheck } from '../../../models/moodcheck';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { DailyMoodcheckViewComponent } from '../../../components/daily-moodcheck-view/daily-moodcheck-view.component';
import { MonthlyMoodcheckViewComponent } from '../../../components/monthly-moodcheck-view/monthly-moodcheck-view.component';
import { WeeklyMoodcheckViewComponent } from '../../../components/weekly-moodcheck-view/weekly-moodcheck-view.component';
import {
    MatDatepicker,
    MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { OverallViewMoodcheckComponent } from '../../../components/overall-view-moodcheck/overall-view-moodcheck.component';
import { DateService } from 'app/lib/url.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/lib/storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MoodcheckService } from 'app/lib/moodcheck.service';
import { ProfessionalService } from '../professional.service';
import { UtilityService } from 'app/lib/utility.service';
import { AssignEventList } from 'app/models/event';

@Component({
    selector: 'app-moodcheck-view',
    templateUrl: './moodcheck-view.component.html',
    styleUrls: ['./moodcheck-view.component.scss'],
    providers: [DatePipe],
})
export class MoodcheckViewComponent implements OnInit {
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    title: string;
    back: string;
    selectedMode: any;
    selectedMood: any;
    selectedDate: Date = new Date();
    changeDate: any;
    currentDate: Date;
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    moodchecks: Moodcheck[];
    moodcheckData: Moodcheck[] = [];
    badMoodStrings: any;
    goodMoodStrings: any;
    isEmpty: boolean;
    today: Date = new Date();
    @ViewChild('moodcheckDailyViewComp')
    moodcheckDailyViewComp: DailyMoodcheckViewComponent;
    @ViewChild('moodcheckWeeklyViewComp')
    moodcheckWeeklyViewComp: WeeklyMoodcheckViewComponent;
    @ViewChild('moodcheckMonthlyViewComp')
    moodcheckMonthlyViewComp: MonthlyMoodcheckViewComponent;
    @ViewChild('moodchecOverallViewComp')
    moodchecOverallViewComp: OverallViewMoodcheckComponent;
    @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
    dateFilterOptions = [
        {
            key: 'last30days',
            value: 30,
            isSelected: true,
        },
        {
            key: 'last3Months',
            value: 90,
            isSelected: false,
        },
        {
            key: 'last6Months',
            value: 180,
            isSelected: false,
        },
    ];
    dateFilter: any = 30;
    dateFilterTitle: any = 'last30days';
    activeLink = 'last30days';
    moodcheckCounts: any;
    tabselecteddays: string = '30';
    activedays: string = '30';
    showEventAssignModal: boolean = false;
    assignedList: any = [];
    completedList: any = [];
    nameInitial: any;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;
    assignedListLoaded: boolean = false;
    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private mcService: MoodcheckService,
        private dateservice: DateService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
        });
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.user = this.userService.getUser();
        this.selectedMode = 'summary';
        this.selectedMood = 'Weekly';
        this.isLoaded = true;
        this.dateservice.getSelectedDate().subscribe(date => {
            this.selectedDate = date;
        });
        this.getMoodcheckData(+this.tabselecteddays);
        this.getAssignedList();
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.updateCount();
        this.moodchecOverallViewComp.getMoodcheckData(+this.dateRangeSelected);
        this.getMoodcheckData(this.dateRangeSelected);
    }

    updateCount() {
        this.professionalService
            .getCounts(this.id, '3', this.rangeDate, this.currentDate)
            .subscribe(result => {
                this.countData = result;
            });
    }

    getMoodcheckData(dayFilter?: number) {
        let from = moment()
            .add(-1, 'months')
            .startOf('month')
            .format('YYYY-MM-DD');
        let to = moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
        this.mcService.getMoodcheckData(from, to, this.id).then(
            (result: any) => {
                this.moodchecks = Moodcheck.initializeArray(result);
                this.isEmpty = this.moodchecks.length > 0 ? false : true;
                let currentDate = moment.parseZone().format(this.changeDate);
                if (this.selectedMood === 'Daily') {
                    this.moodcheckDailyViewComp.filterDailyMoodcheckData(
                        this.moodchecks,
                        currentDate
                    );
                    this.moodcheckDailyViewComp.setCalendarDate(
                        this.selectedDate
                    );
                }
                if (this.selectedMood === 'Weekly') {
                    setTimeout(() => {
                        this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
                            this.moodchecks,
                            currentDate
                        );
                    }, 2000);
                }
                if (this.selectedMood === 'Monthly') {
                    setTimeout(() => {
                        this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
                            this.moodchecks,
                            currentDate
                        );
                    }, 2000);
                }
            },
            (error: any) => {
                this.moodchecks = [];
                this.moodcheckDailyViewComp.filterDailyMoodcheckData(
                    this.moodchecks
                );
                this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
                    this.moodchecks
                );
                this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
                    this.moodchecks
                );
                this.log.error('Error getting moodchecks. ' + error.message);
            }
        );
    }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignedList();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }

    setdayschart(tab: string) {
        if (this.user.userType === 'user') {
            if (this.tabselecteddays != tab) {
                //   this.showbar = true;
                this.tabselecteddays = tab;

                this.getMoodcheckData(+this.tabselecteddays);
            }
        } else if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
                this.getMoodcheckData(+this.tabselecteddays);
            }
        }
    }

    moodcheckPercentageChartUpdate(moodcheckData: any = []) {
        this.goodMoodStrings = this.mcService.getMoodcheckAdminCountData(
            moodcheckData,
            'good'
        ).categories;
        this.badMoodStrings = this.mcService.getMoodcheckAdminCountData(
            moodcheckData,
            'bad'
        ).categories;
        console.log(this.goodMoodStrings, this.badMoodStrings);
    }

    openDatePicker(picker: MatDatepicker<Date>) {
        picker.open();
    }

    onDateSelected(event: MatDatepickerInputEvent<Date>) {
        this.selectedDate = event.value;
    }

    getAssignedList() {
        this.assignedListLoaded = false;
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=moodcheck` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = AssignEventList.initializeArray(
                        result.assigned
                    );
                    this.completedList = AssignEventList.initializeArray(
                        result.completed
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                }
            );
    }

    onTabChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    setMoodPercentageMode(mode) {
        this.selectedMode = mode;
    }

    setMoodMode(mode) {
        this.selectedMood = mode;
        this.refreshContent();
    }

    refreshContent() {
        if (!this.user) {
            return;
        }
        if (this.selectedMode === 'breakdown') {
            this.getMoodcheckData();
        }
    }

    onSelectFilterData(selectedItem: any) {
        this.dateFilter = selectedItem.value;
        this.dateFilterTitle = selectedItem.key;
        this.activeLink = selectedItem.key;
    }

    setCalendarDate() {
        this.changeDate = moment(this.selectedDate)
            .parseZone()
            .format('YYYY-MM-DD');
        this.refreshContent();
    }

    formatDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        };
        return date.toLocaleDateString('en-US', options);
    }

    getStartOfWeek(date: Date): string {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust for Sunday as the start of the week
        return this.datePipe.transform(startOfWeek, 'mediumDate');
    }

    getEndOfWeek(date: Date): string {
        const endOfWeek = new Date(date);
        endOfWeek.setDate(date.getDate() + (6 - date.getDay())); // Adjust for Saturday as the end of the week
        return this.datePipe.transform(endOfWeek, 'mediumDate');
    }

    deleteAssign(aId){
        this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete this assignment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
                    this.api
                    .delete(
                        `syncheduser/assignment/delete/` + aId +
                            `?SynchedUserID=` + this.id
                    )
                    .subscribe(
                        (result: any) => {
                            this.getAssignedList();
                            this.isLoaded = true;
                            this.assignedListLoaded = true;
                            this.modalService.showAlert(
                                "Success",
                                'Assignment deleted successfully'
                            );
                        },
                        (error: any) => {
                            this.log.error(
                                'Error getting delete.' + error.message
                            );
                            this.isLoaded = true;
                        }
                    );
                }
			});
        }
}
