<div class="app-l-page-wrap">
	<div class="text-center">
		<page-loader *ngIf="!isLoaded"></page-loader>
	</div>
	<div class="app-l-page-header-block">
		<div class="app-l-page-title-block">
			<button
				class="app-c-btn app-c-btn--tertiary"
				[ngClass]="{ active: selectedTab === 'daily' }"
				(click)="selectTab('daily')"
				translate
			>
				calendar.daily
			</button>
			<button
				class="app-c-btn app-c-btn--tertiary"
				[ngClass]="{ active: selectedTab === 'weekly' }"
				(click)="selectTab('weekly')"
				translate
			>
				calendar.weekly
			</button>
			<button
				class="app-c-btn app-c-btn--tertiary"
				[ngClass]="{ active: selectedTab === 'monthly' }"
				(click)="selectTab('monthly')"
				translate
			>
				calendar.monthly
			</button>
		</div>
		<div class="app-l-page-title-actions">
			<button
				class="app-c-btn app-c-btn--curve app-c-btn--primary"
				(click)="scheduleActivity()"
			>
				<svg-icon icon="plus"></svg-icon>
				<span translate>calendar.addActivity</span>
			</button>
		</div>
	</div>
	<div class="app-l-calendar__wrap">
		<ng-container *ngIf="selectedTab === 'daily'">
			<app-daily-calendar-view
				[hidden]="selectedTab !== 'daily'"
				#calendarDailyView
			></app-daily-calendar-view>
		</ng-container>
		<ng-container *ngIf="selectedTab === 'weekly'">
			<app-weekly-calendar-view
				[hidden]="selectedTab !== 'weekly'"
				#calendarWeeklyView
			></app-weekly-calendar-view>
		</ng-container>
		<ng-container *ngIf="selectedTab === 'monthly'">
			<app-monthly-calendar-view
				[hidden]="selectedTab !== 'monthly'"
				#calendarMonthlyView
			></app-monthly-calendar-view>
		</ng-container>
	</div>
</div>
