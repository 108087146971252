<div class="form-modal">
    <mat-dialog-content>
  
      <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
          aria-label="Close organization edit form">
          <img src="./assets/img/icons-svg/close-icon.svg">
      </a>
      <div class="img-wrapper">
          <div class="clearfix"></div>
          <div class="title-wrapper">
              <h1>Audit JSON Data</h1>
          </div>
      </div>
      <!-- <popup-loader *ngIf="isloaded"></popup-loader> -->
      <div class="form-wrapper" >
            <div class="box">
                <h1 class="boxheading"> Previous JSON</h1>
                <pre class="boxsplit"  *ngIf = "!nulldata" [innerHTML]="preval" ></pre>
                    <!-- <code *ngIf = "!nulldata">{{auditdataprevArr | json}}</code> -->
                    <code *ngIf = "nulldata">No Data</code>
                <!-- </pre> -->
            </div>
            <div class="box">
                <h1 class="boxheading"> Updated JSON</h1>
                <pre class="boxsplit"  *ngIf = "!nulldata" [innerHTML]="diffView" ></pre>
                <code *ngIf = "nulldata">No Data</code>
                <!-- <ngx-object-diff [obj]="diffView"></ngx-object-diff> -->
                <!-- <pre class="boxsplit">
                    <code *ngIf = "!nulldata">{{auditdatacurrentArr | json}}</code>
                    <code *ngIf = "nulldata">No Data</code>
                </pre> -->
            </div>
      </div>
    </mat-dialog-content>
  </div>
  