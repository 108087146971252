import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';

@Component({
  selector: 'app-moodcheck-reminders',
  templateUrl: './moodcheck-reminders.component.html',
  styleUrls: ['./moodcheck-reminders.component.scss']
})
export class MoodcheckRemindersComponent implements OnInit {
	moodcheckFreq: number = 2;
  user: User;
	prefrencePopups: any;
  constructor(
      private api: ApiService,
      private userService: UserService,
      private log: LogService,
      private modalService: ModalService,
      private translate: TranslateService,
      public dialogRef: MatDialogRef<MoodcheckRemindersComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.moodcheckFreq = data.data.moodcheckFreq;
      this.userService.watcher.subscribe((user: User) => {
        this.user = user;
        this.moodcheckFreq =
              this.user.preferences.moodcheckNotificationFrequency || 0;
      });
      this.user = this.userService.getUser();
		  this.translate.get("menu.notifications.popups").subscribe((res: any) => {
      this.prefrencePopups = res;
      });
  }

  ngOnInit(): void {
  }
  
	saveMoodcheckFrequencyModal() {
		var params = {
		  MoodcheckNotificationFrequency: this.moodcheckFreq,
		};
		//sync changes to server
		this.api.put('users/preferences', params).subscribe(
		  (result: any) => {
			this.user.preferences.moodcheckNotificationFrequency =
			  this.moodcheckFreq;
			this.userService.setUser(this.user);
			//load the dashboard, and update user information
			this.log.event(AnalyticEvent.event.preferenceChange, '', {
			  preference: "NotificationOn",
			});
      this.dialogRef.close('success');
			this.modalService.showAlert(
			  this.prefrencePopups.success,
			  this.prefrencePopups.successMsg
			);
		  },
		  (error: any) => {
			this.log.error("Error updating preferences. " + error.message);
			this.modalService.showAlert(
			  this.prefrencePopups.error,
			  this.prefrencePopups.updating
			);
		  }
		);
	}
  skip() {
    this.dialogRef.close('skip');
  }
}
