<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-dm-das-assessment-list" *ngIf="isLoaded">
    <div class="row app-dm-das-assessment-list-main">
        <div class="col-md-12 col-lg-6">
           <h3 translate>das.resultsSummary</h3>
            <div class="card-content">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="monthDropDown" appearance="outline">
                            <mat-select [value]="dateFilter" name="monthfilter"  (selectionChange)="onSelectFilterData($event)">
                            <mat-option *ngFor="let item of dateFilterOptions" [value]="item.value">
                                {{'das.dateFilter.' + item.key | translate}}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6"> <button mat-button class="startbtn" *ngIf="user.userType === 'user'"   routerLink="/app/assessments/dass/new" translate>das.startAssessment</button></div>
                </div>
                <div class="chart-container">
                    <canvas #dasAssessmentChart id="dasAssessmentChart"></canvas>
                </div>
                <div class="app-dm-chart">
                    <a [ngClass]="{'active': selectedChartLegend === 'all'}" (click)="selectLegend('all')" translate>das.all</a>
                    <a [ngClass]="{'active': selectedChartLegend === 'depression'}" (click)="selectLegend('depression')"translate><img alt="Plus" src="./assets/img/assesment/Depression.svg"> das.depression</a>
                    <a [ngClass]="{'active': selectedChartLegend === 'anxity'}" (click)="selectLegend('anxity')" translate><img alt="Plus" src="./assets/img/assesment/Anxiety.svg"> das.anxiety</a>
                    <a [ngClass]="{'active': selectedChartLegend === 'stress'}" (click)="selectLegend('stress')" translate><img alt="Plus" src="./assets/img/assesment/Stress.svg"> das.stress</a>
                </div>   
            </div>
        </div>
        <div class="col-md-12 col-lg-6 app-dm-results-main">
            <h3 translate>das.dasAssessmentResults
                <!-- <img alt="Plus" src="./assets/img/assesment/filterIcon.png" class="filterIcon"> -->
                <a [href]="'tel:' + contactGroupTelephone" *ngIf= "gethelpbutton && contactGroupTelephone" >
                    <button mat-button class="startbtn-r"  (click)="onCallContact($event, organization.contactGroup)"   translate>
                        <img alt="Plus" src="./assets/img/assesment/call-calling.svg" class="phoneRing">contact.getHelpNow</button>
                </a>
    
                <a tabindex="0" href={{hotline?.url}} *ngIf= "gethelpbutton && showOther" >
                    <button mat-button class="startbtn-r" (click)="onHotline($event)"   translate>
                        <img alt="Plus" src="./assets/img/assesment/call-calling.svg" class="phoneRing">contact.getHelpNow</button>
                </a>
            </h3>
            <div *ngIf="assessments" class="app-dm-results-main-inn">

                <div class="row">
                    
                    <div class="search-filter">
                        <!-- <mat-form-field class="wt-mat-form-field">
                            <input matInput type="text" class="wt-mat-input">
                        </mat-form-field> -->
                    </div>
                </div>
                <div class="results-main">
                    <div class="results-inn">
                        <mat-card class="card app-dm-das-assessment-list-card" *ngFor="let assessment of assessments | paginate: { itemsPerPage: 10, currentPage: data  }; let i = index " >
                            <mat-card-header>
                                <mat-card-title-group>
                                <mat-card-title>
                                <b>  {{assessment.created | date:'MM-dd-yyyy'}}</b>
                                    ( {{assessment.created | date:'h:mm a'}} )
                                    <a mat-button class="popbutton" [routerLink]="['/app/assessment/result',assessment.id]" translate>das.viewResults</a>
                                </mat-card-title>
                                </mat-card-title-group>
                                <mat-card-subtitle> </mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-md-4 borderitem">
                                        <h6 translate>das.tableHeaders.0</h6>
                                        <p>{{assessment.depressionlevellabel}}</p>
                                        <ng-container
                                            [ngTemplateOutlet]="daslevelIndicationImg"
                                            [ngTemplateOutletContext]="{level: assessment.depressionlevel}"
                                        >
                                        </ng-container>
                                        <button mat-button class="courdebtn" *ngIf="assessment.depressionlevel < 1" 
                                        routerLink="/app/series-listing/purpose/130" translate>
                                        {{'theory.courses.purpose.title' | translate | slice:0:17}}...</button>
                                    <button mat-button class="courdebtn" 
                                    *ngIf="assessment.depressionlevel == 1 || assessment.depressionlevel == 2 ||assessment.depressionlevel == 3 || assessment.depressionlevel == 4" 
                                    routerLink="/app/series-listing/depression_new/133">
                                    {{'theory.courses.depression_new.title' | translate | slice:0:17}}...</button>
                                        
                                    </div>
                                    <div class="col-md-4 borderitem">
                                        <h6 translate>das.tableHeaders.1</h6>
                                        <p>{{assessment.anxietylevellabel}}</p>
                                        <ng-container
                                            [ngTemplateOutlet]="daslevelIndicationImg"
                                            [ngTemplateOutletContext]="{level: assessment.anxietylevel}"
                                        >
                                        </ng-container>
                                        <button mat-button class="courdebtn" *ngIf="assessment.anxietylevel < 1" 
                                        routerLink="/app/series-listing/sleep/127">
                                        {{'theory.courses.sleep.title' | translate | slice:0:17}}...</button>
                                        
                                        <span *ngIf="!assessment.anxietylevel == 0 ">
                                            <button mat-button class="courdebtn" 
                                            *ngIf="(assessment.anxietylevel == 1 || assessment.anxietylevel == 2 || assessment.anxietylevel == 3 || assessment.anxietylevel == 4) || (assessment.stresslevel == 2 || assessment.stresslevel == 3 || assessment.stresslevel == 4) "
                                            routerLink="/app/series-listing/anxiety/132">
                                            {{'theory.courses.anxiety.title' | translate | slice:0:17}}...</button>
                                            
                                        </span>
                                    </div>
                                    <div class="col-md-4 borderitem">
                                        <h6 translate>das.tableHeaders.2</h6>
                                        <p>{{assessment.stresslevellabel}}</p>
                                        <ng-container
                                            [ngTemplateOutlet]="daslevelIndicationImg"
                                            [ngTemplateOutletContext]="{level: assessment.stresslevel}"
                                        >
                                        </ng-container>
                                        <button mat-button class="courdebtn" 
                                        *ngIf="assessment.stresslevel < 1" 
                                        routerLink="/app/series-listing/mindfulness/128">
                                        {{'theory.courses.mindfulness.title' | translate | slice:0:17}}...</button>
                                        
                                        <button mat-button class="courdebtn zenroombtn" 
                                        *ngIf="assessment.stresslevel == 1"
                                        routerLink="/app/practice/zen-room">
                                        {{'zenRoom.title' | translate | slice:0:17}}</button>
                                        
                                        <span *ngIf="assessment.stresslevel > 1">
                                            <button mat-button class="courdebtn"
                                            *ngIf="assessment.stresslevel == 2 || assessment.stresslevel == 3 || assessment.stresslevel == 4"
                                            routerLink="/app/series-listing/anxiety/132">
                                            {{'theory.courses.anxiety.title' | translate | slice:0:17}}...</button>
                                            
                                        </span>
                                    </div>
                                </div>
                            </mat-card-content>
                    </mat-card>
                    </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                    <div class="pagination-cls">
                        <paging-controls class="step-pagination" [isItemsPerPage]="true" maxSize="4"></paging-controls>
                    </div>
                    <div class="pagination-cls">
                        <paging-controls class="step-pagination" maxSize="4" responsive="true" (pageChange)="data = $event" [isItemsPerPage]="false"></paging-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-level="level" #daslevelIndicationImg>
    <img
      class="indicator"
      *ngIf="level == 4"
      src="./assets/img/assesment/icon-das-assessment-results-veryhigh.svg"
    />
    <img
       class="indicator"
      *ngIf="level == 3"
      src="./assets/img/assesment/icon-das-assessment-results-high.svg"
    />
    <img
        class="indicator"
      *ngIf="level == 2"
      src="./assets/img/assesment/icon-das-assessment-results-moderate.svg"
    />
    <img
    class="indicator"
      *ngIf="level == 1"
      src="./assets/img/assesment/icon-das-assessment-results-mild.svg"
    />
    <img
    class="indicator"
      *ngIf="level == 0"
      src="./assets/img/assesment/icon-das-assessment-results-good.svg"
    />
  </ng-template>



