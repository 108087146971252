<!--
  Generated template for the Login page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->

<div id="page-password-change" class="public-portal-right-col">
    <h1 id="section-title" class="wt-access">Password change</h1>
    <div class="center form-screen">
        <div id="badge-header">

            <img alt="Weltrack badge" src="./assets/img/welltrack-boost-circle.png" style="max-width:150px; height:auto; max-height:150px;"
                class="animated fadeInDown">
        </div>

        <div class="title-wrapper">
            <h2 translate>changePassword.title</h2>
            <p translate *ngIf="showForm">changePassword.description</p>
        </div>
        <div style="padding-bottom: 0;" *ngIf="showForm">
            <div class="icon-input">
                <img alt="Password validates" *ngIf="passwordValidate && password" src="./assets/img/icons/check@2x.png"
                    class="check animated fadeInUp" item-content>
                <input class="wt-auto-change-password" autoFocus tabindex="0" aria-label="Password" id="password" type="{{passwordType}}" placeholder="{{passwordChangeText.placeholders.password}}"
                    [(ngModel)]="password" name="password" (ngModelChange)="onChangePassword()" />
                    <i  class="icon-password-eye" (click)="onClickPassword('password')"  style="cursor: pointer;"[ngClass]="passwordType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"  ></i>
                <p *ngIf="!passwordValidate && password" aria-live="assertive" role="alert" class="red" translate>signUp.passwordValidate</p>
            </div>
            <div class="icon-input">
                <img alt="password validates" *ngIf="passwordRepeatValidate && passwordrepeat" src="./assets/img/icons/check@2x.png"
                    class="check animated fadeInUp" item-content>
                <input class="wt-auto-change-password" tabindex="0" aria-label="Password repeat" id="passwordrepeat" type="{{passwordConfirmationType}}" placeholder="{{passwordChangeText.placeholders.passwordrepeat}}"
                    [(ngModel)]="passwordrepeat" name="passwordrepeat" (ngModelChange)="onChangeRepeat()" />
                    <i  class="icon-password-eye" (click)="onClickPassword('confirmPassword')"  style="cursor: pointer;"[ngClass]="passwordConfirmationType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"  ></i>
                <p *ngIf="!passwordRepeatValidate && passwordrepeat" aria-live="assertive" role="alert" class="red"
                    translate>signUp.passwordRepeatValidate</p>
            </div>
            <div class="center">
                <a tabindex="0" (click)="onChange()" (keypress)="onChange()" class="btn btn-primary" translate>changePassword.btnSubmit</a>
            </div>
        </div>
        <div [innerHtml]="message" *ngIf="!showForm"></div>
    </div>
</div>