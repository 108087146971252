import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Moodcheck } from "../../models/moodcheck";
import * as moment from "moment";
import { MoodcheckService } from "../../lib/moodcheck.service";
import { UserService } from "../../lib/user.service";
import { User } from "../../models/user";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { DateService } from '../../lib/url.service'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-daily-moodcheck-view',
  templateUrl: './daily-moodcheck-view.component.html',
  styleUrls: ['./daily-moodcheck-view.component.scss']
})

export class DailyMoodcheckViewComponent implements OnInit {
  moodcheckCategory = [
    { key: "fantastic", value: 0, iconIndex: 10 },
    { key: "veryHappy", value: 0, iconIndex: 9 },
    { key: "happy", value: 0, iconIndex: 8 },
    { key: "good", value: 0, iconIndex: 7 },
    { key: "fine", value: 0, iconIndex: 6 },
    { key: "ok", value: 0, iconIndex: 5 },
    { key: "notGreat", value: 0, iconIndex: 4 },
    { key: "bad", value: 0, iconIndex: 3 },
    { key: "veryBad", value: 0, iconIndex: 2 },
    { key: "awful", value: 0, iconIndex: 1 },
  ];
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() moodcheckData: Moodcheck[];
  user: User;
  dailyMoodcheckData: Moodcheck[];
  dashboardLangKey: any;
  selectedDayTitle: string;
  isToday: boolean = true;
  hasData: boolean = false;
  isLoading: boolean = true;
  selectedDate: any = moment.parseZone().format();
  today: any = moment.parseZone().format();
  selectedMonth: any = moment().format("MM");
  paramsSub: any;
  id: number;
  

  constructor(
    private mcService: MoodcheckService,
    private api: ApiService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private log: LogService,
    private dateservice: DateService) {
      this.translate.stream("dashboard").subscribe((res: any) => {
        this.dashboardLangKey = res;
        this.selectedDayTitle = this.dashboardLangKey.today;
      });
     }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.isToday = this.checkToday(this.selectedDate);
  }


  filterDailyMoodcheckData(data: any, currentDate?: any) {
    this.moodcheckData = data;
    this.selectedDate = currentDate;
    this.isToday = this.checkToday(this.selectedDate);
    this.selectedDayTitle = !this.isToday
      ? this.dashboardLangKey.days[moment(this.selectedDate).day()]
      : this.dashboardLangKey.today;
    const moodcheckData = data.filter((item) =>
      this.checkToday(item.created, currentDate)
    );

    const totalDataCount = moodcheckData.length;
    if (totalDataCount > 0) {
      this.dailyMoodcheckData =
        this.mcService.categorizeMoodcheck(moodcheckData);
      for (const property in this.dailyMoodcheckData) {
        let data: any = this.dailyMoodcheckData[property];
        let index = 11 - data.value;
        this.moodcheckCategory[index - 1].value = data.data.length
          ? (data.data.length / totalDataCount) * 100
          : 0;
      }
      this.isLoading = false;
      this.hasData = true;
    } else {
      this.hasData = false;
      this.isLoading = false;
      this.moodcheckCategory.forEach((item) => {
        item.value = 0;
      });
    }
  }

  checkToday(date: Date, currentDate?: Date): boolean {
    const today = currentDate
      ? moment(currentDate).startOf("day")
      : moment().startOf("day");
    const inputDate = moment(date).startOf("day");
    return today.isSame(inputDate);
  }

  setCalendarDate(date) {
    if(date){
      this.selectedDate = date;
    }
    var currentMonth = moment(this.selectedDate).format("MM");
    if (currentMonth == this.selectedMonth) {
      this.filterDailyMoodcheckData(this.moodcheckData, this.selectedDate);
    } else {
      this.selectedMonth = moment(this.selectedDate).format("MM");
      this.getMoodcheckData(this.selectedDate);
    }
  }
  
  onClickPrevBtn() {
    let date = moment(this.selectedDate).add(-1, "days").format();
    this.checkMonth(date);
    let passdate: Date = new Date(date);
    this.dateservice.setSelectedDate(passdate);
  }

  onClickNextBtn() {
    let date = moment(this.selectedDate).add(1, "days").format();
    this.checkMonth(date);
    let passdate: Date = new Date(date);
    this.dateservice.setSelectedDate(passdate);
  }

  checkMonth(date: any) { 
    var selectedMonth = moment(date).format("MM");
    var currentMonth = moment(this.selectedDate).format("MM");
    if (currentMonth == selectedMonth) {
      this.filterDailyMoodcheckData(this.moodcheckData, date);
    } else {
      this.selectedMonth = moment(date).format("MM");
      this.getMoodcheckData(date);
    }
  }

  getMoodcheckData(date) {
    if(this.user.userType != "user"){
      this.paramsSub = this.activatedRoute.params.subscribe((params) => {
        this.id = parseInt(params["id"], 10);
        // this.id = 181361;
      });
    } else{
      this.id = this.user.id;
    }
    this.isLoading = true;
    this.hasData = false;
    let startDate: any = moment(date).startOf("month").format("YYYY-MM-DD");
    let endDate: any = moment(date).endOf("month").format("YYYY-MM-DD");
    this.mcService.getMoodcheckData(startDate, endDate, this.user.id).then(
      (result) => {
        this.filterDailyMoodcheckData(result, date);
      },
      (error) => {
        this.filterDailyMoodcheckData([], date);
      }
    );
  }
}
