<div class="app-l-page-wrap">
	<div id="moodcheck-modal" class="moodcheck-modal-section">
		<!-- <div class="modal-header" tabindex="-1">
            <div class="buttons-left">
                <a tabindex="0" *ngIf="!showOptions" (click)="onDismiss()" (keypress)="onDismiss()" translate>moodcheck.cancel</a>
            </div>
            <h3 translate>moodcheck.title</h3>
            <div class="buttons-right">
                <a tabindex="0" *ngIf="!showOptions" class="btn wt-auto-moodcheck-save" [ngClass]="{'btn-disabled': !saveEnabled, 'btn-primary': saveEnabled}"
                    (click)="!submitting ? onSave(): false" (keypress)="!submitting ? onSave(): false" translate>moodcheck.save</a>
            </div>
        </div> -->

		<div class="app-l-mood-check--wrap">
			<h3 class="app-l-mood-check__title">MoodCheck</h3>
			<div class="app-l-mood-check--tab tab-content-main">
				<!-- <div class="tab-content-box"></div> -->
				<div class="tab-content">
					<div class="tab-item">
						<a
							class="btn tab-btn"
							[ngClass]="{
								active: isTabActive('mood'),
								'active-hidden':
									!isTabActive('mood') && moodcheck?.value
							}"
							tabindex="0"
							(click)="onChangeTab('mood')"
							(keypress)="onChangeTab('mood')"
						>
						<span
						[translate]="
							!isTabActive('mood') && moodcheck?.value
								? ' '
								: '1'
						"></span>
							<svg-icon
								icon="tick"
								*ngIf="!isTabActive('mood') && moodcheck?.value"
							></svg-icon>
						</a>
						<span
							class="tab-btn-label sc-ion-label-md-h sc-ion-label-md-s md hydrated"
							>Mood</span
						>
					</div>
					<div class="tab-item">
						<a
							class="btn tab-btn"
							[ngClass]="{
								active: isTabActive('activity'),
								'active-hidden':
									!isTabActive('activity') &&
									moodcheck?.activity
							}"
							tabindex="0"
							(click)="onChangeTab('activity')"
							(keypress)="onChangeTab('activity')"
							>
							<span
							[translate]="
								!isTabActive('activity') && moodcheck?.activity
									? ' '
									: '2'
							"></span>
						<svg-icon
								icon="tick"
								*ngIf="!isTabActive('activity') && moodcheck?.activity"
							></svg-icon>
						</a>
						<span
							class="tab-btn-label sc-ion-label-md-h sc-ion-label-md-s md hydrated"
							>Activity</span
						>
					</div>
					<div class="tab-item">
						<a
							class="btn tab-btn"
							[ngClass]="{
								active: isTabActive('people'),
								'active-hidden':
									!isTabActive('people') &&
									moodcheck?.peoples.length > 0
							}"
							tabindex="0"
							(click)="onChangeTab('people')"
							(keypress)="onChangeTab('people')"
						>
						<span 
						[translate]="
							!isTabActive('people') && moodcheck?.peoples.length > 0
								? ' '
								: '3'
						"></span>
						<svg-icon
								icon="tick"
								*ngIf="!isTabActive('people') && moodcheck?.peoples.length > 0"
							></svg-icon>
						</a>
						<span
							class="tab-btn-label sc-ion-label-md-h sc-ion-label-md-s md hydrated"
							>People</span
						>
					</div>
					<div class="tab-item">
						<a
							class="btn tab-btn"
							[ngClass]="{
								active: isTabActive('place'),
								'active-hidden':
									!isTabActive('place') && moodcheck?.place
							}"
							tabindex="0"
							(click)="onChangeTab('place')"
							(keypress)="onChangeTab('place')"
						>
						<span 
						[translate]="
							!isTabActive('place') && moodcheck?.place
								? ' '
								: '4'
						"></span>
						<svg-icon
								icon="tick"
								*ngIf="!isTabActive('place') && moodcheck?.place"
							></svg-icon>
						</a>
						<span
							class="tab-btn-label sc-ion-label-md-h sc-ion-label-md-s md hydrated"
							>Place</span
						>
					</div>
					<div class="tab-item">
						<a
							class="btn tab-btn"
							[ngClass]="{
								active: isTabActive('notes'),
								'active-hidden':
									!isTabActive('notes') && moodcheck?.notes
							}"
							tabindex="0"
							(click)="onChangeTab('notes')"
							(keypress)="onChangeTab('notes')"
						>
						<span 
						[translate]="
							!isTabActive('notes') && moodcheck?.notes
								? ' '
								: '5'
						"></span>
						<svg-icon
								icon="tick"
								*ngIf="!isTabActive('notes') && moodcheck?.notes"
							></svg-icon>
						</a>
						<span
							class="tab-btn-label sc-ion-label-md-h sc-ion-label-md-s md hydrated"
							>Thoughts</span
						>
					</div>
				</div>
				<page-loader *ngIf="!isLoaded"></page-loader>
				<div class="app-l-mood-check__tab-body"	*ngIf="isLoaded">
					<page-mood-tab
						[hidden]="!isTabActive('mood')"
						(onMoreClicked)="onMore($event)"
						[options]="moodOptions.feelings"
						[feelings]="moodOptions.feelings"
					></page-mood-tab>
					<page-activity-tab
						[hidden]="!isTabActive('activity')"
						(onMoreClicked)="onMore($event)"
						[options]="moodOptions.activities"
					></page-activity-tab>
					<page-people-tab
						[hidden]="!isTabActive('people')"
						(onMoreClicked)="onMore($event)"
						[options]="moodOptions.people"
					></page-people-tab>
					<page-place-tab
						[hidden]="!isTabActive('place')"
						(onMoreClicked)="onMore($event)"
						[options]="moodOptions.places"
					></page-place-tab>
					<page-note-tab
						[hidden]="!isTabActive('notes')"
					></page-note-tab>
					<page-moodcheck-options
						*ngIf="showOptions"
						[moreOptions]="moreOptions"
						[category]="category" class="app-l-modecheck__sidepanel"
						(closeModal)="onCloseOptions($event)"
					></page-moodcheck-options>
				</div>
				<div class="app-l-mood-check__tab-footer" *ngIf="isLoaded">
					<div class="app-l-tab-footer__left">
						<button
							class="app-c-btn app-c-btn--secondary app-c-btn--curve"
							(click)="onPreviousTab()"
							(keypress)="onPreviousTab()"
						>
							<svg-icon icon="arrow-left-long"></svg-icon>
							<span>Back</span>
						</button>
					</div>
					<div class="app-l-tab-footer__right">
						<button
							tabindex="0"
							class="app-c-btn app-c-btn--primary app-c-btn--curve"
							[disabled]="!saveEnabled"
							[ngClass]="{
								'btn-disabled': !saveEnabled,
								'btn-primary': saveEnabled
							}"
							(click)="onSave()"
							(keypress)="onSave()"
							*ngIf="isTabLast()"
						>
							<span translate> moodcheck.submit </span>
							<svg-icon icon="tick"></svg-icon>
						</button>
						<button
							tabindex="0"
							class="app-c-btn app-c-btn--secondary app-c-btn--curve"
							(click)="onNextTab()"
							(keypress)="onNextTab()"
							*ngIf="!isTabLast()"
						>
							<span translate>moodcheck.continue</span>
							<svg-icon icon="arrow-right-long"></svg-icon>
						</button>
					</div>
					<!-- <a tabindex="0" class="btn btn-bold  wt-auto-moodcheck-close" (click)="onCloseOptions()" (keypress)="onCloseOptions()"
                    *ngIf="showOptions" translate>moodcheck.done</a> -->
				</div>
			</div>
		</div>
		<!-- <div class="tab-wrapper">
            <div class="wt-tabs">
                <div class="wt-tab wt-auto-moodcheck-mood" [ngClass]="{'active': isTabActive('mood')}"><a tabindex="0"
                        (click)="onChangeTab('mood')" (keypress)="onChangeTab('mood')" translate>moodcheck.mood.tab</a></div>
                <div class="wt-tab wt-auto-moodcheck-activity-tab" [ngClass]="{'active': isTabActive('activity')}"><a
                        tabindex="0" (click)="onChangeTab('activity')" (keypress)="onChangeTab('activity')" translate>moodcheck.activity.tab</a></div>
                <div class="wt-tab wt-auto-moodcheck-people-tab" [ngClass]="{'active': isTabActive('people')}"><a tabindex="0"
                        (click)="onChangeTab('people')" (keypress)="onChangeTab('people')" translate>moodcheck.people.tab</a></div>
                <div class="wt-tab wt-auto-moodcheck-save-place-tab" [ngClass]="{'active': isTabActive('place')}"><a
                        tabindex="0" (click)="onChangeTab('place')" (keypress)="onChangeTab('place')" translate>moodcheck.place.tab</a></div>
                <div *ngIf="!hideNotes" class="wt-tab wt-auto-moodcheck-save-notes-tab" [ngClass]="{'active': isTabActive('notes')}"><a
                        tabindex="0" (click)="onChangeTab('notes')" (keypress)="onChangeTab('notes')" translate>moodcheck.notes.tab</a></div>
            </div>
        </div> -->
	</div>
</div>
<!--
<div class="app-l-mood-check__sidepanel">
    <page-moodcheck-options></page-moodcheck-options>
</div> -->
