<page-loader *ngIf="!isLoaded"></page-loader>
<div class="zen-room-top-section">
	<!-- <div class="become-user-label">
            <div class="app-dm-card-body">
                <div class="row">
                    <div class="">
                        <h1 class="h1-card-title" translate>practice.zen.title</h1>
                        <h6 class="h6-card-title" translate>practice.zen.body</h6>
                        <a class="btn btn-danger px-4 py-2" routerLink="/app/practice/zen-room" role="button"
                            translate>practice.zen.button</a>
                    </div>
                </div>
            </div>
        </div> -->
	<div class="app-l-container" *ngIf="isLoaded">
		<div class="app-l-zen-room-banner" *ngIf="isToolEnable('zenroom')">
			<div class="app-l-zen-room-banner__left">
				<h1 class="app-l-zen-room-banner__heading" translate>
					practice.zen.title
				</h1>
				<h6 class="app-l-zen-room-banner__desc" translate>
					practice.zen.body
				</h6>
				<button class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--with-icon"
					routerLink="/app/practice/zen-room" translate>
					<span translate>practice.zen.enter</span>
					<svg-icon icon="angle-right"></svg-icon>
				</button>
			</div>
			<!-- <div class="app-l-zen-room-banner__right">
					<img
					aria-hidden="true"
					alt="Zen room"
					src="./assets/img/zenroom/zen-room-bg.svg"
				/>
				</div> -->
		</div>
	</div>
</div>
<div class="app-l-page-wrap" *ngIf="isLoaded">
	<div class="zen-room-bottom-section">
		<div class="app-dm-card-grid-item__wrap" *ngIf="showThoughts">
			<h4 class="app-dm-card-grid-item__heading" translate>
				practice.thoughts
			</h4>
			<div class="app-dm-card-grid-item">
				<a *ngIf="isToolEnable('thoughtdiary')" class="app-dm-card-item"
					routerLink="/app/practice/thoughtdiary">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" alt="plug" src="./assets/img/Rectangle 4350.png" alt="Thoughtdiary" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.thoughtDiary.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.thoughtDiary.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-right"></svg-icon>
						</button>
					</div>
				</a>

				<a class="app-dm-card-item" *ngIf="isToolEnable('roomForPossitive')"
					routerLink="/app/practice/room-for-positive/listing">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" height="85" width="182" alt="plug"
							src="./assets/img/icons-svg/room_for_positive.svg" alt="Room-For-Positive" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.rfp.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.rfp.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-right"></svg-icon>
						</button>
					</div>
				</a>

				<a class="app-dm-card-item" *ngIf="isToolEnable('quiz')" routerLink="/app/practice/cd-quiz">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" alt="plug" src="./assets/img/Rectangle 4350 (2).png"
							alt="Thought Distortions Quiz" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.cdQuiz.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.cdQuiz.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-right"></svg-icon>
						</button>
					</div>
				</a>
			</div>
		</div>
		<div class="app-dm-card-grid-item__wrap" *ngIf="showActions">
			<h4 class="app-dm-card-grid-item__heading" translate *ngIf="showActions">
				practice.actions
			</h4>
			<div class="app-dm-card-grid-item">
				<a class="app-dm-card-item" *ngIf="isToolEnable('actionPlan')" routerLink="/app/practice/action-plan">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" alt="plug" src="./assets/img/My-Action-Plan 3.svg"
							alt="My Action Plan" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.actionPlan.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.actionPlan.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-right"></svg-icon>
						</button>
					</div>
				</a>
				<a class="app-dm-card-item" *ngIf="isToolEnable('activityscheduler')"
					routerLink="/app/practice/activityscheduler">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" alt="plug" src="./assets/img/Rectangle 4350 (3).png"
							alt="Activity Scheduler" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.as.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.as.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-right"></svg-icon>
						</button>
					</div>
				</a>
				<a class="app-dm-card-item" *ngIf="isToolEnable('funachievement')"
					routerLink="/app/practice/funachievement">
					<div class="app-dm-card-item__image-wrap">
						<img aria-hidden="true" alt="plug" src="./assets/img/Rectangle 4350 (1).png"
							alt="Fun Achievement" />
					</div>
					<div class="app-card-padding">
						<h5 class="app-dm-card-item__heading" translate>
							practice.fa.title
						</h5>
						<p class="app-dm-card-item__desc" translate>
							practice.fa.body
						</p>
						<button class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
							<span>View More</span>
							<svg-icon icon="angle-rightsas"></svg-icon>
						</button>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
