<main>
    <div id="public-portal-wrapper" class="container-fluid">

        <div class="row">

            <div class="left-col" [ngClass]="{'col-xs-12 col-md-8': showRegister, 'full-width': !showRegister}">
                <div class="inside">
                    <nav *ngIf="showLanguage || showLogo">
                        <ul>
                            <li class="logo" *ngIf="showLogo">
                                <a tabindex="0" routerLink="/" alt="App page" title="App page">
                                    <img alt="Welltrack logo" src="./assets/img/welltrack_color.png"
                                        alt="WellTrack Boost">
                                </a>
                            </li>
                            <li class="visible-sm">
                                <a tabindex="0" title="Visit welltrack-boost.com" alt="Visit Welltrack.com"
                                    href="http://welltrack-boost.com" translate>loginDesktop.full</a>
                            </li>
                            <li class="visible-sm">
                                <a tabindex="0" title="Contact us" alt="Contact us"
                                    href="mailto:support@welltrack-boost.com" translate>loginDesktop.contact</a>
                            </li>
                            <li id="languagepicker" *ngIf="showLanguage">
                                <language-dropdown [color]="'grey'" [page]="'public'"></language-dropdown>
                            </li>
                        </ul>
                    </nav>

                    <router-outlet (click)="onNavClose()" (keypress)="onNavClose()"></router-outlet>
                </div>
            </div>
            <!--left-col-->

            <div *ngIf="showRegister" class="right-col"
                [ngClass]="{'col-xs-12 col-md-4': showRegister, 'col-xs-12': !showRegister}">
                <register-form [title]="title" [description]="description"></register-form>
            </div>
            <!--right-col-->

        </div>
    </div>
</main>
<footer class="bottom-container" *ngIf="showBottom">
    <div class="download-holder">
        <img class="screen-cap" alt="Mobile app" src="/assets/img/moodcheck_phone@2x.png">
        <div class="mobile-download">
            <h3 translate>loginDesktop.download</h3>
            <a tabindex="0" *ngIf="playStore" target="_blank" class="wt-auto-google-store google"
                href="{{playStore}}">
                <img alt="Get it on Google Play" src="/assets/img/icons/google-play-badge@2.png" />
            </a>
            <a tabindex="0" *ngIf="appStore" target="_blank" class="wt-auto-apple-store apple" href="{{appStore}}">
                <img alt="Download on the App Store"
                    src="/assets/img/icons/Download_on_the_App_Store_Badge_US-UK_564x167@2.png">
            </a>
        </div>
    </div>
    <div class="info-holder">
        <div>&copy; {{getDate()}} Welltrack Boost</div>
        <a aria-label="HITRUST Alliance" class="mt-3" href="https://hitrustalliance.net/" target="_blank">
            <img src="./assets/img/icons-svg/hitrust.svg">
        </a>
    </div>

</footer>