<div class="page-static public-portal-right-col" style="overflow-y: scroll; height: 100%;">
    <div class="center form-screen">
        <div class="img-wrapper">
            <img alt="Welltrack" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
        </div>

        <div class="title-wrapper">
            <h2>{{title}}</h2>
            <div style="text-align: center;">
                <p>
                    {{message}}
                </p>
            </div>
        </div>
    </div>
</div>