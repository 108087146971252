import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dashboard-super-admin',
  templateUrl: './dashboard-super-admin.component.html',
  styleUrls: ['./dashboard-super-admin.component.scss']
})
export class DashboardSuperAdminComponent implements OnInit {

  constructor() {   }

  ngOnInit(): void {
   
  }

}
