<div>
    <h2 class="uo-contact" translate>adminUpdateOrg.updateOrganization</h2>
    <popup-loader *ngIf="!isLoaded"></popup-loader>
    <div *ngIf="isLoaded" class="setting-orgadmin">
        <div class="basic-info">
            <h5 class="center" translate>adminUpdateOrg.basicInformation</h5>
            <div class="app-l-form-wrapper">
                <div>
                    <label translate>adminUpdateOrg.selectACampus</label>
                    <mat-form-field class="wt-mat-form-field drp-down">
                        <mat-select
                            disableRipple
                            [(ngModel)]="organization"
                            (ngModelChange)="onChangeOrg($event)">
                            <mat-option
                                *ngFor="let org of organizations"
                                [selected]="organization.id === org.id"
                                [value]="org"
                                >{{ org.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <mat-form-field class="wt-mat-form-field">
                    <label translate>adminUpdateOrg.organizationName</label>
                    <input
                        matInput
                        class="wt-mat-input field-disabled"
                        aria-label="Full Name"
                        tabindex="0"
                        readonly
                        id="fullname"
                        type="text"
                        name="fullName"
                        [(ngModel)]="orgName" />
                </mat-form-field>
                <form
                    #f="ngForm"
                    class="org-edit-form"
                    [formGroup]="orgEditForm">
                    <div>
                        <label translate>adminUpdateOrg.address</label>
                        <input
                            #search
                            [placeholder]="'Type your address...'"
                            type="text"
                            formControlName="address" />
                        <div id="addressSuggestions"></div>
                    </div>
                    <div appearance="outline">
                        <label translate>adminUpdateOrg.phoneNumber</label>
                        <ngx-mat-intl-tel-input
                            placeholder="Enter Number"
                            formControlName="phone"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [enableSearch]="true"
                            name="phone"
                            #phoneInput>
                        </ngx-mat-intl-tel-input>
                        <mat-hint class="field-hint" translate
                            ><span translate> adminUpdateOrg.eg</span>:
                            {{
                                phoneInput.selectedCountry?.placeHolder
                            }}</mat-hint
                        >
                        <mat-error
                            class="field-error"
                            *ngIf="
                                orgEditForm.controls['phone'].touched &&
                                orgEditForm.controls['phone'].errors
                                    ?.validatePhoneNumber
                            "
                            translate
                            >adminUpdateOrg.invalidNumber</mat-error
                        >
                    </div>

                    <div class="wt-mat-form-field">
                        <label translate>adminUpdateOrg.website</label>
                        <input
                            matInput
                            class="wt-mat-input"
                            aria-label="Full Name"
                            formControlName="website"
                            tabindex="0"
                            type="text" />
                        <mat-error
                            class="field-error"
                            *ngIf="
                                orgEditForm.controls['website'].touched &&
                                !orgEditForm.controls['website'].valid
                            "
                            translate>
                            adminUpdateOrg.invalidUrl
                        </mat-error>
                    </div>
                </form>
            </div>
        </div>
        <div class="emergency-contact">
            <h3 translate>adminUpdateOrg.emergencyContactMessage</h3>
            <div class="emergency-body">
                <p
                    class="cont-intro"
                    [innerHtml]="
                        'adminUpdateOrg.emergencyContactDesc1' | translate
                    "
                    translate></p>
                <!-- <h5>Message Preview</h5> -->
                <div *ngIf="contactGroupTelephone" class="emergency-info">
                    <h2>{{ organization.contactGroup.title }}</h2>
                    <p
                        [translate]="
                            organization?.contactGroup?.description
                                ? organization.contactGroup.description
                                : 'contact.profesionals'
                        "></p>
                    <button
                        class="app-c-btn app-c-btn--secondary"
                        (keypress)="
                            onCallContact($event, organization.contactGroup)
                        "
                        (click)="
                            onCallContact($event, organization.contactGroup)
                        "
                        translate>
                        contact.getHelpNow
                    </button>
                    <!-- <mat-form-field class="wt-mat-form-field" appearance="None">
                        <input matInput class="wt-mat-input" aria-label="Full Name" readonly [(ngModel)]="emergencyContact" tabindex="0" type="textarea" />
                    </mat-form-field> -->
                    <div class="emergency-contact-footer emergency-main">
                        <span
                            ><span translate> adminUpdateOrg.call</span>
                            :<abbr>{{ contactGroupTelephone }}</abbr></span
                        >
                    </div>
                </div>

                <div *ngIf="emergencyContact" class="emergency-info">
                    <h2 translate>contact.need</h2>
                    <div [innerHTML]="emergencyContact"></div>
                </div>

                <div *ngIf="showOther" class="emergency-info">
                    <h2 translate>contact.need</h2>
                    <p translate>contact.profesionals</p>
                    <button
                        class="app-c-btn app-c-btn--secondary"
                        (click)="onHotline($event)"
                        (keypress)="onHotline($event)"
                        translate>
                        contact.getHelpNow
                    </button>
                    <div class="emergency-contact-footer emergency-main">
                        <span>
                            <span translate> adminUpdateOrg.call</span>
                            :<abbr>{{ hotline.title }}</abbr></span
                        >
                    </div>
                </div>
            </div>
            <div class="rm-info">
                <div>
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.0003 29.3334C23.3337 29.3334 29.3337 23.3334 29.3337 16.0001C29.3337 8.66675 23.3337 2.66675 16.0003 2.66675C8.66699 2.66675 2.66699 8.66675 2.66699 16.0001C2.66699 23.3334 8.66699 29.3334 16.0003 29.3334Z"
                            stroke="#0F1827"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M16 10.6667V17.3334"
                            stroke="#0F1827"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path
                            d="M15.9922 21.3333H16.0042"
                            stroke="#0F1827"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </div>
                <div>
                    <p
                        [innerHtml]="
                            'adminUpdateOrg.emergencyContactDesc2' | translate
                        "
                        translate></p>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-down">
        <div class="page-section-buttons">
            <a
                *ngIf="isLoaded"
                tabindex="0"
                [ngClass]="{ 'btn-disabled': !isSaved }"
                (click)="onUpdateMain()"
                (keypress)="onUpdateMain()"
                class="btn btn-bold"
                translate>
                adminUpdateOrg.saveOrganization
            </a>
        </div>
    </div>
</div>
