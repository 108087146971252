import {
	Component,
	OnInit,
	AfterViewInit,
	Input,
	ViewChild,
	ChangeDetectorRef,
} from "@angular/core";
import { ApiService } from "../../lib/api.service";
import { StorageService } from "../../lib/storage.service";
import { LogService } from "../../lib/log.service";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { ModalService } from "../../lib/modal.service";
import { TranslateService } from "@ngx-translate/core";
import { Assessment } from "../../models/assessment";
import { User } from "../../models/user";
import { UserService } from "../../lib/user.service";
import { AssessmentAlert } from "../../components/assessment-alert/assessment-alert";
import * as _ from "lodash";
import { AnalyticEvent } from "app/lib/analytic-event";
import { UrlService } from "../../lib/url.service";
import {
	CrisisHotline,
	LocationService,
} from "../../../app/lib/location.service";
import { Organization } from "../../../app/models/organization";
import { Chart, ChartData } from "chart.js";
declare var jQuery: any;
import * as moment from "moment";

@Component({
	selector: "das-assessment-listing",
	templateUrl: "das-assessment-listing.component.html",
	styleUrls: ["./das-assessment-listing.component.scss"],
})
export class DasAssessmentListingComponent implements OnInit {
	@ViewChild("dasAssessmentChart") canvasRef;
	title: string;
	back: string;
	alerts: string;
	loggedInUser: User;
	@Input() user: User;
	@Input() assessments: Assessment[];
	showPtsd: boolean = false;
	isLoaded: boolean = false;
	searchText;
	data: number = 1;
	gethelpbutton: boolean = false;
	previousUrl: string = null;
	currentUrl: string = null;

	hotline: CrisisHotline;
	dialing: boolean;
	popupText: any;
	emergencyContact: string;
	organization: Organization;
	isloaded: boolean = false;
	contactGroupTelephone: string;
	showOther: boolean;

	dasAssessmentChart: Chart;
	chartData: ChartData;
	dateFilter: any = 30;
	dateFilterTitle: any = "last30days";
	dateFilterOptions = [
		{
			key: "last30days",
			value: 30,
			isSelected: true,
		},
		{
			key: "last3Months",
			value: 90,
			isSelected: false,
		},
		{
			key: "last6Months",
			value: 180,
			isSelected: false,
		},
	];
	selectedChartLegend = "all";
	constructor(
		private api: ApiService,
		private log: LogService,
		private storage: StorageService,
		private translate: TranslateService,
		private modalService: ModalService,
		private userService: UserService,
		private urlService: UrlService,
		private locationService: LocationService,
		private router: Router
	) {
		this.loggedInUser = this.userService.getUser();
		this.dialing = false;
		this.urlService.setCurrentUrl(this.router.url);
		this.urlService.setCurrentUrlCourse(null);
	}

	ngOnInit() {
		this.translate.stream("das").subscribe((res: any) => {
			this.title = res.title;
			this.back = res.back;
		});

		if (this.user) {
			this.translate.stream("lang").subscribe((res: any) => {
				this.showHelpNow(this.assessments);
				setTimeout(() => {
					this.initDasAssessmentChart();
					this.updateChart();
				}, 3000);
				this.isLoaded = true;
			});
		} else {
			this.user = this.userService.getUser();
			this.getAssessmentList();
		}

		this.translate.get("contact").subscribe((res: any) => {
			this.popupText = res;
		});
		this.locationService.getLocation().subscribe((data: any) => {
			this.log.debug(data);
			this.hotline = this.locationService.getHelpline(
				data.country,
				data.regionName
			);
		});

		let orgId;
		if (this.storage.get("orgselect")) {
			orgId = this.storage.get("orgselect");
		} else {
			if (this.user.primaryOrganization) {
				orgId = this.user.primaryOrganization.id;
			}
		}

		if (orgId) {
			this.api.get("organizations/" + orgId).subscribe(
				(result: any) => {
					this.organization = new Organization(result.data);
					if (this.organization.contactGroup.telephone) {
						this.contactGroupTelephone =
							this.organization.contactGroup.telephone;
					} else {
						this.showOther = true;
					}
				},
				(error: any) => {
					this.log.error(
						"Error getting organization. " + error.message
					);
				},
				() => {
					this.isloaded = true;
				}
			);
		}
	}

	getAssessmentList() {
		this.translate.stream("lang").subscribe((res: any) => {
			/*french translation*/
			if (res === "en") {
				this.api.get("assessments", { UserID: this.user.id }).subscribe(
					(result: any) => {
						this.assessments = Assessment.initializeArray(
							result.data
						);
						this.showHelpNow(this.assessments);
						setTimeout(() => {
							this.initDasAssessmentChart();
							this.updateChart();
						}, 3000);
						this.isLoaded = true;
					},
					(error: any) => {
						this.log.error(
							"Error getting assessment questions. " +
								error.message
						);
						this.isLoaded = true;
					}
				);
			} else {
				this.api.get("assessments").subscribe(
					/*french translation*/
					(result: any) => {
						this.assessments = Assessment.initializeArray(
							result.data
						);
						this.showHelpNow(this.assessments);
						setTimeout(() => {
							this.initDasAssessmentChart();
							this.updateChart();
						}, 3000);
						this.isLoaded = true;
					},
					(error: any) => {
						this.log.error(
							"Error getting assessment questions. " +
								error.message
						);
						this.isLoaded = true;
					}
				);
			}
		});
	}

	onSelectFilterData(selectedItem: any) {
		this.dateFilter = selectedItem.value;
		this.dateFilterTitle = selectedItem.source.triggerValue;
		// this.dateFilterOptions.map((item) => {
		//   item["isSelected"] = item.key == selectedItem.value.key ? true : false;
		// });
		this.getAssessmentList();
	}

	initDasAssessmentChart() {
		if (this.assessments) {
			let canvas = this.canvasRef.nativeElement;
			let parent = canvas.parentElement;
			canvas.width = parent.clientWidth;
			canvas.height = parent.clientHeight;
			let chartOptions = {
				layout: {
					padding: {
						left: 10,
						right: 10,
						top: 0,
						bottom: 0,
					},
				},
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: true,
								lineWidth: 0,
								zeroLineWidth: 1,
								zeroLineBorderDash: [5, 5],
								zeroLineBorderDashOffset: 0.0,
							},
							type: "time",
							time: {
								unit: "day" as const,
								//   displayFormats: {
								//     day: "MMM D",
								//   },
								min: moment(new Date())
									.subtract(this.dateFilter, "days")
									.startOf("day")
									.format(),
								max: moment(new Date()).format(),
								stepSize: 10,
							},
							ticks: {
								callback: (value, index) =>
									this.getXAxisLabel(value),
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								drawBorder: false,
								lineWidth: 1,
							},
							ticks: {
								fontSize: 10,
								max: 4.5,
								min: 0,
								stepSize: 1,
								callback: (value, index) =>
									this.getTranslatedLabelName(value, index),
							},
						},
					],
				},
			};
			this.chartData = {};
			let ctx = canvas.getContext("2d");
			this.dasAssessmentChart = new Chart(ctx, {
				type: "line",
				data: this.chartData,
				options: chartOptions,
			});
		}
	}

	getXAxisLabel(value) {
		return moment(new Date(value)).format(
			this.dateFilter <= 30 ? "MMM D" : "MMM"
		);
	}

	getChartData() {
		if (this.assessments) {
			let depressionData = [];
			let anxityData = [];
			let stressData = [];
			let dataset: any = [];
			this.assessments.forEach((assessment: any) => {
				depressionData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.depressionlevel,
				});
				anxityData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.anxietylevel,
				});
				stressData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.stresslevel,
				});
			});

			(this.selectedChartLegend == "depression" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Depression",
					data: depressionData,
					pointRadius: 1.5,
					borderWidth: 2,
					fill: true,
					lineTension: 0,
					backgroundColor: this.generateGradient("rgb(5, 29, 244)"),
					backgroundImage: "./assets/img/welltrack-boost-circle.png",
					borderColor: "rgb(5, 29, 244)",
				});
			(this.selectedChartLegend == "anxity" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Anxiety",
					data: anxityData,
					pointRadius: 1.5,
					borderWidth: 2,
					fill: true,
					lineTension: 0,
					borderColor: "rgb(195, 22, 255)",
					backgroundColor: this.generateGradient("rgb(195, 22, 255)"),
				});
			(this.selectedChartLegend == "stress" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Stress",
					data: stressData,
					pointRadius: 1.5,
					borderWidth: 2,
					fill: true,
					lineTension: 0,
					borderColor: "rgb(255, 83, 10)",
					backgroundColor: this.generateGradient("rgb(255, 83, 10)"),
				});
			return {
				labels: [],
				datasets: dataset,
			};
		} else {
			return {};
		}
	}

	private generateGradient(color: string): CanvasGradient {
		const ctx = this.canvasRef.nativeElement.getContext("2d");
		const gradient = ctx.createLinearGradient(0, 0, 0, 250);
		gradient.addColorStop(1, `rgba(${color.slice(4, -1)}, 0)`);
		gradient.addColorStop(0, `rgba(${color.slice(4, -1)}, 0.3)`);
		return gradient;
	}

	getTranslatedLabelName = (value: string, index: number) => {
		const translationKey = "das.resultCategory." + value;
		return Number(value) <= 4
			? this.translate.instant(translationKey)
			: " ";
	};

	updateChart() {
		this.dasAssessmentChart.data = this.getChartData();
		this.dasAssessmentChart.options.scales.xAxes[0].time.unit =
			this.dateFilter <= 30 ? "day" : "month";
		this.dasAssessmentChart.options.scales.xAxes[0].time.min =
			this.dateFilter <= 30
				? moment(new Date())
						.subtract(this.dateFilter, "days")
						.startOf("day")
						.format()
				: moment(new Date())
						.subtract(this.dateFilter / 30, "months")
						.startOf("day")
						.format();

		this.dasAssessmentChart.options.scales.xAxes[0].time.stepSize =
			this.dateFilter <= 30 ? 10 : 1;
		this.dasAssessmentChart.update();
	}

	showHelpNow(assessment) {
		assessment.find((assess, val) => {
			if (
				assess.depressionlevel >= 3 ||
				assess.anxietylevel >= 3 ||
				assess.stresslevel >= 3
			) {
				this.gethelpbutton = true;
			}
		});
	}

	onCallContact(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();
			const hotLineText =
				this.user.primaryOrganization &&
				this.user.primaryOrganization.settings["assessment"] ===
					"resilience"
					? this.popupText.popupEmergencyTextVeteran
					: this.organization.contactGroup.getHelpDescription
					? this.organization.contactGroup.getHelpDescription
					: this.popupText.popupEmergencyHotline;

			this.modalService
				.showConfirmation(this.popupText.popTitle, hotLineText)
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.log.event(
							AnalyticEvent.event
								.protocallNumberCalled
						);

						this.api
							.post("analytics/supportlineclick", {
								phoneNumber: this.contactGroupTelephone,
								contactGroupId:
									this.organization.contactGroup.id,
							})
							.subscribe(
								(result: any) => {
									this.log.debug("Activity logged");
								},
								(error: any) => {
									this.log.debug(
										"Something went wrong with the activity logger."
									);
								}
							);

						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent("click"));
					}
				});
		}
	}

	onHotline(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();

			this.modalService
				.showConfirmation(
					this.popupText.popTitle,
					this.popupText.popUpHotline
				)
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.log.event(
							AnalyticEvent.event.hotlineCalled
						);
						/*
					this.api.post('analytics/supportlineclick', { phoneNumber: this.contactGroupTelephone, contactGroupId: this.organization.contactGroup.id }).subscribe(
						(result: any) => {
							this.log.debug('Activity logged');
						},
						(error: any) => {
							this.log.debug('Something went wrong with the activity logger.');
						}
					);
					*/
						//Add analytics event for general hotline dialed
						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent("click"));
					}
				});
		}
	}

	showAlerts(i) {
		let assessment = this.assessments[i];
		this.modalService.showComponent(AssessmentAlert, assessment);
	}

	delete(userQuizId) {
		this.modalService
			.showConfirmation(
				"Delete",
				"Are you sure you want to delete your assessment?"
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.api.delete("assessment/" + userQuizId).subscribe(
						(result: any) => {
							let index = _.findIndex(this.assessments, {
								userQuizId: userQuizId,
							});
							this.assessments.splice(index, 1);
							this.log.event(
								AnalyticEvent.event
									.assessmentDeleted
							);
						},
						(error: any) => {
							this.log.error("Error deleting.");
						}
					);
				}
			});
	}

	selectLegend(item) {
		this.selectedChartLegend = item;
		this.updateChart();
	}
}
