import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { MoodcheckService } from 'app/lib/moodcheck.service';
import { UserService } from 'app/lib/user.service';
import { Moodcheck } from 'app/models/moodcheck';

@Component({
  selector: 'app-overall-detail-view',
  templateUrl: './overall-detail-view.component.html',
  styleUrls: ['./overall-detail-view.component.scss']
})
export class OverallDetailViewComponent implements OnInit {
  @Output() showbar = new EventEmitter<boolean>();
  categorizedMoodcheckData: any;
  selectedMoodcheckCategory: any;
  selectedMoodcheckCategoryData: any;
  totalCount: number = 0;
  categoryIndex: number = 0;
  categorizedMoodcheck: Moodcheck[];
  selectedMoodDetails: any = {
    activity: [],
    people: [],
    places: [],
  };
  moodDetailedData: any = [];
  selectedTab: string = "activities";
  moods = [
    { key: "fantastic", value: 0, iconIndex: 10 },
    { key: "veryHappy", value: 0, iconIndex: 9 },
    { key: "happy", value: 0, iconIndex: 8 },
    { key: "good", value: 0, iconIndex: 7 },
    { key: "fine", value: 0, iconIndex: 6 },
    { key: "ok", value: 0, iconIndex: 5 },
    { key: "notGreat", value: 0, iconIndex: 4 },
    { key: "bad", value: 0, iconIndex: 3 },
    { key: "veryBad", value: 0, iconIndex: 2 },
    { key: "awful", value: 0, iconIndex: 1 },
  ];

  slideOpts = {
    slidesPerView: "auto",
  };
  viewData: any;
  indexselected: any;
  user: any;

  constructor(private mcService: MoodcheckService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.generateMoodDetails();
  }

  overallDetailViewData(data:any,catagoryData:any){
    this.viewData = data;
    this.categorizedMoodcheck=catagoryData;
    this.indexselected = data.categoryIndex;
    this.selectedMoodcheckCategory = data.selectedMoodcheckCategory;
    let selectedMoodDetails = this.mcService.getMoodcheckSortedData(
      data.selectedMoodcheckCategoryData.data
    );
    this.totalCount = data.selectedMoodcheckCategoryData.data.length;
    this.moodDetailedData = selectedMoodDetails[this.selectedTab];
  }

  changeMoodcheckCategory(mood, itemIndex) {
    this.selectedMoodcheckCategory = mood;
    this.indexselected = itemIndex;
    this.selectedMoodcheckCategoryData = this.categorizedMoodcheck[mood];  
    this.viewData = {
      categorizedMoodcheckData: this.categorizedMoodcheck,
        selectedMoodcheckCategory: mood,
        selectedMoodcheckCategoryData: this.categorizedMoodcheck[mood],
        categoryIndex: itemIndex,
    }
    this.generateMoodDetails();
  }

  tabChange(tab: string) {
    this.selectedTab = tab;
    this.generateMoodDetails();
  }

  generateMoodDetails() {
    if(this.viewData){
      let selectedMoodDetails = this.mcService.getMoodcheckSortedData(
        this.viewData.selectedMoodcheckCategoryData.data
      );
      this.totalCount = this.viewData.selectedMoodcheckCategoryData.data.length;
      this.moodDetailedData = selectedMoodDetails[this.selectedTab];
    }
  }
}
