<page-header [headerText]="headerText" [backLink]="" [cssClass]='cssClass' [backText]=""></page-header>

<div class="page-section no-padding">
    <div class="page-section-content clearfix">
        <div class="card-container col-xs-12 col-sm-6">
            <figure class="card">
                <div class="title" translate>community.title</div>
                <div class="description" translate>
                   community.description
                </div>
                <div class="logo-wrapper">
                    <img alt="Logo" src="./assets/img/additional-resources/togetherall.svg">
                </div>
                <div class="actions center">
                 <!-- <a class="form-label" translate>TogetherAll SSO Link</a>-->
                    <button type="button" class="btn btn-primary"
                        (click)="goToResource(ssoLink)" translate>community.joinTheCommunity</button>
                </div>
            </figure>
        </div>
    </div>
</div>