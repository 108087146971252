import { config } from "../../environments/all";
import { UtilityService } from "../lib/utility.service";

export class Moodcheck {
	id: number;
	userId: number;
	people: string;
	place: string;
	activity: string;
	moods: string[];
	notes: string;
	value: number;
	emotion: string;
	created: Date;
	peoples: string[];

	public static initializeArray(objects: any): Moodcheck[] {
		let results: Moodcheck[] = [];

		for (let i = 0; i < objects.length; i++) {
			let mc = new Moodcheck(objects[i]);
			results.push(mc);
		}

		return results;
	}

	constructor(data?: any) {
		this.moods = [];
		this.peoples = [];
		if (data) {
			this.initialize(data);
		}
	}

	public initialize(data: any) {
		this.moods = [];
		this.peoples = [];
		this.id = data.ID || data.id;
		this.people = data.People || data.people;
		this.place = data.Place || data.place;
		this.activity = data.Activity || data.activity;
		this.notes = data.Notes || data.notes;
		this.value = data.Value || data.value || 0;
		this.emotion = data.ValueText || data.valueText;
		this.userId = data.UserID || data.userID;

		//moods
		let moods = data.Moods || data.moods;

		moods.forEach((element) => {
			if (typeof element === "string") {
				this.moods.push(element);
				return;
			}

			if (element.Mood) {
				this.moods.push(element.Mood);
			}
		});

		//date
		this.created = UtilityService.convertToDate(
			data.Created || data.created
		);
	}

	public forApi() {
		return {
			Value: this.value, //convert in app value to match the server format
			Moods: this.moods,
			Activity: this.activity,
			Place: this.place,
			Notes: this.notes,
			People: this.peoples,
		};
	}
}

export class WT_CONSTANTS {
	public static MOODCHECK_CATEGORY_CONFIG = {
		1: "awful",
		2: "veryBad",
		3: "bad",
		4: "notGreat",
		5: "ok",
		6: "fine",
		7: "good",
		8: "happy",
		9: "veryHappy",
		10: "fantastic",
	};

	public static MOODCHECK_DETAIL_CONFIG = {
		activity: [
			{
				key: ["Working", "Travail", "Laboral"],
				imgUrl: "../../../assets/img/dashboard/activity/Working.svg",
			},
			{
				key: ["Going to school", "Aller à l'école", "Ir a la escuela"],
				imgUrl: "../../../assets/img/dashboard/activity/Work-School.svg",
			},
			{
				key: ["Nothing", "Rien", "Nada"],
				imgUrl: "../../../assets/img/dashboard/activity/Nothing.svg",
			},
			{
				key: ["Going to work", "Aller au travail", "Yendo a trabajar"],
				imgUrl: "../../../assets/img/dashboard/activity/Working.svg",
			},
			{
				key: ["Walking", "En marchant", "Comiendo"],
				imgUrl: "../../../assets/img/dashboard/activity/Walking.svg",
			},
			{
				key: ["Eating", "En mangeant", "En mangeant"],
				imgUrl: "../../../assets/img/dashboard/activity/Eating.svg",
			},
			{
				key: [
					"Hanging with friends",
					"Sortir avec des copains",
					"Salir con amigos",
				],
				imgUrl: "../../../assets/img/dashboard/activity/Friends.svg",
			},
			{
				key: ["Exercising", "Exercice", "Ejercicio"],
				imgUrl: "../../../assets/img/dashboard/activity/Exercising.svg",
			},
			{
				key: [
					"Drinking alcohol",
					"Boire de l'alcool",
					"Bebiendo alcohol",
				],
				imgUrl: "../../../assets/img/dashboard/activity/Alcohol.svg",
			},
			{
				key: ["Studying", "En train d'étudier", "Estudiando"],
				imgUrl: "../../../assets/img/dashboard/activity/Studying.svg",
			},
			{
				key: [
					"Trying to sleep",
					"Essayer de dormir",
					"Intentando dormir",
				],
				imgUrl: "../../../assets/img/dashboard/activity/Sleeping.svg",
			},
			{
				key: ["Drinking coffee", "Buvant du café", "Bebiendo café"],
				imgUrl: "../../../assets/img/dashboard/activity/Plus.svg",
			},
			{
				key: [
					"Watching a movie",
					"Regarder un film",
					"Viendo una película",
				],
				imgUrl: "../../../assets/img/dashboard/activity/TV.svg",
			},
		],
		people: [
			{
				key: ["Teacher", "Prof", "Maestro"],
				imgUrl: "../../../assets/img/dashboard/people/Teacher.svg",
			},
			{
				key: ["Friend(s)", "Copains)", "Amigos"],
				imgUrl: "../../../assets/img/dashboard/people/Friend(s).svg",
			},
			{
				key: ["Family", "Famille", "Familia"],
				imgUrl: "../../../assets/img/dashboard/people/Family.svg",
			},
			{
				key: ["Lover", "Amoureux", "Amante"],
				imgUrl: "../../../assets/img/dashboard/people/Lover.svg",
			},
			{
				key: ["Father", "Père", "Padre"],
				imgUrl: "../../../assets/img/dashboard/people/Father.svg",
			},
			{
				key: ["No one", "Personne", "Nadie"],
				imgUrl: "../../../assets/img/dashboard/people/No One.svg",
			},
			{
				key: ["Colleague(s)", "Collègue(s)", "Colegas)"],
				imgUrl: "../../../assets/img/dashboard/people/Colleague.svg",
			},
			{
				key: ["Mother", "Mère", "Madre"],
				imgUrl: "../../../assets/img/dashboard/people/Mother.svg",
			},
			{
				key: ["Pet", "Animal de compagnie", "Mascota"],
				imgUrl: "../../../assets/img/dashboard/people/Pet.svg",
			},
			{
				key: [
					"Spouse/Partner",
					"Epouse/partenaire",
					"Cónyuge / pareja",
				],
				imgUrl: "../../../assets/img/dashboard/people/Spouse or Partner.svg",
			},
			{
				key: ["Child", "Enfant", "Niño"],
				imgUrl: "../../../assets/img/dashboard/people/Child.svg",
			},
			{
				key: ["Children", "Enfants", "Niños"],
				imgUrl: "../../../assets/img/dashboard/people/Children.svg",
			},
			{
				key: ["Stranger", "Étranger", "Extraño"],
				imgUrl: "../../../assets/img/dashboard/people/Stranger.svg",
			},
			{
				key: ["Boss", "Patron", "Jefe"],
				imgUrl: "../../../assets/img/dashboard/people/Boss.svg",
			},
		],

		place: [
			{
				key: ["Work", "Travail", "Trabajar"],
				imgUrl: "../../../assets/img/dashboard/place/Work.svg",
			},
			{
				key: ["School", "École", "Escuela"],
				imgUrl: "../../../assets/img/dashboard/place/School.svg",
			},
			{
				key: ["Home", "Accueil", "Hogar"],
				imgUrl: "../../../assets/img/dashboard/place/Home.svg",
			},
			{
				key: ["Outdoors", "En plein air", "Al aire libre"],
				imgUrl: "../../../assets/img/dashboard/place/Outdoor.svg",
			},
			{
				key: [
					"Public building",
					"EBâtiment public",
					"Edificio publico",
				],
				imgUrl: "../../../assets/img/dashboard/place/Public Building.svg",
			},
			{
				key: ["Vehicle", "Véhicule", "Vehículo"],
				imgUrl: "../../../assets/img/dashboard/place/Vehicle.svg",
			},
			{
				key: [
					"Someone's house",
					"La maison de quelqu'un",
					"La casa de alguien",
				],
				imgUrl: "../../../assets/img/dashboard/place/Someones House.svg",
			},
		],
	};
}
