import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { TranslateService } from '@ngx-translate/core';
import { Assessment } from '../../models/assessment';
import { User } from '../../models/user';
import { UserService } from '../../lib/user.service';
import { ModalService } from '../../lib/modal.service';
import { AssessmentAlert } from '../../components/assessment-alert/assessment-alert';
import { Invite } from '../../components/invite/invite';

@Component({
	selector: 'app-alerts-listing',
	templateUrl: './alerts-listing.component.html',
	styleUrls: ['./alerts-listing.component.scss']
})
export class AlertsListingComponent implements OnInit {
	assessments: Assessment[];
	user: User;
	loggedInUser: User;
	showPtsd: boolean = false;
	userShare: boolean = false;
	isLoaded: boolean = false;
	
	constructor(private api: ApiService,
		private log: LogService,
		private storage: StorageService,
		private translate: TranslateService,
		private userService: UserService,
		private modalService: ModalService) {
		this.user = this.userService.getUser();
		this.loggedInUser = this.userService.getUser();
	}

	ngOnInit(): void {
		this.api.get('das/listing').subscribe(
			(result: any) => {
				this.assessments = Assessment.initializeArray(result.data);
				// this.renderGraph();
				this.isLoaded = true;
			},
			(error: any) => {
				this.log.error('Error getting assessment questions. ' + error.message);
				this.isLoaded = true;
			},
		);
	}

	invite() {
		let inviteInfo = {
			'type': 'professional',
			'endpoint': 'userinvite',
			'forceShare': false,
			'orgId': this.user.primaryOrganization.id
		};
		this.modalService.showComponent(Invite, inviteInfo);
	}

	inviteShare() {
		let inviteInfo = {
			'type': 'professional',
			'endpoint': 'userinvite',
			'forceShare': true,
			'orgId': this.user.primaryOrganization.id
		};
		this.modalService.showComponent(Invite, inviteInfo);
	}

	showAlerts(i) {
		let assessment = this.assessments[i];
		this.modalService.showComponent(AssessmentAlert, assessment);
	}

	// delete(userQuizId) {
	// 	this.modalService.showConfirmation("Delete", "Are you sure you want to delete your assessment?").afterClosed().subscribe(result => {
	// 		if (result) {
	// 			this.api.delete('assessment/' + userQuizId).subscribe(
	// 				(result: any) => {
	// 					let index = _.findIndex(this.assessments, { userQuizId: userQuizId });
	// 					this.assessments.splice(index, 1);
	// 				},
	// 				(error: any) => {
	// 					this.log.error('Error deleting.');
	// 				}
	// 			);
	// 		}
	// 	});
	// }
}
