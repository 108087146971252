<div id="page-confirm" class="public-portal-right-col">
    <h1 id="section-title" class="wt-access">Confirm</h1>
    <div id="confirm-header">
        <img alt="Welltrack badge" src="./assets/img/welltrack-boost-circle.png" class="animated fadeInDown">
    </div>

    <div style="padding-bottom: 0;">
        <img alt="Welltrack secondary logo" id="text-logo" src="./assets/img/welltrack_gray@2x.png">
    </div>

    <div class="center padding" style="margin-top: 100px;">
        <strong class="font-xxl" *ngIf="title != ''">
            {{title}}
        </strong>
        <strong class="font-xxl">
            {{message}}
        </strong>

        <div class="" *ngIf="verified && application == 'web'">
            <p class="instructions" translate *ngIf="instructionsEnable">confirm.readylogin</p>
            <a autoFocus tabindex="0" class="btn btn-primary" routerLink="/" translate>confirm.login</a>
        </div>
    </div>

</div>