import { Component, Inject, OnInit } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';

@Component({
  selector: 'app-create-permission',
  templateUrl: './create-permission.component.html',
  styleUrls: ['./create-permission.component.scss']
})
export class CreatePermissionComponent implements OnInit {

  createPermissionForm: FormGroup;
  isloaded = false;
  lang:any;
  section: any;
  isEditMode:boolean= false;
  isSubmitted:boolean= false;
  constructor(
    public dialogRef: MatDialogRef<CreatePermissionComponent>,
	  private formBuilder: FormBuilder,
    private api: ApiService,
    private log: LogService,
    private modalService: ModalService,
    private translate: TranslateService,
    public storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.section = this.data.data.sections;
      this.lang = this.data.data.language;

      this.createPermissionForm = this.formBuilder.group({
        PermissionName: ["", [Validators.required]],
        Permission: ["", [Validators.required]]
      });

    }

  ngOnInit(){

  }

  /**
   * Save Permission
   */
   doSave(){
     this.isSubmitted = true;
     if(this.createPermissionForm.valid){
     this.api.post('savepermission',this.createPermissionForm.value).subscribe(
       (results: any) => {
         this.modalService.showAlert('Success', 'Permission succesfully created');
          this.log.event(AnalyticEvent.event.systemPermissionAdded);
         this.dialogRef.close(results);
       },
       (error: any) => {
         this.log.error('Error loading. ' + error.message);
         this.isloaded = false;
       }
     );
     }
   }


   onClose() {
		this.dialogRef.close();
	}


}
