<div class="form-modal">
    <mat-dialog-content>

        <!-- <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
          aria-label="Close consent form">
          <img src="./assets/img/icons-svg/close-icon.svg">
      </a> -->
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1>Share Assessment Alerts with Counselors</h1>
            </div>
        </div>
        <!-- <popup-loader *ngIf="isloaded"></popup-loader> -->
        <div class="form-wrapper">
            <p>I give permission for Welltrack to share information I have provided on the Welltrack tool to my school's
                Counseling Center.
                I understand that by sharing my information, I may be contacted by my school's Counseling Center to
                explore my needs further.
                I understand that I may revoke this permission at any time by going to the user settings page and
                choosing to remove this permission.</p>

            <p>
                <!--
                    <mat-checkbox class="wt-auto-login-skip" (change)="showOptions($event)" >
                        <span translate>consent.confirm</span>
                    </mat-checkbox>
                -->
                <mat-form-field>
                    <mat-select name="surveyconfirm" [(ngModel)]="surveyconfirm" (change)="showOptions($event)">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </p>
            <button type="submit" tabindex="0" class="btn-primary btn-bold btn" [disabled]="!surveyconfirm"
                (click)="consentSave()">Submit</button>
        </div>
    </mat-dialog-content>
</div>