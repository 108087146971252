import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { Organization } from '../../models/organization';
import { UserService } from '../../lib/user.service';
import { ModalService } from '../../lib/modal.service';
import { ViewPrevalueAuditComponent } from 'app/components/view-prevalue-audit/view-prevalue-audit.component';


@Component({
  selector: 'app-audit-listing',
  templateUrl: './audit-listing.component.html',
  styleUrls: ['./audit-listing.component.scss']
})
export class AuditListingComponent implements OnInit {
  cols: Array<string> = ['', '', '', '', ''];
  selectedLanguage:any;
  allLang= [
    'en',
    'fr',
	'es'
  ]
  usersLoading: boolean = true;
  offset: number = 0;
  users: User[] = [];
  activeusers;
  filters={
    Section:"",
    Setting:"",
    Key:"",
    Value:""
  }
	EditedDataID: any;
	auditusers: any;
	actionlist: any;
	editvalueid: any;
	userid: any;
	isLoaded: boolean;
	userlist: any[];
	userlistvalue: string;
	errorhandling: any;
	loadMoreLoading: boolean = false;

  constructor(private api: ApiService,
		private storage: StorageService,
		private router: Router,
		private modalService: ModalService,
		private log: LogService,
		private translate: TranslateService,
		private userService: UserService) { }

  ngOnInit(): void {
	this.actionlist = [
		{action:'ViewUser',EditedDataID:'ViewUser',name:'View User'},
		{action:'EditUser',EditedDataID:'EditUser',name:'Edit User'},
		{action:'CreateOrg',EditedDataID:'CreateOrg',name:'Create Organization'},
		{action:'ViewOrg',EditedDataID:'ViewOrg',name:'View Organization'},
	    {action:'EditOrg',EditedDataID:'EditOrg',name:'Edit Organization'},
		{action:'EditCourse',EditedDataID:'EditCourse',name:'Edit Series'},
		{action:'CreateScheduledPushNotification',EditedDataID:'CreateScheduledPushNotification',name:'Create Scheduled Push Notification'},
		{action:'EditScheduledPushNotification',EditedDataID:'EditScheduledPushNotification',name:'Edit Scheduled Push Notification'},
		{action:'ApproveScheduledPushNotification',EditedDataID:'ApproveScheduledPushNotification',name:'Approve Scheduled Push Notification'},
	]
	this.loadMore();
	this.userList();
  }

    actionValue(editid){
		this.editvalueid = editid;
		this.loadMore();
    }

	// userChangeValue(user){
		// this.userid = user.id;
		// this.loadMore();
	// }

	valueChange(evt: string){
		evt = evt + "";
		if (!evt) {
			// this.userlistvalue = evt;
			// this.activeusers = this.userlist;
			this.userid = '';
			this.loadMore();
		}
		// else {
		//   this.activeusers = this.userlist.filter(item => (item.id + "") === evt || item.fullName.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
		// }
	}

	public valueMapper = (key) => {
		let selection = this.userlist?.find(e => {
		  return e.id == key;
		});
		if (selection) {
			this.userid = selection.id;
			this.loadMore();
		  return selection.fullName;
		}else{
			this.userid = '';
			this.loadMore();
		}
	  };

  loadMore() {
		this.usersLoading = true;
		this.offset = this.users.length;

		if (!this.userid || this.userlistvalue === ''){			
			this.userid = '';
		}
		if(!this.editvalueid || this.editvalueid === "") {
			this.editvalueid = '';
		}

		this.api.get('audit/listing', {
			Limit: 100,
			Offset: this.offset,
			User: this.userid,
			Action: this.editvalueid,
		}).subscribe(
			(results: any) => {
				this.auditusers = results;
				this.auditusers.forEach(audit=> {
					audit['ActionName'] = this.actionlist.find(list =>list.action ===  audit.Action)['name'];	
				});
				this.isLoaded = true;
				this.usersLoading = false;
			},
			(error: any) => {
				this.auditusers = [];
				this.errorhandling = error.message;
				this.modalService.showAlert(
					"Error",
					this.errorhandling
				);
				this.log.error('Error loading. ' + error.message);
				this.isLoaded = true;
				this.usersLoading = false;
				
			}
		);


	}

	userList() {
		this.usersLoading = true;
		this.offset = this.users.length;

		if (this.cols[0] && this.cols[1] && this.cols[2]) {

		}

		this.api.get('admin/listaudituserfilter', {
			Limit: 100,
			Offset: this.offset,
		}).subscribe(
			(results: any) => {
				let users = [];
				for (let user of results.data) {
					users.push(new User(user));
				}
				this.userlist = users;
				this.activeusers = users;
				this.isLoaded = true;
				this.usersLoading = false;
			},
			(error: any) => {

				this.log.error('Error loading. ' + error.message);
				this.isLoaded = true;
				this.usersLoading = false;
			}
		);
	}

	viewprevalue(id){
		const modal = this.modalService.showComponent(ViewPrevalueAuditComponent,{
		  aId: id,
		});
		modal.beforeClosed().subscribe((responseData: any) => {
		//   if (responseData){
		// 	this.allSections= [];   
		// 	this.configListing= {}
		// 	this.getAllMoodOptions();  
		//   }  
		});
	   }
 loadMoreList() {
	this.loadMoreLoading = true;
	this.offset = this.auditusers.length;
	this.api.get('audit/listing', {
		Limit: 100,
		Offset: this.offset,
		User: this.userid,
		Action: this.editvalueid,
	}).subscribe(
		(results: any) => {
			results.forEach(audit => {
				audit['ActionName'] = this.actionlist.find(list =>list.action ===  audit.Action)['name'];
				this.auditusers.push(audit);
			});
			this.loadMoreLoading = false;
		},
		(error: any) => {
			this.errorhandling = error.message;
			this.modalService.showAlert(
				"Error",
				this.errorhandling
			);
			this.log.error('Error loading. ' + error.message);
			this.loadMoreLoading = false;
		}
	);
 }

}
