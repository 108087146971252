import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../lib/modal.service';import { User } from '../../../app/models/user';
import { UserService } from '../../../app/lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-consent-page',
  templateUrl: './consent-page.component.html',
  styleUrls: ['./consent-page.component.scss']
})
export class ConsentPageComponent implements OnInit {
  user: User;
	returnPath: any;
	showEmptyHeader: boolean = false;
  surveyconfirm: boolean = false;
  enableAgree: boolean = false;
  showAssessment: boolean;

  constructor(private api: ApiService,
		private modalService: ModalService,
		private log: LogService,
		private storage: StorageService,
		private router: Router,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private translate: TranslateService,
		private route: ActivatedRoute,
		private location: Location) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.returnPath = queryParams['path'];
    });
    if(this.returnPath === "onboarding"){
      this.showEmptyHeader = true;
    }

    this.user = this.userService.getUser();
    this.showAssessment = this.user && this.user.forceAssessment;
  }

  showOptions(event) {
    this.enableAgree = true;
  }

  consentSave(value) {
    this.api.put('users/preferences', {
      Consent: value
    }).subscribe((result) => {
      if(result){
        this.user.preferences.consent = value;
        if (!this.showAssessment) {
          this.user.onboardingStep = 3;
          this.userService.setUser(this.user);
          this.router.navigate(["/app"]);
        } else if(this.returnPath === "onboarding"){
          this.user.onboardingStep = 2;
          this.userService.setUser(this.user);
          this.router.navigate(['/user-onboarding']);
        }
      }
    });
  }
}
