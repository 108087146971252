<div>
	<div class="moodCheck-header">
		<h3 translate>moodcheck.place.title</h3>
		<span translate>moodcheck.place.select</span>
	</div>
	<div class="app-l-mood-check__add">
		<div class="app-l-mood-check__add-block">
			<label
				[for]="0"
				tabindex="0"
				class="app-l-mood-check__add-label"
				(click)="onMore()"
				(keypress)="onMore()"
			>
				<svg-icon icon="plus"></svg-icon>
				<span translate>moodcheck.addOrEditPlace</span>
			</label>
		</div>
	</div>
	<div class="app-l-mood-check__options">
		<div
			class="app-l-mood-check__item"
			*ngFor="let option of getOptions(options);let index = index"
		>
			<div
				class="app-l-mood-check__block"
				*ngIf="option.show == '1' || option.custom === true"
			>
				<label
					[for]="option.ID"
					tabindex="{{index}}"
					class="box"
					[ngClass]="{'active': option.isSelected}"
					(click)="toggleOption(option)"
					(keypress)="toggleOption(option)"
				>
					<span class="app-l-mood-check__option-name"
						>{{option.name | truncate: 30}}</span
					>
					<div class="app-l-mood-check__select-icon">
						<!-- <img
                            alt="select"
                            class="selectIcon active-open"
                            [ngClass]="{'hide': option.isSelected}"
                            src="/assets/img/moodcheck/new/selected-icon.svg"

                            />
                            <img
                            alt="select"
                            class="selectIcon"
                            [ngClass]="{'active': option.isSelected}"
                            src="/assets/img/moodcheck/new/addcheck.svg"
                            /> -->
						<svg-icon
							icon="tick-circle"
							class="w-100 h-100"
						></svg-icon>
					</div>
				</label>
			</div>
		</div>
	</div>
</div>
