import { Component, OnInit, ViewEncapsulation, Input, Inject, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as _ from "lodash";
import { ApiService } from "app/lib/api.service";
import { ModalService } from "app/lib/modal.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "page-moodcheck-options",
	templateUrl: "moodcheck-options.html",
	styleUrls: ["./moodcheck-options.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class MoodcheckOptionsPage implements OnInit {
	//params: any = {};
	@Input() category: string;
	customOption: string;
	options: any;
	activeOptions: any;
	customOptionlist: Array<object> = [];
	defaultOptionlist: Array<object> = [];
	customOptionChangeList = {
		add: [],
		delete: [],
	};
	@Input() moreOptions;

	@Output() closeModal = new EventEmitter<boolean>();
	popups: any;
	saveConfirmation: any;
	optionChanged: boolean = false;

	constructor(
		private storage: StorageService,
		private log: LogService,
		// public dialogRef: MatDialogRef<MoodcheckOptionsPage>,
		private api: ApiService,
		private modalService: ModalService,
		private translateService: TranslateService // @Inject(MAT_DIALOG_DATA) public data: any
	) {
		// this.category = this.data.data.category;
		// this.moreOptions = this.data.data.moreOptions;

		this.options = <any>this.storage.get("moodcheckOptions", true) || {};
		//get category
		this.activeOptions = this.options[this.category];
	}

	onAddCustomOption() {
		this.log.trace('Add' + this.customOption);
		this.log.event(AnalyticEvent.event.moodCheckAddCustomOption, 'MoodCheck', { category: this.category });

		if (!this.customOption) {
			return;
		}

		const option = {
			name: this.customOption,
			custom: true,
			enabled: true,
			usedCount: 0,
			key: this.customOption,
		};

		this.customOptionlist.unshift(option);

		this.customOptionChangeList["add"].push({
			option: this.category,
			value: this.customOption,
		});
		this.optionChanged = true;
		delete this.customOption;
		this.sync();
	}

	hideDefaultOption(option) {
		this.optionChanged = true;
		option.enabled = !option.enabled;
		this.sync();
	}

	sync() {
		// this.options[this.category] = this.moreOptions;
		// this.log.debug('sync');
		// this.log.debug(this.options);
		// this.storage.set('moodcheckOptions', this.options, true);
	}

	ngOnInit() {
		this.filterOptions();
		this.log.event(AnalyticEvent.event.moodCheckAddCustomOption, 'MoodCheck', { category: this.category });
		this.translateService.get("moodcheck").subscribe((res: any) => {
			// this.placeholder = res.customOption;
			this.saveConfirmation = res.confirmation;
			this.popups = res.popups;
		});
	}
	filterOptions() {
		this.moreOptions.forEach((option) => {
			option.custom
				? this.customOptionlist.push({ ...option })
				: this.defaultOptionlist.push({ ...option });
		});
	}

	removeCustomOption(dataOption: any) {
		let itemIndex = _.findIndex(this.customOptionlist, {
			name: dataOption.name as any,
		});
		let addListindex = _.findIndex(this.customOptionChangeList.add, {
			value: dataOption.name as any,
		});
		if (addListindex < 0 && itemIndex >= 0) {
			this.customOptionChangeList["delete"].push(dataOption.id);
		}
		addListindex >= 0 &&
			this.customOptionChangeList.add.splice(addListindex, 1);
		this.customOptionlist.splice(itemIndex, 1);
		this.sync();
		this.optionChanged = true;
	}

	confirm() {
		this.modalService
			.showConfirmation(
				this.saveConfirmation.title,
				this.saveConfirmation.saveContent,
				this.saveConfirmation.ok,
				this.saveConfirmation.cancel
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.saveOptions();
					// this.dialogRef.close();
					this.closeModal.emit();
				}
			});
	}

	saveOptions() {
		let payloadData = {
			default: this.defaultOptionlist.map((option: any) => {
				return {
					ID: option.id,
					Show: option.enabled ? 1 : 0,
				};
			}),
			custom: this.customOptionChangeList,
		};
		this.api.post("moodcheck/addcustomoptions", payloadData).subscribe(
			(result: any) => {
				this.moreOptions = [
					...this.getCustomList(result, "custom"),
					...this.defaultOptionlist,
				];
				this.customOptionChangeList.add = [];
				this.customOptionChangeList.delete = [];
				this.modalService.showAlert(
					this.popups.success.header,
					this.popups.success.successBody
				);
				// this.dialogRef.close("response data");
				this.closeModal.emit();
			},
			(error: any) => {
				this.log.error("Error fetching user. " + error.message);
			}
		);
	}

	getCustomList(options: any, type: string) {
		let currentCustomList = this.customOptionlist.filter((obj) =>
			obj.hasOwnProperty("id")
		);
		var results = [];
		for (var i = 0; i < options.length; i++) {
			results.unshift({
				name: options[i].Value,
				key: type == "default" ? options[i].Key : options[i].Value,
				custom: type == "default" ? false : true,
				enabled: true,
				usedCount: 0,
				id: options[i].ID,
			});
		}
		return [...results, ...currentCustomList];
	}
	cancel() {
		// this.dialogRef.close();
		this.closeModal.emit();
	}
}
