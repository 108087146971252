<div class="page-static public-portal-right-col" style="overflow-y: scroll; height: 100%;">
    <div class="center form-screen">
        <div class="img-wrapper">
            <img alt="Welltrack" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
        </div>

        <div class="title-wrapper">
            <h2>CYBERPSYC CORPORATE SUBSCRIPTION AGREEMENT</h2>

            <p>CYBERPSYC SOFTWARE SOLUTIONS INC. ("<b>CYBERPSYC</b>"/"<b>WE</b>"/"<b>US</b>") PROVIDES ACCESS TO VIRTUAL
                REALITY EXPOSURE PROGRAMS (THE "<b>PROGRAMS</b>") AND SERVICES ON A SUBSCRIPTION BASIS VIA THE CYBERPSYC
                WEBSITE AT <span style="text-decoration: underline;">WWW.MYWELLTRACK.COM</span> (THE "<b>CYBERPSYC
                    SERVICE</b>"). THIS AGREEMENT APPLIES TO ALL CUSTOMERS WHO PURCHASE ACCESS TO THE PROGRAMS ON BEHALF
                OF ONE OR MORE USERS.</p>

            <p>IMPORTANT NOTICE: THIS AGREEMENT CONTAINS IMPORTANT LIMITATIONS ON REPRESENTATIONS, WARRANTIES, REMEDIES
                AND LIABILITIES SO YOU SHOULD READ IT CAREFULLY BEFORE SUBSCRIBING TO THE CYBERPSYC SERVICE. BY
                ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE OR BY EXECUTING A PURCHASE
                ORDER THAT REFERENCES THIS AGREEMENT, YOU AGREE TO THE TERMS HEREOF. IF YOU ARE AN AGENT OR EMPLOYEE OF
                AN ENTITY YOU REPRESENT AND WARRANT THAT (I) THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS DULY AUTHORIZED
                TO ACCEPT THIS AGREEMENT ON SUCH ENTITY'S BEHALF AND TO BIND SUCH ENTITY, AND (II) SUCH ENTITY HAS FULL
                POWER, CORPORATE OR OTHERWISE, TO ENTER INTO THIS AGREEMENT AND PERFORM ITS OBLIGATIONS HEREUNDER. BY
                USING THE SERVICES YOU INDICATE YOUR ACCEPTANCE OF THIS AGREEMENT. IF YOU DO NOT ACCEPT THESE TERMS,
                THEN DO NOT SUBSCRIBE TO USE THE CYBERPSYC SERVICE.</p>

            <div style="text-align: left;">
                <ol>
                    <li>
                        <b>DEFINITIONS:</b>

                        <p>(a) "<b>Cyberpsyc Service</b>" means the web-based, live streaming and video access to the
                            Programs as further described on the Website.</p>

                        <p>(b) "<b>End User</b>" means an individual within the class of persons described in the Quote
                            who is authorized by Customer to use the CyberPsyc Service, and who has created an account
                            on the Website for use of the CyberPsyc Service through Customer's account;</p>

                        <p>(c) "<b>Fees</b>" " means the subscription fees set out in the Quote which are payable by
                            Customer to CyberPsyc for the right to receive and provide access to the CyberPsyc Service
                            to End Users;</p>

                        <p>(d) "<b>Intellectual Property</b>" means legal rights in any invention, discovery,
                            improvement, utility model, patent, copyright, trademark, industrial design or mask work
                            right, and all rights of whatsoever nature in computer software and data, Confidential
                            Information, trade secrets or know-how and all intangible rights or privileges of a nature
                            similar to any of the foregoing, in every case in any part of the world whether or not
                            registered, and shall include all rights in any applications and granted registrations for
                            any of the foregoing rights;</p>

                        <p>(e) "<b>Quote</b>" means and includes an individual, a partnership, a joint venture, a
                            corporation, a limited liability company, a trust, a foundation, an unincorporated
                            organization, and any other type of legal entity; and
                        </p>

                        <p>(f) "<b>Person</b>" means and includes an individual, a partnership, a joint venture, a
                            corporation, a limited liability company, a trust, a foundation, an unincorporated
                            organization, and any other type of legal entity; and</p>

                        <p>(g) "<b>Term</b>" means the subscription period as further defined in Section 9.1.</p>
                    </li>
                    <li>
                        <b>ACCESS &amp; SERVICE TERMS:</b>

                        <p>2.1 <b>Licensed Use:</b> Conditioned on the terms and conditions of this Agreement and
                            payment of the Fees, CyberPsyc hereby grants to Customer a non-exclusive, non-transferable,
                            internal license to use and access the CyberPsyc Service during the Term and to make the
                            same available to End Users. CyberPsyc will create a corporate account for Customer and
                            provide Customer with login details enabling Customer to invite End Users to use the
                            CyberPsyc Service by each End User creating his/her own personal account in accordance with
                            the End User License Agreement terms.</p>

                        <p>2.3 <b>Customer Account &amp; Content Responsibilities:</b> Customer shall:<p>
                                <ul>
                                    <li>(i) ensure that its End Users have read, understood and accepted the <a
                                            href="https://app.welltrack-boost.com/terms-and-conditions">End User License
                                            Agreement</a>
                                        before such End Users access and use the CyberPsyc Service;</li>

                                    <li>(ii) use commercially "best efforts" to prevent unauthorized access to, or use
                                        of, the CyberPsyc Service, and notify CyberPsyc promptly of any such
                                        unauthorized access or use; and</li>

                                    <li>(iii) comply with all applicable local, provincial, state, federal and foreign
                                        laws in using the CyberPsyc Service and providing access to the same to its End
                                        Users.</li>
                                </ul>

                                <p>2.4 <b> Use Restrictions:</b> Customer shall not (and shall not a low any Person to):
                                </p>
                                <ul>
                                    <li>(i) modify, translate, reverse engineer, decompile, disassemble, or create
                                        derivative works based on the Programs;</li>

                                    <li>(ii) circumvent any user limits or other timing or use restrictions that are
                                        built into the Programs;</li>

                                    <li>(iii) remove any proprietary notices, labels, or marks from the Programs;</li>

                                    <li>(iv) access the Programs in order to build a competitive product or service or
                                        copy any ideas, features, functions or graphics of the Programs;</li>

                                    <li>(v) use or promote the use of the Programs for use by persons who have extreme
                                        phobias unless providing such persons with medical supervision or monitoring
                                        while using the CyberPsyc Service;</li>

                                    <li>(vi) license, sublicense, sell, resell, rent, lease, transfer, assign,
                                        distribute, time share or otherwise commercially exploit or make the Programs
                                        available to any third party without CyberPsyc's prior written consent, </li>

                                    <li>(vii) interfere with or disrupt the integrity or performance of the CyberPsyc
                                        Service or the data contained therein;</li>

                                    <li>(viii) attempt to gain unauthorized access to the CyberPsyc Service or its
                                        related systems or networks; or</li>

                                    <li>(ix) use the CyberPsyc Service in a manner which would constitute a criminal
                                        offense or otherwise constitute a violation of any local, state, provincial,
                                        federal or international law or encourage conduct which would violate any such
                                        law.</li>
                                </ul>

                    </li>

                    <li>
                        <p><b>SUPPORT</b></p>

                            <p>Any support issues with the CyberPsyc Service which Customer cannot resolve should be
                                reported to CyberPsyc by contacting us at support@welltrack-boost.com. CyberPsyc will use
                                commercially reasonable efforts to make the CyberPsyc Service available during the Term
                                except for:</p>
                            <ul>
                                <li><strong>(a)</strong> planned downtime, or</li>
                                <li><strong>(b)</strong> any unavailability caused by circumstances beyond CyberPsyc's
                                    reasonable
                                    control,
                                    including without limitation, acts of God, acts of government, flood, fire,
                                    earthquakes,
                                    civil unrest, acts of terror, strikes or other labor problems, or Internet service
                                    provider
                                    failures or delays.</li>
                            </ul>
                    </li>
                    <li>
                        <p><b>PAYMENT TERMS:</b></p>

                        <p>5.1 <b>Fees:</b> In consideration for the receipt of access to the CyberPsyc Service during
                            the Term, Customer shall pay the Fees. Fees will be invoiced each month in advance. Customer
                            is responsible for maintaining complete and accurate billing and contact information with
                            CyberPsyc by updating its online account.</p>

                        <p>5.2 <b>Overdue Payments:</b> Any payment not received from Customer by the due date may
                            accrue, at CyberPsyc's discretion, late charges at the rate of 1.5% of the outstanding
                            balance per month or the maximum rate permitted by law, whichever is lower from the date
                            such payment was due until the date paid. Payments made by Customer shall first be applied
                            to interest on overdue amounts, and then to Taxes and then Fees.</p>

                        <p>5.3 <b>Taxes:</b> Unless otherwise stated, Fees do not include any local, state, provincial,
                            federal or foreign taxes, levies, duties or similar governmental assessments of any nature,
                            including value-added, goods and services, harmonized, use or withholding taxes
                            (collectively, "Taxes"). Customer is responsible for paying all Taxes associated with the
                            subscription services ordered pursuant to this Agreement, excluding taxes based on
                            CyberPsyc's net income or property.</p>

                        <p>5.4 <b>Suspension of Service:</b> If Customer's account is thirty (30) days or more overdue,
                            then in addition to any of its other rights or remedies, CyberPsyc shall have the right to
                            suspend access to the CyberPsyc Service without liability to Customer, until such amounts
                            are paid in full.

                        </p>
                    </li>
                    <li>
                        <p><b> PROPRIETARY RIGHTS:</b></p>

                        <p>6.1 <b> Copyright and Trademark Information:</b> The Website, the Programs, and the
                            information and materials that they contain, are the property of CyberPsyc and its
                            licensors, and are protected from unauthorized copying and dissemination by copyright law,
                            trademark law, international conventions and other intellectual property laws. All CyberPsyc
                            product names and logos are trademarks or registered trademarks of CyberPsyc Inc. Nothing
                            contained on this Website should be construed as granting, by implication, estoppel, or
                            otherwise, any license or right to use the Website or any materials displayed on the Website
                            or contained in the Programs, through the use of framing or otherwise, except:</p>

                        <p>(a) as expressly permitted by this Agreement; or</p>

                        <p>(b) with the prior written permission of CyberPsyc.</p>

                        <p><b>Copyright 2019 CyberPsyc Software Solutions Inc.</b></p>

                        <p>6.2 <b>Reservation of Rights:</b> The rights and licenses granted under this Agreement are
                            only as expressly set forth herein. CyberPsyc reserves all right, title and interest in and
                            to the Programs, including all related Intellectual Property. No other license or right is
                            or will be deemed to be granted, whether by implication, estoppel, inference or otherwise,
                            by or as a result of this Agreement or any conduct of either party under this Agreement.</p>

                        <p>6.3 <b>Suggestions:</b> CyberPsyc shall have a royalty-free, worldwide, transferable,
                            sublicenseable, irrevocable, perpetual, unrestricted license to use or incorporate into the
                            Programs and its products and services any suggestions, enhancement requests,
                            recommendations or other feedback provided by Customer or its End Users relating to the
                            CyberPsyc Service, specific Programs or the Website, and Customer shall obtain from such End
                            Users such license at the sole discretion of CyberPsyc.</p>
                    </li>

                    <li>
                        <p><b>CONFIDENTIALITY &amp; PRIVACY:</b></p>

                        <p>7.1 <b>Definition of Confidential Information</b>: As used herein, "Confidential Information"
                            means confidential and proprietary information of a party (the "Disclosing Party") disclosed
                            to the other party (the "Receiving Party"), whether orally or in writing, that is designated
                            as confidential or that reasonably should be understood to be confidential given the nature
                            of the information and the circumstances of disclosure, business and marketing plans,
                            technology and technical information, product designs, and business processes. Confidential
                            Information shall not include any information that:</p>
                        <ul>
                            <li>(i) is or becomes generally known to the public without breach of any obligation owed to
                                the Disclosing Party;</li>
                            <li>(ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party
                                without breach of any obligation owed to the Disclosing Party;</li>
                            <li>(iii) was independently developed by the Receiving Party without breach of any
                                obligation owed to the Disclosing Party; or</li>
                            <li>(iv) is received from a third party without breach of any obligation owed to the
                                Disclosing Party.</li>
                        </ul>
                        <p>7.2 <b>Confidentiality Obligations:</b> The Receiving Party shall not disclose or use any
                            Confidential Information of the Disclosing Party for any purpose outside the scope of this
                            Agreement, except with the Disclosing Party's prior written permission. Each party agrees to
                            protect the confidentiality of the Confidential Information of the other party in the same
                            manner that it protects the confidentiality of its own proprietary and confidential
                            information (but in no event using less than reasonable care).</p>

                        <p>7.3 <b>Compelled Disclosure:</b> If the Receiving Party is compelled by law to disclose
                            Confidential Information of the Disclosing Party, it shall provide the Disclosing Party with
                            prior notice of such compelled disclosure (to the extent legally permitted) and reasonable
                            assistance, at the Disclosing Party's cost, if the Disclosing Party wishes to contest the
                            disclosure.</p>

                        <p>7.4 <b>Remedies:</b>If the Receiving Party discloses or uses (or threatens to disclose or
                            use) any Confidential Information of the Disclosing Party in breach of confidentiality
                            protections contained in this Agreement, the Disclosing Party shall have the right, in
                            addition to any other remedies available to it, to seek injunctive relief to enjoin such
                            acts, it being specifically acknowledged by the parties that any other available remedies
                            may be inadequate.</p>

                        <p>7.5 <b>Privacy:</b> Each Party shall at all times comply with the privacy requirements of all
                            applicable laws in its collection, use and storage of any Personal Information of End Users.
                            For greater certainty, CyberPsyc's data policies are as set out in the <a
                                href="https://app.welltrack-boost.com/privacy-policy">Privacy Policy</a> and CyberPsyc does
                            not
                            collect any Personal Information unless necessary for provision of its services. Customer
                            will only be granted access to the Personal Information of End Users collected by CyberPsyc
                            through the CyberPsyc Service if Customer has the consent of relevant End Users to such
                            sharing. CyberPsyc is therefore under no obligation to provide such data without evidence
                            from Customer of End User consent. </p>

                        <p>In this Section "Personal Information" means personally identifiable information about an
                            individual, and/or shall have the same meaning as found in the laws of the Province of New
                            Brunswick.</p>
                    </li>

                    <li>
                        <p><b>WARRANTIES AND DISCLAIMERS:</b></p>

                        <p>8.1 <b>General Warranties:</b> Each party represents, warrants to the other party
                            that:</p>
                        <ul>
                            <li>(i) it is a corporation, duly organized, validly existing and in good standing under the
                                laws of its jurisdiction of incorporation;</li>

                            <li>(ii) it has the requisite power and authority and approvals to execute, deliver and
                                perform its obligations under this Agreement; and</li>

                            <li>(iii) ) the execution and delivery of this Agreement and the performance of its
                                obligations hereunder have been duly authorized by it and any necessary third parties.
                            </li>
                        </ul>
                        <p>8.2 <b> DISCLAIMER:</b> THE INFORMATION AND MATERIALS PROVIDED THROUGH THE CYBERPSYC SERVICE
                            AND WEBSITE ARE PROVIDED "AS IS." SPECIFICALLY, BUT WITHOUT LIMITATION, CYBERPSYC DOES NOT
                            REPRESENT, WARRANT OR OFFER ANY CONDITIONS THAT: <br>
                            <b>(I)</b> THE INFORMATION OR MATERIALS
                            PROVIDED ARE CORRECT, ACCURATE, RELIABLE OR COMPLETE;<br>
                            <b>(II)</b> THE FUNCTIONS CONTAINED ON
                            THIS WEBSITE WILL BE UNINTERRUPTED OR ERROR-FREE;<br>
                            <b>(III)</b> DEFECTS WILL BE CORRECTED, OR<br>
                            <b>(IV)</b> THIS WEBSITE OR THE SERVER(S) THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
                            OTHER HARMFUL COMPONENTS. CYBERPSYC SPECIFICALLY DISCLAIMS ALL REPRESENTATIONS, WARRANTIES
                            AND CONDITIONS, EITHER EXPRESS, IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR
                            OTHERWISE INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                            NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE
                            CYBERPSYC SERVICE.</p>
                    </li>

                    <li>
                        <p><b>LIMITATION OF LIABILITY:</b></p>

                        <p>9.1 <b>INDIRECT DAMAGES:</b> UNDER NO CIRCUMSTANCES SHALL CYBERPSYC BE LIABLE FOR ANY
                            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER,
                            INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                            EXEMPLARY OR OTHER DAMAGES THAT RESULT FROM <b>(I)</b> CUSTOMER OR END USERS USE OF OR
                            INABILITY TO USE, THE WEBSITE OR CYBERPSYC SERVICE OR ANY OF THE INFORMATION OR MATERIALS IN
                            THE PROGRAMS, <b>(II)</b> THE COST OF PROCUREMENT OF SUBSTITUTE GOODS, DATA, INFORMATION OR
                            SERVICES, <b>(III)</b> ERRORS, MISTAKES, OR INACCURACIES IN THE MATERIALS ON THE WEBSITE,
                            <b>(IV)</b> PERSONAL INJURY OR PROPERTY DAMAGE OF ANY KIND WHATSOEVER ARISING FROM OR
                            RELATING TO USE OF THE CYBERPSYC SERVICE, ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER
                            FILES OR DATA THAT MAY BE HARMFUL TO COMPUTER OR COMMUNICATION EQUIPMENT OR DATA THAT MAY
                            HAVE BEEN TRANSMITTED TO OR THROUGH THE SERVICES, OR <b>(V)</b> PERSONAL INJURY OR PROPERTY
                            DAMAGE OF ANY KIND WHATSOEVER ARISING FROM OR RELATING TO USE OF THE CYBERPSYC SERVICE, ANY
                            BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER FILES OR DATA THAT MAY BE HARMFUL TO COMPUTER OR
                            COMMUNICATION EQUIPMENT OR DATA THAT MAY HAVE BEEN TRANSMITTED TO OR THROUGH THE SERVICES,
                            OR (V) ANY ERRORS OR OMISSIONS IN ANY MATERIAL ON THE WEBSITE OR ANY OTHER LOSS OR DAMAGE OF
                            ANY KIND ARISING FROM OR RELATING TO USE OF THE WEBSITE. THESE LIMITATIONS SHALL APPLY EVEN
                            IF CYBERPSYC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                        <p>9.2 <b> LIABILITY CAP:</b> IN NO EVENT SHALL CYBERPSYC'S AGGREGATE LIABILITY ARISING OUT OF
                            OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR UNDER ANY
                            OTHER THEORY OF LIABILITY, EXCEED THE AMOUNTS ACTUALLY PAID BY CUSTOMER HEREUNDER IN THE
                            TWELVE MONTHS PRECEDING THE INCIDENT GIVING RISE TO THE LIABILITY. THE FOREGOING LIMITATIONS
                            SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.</p>

                    </li>

                    <li>
                        <p><b>TERM AND TERMINATION:</b></p>

                        <p>10.1 <b>Subscription Term:</b> The terms of this Agreement will apply from the date of
                            payment
                            for a subscription term. Subscription terms are for an initial period of one (1) year
                            ("<b>Initial Term</b>") and will automatically renew for further one (1) year terms unless
                            Customer gives notice to terminate on the expiry date of the Initial Term, and in the case
                            of subsequent subscription terms, at least thirty (30) days in advance of the expiry of any
                            subsequent subscription term.</p>

                        <p>10.2 <b>Termination by CyberPsyc:</b> CyberPsyc may, under certain circumstances and without
                            prior notice, immediately terminate ability to access the CyberPsyc Service or portions
                            thereof. Cause for such termination shall include, but not be limited to:</p>
                        <ul>
                            <li>(a) breaches or violations of this Agreement by the Customer or an End User;</li>

                            <li>(b) requests by law enforcement or other government agencies;</li>

                            <li>(c) a request by an end user customer to terminate their own personal account;</li>

                            <li>(d) discontinuance or material modification to the Website (or any part thereof);</li>

                            <li>(e) unexpected technical, security or legal issues or problems, and/or</li>

                            <li>(f) participation by Customer or its End Users, directly or indirectly, in fraudulent or
                                illegal activities.</li>
                        </ul>

                        <p>You acknowledge and agree that all terminations may be made by CyberPsyc in its sole
                            discretion and that CyberPsyc shall not be liable to Customer or any third-party for any
                            termination of access to the CyberPsyc Service. Any termination of this Agreement by
                            CyberPsyc shall be in addition to any and a l other rights and remedies that CyberPsyc may
                            have.</p>

                        <p>10.3 <b>Outstanding Fees.</b> Termination or expiration shall not relieve Customer of the
                            obligation to pay any Fees, Taxes and Interest accrued or payable to CyberPsyc prior to the
                            effective date of termination or expiration.</p>

                        <p>10.4 <b>Surviving Provisions.</b> The following provisions shall survive any termination or
                            expiration of this Agreement: Sections 4-7, 8.3, 8.4 and 9.</p>
                    </li>
                    <li>
                        <p><b>GENERAL PROVISIONS:</b></p>

                        <p>11.1 <b>Relationship of the Parties:</b> The parties are independent contractors. This
                            Agreement does not create a partnership, franchise, joint venture, agency, fiduciary or
                            employment relationship between the parties. </p>

                        <p>11.2 <b>Publicity:</b> Neither party may issue press releases relating to this Agreement
                            without the other party's prior written consent. Each party may include the name and logo of
                            the other party in lists of customers or vendors in accordance with the other party's
                            standard guidelines.</p>

                        <p>11.3 <b>Notices:</b> All notices under this Agreement shall be in writing and shall be
                            deemed to have been given upon:</p>
                        <ul>
                            <li>(i) personal delivery;</li>

                            <li>(ii) the second business day after mailing;</li>

                            <li>(iii) the second business day after sending by confirmed facsimile; or</li>

                            <li>(iv) the second business day after sending by email.</li>
                        </ul>
                        <p>Notices to each party shall be to the designated person and the address indicated on the
                            Quote.</p>
                        <p>11.4<b> Enforcement of Terms:</b> : If any part of this Agreement is held to be unlawful,
                            void, or unenforceable, that part shall be deemed severable and shall not affect the
                            validity and enforceability of the remaining provisions. The failure of CyberPsyc to
                            exercise or enforce any right or provision under this Agreement shall not constitute a
                            waiver of such right or provision. Any waiver of any right or provision by CyberPsyc must be
                            in writing and shall only apply to the specific instance identified in such writing. No
                            waiver by CyberPsyc of any provision of this Agreement shall be deemed to be a further or
                            continuing waiver of such provision or any other provision.</p>

                        <p>11.5<b> Assignment:</b> Customer shall not transfer or assign any of its rights or
                            obligations under this Agreement, whether by operation of law or otherwise, without the
                            prior written consent of CyberPsyc. Any attempt to assign in breach of this section shall be
                            void and of no effect. Subject to the foregoing, this Agreement shall bind and inure to the
                            benefit of the parties, their respective successors and permitted assigns.</p>

                        <p>11.6<b> Governing Law</b><b>:</b> : This Agreement shall be governed by the laws of the
                            Province of New Brunswick, without regard to its conflict of law principles. The application
                            of the United Nations Convention on Contracts for the International Sale of Goods to this
                            Agreement is expressly excluded.</p>

                        <p>11.7<b> UPDATES:</b> THESE TERMS MAY BE AMENDED OR UPDATED BY CYBERPSYC FROM TIME TO TIME AND
                            THE MOST RECENT VERSION WILL BE POSTED ON THE WEBSITE. THE TERMS MAY HAVE CHANGED SINCE YOUR
                            LAST VISIT TO THIS WEBSITE, AND IT IS your RESPONSIBILITY TO REVIEW THIS AGREEMENT FOR ANY
                            CHANGES. USE OF THE CYBERPSYC SERVICE AFTER ANY AMENDMENTS OR UPDATES OF THIS AGREEMENT
                            SHALL SIGNIFY YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS. ANY NEW FEATURES THAT MAY
                            BE ADDED TO THE CYBERPSYC SERVICE OR SPECIFIC PROGRAMS FROM TIME TO TIME WILL BE SUBJECT TO
                            THIS AGREEMENT, UNLESS STATED OTHERWISE.</p>

                        <p>11.8<b> Entire Agreement</b><b>:</b> This Agreement together with the Quote constitutes the
                            entire agreement between the parties, and supersedes all prior and contemporaneous
                            agreements, proposals or representations, written or oral, concerning its subject matter.
                        </p>
                    </li>
                </ol>
                <b>Last update</b>: October 15th, 2019
            </div>
        </div>
    </div>
</div>