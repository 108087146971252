<div id="page-practice">
    <div class="col-md-12">
        <h2 id="section-title" translate>Assessments</h2>
    </div>
    <!--
        <div class="col-md-12 col-lg-6">
            <div class="panel assessment-wellness">
                <div class="right bgimage"> 
                    <img aria-hidden="true" alt="" src="./assets/img/assessment_icon@2x.png">
                </div>
                <div class="left">
                    <h3 translate>The Wellness Assessment</h3>
                    <p translate>Complete a quiz that rates your depression, anxiety and stress</p>
                    <a autoFocus tabindex="0" class="btn btn-primary" routerLink="das/listing"
                        translate>Take the Assessment</a>
                </div>

            </div>
        </div>
    -->

    <div class="col-md-12 col-lg-6">
        <div class="panel assessment-resilience">
            <div class="right bgimage">
                <img aria-hidden="true" alt="" src="./assets/img/icons/fun_achievement_icon@2x.png">
            </div>
            <div class="left">
                <h3 translate>The Resilience Assessment</h3>
                <p translate>Complete a quiz that assesses your resilience</p>
                <a autoFocus tabindex="0" class="btn btn-primary" routerLink="resilience/listing" translate>Assess your
                    resilience</a>
            </div>

        </div>
    </div>

</div>