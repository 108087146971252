import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UserService } from "../../../app/lib/user.service";
import { User } from "../../../app/models/user";
import { Organization } from "../../../app/models/organization";
import { ApiService } from "../../../app/lib/api.service";
import { LogService } from "../../lib/log.service";
import { StorageService } from "../../lib/storage.service";
import { ModalService } from "../../lib/modal.service";
import { MenuService } from "../../lib/menu.service";
import { UtilityService } from "../../lib/utility.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { AnalyticEvent } from "app/lib/analytic-event";
import { MatTabGroup } from "@angular/material/tabs";
import { MatDialog } from "@angular/material/dialog";
import { OrganizationAssociateComponent } from "app/pages/organization-associate/organization-associate.component";
import * as _ from "lodash";

@Component({
	selector: "app-mysetting",
	templateUrl: "./mysetting.component.html",
	styleUrls: ["./mysetting.component.scss"],
})
export class MysettingComponent implements OnInit {
	@ViewChild("tabGroup") tabGroup: MatTabGroup;
	watcher = new Subject();

	user: User;
	password: string = "";
	currentpassword: string = "";
	passwordConfirmation: string = "";
	emailAddress: string;
	avatarUrl: string;
	accessCode: string;
	reminders: boolean;
	popups: any;
	popupsEmail: any;
	organizations: Organization[] = [];
	orgId: any;
	disablePassword: boolean = false;
	activeOrg: boolean;
	emailChange: string;
	wellnesstrackerNotification: any;
	wellnessNotification: any;
	consent: any;
	storedUser: any;
	enableConsent: boolean;
	passwordType: string = "password";
	currentPasswordType: string = "password";
	passwordConfirmationType: string = "password";
	showOrgList: boolean = false;
	showAssocBtn: boolean = false;
	orgAssociation: boolean;
	showEditPassword: boolean = false;
	passwordFieldType: any = {
		passwordType: false,
		passwordConfirmationType: false,
		currentPasswordType: false,
	};
	passwordValidations = {
		lowerLetters: false,
		upperLetters: false,
		numbers: false,
		symbols: false,
		pwdlength: false,
		notIncludeWelltrack: false,
	};
	isPasswordValid = false;
	profileLogoName: string;
	profileEditView: boolean = false;
	fullName: string;
	surveyEditMode: boolean = false;
	accesscodePopups: any;
	showInitialBtn: boolean = true;

	constructor(
		private modalService: ModalService,
		private api: ApiService,
		private dialog: MatDialog,
		private storage: StorageService,
		private log: LogService,
		private elementRef: ElementRef,
		private userService: UserService,
		private utilityService: UtilityService,
		private menuservice: MenuService,
		private translate: TranslateService
	) {
		this.user = this.userService.getUser();
		this.avatarUrl = this.user.getAvatarUrl();
		this.reminders = this.user.preferences.notificationOn;
		this.wellnesstrackerNotification =
			this.user.preferences.wellnessTrackerNotification;
		this.wellnessNotification = this.user.preferences.wellnessNotifications;
		this.consent = this.user.preferences.consent? 1 : 0;
		this.storedUser = this.storage.get("user", true);
		this.setConfig();
	}

	ngOnInit(): void {
		this.translate.stream("setting.popups").subscribe((res: any) => {
			this.popups = res;
		});

		this.translate.stream("start.popups").subscribe((res: any) => {
			this.popupsEmail = res;
		});

		this.translate
			.get("accessCode.popups")
			.subscribe((res: any) => {
				this.accesscodePopups = res;
			});



		this.profileLogoName =
			this.user.firstName?.charAt(0) + this.user.lastName?.charAt(0);
	}

	editProfile() {
		this.profileEditView = true;
	}

	onSave() {
		//validate
		if (this.profileEditView && !this.showEditPassword) {
			if (!this.fullName || !this.emailChange) {
				this.modalService.showAlert(this.popups[0].title, this.popups[0].body);
				return;
			}

			if (!this.utilityService.validateEmail(this.emailChange)) {
				this.modalService.showAlert(this.popups[12].title, this.popups[12].body);
				return;
			}

			let params: any = {
				Name: this.fullName,
			};

			if (this.emailChange !== this.user.email) {
				params["Email"] = this.emailChange;
				this.saveUserProfileData(params, "email");
			} else {
				this.saveUserProfileData(params, "other");
			}
		} else if (this.showEditPassword) {
			if (!this.currentpassword) {
				this.modalService.showAlert(this.popups[14].title, this.popups[14].body);
				return;
			}

			if (!this.password) {
				this.modalService.showAlert(this.popups[13].title, this.popups[13].body);
				return;
			}

			if (this.password && this.password !== this.passwordConfirmation) {
				this.modalService.showAlert(this.popups[1].title, this.popups[1].body);
				return;
			}

			let params: any = {
				oldPassword: this.currentpassword,
				Password: this.password,
			};
			this.saveUserProfileData(params, "password");
			this.showEditPassword = false;
			this.password = "";
			this.passwordConfirmation = "";
			this.currentpassword = "";
			this.isPasswordValid = false;
			Object.keys(this.passwordValidations).forEach((key) => {
				this.passwordValidations[key] = false;
			});
			Object.keys(this.passwordFieldType).forEach((key) => {
				this.passwordFieldType[key] = false;
			});
		}
		if (this.utilityService.demoMode()) {
			return;
		}
	}

	saveUserProfileData(payLoad: any, type: string) {
		this.api.put("users", payLoad).subscribe(
			(data: any) => {
				//save user information to storage
				this.userService.setUser(this.user);

				if (type == "email") {
					this.log.event(AnalyticEvent.event.settingsUpdated);
					this.modalService.showAlert(
						this.popups[15].title,
						this.popups[15].body1,
						this.popups[15].body2,
					);
					this.log.event(AnalyticEvent.event.userEmailUpdated);
					this.profileEditView = false;
					this.showEditPassword = false;
				} else if (type == "password") {
					this.log.event(AnalyticEvent.event.userInfoUpdated);
					this.modalService.showAlert(
						this.popups[16].title,
						this.popups[16].body
					);
				} else {
					this.log.event(AnalyticEvent.event.userInfoUpdated);
					this.modalService.showAlert(
						this.popups[2].title,
						this.popups[2].body
					);
					this.profileEditView = false;
					this.showEditPassword = false;
				}
			},
			(error: any) => {
				this.modalService.showAlert(
					this.popups[7].title,
					error.message
				);
			}
		);
	}

	  selectNextTab(): void {
		this.tabGroup.selectedIndex = (this.tabGroup.selectedIndex + 1) % this.tabGroup._tabs.length;
	  }
	
	  selectPreviousTab(): void {
		this.tabGroup.selectedIndex = (this.tabGroup.selectedIndex - 1 + this.tabGroup._tabs.length) % this.tabGroup._tabs.length;
	  }

	onTabChanged($event) {
		let tabSelected = $event.tab.textLabel;
		if (tabSelected == "Basic Details") {
			this.showEditPassword = false;
			this.profileEditView = false;
		} else if (tabSelected == "Additional Details") {
			this.surveyEditMode = false;
		} else if (tabSelected == "Organizational Details") {
			this.showOrgList = false;
			if (this.user.primaryOrganization === null) {
				this.showAssocBtn = true;
				this.orgId = 'disconnect';
			} else {
				this.showAssocBtn = false;
			}
		}
	}

	onCancel() {
		this.profileEditView = false;
		this.showEditPassword = false;
	}

	editSurvey() {
		this.surveyEditMode = true;
	}

	focusCheckbox() {
        // Find the checkbox element in the DOM
        const checkboxElement: HTMLElement | null = this.elementRef.nativeElement.querySelector('#user-consent');
        // Set focus to the checkbox if it exists
        if (checkboxElement) {
            checkboxElement.focus();
        }
    }

	setConfig() {
		if (this.user.primaryOrganization && this.user.primaryOrganization.id) {
			this.orgId = this.user.primaryOrganization.id;
			this.disablePassword = this.user.primaryOrganization.enableSso;
		}

		if (this.user.primaryOrganization) {
			if (
				this.user.primaryOrganization.serviceType &&
				this.user.primaryOrganization.serviceType === 1 &&
				this.user.primaryOrganization.settings.enableAlerts
			) {
				this.enableConsent = true;
			}
			this.compileChildren();
			this.organizations.push(this.user.primaryOrganization);
		} else {
			this.enableConsent = false;
		}

		this.activeOrg =
			this.user.primaryOrganization !== null
				? this.user.primaryOrganization.active
				: false;
		if (this.user.primaryOrganization === null) {
			this.showAssocBtn = true;
			this.orgId = 'disconnect';
		}

		if (!this.emailChange) {
			this.emailChange = this.user.email;
		}
		this.emailChange = this.user.email;
		this.fullName = this.user.fullName;
	}

	compileChildren() {
		this.api.get("organizations/children", { includeParent: 0 }).subscribe(
			(result: any) => {
				this.organizations = Organization.initializeArray(result.data);
				this.orgId = this.user.primaryOrganization.id;
			},
			(error: any) => {
				this.log.error("Error getting organizations. " + error.message);
			},
			() => { }
		);
	}

	onClickPassword(field: string) {
		this.passwordFieldType[field] = !this.passwordFieldType[field];
	}

	onChangePassword() {
		this.passwordValidations = this.utilityService.passwordValidation(
			this.password
		);
		this.isPasswordValid = Object.values(this.passwordValidations).every(
			(value) => !!value
		);
	}

	forgetpassword() {
		this.profileEditView = false;
		this.showEditPassword = true;
	}

	onToggleReminders(key, value) {
		this.user.preferences[key] = value ? 1 : 0;
		this.storedUser["preferences"].key = value ? 1 : 0;
		key = key === "notificationOn" ? "NotificationOn" : key;
		this.setPreference(key, value);
	}

	setPreference(pref, value) {
		let params = {};
		params[pref] = value ? 1 : 0;

		if (this.utilityService.demoMode()) {
			return;
		}
		//sync changes to server
		this.api.put("users/preferences", params).subscribe(
			(result: any) => {
				this.modalService.showAlert(
					"Success",
					"Notification updated Succesfully"
				);
				//update stored user object with new preferences
				this.userService.setUser(this.user);
				this.consent = this.user.preferences.consent ? 1 : 0;
				//load the dashboard, and update user information
				this.log.event(
					AnalyticEvent.event.preferenceChange,
					"Settings",
					{ preference: pref }
				);
			},
			(error: any) => {
				this.log.error("Error updating preferences. " + error.message);
				this.modalService.showAlert(
					this.popups[7].title,
					this.popups[7].body
				);
			}
		);
	}

	onUnpair() {
		if (this.utilityService.demoMode()) {
			return;
		}
		//make call to server
		const modal = this.modalService.showConfirmation(
			this.accesscodePopups.confirmation,
			this.accesscodePopups.confirmDisconnectOrg
		);
		modal.beforeClosed().subscribe((responseData: any) => {
			if (responseData) {
				this.showOrgList = false;
				this.api.delete("users/removeorganizations").subscribe(
					(result: any) => {
						// this.compileChildren();
						this.showAssocBtn = true;
						this.showOrgList = false;
						this.api.get("users/me").subscribe((result: any) => {
							this.user = new User(result.data);
							this.userService.setUser(this.user);
							this.setConfig();
							this.log.event(
								AnalyticEvent.event.organizationRemoved
							);
							this.modalService.showAlert(
								this.popups[5].title,
								this.popups[5].body
							);
						});
					},
					(error: any) => {
						this.log.error("Error disconnecting. " + error.message);
						this.modalService.showAlert(
							this.popups[6].title,
							this.popups[6].body
						);
					}
				);
			} else {
				this.showOrgList = false;
				this.orgId = this.user.primaryOrganization.id;
			}
		});
	}

	showAssoModal() {
		this.orgAssociation = true;
		const modal = this.modalService.showComponent(
			OrganizationAssociateComponent,
			{ email: this.emailChange, show: this.orgAssociation },
			"access-modal-main"
		);

		modal.beforeClosed().subscribe((responseData: any) => {
			if (responseData) {
				this.api.get("users/me").subscribe((result: any) => {
					this.user = new User(result.data);
					this.userService.setUser(this.user);
					if(responseData.event === 'accesscode'){
						this.showAssocBtn = false;
						this.showOrgList = false;
						this.setConfig();
						setTimeout(() => {
							this.modalService.showAlert(
								this.accesscodePopups.congrats,
								this.accesscodePopups.pair + this.user?.primaryOrganization?.name
							);
						}, 2000);
					}else if(responseData.event === 'email'){
						this.showAssocBtn = false;
						this.showInitialBtn = false;
						this.showOrgList = false;
						this.compileChildren();
					}
				});
			}
		});
	}

	onChangeOrg(eveval, org) {
		if (this.utilityService.demoMode()) {
			return;
		}
		if (eveval == 'disconnect') {
			this.onUnpair();
		} else {
			if (this.orgId != this.user.primaryOrganization.id) {
				let tempOrg: Organization = _.find(this.organizations, {
					id: this.orgId as any,
				});
				let message = this.accesscodePopups.confirmChangeOrg
					.replace("$org1", this.user.primaryOrganization.name)
					.replace("$org2", tempOrg?.name);
				const modal = this.modalService.showConfirmation(
					this.accesscodePopups.confirmation, message
				);
				modal.beforeClosed().subscribe((responseData: any) => {
					if (responseData) {
						this.submitOrgChange();
					} else {
						this.showOrgList = false;
						this.orgId = this.user.primaryOrganization.id;
					}
				});
			} else {
				this.submitOrgChange();
			}
		}
	}

	submitOrgChange() {
		this.api.post("organizations/saveorg", { OrgID: this.orgId })
			.subscribe(
				(result: any) => {
					this.modalService.showAlert(
						this.popups[10].title,
						this.popups[10].body
					);
					this.api.get("users/me").subscribe((result: any) => {
						this.user = new User(result.data);
						this.userService.setUser(this.user);
						setTimeout(() => { this.setConfig(); }, 500);
					});
					this.showOrgList = false;
					this.log.event(AnalyticEvent.event.userAssociationChanged);
				},
				(error: any) => {
					this.log.error(
						"Error getting organizations. " + error.message
					);
				}
			);
	}

	ngAfterViewInit() {
		// Example: Reload the active tab after a delay (you can adjust the timing or trigger based on events)
		setTimeout(() => {
			const currentIndex = this.tabGroup.selectedIndex;
			this.tabGroup.selectedIndex = -1; // Set to an index that is not currently selected
			this.tabGroup.selectedIndex = currentIndex; // Set back to the original index
		}, 1000); // Adjust the delay as needed
	}

	changeAssociation() {
		this.showOrgList = true;
		const isInArray = this.organizations.some(org => org.id === this.orgId);
		if (!isInArray) {
			this.organizations.push(this.user.primaryOrganization);
		}

	}

	get menuService(): MenuService {
		return this.menuservice;
	}
}
