import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "confirm--popup",
	templateUrl: "./confirm-popup.html",
	styleUrls: ["./confirm-popup.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ConfirmPopup {
	title: string;
	message: string;
	okTxt: string;
	cancelTxt: string;
	helpLine: string;

	constructor(
		private translate: TranslateService,
		public dialogRef: MatDialogRef<ConfirmPopup>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.title = data.title;
		this.message = data.message;
		this.okTxt = data.okTxt;
		this.cancelTxt = data.cancelTxt;
		this.helpLine = data.helpLine;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onClose(value: boolean): void {
		this.dialogRef.close(value);
	}
}
