import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'legend-component',
    templateUrl: 'legend.component.html',
    styleUrls: ['./legend.component.scss'],
})

export class LegendComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }

}
