import { Injectable } from "@angular/core";
import { LogService } from "./log.service";
import { StorageService } from "./storage.service";
import { UtilityService } from "./utility.service";
import "rxjs/Rx";
import { Subject } from "rxjs";
import { Moodcheck, WT_CONSTANTS } from "../models/moodcheck";

import * as _ from "lodash";
import { ApiService } from "./api.service";
import { User } from "app/models/user";

@Injectable()
export class MoodcheckService {
	moodcheckCategoryConfig = WT_CONSTANTS.MOODCHECK_CATEGORY_CONFIG;
	updates = new Subject();
	refresh = new Subject();
	moodcheck: Moodcheck;
	mcCountWatcher = new Subject();

	constructor(
		private logService: LogService,
		private storage: StorageService,
		private api: ApiService,
		private utilService: UtilityService
	) {
		this.moodcheck = new Moodcheck();
	}

	triggerRefresh() {
		this.refresh.next();
	}

	setMoodValue(val) {
		this.logService.debug("Inside set mood value---", val);
		this.moodcheck.value = val;
		this.onUpdate();
	}

	addEmotion(val) {
		if (this.moodcheck.moods.indexOf(val) < 0) {
			this.moodcheck.moods.push(val);
		}
	}

	removeEmotion(val) {
		let index = this.moodcheck.moods.indexOf(val);

		if (index >= 0) {
			this.moodcheck.moods.splice(index, 1);
		}
	}

	setActivity(val) {
		this.moodcheck.activity = val;
		this.onUpdate();
	}

	setPeople(val) {
		this.moodcheck.people = val;
	}

	setPlace(val) {
		this.moodcheck.place = val;
	}

	setNote(val) {
		this.moodcheck.notes = val;
	}

	onUpdate() {
		let isValid = false;
		//check if moodcheck is valid and can be saved
		if (this.moodcheck.activity && this.moodcheck.value) {
			isValid = true;
		}

		this.updates.next(isValid);
	}

	getMoodcheck() {
		return this.moodcheck;
	}

	reset() {
		this.moodcheck = new Moodcheck();
	}

	lastDate(moodchecks: Moodcheck[]) {
		let lastMoodcheck = _.maxBy(moodchecks, function (o) {
			return o.created.getTime();
		});
		let lastDate = new Date(lastMoodcheck.created);
		return lastDate;
	}

	private normalizeData(moodchecks: Moodcheck[], to: Date) {
		let results = [];
		let lastDate = to;
		lastDate = new Date(
			lastDate.getFullYear(),
			lastDate.getMonth(),
			lastDate.getDate()
		);

		moodchecks.forEach((mc) => {
			let mcDate = new Date(
				mc.created.getFullYear(),
				mc.created.getMonth(),
				mc.created.getDate()
			);
			let daysFromLast = Math.ceil(
				Math.abs(lastDate.getTime() - mcDate.getTime()) /
					(1000 * 3600 * 24)
			);
			if (daysFromLast === 0) {
			}

			results.push({
				value: mc.value,
				day: 30 - daysFromLast,
				time: mc.created.getHours(),
				id: mc.id,
			});
		});

		return results;
	}

	getHeatmapData(moodchecks: Moodcheck[], mode = "all", to: Date) {
		let data = {
			datasets: [],
		};

		let moodValues = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]; //Good to Bad
		let colors = [
			"#f9a287",
			"#f8c17b",
			"#f6db72",
			"#dbe982",
			"#b9eb9e",
			"#96e5bf",
			"#80c7dc",
			"#6dadf5",
			"#8f7cc2",
			"#ba4d82",
		];

		let flatData = this.normalizeData(moodchecks, to);

		for (let i = 0; i < moodValues.length; i++) {
			let moodData = [];
			let moods = _.filter(flatData, { value: moodValues[i] });

			if (mode === "good" && moodValues[i] < 6) {
				moods = [];
			} else if (mode === "bad" && moodValues[i] > 4) {
				moods = [];
			}

			if (moods.length) {
				for (let j = 0; j < moods.length; j++) {
					moodData.push({
						x: moods[j].day,
						y: moods[j].time,
						r: 4,
						id: moods[j].id,
					});
				}

				data.datasets.push({
					label: moodValues[i],
					data: moodData,
					backgroundColor: colors[i],
					hoverBackgroundColor: colors[i],
					borderColor: "rgba(255,255,255,0)",
					borderWidth: 0,
				});
			}
		}

		return data;
	}

	getMoodcheckCountData(moodchecks: Moodcheck[], mode = 'all', to: Date) {
		var data = [];
		let flatData = this.normalizeData(moodchecks, to);
		let totalCount = 0;
		let totalDays = 0;

		for (let i = 1; i <= 30; i++) {
			let matches = _.filter(flatData, { day: i });

			totalCount += matches.length;
			totalDays += matches.length ? 1 : 0;

			data.push({
				day: i,
				count: matches.length,
			});
		}

		if (!totalDays) {
			totalDays = 1;
		}

		return {
			data: data,
			total: totalCount,
			average: Math.round(totalCount / totalDays),
		};
	}

	getMoodcheckPercentageData(moodchecks: Moodcheck[], mode = "good") {
		let totalCount = 0;
		let modeCount = 0;

		let categories = {
			activities: [],
			people: [],
			places: [],
		};

		moodchecks.forEach((element) => {
			let track = false;
			if (mode === "good" && element.value >= 5) {
				track = true;
			} else if (mode === "bad" && element.value < 5) {
				track = true;
			}

			if (track) {
				modeCount++;

				if (element.activity) {
					let match = _.find(categories.activities, {
						name: element.activity,
					});
					if (match) {
						match.count++;
					} else {
						categories.activities.push({
							name: this.utilService.capitalizeFirstLetter(
								element.activity
							),
							count: 1,
						});
					}
				}

				if (element.people) {
					let match = _.find(categories.people, {
						name: element.people,
					});
					if (match) {
						match.count++;
					} else {
						categories.people.push({
							name: this.utilService.capitalizeFirstLetter(
								element.people
							),
							count: 1,
						});
					}
				}

				if (element.place) {
					let match = _.find(categories.places, {
						name: element.place,
					});
					if (match) {
						match.count++;
					} else {
						categories.places.push({
							name: this.utilService.capitalizeFirstLetter(
								element.place
							),
							count: 1,
						});
					}
				}
			}

			totalCount++;
		});

		categories.activities = _.sortBy(categories.activities, [
			function (o) {
				return o.count * -1;
			},
			"name",
		]);
		categories.activities = categories.activities.slice(0, 3);

		categories.people = _.sortBy(categories.people, [
			function (o) {
				return o.count * -1;
			},
			"name",
		]);
		categories.people = categories.people.slice(0, 3);

		categories.places = _.sortBy(categories.places, [
			function (o) {
				return o.count * -1;
			},
			"name",
		]);
		categories.places = categories.places.slice(0, 3);

		let categoryStrings = {
			activities: _.map(categories.activities, "name").join(", "),
			people: _.map(categories.people, "name").join(", "),
			places: _.map(categories.places, "name").join(", "),
		};

		if (!totalCount) {
			totalCount = 1;
		}

		return {
			percentage: Math.round((modeCount / totalCount) * 100),
			categories: categoryStrings,
		};
	}

	getMoodcheckAdminCountData(moodchecks: Moodcheck[], mode = "good") {
		let totalCount = 0;
		let modeCount = 0;

		let categories = {
			activities: []
		};

		moodchecks.forEach((element) => {
			let track = false;
			if (mode === "good" && element.value >= 5) {
				track = true;
			} else if (mode === "bad" && element.value < 5) {
				track = true;
			}

			if (track) {
				modeCount++;

				if (element.activity) {
					let match = _.find(categories.activities, {
						name: element.activity,
					});
					if (match) {
						match.count++;
					} else {
						categories.activities.push({
							name: this.utilService.capitalizeFirstLetter(
								element.activity
							),
							count: 1,
						});
					}
				}
			}
			totalCount++;
		});

		categories.activities = _.sortBy(categories.activities, [
			function (o) {
				return o.count * -1;
			},
			"name",
		]);
		categories.activities = categories.activities.slice(0, 5);	
		categories.activities.sort((a, b) => b.count - a.count);	

		if (!totalCount) {
			totalCount = 1;
		}

		return {
			categories: categories,
		};
	}

	getMoodcheckSortedData(moodchecks: Moodcheck[]) {
		let moodcheckDetailConfig = WT_CONSTANTS.MOODCHECK_DETAIL_CONFIG;
		var categories = {
		  activities: [],
		  people: [],
		  places: [],
		  notes: [],
		};
	
		if (moodchecks != undefined) {
		  moodchecks.forEach((element) => {
			if (element.activity) {
			  let match = _.find(categories.activities, {
				name: element.activity as any,
			  });
			  if (match) {
				match.count++;
			  } else {
				const hasItem = moodcheckDetailConfig.activity.find((item) => {
				  return item.key.includes(element.activity);
				});
				let imgUrl = hasItem
				  ? hasItem.imgUrl
				  : "../../../assets/img/dashboard/activity/Plus.svg";
				categories.activities.push({
				  name: element.activity,
				  imgUrl,
				  count: 1,
				});
			  }
			}
	
			if (element.people) {
			  let peoples = element.people.split(",");
			  peoples.forEach((people) => {
				let match = _.find(categories.people, {
				  name: people as any,
				});
				if (match) {
				  match.count++;
				} else {
				  const hasItem = moodcheckDetailConfig.people.find((item) => {
					return item.key.includes(people);
				  });
				  let imgUrl = hasItem ? hasItem.imgUrl : "";
				  categories.people.push({
					name: people,
					imgUrl,
					count: 1,
				  });
				}
			  });
			}
	
			if (element.place) {
			  let match = _.find(categories.places, {
				name: element.place as any,
			  });
			  if (match) {
				match.count++;
			  } else {
				const hasItem = moodcheckDetailConfig.place.find((item) => {
				  return item.key.includes(element.place);
				});
				let imgUrl = hasItem ? hasItem.imgUrl : "";
				categories.places.push({
				  name: element.place,
				  imgUrl,
				  count: 1,
				});
			  }
			}
			if (element.notes) {
				let imgUrl = "";
				categories.notes.push({
				  name: element.notes,
				  imgUrl,
				  count: 1,
				});
			  }
		  });
		}
	
		categories.activities = _.sortBy(categories.activities, [
		  function (o) {
			return o.count * -1;
		  },
		  "name",
		]);
	
		categories.people = _.sortBy(categories.people, [
		  function (o) {
			return o.count * -1;
		  },
		  "name",
		]);
	
		categories.places = _.sortBy(categories.places, [
		  function (o) {
			return o.count * -1;
		  },
		  "name",
		]);
		return categories;
	  }

	addArrayOption(value: string, category: string) {
		if (this.moodcheck[category].indexOf(value) < 0) {
			this.moodcheck[category].push(value);
		}
	}

	removeArrayOption(value: string, category: string) {
		var index = this.moodcheck[category].indexOf(value);

		if (index >= 0) {
			this.moodcheck[category].splice(index, 1);
		}
	}

	categorizeMoodcheck(moodchecks: Moodcheck[]) {
		let categorizeMoodcheckData: any = {
		  fantastic: {
			value: 10,
			data: [],
		  },
		  veryHappy: {
			value: 9,
			data: [],
		  },
		  happy: {
			value: 8,
			data: [],
		  },
		  good: {
			value: 7,
			data: [],
		  },
		  fine: {
			value: 6,
			data: [],
		  },
		  ok: {
			value: 5,
			data: [],
		  },
		  notGreat: {
			value: 4,
			data: [],
		  },
		  bad: {
			value: 3,
			data: [],
		  },
		  veryBad: {
			value: 2,
			data: [],
		  },
		  awful: {
			value: 1,
			data: [],
		  },
		};
		moodchecks.forEach((mc) => {
			categorizeMoodcheckData[this.moodcheckCategoryConfig[mc.value]].data.push(
			  mc
			);
		  });
		return categorizeMoodcheckData;
	  }

	  getMoodcheckData(
		startDate: String,
		endDate: String,
		userId: any
	  ): Promise<any> {
		let moodchecks: Moodcheck[];
		return new Promise((resolve) => {
		  this.api
			.get("moodcheck/" + userId + "?From=" + startDate + "&To=" + endDate)
			.subscribe(
			  (result: any) => {
				moodchecks = Moodcheck.initializeArray(result.data);
				resolve(moodchecks);
			  },
			  (error) => {
				this.logService.error("Error getting moodchecks. " + error.message);
				resolve([]);
			  }
			);
		});
	  }
	  moodcheckCountWatcherUpdate(user: User) {
		this.mcCountWatcher.next(user);
	  }
}
