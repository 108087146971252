import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { ApiService } from "../../../lib/api.service";
import { StorageService } from "../../../lib/storage.service";
import { LogService } from "../../../lib/log.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ModalService } from "../../../lib/modal.service";
import { AssessmentAlert } from "../../../components/assessment-alert/assessment-alert";
import { User } from "../../../../app/models/user";
import { Organization } from "../../../../app/models/organization";
import { Assessment } from "../../../../app/models/assessment";
import { UserService } from "../../../../app/lib/user.service";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe, Location } from "@angular/common";
import * as jQuery from "jquery";
import { UtilityService } from "../../../lib/utility.service";
import { ActionPlan } from "../../../../app/models/action-plan";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AnalyticEvent } from "app/lib/analytic-event";


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM DD, YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: "app-action-plan-detail",
  templateUrl: "./action-plan-detail.component.html",
  styleUrls: ["./action-plan-detail.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ActionPlanDetailComponent implements OnInit {
  @ViewChild('matInputField') matInputField?: ElementRef;
  @ViewChild('textInput') textInput?: ElementRef;
  isLoaded = false;
  questions: Array<Object> = [];
  answers: Array<Object> = [];
  active: number = 0;
  buffer = 0;
  maxCharsText: number = 1000;
  characterCount: number = 0;
  currentStep: number = 1;
  count: number = 10;
  alerts: string;
  user: User;
  popup: any;
  showIntro: boolean = true;
  actionPlanId: number;
  actionPlan;
  actionPlanQuestions: any = [];
  currentQuestionData: any;
  // date = moment();
  step: number;
  popups: any;
  endUserId : any;
  today: Date = new Date();
  constructor(
    private api: ApiService,
    private modalService: ModalService,
    private log: LogService,
    private storage: StorageService,
    private router: Router,
    private userService: UserService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
    private utilityService: UtilityService) {
    this.user = this.userService.getUser();
    const data = history.state;
    this.route.queryParams.subscribe(params => {
			this.endUserId  = params['userId'];
		  });
    this.route.params.subscribe((params) => {
      this.actionPlanId = params["id"];
      this.step = params["questionId"];
      if (this.actionPlanId || this.step) {
        this.showIntro = false;
        let questionId = params["questionId"] ? +params["questionId"] : 0;
        this.getActionPlanDetailsById(this.actionPlanId, questionId, data);
      } else {
        this.getActionPlanQuestions();
        this.showIntro = true;
      }
    });
  }

  ngOnInit(): void {
    this.translate.stream("actionPlan").subscribe((res: any) => {
      this.popups = res.popups;
    });
  }

  getActionPlanQuestions() {
    this.isLoaded = false;
    const lang = <string>this.storage.get("lang", false);
    this.api.get("getactionplanform").subscribe(
      (result: any) => {
        let actionPlanData = new ActionPlan(lang, this.user.id, result);
        actionPlanData.questions.unshift(
          this.addTitleQuestion(
            actionPlanData.title,
            actionPlanData.date
          )
        );
        this.actionPlanQuestions = { ...actionPlanData };
        this.changeCurrentQuestion();
        this.log.event(AnalyticEvent.event.actionPlanCreateView);
        this.isLoaded = true;
      },
      (error: any) => {
        this.isLoaded = true;
        this.log.error(
          "Error getting activity schedule. " + error.message
        );
      },
      () => { }
    );
  }

  getActionPlanDetailsById(id, questionId, data) {
    this.log.event(AnalyticEvent.event.actionPlanEditView);
    const lang = <string>this.storage.get("lang");
    this.isLoaded = false;
    if (id) {
      this.api.get("myactionplan/" + id).subscribe(
        (result: any) => {
          let actionPlanData = new ActionPlan(lang, this.user.id, result);
          actionPlanData.questions.unshift(
            this.addTitleQuestion(actionPlanData.title, actionPlanData.date)
          );
          this.actionPlanQuestions = new ActionPlan(lang, this.user.id, actionPlanData);
          const index = this.actionPlanQuestions.questions.findIndex((item) => {
            return item.id == questionId;
          });
          this.active = index > 0 ? index : 0;
          this.changeCurrentQuestion();
          this.isLoaded = true;
        },
        (error: any) => {
          this.log.error("Error getting action plan. " + error.message);
          this.modalService.showAlert(
            this.popups.error,
            this.popups.errorGettingActionPlanData
          );
          this.isLoaded = true;
        },
        () => { }
      );
    } else {
      let actionPlanData = data.data;
      actionPlanData.questions.unshift(
        this.addTitleQuestion(actionPlanData.title, actionPlanData.date)
      );
      this.actionPlanQuestions = actionPlanData;
      const index = this.actionPlanQuestions.questions.findIndex((item) => {
        return item.id == questionId;
      });
      this.active = index > 0 ? index : 0;
      this.changeCurrentQuestion();
      this.isLoaded = true;
    }
  }

  setIntro(intro) {
    this.showIntro = intro;
  }

  goBack() {
    this.location.back();
  }

  setNextActive() {
    if (
      this.currentQuestionData?.isAddMore &&
      !this.checkOptionTyped(this.currentQuestionData.options)
    ) {
      this.addToAnswerList();
    } else {
      this.trimOptionValues();
    }
    if (this.active < this.count - 1) {
      this.active++;
      this.changeCurrentQuestion();
      this.characterCount = 0;
    }
  }

  setPreviousActive() {
    if (
      this.currentQuestionData?.isAddMore &&
      !this.checkOptionTyped(this.currentQuestionData.options)
    ) {
      this.addToAnswerList();
    } else {
      this.trimOptionValues();
    }
    if (this.active > 0) {
      this.active--;
      this.changeCurrentQuestion();
    }
  }

  trimOptionValues() {
    for (let option of this.currentQuestionData.options) {
      if(option.optionType === 'date'){
        option.value = moment(option.value).format("YYYY-MM-DD")
      }
      option.value = option.value.trim();
    }
  }

  changeCurrentQuestion() {
    this.currentQuestionData = this.actionPlanQuestions.questions.length
      ? this.actionPlanQuestions.questions[this.active]
      : {};
  }

  checkOptionTyped(options: any) {
    const hasValue = options.find((item) => {
      if (item.value.trim()) {
        return item.value;
      }
    });
    return hasValue ? false : true;
  }

  clearFileds() {
    this.currentQuestionData.options.forEach((item) => {
      item.value = "";
    });
    this.characterCount = 0;
  }

  addToAnswerList() {
    let data = {
      isGroupAdd: this.currentQuestionData.isGroupAdd,
    };
    if (this.currentQuestionData.isGroupAdd) {
      let options = [];
      for (let option of this.currentQuestionData.options) {
        let tempData = {
          groupId: option.groupId,
          id: "",
          optionId: option.id,
          title: option.optionTitle,
          optionType: option.optionType,
          value: option.value,
          isGrouped: true,
        };
        options.push({ ...tempData });
      }
      data["items"] = [...options];
    } else {
      if (this.currentQuestionData.options[0].value.trim()) {
        let tempData = {
          groupId: this.currentQuestionData.options[0].groupId,
          id: "",
          optionId: this.currentQuestionData.options[0].id,
          title: this.currentQuestionData.options[0].optionTitle,
          value: this.currentQuestionData.options[0].value,
          optionType: this.currentQuestionData.options[0].optionType,
          isGrouped: false,
        };
        data["items"] = { ...tempData };
      } else {
        data["items"] = {};
      }
    }
    

    this.currentQuestionData.groupAddAnswerList.push(data);
    this.clearFileds();
    const hasTextFieldOption = this.currentQuestionData.options.some(option => option.optionType === "textfield");
    const hasTextAreaOption = this.currentQuestionData.options.some(option => option.optionType === "textarea");

    if (hasTextFieldOption && hasTextAreaOption) {
      this.matInputField?.nativeElement.focus();
    } else if (hasTextAreaOption) {
      this.textInput?.nativeElement.focus();
    } else if (hasTextFieldOption) {
      this.matInputField?.nativeElement.focus();
    }
  }

  removeFromAnswerList(index: number, answer: any) {
    if (!answer.isGroupAdd) {
      this.currentQuestionData.deletedAnswers.push(answer.items.id);
    } else {
      for (let item of answer.items) {
        this.currentQuestionData.deletedAnswers.push(item.id);
      }
    }
    this.currentQuestionData.groupAddAnswerList.splice(index, 1);
  }

  dateChange(dateValue) {
    dateValue = UtilityService.convertToDate(
      moment(dateValue).format("YYYY-MM-DD")
    );
  }

  previewData() {
    if (
      this.currentQuestionData?.isAddMore &&
      !this.checkOptionTyped(this.currentQuestionData.options)
    ) {
      this.addToAnswerList();
    } else {
      this.trimOptionValues();
    }
    let payLoadActionPlanData: ActionPlan = {
      ...this.actionPlanQuestions,
      questions: [...this.actionPlanQuestions.questions],
    };
    let firstQuestion = payLoadActionPlanData.questions.shift();
    payLoadActionPlanData.title = firstQuestion.options[0].value;
    payLoadActionPlanData.date = moment(firstQuestion.options[1].value).format("YYYY-MM-DD")
    const state = {
      data: payLoadActionPlanData,
      prevPage: "submit"
    };
    
    const queryParams = this.endUserId ? { userId: this.endUserId } : undefined;
    this.router.navigate(['app/practice/action-plan/preview'], { state, queryParams });
  }



  submitActionPlan() {
    let payLoadActionPlanData: ActionPlan = {
      ...this.actionPlanQuestions,
      questions: [...this.actionPlanQuestions.questions],
    };
    let firstQuestion = payLoadActionPlanData.questions.shift();
    payLoadActionPlanData.title = firstQuestion.options[0].value;
    payLoadActionPlanData.date = firstQuestion.options[1].value;
    this.api.post("actionplan/save", ActionPlan.forApi(payLoadActionPlanData))
      .subscribe(
        (data: any) => {
          this.modalService.showAlert(
            this.popups.success,
            this.popups.saveSuccessMsg
          );
          this.log.event(AnalyticEvent.event.assessmentCreated);
          if(this.user.userType != "user" && this.endUserId ){
            let url = "/professional/user-details-dashboard/"+ this.endUserId;
            this.router.navigate([url]);
          }else {
          let url = "/app/practice/action-plan";
          this.router.navigate([url]);
          }
        },
        (error: any) => {
          this.log.error("Error" + error.message);
          if (error.status === 409) {
            this.modalService.showAlert(this.popups.error, error.message);
          }else{
            this.modalService.showAlert(
              this.popups.error,
              this.popups.saveErrorMsg
            );
          }
        }
      );
  }

  checkIsRequired(options: any) {
    const hasItem = options?.find((item) => {
      if (item.required && !item.value.trim()) {
        return item;
      }
    });
    return hasItem ? true : false;
  }

  checkFieldType() {
    const { fieldItemsType, options } = this.currentQuestionData;

    const hasTextField = fieldItemsType.includes("textfield");
    const hasTextArea = fieldItemsType.includes("textarea");

    const hasTextFieldOption = options.some(
      (option) => option.optionType === "textfield"
    );
    const hasTextAreaOption = options.some(
      (option) => option.optionType === "textarea"
    );

    return (
      (hasTextField && hasTextArea) ||
      (hasTextFieldOption && hasTextAreaOption)
    );
  }

  onInput(event: any, limit: number): void {
    // this.characterCount = (event.target as HTMLTextAreaElement).value.length;
    let characters = event.target.value.split("");
    let characterCount = limit - characters.length;

    if (characterCount < 0) {
      event.target.value = characters.slice(0, limit).join("");
    }
  }

  discardActionPlan() {
    if (
      this.actionPlanQuestions &&
      this.actionPlanQuestions?.questions &&
      this.actionPlanQuestions?.questions[0]?.options[0]?.value.trim()
    ) {
      this.modalService.showConfirmation(
        this.popups.exit,
        this.popups.exitMsg,
        this.popups.yes,
        this.popups.no
      )
        .afterClosed().subscribe(result => {
          if (result) {
            this.isLoaded = false;
            if(this.user.userType != "user" && this.endUserId ){
              let url = "/professional/user-details-dashboard/"+ this.endUserId;
              this.router.navigate([url]);
            }else {
            let url = "/app/practice/action-plan";
            this.router.navigate([url]);
            }
          }
        });
    } else {
      this.isLoaded = false;
      if(this.user.userType != "user" && this.endUserId ){
        let url = "/professional/user-details-dashboard/"+ this.endUserId;
        this.router.navigate([url]);
      }else {
      let url = "/app/practice/action-plan";
      this.router.navigate([url]);
      }
    }
  }

  addTitleQuestion(title: string, date: string) {
    return {
      options: [
        {
          optionType: "textfield",
          required: 1,
          optionTitle: "actionPlan.question1.option1",
          value: title || "",
          created: "",
          updated: "",
        },
        {
          optionType: "date",
          required: 0,
          optionTitle: "actionPlan.question1.option2",
          created: "",
          updated: "",
          value: date || moment().format("YYYY-MM-DD"),
        },
      ],
      groupAddAnswerList: [],
      answerData: [],
      language: "en",
      questionTitle: "actionPlan.question1.title",
      isAddMore: false,
      isRequired: true,
      fieldItemsType: ["textfield", "date"],
      created: "",
      updated: "",
    };
  }

  checkFieldTypeTextArea() {
    const { fieldItemsType } = this.currentQuestionData;
    return (
      fieldItemsType?.includes("textarea") && fieldItemsType.length == 1
    );
  }
}
