<div id="page-user-listing" class="page-section">
    <div class="page-section">
        <div class="page-section-header">
            <h1 translate>Survey List</h1>
        </div>
        <div class="page-section-content">
            <div class="table-responsive">
                <page-loader *ngIf="isloaded"></page-loader>
                <table class="table" >
                    <thead>
                        <tr>
                            <th translate style="width:25%">UserName</th>
                            <th translate >Sub Question1</th>
                            <th translate >Answer</th>
                            <th translate >Sub Question2</th>
                            <th translate >Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let survey of surveyList" >
                            <td class="strong" >
                                {{survey.UserName}}
                            <td class="strong">
                                {{survey.Questions[0].Sub[0].Question}}
                            </td>
                            <td class="strong">
                                {{survey.Questions[0].Sub[0].AnswerValue}}
                            </td>
                            <td class="strong">
                                {{survey.Questions[0].Sub[0].Question}}
                            </td>
                            <td class="strong">
                                {{survey.Questions[0].Sub[0].AnswerValue}}
                            </td>      
                        </tr>
                        <tr *ngIf="surveyList.length === 0">
                            <td colspan="5" style="text-align: center;">No data to display</td>
                        </tr>
                    </tbody>
                    <div class="page-section-buttons" *ngIf="surveyList.length > 0" (click)="loadMore()">
                        <a tabindex="0" translate>Load More</a>
                    </div>
                </table>
            </div>
        </div>
    </div>
</div>
