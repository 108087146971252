import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LogService } from '../../lib/log.service';
import { ApiService } from '../../lib/api.service';
import { UserService } from '../../../app/lib/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CourseFeedbackComponent } from '../../components/course-feedback/course-feedback.component';
import { CourseCompleteFeedbackComponent } from '../../components/course-complete-feedback/course-complete-feedback.component';
import { ModalService } from '../../lib/modal.service';
import { VideoService } from '../../lib/video.service';
import { Course, Video } from '../../../app/models/course';
import { User } from '../..//models/user';

import * as _ from 'lodash';
import { UrlService } from '../../lib/url.service';
import { Location } from '@angular/common';

@Component({
	selector: 'app-courses',
	templateUrl: './courses.component.html',
	styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit, OnDestroy {
	sub: any;
	backLink: string;
	isLoaded: boolean;

	courseId: string;
	course: Course;

	module: string;
	moduleTitle: string;
	modules: string[];
	cssModule: string;

	chapters: string[];
	chapterTitle: string;
	numOfChapters: number;

	chapterDesc: string;
	chapterDescriptions: string[];
	chapterContents: any[] = [];
	chapterContentsFlattened: any[] = [];

	backText: string;
	callbackUrl: string;
	talkAboutLink: string;
	talkAboutDescription: string;

	//When all content is present, holds an array of chapterContents arrays
	moduleContents: any[];
	emailCopy: Array<string>;
	filter: any;
	user: User;
	favourite: boolean;
	ssoLink: string;
	// modulesGroupA: string[];
	// modulesGroupB: string[];
	// modulesGroupC: string[];
	showlink: any;
	courseKey: string;
	courseVideos: any = [];
	title: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private log: LogService,
		private api: ApiService,
		private modalService: ModalService,
		private userService: UserService,
		private videoService: VideoService,
		private translate: TranslateService,
		private urlService: UrlService,
		private location: Location) {

		this.chapterDescriptions = [];
		this.backText = 'Back to List';
		this.backLink = '/app/series'
		this.cssModule = 'anxiety';

		this.modules = ['anxiety', 'anxiety_old', 'depression', 'phobia', 'disaster', 'resilience', 'sleep', 'wellbeing', 'mindfulness', 'purpose', 'work', 'depression_new'];
		this.chapters = ['1. Introduction', '2. Relaxation', '3. Unhelpful Thinking', '4. Changing Thoughts', '5. Changing Behaviour'];
		this.numOfChapters = this.chapters.length;
		this.callbackUrl = 'series/complete';
		this.user = this.userService.getUser();
	}

	ngOnInit() {

		this.getLanguageTranslation();

		this.activatedRoute.queryParams.subscribe(params => {
			this.filter = params['type'] ? params['type'] : '';
			this.favourite = params['favourite'] ? params['favourite'] : '';
		});

		this.sub = this.activatedRoute.params.subscribe(params => {
			this.module = params['series'];
			this.courseId = params['serieId'];
			this.courseKey = params['series'];
			this.showlink = params['index']
			if (this.module === 'depression_new') {
				this.module = 'depression';
			}

			if (this.urlService.getPreviousUrlCourse() === '/app/series-listing/depression') {
				this.backLink = this.urlService.getPreviousUrlCourse();
			} else if (this.urlService.getPreviousUrl() === '/app/assessment/' + this.module + '/listing') {
				this.backLink = this.urlService.getPreviousUrl();
			} else {
				this.cssModule = this.module;
				this.backLink = '/app/series';
			}


		});
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.courseVideos = [];
			this.getCourseVideos();
			this.getLanguageTranslation();
		});

		this.getLanguageTranslation();
		this.getCourseVideos();

	}

	getLanguageTranslation() {
		this.translate.stream('courseListing').subscribe((res: any) => {
			this.backText = res.back;
		});
	}

	keyProp(settings) {
		if (settings !== undefined) {
			if (settings.hasOwnProperty('showWelltrackConnect')) {
				return settings['showWelltrackConnect'];
			} else {
				return true;
			}
		}
	}

	showConnect() {
		if (
			this.user.primaryOrganization &&
			this.keyProp(this.user.primaryOrganization.settings)
		) {
			return true;
		} else {
			return false;
		}
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}


	getCourseVideos() {

		this.isLoaded = false;
		this.api.get("serieslisting/" + this.courseKey).subscribe(
			(result: any) => {
				this.isLoaded = true;
				let courseData = Course.initializeArray(result.data);
				this.title = courseData[0]?.courseName || "";
				this.ssoLink = localStorage.getItem('ssoLink');
				this.talkAboutDescription = courseData[0]?.talkAboutDescription || "";
				this.talkAboutLink = courseData[0]?.talkAboutLink || "";
				courseData.forEach((course) => {
					if (course.videos.length) {
						course.videos.forEach((video) => {
							video["chapterTitle"] = course.label;
							const hasMoodcheck = video.links?.find((item: any) => {
								return item.label === "Moodcheck";
							});
							video["moodcheckLink"] = hasMoodcheck ? true : false;
						});
						this.courseVideos = [...this.courseVideos, ...course.videos];
					}
				});
				this.chapterContentsFlattened = this.courseVideos;
				this.isLoaded = true;

			},
			(error: any) => {
				// this.loader.close();
				this.log.error("Error getting course data. " + error.message);
			},
			() => {
				// this.loader.close();
			}
		);
	}
	setBackLink(chapterCount: number) {
		if (this.module === 'depression_new') {
			this.module = 'depression';
		}
		if (this.favourite) {
			this.backLink = '/app/favourites';
		} else {
			this.backLink = '/app/series';
		}
		// else if (this.urlService.getPreviousUrlCourse() === '/app/series-listing/depression') {
		// 	this.backLink = this.urlService.getPreviousUrlCourse();
		// }
		// else if (this.urlService.getPreviousUrl() === '/app/assessment/'+this.module+'/listing') {
		// 	this.backLink = this.urlService.getPreviousUrl();
		// }
		// else if (chapterCount === 1) {
		// 	this.backLink = '/app/series';
		// 	this.chapterTitle = '';
		// }
	}

	showCourseFeedback(video) {
		if (this.user.primaryOrganization && this.user.primaryOrganization.settings['assessment'] === 'resilience') {
			let index = _.findIndex(this.chapterContentsFlattened, { id: video.id });
			let showNext = false;
			let last = false;

			if (this.chapterContentsFlattened[index + 1] !== undefined) {
				showNext = true;
				last = false;
			}
			else {
				last = true;
			}

			// this.getCourse();
			let showComplete = false;
			if (this.course.isCompleted && last) {
				showComplete = true;
			}

			this.modalService.showComponent(CourseFeedbackComponent, { video: video, showSuccess: !showComplete  /*, showNext: showNext */ })
				.afterClosed().subscribe(data => {

					if (data && data.action == 'playNext') {
						this.playNext(data.video);
					}

					if (showComplete) {
						this.modalService.showComponent(CourseCompleteFeedbackComponent, this.course);
					}
				});

		}
	}

	onRate(video) {
		this.modalService.setCloseonClick(false);
		this.modalService.showComponent(CourseFeedbackComponent, { video: video });
	}

	playNext(video) {
		let index = _.findIndex(this.chapterContentsFlattened, { id: video.id });

		if (this.chapterContentsFlattened[index + 1] !== undefined) {
			let nextVideo = this.chapterContentsFlattened[index + 1];
			this.videoService.setVideo(nextVideo);
		}

	}

	onClick(attachment) {
		if (attachment.fileFullPath) {
			window.open(attachment.fileFullPath, "_blank");
			this.api.post('analytics/seriesattachmentclick', {
				attachment: attachment
			}).subscribe(
				(result: any) => {
					//do nothing
				},
				(error: any) => {
					this.log.error('Error logging link click');
				}
			);
		}
		else {
			this.modalService.showAlert('Error', 'Could not load this link. Please contact info@welltrack-boost.com');
		}
	}


	onTalkAboutLink(link) {
		window.open(this.ssoLink + link, "_blank");
		this.api.post('analytics/additionalresources', {
			link: this.ssoLink + link,
			orgId: this.user.primaryOrganization.id
		}).subscribe(
			(result: any) => {
				//do nothing
			},
			(error: any) => {
				this.log.error('Error logging link click');
			}
		);
	}

	gotoVideo(videoId, autoplay) {
		this.router.navigateByUrl(
			"app/series-details/" + this.courseKey + "/" + videoId + "/" + autoplay
		);
	}
	goBack() {
		this.location.back();
	}
	gotoCourses() {
		this.router.navigate(['app/series']);
	}
}
