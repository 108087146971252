import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'app/lib/storage.service';
/**
 * Allows the aside to be toggled via click.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[accessControl]'
})
export class AccessControlDirective {
  private permissionsList: any;
  private page: any;
  private userData: any;
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _router: Router, 
    public storage: StorageService,
  ) {
    this.userData = this.storage.get('user', true);
    this.permissionsList = this.userData['permissions'];
    
  }

  @Input() set accessControl(accessKey: string) {
    if (this.checkAccess(accessKey)) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // Else remove template from DOM
      this.viewContainer.clear();
    }
  }

   // IF NO ACCESS ROUTE TO 404
   routeAccess(accessKey) {
    if (accessKey) {
      if (!this.checkAccess(accessKey)) {
        localStorage.clear();
        this._router.navigate(['/login']);
      }
    }
  }

  // TO CHECK IF THERE IS ACCESS
  checkAccess(accessKey) {
    accessKey = accessKey.trim();
    if (this.permissionsList) {
     if (this.permissionsList.hasOwnProperty(accessKey) && this.permissionsList[accessKey]) {
        return true;
      }
      else if (accessKey === 'NA') {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  /**
   * To check page access
   */
  checkPageAccess(accessKey) {
    accessKey = accessKey.trim();
    if (this.page) {
      if (this.page.indexOf(accessKey) > -1) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  // check permittion from component
  public isHaveAccess(accessKey) {
    return this.checkAccess(accessKey);
  }
}
