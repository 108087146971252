import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { User } from '../models/user';
import { ApiService } from '../lib/api.service';
import { LogService } from '../lib/log.service';

import 'rxjs/Rx';
import { Subject } from 'rxjs';
import { _MAT_INK_BAR_POSITIONER_FACTORY } from '@angular/material/tabs/ink-bar';

@Injectable()
export class UserService {

	watcher = new Subject();

	constructor(
		private storage: StorageService,
		private api: ApiService,
		private log: LogService
	) {

	}

	reloadUser() {
		this.api.get('users/me').subscribe(
			(result: any) => {
				let newuser = new User(result.data);
				this.storage.set('user', newuser, true);
				this.watcher.next(newuser);
				return newuser;
			},
		);
	}

	getUser(): User {
		let storedUser = this.storage.get('user', true);
		if(storedUser){
			this.log.debug('called from user service');
			let user = new User(storedUser);
			return user;
		}
		else{
			return null; 
		}
	}

	setUser(user: User) {
		//update first and last name
		if (user.fullName) {
			let name = user.fullName.split(/\s+/);
			user.firstName = name.slice(0, -1).join(' ');
			user.lastName = name.pop();
		}
		this.storage.set('user', null); //invalidate cookie user
		this.storage.set('user', user, true);
		this.watcher.next(user);
		return;
	}

	isAdmin(user: User) {
		if(user.userType){
			if(['admin', 'superadmin'].includes(user.userType)){
				return true; 
			}
			else{
				return false; 
			}
		}
		else{
			return false; 
		}
	}

	isInternal(user: User) {
		if(user.userType){
			if(['admin', 'superadmin', 'relationshipmanager'].includes(user.userType)){
				return true; 
			}
			else{
				return false; 
			}
		}
		else{
			return false; 
		}
	}

  isShowConsentInOnboarding(user: User) {
    const showConsent =
      user &&
      user.userType === "user" &&
      user.primaryOrganization &&
      user.primaryOrganization.settings &&
      user.primaryOrganization.serviceType === 1 &&
      user.primaryOrganization.settings.hasAssessmentAlerts &&
      user.primaryOrganization.settings.enableAlerts &&
      (user.preferences.consent === null ||
        [1, 0].includes(user.preferences.consent));
    return showConsent;
  }
  isShowTutorialPage(user: User) {
    let showConsent = this.isShowConsent(user);
    let showAssessment = user.forceAssessment && user.assessmentCount == 0;
    let showDemographic = user.showDemographic && user.demographicCount == 0;
    switch (user.onboardingStep) {
      case 0: {
        return showDemographic || showConsent || showAssessment;
      }
      case 1: {
        return showConsent || showAssessment;
      }
      case 2: {
        return showAssessment;
      }
      case 3: {
        return false;
      }
    }
  }
  isShowConsent(user: User) {
    const showConsent =
      user &&
      user.userType === "user" &&
      user.primaryOrganization &&
      user.primaryOrganization.settings &&
      user.primaryOrganization.serviceType === 1 &&
      user.primaryOrganization.settings.hasAssessmentAlerts &&
      user.primaryOrganization.settings.enableAlerts &&
      user.preferences.consent === null;

    return showConsent;
  }

}
