import { Component, OnInit, } from '@angular/core';

@Component({
	selector: 'page-loader',
	templateUrl: 'loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class Loader implements OnInit {
	ngOnInit(){}
}
