<div class="top-menu header-section">
    <div class="left-side-header">
        <div
            class="hamburger-container"
            (click)="toggleMenu()"
            (keypress)="toggleMenu()"
            [class.change]="menuService.menuState$ | async"
            tabindex="0"
            role="button">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
        <div
            class="sliding-menu"
            *ngIf="menuService.menuState$ | async"
            [class.change]="menuService.menuState$ | async">
            <!-- <div class="sliding-menu-header">
                <div class="submenu-hamburger">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
                <h2 class="menu-heading app-c-heading--h2--bold">Menu</h2>
            </div> -->
            <span
                tabindex="0"
                class="close_sidemenu"
                (click)="closeToggleMenu()"
                (keypress)="closeToggleMenu()"
                ><svg-icon
                    icon="close"
                    class="w-100 h-100"
                    alt="close"
                    title="close"></svg-icon
            ></span>
            <div class="slide-menu-content">
                <div class="menu-item-details">
                    <h2 class="app-c-heading--h2--bold" translate>
                        topNav.aboutMe
                    </h2>
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="hamburgerMenuNav('/app/my-settings')"
                        (keypress)="hamburgerMenuNav('/app/my-settings')">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="my-profile-icon"
                                title="my-profile"
                                alt="my-profile"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.myProfile
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                    <!-- <div class="menu-items-details-container">
						<div class="menu-items-first-section">
							<svg-icon icon="my-association-icon"></svg-icon>
							<p class="app-c-heading--h5">My Associations</p>
						</div>
						<div class="menu-items-second-section">
							<svg-icon icon="right-arrow-circle"></svg-icon>
						</div>
					</div> -->
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="hamburgerMenuNav('/app/resources')"
                        (keypress)="hamburgerMenuNav('/app/resources')">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="my-resources-icon"
                                title="my-resources"
                                alt="my-resources"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.myResources
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="menu-item-details">
                    <h2 class="app-c-heading--h2--bold" translate>
                        topNav.support
                    </h2>
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="support()"
                        (keypress)="support()">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="technical-support-icon"
                                title="technical-support"
                                alt="technical-support"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.contactTechnicalSupport
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="hamburgerMenuNav('/app/counselor-sync')"
                        (keypress)="hamburgerMenuNav('/app/counselor-sync')">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="councellor-icon"
                                title="councellor"
                                alt="councellor"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.syncWithCounselor
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="menu-item-details mb-0">
                    <h2 class="app-c-heading--h2--bold" translate>
                        topNav.settings
                    </h2>
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="subMenuToggle('moodcheck')"
                        (keypress)="subMenuToggle('moodcheck')">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="moodcheck-reminder-icon"
                                title="moodcheck-reminder"
                                alt="moodcheck-reminder"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.moodcheckReminders
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                    <!-- <div class="menu-items-details-container">
						<div class="menu-items-first-section">
							<svg-icon icon="fun-achievement-icon"></svg-icon>
							<p class="app-c-heading--h5">
								Fun & Achievement Reminders
							</p>
						</div>
						<div class="menu-items-second-section">
							<svg-icon icon="right-arrow-circle"></svg-icon>
						</div>
					</div> -->
                    <div
                        tabindex="0"
                        class="menu-items-details-container"
                        (click)="subMenuToggle('language')"
                        (keypress)="subMenuToggle('language')">
                        <div class="menu-items-first-section">
                            <svg-icon
                                icon="language-selection-icon"
                                title="language-selection"
                                alt="language-selection"></svg-icon>
                            <p class="app-c-heading--h5" translate>
                                topNav.languageSelection
                            </p>
                        </div>
                        <div class="menu-items-second-section">
                            <svg-icon
                                icon="right-arrow-circle"
                                title="right-arrow"
                                alt="right-arrow"></svg-icon>
                        </div>
                    </div>
                </div>
                <nav
                    id="nav"
                    role="navigation"
                    class="navigation-section first-navigation-section hamburger-side-bar"
                    [class.change]="menuService.menuState$ | async">
                    <div class="menu-items">
                        <ng-container *ngFor="let menu of menuList">
                            <ng-container *accessControl="menu.accessControl">
                                <div
                                    *ngIf="
                                        customCondition(menu) &&
                                        !(
                                            [
                                                'image',
                                                'profilelogo',
                                                'button'
                                            ].includes(menu.type) ||
                                            ['image', 'profilelogo'].includes(
                                                menu.menu
                                            )
                                        )
                                    "
                                    class="menu-items-details"
                                    tabindex="0">
                                    <div class="menu-list">
                                        <!--BUTTON-->
                                        <div
                                            *ngIf="
                                                menu.type === 'btn-dropdown' &&
                                                showOrgdropdown &&
                                                (user.userType == 'orgadmin' ||
                                                    user.userType ==
                                                        'professional')
                                            "
                                            class="org-menu">
                                            <organization-dropdown></organization-dropdown>
                                        </div>
                                        <!--LABEL-->
                                        <div
                                            class="app-l-menu__over"
                                            *ngIf="
                                                menu.subMenu.length == '0' &&
                                                menu.type == 'label'
                                            ">
                                            <span class="app-l-menu__label">
                                                <a
                                                    tabindex="0"
                                                    [ngClass]="menu.class"
                                                    class="labels-style non-drp-down"
                                                    [alt]="
                                                        getDynamicClasses(
                                                            menu,
                                                            menu.imageIcon.alt
                                                        )
                                                    "
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact:
                                                            menu.imageIcon
                                                                .alt ===
                                                            'Dashboard'
                                                                ? true
                                                                : false
                                                    }"
                                                    (click)="
                                                        menuService.nav(
                                                            menu.path
                                                        )
                                                    "
                                                    (keypress)="
                                                        menuService.nav(
                                                            menu.path
                                                        )
                                                    ">
                                                    <span
                                                        class="app-l-menu__icon"
                                                        (click)="
                                                            goTo(menu.path)
                                                        "
                                                        (keypress)="
                                                            goTo(menu.path)
                                                        "
                                                        [attr.aria-label]="
                                                            menu.name
                                                        ">
                                                        <svg-icon
                                                            [icon]="menu.name"
                                                            class=""
                                                            [alt]="menu.name"
                                                            [title]="
                                                                menu.name
                                                            "></svg-icon>
                                                    </span>
                                                    <span translate>{{
                                                        menu.menu
                                                    }}</span>
                                                    <div
                                                        class="menu-items-second-section">
                                                        <svg-icon
                                                            icon="right-arrow-circle"
                                                            title="right-arrow"
                                                            alt="right-arrow"></svg-icon>
                                                    </div>
                                                </a>
                                            </span>
                                        </div>
                                        <!--Dropdown Menus-->
                                        <div
                                            class="dropdown menu-submenu-title tools-dropdown"
                                            (click)="toggleSubMenu(menu)"
                                            (keypress)="toggleSubMenu(menu)"
                                            *ngIf="
                                                menu.subMenu.length > '0' &&
                                                menu.menu != 'profilelogo'
                                            "
                                            [ngClass]="{
                                                active: menu.showSubMenu
                                            }"
                                            (click)="goTo(menu.path)"
                                            (keypress)="goTo(menu.path)">
                                            <!-- [ngClass]="getDynamicClasses(menu,menu.imageIcon.alt)" -->
                                            <div
                                                class="app-l-menu__over contains_submenu"
                                                [ngClass]="{
                                                    active: menu.showSubMenu
                                                }">
                                                <span
                                                    class="app-l-menu__icon"
                                                    [attr.aria-label]="
                                                        menu.name
                                                    ">
                                                    <svg-icon
                                                        [icon]="menu.name"
                                                        class="w-100 h-100"
                                                        [alt]="menu.name"
                                                        [title]="
                                                            menu.name
                                                        "></svg-icon>
                                                </span>
                                                <span
                                                    class="app-l-menu__label"
                                                    *ngIf="
                                                        menu.type == 'label'
                                                    ">
                                                    <a
                                                        tabindex="0"
                                                        class="menu.class labels-style"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact:
                                                                menu.imageIcon
                                                                    .alt ===
                                                                'Dashboard'
                                                                    ? true
                                                                    : false
                                                        }"
                                                        (click)="
                                                            menuService.nav(
                                                                menu.path
                                                            )
                                                        "
                                                        (keypress)="
                                                            menuService.nav(
                                                                menu.path
                                                            )
                                                        ">
                                                        <span translate>{{
                                                            menu.menu
                                                        }}</span>
                                                    </a>
                                                </span>
                                                <span
                                                    *ngIf="
                                                        menu.type == 'image'
                                                    ">
                                                    <img
                                                        [alt]="
                                                            menu.imageIcon.alt
                                                        "
                                                        [class]="
                                                            menu.imageIcon.class
                                                        "
                                                        [src]="
                                                            menu.imageIcon.src
                                                        "
                                                        style="height: 20px" />
                                                </span>
                                                <button
                                                    class="dropbtn"
                                                    [attr.aria-label]="
                                                        menu?.imageIcon?.alt +
                                                        'menu'
                                                    "
                                                    [ngClass]="{
                                                        collapsed:
                                                            isCollapsed[
                                                                menu.name
                                                            ]
                                                    }"
                                                    (click)="
                                                        toggleCollapse(
                                                            menu.name
                                                        )
                                                    "
                                                    attr.aria-expanded="{{
                                                        isCollapsed[menu.name]
                                                    }}">
                                                    <svg-icon
                                                        icon="angle-down"
                                                        class="w-100 h-100"
                                                        alt="angle-down"
                                                        title="angle-down"></svg-icon>
                                                </button>
                                            </div>
                                            <div
                                                class="submenu-container"
                                                [ngClass]="{
                                                    show: menu.showSubMenu
                                                }">
                                                <div
                                                    class="dropdown-content"
                                                    [ngClass]="{
                                                        show: menu.showSubMenu
                                                    }"
                                                    *ngFor="
                                                        let submenu of menu.subMenu
                                                    ">
                                                    <div
                                                        *ngIf="
                                                            customCondition(
                                                                submenu
                                                            )
                                                        ">
                                                        <ng-container
                                                            *accessControl="
                                                                submenu.accessControl
                                                            ">
                                                            <a
                                                                tabindex="0"
                                                                [ngClass]="
                                                                    submenu.class
                                                                "
                                                                class="dropdown-item"
                                                                *ngIf="
                                                                    submenu
                                                                        .subMenu
                                                                        .length ==
                                                                    '0'
                                                                "
                                                                [alt]="
                                                                    getDynamicClasses(
                                                                        submenu,
                                                                        submenu
                                                                            .imageIcon
                                                                            .alt
                                                                    )
                                                                "
                                                                routerLinkActive="active"
                                                                [routerLinkActiveOptions]="{
                                                                    exact:
                                                                        submenu
                                                                            .imageIcon
                                                                            .alt ===
                                                                        'Dashboard'
                                                                            ? true
                                                                            : false
                                                                }"
                                                                (click)="
                                                                    submenu.outputType ==
                                                                    'popup'
                                                                        ? modalFunction(
                                                                              submenu.accessControl
                                                                          )
                                                                        : menuService.nav(
                                                                              submenu.path
                                                                          )
                                                                "
                                                                (keypress)="
                                                                    submenu.outputType ==
                                                                    'popup'
                                                                        ? modalFunction(
                                                                              submenu.accessControl
                                                                          )
                                                                        : menuService.nav(
                                                                              submenu.path
                                                                          )
                                                                ">
                                                                <span
                                                                    class="app-l-menu__icon"
                                                                    (click)="
                                                                        goTo(
                                                                            submenu.path
                                                                        )
                                                                    "
                                                                    (keypress)="
                                                                        goTo(
                                                                            submenu.path
                                                                        )
                                                                    "
                                                                    [attr.aria-label]="
                                                                        submenu.name
                                                                    ">
                                                                    <svg-icon
                                                                        [icon]="
                                                                            submenu
                                                                                .imageIcon
                                                                                .name
                                                                        "
                                                                        class="w-100 h-100"
                                                                        [alt]="
                                                                            submenu.name
                                                                        "
                                                                        [title]="
                                                                            submenu.name
                                                                        "></svg-icon>
                                                                    <!-- <img [alt]="menu.imageIcon.alt" class="menu.imageIcon.class"
                                                        [src]="submenu.imageIcon.src" /> -->
                                                                </span>
                                                                <span
                                                                    class="submenu-title"
                                                                    translate>
                                                                    {{
                                                                        submenu.menu
                                                                    }}
                                                                </span>
                                                            </a>
                                                            <div
                                                                class="second-dropdown menu-submenu-title sub-dropdown-section"
                                                                *ngIf="
                                                                    submenu
                                                                        .subMenu
                                                                        .length >
                                                                    '0'
                                                                "
                                                                (click)="
                                                                    toggleSecondSubMenu(
                                                                        submenu,
                                                                        menu
                                                                    )
                                                                "
                                                                (keypress)="
                                                                    toggleSecondSubMenu(
                                                                        submenu,
                                                                        menu
                                                                    )
                                                                ">
                                                                <div
                                                                    class="app-l-menu__over">
                                                                    <span
                                                                        *ngIf="
                                                                            submenu.type ==
                                                                            'label'
                                                                        "
                                                                        translate>
                                                                        <a
                                                                            tabindex="0"
                                                                            class="submenu.class"
                                                                            [ngClass]="
                                                                                getDynamicClasses(
                                                                                    submenu,
                                                                                    submenu
                                                                                        .imageIcon
                                                                                        .alt
                                                                                )
                                                                            "
                                                                            routerLinkActive="active"
                                                                            [routerLinkActiveOptions]="{
                                                                                exact:
                                                                                    submenu
                                                                                        .imageIcon
                                                                                        .alt ===
                                                                                    'Dashboard'
                                                                                        ? true
                                                                                        : false
                                                                            }"
                                                                            (click)="
                                                                                submenu.outputType ==
                                                                                'popup'
                                                                                    ? modalFunction(
                                                                                          submenu.accessControl
                                                                                      )
                                                                                    : menuService.nav(
                                                                                          submenu.path
                                                                                      )
                                                                            "
                                                                            (keypress)="
                                                                                submenu.outputType ==
                                                                                'popup'
                                                                                    ? modalFunction(
                                                                                          submenu.accessControl
                                                                                      )
                                                                                    : menuService.nav(
                                                                                          submenu.path
                                                                                      )
                                                                            ">
                                                                            <span
                                                                                class="app-l-menu__icon"
                                                                                (click)="
                                                                                    goTo(
                                                                                        submenu.path
                                                                                    )
                                                                                "
                                                                                (keypress)="
                                                                                    goTo(
                                                                                        submenu.path
                                                                                    )
                                                                                "
                                                                                [attr.aria-label]="
                                                                                    submenu.name
                                                                                ">
                                                                                <!-- <img [alt]="menu.imageIcon.alt"
                                                                        class="menu.imageIcon.class"
                                                                        [src]="submenu.imageIcon.src"
                                                                        style="height: 20px" /> -->
                                                                                <svg-icon
                                                                                    [icon]="
                                                                                        submenu
                                                                                            .imageIcon
                                                                                            .name
                                                                                    "
                                                                                    [alt]="
                                                                                        submenu.name
                                                                                    "
                                                                                    class="w-100 h-100"
                                                                                    [title]="
                                                                                        submenu.name
                                                                                    "></svg-icon>
                                                                            </span>
                                                                            <span
                                                                                class="submenu-title"
                                                                                translate>
                                                                                {{
                                                                                    submenu.menu
                                                                                }}
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="
                                                                            submenu.type ==
                                                                            'image'
                                                                        ">
                                                                        <img
                                                                            [alt]="
                                                                                submenu
                                                                                    .imageIcon
                                                                                    .alt
                                                                            "
                                                                            class="submenu.imageIcon.class"
                                                                            [src]="
                                                                                submenu
                                                                                    .imageIcon
                                                                                    .src
                                                                            "
                                                                            style="
                                                                                height: 20px;
                                                                            "
                                                                    /></span>
                                                                    <button
                                                                        class="dropbtn"
                                                                        [attr.aria-label]="
                                                                            submenu
                                                                                ?.imageIcon
                                                                                ?.alt +
                                                                            'menu'
                                                                        "
                                                                        [ngClass]="{
                                                                            collapsed:
                                                                                isCollapsed[
                                                                                    submenu
                                                                                        .name
                                                                                ]
                                                                        }"
                                                                        (click)="
                                                                            toggleCollapse(
                                                                                submenu.name
                                                                            )
                                                                        "
                                                                        attr.aria-expanded="{{
                                                                            isCollapsed[
                                                                                submenu
                                                                                    .name
                                                                            ]
                                                                        }}">
                                                                        <!-- <i class="fa fa-caret-down"></i> -->
                                                                        <svg-icon
                                                                            icon="angle-right"
                                                                            class="w-100 h-100"
                                                                            alt="angle-right"
                                                                            title="angle-right"></svg-icon>
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    class="second-submenu-container"
                                                                    [ngClass]="{
                                                                        show: submenu.showSecondSubMenu
                                                                    }">
                                                                    <div
                                                                        class="second-dropdown-content dropdown-content"
                                                                        [ngClass]="{
                                                                            show: submenu.showSecondSubMenu
                                                                        }"
                                                                        *ngFor="
                                                                            let innersubmenu of submenu.subMenu
                                                                        ">
                                                                        <div  *ngIf="
                                                                        customCondition(
                                                                            innersubmenu
                                                                        )
                                                                    ">
                                                                        <ng-container
                                                                            *accessControl="
                                                                                innersubmenu.accessControl
                                                                            ">
                                                                            <a
                                                                                translate
                                                                                tabindex="0"
                                                                                class="second-dropdown-item"
                                                                                *ngIf="
                                                                                    innersubmenu
                                                                                        .subMenu
                                                                                        .length ==
                                                                                    '0'
                                                                                "
                                                                                [ngClass]="
                                                                                    getDynamicClasses(
                                                                                        innersubmenu,
                                                                                        innersubmenu
                                                                                            .imageIcon
                                                                                            .alt
                                                                                    )
                                                                                "
                                                                                routerLinkActive="active"
                                                                                [routerLinkActiveOptions]="{
                                                                                    exact:
                                                                                        innersubmenu
                                                                                            .imageIcon
                                                                                            .alt ===
                                                                                        'Dashboard'
                                                                                            ? true
                                                                                            : false
                                                                                }"
                                                                                (click)="
                                                                                    innersubmenu.outputType ==
                                                                                    'popup'
                                                                                        ? modalFunction(
                                                                                              innersubmenu.accessControl
                                                                                          )
                                                                                        : menuService.nav(
                                                                                              innersubmenu.path
                                                                                          )
                                                                                "
                                                                                (keypress)="
                                                                                    innersubmenu.outputType ==
                                                                                    'popup'
                                                                                        ? modalFunction(
                                                                                              innersubmenu.accessControl
                                                                                          )
                                                                                        : menuService.nav(
                                                                                              innersubmenu.path
                                                                                          )
                                                                                ">
                                                                                <span
                                                                                    class="app-l-menu__icon"
                                                                                    (click)="
                                                                                        goTo(
                                                                                            innersubmenu.path
                                                                                        )
                                                                                    "
                                                                                    (keypress)="
                                                                                        goTo(
                                                                                            innersubmenu.path
                                                                                        )
                                                                                    "
                                                                                    [attr.aria-label]="
                                                                                        innersubmenu.name
                                                                                    ">
                                                                                    <!-- <img [alt]="menu.imageIcon.alt"
                                                                            class="menu.imageIcon.class"
                                                                            [src]="innersubmenu.imageIcon.src" /> -->
                                                                                    <svg-icon
                                                                                        [icon]="
                                                                                            innersubmenu
                                                                                                .imageIcon
                                                                                                .name
                                                                                        "
                                                                                        [alt]="
                                                                                            innersubmenu.name
                                                                                        "
                                                                                        class="w-100 h-100"
                                                                                        [title]="
                                                                                            innersubmenu.name
                                                                                        "></svg-icon>
                                                                                </span>
                                                                                <span
                                                                                    class="innersubmenu-title submenu-title"
                                                                                    translate>
                                                                                    {{
                                                                                        innersubmenu.menu
                                                                                    }}
                                                                                </span>
                                                                            </a>
                                                                        </ng-container>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </nav>
            </div>
            <div class="sliding-menu-footer">
                <button
                    class="sign-out-button"
                    (click)="menuService.onLogout()"
                    (keypress)="menuService.onLogout()"
                    translate>
                    <svg-icon
                        icon="sign-out-button-icon"
                        title="sign-out"
                        alt="sign-out"></svg-icon
                    >topNav.signOut
                </button>
                <svg-icon
                    icon="powered-by-logo"
                    class="footer-logo"
                    title="footer-logo"
                    alt="footer-logo"></svg-icon>
            </div>
            <div class="sliding-submenu" *ngIf="showSubMenu === 'moodcheckSub'">
                <div class="sliding-submenu-header">
                    <h2 class="app-c-heading--h2--bold" translate>
                        topNav.moodcheckReminders
                    </h2>
                    <button
                        (click)="showSubMenu = ''"
                        (keypress)="showSubMenu = ''">
                        <svg-icon
                            icon="close"
                            title="close"
                            alt="close"></svg-icon>
                    </button>
                </div>
                <div class="sliding-submenu-content">
                    <div class="content-header">
                        <h2 class="app-c-heading--h2--bold" translate>
                            topNav.moodcheckReminders
                        </h2>
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="reminders" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <p class="submenu-content-text" translate>
                        topNav.moodPatterns
                    </p>
                    <div class="badges-container" tabindex="0">
                        <h3 translate>topNav.howManyTimes</h3>
                        <div class="badges-section">
                            <div
                                class="badge"
                                *ngFor="let item of [1, 2, 3, 4, 0]"
                                (click)="moodcheckFreq = item"
                                (keypress)="moodcheckFreq = item"
                                [ngClass]="{
                                    active: moodcheckFreq == item,
                                    'never-badge': item == 0,
                                    'btn-disabled': !reminders
                                }"
                                translate>
                                {{ 'moodcheck.moodcheckFrequency.' + item }}
                            </div>
                        </div>
                    </div>
                    <div class="sliding-submenu-footer" tabindex="0">
                        <button
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="saveMoodcheckFrequencyModal()"
                            (keypress)="saveMoodcheckFrequencyModal()"
                            translate>
                            topNav.applyChanges
                        </button>
                    </div>
                </div>
            </div>
            <div class="sliding-submenu" *ngIf="showSubMenu === 'languageSub'">
                <div class="sliding-submenu-header">
                    <h2 class="app-c-heading--h2--bold" translate>
                        topNav.languageSelection
                    </h2>
                    <button
                        (click)="showSubMenu = ''"
                        (keypress)="showSubMenu = ''">
                        <svg-icon
                            icon="close"
                            title="close"
                            alt="close"></svg-icon>
                    </button>
                </div>
                <div class="sliding-submenu-content">
                    <div
                        class="language-selection-main-container"
                        role="radiogroup"
                        aria-label="Language Selection">
                        <div
                            *ngFor="let lang of languages"
                            class="language-selection-option"
                            tabindex="0"
                            role="radio"
                            [attr.aria-checked]="
                                language === lang.value ? 'true' : 'false'
                            "
                            (keydown)="handleKeydown($event, lang.value)"
                            (click)="selectLanguage(lang.value)">
                            <label>
                                <input
                                    type="radio"
                                    class="question-option"
                                    tabindex="-1"
                                    name="100"
                                    [value]="lang.value"
                                    [(ngModel)]="language" />
                                <div class="app-c-custom__select">
                                    <div class="language-content">
                                        <svg-icon [icon]="lang.icon"></svg-icon>
                                        <span
                                            class="app-c-custom__select-text"
                                            >{{ lang.label | translate }}</span
                                        >
                                    </div>
                                    <svg-icon
                                        icon="tick-circle"
                                        class="app-c-custom__select-icon">
                                        <svg>
                                            <use
                                                xlink:href="../../../assets/icon/svg-icon-def.svg#tick-circle"></use>
                                        </svg>
                                    </svg-icon>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="sliding-submenu-footer">
                        <button
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="saveLanguage(language)"
                            (keypress)="handleKeydownForButton($event)">
                            Apply Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay-sidemenu"></div>

        <div class="app-l-logo">
            <h1 tabindex="0">
                <a class="header-logo" alt="Welltrack boost" routerLink="/app">
                    <svg-icon
                        icon="logo"
                        class="w-100 h-100"
                        title="Welltrack boost"></svg-icon>
                </a>
            </h1>
        </div>
    </div>
    <div class="navigation-items">
        <nav
            id="nav"
            role="navigation"
            class="navigation-section first-navigation-section hamburger-side-bar"
            [class.change]="menuService.menuState$ | async">
            <div class="menu-items">
                <ng-container *ngFor="let menu of menuList">
                    <ng-container *accessControl="menu.accessControl">
                        <div
                            *ngIf="
                                customCondition(menu) &&
                                !(
                                    ['image', 'profilelogo', 'button'].includes(
                                        menu.type
                                    ) ||
                                    ['image', 'profilelogo'].includes(menu.menu)
                                )
                            "
                            class="menu-items-details"
                            role="menuitem">
                            <div class="menu-list">
                                <!--BUTTON-->
                                <div
                                    *ngIf="
                                        menu.type === 'btn-dropdown' &&
                                        showOrgdropdown &&
                                        (user.userType == 'orgadmin' ||
                                            user.userType == 'professional')
                                    "
                                    class="org-menu">
                                    <organization-dropdown></organization-dropdown>
                                </div>
                                <!--LABEL-->
                                <div
                                    class="app-l-menu__over"
                                    *ngIf="
                                        menu.subMenu.length == '0' &&
                                        menu.type == 'label'
                                    "
                                    (click)="menuService.nav(menu.path)"
                                    (keypress)="menuService.nav(menu.path)"
                                    tabindex="0"
                                    role="menuitem">
                                    <span
                                        class="app-l-menu__icon"
                                        [attr.aria-label]="menu.name">
                                        <svg-icon
                                            [icon]="menu.name"
                                            class="w-100 h-100"
                                            [alt]="menu.name"
                                            [title]="menu.name"></svg-icon>
                                    </span>
                                    <span class="app-l-menu__label">
                                        <a
                                            tabindex="0"
                                            [ngClass]="menu.class"
                                            class="labels-style non-drp-down"
                                            [alt]="
                                                getDynamicClasses(
                                                    menu,
                                                    menu.imageIcon.alt
                                                )
                                            "
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact:
                                                    menu.imageIcon.alt ===
                                                    'Dashboard'
                                                        ? true
                                                        : false
                                            }">
                                            <span translate>{{
                                                menu.menu
                                            }}</span>
                                        </a>
                                    </span>
                                </div>
                                <!--Dropdown Menus-->
                                <div
                                    class="dropdown menu-submenu-title tools-dropdown"
                                    (click)="toggleSubMenu(menu)"
                                    (keypress)="toggleSubMenu(menu)"
                                    *ngIf="
                                        menu.subMenu.length > '0' &&
                                        menu.menu != 'profilelogo'
                                    "
                                    tabindex="0"
                                    role="menuitem"
                                    [ngClass]="{ active: menu.showSubMenu }"
                                    (click)="goTo(menu.path)"
                                    (keypress)="goTo(menu.path)">
                                    <!-- [ngClass]="getDynamicClasses(menu,menu.imageIcon.alt)" -->
                                    <div
                                        class="app-l-menu__over contains_submenu"
                                        [ngClass]="{
                                            active: menu.showSubMenu
                                        }">
                                        <span
                                            class="app-l-menu__icon"
                                            [attr.aria-label]="menu.name">
                                            <svg-icon
                                                [icon]="menu.name"
                                                class="w-100 h-100"
                                                [alt]="menu.name"
                                                [title]="menu.name"></svg-icon>
                                        </span>
                                        <span
                                            class="app-l-menu__label"
                                            *ngIf="menu.type == 'label'">
                                            <a
                                                tabindex="0"
                                                class="menu.class labels-style"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact:
                                                        menu.imageIcon.alt ===
                                                        'Dashboard'
                                                            ? true
                                                            : false
                                                }"
                                                (click)="
                                                    menuService.nav(menu.path)
                                                "
                                                (keypress)="
                                                    menuService.nav(menu.path)
                                                ">
                                                <span translate>{{
                                                    menu.menu
                                                }}</span>
                                            </a>
                                        </span>
                                        <span *ngIf="menu.type == 'image'">
                                            <img
                                                [alt]="menu.imageIcon.alt"
                                                [class]="menu.imageIcon.class"
                                                [src]="menu.imageIcon.src"
                                                style="height: 20px" />
                                        </span>
                                        <button
                                            class="dropbtn"
                                            [attr.aria-label]="
                                                menu?.imageIcon?.alt + 'menu'
                                            "
                                            [ngClass]="{
                                                collapsed:
                                                    isCollapsed[menu.name]
                                            }"
                                            (click)="toggleCollapse(menu.name)"
                                            attr.aria-expanded="{{
                                                isCollapsed[menu.name]
                                            }}">
                                            <svg-icon
                                                icon="angle-down"
                                                class="w-100 h-100"
                                                alt="angle-down"
                                                title="angle-down"></svg-icon>
                                        </button>
                                    </div>
                                    <div
                                        class="submenu-container"
                                        [ngClass]="{ show: menu.showSubMenu }">
                                        <div
                                            class="dropdown-content"
                                            [ngClass]="{
                                                show: menu.showSubMenu
                                            }"
                                            *ngFor="
                                                let submenu of menu.subMenu
                                            ">
                                            <div
                                                *ngIf="
                                                    customCondition(submenu)
                                                ">
                                                <ng-container
                                                    *accessControl="
                                                        submenu.accessControl
                                                    ">
                                                    <a
                                                        tabindex="0"
                                                        [ngClass]="
                                                            submenu.class
                                                        "
                                                        class="dropdown-item"
                                                        *ngIf="
                                                            submenu.subMenu
                                                                .length == '0'
                                                        "
                                                        [alt]="
                                                            getDynamicClasses(
                                                                submenu,
                                                                submenu
                                                                    .imageIcon
                                                                    .alt
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact:
                                                                submenu
                                                                    .imageIcon
                                                                    .alt ===
                                                                'Dashboard'
                                                                    ? true
                                                                    : false
                                                        }"
                                                        (click)="
                                                            submenu.outputType ==
                                                            'popup'
                                                                ? modalFunction(
                                                                      submenu.accessControl
                                                                  )
                                                                : menuService.nav(
                                                                      submenu.path
                                                                  )
                                                        "
                                                        (keypress)="
                                                            submenu.outputType ==
                                                            'popup'
                                                                ? modalFunction(
                                                                      submenu.accessControl
                                                                  )
                                                                : menuService.nav(
                                                                      submenu.path
                                                                  )
                                                        ">
                                                        <span
                                                            class="app-l-menu__icon"
                                                            (click)="
                                                                goTo(
                                                                    submenu.path
                                                                )
                                                            "
                                                            (keypress)="
                                                                goTo(
                                                                    submenu.path
                                                                )
                                                            "
                                                            [attr.aria-label]="
                                                                submenu.name
                                                            ">
                                                            <svg-icon
                                                                [icon]="
                                                                    submenu
                                                                        .imageIcon
                                                                        .name
                                                                "
                                                                class="w-100 h-100"
                                                                [alt]="
                                                                    submenu.name
                                                                "
                                                                [title]="
                                                                    submenu.name
                                                                "></svg-icon>
                                                            <!-- <img [alt]="menu.imageIcon.alt" class="menu.imageIcon.class"
                                                        [src]="submenu.imageIcon.src" /> -->
                                                        </span>
                                                        <span
                                                            class="submenu-title"
                                                            translate>
                                                            {{ submenu.menu }}
                                                        </span>
                                                    </a>
                                                    <div
                                                        class="second-dropdown menu-submenu-title sub-dropdown-section"
                                                        *ngIf="
                                                            submenu.subMenu
                                                                .length > '0'
                                                        "
                                                        (click)="
                                                            toggleSecondSubMenu(
                                                                submenu,
                                                                menu
                                                            )
                                                        "
                                                        (keypress)="
                                                            toggleSecondSubMenu(
                                                                submenu,
                                                                menu
                                                            )
                                                        ">
                                                        <div
                                                            class="app-l-menu__over">
                                                            <span
                                                                *ngIf="
                                                                    submenu.type ==
                                                                    'label'
                                                                "
                                                                translate>
                                                                <a
                                                                    tabindex="0"
                                                                    class="dropdown-item"
                                                                    [ngClass]="
                                                                        getDynamicClasses(
                                                                            submenu,
                                                                            submenu
                                                                                .imageIcon
                                                                                .alt
                                                                        )
                                                                    "
                                                                    routerLinkActive="active"
                                                                    [routerLinkActiveOptions]="{
                                                                        exact:
                                                                            submenu
                                                                                .imageIcon
                                                                                .alt ===
                                                                            'Dashboard'
                                                                                ? true
                                                                                : false
                                                                    }"
                                                                    (click)="
                                                                        submenu.outputType ==
                                                                        'popup'
                                                                            ? modalFunction(
                                                                                  submenu.accessControl
                                                                              )
                                                                            : menuService.nav(
                                                                                  submenu.path
                                                                              )
                                                                    "
                                                                    (keypress)="
                                                                        submenu.outputType ==
                                                                        'popup'
                                                                            ? modalFunction(
                                                                                  submenu.accessControl
                                                                              )
                                                                            : menuService.nav(
                                                                                  submenu.path
                                                                              )
                                                                    ">
                                                                    <span
                                                                        class="app-l-menu__icon"
                                                                        (click)="
                                                                            goTo(
                                                                                submenu.path
                                                                            )
                                                                        "
                                                                        (keypress)="
                                                                            goTo(
                                                                                submenu.path
                                                                            )
                                                                        "
                                                                        [attr.aria-label]="
                                                                            submenu.name
                                                                        ">
                                                                        <!-- <img [alt]="menu.imageIcon.alt"
                                                                        class="menu.imageIcon.class"
                                                                        [src]="submenu.imageIcon.src"
                                                                        style="height: 20px" /> -->
                                                                        <svg-icon
                                                                            [icon]="
                                                                                submenu
                                                                                    .imageIcon
                                                                                    .name
                                                                            "
                                                                            [alt]="
                                                                                submenu.name
                                                                            "
                                                                            class="w-100 h-100"
                                                                            [title]="
                                                                                submenu.name
                                                                            "></svg-icon>
                                                                    </span>
                                                                    <span
                                                                        class="submenu-title"
                                                                        translate>
                                                                        {{
                                                                            submenu.menu
                                                                        }}
                                                                    </span>
                                                                </a>
                                                            </span>
                                                            <span
                                                                *ngIf="
                                                                    submenu.type ==
                                                                    'image'
                                                                ">
                                                                <img
                                                                    [alt]="
                                                                        submenu
                                                                            .imageIcon
                                                                            .alt
                                                                    "
                                                                    class="submenu.imageIcon.class"
                                                                    [src]="
                                                                        submenu
                                                                            .imageIcon
                                                                            .src
                                                                    "
                                                                    style="
                                                                        height: 20px;
                                                                    "
                                                            /></span>
                                                            <button
                                                                class="dropbtn"
                                                                [attr.aria-label]="
                                                                    submenu
                                                                        ?.imageIcon
                                                                        ?.alt +
                                                                    'menu'
                                                                "
                                                                [ngClass]="{
                                                                    collapsed:
                                                                        isCollapsed[
                                                                            submenu
                                                                                .name
                                                                        ]
                                                                }"
                                                                (click)="
                                                                    toggleCollapse(
                                                                        submenu.name
                                                                    )
                                                                "
                                                                attr.aria-expanded="{{
                                                                    isCollapsed[
                                                                        submenu
                                                                            .name
                                                                    ]
                                                                }}">
                                                                <!-- <i class="fa fa-caret-down"></i> -->
                                                                <svg-icon
                                                                    icon="angle-right"
                                                                    class="w-100 h-100"
                                                                    alt="angle-right"
                                                                    title="angle-right"></svg-icon>
                                                            </button>
                                                        </div>
                                                        <div
                                                            class="second-submenu-container"
                                                            [ngClass]="{
                                                                show: submenu.showSecondSubMenu
                                                            }">
                                                            <div
                                                                class="second-dropdown-content dropdown-content"
                                                                [ngClass]="{
                                                                    show: submenu.showSecondSubMenu
                                                                }"
                                                                *ngFor="
                                                                    let innersubmenu of submenu.subMenu
                                                                ">
                                                                <div  *ngIf="
                                                                customCondition(
                                                                    innersubmenu
                                                                )
                                                            ">
                                                                <ng-container
                                                                    *accessControl="
                                                                        innersubmenu.accessControl
                                                                    ">
                                                                    <a
                                                                        translate
                                                                        tabindex="0"
                                                                        class="second-dropdown-item"
                                                                        *ngIf="
                                                                            innersubmenu
                                                                                .subMenu
                                                                                .length ==
                                                                            '0'
                                                                        "
                                                                        [ngClass]="
                                                                            getDynamicClasses(
                                                                                innersubmenu,
                                                                                innersubmenu
                                                                                    .imageIcon
                                                                                    .alt
                                                                            )
                                                                        "
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{
                                                                            exact:
                                                                                innersubmenu
                                                                                    .imageIcon
                                                                                    .alt ===
                                                                                'Dashboard'
                                                                                    ? true
                                                                                    : false
                                                                        }"
                                                                        (click)="
                                                                            innersubmenu.outputType ==
                                                                            'popup'
                                                                                ? modalFunction(
                                                                                      innersubmenu.accessControl
                                                                                  )
                                                                                : menuService.nav(
                                                                                      innersubmenu.path
                                                                                  )
                                                                        "
                                                                        (keypress)="
                                                                            innersubmenu.outputType ==
                                                                            'popup'
                                                                                ? modalFunction(
                                                                                      innersubmenu.accessControl
                                                                                  )
                                                                                : menuService.nav(
                                                                                      innersubmenu.path
                                                                                  )
                                                                        ">
                                                                        <span
                                                                            class="app-l-menu__icon"
                                                                            (click)="
                                                                                goTo(
                                                                                    innersubmenu.path
                                                                                )
                                                                            "
                                                                            (keypress)="
                                                                                goTo(
                                                                                    innersubmenu.path
                                                                                )
                                                                            "
                                                                            [attr.aria-label]="
                                                                                innersubmenu.name
                                                                            ">
                                                                            <!-- <img [alt]="menu.imageIcon.alt"
                                                                            class="menu.imageIcon.class"
                                                                            [src]="innersubmenu.imageIcon.src" /> -->
                                                                            <svg-icon
                                                                                [icon]="
                                                                                    innersubmenu
                                                                                        .imageIcon
                                                                                        .name
                                                                                "
                                                                                [alt]="
                                                                                    innersubmenu.name
                                                                                "
                                                                                class="w-100 h-100"
                                                                                [title]="
                                                                                    innersubmenu.name
                                                                                "></svg-icon>
                                                                        </span>
                                                                        <span
                                                                            class="innersubmenu-title submenu-title"
                                                                            translate>
                                                                            {{
                                                                                innersubmenu.menu
                                                                            }}
                                                                        </span>
                                                                    </a>
                                                                </ng-container>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </nav>
        <nav
            id="nav"
            role="navigation"
            class="navigation-section second-navigation-section">
            <div class="menu-items">
                <ng-container *ngFor="let menu of menuList">
                    <ng-container *accessControl="menu.accessControl">
                        <div
                            *ngIf="
                                customCondition(menu) &&
                                (['image', 'profilelogo', 'button'].includes(
                                    menu.type
                                ) ||
                                    ['image', 'profilelogo'].includes(
                                        menu.menu
                                    ))
                            "
                            tabindex="0">
                            <div class="menu-list">
                                <div
                                    tabindex="0"
                                    *ngIf="
                                        menu.type == 'button' &&
                                        menu.outputType != 'popup'
                                    "
                                    [ngClass]="menu.class">
                                    <button
                                        class="non-drp-down app-c-btn app-c-btn--curve app-c-btn--sm app-c-btn--white"
                                        (click)="
                                            menu.outputType == 'popup'
                                                ? modalFunction(
                                                      menu.accessControl
                                                  )
                                                : menuService.nav(menu.path)
                                        "
                                        (keypress)="
                                            menu.outputType == 'popup'
                                                ? modalFunction(
                                                      menu.accessControl
                                                  )
                                                : menuService.nav(menu.path)
                                        ">
                                        <svg-icon
                                            [icon]="menu.name"
                                            [title]="menu.name"
                                            [alt]="menu.name"></svg-icon>
                                        <span translate>{{ menu.menu }}</span>
                                    </button>
                                </div>
                                <div
                                    tabindex="0"
                                    *ngIf="
                                        menu.type == 'button' &&
                                        menu.outputType == 'popup'
                                    "
                                    [ngClass]="menu.class"
                                    class="share-button">
                                    <button
                                        class="non-drp-down app-c-btn app-c-btn--curve app-c-btn--sm app-c-btn--white"
                                        *ngIf="showShare"
                                        (click)="
                                            menu.outputType == 'popup'
                                                ? modalFunction(menu.menu)
                                                : menuService.nav(menu.path)
                                        "
                                        (keypress)="
                                            menu.outputType == 'popup'
                                                ? modalFunction(menu.menu)
                                                : menuService.nav(menu.path)
                                        ">
                                        <svg-icon
                                            [icon]="menu.name"
                                            [title]="menu.name"
                                            [alt]="menu.name"></svg-icon>
                                        <span translate>{{ menu.menu }}</span>
                                    </button>
                                </div>
                                <div class="menu-list-wrap">
                                    <!--IMAGE-->
                                    <div
                                        class="app-l-menu__over"
                                        *ngIf="
                                            menu.subMenu.length == '0' &&
                                            menu.type == 'image'
                                        ">
                                        <!--span-->
                                        <span
                                            class="app-l-menu__icon non-drp-down"
                                            (click)="goTo(menu.path)"
                                            (keypress)="goTo(menu.path)"
                                            [attr.aria-label]="menu.name"
                                            routerLinkActive="active"
                                            tabindex="0"
                                            [routerLinkActiveOptions]="{
                                                exact:
                                                    menu.imageIcon.alt ===
                                                    'Dashboard'
                                                        ? true
                                                        : false
                                            }"
                                            (click)="
                                                menu.outputType == 'popup'
                                                    ? modalFunction(menu.menu)
                                                    : menuService.nav(menu.path)
                                            "
                                            (keypress)="
                                                menu.outputType == 'popup'
                                                    ? modalFunction(menu.menu)
                                                    : menuService.nav(menu.path)
                                            ">
                                            <svg-icon
                                                [icon]="menu.imageIcon.name"
                                                class="w-100 h-100"
                                                [alt]="menu.name"
                                                [title]="menu.name"></svg-icon>
                                        </span>
                                        <!--span-->
                                    </div>
                                    <!--Dropdown Menus-->
                                    <div
                                        class="dropdown profile"
                                        *ngIf="menu.menu === 'profilelogo'"
                                        (click)="toggleSubMenu(menu)"
                                        (keypress)="toggleSubMenu(menu)">
                                        <div
                                            class="profile-picture"
                                            [ngClass]="{
                                                'profile-open': menu.showSubMenu
                                            }">
                                            <span
                                                >{{ firstNameInitial
                                                }}{{ lastNameInitial }}</span
                                            >
                                            <div class="status"></div>
                                        </div>
                                        <div
                                            class="submenu-container"
                                            [ngClass]="{
                                                show: menu.showSubMenu
                                            }">
                                            <div class="app-l-profile__info">
                                                <div
                                                    class="app-l-profile__avatar">
                                                    <img
                                                        src="/assets/img/profile-logo.svg" />
                                                </div>
                                                <div class="app-l-profile__cnt">
                                                    <h3
                                                        class="app-c-body-text--15-m">
                                                        {{ user.firstName
                                                        }}{{ user.lastName }}
                                                    </h3>
                                                    <span
                                                        class="app-c-body-text--13"
                                                        >{{ user.email }}</span
                                                    >
                                                </div>
                                            </div>
                                            <div
                                                class="dropdown-content"
                                                *ngFor="
                                                    let submenu of menu.subMenu
                                                "
                                                [ngClass]="{
                                                    show: menu.showSubMenu
                                                }">
                                                <span
                                                    *ngIf="
                                                        submenu.type == 'label'
                                                    "
                                                    translate>
                                                    <a
                                                        tabindex="0"
                                                        translate
                                                        [ngClass]="
                                                            submenu.class
                                                        "
                                                        [alt]="
                                                            getDynamicClasses(
                                                                submenu,
                                                                submenu
                                                                    .imageIcon
                                                                    .alt
                                                            )
                                                        "
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact:
                                                                submenu
                                                                    .imageIcon
                                                                    .alt ===
                                                                'Dashboard'
                                                                    ? true
                                                                    : false
                                                        }"
                                                        (click)="
                                                            submenu.outputType ==
                                                            'popup'
                                                                ? modalFunction(
                                                                      submenu
                                                                          .imageIcon
                                                                          .alt
                                                                  )
                                                                : menuService.nav(
                                                                      submenu.path
                                                                  )
                                                        "
                                                        (keypress)="
                                                            submenu.outputType ==
                                                            'popup'
                                                                ? modalFunction(
                                                                      submenu
                                                                          .imageIcon
                                                                          .alt
                                                                  )
                                                                : menuService.nav(
                                                                      submenu.path
                                                                  )
                                                        ">
                                                        <span
                                                            class="app-l-menu__icon"
                                                            (click)="
                                                                goTo(
                                                                    submenu.path
                                                                )
                                                            "
                                                            (keypress)="
                                                                goTo(
                                                                    submenu.path
                                                                )
                                                            "
                                                            [attr.aria-label]="
                                                                submenu.name
                                                            ">
                                                            <svg-icon
                                                                [icon]="
                                                                    submenu
                                                                        .imageIcon
                                                                        .name
                                                                "
                                                                class="w-100 h-100"
                                                                [alt]="
                                                                    submenu.name
                                                                "
                                                                [title]="
                                                                    submenu.name
                                                                "></svg-icon>
                                                        </span>
                                                        <span
                                                            class="submenu-title"
                                                            translate>
                                                            {{ submenu.menu }}
                                                        </span>
                                                    </a>
                                                </span>
                                            </div>
                                            <!-- <div class="dropdown-content" [ngClass]="{ 'show': menu.showSubMenu }">
                                                <a (click)="menuService.onLogout()" (keypress)="menuService.onLogout()"
                                                    translate>
                                                    <span class="app-l-menu__icon">
                                                        <svg-icon icon="log-out" class="w-100 h-100"></svg-icon>
                                                    </span>
                                                    <span class="submenu-title" translate>menu.signout</span>
                                                </a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- <div class="menu-list">
					<language-dropdown
						[color]="'white'"
						[page]="'inside'"
						*ngIf="!menuService.disableLanguage"
						class="language-dropdown-section"
					></language-dropdown>
				</div> -->
            </div>
        </nav>
    </div>
</div>
