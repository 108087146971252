<div>
    <div class="app-c-datebox">
        <button class="app-c-datebox__nav" (click)="onClickPrevBtn()">
            <svg-icon icon="angle-left" class="w-100 h-100"></svg-icon>
        </button>
        <div class="app-l-summary__date-label">
            <span>{{ displayDate + ", Week " + weekNumber }}</span>
        </div>
        <button class="app-c-datebox__nav" [ngClass]="{ disable: isCurrentWeek }" (click)="!isCurrentWeek && onClickNextBtn()">
            <svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
        </button>
    </div>
    <div class="app-l-weekly__mood">
         <div class="app-l-weekly__mood-wrap">
            <div class="app-l-weekly__mood-progress" *ngFor="let item of weekDays | keyvalue"
                (click)="openMoodcheckDailyModal(item,$event)" [ngClass]="{ 'current-date': item.value.date === classDate }">
                <div class="app-l-weekly__mood-label">
                    <div class="app-l-wml__left">
                        <span class="app-c-body-text--12">{{ item.value.shortDate }}</span>
                    </div>
                    
                    <div class="app-l-wml__right">
                        <span class="app-c-body-text--12">{{ item.value.data.length || 0 }} MoodChecks</span>
                    </div>
                </div>
                <div class="app-l-wml__progress-bar">
                    <div *ngFor="let item of item.value.moodcheckCategory" class="segment"
                        [style.width.%]="item.value"></div>
                </div>
            </div>
        </div>

          
        <!-- <ng-template #emptyMoodcheckDailyTemplate>
            <div class="no-daily-data">
                <div class="no-data-image">
                    <img src="../../../assets/img/sad-image.svg" />
                </div>
                <h2 [translate]="
                isSelectedDayToday
                  ? 'dashboard.daily.noMoodcheckYet'
                  : 'dashboard.daily.noMoodcheckOnDay'
              "></h2>
                <p translate>dashboard.daily.moodcheckSummaryAppearHere</p>
            </div>
        </ng-template> -->
    </div>
    <div class="app-l-mood-check__legends" *ngIf="showLegends"> 
        <div class="app-l-mood-check__legend" [ngClass]="item.key + '-legend'" *ngFor="let item of weeklyMoodcheckCategory">
            <span translate>{{ "dashboard." + item.key }}</span>
        </div>
    </div>
</div>


<ng-template class="modal-container" #moodcheckDailyModal>
    <div mat-dialog-content class="modal-container">
        <div class="app-l-moods__modal">
            <div class="app-l-moods__header">
                <h4 class="app-c-heading--h4" translate>dashboard.moodDaily</h4>
                <div class="app-l-mood__date">
                    <span class="app-c-body-text--14-m">{{ modalDailySelectedDate | date : "EEEE" }}, {{ modalDailySelectedDate | date : "MMM dd" }}</span>
                </div>
                <button class="app-l-modal__close app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14" mat-dialog-close cdkFocusInitial>
                    <svg-icon icon="close" class="w-100 h-100"></svg-icon>
                </button>
            </div>
            <div *ngIf="hasSelectedDayData; else emptyMoodcheckDailyTemplate" class="progress-section">
                <div class="app-l-daily__mood" *ngFor="let item of weeklyMoodcheckCategory">
                    <div class="app-l-daily-mood__left">
                        <span class="app-l-daily__m-label" translate>{{ "dashboard." + item.key }}</span>
                    </div>
                    <div class="app-l-daily-mood__right">
                        <span class="app-l-daily__amount">{{ item.value | number : "1.0-0" }}%</span>
                        <div class="app-l-daily__progress">
                            <div class="app-c-welltrack__progress" [ngClass]="item.key + '-progress'" >
                                <div class="app-c-welltrack__progress-fill" [style.width]="item.value + '%'">
                                    <svg-icon [icon]="'emoji-' + item.iconIndex"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-mood-check__legends" *ngIf="hasSelectedDayData">
                <div class="app-l-mood-check__legend" [ngClass]="item.key + '-legend'" *ngFor="let item of weeklyMoodcheckCategory; let dataIndex = index">
                    <span translate>{{ "dashboard." + item.key }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #emptyMoodcheckDailyTemplate>
    <div class="app-l-no-mood-check">
        <div class="no-data-image">
            <svg-icon icon="emoji-2"></svg-icon>
            <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
            <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
            <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
        </div>
        <h5 class="app-c-body-text--16-m" [translate]="
        isSelectedDayToday
          ? 'dashboard.daily.noMoodcheckYet'
          : 'dashboard.daily.noMoodcheckOnDay'
      "></h5>
        <p class="app-c-body-text--14" translate>dashboard.daily.moodcheckSummaryAppearHere</p>
    </div>
</ng-template>