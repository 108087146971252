<div class="page-section">
    <div class="page-section">
        <div class="page-section-header">
            <div class="page-section-buttons">
                <a autoFocus tabindex="0" (click)="invite()" (keydown.enter)="invite()"
                    class="trigger-modal invite btn btn-primary btn-with-icon pull-right" translate>
                    <img alt="Invite new user" src="./assets/img/icons-svg/user-icon.svg">userListing.inviteNew</a>
                <a tabindex="0" (click)="inviteShare()" (keydown.enter)="inviteShare()"
                    class="trigger-modal inviteshare btn btn-primary btn-with-icon pull-right" translate>
                    <img alt="Invite to share" src="./assets/img/icons-svg/user-icon.svg">userListing.inviteShare</a>
            </div>
            <h1 translate>Wellness Alerts</h1>
        </div>
        <div class="page-section-content">
            <p>
                This diplays the mental health alerts that have been generated by users that have complete the DASS
                assessment.
                The alerts appearing below are all from users that have consented to having their results shared with
                counselors at this university.
            </p>
            <div class="table-responsive" *ngIf="isLoaded">
                <table class="table" role="grid">
                    <thead role="rowgroup">
                        <tr role="row">
                            <th role="columnheader" translate>das.name</th>
                            <th role="columnheader" translate>das.email</th>
                            <th role="columnheader" translate>das.tableHeaders.0</th>
                            <th role="columnheader" translate>das.tableHeaders.1</th>
                            <th role="columnheader" translate>das.tableHeaders.2</th>
                            <th role="columnheader"><span translate>das.tableHeaders.6</span></th>
                            <th role="columnheader" class="text-right" translate width="200" style="width:200px">
                                das.tableHeaders.5</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup" *ngIf="assessments.length > 0">
                        <tr scope="row" *ngFor="let assessment of assessments; let i = index">
                            <td scope="col" class="strong"><span> {{assessment.Name? assessment.Name : '' }}</span></td>
                            <td scope="col" class="strong"><span> {{assessment.Email? assessment.Email : '' }}</span>
                            </td>
                            <td scope="col" class="strong"
                                [ngClass]="{'positive': assessment.depressionlevel === 0, 'negative': assessment.depressionlevel >= 3}">
                                {{assessment.depressionlevellabel}}
                                ({{assessment.depression}})</td>
                            <td scope="col" class="strong"
                                [ngClass]="{'positive': assessment.anxietylevel === 0, 'negative': assessment.anxietylevel >= 3}">
                                {{assessment.anxietylevellabel}}
                                ({{assessment.anxiety}})</td>
                            <td scope="col" class="strong"
                                [ngClass]="{'positive': assessment.stresslevel === 0, 'negative': assessment.stresslevel >= 3}">
                                {{assessment.stresslevellabel}}
                                ({{assessment.stress}})</td>
                            <td scope="col"><img alt="Alert" height="20"
                                    *ngIf="assessment.depressionlevel >= 3 || assessment.anxietylevel >= 3 || assessment.stresslevel >= 3"
                                    src="/assets/img/professional/alert.png"></td>
                            <td scope="col" class="strong text-right"> {{assessment.created | date:'dd MMM yyyy'}}</td>
                            <td scope="col" class="text-right">
                                <a style="float:right" class="trigger-modal info" tabindex="0" aria-label="Show alerts"
                                    (click)="showAlerts(i)" (keypress)="showAlerts(i)" translate>i</a>
                            </td>
                            <td scope="col" class="text-right">
                                <!-- *ngIf="assessment.usershareStatus && userShare " -->
                                <a tabindex="0" class="pull-right"
                                    [routerLink]="['/app/user-details', assessment.userId]">
                                    <i class="fa fa-eye trigger-view" aria-hidden="true"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="empty" *ngIf="assessments.length === 0">There are no alerts to show.</div>
            </div>
        </div>
    </div>