
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import Debounce from 'debounce-decorator'

@Component({
  selector: 'app-nps-survey-listing',
  templateUrl: './nps-survey-listing.component.html',
  styleUrls: ['./nps-survey-listing.component.scss']
})
export class NpsSurveyListingComponent implements OnInit {
  isloaded = false;
  surveyList :any = [];
  constructor(private api: ApiService,
    private log: LogService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.geAllSurveyListing();
    
  }


  /**
   * Get my favourite list
   */
   geAllSurveyListing(){
    this.isloaded = true;
    this.api.get('survey/nps_survey').subscribe(
      (result: any) => {
        this.isloaded = false;
        this.surveyList = result;
     },
      (error: any) => {
        this.isloaded = false;
        this.log.error('Error getting favourite List. ' + error.message);
      },
      () => {
        this.isloaded = false;
    });
   }


   @Debounce(500)
	loadMore() {
		this.isloaded = true;
		this.api.get('form/surveyresults/15', {
			Limit: 100,
			Offset: this.surveyList.length,
		}).subscribe(
			(results: any) => {
				if(results.length > 0) {
					this.surveyList = this.surveyList.concat(results);
				}
				this.isloaded = false;
			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isloaded = false;
			}
		);
	}




}

