import { Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { UserService } from '../../lib/user.service';
import { ModalService } from '../../lib/modal.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Debounce from 'debounce-decorator'
import * as _ from 'lodash';

@Component({
    selector: 'demographic-edit',
    templateUrl: 'demographic-edit.component.html',
    styleUrls: ['./demographic-edit.component.scss']
})
export class DemographicEditComponent implements OnInit {
    demographicEditForm = [];
    genderRows: string[];
    ethnicityRows: string[];
    yearInProgramRows: any;
    formData = [];
    finalData = [];
    userData: any;
    isSubmitted: boolean = false;
    prevalue = [];
    changeVal = [];
    isReadOnly: boolean = true;
    constructor(
        private formBuilder: FormBuilder,
        private api: ApiService,
        private log: LogService,
        private user: UserService,
        private translate: TranslateService,
        private modalService: ModalService,) {
        this.genderRows = [
            'Female',
            'Male',
            'Transgender',
            'Gender non-binary / non-forming / fluid',
            'Self-identify'
        ];
        this.ethnicityRows = [
            'American Indian / Native American',
            'Arab / Middle Eastern',
            'Asian / Asian American',
            'Black / African American',
            'Latino',
            'Multiracial',
            'White / Caucasian',
            'Other'
        ];
        this.yearInProgramRows = [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10
        ];
        this.userData = this.user.getUser();
        this.log.debug('UserDta', this.userData.demographicFormId);
        this.demographicEditForm = this.createDemographicEditForm();
    }

    createDemographicEditForm() {
        let data = [];
        this.formData.forEach((field, index) => {
          let formItem = {
            title: field.Comment,
            value: "",
          };
          if (field.Type === 5 || field.Type === 6 || field.Type === 9) {
            formItem.value = field.valueText;
          } else {
            let match = _.find(field.options, {
              ID: field.value as any,
            });
            formItem.value = match ? match.Comment : "";
          }
          data.push(formItem);
        });
        return data;
    }

    
    ngOnInit(): void {
        this.getDemographicData();
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getDemographicData();
        });
    }

    @Debounce(500)
    getDemographicData() {
        this.formData = [];
        this.prevalue = [];
        this.api.get('form/demographicdata/' + this.userData.demographicFormId, {
        }).subscribe(
            (result: any) => {
                for (let obj of result.data) {
                    this.formData.push(obj[0]);
                    const prevalarray = {
                        "id": obj[0].ID,
                        "preval": obj[0].value
                    }
                    this.prevalue.push(prevalarray);
                }
                this.demographicEditForm = this.createDemographicEditForm();
                console.log(this.demographicEditForm)
            },
            (error: any) => {
                this.log.error('Error fetching user. ' + error.message);
            }
        );
    }


    // doSave() {
    //     this.isSubmitted = true;
    //     this.demographicEditForm.markAllAsTouched();
    //     if (this.demographicEditForm.valid) {
    //         for (let fd of this.finalData) {
    //             for (let pv of this.prevalue) {
    //                 if (pv.id === fd.id) {
    //                     this.finalData = [];
    //                     let previousval = {
    //                         "value":fd.value,
    //                         "name":fd.name,
    //                         "id": fd.id,
    //                         "preval":pv.preval
    //                 }
    //                 this.changeVal.push(previousval);
    //                 }
    //             }
    //         }

    //         for (let data of this.formData) {
    //             const index = this.changeVal.findIndex(fd => {
    //                 return fd.id === data.ID
    //             });
    //             if (index < 0) {
    //                 let valueSelected =
    //                 {
    //                     "value": (data.Type === 5 || data.Type === 6) ? data.valueText : data.ID,
    //                     "name": data.value,
    //                     "id": data.ID,
    //                 }
    //                 this.changeVal.push(valueSelected);
    //             }
    //         }
    //         let changeVal = {
    //             Type: this.userData.demographicFormId,
    //             Data: JSON.stringify(this.changeVal)
    //         };
    //         this.api.put('settings/demographic/' + this.userData.demographicFormId, changeVal).subscribe(
    //             (result: any) => {
    //                 this.modalService.showAlert('Success', result.message);
    //                 this.changeVal = [];
    //                 this.getDemographicData();
    //             },
    //             (error: any) => {
    //                 this.modalService.showAlert('Error', error.message);
    //             },
    //             () => {
    //             });
    //     }
    // }
    // // Field type change 
    // valueChange(data, ev, opt) {
    //     let valueSelected: any;
    //     if (data.Type === 5 || data.Type === 6) {
    //         valueSelected = {
    //             "value": ev,
    //             "name": data.options[0].ID,
    //             "id": data.options[0].QuestionID
    //         }
    //         const index = this.finalData.findIndex(fd => {
    //             return fd.name === data.options[0].ID
    //         });
    //         if (index >= 0) {
    //             this.finalData.splice(index, 1)
    //         }
    //     } else {
    //         valueSelected = {
    //             "value": data.ID,
    //             "name": (data.Type === 1 || data.Type === 2) ? ev.ID : ev.value,
    //             "id": (data.Type === 1 || data.Type === 2) ? ev.QuestionID : data.ID,
    //         }
    //         const index = this.finalData.findIndex(fd => {
    //             return fd.id === ((data.Type === 1 || data.Type === 2) ? ev.QuestionID : ev.value.QuestionID)
    //         });
    //         if (index >= 0) {
    //             this.finalData.splice(index, 1)
    //         }
    //     }
    //     this.finalData.push(valueSelected);
    // }
}