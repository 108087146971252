<div class="app-c-datebox">
    <button class="app-c-datebox__nav" (click)="onClickPrevBtn()">
        <svg-icon icon="angle-left" class="w-100 h-100"></svg-icon>
    </button>
    <div class="app-l-summary__date-label">
        <span> {{ selectedDayTitle }} </span>
        <!-- <span> {{ selectedDate | date : "MMM dd, YYYY" }}</span> -->
    </div>
    <button class="app-c-datebox__nav" [ngClass]="{ disable: isToday }" (click)="!isToday && onClickNextBtn()">
        <svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
    </button>
</div>


    
<div class="app-l-mood-check__daily" *ngIf="hasData">
    <div class="app-l-daily__mood" *ngFor="let item of moodcheckCategory">
        <div class="app-l-daily-mood__left">
            <span class="app-l-daily__m-label" translate>{{ "dashboard." + item.key }}</span>           
        </div>
        <div class="app-l-daily-mood__right">
            <span class="app-l-daily__amount">{{ item.value | number : "1.0-0" }}%</span>
            <div class="app-l-daily__progress">
                <div class="app-c-welltrack__progress" [ngClass]="item.key + '-progress'" >
                    <div class="app-c-welltrack__progress-fill" [style.width]="item.value + '%'">
                        <svg-icon [icon]="'emoji-' + item.iconIndex"></svg-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="app-l-mood-check__legends" *ngIf="hasData">
    <div class="app-l-mood-check__legend" [ngClass]="item.key + '-legend'" *ngFor="let item of moodcheckCategory">
        <span translate>{{ "dashboard." + item.key }}</span>
    </div>
</div>

<div *ngIf="isLoading && !hasData" class="skeleton-text-container">
    <ion-skeleton-text *ngFor="let item of moodcheckCategory" class="skeleton-text"
        [animated]="true"></ion-skeleton-text>
</div>

<div class="app-l-no-mood-check" *ngIf="!isLoading && !hasData && user.hasContent">
    <div class="no-data-image">
        <svg-icon icon="emoji-2"></svg-icon>
        <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
    </div>
    <h5 class="app-c-body-text--16-m" [translate]="
        isToday
            ? 'dashboard.daily.noMoodcheckYet'
            : 'dashboard.daily.noMoodcheckOnDay'
        "></h5>
    <p class="app-c-body-text--14" *ngIf="user.userType === 'user'" translate>dashboard.daily.moodcheckSummaryAppearHere</p>
    <button class="app-c-btn app-c-btn--primary app-c-btn--curve"  *ngIf="user.userType === 'user'"  routerLink="/app/mood-check">
        <svg-icon icon="emoji-fill"></svg-icon>
        <span translate>dashboard.readyText</span>
    </button>
</div>

<div class="app-l-no-mood-check" *ngIf="!user.hasContent && !hasData">
    <div class="no-data-image" *ngIf="user.userType === 'user'">
        <svg-icon icon="emoji-8"></svg-icon>
        <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
    </div>
    <h5 class="app-c-body-text--16-m" translate *ngIf="user.userType === 'user'">dashboard.feelingGood</h5>
    <p class="app-c-body-text--14" *ngIf="user.userType === 'user'" translate>dashboard.doMoodcheck</p>
    <button class="app-c-btn app-c-btn--primary app-c-btn--curve" *ngIf="user.userType === 'user'" routerLink="/app/mood-check">
        <svg-icon icon="emoji-fill"></svg-icon>
        <span translate>dashboard.readyText</span>
    </button>
    <div class="no-data-image" *ngIf="user.userType != 'user'">
        <svg-icon icon="emoji-2"></svg-icon>
        <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
        <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
    </div>
    <h5 class="app-c-body-text--16-m" *ngIf="user.userType != 'user'" [translate]="
        isToday
            ? 'dashboard.daily.noMoodcheckYet'
            : 'dashboard.daily.noMoodcheckOnDay'
        "></h5>
</div>
  
