import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss']
})
export class CommunityComponent implements OnInit {
  backLink: string;
  backText: string;
  cssClass: string;
  headerText: string;
  ssoLink: string;
  user: User; 
  
  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private userService: UserService,
  ) { 

  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.cssClass = 'additional-resources';
    this.headerText = 'Community';

    this.apiService.get('organizations/' + this.user.primaryOrganization.id).subscribe(
      (result: any) => {
        this.logService.debug('organization detected');
        const organization = new Organization(result.data, 'full');
        this.ssoLink = organization.integrations.togetherAllSsoLink;
      },
      (error: any) => {
        this.logService.error('Error getting organizations. ' + error.message);
      });
  }


  goToResource(link){
    window.open(link);
    this.apiService.post('analytics/additionalresources', {
      link: link
    }).subscribe(
      (result: any) => {
        window.open(link);
      },
      (error: any) => {
        this.logService.error('Error logging link click');
      }
    );
  }

}
