<mat-chip-list #chipList>

	<!-- <mat-chip *ngFor="let item of selectedItems" [selectable]="selectable" [removable]="removable" (removed)="remove(item)">
		{{ item.item }}
	</mat-chip> -->

	<mat-form-field class="wt-mat-form-field"> 
		<input class="auto-text-input" type="text" #inputTrigger [placeholder]="placeholder" matInput
			class="wt-mat-input" [matAutocomplete]="autoComplete" [formControl]="itemControl"
			[matChipInputFor]="chipList">
	</mat-form-field>
	<mat-chip *ngFor="let item of selectedItems" [selectable]="selectable" [removable]="removable"
		(removed)="toggleSelection(item)">
		{{ item.item }}
		<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
	</mat-chip>
</mat-chip-list>

<mat-autocomplete #autoComplete="matAutocomplete">
	<mat-option>
		<mat-checkbox [checked]="isAllSelected" (change)="toggleSelectAll()" (click)="$event.stopPropagation()">
			Select All
		</mat-checkbox>
	</mat-option>
	<mat-option *ngFor="let item of filteredItems" [value]="selectedItems">
		<div (click)="optionClicked($event, item);">
			<mat-checkbox [checked]="item.selected" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
				{{ item.item }}
			</mat-checkbox>
		</div>
	</mat-option>
</mat-autocomplete>