<div class="page-static public-portal-right-col" style="overflow-y: scroll; height: 100%;">
	<div class="form-screen" id="nmconnect">
		<div class="img-wrapper">
			<img alt="Welltrack" src="./assets/img/nm-connect-logo-v4-small.png" alt="NMConnect App">
		</div>

		<div class="title-wrapper">

			<h2>Download NMConnect!</h2>

			<div style="text-align: left;">
				<p>Thanks for your interest in downloading NMConnect! To use the link we sent you, you must first
					download the NMConnect app from your phone by using the Apple App Store or Google Play Store. </p>

				<div id="download">
					<a tabindex="0" target="_blank" class="wt-auto-google-store google"
						href="{{playStore}}">
						<img alt="Get it on Google Play" src="/assets/img/icons/google-play-badge@2.png" />
					</a>
					<a tabindex="0" target="_blank" class="wt-auto-apple-store apple"
						href="{{appStore}}">
						<img alt="Download on the App Store"
							src="/assets/img/icons/Download_on_the_App_Store_Badge_US-UK_564x167@2.png">
					</a>
				</div>

				<p class="strong">Once you've downloaded the app:</p>
				<ul>
					<li>Click on this link for full access:<br>
						<a href="https://app.welltrack-boost.com/referral?accesscode=nmreferral">https://app.welltrack-boost.com/referral?accesscode=nmreferral</a></li>

					<li>Create your account with any email you like and follow the on screen steps</li>
					<li>Verify your account using the link in the email we send you from <a
							href="mailto:noreply@welltrack.com">noreply@welltrack.com</a></li>
					<li>Login and enjoy using NMConnect's self help resources, provided by Welltrack Boost</li>
				</ul>
			</div>
		</div>
	</div>
</div>