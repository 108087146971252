import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "svg-icon",
	styleUrls: ["./svg-icon.component.scss"],
	template: `
		<svg role="icon" [attr.aria-label]="alt">
			<use
				attr.xlink:href="../../../assets/icon/svg-icon-def.svg#{{
					icon
				}}"
			></use>
		</svg>
	`,
})
export class SvgIconComponent implements OnInit {
	@Input() icon: string;
	@Input() alt: string = "";
	constructor() {}

	ngOnInit(): void {}
}
