<page-loader *ngIf="isLoaded === 0"></page-loader>
<div *ngIf="isLoaded != 0">
<div role="region" aria-labelledby="HeadersRow" tabindex="0" class="colheaders" *ngIf="type === 'updatedOrganization'">
	<table class="table" *ngIf=" organizations?.length > 0">
		<thead>
			<tr>
				<th style="width:40%;" translate>editedOrgListing.headers.0</th>
				<th style="width:20%;" translate>editedOrgListing.headers.1</th>
				<th style="width:8%;"></th>
				<th style="width:9%;"></th>
				<th style="width:9%;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let organization of activeOrganizations" [ngClass]="{'highlighted': organization.isPaired}">
				<td class="strong" style="width:45%;">
					<a tabindex="0" [routerLink]="['/admin/organization', organization.ID]">
						{{organization.Name}}</a>
					<div *ngIf="organization.parentOrganization">
						<span>Parent:</span>
						<a
							[routerLink]="['/admin/organization', organization.parentOrganization.id]">{{organization.parentOrganization.name}}</a>
					</div>
				</td>
				<td class="strong">{{organization.Action === "CreateOrg" ? "Created" : "Edited"}}</td>
				<td class="strong"></td>
				<td class="strong"></td>
				<td>
					<a tabindex="0" class="pull-right" [routerLink]="['/admin/organization', organization.ID]"
						translate>editedOrgListing.view</a>
				</td>
				<td>
					<a tabindex="0" class="pull-right" (click)="onEdit(organization.ID)"
						(keypress)="onEdit(organization.ID)" translate>editedOrgListing.edit</a>
				</td>
				<td></td>
			</tr>
		</tbody>
	</table>
	<p *ngIf="!organizations || organizations.length < 1" translate class="center">
		editedOrgListing.noneFound</p>
</div>

<div role="region" aria-labelledby="HeadersRow" tabindex="0" class="colheaders" *ngIf="type === 'allOrganization'">
	<table class="table">
		<thead>
			<tr>
				<th style="width:40%;" translate>editedOrgListing.headers.0</th>
				<th style="width:8%;"></th>
				<th style="width:9%;"></th>
				<th style="width:9%;"></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let organization of allActiveOrganizations" [ngClass]="{'highlighted': organization.isPaired}">
				<td class="strong" style="width:45%;">
					<a tabindex="0" [routerLink]="['/admin/organization', organization.id]">
						{{organization.name}}</a>
					<div *ngIf="organization.parentOrganization">
						<span>Parent:</span>
						<a
							[routerLink]="['/admin/organization', organization.parentOrganization.id]">{{organization.parentOrganization.name}}</a>
					</div>
					<div *ngIf="organization.childOrganizations">
						<span>Children:</span>
						<div *ngFor="let child of organization.childOrganizations">
							<a tabindex="0" [routerLink]="['/admin/organization', child.id]">{{child.name}}</a>
						</div>
					</div>
				</td>
				<td class="strong"></td>
				<td class="strong"></td>
				<td>
					<a tabindex="0" class="pull-right" [routerLink]="['/admin/organization', organization.id]"
						translate>editedOrgListing.view</a>
				</td>
				<td>
					<a tabindex="0" class="pull-right" (click)="onEdit(organization.id)"
						(keypress)="onEdit(organization.id)" translate>editedOrgListing.edit</a>
				</td>
				<td></td>
			</tr>
		</tbody>
	</table>
	<p *ngIf="!allOrganizations || allOrganizations.length < 1" translate class="center">
		organizationListing.noneFound</p>
</div>
</div>