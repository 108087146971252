import { Component, OnInit } from '@angular/core';
import { User } from '../../../app/models/user';
import { UserService } from '../../../app/lib/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../lib/storage.service';
import { ApiService } from '../../lib/api.service';
import { Organization } from '../../models/organization';
import { ModalService } from '../../lib/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'app/lib/log.service';
import * as _ from 'lodash';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { CrisisHotline, LocationService } from 'app/lib/location.service';
import { UtilityService } from 'app/lib/utility.service';

@Component({
    selector: 'app-orgadmin-setting',
    templateUrl: './orgadmin-setting.component.html',
    styleUrls: ['./orgadmin-setting.component.scss'],
})
export class OrgadminSettingComponent implements OnInit {
    user: User;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    organization: Organization;
    orgName: string;
    email: string;
    address: string;
    phone: string;
    website: string;
    orgPopups: any[];
    emgPopups: any;
    organizations: Organization[] = [];
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];
    orgEditForm: FormGroup;
    isLoaded: boolean = false;
    hotline: CrisisHotline;
    contactGroupTelephone: string;
    emergencyContact: string;
    showOther: boolean;
    urlPattern: any;
    isSaved: boolean = true;
    constructor(
        private api: ApiService,
        private userService: UserService,
        private modalService: ModalService,
        private locationService: LocationService,
        private log: LogService,
        private translate: TranslateService,
        private storage: StorageService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utility: UtilityService
    ) {
        this.user = this.userService.getUser();
        this.urlPattern = this.utility.urlValidationPattern();
        this.getTranslationText();
        this.createForm();
        this.getEmergencyHotlineContact();
    }

    ngOnInit(): void {
        this.getOrganizationList();
    }

    createForm() {
        this.orgEditForm = new FormGroup({
            address: new FormControl(''),
            phone: new FormControl(''),
            website: new FormControl('', Validators.pattern(this.urlPattern)),
        });
    }

    getTranslationText() {
        this.translate.stream('organizationInfo').subscribe((res: any) => {
            this.orgPopups = res.popups;
        });
        this.translate.get('contact').subscribe((res: any) => {
            this.emgPopups = res;
        });
    }

    getEmergencyHotlineContact() {
        this.locationService.getLocation().subscribe(
            (data: any) => {
                this.log.debug(data);
                this.hotline = this.locationService.getHelpline(
                    data.country,
                    data.regionName
                );
            },
            (error: any) => {
                this.hotline = this.locationService.getHelpline();
            }
        );
    }

    getOrganizationList() {
        if (
            this.user.userType == 'orgadmin' ||
            this.user.userType == 'professional'
        ) {
            this.isLoaded = false;
            this.api
                .get('organizations/children', { includeParent: true })
                .subscribe(
                    (result: any) => {
                        this.organizations = Organization.initializeArray(
                            result.data
                        );
                        this.log.debug(this.organizations);
                        if (this.user.organizations) {
                            let orgId = this.getOrgID();
                            let index = this.organizations.findIndex(
                                org => org.id === orgId
                            );
                            if (index !== -1) {
                                this.organization = this.organizations[index];
                                this.updateOrgInfo();
                            }
                        }
                        this.isLoaded = true;
                    },
                    (error: any) => {
                        this.isLoaded = true;
                        this.log.error(
                            'Error getting organizations. ' + error.message
                        );
                    },
                    () => {}
                );
        } else {
            this.isLoaded = true;
        }
    }

    getOrgID() {
        if (this.router.url.slice(1, 6) === 'admin') {
            return this.activatedRoute.params.subscribe(params => params['id']);
        } else {
            if (this.storage.get('orgselect')) {
                return this.storage.get('orgselect');
            } else {
                this.user.primaryOrganization;
                return this.user.primaryOrganization.id;
            }
        }
    }

    onUpdateMain() {
        const formData = this.orgEditForm.value;
        this.isSaved = false;
        this.api
            .put('organizations/updateexecutive/' + this.organization.id, {
                Name: this.orgName,
                Address: formData.address,
                Phone: formData.phone,
                Website: formData.website,
            })
            .subscribe(
                (data: any) => {
                    const organization = new Organization(data.data, 'full');
                    this.organization.address = organization.address;
                    this.organization.phone = organization.phone;
                    this.organization.website = organization.website;
                    this.modalService.showAlert(
                        this.orgPopups[3].title,
                        this.orgPopups[3].body
                    );
                    this.isSaved = true;
                    this.log.event(AnalyticEvent.event.organizationUpdated);
                },
                (error: any) => {
                    this.isSaved = true;
                    this.modalService.showAlert(
                        this.orgPopups[0],
                        error.message
                    );
                    this.log.error('Error registering. ' + error.message);
                }
            );
    }

    updateOrgInfo() {
        this.orgName = this.organization.name;
        this.emergencyContact = this.organization.emergencyContact;
        this.orgEditForm.controls['address'].setValue(
            this.organization.address
        );
        this.orgEditForm.controls['phone'].setValue(this.organization.phone);
        this.orgEditForm.controls['website'].setValue(
            this.organization.website
        );
        this.contactGroupTelephone = '';
        this.emergencyContact = '';
        this.showOther = false;
        this.setEmergencyCntactInformation();
    }

    setEmergencyCntactInformation() {
        if (this.organization?.contactGroup?.telephone) {
            this.contactGroupTelephone =
                this.organization.contactGroup.telephone;
        } else if (this.organization?.emergencyContact) {
            this.emergencyContact = this.decodeHtml(
                this.organization.emergencyContact
            );
        } else {
            this.showOther = true;
        }
    }

    decodeHtml(html: string): string {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = html;
        return textarea.value;
    }

    onChangeOrg(organization: Organization) {
        this.organization = organization;
        this.updateOrgInfo();
    }

    onHotline(event: MouseEvent) {
        this.modalService
            .showConfirmation(
                this.emgPopups.confirmCall,
                this.emgPopups.popupEmergencyHotline
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.log.event(AnalyticEvent.event.hotlineCalled);
                    window.location.href = `tel:${this.hotline.url}`;
                }
            });
    }

    onCallContact(event: MouseEvent) {
        const hotLineText =
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings['assessment'] ===
                'resilience'
                ? this.emgPopups.popupEmergencyTextVeteran
                : this.organization.contactGroup.getHelpDescription
                  ? this.organization.contactGroup.getHelpDescription
                  : this.emgPopups.popupEmergencyHotline;

        this.modalService
            .showConfirmation(this.emgPopups.confirmCall, hotLineText)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.log.event(AnalyticEvent.event.protocallNumberCalled);
                    window.location.href = `tel:${this.contactGroupTelephone}`;
                    this.api
                        .post('analytics/supportlineclick', {
                            phoneNumber: this.contactGroupTelephone,
                            contactGroupId: this.organization.contactGroup.id,
                        })
                        .subscribe(
                            (result: any) => {
                                this.log.debug('Activity logged');
                            },
                            (error: any) => {
                                this.log.debug(
                                    'Something went wrong with the activity logger.'
                                );
                            }
                        );
                }
            });
    }
}
