import { UtilityService } from "../lib/utility.service";
import * as moment from "moment";

import * as _ from "lodash";

export class ActionPlan {
  id: number;
  title: string;
  description: string;
  active: number;
  created: Date;
  updated: Date;
  language: string;
  date: string;
  questions: any[] = [];
  userActionPlanId: number;
  UserId: any;
  isAdminCreated: boolean;
  createdBy: number;
  constructor(language: string, userId: number, data?: any) {
    this.language = language;
    this.id = data.ID || data.id;
    this.title = data.Title || data.title;
    this.description = data.Description || data.description;
    this.active  = data.Active || data.active;
    this.date = data.ActionPlanDate || data.date;
    this.createdBy = data.created_by || data.CreatedBy || data.createdBy;
    this.isAdminCreated = this.createdBy == userId ? false : true;
    this.userActionPlanId =
      data.user_action_plan_id || data.MyActionPlanID || data.userActionPlanId;
      this.created = UtilityService.convertToDate(
        data.created_at || data.CreatedOnUtc || data.created
      );
      this.updated = UtilityService.convertToDate(
        data.updated_at || data.UpdatedOnUtc || data.updated
      );
    this.initializeQuestionSet(data.questions);
  }
  public initializeQuestionSet(questions: any) {
    if (questions.length) {
      for (let i = 0; i < questions.length; i++) {
        this.questions.push(new questionSet(this.language, questions[i]));
      }
    }
  }

  public static forApi(actionPlan: ActionPlan) {
    let payLoad: any = {
      actionPlanId: actionPlan.id,
      MyActionPlanID: actionPlan.userActionPlanId,
      title: actionPlan.title,
      date: actionPlan.date,
      UserId: actionPlan.UserId,
      data: [],
    };
    for (let question of actionPlan.questions) {
      let questionItem = {
        questionId: question.id,
        addedAnswers: [],
        deletedAnswers: [],
      };
      questionItem.deletedAnswers = question.deletedAnswers.length
        ? question.deletedAnswers
        : [];
      if (!question.isAddMore) {
        for (let option of question.options) {
          let answerItem = {
            id: option.answerId,
            optionId: option.id,
            value: option.value,
            fieldType: option.optionType,
            groupId: option.groupId,
            isGrouped: false,
            groupedValues: [],
          };
          questionItem.addedAnswers.push(answerItem);
        }
      } else {
        for (let answerItem of question.groupAddAnswerList) {
          if (answerItem.isGroupAdd) {
            let answer = {
              groupId: "",
              isGrouped: true,
              groupedValues: [],
            };
            for (let item of answerItem?.items) {
              if (!item.id && item.value) {
                answer.groupId = item.groupId;
                let dataItem = {
                  id: item.id,
                  optionId: item.optionId,
                  value: item.value,
                  fieldType: item.optionType,
                  groupId: item.groupId,
                };
                answer.groupedValues.push(dataItem);
              }
            }
            answer.groupedValues.length &&
              questionItem.addedAnswers.push(answer);
          } else {
            if (answerItem.items.value) {
              let answer = {
                id: answerItem.items.id,
                optionId: answerItem.items.optionId,
                value: answerItem.items.value,
                fieldType: answerItem.items.optionType,
                groupId: answerItem.items.groupId,
                isGrouped: false,
                groupedValues: [],
              };
              questionItem.addedAnswers.push(answer);
            }
          }
        }
      }
      (questionItem.addedAnswers.length ||
        questionItem.deletedAnswers.length) &&
        payLoad.data.push(questionItem);
    }
    return payLoad;
  }
}

export class questionSet {
  id: number;
  actionPlanId: number;
  questionTitle: string;
  questionDescription: string;
  sort: number;
  active: number;
  showEmergencyContact: boolean;
  created: Date;
  updated: Date;
  options: any[] = [];
  isAddMore: boolean;
  language: string;
  groupAddAnswerList: any[] = [];
  answerData: any[] = [];
  deletedAnswers: [];
  fieldItemsType: string[] = [];
  isGroupAdd: boolean;
  isRequired: boolean;
  constructor(language: string, data?: any) {
    this.language = language;

    this.id = data.ID || data.id;
    this.actionPlanId =
      data.action_plan_id || data.ActionPlanID || data.actionPlanId;
      this.questionTitle =
      data["question_" + this.language] ||
      data["Question" + UtilityService.capitalizeFirstCharacter(this.language)] ||
      data.questionTitle ||
      "";
      this.questionDescription =
      data["question_description_" + this.language] ||
      data[
        "QuestionDescription" +
          UtilityService.capitalizeFirstCharacter(this.language)
      ] ||
      data.questionDescription ||
      "";
    this.sort = data.Sort || data.sort;
    this.active = data.Active || data.active;
    this.showEmergencyContact =
      data.show_emergency_contact ||
      data.ShowEmergencyContact ||
      data.showEmergencyContact
        ? true
        : false;
    this.created = UtilityService.convertToDate(
      data.created_at || data.CreatedOnUtc || data.created
    );
    this.updated = UtilityService.convertToDate(
      data.updated_at || data.UpdatedOnUtc || data.updated
    );
    this.isAddMore =
      data.is_addmore || data.IsAddmore || data.isAddMore ? true : false;
    this.answerData = data?.answerData || [];
    this.isGroupAdd =
      this.isAddMore && (data.fields?.length > 1 || data.options?.length > 1)
        ? true
        : false;
    this.deletedAnswers = data.deletedAnswers || [];
    if (typeof this.answerData === "object" && this.answerData !== null) {
      this.answerData = Object.values(this.answerData);
    }
    this.initializeOptionSet(data.fields || data.options);
    this.setAnswerData();
  }

  public initializeOptionSet(options?: any) {
    if (options.length) {
      for (let i = 0; i < options.length; i++) {
        this.options.push(new optionSet(this.language, options[i]));
        if (!this.fieldItemsType.includes(options[i].optionType)) {
          this.fieldItemsType.push(options[i].field_type);
        }
        // if (this.answerData.length) {
        //   this.setAnswerData(this.options[i]);
        // }
        if (options[i].required || options[i].Required) {
          this.isRequired = true;
        }
      }
    }
  }

  setAnswerData() {
    if (!this.isAddMore) {
      for (let i = 0; i < this.options.length; i++) {
        let item = _.find(this.answerData, {
          optionId: this.options[i].id as any,
        });
        if (item) {
          this.options[i].value = item.value;
          this.options[i].answerId = item.id;
        }
      }
    } else {
      for (let item of this.answerData) {
        let resultData = {
          isGroupAdd: item.isGrouped,
        };
        if (item.isGrouped) {
          for (let i = 0; i < this.options.length; i++) {
            let data = _.find(item.groupedValues, {
              optionId: this.options[i].id as any,
            });
            if (data) {
              data["title"] = this.options[i].optionTitle;
            }
          }
          resultData["items"] = item.groupedValues;
        } else {
          let data = _.find(this.options, {
            id: item.optionId as any,
          });
          if (data) {
            item["title"] = data.optionTitle;
          }
          resultData["items"] = item;
        }

        this.groupAddAnswerList.push(resultData);
      }
    }
  }
}

export class optionSet {
  id: number;
  questionId: number;
  optionType: string;
  required: boolean;
  optionTitle: string;
  optionDescription: string;
  placeholder: string;
  groupId: number;
  sort: number;
  active: number;
  created: Date;
  updated: Date;
  value: any;
  language: string;
  answerId: number;
  constructor(language: string, data?: any) {
    this.language = language;
    this.id = data.ID || data.id;
    this.questionId = data.QuestionID || data.question_id || data.questionId;
    this.optionType = data.FieldType || data.field_type || data.optionType;
    this.required = data.Required || data.required;
    this.optionTitle =
      data[
        "FieldTitle" + UtilityService.capitalizeFirstCharacter(this.language)
      ] ||
      data["field_title_" + this.language] ||
      data.optionTitle ||
      "";
    this.optionDescription =
      data[
        "FieldDescription" + UtilityService.capitalizeFirstCharacter(this.language)
      ] ||
      data["field_description_" + this.language] ||
      data.optionDescription ||
      "";
    this.placeholder =
      data[
        "Placeholder" + UtilityService.capitalizeFirstCharacter(this.language)
      ] ||
      data["placeholder_" + this.language] ||
      data.placeholder ||
      "";
    this.groupId = data.GroupId || data.group_id || data.groupId;
    this.sort = data.Sort || data.sort;
    this.active = data.Active || data.active;
    this.created = UtilityService.convertToDate(
      data.created_at || data.CreatedOnUtc || data.created
    );
    this.updated = UtilityService.convertToDate(
      data.updated_at || data.UpdatedOnUtc || data.updated
    );

    if (this.optionType == "date") {
      this.value = data.value || moment().format("YYYY-MM-DD");
    } else {
      this.value = data.value || "";
    }
  }
}

export class ActionPlanList {
  id: number;
  userId: number;
  actionPlanId: number;
  title: string;
  actionPlanDate: any;
  deleted: number;
  createdBy: number;
  ownedBy: number;
  created: Date;
  updated: Date;
  language: string;
  isAdminCreated: boolean;
  isUpdated: boolean;
  constructor(userId: number, data?: any) {
    this.language = data.langcode || data.LangCode || data.language;
    this.id = data.ID || data.id;
    this.userId = data.user_id || data.userId || data.UserID;
    this.actionPlanId = data.ActionPlanID || data.action_plan_id || data.actionPlanId;
    this.title = data.Title || data.title;
    this.actionPlanDate = moment(data.action_plan_date || data.ActionPlanDate || data.actionPlanDate);
    this.deleted = data.deleted || 0;
    this.createdBy = data.created_by || data.CreatedBy || data.createdBy;
    this.ownedBy = data.owned_by || data.ownedBy || "";
    this.isAdminCreated = this.createdBy == userId ? false : true;
    this.created = UtilityService.convertToDate(
      data.created_at || data.CreatedOnUtc || data.created
    );
    this.updated = UtilityService.convertToDate(
      data.updated_at || data.UpdatedOnUtc || data.updated
    );
    this.isUpdated = this.isShowUpdatedDate();
  }
  public static initializeArray(
    objects: any,
    userId: number
  ): ActionPlanList[] {
    let results: ActionPlanList[] = [];
    for (let i = 0; i < objects.length; i++) {
      let as = new ActionPlanList(userId, objects[i]);
      results.push(as);
    }
    return results;
  }

  isShowUpdatedDate() {
    const createdDate = moment(this.created);
    const updatedDate = moment(this.updated);

    if (createdDate.isSame(updatedDate)) {
      return false;
    } else {
      return true;
    }
  }
}

export class ActionPlanDashboard {
  id: number;
  userId: number;
  actionPlanId: number;
  title: string;
  actionPlanDate: any;
  deleted: number;
  createdBy: number;
  created: Date;
  updated: Date;
  language: string;
  isAdminCreated: boolean;
  isUpdated: boolean;
  questionTitle: string;
  questionId: number = 0;
  answers: string;
  hasAnswers: boolean;

  constructor(userId: number, data?: any) {
    this.id = data.ID || data.id;
    this.userId = data.user_id || data.userId || data.UserID;
    this.actionPlanId =
      data.ActionPlanID || data.action_plan_id || data.actionPlanId;
    this.title = data.Title || data.title;
    this.actionPlanDate = moment(
      data.action_plan_date || data.ActionPlanDate || data.actionPlanDate
    );
    this.language = data.langcode || data.LangCode || data.language;
    this.deleted = data.deleted || 0;
    this.createdBy = data.created_by || data.CreatedBy || data.createdBy;
    this.isAdminCreated = this.createdBy == userId ? false : true;
    this.created = UtilityService.convertToDate(
      data.created_at || data.CreatedOnUtc || data.created
    );
    this.updated = UtilityService.convertToDate(
      data.updated_at || data.UpdatedOnUtc || data.updated
    );
    this.isUpdated = this.isShowUpdatedDate();
    this.hasAnswers = data.hasOwnProperty("question");
  }
  public static initializeArray(
    objects: any,
    userId: number
  ): ActionPlanDashboard[] {
    let results: ActionPlanDashboard[] = [];
    for (let i = 0; i < objects.length; i++) {
      let ac = new ActionPlanDashboard(userId, objects[i]);
      if (objects[i].hasOwnProperty("question")) {
        let question: any = Object.values(objects[i].question);
        for (let i = 0; i < question.length; i++) {
          ac.questionId = question[i].id;
          ac.questionTitle = question[i].title;
          ac.answers = question[i].answers || [];
        }
      }
      results.push(ac);
    }
    return results;
  }

  isShowUpdatedDate() {
    const createdDate = moment(this.created);
    const updatedDate = moment(this.updated);

    if (createdDate.isSame(updatedDate)) {
      return false;
    } else {
      return true;
    }
  }
}
