export class UserPreferences {

	language: string = 'en';
	createdOnUtc: Date;
	updatedOnUtc: Date;
	timezone: string;
	notificationOn: boolean;
	wellnessNotifications: boolean;
	wellnessTrackerNotification: boolean;
	consent: number;
	moodcheckNotificationFrequency: number;

	constructor(data?: any) {
		if (data) {
			this.language = data.Language || data.language;
			this.createdOnUtc = data.CreatedOnUtc || data.createdOnUtc;
			this.updatedOnUtc = data.UpdatedOnUtc || data.updatedOnUtc;
			this.timezone = data.Timezone || data.timezone;
			this.notificationOn = data.NotificationOn || data.notificationOn || false;
			this.wellnessTrackerNotification = data.WellnessTrackerNotification || data.wellnessTrackerNotification || false;
			this.wellnessNotifications = data.WellnessNotifications || data.wellnessNotifications || false;
			this.moodcheckNotificationFrequency =
        		data.MoodcheckNotificationFrequency ||
        		data.moodcheckNotificationFrequency;
			if(data.hasOwnProperty('consent')){
				this.consent = data.consent; 
			}
			else if(data.hasOwnProperty('Consent')){
				this.consent = data.Consent;
			}
			else{
				/* This means that question has not been answered yet */
				this.consent = null; 
			}
		
		}
	}
}
