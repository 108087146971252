<div class="page-section">
	<div class="page-section-header">
		<div class="page-section-buttons">
			<a (click)="doCreate()" (keypress)="doCreate()" class="btn btn-primary btn-with-icon"
				translate>Create New Series</a>
		</div>
		<h1>Series</h1>
	</div>
	<div class="page-section-content">
		<page-loader *ngIf="!isLoaded"></page-loader>
		<div class="table-responsive" *ngIf="isLoaded">
			<table class="table">
				<thead>
					<tr>
						<th style="width:25%;" translate>Name</th>
						<th style="width:25%;"></th>
						<th style="width:25%;"></th>
						<th style="width:25%;"></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let course of courses">
						<td>{{course.label}}  <span *ngIf ="course.active === 0">(Unpublished)</span></td>
						<td><a tabindex="0" class="pull-right" [routerLink]="'/app/series-listing/' + course.key"
							 translate>View</a></td>
						<td><a tabindex="0" class="pull-right" (click)="onEdit(course.id)"
							(keypress)="onEdit(course.id)" translate>Edit</a></td>
						<td><a class="trigger-modal delete pull-right" tabindex="0" aria-label="Delete series" (click)="delete(course.id)"
                            (keypress)="delete(course.id)"><img src="./assets/img/icons-svg/x-icon.svg"></a></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
