
<page-loader *ngIf="loadedCount < 2"></page-loader>
<div id="page-resources" *ngIf="loadedCount >= 2" >
    <div class="app-l-resource__banner">
        <div class="app-l-resource__over app-l-container">
            <h2 translate>resources.title</h2>
            <p translate>resources.resourceCardDesc</p>
            <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="menuService.nav('/app/emergency-contact')"
			(keypress)="menuService.nav('/app/emergency-contact')">
                <svg-icon icon="get-help"></svg-icon>
                <span translate>resources.getHelpNow</span>
            </button>
        </div>
    </div>
    <!-- <div class="col-md-12">
        <div class="page-section-buttons">
            <a tabindex="0" (click)="onEdit()" (keypress)="onEdit()" class="btn btn-bold" translate>resources.editResourse</a>
        </div>
        <h2 translate>resources.title</h2>
    </div> -->
    <div class="app-l-page-wrap">
        
        <div class="app-l-resource-wrap">
            <div [innerHtml]="resources" class="resources"></div>
            <div class="app-l-resource-blocks" *ngIf="organization.resourceSet && organization.resourceSet.length > 0">
                <!--
                        <div class="col-md-12">
                            <div class="recommendedInstructions clearfix">
                                <img alt="Recommended" height="40" width="40" src="./assets/img/icons-svg/recommended-star.svg">
                                <h1 translate>resources.recommendedResources</h1>
                            </div>
                        </div>
                    -->

                <ng-container *ngFor="let resourceset of organization.resourceSet; let i = index; ">
                    <div *ngIf=showResourceByLanguage(resourceset) class="app-l-resource-block" [ngClass]="{active: assessmentsExists}">              
                        <div class="{{resourceset.className}}"
                            [ngClass]="{recommended: (resourceset.step && currentLevelMap && currentLevelMap.includes(resourceset.step))}"
                            (click)="onGoTo(i)" (keypress)="onGoTo(i)" [autoFocus]="{apply: i === 0}" tabindex="0"
                            aria-label="Learn more">
                            <div class="app-l-resource__image">
                                <!-- <img aria-hidden="true" alt=""  [src]="'./assets/img/icons/resources/' + resourceset.className + '.png'"> -->
                                <svg-icon [icon]="resourceset.className ? resourceset.className : 'default'"></svg-icon>
                            </div>
                            <div class="app-l-resource__content">
                                <h3 aria-live="polite" tabindex="0" class="app-c-body-text--16-m">
                                    <span translate>
                                        {{resourceset.title}}
                                    </span>
                                </h3>
                                <p class="app-c-body-text--12" aria-live="polite" tabindex="0"
                                    [innerHtml]="resourceset.summary"></p>
                                <!-- <a [autoFocus] = "{apply: i === 0}" tabindex="0" aria-label="Learn more" title="Learn more" class="trigger-modal btn btn-primary" (click)="onGoTo(i)" (keypress)="onGoTo(i)" translate>resources.learnmore</a> -->
                            </div>
                            <div class="recommended-star"
                                *ngIf="resourceset.step && currentLevelMap &&  currentLevelMap.includes(resourceset.step)">
                                <img alt="Recommended" height="30" width="30"
                                    src="./assets/img/icons-svg/recommended-star.svg">
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="empty()" class="app-l-no-mood-check" translate>
                <div class="no-data-image">
                    <svg-icon icon="scheduled-activities"></svg-icon>
                    <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
                    <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
                    <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
                </div>
                <h5 class="app-c-body-text--16-m" translate>resources.empty</h5>

            </div>
        </div>
    </div>