<div class="app-l-page-wrap">
	<page-loader *ngIf="!isLoaded"></page-loader>
	<div class="app-l-container" *ngIf="isLoaded">
		<div class="app-l-page__navigation">
			<div class="app-l-page__previous" [routerLink]="['/app/practice']">
				<button
					class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				>
					<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
				</button>
			</div>
			<div class="app-l-page__breadcrumbs">
				<ul>
					<li [routerLink]="['/app/practice']" translate>
						menu.tools
					</li>
					<li translate="">zenRoom.title</li>
				</ul>
			</div>
		</div>
		<div class="zen-room-main-container">
			<div class="zen-room-inner-container">
				<div class="app-dm-zen-type">
					<div class="app-dm-zen-type__header">
						<div class="app-dm-zen-type__header__left">
							<h3 class="app-dm-zen-type__heading" translate>
								zenRoom.ambiance
							</h3>
							<p class="app-dm-zen-type__desc" translate>
								zenRoom.selectAmbiance
							</p>
						</div>
						<div class="app-dm-zen-type__header__right">
							<div class="app-dm-zen-type__search">
								<span class="icon"
									><i class="fa fa-search"></i
								></span>
								<input
									type="search"
									(input)="searchChange($event, 'ambiance')"
									id="search"
									maxlength="30"
									placeholder="{{
										'zenRoom.searchAmbiance' | translate
									}}"
									*ngIf="ambianceTrackList.length"
									tabindex="0"
									role="input"
									aria-label="Search Ambiance Track"
								/>
							</div>
						</div>
					</div>

					<div
						class="app-zen-owl-carousel middle"
						*ngIf="ambianceTrackList.length"
						tabindex="1"
						role="region"
						aria-label="Ambiance Carousel"
					>
						<owl-carousel-o [options]="customOptions">
							<ng-template
								carouselSlide
								tabindex="2"
								*ngFor="let ambiance of ambianceTrackList"
							>
								<div
									class="slide"
									(click)="onChooseBackgroundTrack(ambiance)"
								>
									<input
										type="radio"
										name="ambianceType"
										[checked]="ambiance.isSelected"
									/>
									<div
										class="app-dm-slide-box text-center ambiance-border"
									>
										<div class="app-dm-box-image">
											<img
												[defaultImage]="
													'./assets/img/NoImage.svg'
												"
												[lazyLoad]="ambiance.imageUrl"
												class="img-fluid rounded-top"
												alt="{{ ambiance.key }}"
											/>
											<span>{{ ambiance.title }}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</owl-carousel-o>

						<div
							class="noData"
							translate
							*ngIf="!ambianceTrackList.length"
						>
							zenRoom.noDataFound
						</div>
					</div>
				</div>
				<div class="app-dm-zen-type">
					<div class="app-dm-zen-type__header">
						<div class="app-dm-zen-type__header__left">
							<h3 class="app-dm-zen-type__heading" translate>
								zenRoom.meditationType
							</h3>
							<p class="app-dm-zen-type__desc" translate>
								zenRoom.selectMeditation
							</p>
						</div>
						<div class="app-dm-zen-type__header__right">
							<div class="app-dm-zen-type__search">
								<span class="icon"
									><i class="fa fa-search"></i
								></span>
								<input
									type="search"
									(input)="searchChange($event, 'meditation')"
									id="search"
									placeholder="{{
										'zenRoom.searchMeditation' | translate
									}}"
									tabindex="3"
									role="input"
									aria-label="Search Meditation Track"
								/>
							</div>
						</div>
					</div>
					<div
						class="app-zen-owl-carousel middle"
						*ngIf="meditationTrackList.length"
						tabindex="4"
						role="region"
						aria-label="Meditation Carousel"
					>
						<owl-carousel-o [options]="customOptions">
							<ng-template
								carouselSlide
								*ngFor="let meditation of meditationTrackList"
							>
								<div
									class="slide"
									(click)="onChooseVoiceTrack(meditation)"
								>
									<label>
										<input
											type="radio"
											name="meditationType"
											[checked]="meditation.isSelected"
										/>
										<div
											class="app-dm-slide-box text-center meditation-border"
										>
											<div class="app-dm-box-image">
												<img
													[defaultImage]="
														'./assets/img/NoImage.svg'
													"
													[lazyLoad]="
														meditation.imageUrl
													"
													class="img-fluid rounded-top"
													alt="{{ meditation.key }}"
												/>
												<span>{{
													meditation.title
												}}</span>
											</div>
										</div>
									</label>
								</div>
							</ng-template>
						</owl-carousel-o>
						<div
							class="noData"
							translate
							*ngIf="!meditationTrackList.length"
						>
							zenRoom.noDataFound
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="app-dm-zen-player">
	<div class="app-dm-zen-footer">
		<div class="app-dm-zen-footer__left">
			<div class="app-dm-play-wrapper">
				<div class="circle pulse"></div>
				<a
					class="circle"
					[ngClass]="{
						noAudioSelected:
							this.ambianceType === 'none' &&
							this.voiceType === 'none'
					}"
					tabindex="0"
					(click)="onPlay()"
				>
					<svg
						style="margin: 14px 12px 14px 16px"
						*ngIf="!playing"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 17 18"
						fill="none"
					>
						<path
							d="M2.3013 18.9312C2.00158 18.9307 1.70717 18.8521 1.44702 18.7033C0.861235 18.3715 0.49707 17.7273 0.49707 17.0279V2.87524C0.49707 2.17395 0.861235 1.53171 1.44702 1.19986C1.71336 1.04683 2.01593 0.968135 2.32309 0.971998C2.63026 0.975862 2.93075 1.06214 3.19316 1.22182L15.2921 8.46214C15.5443 8.6202 15.7521 8.8397 15.8962 9.10005C16.0403 9.3604 16.1159 9.65307 16.1159 9.95061C16.1159 10.2482 16.0403 10.5408 15.8962 10.8012C15.7521 11.0615 15.5443 11.281 15.2921 11.4391L3.1912 18.6814C2.92267 18.8437 2.61509 18.93 2.3013 18.9312Z"
							fill="white"
						/>
					</svg>
					<svg
						class="pause"
						style="margin: 12px 11px 12px 13px"
						*ngIf="playing"
						width="20"
						height="20"
						viewBox="0 0 13 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.18494 0.87085H1.68494C1.28711 0.87085 0.905581 1.02888 0.624277 1.31019C0.342972 1.59149 0.184937 1.97302 0.184937 2.37085V14.3708C0.184937 14.7687 0.342972 15.1502 0.624277 15.4315C0.905581 15.7128 1.28711 15.8708 1.68494 15.8708H3.18494C3.58276 15.8708 3.96429 15.7128 4.2456 15.4315C4.5269 15.1502 4.68494 14.7687 4.68494 14.3708V2.37085C4.68494 1.97302 4.5269 1.59149 4.2456 1.31019C3.96429 1.02888 3.58276 0.87085 3.18494 0.87085ZM10.6849 0.87085H9.18494C8.78711 0.87085 8.40558 1.02888 8.12428 1.31019C7.84297 1.59149 7.68494 1.97302 7.68494 2.37085V14.3708C7.68494 14.7687 7.84297 15.1502 8.12428 15.4315C8.40558 15.7128 8.78711 15.8708 9.18494 15.8708H10.6849C11.0828 15.8708 11.4643 15.7128 11.7456 15.4315C12.0269 15.1502 12.1849 14.7687 12.1849 14.3708V2.37085C12.1849 1.97302 12.0269 1.59149 11.7456 1.31019C11.4643 1.02888 11.0828 0.87085 10.6849 0.87085Z"
							fill="white"
						/>
					</svg>
				</a>
			</div>
			<div class="current-playing-card-content">
				<audio
					hidden
					#ambianceaudioplayer
					id="ambiance-audio-player"
					preload="none"
					crossorigin="anonymous | use-credentials"
				>
					<source id="ambiance-source" [type]="'audio/mp3'" />
				</audio>
				<div
					class="title-name"
					*ngIf="ambianceType === 'none' && voiceType === 'none'"
					translate
				>
					zenRoom.noTrackSelected
				</div>
				<div
					class="title-name"
					*ngIf="ambianceType != 'none' || voiceType != 'none'"
				>
					{{ ambianceName }}-{{ meditationName }}
				</div>
				<vg-player
					class="audio-player audio-player-v2"
					(onPlayerReady)="onPlayerReady($event)"
				>
					<vg-controls class="player-controls">
						<vg-scrub-bar
							class="scrub-bar"
							vgFor="meditation-audio-player"
							[vgSlider]="false"
						>
							<vg-scrub-bar-current-time
								class="meditation-progress-bar"
								id="progress-bar"
							></vg-scrub-bar-current-time>
							<vg-scrub-bar-buffering-time
								class="buffer-bar"
							></vg-scrub-bar-buffering-time>
						</vg-scrub-bar>
						<div
							class="display-time"
							*ngIf="this.voiceType != 'none'"
						>
							<vg-time-display
								class="play-time"
								id="play-time"
								vgProperty="current"
								vgFormat="mm:ss"
							></vg-time-display>
							<vg-time-display
								class="total-time"
								id="total-time"
								vgProperty="total"
								vgFormat="mm:ss"
							></vg-time-display>
						</div>
						<div
							class="display-time-none"
							*ngIf="this.voiceType === 'none'"
						>
							<label
								class="play-time"
								id="play-time"
								vgProperty="current"
								vgFormat="mm:ss"
								>00:00</label
							>
							<label
								class="total-time"
								id="total-time"
								vgProperty="total"
								vgFormat="mm:ss"
								>00:00</label
							>
						</div>
					</vg-controls>
					<audio
						#meditationaudioplayer
						#media
						[vgMedia]="media"
						id="meditation-audio-player"
						preload="none"
						crossorigin="anonymous | use-credentials"
						volume="0"
					>
						<source id="audio-source" [type]="'audio/mp3'" />
						<track
							kind="subtitles"
							class="subtest"
							[label]="'English'"
							default
							[src]="vttUrl"
						/>
					</audio>
				</vg-player>
			</div>
		</div>
		<div class="app-dm-zen-footer__right">
			<div class="app-dm-left-radial-gradient">
				<p class="playing-title-h1" translate>zenRoom.ambiance</p>
				<mat-slider
					min="0"
					max="100"
					[(ngModel)]="backgroundVolume"
					[ngClass]="{
						noAudioSelected: this.ambianceType === 'none'
					}"
					(ngModelChange)="backgroundVolumeChange($event)"
					class="ambiance-volume"
				>
				</mat-slider>
				<span class="playing-title-span">{{ backgroundVolume }}%</span>
			</div>
			<div class="app-dm-right-radial-gradient">
				<p class="playing-title-h1" translate>zenRoom.meditationType</p>
				<mat-slider
					min="0"
					max="100"
					[(ngModel)]="voiceVolume"
					[ngClass]="{
						noAudioSelected: this.voiceType === 'none'
					}"
					(ngModelChange)="voiceVolumeChange($event)"
					class="meditation-volume"
				>
				</mat-slider>

				<span class="playing-title-span">{{ voiceVolume }}%</span>
				<!-- </div> -->
			</div>
		</div>
	</div>
	<div
		#subtitle
		id="subtitle"
		class="subtitle"
		[ngClass]="{ 'subtitle-bg': showSubtitle }"
	></div>
</div>
