import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { SharedModule } from "../../shared/shared.module";
import { PracticeRoutingModule } from "./practice-routing.module";
import { PracticeComponent } from "./practice/practice.component";
import { ThoughtDiaryListingPage } from "./thoughtdiary/thoughtdiary.component";
import { ThoughtDiaryDetailsPage } from "./thoughtdiary-details/thoughtdiary-details.component";
import { FunAchievementListingPage } from "./fun-achievement-listing/fun-achievement-listing.component";
import { FunAchievementDetailsPage } from "./fun-achievement-details/fun-achievement-details.component";
import { ActivitySchedulerDetails } from "./activity-scheduler-details/activity-scheduler-details";
import { AddictionDetailsPage } from "./addiction-details/addiction-details";
import { ZenRoomPage } from "./zen-room/zen-room.component";
import { CognitiveQuiz } from "./cd-quiz/cd-quiz.component";
import { MatTabsModule } from "@angular/material/tabs";
import { RoomForPositiveLisitingComponent } from "./room-for-positive-lisiting/room-for-positive-lisiting.component";
import { RoomForPositiveCreateComponent } from "./room-for-positive-create/room-for-positive-create.component";
import { RoomForPositiveViewComponent } from "./room-for-positive-view/room-for-positive-view.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { CarouselModule } from "ngx-owl-carousel-o";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";
// import { ActionPlanListingComponent } from './action-plan-listing/action-plan-listing.component';
import { ActionPlanDetailComponent } from './action-plan-detail/action-plan-detail.component';
import { ActionPlanPreviewComponent } from './action-plan-preview/action-plan-preview.component';
import { ActionPlanListingPage } from './action-plan/action-plan.component';
import { NewlineToBrPipe } from "../../pipes/newLineToBr";

@NgModule({
	declarations: [
		PracticeComponent,
		ThoughtDiaryListingPage,
		ThoughtDiaryDetailsPage,
		FunAchievementListingPage,
		FunAchievementDetailsPage,
		ActivitySchedulerDetails,
		AddictionDetailsPage,
		ZenRoomPage,
		CognitiveQuiz,
		RoomForPositiveLisitingComponent,
		RoomForPositiveCreateComponent,
		RoomForPositiveViewComponent,
		// ActionPlanListingComponent,
		ActionPlanDetailComponent,
		ActionPlanPreviewComponent,
		ActionPlanListingPage,
	],
	imports: [
		CommonModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		PracticeRoutingModule,
		SharedModule,
		MatTabsModule,
		MatExpansionModule,
		FormsModule,
		ReactiveFormsModule,
		CarouselModule,
		LazyLoadImageModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		MatSliderModule,
		MatTooltipModule,
	],
	providers: [],
	exports: [],
	entryComponents: [
		RoomForPositiveCreateComponent,
		RoomForPositiveViewComponent,
	],
})
export class PracticeModule {}
