import {
    Component,
    ViewChild,
    ViewEncapsulation,
    OnInit,
    Inject,
} from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { ModalService } from '../../lib/modal.service';
import { LogService } from '../../lib/log.service';
import { User } from '../../models/user';
import { UserService } from '../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilityService } from '../../lib/utility.service';
import { Organization } from '../../models/organization';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { StorageService } from 'app/lib/storage.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-invite-professional',
    templateUrl: './invite-professional.component.html',
    styleUrls: ['./invite-professional.component.scss'],
})
export class InviteProfessional implements OnInit {
    type: string;
    message: string = '';
    email: string;
    success: boolean = false;
    share: boolean = false;
    showShare: boolean = false;
    user: User;
    orgID: number;
    popup: any;
    isAdmin = false;
    endpoint = '';
    options = [];
    admin: boolean = false;
    admintitle: string;
    userShare: boolean;
    subscribe: boolean;
    response: string;
    emailValidate: boolean;
    inviteForm: FormGroup;
    organizations: any = [];
    roles = [
        {
            id: 1,
            name: 'Counselor-Ability to sync with clients',
            value: 'counselor',
        },
        {
            id: 2,
            name: 'Admin - Admin permissions only, no client contact',
            value: 'admin',
        },
        {
            id: 3,
            name: 'Admin Counselor-Admin and Counselor permissions',
            value: 'adminCounselor',
        },
        {
            id: 4,
            name: 'User-Client',
            value: 'user',
        },
    ];
    inviteType: any;
    constructor(
        private api: ApiService,
        private log: LogService,
        private modalService: ModalService,
        private userService: UserService,
        public dialogRef: MatDialogRef<InviteProfessional>,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private utility: UtilityService,
        private storage: StorageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.inviteType = data.data.key;
        this.user = this.userService.getUser();
        let inviteParams = data.data;
        this.type = inviteParams.type;
        this.orgID = inviteParams.orgId;
        // this.endpoint = inviteParams.endpoint;
        if (inviteParams.forceShare) {
            this.share = true;
            this.showShare = true;
        }

        this.createInviteForm();

        // this.api.get("admin/organizations").subscribe(
        // 	(results: any) => {
        // 		this.organizations = Organization.initializeArray(results.data);
        // 	},
        // 	(error: any) => {
        // 		this.log.error("Error loading. " + error.message);
        // 	}
        // );
    }

    createInviteForm() {
        this.inviteForm = this.formBuilder.group({
            email: [
                '',
                [
                    Validators.required,
                    Validators.pattern(this.utility.emailValidationPattern()),
                ],
            ],
            role: [
                this.inviteType === 'clientInvite' ? 'user' : '',
                [Validators.required],
            ],
            message: [''],
            orgID: [this.user.organizations[0].id],
            share: [false],
        });
    }

    ngOnInit() {
        this.translate.stream('invite').subscribe((res: any) => {
            this.popup = res.popups;
            this.options = res.admin.types;
        });
        this.getOrganizations();
    }

    get f() {
        return this.inviteForm.controls;
    }
    getOrganizations() {
        this.api
            .get('organizations/children', { includeParent: true })
            .subscribe(
                (result: any) => {
                    this.organizations = Organization.initializeArray(
                        result.data
                    );
                    this.log.debug(this.organizations);
                    if (this.user.organizations) {
                        let orgId = this.user.organizations[0].id;
                        let index = this.organizations.findIndex(
                            org => org.id === orgId
                        );
                        // if (index !== -1) {
                        // 	this.selected = index;
                        // }
                    }
                    if (this.storage.get('orgselect')) {
                        let index = _.findIndex(this.organizations, {
                            id: +this.storage.get('orgselect'),
                        });
                        if (index !== -1) {
                            // this.selected = index;
                        } else {
                            this.storage.set('orgselect', null, false);
                        }
                    }
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organizations. ' + error.message
                    );
                    if (this.storage.get('orgselect')) {
                        this.storage.set('orgselect', null, false);
                        window.location.reload();
                    }
                },
                () => {}
            );
    }

    sendInvite() {
        // if (!this.email) {
        // 	this.modalService.showAlert(this.popup.error, this.popup.blank);
        // 	return;
        // }

        if (this.inviteForm.invalid) {
            return;
        }
        let payload = {
            Email: this.inviteForm.value.email,
            Message: this.inviteForm.value.message,
            OrgID: this.inviteForm.value.orgID
                ? this.inviteForm.value.orgID
                : this.orgID,
        };
        if (this.showShare) {
            payload['Share'] = this.inviteForm.value.share;
        }
        if (this.inviteType !== 'clientInvite') {
            payload['Orgadmin'] = true;
        }
        switch (this.inviteForm.value.role) {
            case 'counselor': {
                this.endpoint = '/proinvite';
                break;
            }
            case 'admin': {
                this.endpoint = '/adminexecutive';
                break;
            }
            case 'adminCounselor': {
                this.endpoint = '/adminexecutiveprofessional';
                break;
            }
            case 'user': {
                this.endpoint = '/userinvite';
                break;
            }
            default: {
            }
        }
        this.api.post('invitations' + this.endpoint, payload).subscribe(
            (result: any) => {
                this.success = true;
                this.log.event(AnalyticEvent.event.userInvitationSent);
                this.response = result.message;
                this.dialogRef.close(result);
            },
            (error: any) => {
                this.modalService.showAlert(this.popup.error, error.message);
                this.log.error('Error fetching user. ' + error.message);
            }
        );
    }

    close() {
        this.dialogRef.close();
        this.success = false;
    }
    showShareOpt() {
        if (
            this.inviteForm.value.role &&
            this.inviteForm.value.role === 'user' &&
            this.user.permissions.hasOwnProperty('clientList') &&
            this.user.permissions['clientList']
        ) {
            this.showShare = true;
        } else {
            this.showShare = false;
        }
    }
}
