import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';

@Component({
  selector: 'app-additional-resources',
  templateUrl: './additional-resources.html',
  styleUrls: ['./additional-resources.scss']
})
export class AdditionalResourcesPage implements OnInit {
  user: User;
  backLink: string;
  backText: string;
  cssClass: string;
  headerText: string;
  ssoLink: string;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getUser();
    this.backLink = '/app/theory';
    this.backText = 'Back';
    this.cssClass = 'additional-resources';
    this.headerText = 'Additional Resources';
    this.getOrganizationDetails();
  }

  goToResource(link: string) {
    window.open(link);
    this.apiService.post('analytics/additionalresources', {
      link: link,
      orgId: this.user.primaryOrganization.id
    }).subscribe(
      (result: any) => {
        window.open(link);
      },
      (error: any) => {
        this.logService.error('Error logging link click');
      }
    );
  }

  getOrganizationDetails(){
    this.apiService.get('organizations/' + this.user.primaryOrganization.id).subscribe(
      (result: any) => {
        this.logService.debug('organization detected');
        const organization = new Organization(result.data, 'full');
        this.ssoLink = organization.integrations.togetherAllSsoLink;
      },
      (error: any) => {
        this.logService.error('Error getting organizations. ' + error.message);
      });
  }

}
