<!-- <div tabindex="0" (click)="onChangeLanguage(language)" (keydown)="onPressTabKey($event)" id="language-picker" [ngClass]="color + ' ' + page">
    <a tabindex="-1" title="Language" alt="Language" 
    ><span translate>languageSwitcher.label</span>: {{language}}</a>
</div> -->
<div id="language-picker" class="app-l-lang__choose" [ngClass]="color + ' ' + page">
    <div class="language-picker">
        <div class="app-l-lang__choose-over">
            <span *ngIf="page != 'inside'">{{'languageSwitcher.label' | translate }}:</span>
            <div class="app-l-lang__globe" *ngIf="page == 'inside'" (click)="toggleSubMenu()">
                <svg-icon icon="globe" class="w-100 h-100"></svg-icon>
            </div>
            <span class="app-l-lang__selected" (click)="toggleSubMenu()">{{language}}</span>
            <button class="app-l-lang__arrow" (click)="toggleSubMenu()">
                <svg-icon icon="angle-down" class="w-100 h-100"></svg-icon>
            </button>
            <!-- <div *ngIf="page != 'inside'" class="submenu-container" [ngClass]="{ 'show': showSubMenu }">
                <div class="dropdown-content">
                    <a tabindex="0" (click)="onChangeLanguage('En')">
                        <span class="submenu-title" translate>
                            EN
                        </span>
                    </a>
                </div>
                <div class="dropdown-content">
                    <a tabindex="0" (click)="onChangeLanguage('Fr')">
                        <span class="submenu-title" translate>
                            FR
                        </span>
                    </a>
                </div>
                <div class="dropdown-content">
                    <a tabindex="0" (click)="onChangeLanguage('Es')">
                        <span class="submenu-title" translate>
                            ES
                        </span>
                    </a>
                </div>
            </div> -->
        </div>


        <div class="submenu-container" [ngClass]="{ 'show': showSubMenu }">
            <div class="dropdown-content">
                <a tabindex="0" (click)="onChangeLanguage('En')">
                    <span class="submenu-title" translate>
                        EN
                    </span>
                </a>
            </div>
            <div class="dropdown-content">
                <a tabindex="0" (click)="onChangeLanguage('Fr')">
                    <span class="submenu-title" translate>
                        FR
                    </span>
                </a>
            </div>
            <div class="dropdown-content">
                <a tabindex="0" (click)="onChangeLanguage('Es')">
                    <span class="submenu-title" translate>
                        ES
                    </span>
                </a>
            </div>
        </div>

    </div>
</div>