import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../../lib/api.service";
import { ModalService } from "../../../lib/modal.service";
import { LogService } from "../../../lib/log.service";
import { User } from "../../../models/user";
import { UserService } from "../../../lib/user.service";
import { ThoughtDiary, ThinkingStyle, ThoughtDiaryEntry, DefaultThinkingStyles } from "../../../models/thought-diary";
import { TranslateService } from "@ngx-translate/core";
import { UtilityService } from "../../../lib/utility.service";
import { AnalyticEvent } from "app/lib/analytic-event";
import * as _ from "lodash";
import { Location } from "@angular/common";
declare var jQuery: any;

@Component({
	selector: "app-thoughtdiary-details",
	templateUrl: "./thoughtdiary-details.component.html",
	styleUrls: ["./thoughtdiary-details.component.scss"],
})
export class ThoughtDiaryDetailsPage
	implements OnInit, OnDestroy, AfterViewInit
{
	id: number;
	sub: Subscription;
	isLoaded = false;
	diary: ThoughtDiary;
	viewMode: string;
	step: number;
	steps: number[];
	totalSteps = 6;
	backLink = "/app/practice/thoughtdiary";
	helpSections = {
		thinkingStyles: false,
		feelings: false,
		thoughts: false,
	};
	title: string;
	back: string;
	popups: any;
	thinkingStyles: Array<Object> = [];
	thinkingStylesKeys: Array<string> = [];
	user: User;
	feeling: string;
	
	presetFeelings: Array<string> = [];
	diaryEntry = new ThoughtDiaryEntry(null);
	isDisabled = false;
	stepsCompleted: Array<boolean> = [false, false, false, false, false, false];
	formatLabel(value: number): string {
		if (value >= 1000) {
			return Math.round(value / 1000) + "k";
		}

		return `${value}`;
	}
	constructor(
		private activatedRoute: ActivatedRoute,
		private api: ApiService,
		private router: Router,
		private modalService: ModalService,
		private log: LogService,
		private userService: UserService,
		private utilityService: UtilityService,
		private translate: TranslateService,
		private location: Location
	) {
		this.popups = {};
		this.diary = new ThoughtDiary(null);
		this.diary.status = "inprogress";
		this.step = 1;
		this.viewMode = "";
		this.steps = Array(this.totalSteps)
			.fill(0)
			.map((x, i) => i + 1);
		this.user = this.userService.getUser();
	}

	ngOnInit() {
		this.translate.stream("thoughtDiary").subscribe((res: any) => {
			let thinkingStyles = res.thinkingStyles;
      		this.title = res.title;
      		this.popups = res.popups;
			this.presetFeelings = res.presetFeelings;
			if (thinkingStyles) {
      		for (let thinkingStyle of thinkingStyles) {
        		this.thinkingStylesKeys.push(thinkingStyle.key);
        		this.thinkingStyles[thinkingStyle.key] = {
          			info: thinkingStyle.info,
          			text: thinkingStyle.text,
        		};
      		}
			}
			this.activatedRoute.params.subscribe((params) => {
				this.id = params["id"];
				if (this.id) {
				  this.getThoughtDairyDetailsById(this.id);
				} else {
				  this.isLoaded = true;
				  this.viewMode = "create";
				  this.resetDiaryFeeingEntry();
				}
			  });
		});
	}

	ngAfterViewInit() {
		jQuery(document).ready(() => {});
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	getThoughtDairyDetailsById(id: number) {
		this.api.get("theory/thoughtdiary/" + id).subscribe(
		  (result: any) => {
			this.isLoaded = true;
			this.log.debug("Thought diary data fetched");
			//this.log.debug(result.data);
			this.diary = new ThoughtDiary(result.data);
			//this.log.debug(this.diaries);
	
			if (this.diary.status === "complete") {
			  this.viewMode = "readonly";
			  this.isDisabled = true;
			  this.checkStepsCompleted();
			} else {
			  this.viewMode = "edit";
			  this.resetDiaryFeeingEntry();
			  //add a blank feeling if non exists
			  if (this.diary.entries.length === 0) {
				this.step = 1;
			  }
			  this.checkStepsCompleted();
			}
			if (this.user.id !== this.diary.userId) {
				this.backLink =
					"/professional/user-details/" + this.diary.userId;
			} else {
				this.backLink = "/app/practice/thoughtdiary";
			}

			if (this.user.userType === "orgadmin") {
				this.backLink =
					"/app/user-details/" + this.diary.userId;
			}
		  },
		  (error: any) => {
			this.log.error("Error getting thought diary. " + error.message);
		  },
		  () => {
			this.isLoaded = true;
	
			if (this.viewMode === "readonly") {
			  this.isDisabled = true;
			setTimeout(() => {
				this.makeReadOnly();
			}, 250);
			}
		  }
		);
	}
	
	goBack() {
		if (this.diary.thought.event && this.viewMode != "readonly") {
			this.modalService.showConfirmation("Alert", this.popups.goBackMsg).afterClosed().subscribe(result => {
				if (result) {
					this.onSaveForLater();
			  } else {
				this.router.navigate(['app/practice/thoughtdiary']);
			  }
			});
		  } else {
			this.router.navigate(['app/practice/thoughtdiary']);
		  }
	}

	makeReadOnly() {
		jQuery("#page-thoughtdiary input").attr("disabled", true);
		jQuery("#page-thoughtdiary textarea").attr("disabled", true);
	}

	isValid() {
		//TODO: add validation for each step
		if (this.step === 1) {
			if (
				!this.diary.thought.event ||
				this.diary.thought.event.length === 0
			) {
				this.modalService.showAlert(
					this.popups.validation.header,
					this.popups.validation.bodys[0]
				);
				return false;
			} else {
				this.stepsCompleted[0] = true;
				return true;
			  }
		} else if (this.step === 2) {
			if (!this.diary.entries.length) {
				this.modalService.showAlert(
					this.popups.validation.header,
					this.popups.validation.bodys[1]
				);
				this.log.debug("break");
				return false;
			  } else {
			  	this.stepsCompleted[1] = true;
			  	return true;
			  }
		} else if (this.step === 3) {
			for (let i = 0; i < this.diary.entries.length; i++) {
				let entry = this.diary.entries[i];
				if (!entry.thought || entry.thought.length === 0) {
					this.modalService.showAlert(
						this.popups.validation.header,
						this.popups.validation.bodys[2]
					);
					return false;
				}
			}
			this.stepsCompleted[2] = true;
     		return true;
		} else if (this.step === 4) {
			for (let i = 0; i < this.diary.entries.length; i++) {
				let entry = this.diary.entries[i];

				if (!entry.hasThinkingStyle()) {
					this.modalService.showAlert(
						this.popups.validation.header,
						this.popups.validation.bodys[3]
					);
					return false;
				}
			}
			this.stepsCompleted[3] = true;
      		return true;
		} else if (this.step === 5) {
			for (let i = 0; i < this.diary.entries.length; i++) {
				let entry = this.diary.entries[i];
				if (!entry.challenge || entry.challenge.length === 0) {
					this.modalService.showAlert(
						this.popups.validation.header,
						this.popups.validation.bodys[4]
					);
					return false;
				}
			}
			this.stepsCompleted[4] = true;
      		return true;
		} else if (this.step === 6) {
			if (
				!this.diary.thought.plan ||
				this.diary.thought.plan.length === 0
			) {
				this.modalService.showAlert(
					this.popups.validation.header,
					this.popups.validation.bodys[4]
				);
				return false;
			}
			this.stepsCompleted[5] = true;
			return true;
		}
	}

	onNext() {
		if (!this.isValid()) {
			return;
		}

		this.step++;

		this.scrollToNext();
	}
	onSaveForLater() {
		if (this.viewMode === "edit") {
			if (this.utilityService.demoMode()) {
				return;
			}
			this.api.put("theory/thoughtdiary", this.diary.forApi()).subscribe(
				(data: any) => {
					this.modalService.showAlert(
						this.popups.success.header,
						this.popups.success.body
					);
					this.log.event(
						AnalyticEvent.event.thoughtDairyDetailsSaveLater
					  );
					this.router.navigate(['app/practice/thoughtdiary']);
				},
				(error: any) => {
					this.log.error("thoughtdiary_error");
					this.modalService.showAlert(
						this.popups.error,
						error.message
					);
				}
			);
		} else if (this.viewMode === "create") {
				if (this.utilityService.demoMode()) {
					return;
				}
				this.api
					.post("theory/thoughtdiary", this.diary.forApi())
					.subscribe(
						(data: any) => {
							this.modalService.showAlert(
								this.popups.success.header,
								this.popups.success.later
							);
							this.router.navigate(['app/practice/thoughtdiary']);
						},
						(error: any) => {
							this.log.error("thoughtdiary_error");
							this.modalService.showAlert(
								this.popups.error,
								error.message
							);
						}
					);
		}
	}

	onComplete() {
		if (!this.isValid()) {
			return;
		}

		this.diary.status = "complete";

		if (this.utilityService.demoMode()) {
			return;
		}

		if (this.viewMode === "edit") {
			this.api.put("theory/thoughtdiary", this.diary.forApi()).subscribe(
				(data: any) => {
					this.modalService.showAlert(
						this.popups.success.header,
						this.popups.success.body
					);
					this.log.event(
						AnalyticEvent.event.thoughtDairyDetailsUpdate
					);
					this.router.navigate(['app/practice/thoughtdiary']);
				},
				(error: any) => {
					this.log.error("thoughtdiary_error");
					this.modalService.showAlert(
						this.popups.error,
						error.message
					);
				}
			);
		} else if (this.viewMode === "create") {
			this.api.post("theory/thoughtdiary", this.diary.forApi()).subscribe(
				(data: any) => {
					this.modalService.showAlert(
						this.popups.success.header,
						this.popups.success.body
					);
					this.log.event(
						AnalyticEvent.event.thoughtDairyDetailsCreate
					);
					setTimeout(() => {
						this.router.navigate(['app/practice/thoughtdiary']);
					  }, 2000);
				},
				(error: any) => {
					this.log.error("thoughtdiary_error");
					this.modalService.showAlert(
						this.popups.error,
						error.message
					);
				}
			);
		}
	}

	// onAddFeeling() {
	// 	this.diary.addFeeling();
	// 	this.diary.entries[this.diary.entries.length - 1].belief = 50;
	// 	this.diary.entries[this.diary.entries.length - 1].intensity = 50;
	// }


	onToggleHelpSection(helpSection: string) {
		this.helpSections[helpSection] = !this.helpSections[helpSection];
	}

	scrollToNext() {
		setTimeout(() => {
			let element = document.getElementById("step-" + this.step);
			element.scrollIntoView();
		}, 100);
	}

	validateInput(value, i) {
		value = parseInt(value);

		if (value) {
			if (value < 0) {
				value = 0;
			}

			if (value > 100) {
				value = 100;
			}
		} else {
			value = 0;
		}
		this.diary.entries[i].intensity = value;
	}
	onCreateFeeling(data?: string) {
		if (data) {
		  this.feeling = data;
		}
		this.diaryEntry.feeling = this.feeling;
	  }
	  onAddFeeling() {
		if (this.viewMode === "readonly") {
		  return;
		}
		this.diaryEntry.thinkingStyles = _.cloneDeep(DefaultThinkingStyles);
		this.diary.entries.push(_.cloneDeep(this.diaryEntry));
		console.log(this.diary);
		
		this.resetDiaryFeeingEntry();
		// this.diary.entries[this.diary.entries.length - 1].belief = 50;
		// this.diary.entries[this.diary.entries.length - 1].intensity = 50;
	  }
	  resetDiaryFeeingEntry() {
		this.feeling = null;
		this.diaryEntry.feeling = null;
		this.diaryEntry.intensity = 5;
		this.diaryEntry.belief = 5;
	  }

	  onToggleThinkingStyle(thinkingStyle: ThinkingStyle) {
		if (this.viewMode === "readonly") {
		  return;
		}
		this.log.debug(this.diary.entries[0].thinkingStyles);
		thinkingStyle.isSelected = !thinkingStyle.isSelected;
	  }

	  deleteFeeling(index: number) {
		this.diary.entries.splice(index, 1);
	  }
	  prevStep() {
		this.step--;
	  }
	  checkStepsCompleted() {
		if (this.viewMode == "readonly") {
		  this.stepsCompleted = this.stepsCompleted.map(() => true);
		} else {
		  for (let i = 1; i <= 6; i++) {
			switch (i) {
			  case 1: {
				if (this.diary.thought.event) {
				  this.stepsCompleted[0] = true;
				  this.step = 1;
				  break;
				} else {
				  return true;
				}
			  }
			  case 2: {
				if (this.diary.entries.length) {
					this.stepsCompleted[1] = true;
					this.step = 2;
					break;
				  } else {
					return true;
				  }
			  }
			  case 3: {
				let stepCompleted: boolean;
				for (let i = 0; i < this.diary.entries.length; i++) {
					if (typeof this.diary.entries[i].thought != "undefined") {
					  stepCompleted = true;
					} else {
					  stepCompleted = false;
					  return;
					  }
				  }
				  if (stepCompleted) {
					this.stepsCompleted[2] = true;
					this.step = 3;
					break;
				  } else {
					return true;
				  }
			  }
			  case 4: {
				let stepCompleted: boolean;
				for (let i = 0; i < this.diary.entries.length; i++) {
				  let ar = this.diary.entries[i].thinkingStyles.filter(
					(it) => it["isSelected"] === true
				  );
				  if (ar.length === 0) {
					stepCompleted = false;
					return true;
				  } else {
					stepCompleted = true;
				  }
				}
				if (stepCompleted) {
				  this.stepsCompleted[3] = true;
				  this.step = 4;
				  break;
				} else {
				  return true;
				}
			  }
			  case 5: {
				let stepCompleted: boolean;
				for (let i = 0; i < this.diary.entries.length; i++) {
				  if (typeof this.diary.entries[i].challenge != "undefined") {
					stepCompleted = true;
				  } else {
					stepCompleted = false;
					return;
				  }
				}
				if (stepCompleted) {
				  this.stepsCompleted[4] = true;
				  this.step = 5;
				  break;
				} else {
				  return true;
				}
			  }
			  case 6: {
				if (this.diary.thought.plan) {
				  this.stepsCompleted[5] = true;
				  this.step = 6;
				  break;
				} else {
				  return true;
				}
			  }
			}
		  }
		}
	  }
}
