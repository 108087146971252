import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleTruncate',
    pure: false,
})
@Injectable()
export class TitleTruncatePipe implements PipeTransform {
    transform(title: string, length: number, value: any): string {
        if (!title) {
            return '';
        }
        return length <= 30 && title.length > 60
            ? title.slice(0, 40) + '…'
            : title;
    }
}
