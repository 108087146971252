import { Injectable } from '@angular/core';

@Injectable()
export class DynamicFormDataService {

  getData() {

   
    const data= 
        [{
        key: 'country',
        label: 'Country',
        options: [
          {key: '1',  value: 'USA'},
          {key: '2',  value: 'UK'},
          {key: '3',   value: 'India'},
          {key: '4', value: 'Australia'}
        ],
        control:'select',
        order: 3
      },{
        key: 'firstName',
        label: 'First name',
        value: 'Bombasto',
        required: true,
        order: 1,
        control:'text',
      },{
        key: 'emailAddress',
        label: 'Email',
        type: 'email',
        order: 2,
        control:'text',
      },
      {
        key: 'address',
        label: 'Address',
        type: 'address',
        order: 4,
        control:'textarea',
      },
      {
        key: 'gender',
        label: 'Gender',
        options: [
          {key: '1',  value: 'Male'},
          {key: '2',  value: 'Female'}
        ],
        control:'radio',
        order: 5
      },
      {
        key: 'termsAndCondition',
        label: 'Terms and Condition',
        type: 'Terms and Condition',
        control:'checkbox',
        order: 6
      }
    ];
    return (data.sort((a, b) => a.order - b.order));
  }
}
