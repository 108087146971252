

export class UserPermissions {

	moodcheck: boolean;
	community: boolean;
	podcasts: boolean;
	wellnessDomains: boolean;
	practice: boolean;
	calendar: boolean;
	assessment: boolean;
	challenges: boolean;
	emergencyContact: boolean;
	resourceList: boolean;
	syncWithCounselor: boolean;
	settings: boolean;
	support: boolean;
	clientList: boolean;
	orgDetails: boolean;
	adminUsers: boolean;
	adminOrganizations: boolean;
	adminActivity: boolean;
	adminChallenges: boolean;
	adminReports: boolean;
	adminConfig: boolean;
	adminAudit: boolean;
	adminResources: boolean;
	adminSurveys: boolean;

	videohost: boolean;

	constructor(data?: any) {
		if (data) {
			this.moodcheck = data.moodcheck;
			this.community = data.community;
			this.podcasts = data.podcasts;
			this.wellnessDomains = data.wellnessDomains;
			this.practice = data.practice;
			this.calendar = data.calendar;
			this.assessment = data.assessment;
			this.challenges = data.challenges;
			this.emergencyContact = data.emergencyContact;
			this.resourceList = data.resourceList;
			this.syncWithCounselor = data.syncWithCounselor;
			this.settings = data.settings;
			this.support = data.support;
			this.clientList = data.clientList;
			this.orgDetails = data.orgDetails;
			this.adminUsers = data.adminUsers;
			this.adminOrganizations = data.adminOrganizations;
			this.adminActivity = data.adminActivity;
			this.adminChallenges = data.adminChallenges;
			this.adminReports = data.adminReports;
			this.adminConfig = data.adminConfig;
			this.adminAudit = data.adminAudit;
			this.adminResources = data.adminResources;
			this.adminSurveys = data.adminSurveys;


			this.videohost = null; 
		}
	}
}
