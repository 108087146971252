<div class="form-wrapper-80">
    <h2>Resources</h2>
    <form novalidate [formGroup]="resourcesEditForm">
        <div class="icon-input">
            <mat-checkbox #enableResourcesFlag formControlName="enableResources" id="enableResources"></mat-checkbox>
            <label for="enableResources">Enable resources?</label>
        </div>

        <div *ngIf="enableResourcesFlag.checked">
            <div class="icon-input">
                <label for="resources" translate>General info</label>
                <ckeditor formControlName="resources" [config]="config" id="resources"></ckeditor>
            </div>

            <div id="ressourceSection" *ngIf="organization.resourceSet">
                <div>
                    <h2>Resource Sets</h2>
                    <div id="language-switcher">
                        <mat-form-field class="wt-mat-form-field">
                            <mat-select [ngModelOptions]="{standalone: true}" name="language" [(ngModel)]="Language"
                                (selectionChange)="onSelectOrganization()">
                                <mat-option *ngFor="let lang of this.allLang" [value]="lang">{{lang}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>


                <mat-accordion [id]="'resourceId'" cdkDropList (cdkDropListDropped)="drop($event)"
                    formArrayName="resourceSet">
                    <ng-container *ngFor="let resource of resourceSet.controls; let i = index;" >
                        <mat-expansion-panel *ngIf="showResourceByLanguage(resource)" class="groupset" id="resourceSet_{{i}}"
                            [formGroupName]="i" cdkDrag
                            (opened)="panelOpenState = true" (closed)="panelOpenState = false" cdkDragLockAxis="y"
                            [expanded]="isExpanded(i)">
                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                            <mat-expansion-panel-header id="resource_{{i}}">
                                <mat-panel-title>
                                    <div>{{resource.get('title').value?resource.get('title').value:"New Resource Category"
                                        }}</div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <a class="removeGroup" tabindex="0"
                                        (click)="onRemoveResourceset(i); $event.stopPropagation()"> <svg-icon
                                            icon="trash"></svg-icon></a>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <ng-container padding>
                                <mat-form-field class="wt-mat-form-field">
                                    <input cdkFocusInitial class="wt-mat-input" matInput
                                        placeholder="Resource Category Title" type="text" required="true"
                                        [matAutocomplete]="auto" formControlName="title" />
                                    <mat-error *ngIf="
                                    getResourceSetFormGroup(i).controls['title'].touched &&
                                    getResourceSetFormGroup(i).controls['title'].errors?.required
                                        ">
                                        Resource set needs a title
                                    </mat-error>
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let option of resourceSetOptions[Language]" [value]="option">{{option}}</mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>Please choose a category from the list or create your own</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="wt-mat-form-field"><input class="wt-mat-input" matInput
                                        placeholder="Description" type="text" formControlName="summary" />
                                </mat-form-field>
                            </ng-container>
                            <mat-accordion [id]="i" 
                                formArrayName="resourcesetGroup">
                                <mat-expansion-panel class="groupset" [expanded]="true">
                                    
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="resource-title">Resource List
                                            ({{getResourceGroup(i).controls.length}})</mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <ng-container padding>
                                        <div class="table-responsive">
                                            <p *ngIf="getResourceGroup(i).controls.length === 0">Please add resources</p>
                                            <table class="table">
                                                <tbody cdkDropList (cdkDropListDropped)="dropGroup($event, i)">
                                                    <tr cdkDrag
                                                        *ngFor="let resourcegp of let resourcesetGroup of getResourceGroup(i).controls; let j = index">
                                                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                                        <td cdkDragLockAxis="y">
                                                            <p class="strong title">{{resourcegp.value.title}}</p>
                                                            <div *ngIf="resourcegp.value.address" class="icon-text">
                                                                <svg-icon _ngcontent-srp-c114="" icon="lang-icon"
                                                                    _nghost-srp-c99="" ng-reflect-icon="lang-icon">
                                                                    <svg _ngcontent-srp-c99="">
                                                                        <use _ngcontent-srp-c99=""
                                                                            xlink:href="../../../assets/icon/svg-icon-def.svg#lang-icon">
                                                                        </use>
                                                                    </svg>
                                                                </svg-icon>
                                                                <span>{{resourcegp.value.address}}</span>
                                                            </div>
                                                            <div *ngIf="resourcegp.value.website" class="icon-text">
                                                                <svg-icon _ngcontent-srp-c114="" icon="web-icon"
                                                                    _nghost-srp-c99="" ng-reflect-icon="web-icon">
                                                                    <svg _ngcontent-srp-c99="">
                                                                        <use _ngcontent-srp-c99=""
                                                                            xlink:href="../../../assets/icon/svg-icon-def.svg#web-icon">
                                                                        </use>
                                                                    </svg>
                                                                </svg-icon>
                                                                <a [href]="resourcegp.value.website"
                                                                    target="_blank"><span>{{resourcegp.value.website}}</span></a>
                                                            </div>
                                                            <!--Phone #-->
                                                            <div *ngIf="resourcegp.value.contact" class="icon-text">
                                                                <svg-icon _ngcontent-srp-c114="" icon="support-icon"
                                                                    _nghost-srp-c99="" ng-reflect-icon="support-icon">
                                                                    <svg _ngcontent-srp-c99="">
                                                                        <use _ngcontent-srp-c99=""
                                                                            xlink:href="../../../assets/icon/svg-icon-def.svg#support-icon">
                                                                        </use>
                                                                    </svg>
                                                                </svg-icon>
                                                                <span>{{resourcegp.value.contact}}</span>
                                                            </div>

                                                            <!--Email-->
                                                            <div *ngIf="resourcegp.value.alternateContact"
                                                                class="icon-text">
                                                                <svg-icon _ngcontent-srp-c114="" icon="email-icon"
                                                                    _nghost-srp-c99="" ng-reflect-icon="email-icon">
                                                                    <svg _ngcontent-srp-c99="">
                                                                        <use _ngcontent-srp-c99=""
                                                                            xlink:href="../../../assets/icon/svg-icon-def.svg#email-icon">
                                                                        </use>
                                                                    </svg>
                                                                </svg-icon>
                                                                <span>{{resourcegp.value.alternateContact}}</span>
                                                            </div>
                                                            <p>{{resourcegp.value.description}}</p>

                                                            <div
                                                                [ngClass]="resourcegp.value.active === 1 ? 'active' :'inactive'">
                                                                <p class="strong"><img alt="User avatar" class="rounded"
                                                                        height="20"
                                                                        src="./assets/img/welltrack-boost-circle.png">{{resourcegp.value.active
                                                                    === 1 ? 'Active' :
                                                                    'Inactive'}}</p>
                                                            </div>
                                                        </td>
                                                        <td class="action-btn">
                                                            <button class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                                                (click)="editResourceGroup(resourcegp.value,resource.value,i,j)">
                                                                <svg-icon icon="edit"></svg-icon>
                                                                <span translate>practice.rfp.edit</span>
                                                            </button>
                                                        </td>
                                                        <td class="action-btn">
                                                            <button class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                                                (click)="removeResourceGroup(resourcegp.value,resource.value,i,j)">
                                                                <svg-icon icon="trash"></svg-icon>
                                                                <span translate>practice.rfp.delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <a tabindex="0" class="btn-primary btn-inline btn add-resource-btn"
                                (click)="addResourceGroup(i, {},'add',resource.value,j)">Add Resource</a>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>

                <a tabindex="0" class="btn-primary btn-inline btn" (click)="addRessourceSet({})">Add Resource Set</a>
            </div>
        </div>
    </form>
</div>