import {
	Component,
	OnInit,
	AfterViewInit,
	Output,
	EventEmitter,
	ViewChild,
	TemplateRef,
} from "@angular/core";
import { Event } from "../../models/event";
import { ApiService } from "../../lib/api.service";

import { StorageService } from "../../lib/storage.service";
import { LogService } from "../../lib/log.service";
import { Router } from "@angular/router";
import { ModalService } from "../../lib/modal.service";
import { AppointmentInviteComponent } from "../../components/appointment-invite/appointment-invite.component";
import { TranslateService } from "@ngx-translate/core";
import { CalendarEvent, CalendarMonthViewDay } from "angular-calendar";
import { Subject } from "rxjs";
import { User } from "../../models/user";
import { UserService } from "../../lib/user.service";
import { Appointment } from "../../models/appointment";
import { Invite } from "../../components/invite/invite";
import * as moment from "moment";
import * as _ from "lodash";
import { UtilityService } from "app/lib/utility.service";
import {
	MatCalendar,
	MatCalendarCellClassFunction,
	MatDatepicker,
	MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import { DatePipe } from "@angular/common";
import { ActivitySchedulerDetails } from "../../modules/practice/activity-scheduler-details/activity-scheduler-details";
import { AnalyticEvent } from "app/lib/analytic-event";

@Component({
	selector: "app-monthly-calendar-view",
	templateUrl: "./monthly-calendar-view.component.html",
	styleUrls: ["./monthly-calendar-view.component.scss"],
	providers: [DatePipe],
})
export class MonthlyCalendarViewComponent implements OnInit {
	@ViewChild("sideView", { static: true }) sideView: TemplateRef<any>;
	@ViewChild(MatCalendar) matCalendar: MatCalendar<any>;
	@Output() emitDailyViewData = new EventEmitter<any>();
	selected: Date | null;
	calendarData: any = [];
	user: User;
	hasData: boolean = false;
	isLoading: boolean = true;
	viewDate: Date = new Date();
	selectedDate: any = moment.parseZone().format();
	today: any = moment.parseZone().format();
	dateFilteredEvnets: any = [];
	calendarList: Event[];
	detailViewData: any;
	showSideView: boolean = false;
	selectedFilter: string = "all";
	refresh = new Subject<void>();
	selectDate: MatCalendarCellClassFunction<Date>;
	filterList = [
		"all",
		"activityscheduler",
		"courses",
		"appointment",
		"homework",
	];

	constructor(
		private api: ApiService,
		private modalService: ModalService,
		private log: LogService,
		private storage: StorageService,
		private router: Router,
		private datePipe: DatePipe,
		private translate: TranslateService,
		private userService: UserService
	) {
		this.user = this.userService.getUser();
	}

	ngOnInit(): void {}

	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.fetchEvents();
			});
	}

	fetchEvents(): void {
		this.api.get("calendar/all", { creatorView: true }).subscribe(
			(result: any) => {
				const events = Event.initializeArray(result.data, true);
				if (this.user.userType === "user") {
					events.forEach((eventClass, index) => {
						eventClass["cssClass"] = "";
					});
				}
				this.log.event(AnalyticEvent.event.calendarLoad);
				let currentDate = moment.parseZone().startOf("day").format();
				this.updateMonthlyCalendarView(events, currentDate);
			},
			(error: any) => {
				this.log.error(
					"Error getting calendar events." + error.message
				);
			},
			() => {}
		);
		this.refresh.next();
	}
	openDatePicker(picker: MatDatepicker<Date>) {
		picker.open();
	}

	updateMonthlyCalendarView(data: any, currentDate?: any) {
		this.calendarData = data;
		this.selectedDate = currentDate;
		this.generateCalendarData(data);
		this.viewDate = UtilityService.convertToDate(
			moment(currentDate).format("YYYY-MM-DD")
		);
		this.dateFilteredEvnets = this.generateDateObjectOfEvents();
		this.setCurrentDateData();
	}

	dateSelected(event): void {
		this.selectDate = event;

		this.updateMonthlyCalendarView(
			this.calendarData,
			moment.parseZone(event).startOf("day").format()
		);
	}

	generateDateObjectOfEvents() {
		let dateObjOfEvents = {};
		for (let i = 0; i < this.calendarData.length; i++) {
			let eventStartDate = moment
				.parseZone(this.calendarData[i].start)
				.format("L");

			if (!dateObjOfEvents[eventStartDate]) {
				dateObjOfEvents[eventStartDate] = {
					data: [],
				};
			}
			dateObjOfEvents[eventStartDate].data.push(this.calendarData[i]);
		}
		return dateObjOfEvents;
	}

	setCurrentDateData() {
		this.detailViewData = [];
		let data = this.dateFilteredEvnets[
			moment(this.selectedDate).format("L")
		]
			? this.dateFilteredEvnets[moment(this.selectedDate).format("L")]
					.data
			: [];
		if (this.selectedFilter != "all") {
			if (
				["activityscheduler", "courses", "appointment"].includes(
					this.selectedFilter
				)
			) {
				this.detailViewData = _.filter(data, {
					category: this.selectedFilter as any,
				});
			} else {
				this.detailViewData = data.filter(
					(item) =>
						![
							"activityscheduler",
							"courses",
							"appointment",
						].includes(item.category)
				);
			}
		} else {
			this.detailViewData = data;
		}
		this.refresh.next();
	}

	generateCalendarData(data) {
		data.forEach((item) => {});
	}

	setCalendarDate() {
		this.viewDate = UtilityService.convertToDate(
			moment(this.selectedDate).format("YYYY-MM-DD")
		);
	}

	openData(day) {
		this.emitDailyViewData.emit({
			date: moment(day.date).parseZone().startOf("day").format(),
		});
	}

	onClickNextBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(1, "month").format();
		this.selectedDate = date;
		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;

		const currentViewDate = this.matCalendar.activeDate;
		const nextMonth = new Date(currentViewDate);
		nextMonth.setMonth(nextMonth.getMonth() + 1);
		this.matCalendar.activeDate = nextMonth;
	}

	onClickPrevBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(-1, "month").format();
		this.selectedDate = date;
		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;
		const currentViewDate = this.matCalendar.activeDate;
		const nextMonth = new Date(currentViewDate);
		nextMonth.setMonth(nextMonth.getMonth() - 1);
		this.matCalendar.activeDate = nextMonth;
	}

	onDateSelected(event: MatDatepickerInputEvent<Date>) {
		this.selectedDate = event.value;
	}

	toggleSideView(event, val) {
		this.selectedDate = this.datePipe.transform(
			event,
			"yyyy-MM-ddTHH:mm:ssZ"
		);
		this.onSelectFilter("all");
		if (val === "true") {
			this.showSideView = true;
		} else {
			this.showSideView = !this.showSideView;
		}
	}

	dayClicked({
		date,
		events,
	}: {
		date: Date;
		events: CalendarEvent[];
	}): void {
		this.selectedDate = this.datePipe.transform(
			date,
			"yyyy-MM-ddTHH:mm:ssZ"
		);
		this.onSelectFilter("all");
		this.showSideView = true;
	}

	onSelectFilter(type) {
		this.selectedFilter = type;
		this.setCurrentDateData();
	}

	async openEditActivityModal(type, eventData: any, itemIndex: number) {
		this.modalService
			.showComponent(
				ActivitySchedulerDetails,
				{ activityId: eventData.data.id },
				"app-c-modal--cure",
				false,
				eventData.data.id ? "400px" : ""
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					switch (data.action) {
						case "save": {
							const inputDate = moment(
								data.activityData.start
							).startOf("day");
							let event =
								this.dateFilteredEvnets[
									moment(this.selectedDate).format("L")
								].data[itemIndex];
							const calendarIndex = this.calendarData.findIndex(
								(item) => item.id === event.id
							);
							if (calendarIndex !== -1) {
								this.calendarData[
									calendarIndex
								].activityStatus = data.activityData.status;
								this.calendarData[calendarIndex].data =
									data.activityData;
								this.calendarData[calendarIndex].start =
									data.activityData.start;
								this.calendarData[calendarIndex].startDate =
									data.activityData.start;
							}
							event.activityStatus = data.activityData.status;
							event.data = data.activityData;
							if (
								!moment(this.selectedDate)
									.startOf("day")
									.isSame(inputDate)
							) {
								this.dateFilteredEvnets[
									moment(this.selectedDate).format("L")
								].data.splice(itemIndex, 1);
								if (
									!this.dateFilteredEvnets[
										moment(inputDate).format("L")
									]
								) {
									this.dateFilteredEvnets[
										moment(inputDate).format("L")
									] = {
										data: [],
									};
								}
								this.dateFilteredEvnets[
									moment(inputDate).format("L")
								].data.push(event);
							}
							break;
						}
						case "delete": {
							this.calendarData[
								moment(this.selectedDate).format("L")
							].data.splice(itemIndex, 1);
							break;
						}
					}
					this.refresh.next();
				}
			});
	}

	async setMarkAsCompleted(type, eventData: any, itemIndex: number) {
		this.modalService
			.showConfirmation(
				"Mark as complete",
				"Are you sure you want to mark this activity as completed?"
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.api
						.post(
							`practice/activityscheduler/updatestatus/${eventData.data.id}?Status=complete`,
							""
						)
						.subscribe(
							(result: any) => {
								if (result) {
									this.api
										.get("calendar/all", {
											creatorView: true,
										})
										.subscribe((listData: any) => {
											this.calendarList =
												Event.initializeArray(
													listData.data,
													true
												);
											this.log.event(
												AnalyticEvent.event
													.markAsCompleteActivity
											);
											const calendarIndex =
												this.calendarList.findIndex(
													(item) =>
														item.id ===
														eventData.data.id
												);
											if (calendarIndex !== -1) {
												let event =
													this.dateFilteredEvnets[
														moment(
															this.selectedDate
														).format("L")
													].data[itemIndex];
												event.data.status =
													this.calendarList[
														calendarIndex
													].data.status;
												this.modalService.showAlert(
													"Success",
													result.message
												);
											}
										});
								}
							},
							(error: any) => {
								this.log.error("Error in updating status. ");
							}
						);
				}
			});
	}
}
