<div class="app-works-main-container" *ngIf="showEmptyHeader">
    <div class="app-works-header">
        <img
            src="../../../assets/img/welltrack-logos/well-track-white-logo.svg"
            alt="welltrack-logo" />
        <div class="app-works-header-bg">
            <svg-icon icon="app-works-header-bg"></svg-icon>
        </div>
    </div>
</div>
<div class="app-l-page-wrap">
    <div
        id="page-assessment"
        class="page-assessment-section"
        [ngClass]="{'submitLoader': showAnimation}">
        <!-- <page-header [headerText]="title" [cssClass]="cssClass" [backLink]="backLink" [backText]="back"
			[headerImage]="headerImage"></page-header> -->

        <div
            class="app-l-assesment__question-base"
            *ngIf="isLoaded && !showExplain && !showIntro && !showScore">
            <div class="app-l-assesment__q-header" *ngIf="isLoaded">
                <h3
                    class="app-c-heading--h3"
                    *ngIf="!showExplain && !showExplain && !showIntro && !showScore  && questions[active].questionType != 'resourceQusetion'">
                    <span class="questionTitle" translate>das.question</span>
                    <span class="questionNumber"
                        >{{active + 1 }}/{{count}}</span
                    >
                </h3>
                <div class="app-l-assesment__q-progress">
                    <mat-progress-bar
                        class="app-l-assesment__q-progress-bar"
                        *ngIf="!showExplain && !showIntro && !showScore  && questions[active].questionType != 'resourceQusetion'"
                        mode="buffer"
                        [bufferValue]="buffer"
                        [value]="progress"></mat-progress-bar>
                </div>
            </div>

            <div class="app-l-assesment__q-body page-section-content">
                <div class="app-l-assesment__question">
                    <div
                        class="app-l-assesment__qes-block"
                        *ngFor="let question of questions; let i = index"
                        [ngClass]="{'active': (active === i )}">
                        <div
                            *ngIf="question.questionType === 'assessmentQuestion'">
                            <h4
                                aria-live="polite"
                                tabindex="0"
                                class="app-c-heading--h4">
                                {{question.Comment}}
                            </h4>
                            <div
                                [ngClass]="'wt-auto-question-buttons-' + i"
                                class="app-l-question__wrap">
                                <p *ngIf="question.Type == 2" translate>
                                    das.selectAll
                                </p>

                                <div
                                    class="app-l-question__block"
                                    *ngFor="let option of question.options; let j = index;">
                                    <ng-container
                                        [ngTemplateOutlet]="assessmentFormTemplate"
                                        [ngTemplateOutletContext]="{ question: question,option: option,index: i }">
                                    </ng-container>
                                    <label
                                        [for]="option.ID"
                                        class="app-c-custom__select"
                                        [ngClass]="{'active': optionType === option.ID}">
                                        <span class="app-c-custom__select-text"
                                            >{{option.Comment}}</span
                                        >
                                        <svg-icon
                                            icon="tick-circle"
                                            class="app-c-custom__select-icon"></svg-icon>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="question.questionType == 'resourceQuestion'"
                            class="resource-section">
                            <h4 translate class="app-c-heading--h4">
                                das.resourceQuestionTitle
                            </h4>
                            <div class="app-l-question__wrap">
                                <div
                                    class="app-l-question__block"
                                    *ngFor="let resourceQuestion of question.resourceQuestions; let resQntIndex = index"
                                    (click)="onSelectionChange(i + resQntIndex, 1, resourceQuestion.Type, '','resourceQusetion')">
                                    <label
                                        class="app-c-custom__select"
                                        [ngClass]="{'active':answers[i + resQntIndex].value == 1}">
                                        <span class="app-c-custom__select-text"
                                            >{{resourceQuestion.Comment}}</span
                                        >
                                        <svg-icon
                                            icon="tick-circle"
                                            class="app-c-custom__select-icon"></svg-icon>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template
                    let-question="question"
                    let-option="option"
                    let-index="index"
                    #assessmentFormTemplate>
                    <input
                        *ngIf="question.Type == 1 || question.Type == 3"
                        type="radio"
                        value="0"
                        [name]="question.ID"
                        [id]="option.ID"
                        [checked]="answers[index].value === option.OptionValue"
                        (click)="onSelectionChange(index, option.OptionValue, question.Type, option.ID,'assessmentQuestion')" />
                    <input
                        *ngIf="question.Type == 2"
                        type="checkbox"
                        value="0"
                        [name]="question.ID"
                        class="question-option"
                        [checked]="answers[index].value === option.OptionValue"
                        (click)="onSelectionChange(index, option.OptionValue, question.Type, option.ID,'assessmentQuestion')" />
                </ng-template>

                <ng-template
                    let-question="question"
                    let-option="option"
                    let-index="index"
                    #resourceFormTemplate>
                    <input
                        *ngIf="(question.Type == 1 || question.Type == 3) && option.OptionValue != 0"
                        type="checkbox"
                        value="0"
                        [name]="question.ID"
                        class="question-option"
                        [checked]="answers[index].value === option.OptionValue" />
                    <input
                        *ngIf="question.Type == 2"
                        type="checkbox"
                        value="0"
                        [name]="question.ID"
                        class="question-option"
                        [checked]="answers[index].value === option.OptionValue" />
                </ng-template>

                <div class="app-l-assesment__question-footer">
                    <div class="app-l-aq__f-wrap">
                        <button
                            tabindex="0"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                            (click)="setPreviousActive()"
                            (keypress)="setPreviousActive()"
                            [ngClass]="{'btn-disabled': active === 0}"
                            translate>
                            <svg-icon icon="arrow-left"></svg-icon>
                            <span translate>das.previous</span>
                        </button>

                        <button
                            tabindex="0"
                            *ngIf="questions[active].Type == 1 &&  progress <=  buffer &&  active < (count - 1)"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="setNextActive()"
                            (keypress)="setNextActive()">
                            <span translate>das.next</span>
                            <svg-icon icon="arrow-right"></svg-icon>
                        </button>

                        <button
                            tabindex="0"
                            *ngIf="questions[active].Type == 2 && active < (count - 1)"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="setNextActive()"
                            (keypress)="setNextActive()">
                            <span translate>das.next</span>
                            <svg-icon icon="arrow-right"></svg-icon>
                        </button>

                        <button
                            tabindex="0"
                            *ngIf="questions[active].Type == 3 && answers[active].value === 1 &&  active < (count - 1)"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="setNextActive()"
                            (keypress)="setNextActive()">
                            <span translate>das.next</span>
                            <svg-icon icon="arrow-right"></svg-icon>
                        </button>

                        <button
                            tabindex="0"
                            *ngIf="active === (count - 1)"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve"
                            (click)="onSubmit()"
                            [ngClass]="checkAllAnswered() ? 'btn-primary':'fn-btn-disabled'">
                            <span translate
                                >{{'assessments.'+quizType+'.finish'}}</span
                            >

                            <svg-icon icon="tick"></svg-icon>
                        </button>
                    </div>
                </div>
            </div>

            <p *ngIf="quizType == 'ptsd'" class="text-center" translate>
                das.opinionofprofessional
            </p>
        </div>

        <div class="app-l-assessment__instruction" *ngIf="showIntro">
            <div class="app-l-assessment__i-body">
                <div class="app-l-assessment__icon">
                    <img
                        [src]="'./assets/img/icons-svg/assessments/assessment_type_' +quizType+ '.svg'" />
                </div>
                <div class="app-l-assessment__content">
                    <h3 class="app-c-heading--h3" translate>
                        {{'assessments.'+quizType+'.title'}}
                    </h3>
                    <div class="app-l-assessment__content-block">
                        <h4 translate>assessments.instructions</h4>
                        <ng-container *ngIf="quizType === 'dass'">
                            <p translate aria-live="polite" tabindex="0">
                                {{'assessments.'+quizType + '.intro'}}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="quizType !== 'dass'">
                            <p translate aria-live="polite" tabindex="0">
                                {{ 'assessments.' + quizType + '.intro'}}
                            </p>
                            <p
                                *ngIf="assessmenttranslation.subIntro && quizType == 'resilience'"
                                translate
                                aria-live="polite"
                                tabindex="0">
                                {{'assessments.' + quizType + '.subIntro'}}
                            </p>
                        </ng-container>
                        <p
                            translate
                            *ngIf="forced"
                            aria-live="assertive"
                            tabindex="0">
                            {{quizType.dasforcedintro}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment__i-footer">
                <button
                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                    (click)="goBack()">
                    <svg-icon icon="arrow-left"></svg-icon>
                    <span translate>das.back</span>
                </button>
                <button
                    autofocus
                    aria-live="polite"
                    tabindex="0"
                    class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    (click)="setIntro(false)"
                    (keypress)="setIntro(false)"
                    translate>
                    das.start
                </button>
            </div>
        </div>
        <div class="app-das-assessment-main" *ngIf="showScore">
            <div class="assessment-alert">
                <div class="col-xs-12 assessmentsection">
                    <div
                        class="alertwrapper"
                        [ngClass]="{'low': !alert, 'high': alert}">
                        <div class="header">
                            <h3 class="uppercase" translate>
                                resilience.alert.title
                            </h3>
                            <div class="alerttitle">{{resiliencyLevel}}</div>
                        </div>
                        <div class="content">
                            <p>
                                <span translate>
                                    resilience.alert.preDescription
                                </span>
                                <strong>{{resiliencyLevel}}</strong>.
                            </p>
                            <p [innerHtml]="resiliencyDescription"></p>

                            <emergency-contact
                                *ngIf="alert"
                                [user]="user"></emergency-contact>
                        </div>
                    </div>
                </div>
            </div>

            <div class="instructions" translate></div>
            <a
                autofocus
                tabindex="0"
                class="btn btn-bold btn-inline btn-wide"
                (click)="setExplain(true)"
                (keypress)="setExplain(true)"
                translate
                >das.continue</a
            >
        </div>

        <div class="app-das-assessment-main" *ngIf="showExplain">
            <p translate>resilience.explain</p>
            <a
                autofocus
                tabindex="0"
                class="btn btn-bold btn-inline btn-wide"
                (click)="setExplain(false)"
                (keypress)="setExplain(false)"
                translate
                >das.continue</a
            >
        </div>
    </div>
</div>

<div class="app-c-loader" *ngIf="showAnimation">
    <div class="app-c-loader__inside">
        <div class="app-c-loader__image">
            <img alt="select" src="/assets/img/loader.gif" />
        </div>
        <div class="app-c-loader__content">
            <h4 class="app-c-heading--h4">Hold on!</h4>
            <p>Your assessment is almost complete</p>
        </div>
    </div>
</div>
