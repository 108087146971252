import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { CounselorUser } from '../../models/counselor-user';
import { Organization } from '../../models/organization';
import { UserService } from '../../lib/user.service';
import { ModalService } from '../../lib/modal.service';
import { TranslateService } from '@ngx-translate/core';
import Debounce from 'debounce-decorator'
import { Invite } from '../../components/invite/invite';
import * as _ from 'lodash';
import { UserCreateComponent } from 'app/components/admin/user-create/user-create.component';

@Component({
  selector: 'app-internal-user-listing',
  templateUrl: './internal-user-listing.component.html',
  styleUrls: ['./internal-user-listing.component.scss']
})
export class InternalUserListingComponent implements OnInit {

  user: User;
	users: User[] = [];
	pendingUsers: CounselorUser[] = [];
	activeusers: User[] = [];
	popup: any;
	syncPopup: any;
	isLoaded = false;
	isAdmin = false;
	cols: Array<string> = ['', '', '', '', ''];
	userShare: boolean = false;
	userListing: boolean = false;
	inviteSubscribe: boolean = false;
	offset: number = 0;
	invites: any;
	statuses: any;
	organization: Organization;
	zoomUsers: any;
	roles: Array<string> = [];
	userStatuses: Array<string> = [];
	usersLoading: boolean = true;

	constructor(
		private api: ApiService,
		private storage: StorageService,
		private router: Router,
		private modalService: ModalService,
		private log: LogService,
		private translate: TranslateService,
		private userService: UserService
	) {
		this.user = this.userService.getUser();
  
		this.roles = [
			"user",
			"professional",
			"orgadmin",
			"admin",
			"superadmin"
		  ];
		  this.userStatuses = [
			"approved",
			"unconfirmed",
			"disabled"
		  ];
	}

	ngOnInit() {

		this.translate.stream('userListing.popup').subscribe((res: any) => {
			this.popup = res;
		});

		this.translate.stream('userListing.statuses').subscribe((res: any) => {
			this.statuses = res;
		});

		this.translate.stream('sync.popup').subscribe((res: any) => {
			this.syncPopup = res;
		});

		this.loadMore();
	}


	valueChange(i) {

		let filterValue = this.cols[i].toLowerCase();
		this.loadMore();
	}

	reset() {
		let valid = true;

		for (let i = 0; i < this.cols.length; i++) {

			if (this.cols[i] !== '') {
				valid = false;
			}
		}
		return valid;
	}

	@Debounce(500)
	loadMore() {
		this.usersLoading = true;
		this.offset = this.users.length;

		if (this.cols[0] && this.cols[1] && this.cols[2]) {

		}

		this.api.get('users/listing', {
			Limit: 100,
			Offset: this.offset,
			Name: this.cols[0],
			Email: this.cols[1],
			OrganizationName: this.cols[2],
			UserType: this.cols[3] || '',
			Status: this.cols[4] || '',
		}).subscribe(
			(results: any) => {
				let users = [];
				for (let user of results.data) {
					users.push(new User(user));
				}
				this.activeusers = users;
				this.isLoaded = true;
				this.usersLoading = false;
			},
			(error: any) => {

				this.log.error('Error loading. ' + error.message);
				this.isLoaded = true;
				this.usersLoading = false;
			}
		);


	}



	createUser(){
		this.modalService.showComponent(UserCreateComponent);
	}


	/**
	 * Invite user
	 */
	 showInvite(inviteType: string) {
		let inviteInfo;
		let user
		user = this.storage.get('user',true);

		inviteInfo = {
			'type': 'relationShipManager',
			'endpoint': 'rminvite',
			'orgId': user.organizations[0].id
		};
		const modal = this.modalService.showComponent(Invite, inviteInfo);

		modal.beforeClosed().subscribe((data:any) => {
			//this.getInvites();
		});

	}

	activateUser(userID: number, status:number){
		const activate = status? "approved": "disabled";
		this.api.post('users/activate', { UserID: userID, Status: activate}, true, false).subscribe(
			(data: any) => {
				const index = this.activeusers.findIndex((user) => user.id === userID);
				this.activeusers[index].status = status? "approved": "disabled";
			},
			(error: any) => {
				this.modalService.showAlert('Error', 'Something went wrong. ' + error.message);
			}
		);
	}


}
