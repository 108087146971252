<div id="page-quiz" class="thought-distortion-quiz-main-container">
	<!-- <page-header [headerText]="title" [backLink]="backLink" [cssClass]="'tools-quiz tools'" [backText]='backText'
        [headerImage]="'./assets/img/Rectangle 4350 (2).png'"></page-header> -->
	<div class="thought-distortion-quiz-main-page">
		<div class="app-l-page-wrap-quiz">
			<div class="app-l-page__navigation" *ngIf = "user.userType != 'user' && !showCreateBtn">
				<div class="app-l-page__previous">
					<button
						class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
						(click)="goBack()"
					>
						<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
					</button>
				</div>
				<div class="app-l-page__breadcrumbs">
					<ul >
						<li (click)="toClienList()" translate>menu.clientList</li>
						<li class="app-l-current-page" translate>
							cdQuiz.heading
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="start-section" *ngIf="start">
			<div class="start-section-content">
				<h1 translate>cdQuiz.heading</h1>
				<p translate class="app-c-heading--h5">cdQuiz.body</p>
				<button *ngIf = "user.userType === 'user' || (user.userType != 'user' && showCreateBtn) "
					tabindex="0"
					class="app-c-btn app-c-btn--secondary app-c-btn--curve"
					(click)="begin()"
					(keypress)="begin()"
					translate
				>
					<span translate>cdQuiz.begin</span>
					<svg-icon icon="right-navigation-arrow"></svg-icon>
				</button>
			</div>
			<div class="start-section-bg">
				<div class="start-section-first-bg">
					<svg-icon icon="quiz-section-bg"></svg-icon>
				</div>
				<div class="start-section-second-bg">
					<svg-icon icon="quiz-section-bg-emoji"></svg-icon>
				</div>
			</div>
		</div>

		<div class="results-section" *ngIf="start && showScore">
			<!-- <h2 *ngIf="start && showScore">Results</h2> -->

			<div class="results-card last-score-card">
				<svg-icon icon="last-score-icon"></svg-icon>
				<div class="footer-content">
					<h3 id="last">
						{{ avgScore | number : "1.0-0" }}<span>%</span>
					</h3>
					<p class="app-c-heading--h5" translate>cdQuiz.last</p>
				</div>
				<div class="card-bg">
					<svg-icon icon="score-card-bg"></svg-icon>
				</div>
			</div>

			<div class="results-card best-score-card">
				<svg-icon icon="best-score-icon"></svg-icon>
				<div class="footer-content">
					<h3 id="best">
						{{ bestScore | number : "1.0-0" }}<span>%</span>
					</h3>
					<p class="app-c-heading--h5" translate>cdQuiz.best</p>
				</div>
				<div class="card-bg">
					<svg-icon icon="score-card-bg"></svg-icon>
				</div>
			</div>
		</div>
	</div>

	<div id="top" class="thought-distortion-quiz-main-section">
		<div class="app-l-assesment__q-header" *ngIf="!start">
			<h3 class="app-c-heading--h3">
				<span class="questionTitle" translate>cdQuiz.question</span>
				<span *ngIf="!start" class="questionNumber"
					>{{ active + 1 }} / {{ count }}</span
				>
			</h3>
			<div class="app-l-assesment__q-progress">
				<mat-progress-bar
					class="app-l-assesment__q-progress-bar"
					mode="buffer"
					bufferValue="buffer"
					[value]="progress"
				></mat-progress-bar>
			</div>
		</div>
		<div class="page-section-content custom-scrollbar">
			<div
				class="question-section thought-distortion-questions-container"
				*ngIf="!start"
			>
				<div class="thought-distortion-questions-section">
					<div
						class="question row"
						*ngFor="let question of questions; let i = index"
						[ngClass]="{ active: active == i }"
					>
						<div class="thought-distortion-questions">
							<h2
								class="app-c-heading--h4"
								[innerHTML]="question.comment"
							></h2>
							<p class="app-c-body-text--16" translate>
								cdQuiz.selectOne
							</p>
						</div>

						<!-- <p translate>cdQuiz.showDefinitions <a class="info-icon" (click)="showHelp()">?</a></p>
                    <div class="help-section" [ngClass]="{'in': displayHelp}">
                        <p *ngFor="let key of thinkingStylesKeys;">
                            <strong>{{thinkingStyles[key].info}}</strong>
                            <span>{{thinkingStyles[key].text}}</span>
                        </p>
                    </div> -->
						<div class="question-buttons form-group">
							<div
								tabindex="{{ optionIndex }}"
								(click)="
									onSelectionChange(
										i,
										question.name,
										optionIndex
									);
									showAnswer(i)
								"
								(keypress)="
									onSelectionChange(
										i,
										question.name,
										optionIndex
									);
									showAnswer(i)
								"
								class="app-l-question__block"
								*ngFor="
									let option of questionOptions;
									let optionIndex = index
								"
							>
								<input
									tabindex="{{ optionIndex }}"
									[name]="question.name"
									type="radio"
									[value]="optionIndex"
									class="question-option"
									[checked]="isChecked[optionIndex]"
									(change)="
										onSelectionChange(
											i,
											question.name,
											optionIndex
										);
										showAnswer(i)
									"
								/>
								<div class="app-c-custom__select">
									<span
										translate
										class="app-c-custom__select-text"
										>{{ option.title }}</span
									>
									<svg-icon
										icon="tick-circle"
										class="app-c-custom__select-icon"
									></svg-icon>
								</div>
							</div>
						</div>
					</div>

					<div
						class="app-l-assesment__question-footer"
						*ngIf="!start"
					>
						<div class="app-l-aq__f-wrap">
							<!-- <button tabindex="0" [ngClass]="{'btn-disabled': active === 0}"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="setPreviousActive()"
                                (keypress)="setPreviousActive()">
                                <svg-icon icon="arrow-left"></svg-icon>
                                <span translate>cdQuiz.previous</span></button> -->
							<button
								tabindex="0"
								class="app-c-btn app-c-btn--primary app-c-btn--curve submit-button"
								[ngClass]="{
									'btn-disabled': active + 1 < count
								}"
								(click)="onSubmit()"
								(keypress)="onSubmit()"
							>
								<span translate>cdQuiz.submit</span>
								<svg-icon icon="tick"></svg-icon>
							</button>
							<button
								tabindex="0"
								class="app-c-btn app-c-btn--secondary app-c-btn--curve"
								[ngClass]="{
									'btn-disabled': active + 1 === count
								}"
								(click)="setNextActive()"
								(keypress)="setNextActive()"
							>
								<span translate>cdQuiz.continue</span>
								<svg-icon icon="arrow-right"></svg-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<mat-accordion
			class="app-c-accordion app-c-accordion__survey"
			*ngIf="!start"
		>
			<mat-expansion-panel
				(opened)="panelOpenState = true"
				(closed)="panelOpenState = false"
			>
				<mat-expansion-panel-header>
					<mat-panel-title translate
						>cdQuiz.showDefinitions</mat-panel-title
					>
				</mat-expansion-panel-header>
				<ng-container class="accordion-content">
					<p
						translate
						class="app-c-body-text--12"
						*ngFor="let definition of questionOptions"
					>
						<span>{{ definition.title }}</span>
						{{ definition.info }}
					</p>
				</ng-container>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</div>
