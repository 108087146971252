<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap home-dashboard-main-container" *ngIf="isLoaded">
    <page-user-event-assign
        *ngIf="showEventAssignModal"
        class="app-l-modecheck__sidepanel"
        [selectedUser]="userDetail"
        [disableUserList]="true"
        (closeModal)="onCloseOptions($event)"></page-user-event-assign>
    <div class="app-l-main-heading">
        <h2 translate>clientList.overview.title</h2>
        <div class="app-l__actions-block">
            <button
                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                (click)="showAssignModal('true')"
                translate>
                <img
                    alt="Assign"
                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                <span translate>clientList.assign</span>
            </button>
            <div class="app-c-mat-select">
                <img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange()">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="app-l-dashboard__wrap">
        <div class="app-l-left-part">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card__header">
                        <div class="title-section app-l-card__h-left">
                            <h2 class="initial-image">
                                {{ getInitials(userDetail?.fullName) }}
                            </h2>
                            <h4>
                                {{ userDetail?.fullName }}<br />
                                <p>{{ userDetail?.email }}</p>
                            </h4>
                        </div>
                    </div>
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p translate>clientList.age</p>
                            <h4>
                                {{
                                    demographicData?.Age
                                        ? demographicData?.Age
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.ethinicity</p>
                            <h4>
                                {{
                                    demographicData?.Ethnicity
                                        ? demographicData?.Ethnicity
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gender</p>
                            <h4>
                                {{
                                    demographicData?.Gender
                                        ? demographicData?.Gender
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gradLevel</p>
                            <h4>
                                {{
                                    demographicData?.GradeLevel
                                        ? demographicData?.GradeLevel
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.referredBy</p>
                            <h4>
                                {{
                                    demographicData?.Referred
                                        ? demographicData?.Referred
                                        : '-'
                                }}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-mood-day">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.overview.moodCheck
                        </h3>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-moods__overall">
                            <app-overall-view-moodcheck
                                [hideFilter]="true"
                                [moodchecks]="moodchecks"
                                #moodchecOverallViewComp></app-overall-view-moodcheck>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-assignment">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header app-c-card-header-right">
                        <h3 class="app-l-card__title" translate>
                            clientList.overview.assignment
                        </h3>
                        <div
                            class="app-l-card__h-right"
                            *ngIf="assignedListLoaded">
                            <mat-select
                                class="app-l-dsh__rfp-select"
                                disableOptionCentering
                                [(value)]="selectedType"
                                (selectionChange)="getAssignment()"
                                panelClass="app-l-dsh__rfp-panel">
                                <mat-option value="all" translate
                                    >All</mat-option
                                >
                                <mat-option
                                    *ngFor="let assignment of assignmentTypes"
                                    [value]="assignment.key"
                                    translate>
                                    {{ assignment.title }}
                                </mat-option>
                            </mat-select>
                            <button
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                (click)="showAssignModal('true')"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                                <span translate>clientList.assign</span>
                            </button>
                        </div>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <ng-container
                                    *ngIf="!assignedListLoaded"
                                    [ngTemplateOutlet]="skeletonLoader"
                                    [ngTemplateOutletContext]="{
                                        height: '100%'
                                    }">
                                </ng-container>
                                <mat-tab-group
                                    *ngIf="assignedListLoaded"
                                    #tabGroup
                                    disableRipple="true"
                                    (selectedTabChange)="onTabChanged($event)">
                                    <!-- Tab 1: Basic Details -->
                                    <mat-tab label="Assigned">
                                        <div
                                            tabindex="0"
                                            class="app-l-assignment-ls-container"
                                            *ngFor="let assignment of assigned">
                                            <div class="app-l-dsh__rp-content">
                                                <h4
                                                    class="app-c-body-text--12-m">
                                                    {{ assignment.Name }}
                                                </h4>
                                                <div class="app-l-time">
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>
                                                    <p
                                                        class="app-c-body-text--12">
                                                        {{
                                                            assignment.Date
                                                                | date
                                                                    : 'MMM dd, yyyy'
                                                        }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <button
                                                        tabindex="0"
                                                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                                                        (click)="deleteAssign(assignment.ID)"
                                                        (keypress)="deleteAssign(assignment.ID)">
                                                        <svg-icon
                                                            icon="trash"
                                                            class="w-100 h-100"
                                                            alt="trash"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            tabindex="0"
                                            class="app-l-assignment-ls-container"
                                            *ngIf="assigned.length === 0">
                                            <p
                                                class="center"
                                                style="margin-top: 10px"
                                                translate>
                                                clientList.overview.noPendingAssignment
                                            </p>
                                        </div>
                                    </mat-tab>

                                    <mat-tab label="Completed">
                                        <div
                                            tabindex="0"
                                            class="app-l-assignment-ls-container"
                                            *ngFor="
                                                let assignment of completed
                                            ">
                                            <div class="app-l-dsh__rp-content">
                                                <h4
                                                    class="app-c-body-text--12-m">
                                                    {{ assignment.Name }}
                                                </h4>
                                                <div class="app-l-time">
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>
                                                    <p
                                                        class="app-c-body-text--12">
                                                        {{
                                                            assignment.UpdatedOnUtc
                                                                | date
                                                                    : 'MMM dd, yyyy'
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            tabindex="0"
                                            class="app-l-assignment-ls-container"
                                            *ngIf="completed.length === 0">
                                            <p
                                                class="center"
                                                style="margin-top: 10px"
                                                translate>
                                                clientList.overview.noCompletedAssignment
                                            </p>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-l-right-part">
            <div class="app-l-result-summary app-l-two-column">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.snapshot
                        </h3>
                    </div>
                    <ng-container
                        *ngIf="!countDataLoaded"
                        [ngTemplateOutlet]="skeletonLoader"
                        [ngTemplateOutletContext]="{
                            height: '100%'
                        }">
                    </ng-container>
                    <div
                        class="app-l-result__card-body"
                        *ngIf="countDataLoaded">
                        <div class="app-l-completed-assessment">
                            <!-- <div>
                                <p>Total Hours spent in the App</p>
                                <h2>25 hr 25 min</h2>
                            </div> -->
                            <div>
                                <p translate>clientList.overview.totalLogins</p>
                                <h2>
                                    {{
                                        countData?.login?.count
                                            ? countData?.login?.count
                                            : 0
                                    }}
                                </h2>
                            </div>
                        </div>

                        <div class="app-l-additional-assessment">
                            <p translate>clientList.overview.topCategories</p>
                            <div class="additional-val">
                                <p>
                                    {{
                                        countData?.assessment?.dass.count
                                            ? countData?.assessment?.dass.count
                                            : 0
                                    }}
                                </p>
                                <p translate>
                                    clientList.overview.dassAssessment
                                </p>
                            </div>
                            <div class="additional-val">
                                <p>
                                    {{
                                        countData?.moodcheck?.count
                                            ? countData?.moodcheck?.count
                                            : 0
                                    }}
                                </p>
                                <p translate>clientList.overview.moodCheck</p>
                            </div>
                            <div class="additional-val">
                                <p>{{ seriesList.length }}</p>
                                <p translate>clientList.overview.series</p>
                            </div>
                        </div>
                        <div class="app-footer-holder">
                            <span translate>
                                <b>{{
                                    countData?.gethelpbutton?.count
                                        ? countData?.gethelpbutton?.count
                                        : 0
                                }}</b>
                                clientList.overview.timesUtilized
                                <b>Get Help now</b> button
                            </span>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.overview.latestDassResult
                        </h3>
                        <ng-container
                            *ngIf="!latestDassDataLoaded"
                            [ngTemplateOutlet]="skeletonLoader"
                            [ngTemplateOutletContext]="{
                                height: '100px'
                            }">
                        </ng-container>
                        <div
                            class="app-l-date"
                            *ngIf="latestDassData && latestDassDataLoaded">
                            <div class="icon">
                                <svg-icon icon="calendarv1"></svg-icon>
                            </div>
                            <b
                                >{{
                                    latestDassData?.UpdatedOnUtc
                                        | date: 'MM-dd-yyyy'
                                }}
                                ({{
                                    latestDassData?.UpdatedOnUtc
                                        | date: 'h:mm a'
                                }})
                            </b>
                            <!-- <span>{{
                                latestDassData?.UpdatedOnUtc
                                    | date: 'dd-MM-yyyy'
                            }}</span> -->
                        </div>
                    </div>

                    <div
                        class="app-l-result__card-body"
                        *ngIf="latestDassData && latestDassDataLoaded">
                        <div class="app-l-da">
                            <div class="app-l-da__item-block">
                                <h6 translate>
                                    clientList.overview.depression
                                </h6>
                                <h3>
                                    {{ latestDassData?.DepressionScore.level }}
                                </h3>
                                <ng-container
                                    [ngTemplateOutlet]="daslevelIndicationImg"
                                    [ngTemplateOutletContext]="{
                                        level: latestDassData?.DepressionScore
                                            .position
                                    }">
                                </ng-container>
                            </div>
                            <div class="app-l-da__item-block">
                                <h6 translate>clientList.overview.anxiety</h6>
                                <h3>
                                    {{ latestDassData?.AnxietyScore.level }}
                                </h3>
                                <ng-container
                                    [ngTemplateOutlet]="daslevelIndicationImg"
                                    [ngTemplateOutletContext]="{
                                        level: latestDassData?.AnxietyScore
                                            .position
                                    }">
                                </ng-container>
                            </div>
                            <div class="app-l-da__item-block">
                                <h6 translate>clientList.overview.stress</h6>
                                <h3>{{ latestDassData?.StressScore.level }}</h3>
                                <ng-container
                                    [ngTemplateOutlet]="daslevelIndicationImg"
                                    [ngTemplateOutletContext]="{
                                        level: latestDassData?.StressScore
                                            .position
                                    }">
                                </ng-container>
                            </div>
                        </div>
                        <!-- <canvas
                                            #dasAssessmentChart
                                            id="dasAssessmentChart"></canvas> -->
                    </div>
                    <div
                        class="app-l-dsh__sa-wrap"
                        style="margin-top: 30px"
                        *ngIf="!latestDassData">
                        <div class="app-l-dsh__sa-card" translate>
                            clientList.noData
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-video-series">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header app-c-card-header-right">
                        <div class="app-l-header-top">
                            <h3 class="app-l-card__title" translate>
                                clientList.overview.series
                            </h3>
                            <!-- <div class="app-l-card__h-right">
                                <a
                                    role="tab"
                                    tabindex="0"
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                    routerLink="/app/series">
                                    <span translate>menu.viewAll</span>
                                    <svg-icon
                                        icon="angle-right"
                                        alt="angle-right"></svg-icon>
                                </a>
                            </div> -->
                        </div>
                        <p translate>clientList.overview.recentActivity</p>
                    </div>

                    <div class="app-c-card__body">
                        <div class="app-l-dashboard-course">
                            <ng-container
                                *ngIf="!seriesLoaded"
                                [ngTemplateOutlet]="skeletonLoader"
                                [ngTemplateOutletContext]="{
                                    height: '100%'
                                }">
                            </ng-container>
                            <div class="app-l-dc__body" *ngIf="seriesLoaded">
                                <div
                                    class="app-l-dc__course-row series-list"
                                    *ngIf="seriesList.length !== 0">
                                    <div
                                        tabindex="0"
                                        class="app-l-dc__c-block"
                                        *ngFor="let series of seriesList">
                                        <div class="app-l-dc__c-over">
                                            <div
                                                class="app-l-completed-date inprogress"
                                                *ngIf="
                                                    series.courseprogress ===
                                                    'inprogress'
                                                ">
                                                <div class="icon">
                                                    <svg-icon
                                                        icon="progress"></svg-icon>
                                                </div>
                                                <span translate
                                                    >clientList.overview.inProgress</span
                                                >
                                            </div>
                                            <div
                                                class="app-l-completed-date completed"
                                                *ngIf="
                                                    series.courseprogress ===
                                                    'completed'
                                                ">
                                                <div class="icon">
                                                    <svg-icon
                                                        icon="tickIcon"></svg-icon>
                                                </div>
                                                <span translate
                                                    >clientList.overview.completed</span
                                                >
                                            </div>
                                            <div class="app-l-dc__c-image">
                                                <img
                                                    [defaultImage]="
                                                        '../../../../assets/img/dashboard/no-image.svg'
                                                    "
                                                    [lazyLoad]="
                                                        series.CourseImage
                                                    "
                                                    alt="view-more-button" />
                                            </div>
                                            <div class="app-l-dc__c-content">
                                                <h2
                                                    class="app-c-body-text--16-m">
                                                    {{ series.Name }}
                                                </h2>
                                                <!-- <div class="app-l-dc__c-info-box">
											<div class="app-l-dc__c-info">
												<span class="app-l-dc__cat" translate>{{
													"dashboard." + series.cardType
												}}</span>
											</div>
										</div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="app-l-dsh__sa-wrap"
                                    *ngIf="seriesList.length === 0">
                                    <div class="app-l-dsh__sa-card" translate>
                                        clientList.overview.noRecentSeries
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-l-result-summary app-l-action-plan">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 translate class="app-l-result__card-header-left">
                            clientList.overview.actionPlan
                            <button
                                tabindex="0"
                                (click)="goToActionPlan()"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="add-icon"></svg-icon>
                                <span translate
                                    >clientList.overview.createNewPlan</span
                                >
                            </button>
                        </h3>
                        <a
                            role="tab"
                            tabindex="0"
                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                            [routerLink]="'/app/practice/action-plan/' + id">
                            <span translate>menu.viewAll</span>
                            <svg-icon
                                icon="angle-right"
                                alt="angle-right"></svg-icon>
                        </a>
                    </div>
                    <p style="font-size: 12px" translate>
                        clientList.overview.recentActivity
                    </p>

                    <div class="app-c-card__body">
                        <div
                            class="app-l-dsh__sa-wrap actionPlan-list"
                            *ngIf="userActionPlans.length">
                            <div
                                class="app-l-dsh__sa-card"
                                *ngFor="let plan of userActionPlans">
                                <h5 class="app-c-body-text--12-m">
                                    {{ plan.title }}
                                </h5>
                                <div class="app-l-sa__time">
                                    <svg-icon
                                        icon="calendarv2"
                                        alt="clock"></svg-icon>
                                    <span class="app-c-body-text--12">{{
                                        plan.isUpdated
                                            ? (plan.updated
                                              | date: 'MMM dd, yyyy')
                                            : (plan.created
                                              | date: 'MMM dd, yyyy')
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="app-l-dsh__sa-wrap"
                            *ngIf="!userActionPlans.length">
                            <div class="app-l-dsh__sa-card" translate>
                                clientList.overview.noActionPlan
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-result-summary app-l-resources-warper">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card-header">
                        <h3 class="app-l-card__title" translate>
                            clientList.overview.resources
                        </h3>
                    </div>
                    <ng-container
                        *ngIf="!resourceClicksLoaded"
                        [ngTemplateOutlet]="skeletonLoader"
                        [ngTemplateOutletContext]="{
                            height: '100%'
                        }">
                    </ng-container>
                    <div class="app-c-card__body" *ngIf="resourceClicksLoaded">
                        <div
                            class="app-l-dsh__sa-wrap"
                            *ngIf="resourceData.length !== 0">
                            <div
                                class="app-l-dsh__sa-card"
                                *ngFor="let resource of resourceData">
                                <h5>{{ resource.title }}</h5>
                                <h3>
                                    {{ resource.count
                                    }}<span translate
                                        >clientList.overview.clicks</span
                                    >
                                </h3>
                                <div class="app-l-sa__time">
                                    Last {{ dateRangeSelected }} days
                                </div>
                            </div>
                        </div>
                        <div *ngIf="resourceData.length === 0">
                            <p class="center" translate>
                                clientList.overview.noResources
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="app-l-dashboard__wrap">
        <!-- <div class="app-l-dashboard__block app-l-dsh__sa">
			<div class="app-c-card">
				<div
					class="app-c-card__header"
				>
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title" translate>
							Snapshot
						</h3>
					</div>
				</div>
				<div
					class="app-c-card__body"
				>
					<div class="app-l-dsh__sa-wrap">
						<div
							class="app-l-dsh__sa-card"
						>
							<h5 class="app-c-body-text--12-m">
								First Plan
							</h5>
							<div class="app-l-sa__time">
								<svg-icon icon="clock" alt="clock"></svg-icon>
								Created On
								<span class="app-c-body-text--12"
									>Feb 14, wed</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->

        <!-- <div class="app-l-dashboard__block app-l-dsh__sa app-l-overview-snapshot">
			<div class="app-c-card">
				<div
					class="app-c-card__header"
				>
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title" translate>
							Latest DASS Result
						</h3>
					</div>
					<div class="app-l-card__h-right">
						<a role="tab" tabindex="0"
							class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
							routerLink="/app/practice/activityscheduler"
						>
							<span translate>menu.viewAll</span>
							<svg-icon icon="angle-right" alt="angle-right"></svg-icon>
						</a>
					</div>
				</div>
				<div
					class="app-c-card__body"
				>
					<div class="app-l-dsh__sa-wrap">
						<div class="app-l-graph__wrap">
							<div class="app-l-graph__container">
								<canvas
									#dasAssessmentChart
									id="dasAssessmentChart"
								></canvas>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> -->
    </div>
</div>
<ng-template let-level="level" #daslevelIndicationImg>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high"
        *ngIf="level == 4">
        <div class="app-c-welltrack__progress-fill" style="width: 65%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__high"
        *ngIf="level == 3">
        <div class="app-c-welltrack__progress-fill" style="width: 50%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__moderate"
        *ngIf="level == 2">
        <div class="app-c-welltrack__progress-fill" style="width: 25%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__mild"
        *ngIf="level == 1">
        <div class="app-c-welltrack__progress-fill" style="width: 10%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__good"
        *ngIf="level == 0">
        <div class="app-c-welltrack__progress-fill" style="width: 35%"></div>
    </div>
</ng-template>
<ng-template let-height="height" #skeletonLoader>
    <mat-card class="skeleton-card" [ngStyle]="{ height: height }">
        <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card>
</ng-template>
