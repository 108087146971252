<div class="confirm-popup text-center">
    <!-- <img alt="Moodcheck activity icon" src="./assets/img/moodcheck/new/Successpopup.svg"> -->
    <h2 mat-dialog-title class="modal-title app-c-heading--h3">{{title}}</h2>
    <div class="modal-message-container">
        <mat-dialog-content class="modal-message app-c-body-text--16">
            <div [innerHtml]="message"></div>
            <p *ngIf="helpLine">{{helpLine}}</p>
        </mat-dialog-content>
    </div>
    <div class="footer">
        <button
            mat-button
            mat-dialog-close
            class="app-c-btn app-c-btn--secondary app-c-btn--curve">
            {{cancelTxt ? cancelTxt : 'setting.no' | translate}}
        </button>
        <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
        <button
            mat-button
            [mat-dialog-close]="true"
            class="app-c-btn app-c-btn--primary app-c-btn--curve primary-button"
            translate>
            {{okTxt ? okTxt : 'setting.yes' | translate}}
        </button>
    </div>
</div>
