import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
} from "@angular/core";
import { MoodcheckOptionsService } from "../../../lib/moodcheck-options.service";
import { MoodcheckService } from "../../../lib/moodcheck.service";
import { LogService } from "../../../lib/log.service";
import { TranslateService } from "@ngx-translate/core";
import { config } from "../../../../environments/all";
//import * as jQuery from 'jquery';
//import 'roundslider';
import { ModalService } from "../../../lib/modal.service";
import { EventManagerService } from "../../../lib/event-manager.service";
import { StorageService } from "app/lib/storage.service";
import { ApiService } from "app/lib/api.service";
import { AnalyticEvent } from "app/lib/analytic-event";
import { MoodcheckOptionsPage } from "../moodcheck-options/moodcheck-options";

declare var jQuery: any;

@Component({
	selector: "page-mood-tab",
	templateUrl: "mood-tab.html",
	styleUrls: ["./mood-tab.scss"],
})
export class MoodTab implements OnInit, OnDestroy {
	currentMood: number;
	actualMood: number;
	touchStart: number;
	slider: any;
	category = "emotions";
	moodIconUrl: string;
	moodClassName = "";
	moodLabels: any;
	init: boolean;
	lang: any;
	moodLabel: string;
	@Output() onMoreClicked: EventEmitter<any> = new EventEmitter();
	@Input() options;
	@Input() feelings;
	constructor(
		private mcOptions: MoodcheckOptionsService,
		private log: LogService,
		private mcService: MoodcheckService,
		private translate: TranslateService,
		private eventService: EventManagerService,
		private storage: StorageService,
		public api: ApiService,
		private modalService: ModalService
	) {
		this.currentMood = 0;

		this.mcOptions.watcher.subscribe((updatedData: any) => {
			if (
				updatedData.category &&
				updatedData.category === this.category
			) {
				this.options = updatedData.options;
			}
		});

		this.eventService.registerEvent("animateMoodSlider", this, () => {
			this.animateMoodSlider();
		});
		this.init = true;
	}

	ngOnInit() {
		this.log.event(AnalyticEvent.event.moodCheckMoodTab);

		var _elementDown = $.fn.roundSlider.prototype._elementDown;

		var pathOpacity = 0.3;

		var radius = 350;

		var distance = 20;

		var sliderWidth = 25;

		var firstStartAngle = 23;

		var firstEndAngle = 157;

		var refreshSliderSize = function () {
			var radius = this.control.parent().width() / 2;
			var width = 14;
			if (radius > 350) radius = 350;
			if (radius > 120) width = 33;
			else if (radius > 70) width = 18;
			this.option({ radius: radius, width: width });
		};

		this.slider = (jQuery("#mood-slider") as any).roundSlider({
			svgMode: true,

			sliderType: "min-range",

			editableTooltip: false,

			width: sliderWidth,

			borderWidth: 0,

			startAngle: 0,

			endAngle: 100,

			lineCap: "round",

			showTooltip: false,

			animation: false,

			handleShape: "dot",

			handleSize: "+15",

			min: 0,

			max: 90,

			drag: (e) => {
				this.onSliderUpdate(e.value);
			},

			change: (e) => {
				this.onSliderUpdate(e.value);
			},

			create: function () {
				var that = this;
				refreshSliderSize.call(that);

				$(window).on("resize", function () {
					refreshSliderSize.call(that);
				});
			},
		});

		var incrementAngle = 2.5;

		(jQuery("#mood-slider") as any).roundSlider({
			rangeColor: "url(#grad1)",
			pathColor: "url(#grad1)",

			startAngle: firstStartAngle + incrementAngle * 0,

			endAngle: firstEndAngle - incrementAngle * 0,
		});

		jQuery("#mood-slider").on("touchmove", (e) => {
			e.preventDefault();
		});
		this.getIcon(0);
	}

	checkRange(value) {
		value = parseInt(value);
		this.moodLabels = "";
		if (isNaN(value)) {
			return "";
		}
		this.actualMood = value;
		this.setWithinRange();
		if (value >= 0) {
			this.slider.roundSlider("setValue", (this.actualMood - 1) * 10);
			this.currentMood = 10 - this.actualMood + 1;
			this.getLabel(this.actualMood);
			this.getIcon(this.actualMood == 0 ? 1 : this.actualMood);
			this.mcService.setMoodValue(this.actualMood);
		}
	}

	onSliderUpdate(value) {
		this.init = false;
		this.currentMood = Math.floor(value / 10) + 1;
		this.actualMood = Math.floor(value / 10) + 1;
		this.getLabel(this.actualMood);
		this.getIcon(this.actualMood == 0 ? 1 : this.actualMood);
		this.mcService.setMoodValue(this.actualMood);
	}

	toggleOption(option) {
		option.isSelected = !option.isSelected;

		if (option.isSelected) {
			this.mcService.addEmotion(option.name);
			this.log.event(
				AnalyticEvent.event.moodCheckSelectEmotion
			);
		} else {
			this.mcService.removeEmotion(option.name);
		}
	}

	// onNext() {
	// 	this.tabCtrl.slideTo(1, 'moodTabs');
	// 	//this.events.publish('mood-tab-slide:next', 1);
	// }

	onMore() {
		// this.onMoreClicked.emit({ options: this.options, category: this.category });
		this.modalService.showComponent(
			MoodcheckOptionsPage,
			{
				category: this.category,
				moreOptions: this.options,
			},
			"",
			true
		);
	}

	animateMoodSlider() {
		this.moodClassName = "fivePhasesFadeIn";

		setTimeout(() => {
			this.moodClassName = "";
		}, 4000);
	}

	ngOnDestroy() {
		this.eventService.unregisterEvent("animateMoodSlider", this);
	}

	onGoUp() {
		this.initMood();
		this.actualMood += 1;
		this.setWithinRange();
		this.currentMood = 10 - this.actualMood + 1;
		this.slider.roundSlider("setValue", this.actualMood * 10);
		this.getLabel(this.actualMood);
		this.getIcon(this.actualMood == 0 ? 1 : this.actualMood);
		this.mcService.setMoodValue(this.actualMood);
	}

	onGoDown() {
		this.initMood();
		this.actualMood -= 1;
		this.setWithinRange();
		this.currentMood = 10 - this.actualMood + 1;
		this.slider.roundSlider("setValue", this.actualMood * 10);
		this.getLabel(this.actualMood);
		this.getIcon(this.actualMood == 0 ? 1 : this.actualMood);
		this.mcService.setMoodValue(this.actualMood);
	}

	initMood() {
		if (this.actualMood === undefined) {
			this.actualMood = 10;
		}
	}

	setWithinRange() {
		if (this.actualMood === 0 || this.actualMood < 1) {
			this.actualMood = 1;
		}

		if (this.actualMood > 10) {
			this.actualMood = 10;
		}
	}

	getLabel(value: number) {
		this.moodLabels = this.feelings.find(
			(feelings) => feelings.key === (value == 0 ? "1" : value.toString())
		)["name"];
	}

	getIcon(value: number) {
		this.moodIconUrl = `/assets/img/moodcheck/new/${value}@2x.png`;
	}
}
