import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { NgxObjectDiffModule, NgxObjectDiffService } from 'ngx-object-diff';

@Component({
  selector: 'app-view-prevalue-audit',
  templateUrl: './view-prevalue-audit.component.html',
  styleUrls: ['./view-prevalue-audit.component.scss']
})
export class ViewPrevalueAuditComponent implements OnInit {
  Id: any;
  datas:any;
  auditdataprevArr: any=[];
  auditdatacurrentArr: any=[];
  nulldata: boolean;
  diffView: string;
  diffValueChanges: any;
  preval: Object;
  constructor(
    public dialogRef: MatDialogRef<ViewPrevalueAuditComponent>,
    private api: ApiService,
    private log: LogService,
    private modalService: ModalService,
    private translate: TranslateService,
    public storage: StorageService,
    private objectDiff: NgxObjectDiffService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.Id = this.data.data.aId;
  }

  ngOnInit(): void {
    this.api.get(`audit/getjson/${this.Id}`).subscribe(
      (results: any) => {
        if(results[0].PrevJSON || results[0].NewJSON){
        this.nulldata = false;
        this.auditdataprevArr.push(JSON.parse(results[0].PrevJSON));
        this.auditdatacurrentArr.push(JSON.parse(results[0].NewJSON));
        let diff = this.objectDiff.diff(this.auditdataprevArr,this.auditdatacurrentArr);
        this.preval = this.objectDiff.objToJsonView(this.auditdataprevArr);
        this.diffView = this.objectDiff.toJsonView(diff)
        this.diffValueChanges = this.objectDiff.toJsonDiffView(diff);
        this.log.debug(diff)
        this.log.debug(this.diffView)
        this.log.debug(this.diffValueChanges)
        }
        else{
          this.nulldata = true;
        }
      },
      (error: any) => {
        this.log.error('Error loading. ' + error.message);
        // this.isloaded = false;
      }
    );
  }

  onClose() {
		this.dialogRef.close();
	}
}
