import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../app/models/user';
import { UserService } from '../../../app/lib/user.service';
import { LogService } from '../../../app/lib/log.service';
import {
    CrisisHotline,
    LocationService,
} from '../../../app/lib/location.service';
import { ModalService } from '../../lib/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../lib/api.service';
import { Organization } from '../../../app/models/organization';
import { StorageService } from '../../lib/storage.service';
import { AnalyticEvent } from 'app/lib/analytic-event';

declare var window;

@Component({
    selector: 'emergency-contact',
    templateUrl: 'emergency-contact.component.html',
    styleUrls: ['./emergency-contact.component.scss'],
})
export class EmergencyContactComponent implements OnInit {
    hotline: CrisisHotline;
    @Input() user: User;
    popupText: any;
    emergencyContact: string;
    organization: Organization;
    isloaded: boolean = false;
    contactGroupTelephone: string;
    @Input() showAsButton: boolean = false;
    @Input() isTemplateView: boolean = false;
    @Input() templateType: string;
    @Input() cssClass: string = '';
    showOther: boolean;

    constructor(
        private userService: UserService,
        private log: LogService,
        private modalService: ModalService,
        private locationService: LocationService,
        private translate: TranslateService,
        private api: ApiService,
        private storage: StorageService
    ) {}

    ngOnInit() {
        this.user = this.userService.getUser();
        this.log.screen('Emergency Help');
        this.log.event(AnalyticEvent.event.hotlineCalled);

        this.translate.get('contact').subscribe((res: any) => {
            this.popupText = res;
        });
        this.getEmergencyHotlineContact();
        if (this.user?.primaryOrganization) {
            this.organization = this.user?.primaryOrganization;
            this.setEmergencyCntactInformation();
            this.isloaded = true;
        } else {
            this.showOther = true;
            this.isloaded = true;
        }
    }

    getEmergencyHotlineContact() {
        this.locationService.getLocation().subscribe(
            (data: any) => {
                this.log.debug(data);
                this.hotline = this.locationService.getHelpline(
                    data.country,
                    data.regionName
                );
            },
            (error: any) => {
                this.hotline = this.locationService.getHelpline();
            }
        );
    }

    setEmergencyCntactInformation() {
        if (this.organization?.contactGroup?.telephone) {
            this.contactGroupTelephone =
                this.organization.contactGroup.telephone;
        } else if (this.organization?.emergencyContact) {
            this.emergencyContact = this.decodeHtml(
                this.organization.emergencyContact
            );
            this.showOther = this.showAsButton ? true : false;
        } else {
            this.showOther = true;
        }
    }

    decodeHtml(html: string): string {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = html;
        return textarea.value;
    }

    onHotline(event: MouseEvent) {
        let params: [string, string, string?, string?, string?]; // Define params as a tuple type
        if (this.templateType === 'actionPlan') {
            params = [
                this.popupText.popTitle,
                this.popupText.popUpHotline,
                this.popupText.yes,
                this.popupText.cancel,
                this.hotline.title,
            ];
        } else {
            params = [
                this.popupText.confirmCall,
                this.popupText.popupEmergencyHotline,
            ];
        }
        this.modalService
            .showConfirmation(...params)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.log.event(AnalyticEvent.event.hotlineCalled);
                    window.location.href = `tel:${this.hotline.url}`;
                }
            });
    }

    // on911(event: MouseEvent) {
    //     if (this.dialing) {
    //         this.dialing = false;
    //     } else {
    //         event.preventDefault();
    //         this.modalService
    //             .showConfirmation(
    //                 this.popupText.popTitle,
    //                 this.popupText.popup911
    //             )
    //             .afterClosed()
    //             .subscribe(result => {
    //                 if (result) {
    //                     this.log.event(AnalyticEvent.event.called911);
    //                     this.dialing = true;
    //                     //Add analytics event for 911 dialed
    //                     event.target.dispatchEvent(new MouseEvent('click'));
    //                 }
    //             });
    //     }
    // }

    onCallContact(event: MouseEvent) {
        const hotLineText =
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings['assessment'] ===
                'resilience'
                ? this.popupText.popupEmergencyTextVeteran
                : this.organization.contactGroup.getHelpDescription
                  ? this.organization.contactGroup.getHelpDescription
                  : this.popupText.popupEmergencyHotline;

        let params: [string, string, string?, string?, string?]; // Define params as a tuple type

        if (this.templateType === 'actionPlan') {
            params = [
                this.popupText.confirmCall,
                hotLineText,
                this.popupText.yes,
                this.popupText.cancel,
                this.contactGroupTelephone,
            ];
        } else {
            params = [this.popupText.confirmCall, hotLineText];
        }

        this.modalService
            .showConfirmation(...params)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.log.event(AnalyticEvent.event.protocallNumberCalled);
                    window.location.href = `tel:${this.contactGroupTelephone}`;
                    this.api
                        .post('analytics/supportlineclick', {
                            phoneNumber: this.contactGroupTelephone,
                            contactGroupId: this.organization.contactGroup.id,
                        })
                        .subscribe(
                            (result: any) => {
                                this.log.debug('Activity logged');
                            },
                            (error: any) => {
                                this.log.debug(
                                    'Something went wrong with the activity logger.'
                                );
                            }
                        );
                }
            });
    }
}
