<div id="results" class="quiz-results-main-container">
    <div class="success-card">
        <div class="success-img">
            <img src="./assets/img/success.gif" />
        </div>
        <h2 class="app-c-heading--h3">Thank you!</h2>
        <p>Thought distortion quiz has been submitted successfully</p>
    </div>
    <div class="results-info-section custom-scrollbar">
        <div class="results-info-header-section">
            <div class="results-card correct-card">
                <h2>{{ correctAnswers.length }}</h2>
                <p class="app-c-heading--h5">Correct</p>
            </div>
            <div class="results-card incorrect-card">
                <h2>{{ wrongAnswers.length }}</h2>
                <p class="app-c-heading--h5">InCorrect</p>
            </div>
        </div>
        <div class="results-info-items">
            <div
                class="thinking-styles-items"
                *ngFor="let answer of wrongAnswers; let index = index">
                <div
                    class="selection-button"
                    [innerHTML]="answer.questionComment">
                    {{ answer.questionComment }}
                    <div class="select-icon">
                        <svg-icon
                            icon="tick-circle"
                            class="w-100 h-100"></svg-icon>
                    </div>
                </div>
                <button mat-raised-button [matMenuTriggerFor]="menu">
                    <svg-icon icon="info-icon-rectangle"></svg-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div class="tooltip" [innerHTML]="answer.true"></div>
                </mat-menu>
            </div>
        </div>
        <div class="app-c-card__footer">
            <button
                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                (click)="onFinish()"
                (keypress)="onFinish()"
                translate>
                das.close
            </button>
        </div>
    </div>

    <!-- <div class="results-slide">
        <div class="img-wrapper">
            <div>
                <img alt="Quiz icon" src='./assets/img/quiz_icon.png' style="width: 70px; height: auto;">
            </div>
            <h3 id='header' translate>results.title</h3>
        </div>
        <div class="body-wrapper">
            <div class="content">
                <h1 class="content-header">{{score}}%</h1>
                <div class="results">
                    <p *ngIf="score >= 90" translate>
                        results.well
                    </p>

                    <p *ngIf="score >= 65 && score < 90" translate>
                        results.ok
                    </p>

                    <p *ngIf="score >= 30 && score < 65" translate>
                        results.bad
                    </p>

                    <p *ngIf="score < 30" translate>
                        results.review
                    </p>

                </div>
            </div>
        </div>

        <div class="fixed-footer">
            <a tabindex="0" (click)="onFinish()" (keypress)="onFinish()" class="btn btn-primary"
                translate>results.finish</a>
        </div>
    </div> -->
</div>
