<div class="app-c-modal-primary">
  <div class="app-c-modal-primary__header">
      <div class="app-c-modal-primary__header-content">
        <h2 class="app-c-modal-primary__title" translate>practice.rfp.title</h2>
      </div>
      <button tabindex="0" (click)="goBack()" (keypress)="goBack()" class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-16">
        <svg-icon icon="close" class="w-100 h-100"></svg-icon>
      </button>
  </div>
  <div class="app-c-modal-primary__body">
    <div class="app-l-rc__info">
      <div class="app-l-rc__entry">
        <span class="app-l-rc__entry-lavel" translate> practice.rfp.entryOn</span>
        <h4 class="app-l-rc__entry-date">{{ pageData?.created_at | date : "dd MMM YYYY" }}</h4>
      </div>
      <div class="app-l-rc__action" *ngIf = "user.userType === 'user' || (user.userType !== 'user' && id === null)">
        <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="deleteEntry()">
          <svg-icon icon="trash"></svg-icon>
          <span translate>practice.rfp.delete</span>
        </button>
        <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="editEntry()">
          <svg-icon icon="edit"></svg-icon>
          <span translate>practice.rfp.edit</span>
        </button>
      </div>
    </div>

      <div class="accordion">
        <div class="accordion-item">
          <button class="aoocrdion-head-btn" id="accordion-button-1" [attr.aria-expanded]="openProudAccordian" (click)="openProudAccordian = !openProudAccordian">
            <span class="icon" aria-hidden="true">
              <svg-icon icon="minus-circle" class="w-100 h-100"></svg-icon>
              <svg-icon icon="plus-circle" class="w-100 h-100"></svg-icon>
            </span>
            <span class="accordion-title" translate>practice.rfp.shortTitleProud </span>
          </button>
          <div class="accordion-content">
            <h6>{{pageData.proudTitle}}</h6>
            <div class="app-dm-body">
              <p>{{pageData.proudDescription}}</p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <button class="aoocrdion-head-btn" id="accordion-button-2" [attr.aria-expanded]="openGratefulAccordian" (click)="openGratefulAccordian = !openGratefulAccordian">
            <span class="icon" aria-hidden="true">
              <svg-icon icon="minus-circle" class="w-100 h-100"></svg-icon>
              <svg-icon icon="plus-circle" class="w-100 h-100"></svg-icon>
            </span>
            <span class="accordion-title" translate>practice.rfp.shortTitleGrateful</span>
          </button>
          <div class="accordion-content">
            <h6>{{pageData.gratefulTitle}}</h6>
              <div class="app-dm-body">
                <p>{{pageData.gratefulDescription}}</p>
              </div>
            </div>
        </div>
      </div>

      <!-- <mat-accordion>
        <mat-expansion-panel  [expanded]="questionIndex == 0" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="header-title" translate>
                {{ questions[0].questionShortTitle }}
              </h3>

            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="sub-title">
            {{pageData[questionTypes[0] + "Title"]}}
          </div>
          <div class="answer-description">
            {{ pageData[questionTypes[0] + "Description"] }}
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel  [expanded]="questionIndex == 1" (opened)="panelOpenState = true"
        (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="header-title" translate>
                {{ questions[1].questionShortTitle }}
                <div *ngIf="questionIndex == 1" class="header-line2"></div>
              </h3>

            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="sub-title">
            {{pageData[questionTypes[1] + "Title"]}}
          </div>
          <div class="answer-description">
            {{ pageData[questionTypes[1] + "Description"] }}
          </div>
        </mat-expansion-panel>
      </mat-accordion> -->
  </div>
</div>
