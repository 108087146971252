<div class="form-modal">
    <mat-dialog-content>
        <div class="row">

            <div class="col-md-6 left-text-wrapper">
                <div class="clearfix"></div>
                <div class="title-wrapper">
                    <h4 class="strong" translate>share.shareWith <span translate>{{modalContent.to}}</span></h4>
                    <p class="strong" translate>{{modalContent.content}}</p>
                </div>
                <div class="form-wrapper">
                    <form novalidate [formGroup]="shareForm" (ngSubmit)="doSave()">
                        <label for="fullName"><span translate>share.email</span></label>
                        <mat-form-field appearance="outline">
                            <input matInput class="wt-mat-input" type="text" formControlName="Email" id="email" #emailInput/>
                            <mat-error *ngIf="isSubmitted && shareForm.get('Email').hasError('required')" translate>
                                share.emailField
                            </mat-error>
                            <mat-error *ngIf="isSubmitted && shareForm.get('Email').hasError('email')" translate>
                                share.validEmail
                            </mat-error>
                        </mat-form-field>

                        <label for="fullName" translate>share.message</label>
                        <mat-form-field appearance="outline">
                            <textarea [attr.aria-label]="'share.message'| translate"matInput class="wt-mat-input" type="text" id="setting"
                                formControlName="Message"></textarea>
                        </mat-form-field>


                        <div class="fixed-footer">
                            <button tabindex="0" class="btn-primary share-footer-button btn" translate>
                                share.help <span translate>{{modalContent.to}}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6">
                <div class="clearfix"></div>
                <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
                    aria-label="Close organization edit form">
                    <img src="./assets/img/icons-svg/close-icon.svg">
                </a>
                <div>
                    <img src="./assets/img/Share_with_Friends.jpg" style="height: 600px;">
                </div>

            </div>
        </div>


    </mat-dialog-content>
</div>