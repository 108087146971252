<page-loader *ngIf="!isLoaded"></page-loader>
<div class="form-screen-main">
    <div id="page-login" class="form-screen-container public-portal-right-col">
        <div
            id="login-header"
            class="login-header"
            *ngIf="!loginApiService.healthCanadaEnable">
            <img
                [alt]="name"
                *ngIf="loginApiService.logoPath"
                [src]="loginApiService.logoPath"
                class="animated fadeInDown welltrack-img-horizontal" />
            <h2
                *ngIf="loginApiService.state == 'login'"
                class="login-title"
                id="section-title">
                Login
            </h2>

            <!--
            <div *ngIf="!loginApiService.logoPath" class="animated fadeInDown login-logo"><svg-icon
                    icon="welltrack-logo"></svg-icon></div> -->
        </div>

        <div id="login-header" *ngIf="loginApiService.healthCanadaEnable">
            <p
                class="enable-health-canada"
                [innerHTML]="'signUp.healthCanada' | translate"></p>
        </div>

        <div *ngIf="!closeNotice" id="main-content" class="main-content">
            <div
                class="login-info-text"
                *ngIf="loginApiService.state == 'login'">
                <p class="app-c-body-text--14" translate>
                    Enter your email address
                    <span *ngIf="!continue" translate>and Password</span>
                </p>
            </div>
            <div
                class="signUp-info-text"
                *ngIf="loginApiService.state == 'registerInfo'">
                <h2 class="app-c-heading--h2" translate>
                    signUp.createAccount
                </h2>
                <p class="app-c-body-text--14" translate>
                    signUp.emailNotExist
                </p>
            </div>
            <div
                class="signUp-info-text"
                *ngIf="loginApiService.state == 'register' && !loginApiService.showNext">
                <h2 class="app-c-heading--h2" translate>signUp.title</h2>
                <p class="app-c-body-text--16" translate>signUp.enterInfo</p>
            </div>
            <div class="accesscode-info-text" *ngIf="loginApiService.showNext">
                <h2 class="app-c-heading--h2" translate>signUp.title</h2>
                <h2 class="app-c-body-text--14" translate>
                    signUp.enterTrialInfo
                </h2>
            </div>
            <form (keydown.enter)="triggerNextStep()" #loginForm="ngForm">
                <!-- Account detection form | triggers next step which is either account creation or login-->
                <div
                    *ngIf="loginApiService.state !== 'complete' && loginApiService.state !== 'sso'">
                    <div *ngIf="!continue">
                        <label
                            *ngIf="loginApiService.state == 'register' && !loginApiService.showNext"
                            for="email"
                            class="app-c-body-text--14-m"
                            translate
                            >signUp.orgEmailId</label
                        >
                        <div
                            class="icon-input email-text"
                            (click)="onToggleContinue()">
                            <svg-icon
                                alt="Email"
                                icon="email-logo"
                                *ngIf="loginApiService.state == 'register' && !loginApiService.showNext"></svg-icon>
                            <p>
                                {{emailAddress}}<span
                                    *ngIf="loginApiService.suffix"
                                    >{{loginApiService.suffix}}</span
                                ><span *ngIf="domain">{{domain}}</span>
                            </p>
                            <a class="edit-button">
                                <svg-icon
                                    alt="Edit"
                                    icon="pen-edit-icon"></svg-icon>
                            </a>
                        </div>
                    </div>

                    <div *ngIf="continue">
                        <div class="icon-input" *ngIf="loginApiService.suffix">
                            <!-- <img alt="Email validates" *ngIf="emailAddress && emailValidate && !loginApiService.suffix"
                                src="./assets/img/icons/check@2x.png" class="check animated fadeInUp" item-content> -->
                            <div [class.input-suffix]="loginApiService.suffix">
                                <div class="suffix-wrapper">
                                    <div class="custom-input-container">
                                        <label
                                            for="email"
                                            class="app-c-body-text--14-m"
                                            translate
                                            >signUp.emailLabel</label
                                        >
                                        <div class="custom-input-section">
                                            <input
                                                class="wt-auto-login-email"
                                                tabindex="0"
                                                id="email"
                                                type="email"
                                                aria-label="Email"
                                                placeholder="{{'signUp.emailPlaceholder' | translate}}"
                                                [(ngModel)]="emailAddress"
                                                name="emailAddress"
                                                [class.fieldl]="loginApiService.suffix"
                                                (ngModelChange)="onChangeEmail($event)"
                                                class="app-c-body-text--14" />
                                            <svg-icon
                                                alt="Email"
                                                icon="email-logo"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    *ngIf="loginApiService.suffix"
                                    class="suffix">
                                    {{loginApiService.suffix}}
                                </div>
                                <div
                                    *ngIf="loginApiService.domains"
                                    class="suffix">
                                    <select
                                        class="wt-auto-login-domain"
                                        name="suffix"
                                        [(ngModel)]="domain"
                                        tabindex="0"
                                        aria-label="Select subdomain">
                                        <option value="" translate>
                                            signUp.selectSubdomain
                                        </option>
                                        <option
                                            *ngFor="let domain of loginApiService.domains"
                                            [value]="domain">
                                            {{domain}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="icon-input" *ngIf="!loginApiService.suffix">
                            <div class="custom-input-container">
                                <label
                                    for="email"
                                    class="app-c-body-text--14-m"
                                    translate
                                    >signUp.emailLabel</label
                                >
                                <div class="custom-input-section">
                                    <input
                                        class="wt-auto-login-email"
                                        tabindex="0"
                                        aria-label="Email"
                                        type="email"
                                        placeholder="{{'loginMobile.emailAddress' | translate}}"
                                        (ngModelChange)="onChangeEmail($event)"
                                        name="emailAddress"
                                        [(ngModel)]="emailAddress"
                                        class="app-c-body-text--14" />
                                    <svg-icon
                                        alt="Email"
                                        icon="email-logo"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="signUp-info-text"
                    *ngIf="loginApiService.state == 'registerInfo'">
                    <h2 class="app-c-body-text--14" translate>
                        signUp.registerInfo
                    </h2>
                </div>
                <!-- login form-->
                <div *ngIf="loginApiService.state == 'login'">
                    <div class="icon-input password-field" *ngIf="!continue">
                        <div class="custom-input-container">
                            <label for="password" class="app-c-body-text--14-m"
                                >Password</label
                            >

                            <div
                                class="custom-input-section custom-input-password-section">
                                <input
                                    #pwdInput
                                    class="wt-auto-login-password"
                                    tabindex="0"
                                    aria-label="Password"
                                    id="password"
                                    type="{{passwordType}}"
                                    placeholder="{{'loginMobile.password' | translate}}"
                                    name="password"
                                    [(ngModel)]="password"
                                    (ngModelChange)="onChangePassword()" />
                                <svg-icon
                                    alt="Password"
                                    icon="password-logo"></svg-icon>
                                <!-- <img *ngIf="passwordValidate && password" src="./assets/img/icons/check@2x.png"
                                class="check animated fadeInUp" item-content> -->
                                <a
                                    class="password-show-button"
                                    (click)="onClickPassword()"
                                    [ngClass]="passwordType == 'password'? 'show-eye' : 'hide-eye'"
                                    tabindex="0">
                                    <svg-icon
                                        [alt]="passwordType == 'password'? 'Hide password' : 'Show password'"
                                        [icon]="passwordType == 'password'? 'eye-icon-hidden' : 'eye-icon'"></svg-icon>
                                </a>
                            </div>
                            <!-- <i class="icon-password-eye" (click)="onClickPassword()" style="cursor: pointer;"
                                [ngClass]="passwordType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"></i> -->
                        </div>
                    </div>

                    <p
                        class="right-align strong mt-half forget-password-section"
                        *ngIf="!continue">
                        <a
                            class="wt-auto-forgot-password app-c-body-text--12"
                            tabindex="0"
                            routerLink="forgot-password"
                            translate
                            >loginMobile.forgot</a
                        >
                    </p>
                </div>

                <!-- Create form-->
                <div *ngIf="loginApiService.state == 'register'">
                    <div *ngIf="!loginApiService.showNext">
                        <div class="icon-input margin-b-16px">
                            <!-- <img  src="./assets/img/icons/check@2x.png" class="check animated fadeInUp"
                                item-content> -->
                            <div class="custom-input-container">
                                <label
                                    for="full-name"
                                    class="app-c-body-text--14-m"
                                    >Full Name</label
                                >
                                <div class="custom-input-section">
                                    <input
                                        class="wt-auto-login-email"
                                        tabindex="0"
                                        aria-label="Name"
                                        type="text"
                                        placeholder="{{'loginMobile.name' | translate}}"
                                        name="fullName"
                                        id="full-name"
                                        [(ngModel)]="fullName" />
                                    <svg-icon
                                        alt="user"
                                        icon="user-icon"></svg-icon>
                                    <button
                                        *ngIf="fullName"
                                        class="validation-tick">
                                        <svg-icon
                                            alt="validation tick"
                                            icon="validation-tick"
                                            class="check animated fadeInUp"></svg-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="icon-input password-field margin-b-16px">
                            <!-- <img *ngIf="passwordValidate && password" src="./assets/img/icons/check@2x.png"
                                class="check animated fadeInUp" item-content> -->
                            <div class="custom-input-container">
                                <label
                                    for="signup-password"
                                    class="app-c-body-text--14-m"
                                    >Password</label
                                >
                                <div
                                    class="custom-input-section custom-input-password-section">
                                    <input
                                        class="wt-auto-login-password"
                                        tabindex="0"
                                        aria-label="Password"
                                        tabindex="0"
                                        type="{{passwordType}}"
                                        placeholder="{{'loginMobile.password' | translate}}"
                                        name="password"
                                        [(ngModel)]="password"
                                        (ngModelChange)="onChangePassword()"
                                        id="signup-password" />
                                    <svg-icon
                                        alt="Password"
                                        icon="password-logo"></svg-icon>
                                    <img
                                        *ngIf="passwordValidate && password"
                                        src="./assets/img/icons/check@2x.png"
                                        class="check animated fadeInUp"
                                        item-content />
                                    <a
                                        class="password-show-button"
                                        (click)="onClickPassword('password')"
                                        [ngClass]="passwordType == 'password'? 'show-eye' : 'hide-eye'"
                                        tabindex="0">
                                        <svg-icon
                                            [alt]="passwordType == 'password'? 'Hide password' : 'Show password'"
                                            [icon]="passwordType == 'password'? 'eye-icon-hidden' : 'eye-icon'"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <p
                                aria-live="assertive"
                                role="alert"
                                *ngIf="!passwordValidate && password"
                                class="error-info app-c-body-text--12"
                                translate>
                                signUp.passwordValidate
                            </p>
                        </div>

                        <div class="icon-input password-field margin-b-16px">
                            <!-- <img alt="password validates" *ngIf="passwordRepeatValidate && passwordrepeat"
                                src="./assets/img/icons/check@2x.png" class="check animated fadeInUp" item-content> -->
                            <div class="custom-input-container">
                                <label
                                    for="signup-password-confirm"
                                    class="app-c-body-text--14-m"
                                    >Re-enter Password</label
                                >
                                <div
                                    class="custom-input-section custom-input-password-section">
                                    <input
                                        class="wt-auto-login-passwordrepeat"
                                        tabindex="0"
                                        aria-label="Password repeat"
                                        id="passwordrepeat"
                                        type="{{passwordConfirmationType}}"
                                        placeholder="{{passwordChangeText.placeholders.passwordrepeat}}"
                                        [(ngModel)]="passwordrepeat"
                                        name="passwordrepeat"
                                        (ngModelChange)="onChangeRepeat()"
                                        id="signup-password-confirm" />
                                    <svg-icon
                                        alt="Password"
                                        icon="password-logo"></svg-icon>
                                    <img
                                        *ngIf="passwordValidate && passwordRepeatValidate"
                                        src="./assets/img/icons/check@2x.png"
                                        class="check animated fadeInUp"
                                        item-content />
                                    <a
                                        class="password-show-button"
                                        (click)="onClickPassword('confirmPassword')"
                                        [ngClass]="passwordConfirmationType == 'password'? 'show-eye' : 'hide-eye'"
                                        tabindex="0">
                                        <svg-icon
                                            [alt]="passwordConfirmationType == 'password'? 'Hide password' : 'Show password'"
                                            [icon]="passwordConfirmationType == 'password'? 'eye-icon-hidden' : 'eye-icon'"></svg-icon>
                                    </a>
                                </div>
                            </div>
                            <p
                                aria-live="assertive"
                                role="alert"
                                *ngIf="!passwordRepeatValidate && passwordrepeat"
                                class="error-info app-c-body-text--12"
                                translate>
                                signUp.passwordRepeatValidate
                            </p>
                        </div>

                        <div
                            class="icon-group input-full-width"
                            *ngIf="loginApiService.organizations">
                            <mat-form-field>
                                <mat-select
                                    class="wt-auto-login-organization"
                                    name="orgId"
                                    [(ngModel)]="orgId"
                                    placeholder="Select an organization">
                                    <mat-option
                                        *ngFor="let organization of loginApiService.organizations"
                                        [value]="organization.id">
                                        {{organization.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="loginApiService.showNext">
                        <h2
                            class="center access-heading margin-b-16px app-c-body-text--16-m"
                            translate>
                            signUp.toGainFullAccess
                        </h2>

                        <div class="custom-input-container">
                            <label
                                translate
                                for="accessCode"
                                class="app-c-body-text--14-m"
                                >signUp.enterAccessCode</label
                            >
                            <div class="custom-input-section">
                                <input
                                    aria-label="Access code"
                                    tabindex="0"
                                    type="text"
                                    placeholder="GHJK432"
                                    name="accesscode"
                                    [(ngModel)]="accesscode"
                                    id="accessCode"
                                    name="accesscode"
                                    class="uppercase wt-auto-login-accesscode" />
                                <svg-icon
                                    alt="Access code"
                                    icon="access-code-icon"></svg-icon>
                            </div>
                        </div>
                        <div class="divider">
                            <div class="left-border"></div>
                            <p
                                translate
                                class="center divider-text app-c-body-text--12-m">
                                OR
                            </p>
                            <div class="right-border"></div>
                        </div>
                        <div class="custom-input-container">
                            <label class="app-c-body-text--14-m" translate
                                >signUp.enterOrgEmail</label
                            >
                            <div class="custom-input-section">
                                <input
                                    name="organizationEmail"
                                    class="wt-auto-login-orgemail"
                                    aria-label="Organization email"
                                    tabindex="0"
                                    id="orgemail"
                                    type="email"
                                    placeholder="john@unb.com"
                                    [(ngModel)]="orgEmail"
                                    name="emailAddress" />
                                <svg-icon
                                    alt="Email"
                                    icon="email-logo"></svg-icon>
                            </div>
                        </div>

                        <div class="divider">
                            <div class="left-border"></div>
                            <p
                                translate
                                class="center divider-text app-c-body-text--12-m">
                                OR
                            </p>
                            <div class="right-border"></div>
                        </div>

                        <p>
                            <mat-checkbox
                                class="checkbox-section checkbox-section-second"
                                name="skip"
                                [(ngModel)]="skip"
                                ><span translate>signUp.skip</span>
                            </mat-checkbox>
                        </p>
                    </div>
                </div>

                <div
                    class="sso-terms-link"
                    *ngIf="loginApiService.showTerms && !loginApiService.showNext && (loginApiService.state === 'register' || loginApiService.state === 'sso' || validAssertion === true)">
                    <div
                        class="sso-info center"
                        *ngIf="loginApiService.state === 'sso'"
                        translate>
                        signUp.tos.copy
                    </div>
                    <p
                        class="sso-custominfo center"
                        *ngIf="loginApiService.state === 'sso'">
                        <a
                            class="wt-auto-corporate-policy"
                            *ngIf="activateCorporatePolicy"
                            tabindex="0"
                            href="/corporate-policy"
                            alt="Corporate policy"
                            translate
                            >signUp.tos.corporatepolicy</a
                        >
                        <a
                            class="wt-auto-terms-and-conditions"
                            *ngIf="!activateCorporatePolicy"
                            tabindex="0"
                            href="/terms-and-conditions"
                            alt="Terms and conditions"
                            translate
                            >signUp.tos.tos</a
                        >
                        <span translate class="strong">|</span>
                        <a
                            class="wt-auto-privacy-policy"
                            tabindex="0"
                            href="/privacy-policy"
                            alt="Privacy policy"
                            translate
                            >signUp.tos.privacy</a
                        >.
                    </p>
                    <p
                        class="strong"
                        class="sso-custominfo"
                        [innerHtml]="loginApiService.customConfirm"
                        *ngIf="loginApiService.customConfirm"></p>
                    <mat-checkbox
                        name="I agree all terms and conditions"
                        [(ngModel)]="loginApiService.agree"
                        translate
                        class="checkbox-section sso-center"
                        *ngIf="loginApiService.state === 'sso'">
                        <span translate>signUp.iAgree</span>
                    </mat-checkbox>

                    <mat-checkbox
                        name="I agree all terms and conditions"
                        [(ngModel)]="loginApiService.agree"
                        translate
                        class="checkbox-section"
                        *ngIf="loginApiService.state != 'sso'">
                        <span translate>signUp.iAgree</span>
                    </mat-checkbox>

                    <div
                        class="icon-group input-full-width"
                        *ngIf="loginApiService.organizations && loginApiService.state === 'sso'">
                        <mat-form-field>
                            <mat-select
                                class="wt-auto-login-organization"
                                name="orgId"
                                [(ngModel)]="orgId"
                                placeholder="Select an organization">
                                <mat-option
                                    *ngFor="let organization of loginApiService.organizations"
                                    [value]="organization.id">
                                    {{organization.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <a
                        *ngIf="loginApiService.validAssertion"
                        tabindex="0"
                        (click)="onConfirmSso()"
                        class="btn btn-secondary btn-sso"
                        translate
                        >loginMobile.continue</a
                    >
                </div>

                <!-- Action buttons-->
                <div class="center">
                    <div
                        *ngIf="loginApiService.state == 'login'"
                        class="center">
                        <a
                            tabindex="0"
                            (click)="onContinue()"
                            (keyup.enter)="onContinue()"
                            [ngClass]="{'btn-disabled': !emailValidate}"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve wt-auto-login-continue"
                            translate
                            *ngIf="continue"
                            >loginMobile.signIn</a
                        >
                        <a
                            tabindex="0"
                            [ngClass]="{'btn-disabled': loginApiService.submitting}"
                            (click)="!loginApiService.submitting? onLogin(): false "
                            (keyup.enter)="!loginApiService.submitting? onLogin(): false "
                            class="app-c-btn app-c-btn--primary app-c-btn--curve wt-auto-login-continue"
                            translate
                            *ngIf="!continue"
                            >loginMobile.signIn</a
                        >
                    </div>
                    <div
                        *ngIf="loginApiService.state == 'registerInfo'"
                        class="access-footer-buttons">
                        <a
                            tabindex="0"
                            (click)="registerInfo()"
                            [ngClass]="{'btn-disabled': !emailValidate}"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve wt-auto-login-continue"
                            translate
                            >signUp.title</a
                        >
                    </div>
                    <div
                        *ngIf="loginApiService.state == 'register'"
                        class="access-footer-buttons">
                        <a
                            tabindex="0"
                            *ngIf="loginApiService.showNext"
                            (click)="onPrevious()"
                            (keyup.enter)="onPrevious()"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                            translate
                            >signUp.previous</a
                        >
                        <a
                            tabindex="0"
                            [ngClass]="{'btn-disabled': loginApiService.submitting}"
                            (click)="!loginApiService.submitting? onCreate(): false"
                            (keyup.enter)="!loginApiService.submitting? onCreate(): false"
                            class="app-c-btn app-c-btn--primary app-c-btn--curve wt-auto-login-continue"
                            translate
                            >signUp.create</a
                        >
                    </div>
                    <div
                        *ngIf="loginApiService.state == 'sso' && loginApiService.ssoType === 'saml2' &&  loginApiService.validAssertion === false"
                        class="center">
                        <a
                            tabindex="0"
                            href="{{loginApiService.sso}}"
                            class="btn btn-secondary btn-sso wt-auto-login-sso"
                            translate
                            >signUp.signUp</a
                        >
                    </div>
                    <div *ngIf="loginApiService.ssoType === 'oauth2pkce'">
                        <sso-authorization-login
                            [ssoParams]="loginApiService.sso"></sso-authorization-login>
                    </div>
                    <div *ngIf="loginApiService.state == 'complete'">
                        <a
                            tabindex="0"
                            tabindex="0"
                            (click)="onComplete()"
                            (keyup.enter)="onComplete()"
                            class="btn btn-secondary wt-auto-login-complete"
                            translate
                            >loginMobile.continue</a
                        >
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="closeNotice" translate class="center close-text">
            Please close
        </div>
    </div>
    <div id="policies-wrapper" class="text-center policies-wrapper">
        <a
            class="wt-auto-corporate-policy app-c-body-text--12-m"
            *ngIf="activateCorporatePolicy"
            tabindex="0"
            href="/corporate-policy"
            alt="Corporate policy"
            translate
            >signUp.tos.corporatepolicy</a
        >
        <a
            class="wt-auto-terms-and-conditions app-c-body-text--12-m"
            *ngIf="!activateCorporatePolicy"
            tabindex="0"
            href="/terms-and-conditions"
            alt="Terms and conditions"
            translate
            >signUp.tos.tos</a
        >
        <span class="app-c-body-text--12-m">|</span>
        <a
            class="wt-auto-privacy-policy app-c-body-text--12-m"
            tabindex="0"
            href="/privacy-policy"
            alt="Privacy policy"
            translate
            >signUp.tos.privacy</a
        >
    </div>
</div>
