<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap" *ngIf="isLoaded">
	<div id="page-assessment-listing">
		<div class="app-l-assesment__wrap" *ngIf="listingAssessments.length > 0">
			<div class="app-l-assesment__block" *ngFor="let assessmentslist of listingAssessments">
				<a class="app-l-assesment__link" [routerLink]="[
                        '/app/assessment',
                        assessmentslist.key,
                        'listing'
                    ]">
					<!-- <img alt="Plus" src="./assets/img/assesment/DASSAssessment.svg"> -->
					<div class="app-l-dm__row">
						<div class="app-l-dm__image">
							<img [src]="
                                    './assets/img/icons-svg/assessments/assessment_type_' +
                                    assessmentslist.key +
                                    '.svg'
                                " />
						</div>
						<div class="app-l-assesment__angle">
							<svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
						</div>
					</div>

					<h3 class="app-c-heading--h3" translate>
						{{ assessmentslist.title }}
					</h3>
					<p class="app-c-body-text--16" translate>
						{{ assessmentslist.description }}
					</p>
				</a>
			</div>
		</div>
		<div *ngIf="listingAssessments.length === 0">
			<p class="center">
				<span translate>das.noAssessment</span>
			</p>
		</div>
	</div>
</div>
