<div class="reminder-modal-container">
    <div class="modal-header-section">
        <h2 class="app-c-heading--h3">MoodCheck Reminders</h2>
        <button class="close-button"><svg-icon icon="close-v2"></svg-icon></button>
    </div>
    <div class="modal-content-section">
        <p class="modal-content-text">To help notice your mood patterns,
            Boost can prompt you to complete mood checks each day.</p>
        <div class="badges-container">
            <h3>How many times a day do you want to be prompted?</h3>
            <div class="badges-section">
                <div class="badge" *ngFor="let item of [1,2,3,4,0]" (click)="moodcheckFreq = item" [ngClass]="{'active': moodcheckFreq == item, 'never-badge': item == 0}" translate>{{'moodcheck.moodcheckFrequency.'+ item}}</div>
            </div>
        </div>
    </div>
    <div class="modal-footer-section">
        <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="skip()">Skip</button>
        <button class="app-c-btn app-c-btn--primary app-c-btn--curve done-button" (click)="saveMoodcheckFrequencyModal()">Done</button>
    </div>
</div>