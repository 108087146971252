import { Injectable, OnInit } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { LogService } from './log.service';

import 'rxjs/Rx';
import { Subject } from 'rxjs';
//import { ModalController, Modal } from 'ionic-angular';
//import { MoodcheckOptionsPage } from '../../pages/moodcheck/moodcheck-options/moodcheck-options';


import * as _ from 'lodash';
import { config } from '../../environments/all';
import { ModalService } from './modal.service';
import { ApiService } from './api.service';
import { MoodcheckOptionsPage } from '../components/moodcheck-modal/moodcheck-options/moodcheck-options';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class MoodcheckOptionsService implements OnInit {

	watcher = new Subject();
	options: any;

	constructor(
		private modalService: ModalService,
		private logService: LogService,
		private apiService: ApiService,
		private storage: StorageService,
		private translate: TranslateService) {
		this.initOptions(translate.currentLang);
		this.translate.stream('lang').subscribe((res: any) => {
			this.initOptions(res);
		});
	}

	ngOnInit() {

	}


	initOptions(language: string): Promise<any> {
		this.options = {};

		return new Promise(resolve => {
			this.apiService.get(`config/moodcheck`).subscribe(
				(data: any) => {
				
					const moodOptions = data;
					this.options = moodOptions.default;
					this.options.feelings = this.initCategory(moodOptions.default.feelings, "default");
					this.options.emotions = _.union(
						this.initCategory(moodOptions.custom?.emotions || [], "custom"),
						this.initCategory(moodOptions.default.emotions, "default")
					);
					this.options.people = _.union(
						this.initCategory(moodOptions.custom?.people || [], "custom"),
						this.initCategory(moodOptions.default.people, "default")
					);
					this.options.places = _.union(
						this.initCategory(moodOptions.custom?.places || [], "custom"),
						this.initCategory(moodOptions.default.places, "default")
					);
					this.options.activities = _.union(
						this.initCategory(moodOptions.custom?.activities || [], "custom"),
						this.initCategory(moodOptions.default.activities, "default")
					);

					this.storage.set('moodcheckOptions', this.options, true);
					resolve(this.options);
				},
				(error: any) => {
					this.logService.error('moodcheck_error');
					//reject(new Error(""));
				}
			);
		});

	}

	initCategory(options: any, type: string) {
		let results = [];

		for (let i = 0; i < options.length; i++) {
			results.push({
				name: options[i].Value,
				key: type == "default" ? options[i].Key : options[i].Value,
				custom: type == "default" ? false : true,
				enabled: type == "default" ? (options[i].Show == "1" ? true : false) : true,
				usedCount: 0,
				id: options[i].ID,
				show: options[i].Show
			});
		}

		return results;
	}

	getCategory(category) {
		//filter out disabled items, and sort my most used
		this.logService.debug('inside getCategory', category);
		let filteredOptions = this.options[category];
		filteredOptions = _.filter(filteredOptions, { 'enabled': true });
		filteredOptions = _.sortBy(filteredOptions, function (o: any) { return o.usedCount * -1; });
		return _.cloneDeep(filteredOptions);
	}

	refreshData() {
		this.logService.debug('inside refreshdata');
		let category = this.storage.get('moodcheckOptionsCategory');
		this.options = this.storage.get('moodcheckOptions', true);

		this.watcher.next({ category: category, options: this.getCategory(category) });
	}

	show(params?) {

		if (!params) {
			params = {};
		}

		this.storage.set('moodcheckOptionsCategory', params.category);
		this.modalService.showComponent(MoodcheckOptionsPage, {});
		//@ViewChild(MoodcheckOptionsPage) modal: MoodcheckOptionsPage;
		// this.modal = this.modalCtrl.create(MoodcheckOptionsPage, params, {cssClass: 'inset-modal'});

		// this.modal.onDidDismiss(data => {


		//     this.options = this.storage.get('moodcheckOptions');

		//     this.watcher.next({category: params.category, options: this.getCategory(params.category)});
		// });


		// this.modal.present();       
	}

	formatData(options) {
		for (let i = 0; i < options.length; i++) {
			options[i]['custom'] = false,
				options[i]['enabled'] = true,
				options[i]['usedCount'] = 0

		}

		return options;
	}
}
