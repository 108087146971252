import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../lib/api.service";
import { StorageService } from "../../lib/storage.service";
import { LogService } from "../../lib/log.service";
import { ModalService } from "../../lib/modal.service";
import { Router } from "@angular/router";
import { User } from "../../models/user";
import { Moodcheck } from "../../models/moodcheck";
import { Activity } from "../../models/activity";
import { UserService } from "../../lib/user.service";
import { ThoughtDiary } from "../../models/thought-diary";
import { TranslateService } from "@ngx-translate/core";
import { MatTabChangeEvent } from '@angular/material/tabs';
import * as _ from "lodash";
import { AnalyticEvent } from "app/lib/analytic-event";
import { MatDatepicker } from "@angular/material/datepicker";
import * as moment from "moment";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { ActionPlanList } from "app/models/action-plan";
import { UtilityService } from "app/lib/utility.service";

interface ActionPlan {
  createdDate: string;
  createdBy: string;
  actionPlan: string;
}

@Component({
  selector: 'app-action-plan-listing',
  templateUrl: './action-plan-listing.component.html',
  styleUrls: ['./action-plan-listing.component.scss'],
})
export class ActionPlanListingComponent implements OnInit {
  @Input() user: User;
  @Input() showAsAdmin: boolean;
  actionPlanData: ActionPlanList[] = [];
  currentUser: User;
  questionIndex: number = 0;
  isLoaded: boolean = false;
  backLink: string;
  title: string;
  back: string;
  maxDate: Date;
  questionLabel: number = 0;
  filteredItems: any = [];
  allLength: any;
  userLength: any;
  counselorLength: any;
  popups: any;
  paramsSub: any;
  id: number;
  showAdminActionplan: boolean = false;

  constructor(
    private api: ApiService,
    private storage: StorageService,
    private router: Router,
    private log: LogService,
    private userService: UserService,
    private modalService: ModalService,
    private translate: TranslateService,
    private utilityService: UtilityService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user = this.userService.getUser();
    this.backLink = "/app/practice";
  }

  ngOnInit(): void {
    if(this.user.userType !== "user"){
			this.paramsSub = this.activatedRoute.params.subscribe((params) => {
				this.id = parseInt(params["id"], 10);
			});
      this.showAdminActionplan = this.id ? true : false;
		}
    this.translate.stream("actionPlan").subscribe((res: any) => {
      this.popups = res.popups;
    });
    this.getListContent();
  }

  getListContent() {
    this.isLoaded = false;
    let apiUrl = this.showAsAdmin ? `syncheduser/actionplans/${this.user.id}` : 'myactionplans';
    if (this.user.userType !== 'user' && this.id) {
      apiUrl += `?SynchedUserID=${this.id}`;
  }
    this.api
      .get(apiUrl)
      .subscribe(
        (result: any) => {
          if(this.showAsAdmin){
            this.actionPlanData = ActionPlanList.initializeArray(result.data,this.user.id);
          } else {
            this.actionPlanData = ActionPlanList.initializeArray(result.data,this.user.id);
          }
          this.log.event( AnalyticEvent.event.actionPlanListingView);
          this.filterCreatedBy(0);
          this.isLoaded = true;
        },
        (error: any) => {
          this.isLoaded = true;
          if (error.status != 404) {
            this.log.error("Error getting action plan. " + error.message);
            this.modalService.showAlert(
              this.popups.error,
              this.popups.errorGettingActionPlanData
            );
          }
        }
      );
  }

  filterCreatedBy(tabSelected: number): void {
    if (tabSelected === 1) {
      this. filteredItems = this.actionPlanData.filter(item => !item.isAdminCreated);
      this.userLength = this.filteredItems.length
    } else if (tabSelected === 2) {
      this.filteredItems = this.actionPlanData.filter(item => item.isAdminCreated);
      this.counselorLength = this.filteredItems.length
    } else {
      this.filteredItems = this.actionPlanData;
      this.allLength = this.filteredItems.length
    }
  }

  onTabChanged(event: MatTabChangeEvent): void {
    this.questionLabel = event.index;
    this.filterCreatedBy(this.questionLabel);
  }

  goToActionPlan(plan?: any) {
    let url = "/app/practice/action-plan/create";
    // if (plan) {
    //   url = url + "/" + plan.id;
    // }
    if(this.user.userType !== 'user' && this.id){
      this.router.navigate([url], {queryParams: { userId: this.id } });
    } else {
      this.router.navigate([url]);
    }
  }

  newActionplan() {
      this.router.navigate(["/app/practice/action-plan/create"]);    
  }

  previewActionplan(plan: any) {
    if(this.user.userType !== 'user' && this.id){
      this.router.navigate(["/app/practice/action-plan/preview/"+ plan.id], {queryParams: { userId: this.id } });
    } else {
      this.router.navigate(["/app/practice/action-plan/preview/"+ plan.id]);
    }
  }

  deleteActionPlan(actionPlan){
    this.modalService.showConfirmation(
      this.popups.deleteTitle,
        this.popups.deleteConfirmMsg,
        this.popups.yes,
        this.popups.no
    )
    .afterClosed().subscribe(result => {
      if (result) {
        this.isLoaded = false;
        let url = (this.user.userType != 'user' && !actionPlan.isAdminCreated) ? 'deletecounseloractionplan/' : 'deleteactionplan/';
        this.api
        .delete(url + actionPlan.id)
        .subscribe(
          (result: any) => {
            this.modalService.showAlert( 
              this.popups.success,
              this.popups.deleteSuccessMsg);
              let index = this.actionPlanData.findIndex(
                (plan) => plan["id"] == actionPlan.id
              );
              this.actionPlanData.splice(index, 1);
              this.log.event(
                AnalyticEvent.event.actionPlanDelete
              );
            this.getListContent() 
            this.isLoaded = true;
          },
          (error: any) => {
            this.modalService.showAlert(
              this.popups.error,
              this.popups.somethingWentWrongMsg
            );
            this.log.error(
              "Error deleting." + error.message
            );
            this.isLoaded = true;
          });
      }
    });
  }

  getFilteredItems(tab: string): { count: number; filteredItems: any[] } {
    let filteredItems: any[] = this.actionPlanData;
  
    if (this.user.userType === 'user') {
      // For 'user' userType
      if (tab === "Created by Me") {
        filteredItems = filteredItems.filter(item => !item.isAdminCreated);
      } else if (tab === "Counselor Created") {
        filteredItems = filteredItems.filter(item => item.isAdminCreated);
      }
    } else if (!this.showAdminActionplan) {
      // For non-user type and showAdminActionplan is false
      if (tab === "Created by Me") {
        filteredItems = filteredItems.filter(item => !item.isAdminCreated);
      }
    } else {
      // For non-user type and showAdminActionplan is true
      if (tab === "Counselor Created") {
        filteredItems = filteredItems.filter(item => !item.isAdminCreated);
      } else if (tab === "Created by Me") {
        filteredItems = filteredItems.filter(item => item.isAdminCreated);
      }
    }
  
    return { count: filteredItems.length, filteredItems };
  }
}
