import { Component, OnInit } from '@angular/core';
import { ModalService } from 'app/lib/modal.service';

@Component({
  selector: 'dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss']
})
export class DashboardAdminComponent implements OnInit {

  constructor(private modalService: ModalService) { 
  }

  ngOnInit() {
  }

}
