<div class="form-modal">
    <mat-dialog-content>
  
      <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
          aria-label="Close organization edit form">
          <img src="./assets/img/icons-svg/close-icon.svg">
      </a>
      <div class="img-wrapper">
          <div class="clearfix"></div>
          <div class="title-wrapper">
              <h1>Create Permission</h1>
          </div>
      </div>
      <popup-loader *ngIf="isloaded"></popup-loader>
      <div class="form-wrapper" >
          <form
              novalidate
              [formGroup]="createPermissionForm"
              (ngSubmit)="doSave()"
          >
              <mat-form-field >
                <label for="fullName">Permission Name</label>
                <input matInput class="wt-mat-input" type="text"  id="PermissionName"  formControlName="PermissionName"/>
                <mat-error *ngIf="isSubmitted && createPermissionForm.get('PermissionName').hasError('required')">
                    Permission Name is required
                </mat-error>
                </mat-form-field>
              <mat-form-field>
                  <label for="fullName">Permission Key</label>
                  <input matInput class="wt-mat-input" type="text" formControlName="Permission" id="email" />
                  <mat-error *ngIf="isSubmitted && createPermissionForm.get('Permission').hasError('required')">
                    Permisison key is required
                </mat-error>
              </mat-form-field>
  
  
              <div class="fixed-footer">
                  <button
                      tabindex="0"
                      class="btn-primary btn"
                     
                  >
                      Save
                  </button>
              </div>
          </form>
      </div>
    </mat-dialog-content>
  </div>
  