import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { CalendarService } from 'app/lib/calendar.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Event } from '../../../models/event';
import {
    ASSESSMENT_TYPES_CONFIG,
    TOOLS_CONFIG,
    ADMIN_CONFIG,
} from 'app//lib/constants';
@Component({
    selector: 'page-user-event-assign',
    templateUrl: './user-event-assign.component.html',
    styleUrls: ['./user-event-assign.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserEventAssignComponent implements OnInit {
    @Output() closeModal = new EventEmitter<boolean>();
    user: User;
    @Input() userList: User[];
    @Input() selectedUser = null;
    @Input() disableUserList: boolean = false;
    @Input() eventType: string;
    @Input() disableEventList: boolean = false;
    @Input() cssClass: string = '';
    userAddedEvents: any = [];
    selectedEventType: any;
    selectedEvent: any;
    selectedDate = moment().format();
    minDate = moment().startOf('day').format();
    isLoading: boolean = false;
    eventTypeList = [];
    eventAssignPopups: any;
    constructor(
        private api: ApiService,
        private logService: LogService,
        private userService: UserService,
        private calendarService: CalendarService,
        private modalService: ModalService,
        private translate: TranslateService,
        private storage: StorageService,
        private log: LogService
    ) {
        this.user = this.userService.getUser();
        this.eventTypeList = _.cloneDeep(ADMIN_CONFIG.USER_ASSIGN_TYPES_LIST);
    }

    ngOnInit(): void {
        this.translate.get('eventAssign.popups').subscribe((res: any) => {
            this.eventAssignPopups = res;
        });
        if (this.disableUserList) {
            this.selectedUser['Synched'] = 1;
            this.userList = [this.selectedUser];
        }
        if (this.eventType) {
            if (this.eventType == 'actionplan') {
                this.eventTypeList.push({
                    name: 'actionPlan.actionPlan',
                    type: 'actionplan',
                    multiSelect: false,
                    subOptions: null,
                });
            }
            this.selectedEventType = this.eventTypeList.find(
                event => event.type === this.eventType
            );
        }
        this.selectedEvent = null;
        this.userAddedEvents = [];
        this.setOrgDetails();
    }

    setOrgDetails() {
        if (this.storage.get('orgselect')) {
            let orgID = this.storage.get('orgselect');
            this.getOrgDetails(orgID);
        } else {
            this.getOrgDetails(this.user.primaryOrganization.id);
        }
    }

    getOrgDetails(orgID: any) {
        this.api.get('organizations/' + orgID).subscribe(
            (result: any) => {
                let organization = new Organization(result.data, 'full');
                this.generateAssessmentList(organization.assessmentConfig);
                this.generateToolsList(organization.toolsConfig);
                this.generateCourseList(organization.courses);
            },
            (error: any) => {
                this.log.error('Error getting organizations. ' + error.message);
            },
            () => {}
        );
    }

    generateAssessmentList(assessmentListData: any) {
        let assessmentList = this.eventTypeList.find(
            event => event.type === 'assessment'
        );
        let listData = [];
        assessmentListData.forEach((assessment: any) => {
            listData.push({
                name:
                    ASSESSMENT_TYPES_CONFIG.ASSESSMENT_LIST_LANG[assessment.key]
                        ?.title || assessment.Comment,
                subOptions: null,
                type: ADMIN_CONFIG.USER_ASSIGN_ASSESSMENT_TYPES[assessment.key],
                enabled: assessment.enabled,
            });
        });
        assessmentList.subOptions = [...listData];
    }

    generateToolsList(toolsListData: any) {
        let toolsList = this.eventTypeList.find(
            event => event.type === 'tools'
        );
        let listData = [];
        toolsListData.forEach((tool: any) => {
            listData.push({
                name:
                    TOOLS_CONFIG.TOOLS_LIST_LANG[tool.key]?.title ||
                    tool.Comment,
                subOptions: null,
                type: tool.key,
                enabled: tool.enabled,
            });
        });
        toolsList.subOptions = [...listData];
    }

    generateCourseList(courseListData: any) {
        let courseList = this.eventTypeList.find(
            event => event.type === 'series'
        );
        let listData = [];
        courseListData.forEach((course: any) => {
            listData.push({
                name: course.name,
                type: course.key,
                subOptions: null,
                enabled: course.enabled,
            });
        });
        courseList.subOptions = [...listData];
    }

    cancel() {
        this.closeModal.emit(false);
    }

    onChangeEventType() {
        this.selectedEvent = null;
        this.userAddedEvents = [];
    }

    onSubmit() {
        this.userAddedEvents = [];
        if (this.selectedEventType.type === 'series') {
            this.selectedEvent?.forEach((event: any) => {
                let newEventType = _.cloneDeep(this.selectedEventType);
                newEventType.start = this.selectedDate;
                newEventType.name = this.translate.instant(event.name);
                newEventType.type = event.type;
                newEventType['title'] = this.translate.instant(event.name);
                newEventType['isCourse'] = true;
                let newEvent = new Event(newEventType);
                this.userAddedEvents.push(newEvent);
            });
        } else {
            let newEventType = _.cloneDeep(this.selectedEventType);
            newEventType.start = this.selectedDate;
            newEventType['isCourse'] = false;
            newEventType['title'] =
                this.selectedEventType.type === 'assessment' ||
                this.selectedEventType.type === 'tools'
                    ? this.translate.instant(this.selectedEvent.name)
                    : this.translate.instant(this.selectedEventType.name);
            newEventType.name =
                this.selectedEventType.type === 'assessment' ||
                this.selectedEventType.type === 'tools'
                    ? this.translate.instant(this.selectedEvent.name)
                    : this.translate.instant(this.selectedEventType.name);
            newEventType.type =
                this.selectedEventType.type === 'tools' ||
                this.selectedEventType.type === 'assessment'
                    ? this.selectedEvent.type
                    : this.selectedEventType.type;
            let newEvent = new Event(newEventType);
            this.userAddedEvents.push(newEvent);
        }
        this.showConfirmPopup();
    }

    showConfirmPopup() {
        let userName = this.selectedUser?.fullName || this.selectedUser?.Name;
        let eventItemsString = this.userAddedEvents
            .map(item => item.name)
            .join(' and ');
        let message = this.eventAssignPopups.confirmationMsg
            .replace('$event$', '<strong>' + eventItemsString + '</strong>')
            .replace('$userName$', '<strong>' + userName + '</strong>');
        this.modalService
            .showConfirmation(this.eventAssignPopups.confirmation, message)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.onSaveEvents();
                }
            });
    }

    onSaveEvents() {
        this.isLoading = true;
        let payload = {
            events: this.userAddedEvents,
            CreatorUserID: this.user.id,
            UserID: this.selectedUser.id || this.selectedUser.UserID,
        };
        this.api.post('calendar/schedule', payload).subscribe(
            (data: any) => {
                this.isLoading = false;
                this.userAddedEvents = [];
                this.modalService.showAlert(
                    this.eventAssignPopups.success,
                    this.eventAssignPopups.saveSuccessMsg
                );
                this.calendarService.triggerRefresh();
                this.closeModal.emit(true);
            },
            (error: any) => {
                this.modalService.showAlert(
                    this.eventAssignPopups.error,
                    this.eventAssignPopups.saveErrorMsg
                );
                this.logService.error('Error saving. ' + error.message);
                this.isLoading = false;
            },
            () => {}
        );
    }

    ngOnDestroy() {
        this.eventType = null;
    }
}
