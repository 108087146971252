import { Component, ViewEncapsulation, Inject } from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";

@Component({
	selector: "error-popup",
	templateUrl: "./error-popup.html",
	styleUrls: ["./error-popup.scss"],
})
export class ErrorPopup {
	title: string;
	message: string;
	message2: string;
	message1: string;

	constructor(
		public dialogRef: MatDialogRef<ErrorPopup>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.title = data.title;
		this.message = data.message;
		this.message2 = data.message2;
		this.message1 = data.message1;
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
