<div class="onboarding-main-container" *ngIf ="showImgcard && !showSurveyCard">
    <div class="ondoarding-header">
        <svg-icon icon="onboarding-logo"></svg-icon>
    </div>
    <div class="onboarding-content-container">
        <div class="onboarding-content-first"  [ngClass]="{active:activeslideStep == 0}">
            <div class="onboarding-content-section">
                <div class="onboarding-content-image">
                    <img src="./assets/img/icons-svg/onboarding_dass_assessment.svg" />
                </div>
                <div class="onboarding-content-text">
                    <h2 translate>onboarding.wellnessAssessment</h2>
                    <p translate>onboarding.wellnessAssessmentDesc</p>
                </div>
            </div>
        </div>
        <div class="onboarding-content-second" [ngClass]="{ active: activeslideStep === 1}">
            <div class="onboarding-content-section">
                <div class="onboarding-content-image">
                    <img src="./assets/img/icons-svg/onboarding_courses.svg" />
                </div>
                <div class="onboarding-content-text">
                    <h2 translate>onboarding.courses</h2>
                    <p translate>onboarding.coursesDesc</p>
                </div>
            </div>
        </div>
        <div class="onboarding-content-third" [ngClass]="{ active: activeslideStep === 2 }">
            <div class="onboarding-content-section">
                <div class="onboarding-content-image">
                    <img src="./assets/img/icons-svg/onboarding_moodcheck.svg" />
                </div>
                <div class="onboarding-content-text">
                    <h2 translate>onboarding.moodcheck</h2>
                    <p translate>onboarding.moodcheckDesc</p>
                </div>
            </div>
        </div>
        <div class="slide-buttons">
            <div class="button"  [ngClass]="{active:activeslideStep == 0}"></div>
            <div class="button"  [ngClass]="{active:activeslideStep == 1}"></div>
            <div class="button"  [ngClass]="{active:activeslideStep == 2}"></div>
        </div>
    </div>
    <div class="onboarding-footer">
        <button tabindex="0" class="app-c-btn app-c-btn--secondary app-c-btn--curve"  (click)="skipToLogin()">
            <span translate>onboarding.skip</span>
        </button>
        <button tabindex="0" class="app-c-btn app-c-btn--primary app-c-btn--curve" (click)="nextSlide()">
            <span translate>onboarding.next</span>
            <svg-icon icon="arrow-right">
            </svg-icon>
        </button>
    </div>
</div>

<!-- How your app works section -->
<div class="app-works-main-container" *ngIf ="showSurveyCard && !showImgcard">
    <div class="app-works-header">
        <img src="./assets/img/welltrack-logos/well-track-white-logo.svg" alt="welltrack-logo" />
        <div class="app-works-header-bg">
            <svg-icon icon="app-works-header-bg"></svg-icon>
        </div>
    </div>
    <div class="app-works-content-section">
        <div class="app-works-content-container">
            <h2 class="app-c-heading--h2" translate>onboarding.stepper.appWork</h2>
            <div class="steps-container">
                <div class="steps-section completed">
                    <div class="steps-logo current-logo">
                        <svg-icon icon="lock-circle"></svg-icon>
                    </div>
                    <div class="steps-logo completed-logo">
                        <svg-icon icon="tick-circle-new"></svg-icon>
                    </div>
                    <div class="steps-logo active-logo">
                        <svg-icon icon="unlock-circle"></svg-icon>
                    </div>
                    <div class="steps-content">
                        <h2 class="app-c-body-text--16-m" translate>onboarding.stepper.createAccount</h2>
                        <p class="app-c-body-text--14" translate>onboarding.stepper.successAccount</p>
                    </div>
                </div>
                <div class="steps-section" *ngIf="showDemographic" [ngClass]="onBoardingStep == 0 ? 'active':onBoardingStep > 0 ? 'completed':''">
                    <div class="steps-logo current-logo">
                        <svg-icon icon="lock-circle"></svg-icon>
                    </div>
                    <div class="steps-logo completed-logo">
                        <svg-icon icon="tick-circle-new"></svg-icon>
                    </div>
                    <div class="steps-logo active-logo">
                        <svg-icon icon="unlock-circle"></svg-icon>
                    </div>
                    <div class="steps-content">
                        <h2 class="app-c-body-text--16-m" translate>onboarding.stepper.demoSurvey</h2>
                        <p class="app-c-body-text--14"  translate>onboarding.stepper.demoDesc</p>
                    </div>
                </div>
                <div class="steps-section"  *ngIf="showConsent" [ngClass]="onBoardingStep == 1 ? 'active':onBoardingStep > 1 ? 'completed':''">
                    <div class="steps-logo current-logo">
                        <svg-icon icon="lock-circle"></svg-icon>
                    </div>
                    <div class="steps-logo completed-logo">
                        <svg-icon icon="tick-circle-new"></svg-icon>
                    </div>
                    <div class="steps-logo active-logo">
                        <svg-icon icon="unlock-circle"></svg-icon>
                    </div>
                    <div class="steps-content">
                        <h2 class="app-c-body-text--16-m"  translate>onboarding.stepper.sharePrefer</h2>
                        <p class="app-c-body-text--14"  translate>onboarding.stepper.shareDesc</p>
                    </div>
                </div>
                <div class="steps-section" *ngIf="isShowAssessment" [ngClass]="onBoardingStep == 2 ? 'active':onBoardingStep > 2 ? 'completed':''">
                    <div class="steps-logo current-logo">
                        <svg-icon icon="lock-circle"></svg-icon>
                    </div>
                    <div class="steps-logo completed-logo">
                        <svg-icon icon="tick-circle-new"></svg-icon>
                    </div>
                    <div class="steps-logo active-logo">
                        <svg-icon icon="unlock-circle"></svg-icon>
                    </div>
                    <div class="steps-content">
                        <h2 class="app-c-body-text--16-m"  translate>onboarding.stepper.wellnessAssesment</h2>
                        <p class="app-c-body-text--14" translate>onboarding.stepper.wellnessAssesmentDesc</p>
                    </div>
                </div>
            </div>
            <div class="apps-content-footer">
                <button class="app-c-btn app-c-btn--primary app-c-btn--curve"  (click)="onBoardingNextStep()">
                    <span translate>onboarding.stepper.continue</span>
                    <svg-icon icon="arrow-right"></svg-icon>
                </button>
            </div>
        </div>
        <p class="terms-text app-c-body-text--12-m">
            <a href="/terms-and-conditions" target="_blank" translate>signUp.tos.tos</a>
            <span>|</span>
            <a  href="/privacy-policy" target="_blank" translate>signUp.tos.privacy</a>
        </p>
    </div>
</div>