import { Component, OnInit } from '@angular/core';
import { User } from '../../../app/models/user';
import { Assessment } from '../../../app/models/assessment';
import { Organization } from '../../../app/models/organization';
import { UserService } from '../../../app/lib/user.service';
import { ApiService } from '../../../app/lib/api.service';
import { LogService } from '../../../app/lib/log.service';
import { ModalService } from '../../../app/lib/modal.service';
import { ResourcesComponent } from '../../components/resources/resources.component';
import { ResourcesEditModal } from '../../components/resources-edit-modal/resources-edit-modal.component';
import { AnalyticEvent } from 'app/lib/analytic-event';
import {
    Router,
    ActivatedRoute,
    NavigationEnd,
    NavigationExtras,
} from '@angular/router';
import { StorageService } from 'app/lib/storage.service';
import { MenuService } from 'app/lib/menu.service';
declare var window;

@Component({
    selector: 'page-resources',
    templateUrl: 'resources.html',
    styleUrls: ['./resources.scss'],
})
export class ResourcesPage implements OnInit {
    //images: Array<string> = ['education', 'financial', 'stop-violence', 'stormhead', 'health', 'safety', 'emergency' ];
    // images: Array<string> = ['academic', 'financial', 'sexual', 'substance', 'health', 'security', 'emergency' ];

    //Academic, Financial, Sexual Violence, Substance Use, Health & Wellness, Safety & Security

    user: User;
    resources: string;
    organization: Organization;
    assessment: Assessment;
    isLoaded: boolean = false;
    loadedCount: number = 0;
    loaded: boolean;
    ptsd: boolean = false;
    highestLevel: number;
    stepMapping: any = [[]];
    currentLevelMap: any;
    assessmentsExists: boolean = false;
    lang: any;

    constructor(
        public storage: StorageService,
        private userService: UserService,
        private log: LogService,
        private api: ApiService,
        private modalService: ModalService,
        private router: Router,
        private _router: ActivatedRoute,
        private menuservice: MenuService
    ) {
        this.loaded = false;
        this.user = this.userService.getUser();
    }

    ngOnInit() {
        this.api
            .get('organizations/' + this.user.primaryOrganization.id)
            .subscribe(
                (result: any) => {
                    if (!result.data.resourceSet) {
                        result.data.resourceSet = [];
                    }
                    this.organization = new Organization(result.data, 'view');
                    this.loadedCount++;
                    this.api
                        .get('assessments/lastassessment', {
                            Type: 'resilience',
                        })
                        .subscribe(
                            (result: any) => {
                                this.assessment = new Assessment(result.data);
                                this.loadedCount++;
                                this.log.event(
                                    AnalyticEvent.event.assessmentLoaded
                                );
                                this.loaded = true;
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error getting assessment. ' + error.message
                                );
                                this.assessmentsExists = false;
                                this.loadedCount++;
                                this.loaded = true;
                            },
                            () => {}
                        );
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organization. ' + error.message
                    );
                    this.loadedCount++;
                    this.loaded = true;
                },
                () => {}
            );
    }

    onGoTo(i) {
        // this.modalService.showComponent(ResourcesComponent, resourceSet);
        const navigationExtras: NavigationExtras = {
            state: {
                resourceSet: this.organization.resourceSet[i],
                resources: this.filterResourcesByLanguage(),
            },
        };
        this.router.navigate(['/app/resources-detail'], navigationExtras);
    }

    filterResourcesByLanguage() {
        let selectedLang = this.storage.get('lang');
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang)
        ) {
            selectedLang = 'en';
        }
        return this.organization.resourceSet.filter(
            rs => rs.language === selectedLang
        );
    }

    onEdit() {
        this.modalService.setCloseOnClickAway(false);
        const modal = this.modalService.showComponent(
            ResourcesEditModal,
            this.organization.id
        );
    }

    empty() {
        return (
            !this.organization.resourceSet ||
            (this.organization.resourceSet &&
                this.organization.resourceSet.length === 0)
        );
    }

    hasResourceByLangage(lang: string): boolean {
        return this.organization.resourceSet.some(r => r['language'] === lang);
    }

    showResourceByLanguage(resourceSet: any): boolean {
        const resourceLang = resourceSet.language;
        const selectedLang = this.storage.get('lang');

        // If the resource language matches the selected language, return true
        if (resourceLang === selectedLang) {
            return true;
        }

        // If the selected language is French or Spanish, and there's no resource for the selected language,
        // show the resource in English if available
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang) &&
            resourceLang === 'en'
        ) {
            return true;
        }

        // In all other cases, do not show the resource
        return false;
    }

	get menuService(): MenuService {
		return this.menuservice;
	}
}
