import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { User } from 'app/models/user';
import { ProfessionalService } from '../professional.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActionPlanDashboard } from 'app/models/action-plan';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-user-details-dashboard',
    templateUrl: './user-details-dashboard.component.html',
    styleUrls: ['./user-details-dashboard.component.scss'],
})
export class UserDetailsDashboardComponent implements OnInit {
    [x: string]: any;
    paramsSub: any;
    id: number;
    user: User;
    countData: any;
    demographicDetail: any;
    isLoaded: boolean = false;
    selectedTabIndex: number = 0;
    userDetailOverview: boolean = false;
    assessmentView: boolean = false;
    moodcheckView: boolean = false;
    actionplanView: boolean = false;
    seriesView: boolean = false;
    toolsView: boolean = false;
    date = new Date();
    userActionPlans = [];

    constructor(
        private api: ApiService,
        private log: LogService,
        private activatedRoute: ActivatedRoute,
        private professionalService: ProfessionalService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        let currentDate = new Date();
        let rangeDate = new Date(
            new Date().setDate(currentDate.getDate() - 30)
        );
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            setTimeout(() => {
                this.professionalService
                    .getCounts(this.id, '1-3-13-21', rangeDate, currentDate)
                    .subscribe(result => {
                        this.countData = result;
                    });
                this.getUserActionPlans();
                this.getDemographic();
                this.initData();
            }, 100);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getUserActionPlans();
        });
        this.loadDataForTab(0);
    }

    onTabChanged(event: MatTabChangeEvent) {
        let tabSelected = event.tab.textLabel;
        this.selectedTabIndex = event.index;
        this.loadDataForTab(this.selectedTabIndex);
    }

    loadDataForTab(index: number) {
        switch (index) {
            case 0:
                this.userDetailOverview = true;
                break;
            case 1:
                this.assessmentView = true;
                break;
            case 2:
                this.moodcheckView = true;
                break;
            case 3:
                this.seriesView = true;
                break;
            case 4:
                this.actionplanView = true;
                break;
            case 5:
                this.toolsView = true;
                break;
        }
    }

    initData() {
        this.api.get('users/' + this.id, {}).subscribe(
            (result: any) => {
                this.user = new User(result.data);
                this.log.event(AnalyticEvent.event.userDetailsLoaded);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error fetching user. ' + error.message);
            }
        );
    }

    getDemographic() {
        this.api.get('demographic/' + this.id).subscribe(
            (result: any) => {
                this.demographicDetail = result.data[0];
                this.isLoaded = true;
            },
            (error: any) => {
                this.isLoaded = false;
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
            }
        );
    }

    getUserActionPlans() {
        this.api.get(`syncheduser/actionplans/${this.id}`).subscribe(
            (result: any) => {
                if (result.data) {
                    this.userActionPlans = ActionPlanDashboard.initializeArray(
                        result.data,
                        this.id
                    );
                }
            },
            error => {
                this.userActionPlans = [];
                this.log.error('Error getting action plan. ' + error.message);
            }
        );
    }
}
