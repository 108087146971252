<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap home-dashboard-main-container" *ngIf="isLoaded">
    <div class="app-l-main-heading">
        <h2 translate>clientList.series.title</h2>
        <div class="app-l__actions-block">
            <button
                (click)="showAssignModal('true')"
                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                translate>
                <img
                    alt="Assign"
                    src="./assets/img/icons-svg/assign-user-icon.svg" />clientList.assign
            </button>
            <div class="app-c-mat-select">
                <img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange()">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="title-section" *ngIf="isLoaded">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            [eventType]="'series'"
            [selectedUser]="userDetail"
            [disableUserList]="true"
            [disableEventList]="true"
            class="app-l-modecheck__sidepanel"
            [userList]="userDetail"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
    </div>
    <div class="app-l-dashboard__wrap">
        <div class="app-l-dashboard__wrap--left-area">
            <div class="app-l-dashboard__block">
                <div class="app-l-summary__base">
                    <div class="app-l-result-summary">
                        <div class="app-l-result__card-dm">
                            <div class="app-c-card__header">
                                <div class="title-section app-l-card__h-left">
                                    <h2 class="initial-image">
                                        {{ getInitials(userDetail?.fullName) }}
                                    </h2>
                                    <h4>
                                        {{ userDetail?.fullName }}<br />
                                        <p>{{ userDetail?.email }}</p>
                                    </h4>
                                </div>
                            </div>
                            <div class="demo-result-card">
                                <div class="demo-val">
                                    <p translate>clientList.age</p>
                                    <h4>
                                        {{
                                            demographicData?.Age
                                                ? demographicData?.Age
                                                : '-'
                                        }}
                                    </h4>
                                </div>
                                <div class="demo-val">
                                    <p translate>clientList.ethinicity</p>
                                    <h4>
                                        {{
                                            demographicData?.Ethnicity
                                                ? demographicData?.Ethnicity
                                                : '-'
                                        }}
                                    </h4>
                                </div>
                                <div class="demo-val">
                                    <p translate>clientList.gender</p>
                                    <h4>
                                        {{
                                            demographicData?.Gender
                                                ? demographicData?.Gender
                                                : '-'
                                        }}
                                    </h4>
                                </div>
                                <div class="demo-val">
                                    <p translate>clientList.gradLevel</p>
                                    <h4>
                                        {{
                                            demographicData?.GradeLevel
                                                ? demographicData?.GradeLevel
                                                : '-'
                                        }}
                                    </h4>
                                </div>
                                <div class="demo-val">
                                    <p translate>clientList.referredBy</p>
                                    <h4>
                                        {{
                                            demographicData?.Referred
                                                ? demographicData?.Referred
                                                : '-'
                                        }}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="app-c-card app-l-chart__card">
				<div class="app-c-card__body">
					<div
						class="app-l-moods__overall"
					>
					<h3 class="app-c-heading--h3">Mood of the day</h3>
						<app-overall-view-moodcheck
							[hideFilter]="true"
							#moodchecOverallViewComp
						></app-overall-view-moodcheck>
					</div>
				</div>
			</div> -->
            </div>
            <div class="app-l-dashboard__block app-l-dsh__sa">
                <div class="app-c-card">
                    <div class="app-l-summary__base">
                        <div class="app-l-result-summary">
                            <div class="app-l-result__card-dm">
                                <div class="app-l-result__card-header">
                                    <h3 class="app-c-heading--h3" translate>
                                        clientList.snapshot
                                    </h3>
                                </div>
                                <div class="app-l-result__card-body">
                                    <div class="app-l-completed-assessment">
                                        <p translate>
                                            clientList.series.watchedVideosNumber
                                        </p>
                                        <h2>
                                            {{ completedCount }}
                                            <span translate
                                                >clientList.series.videos</span
                                            >
                                        </h2>
                                    </div>
                                    <div class="app-l-latest-videos">
                                        <p translate>
                                            clientList.series.latestVideos
                                        </p>
                                        <div
                                            class="app-l-latest-videos-list"
                                            *ngIf="latestVideos.length > 0">
                                            <div
                                                class="additional-val"
                                                *ngFor="
                                                    let video of latestVideos
                                                ">
                                                <div class="app-l-img-holder">
                                                    <img
                                                        [defaultImage]="
                                                            './assets/img/course_image_default.svg'
                                                        "
                                                        [lazyLoad]="
                                                            video[0].ImagePath
                                                        " />
                                                </div>
                                                <div class="app-l-details">
                                                    <div
                                                        class="badge completed"
                                                        *ngIf="
                                                            video[0].Status ===
                                                            'completed'
                                                        "
                                                        translate>
                                                        <svg-icon
                                                            icon="completed"></svg-icon>
                                                        clientList.series.completed
                                                    </div>
                                                    <div
                                                        class="badge in-progress"
                                                        *ngIf="
                                                            video[0].Status ===
                                                            'inprogress'
                                                        "
                                                        translate>
                                                        <svg-icon
                                                            icon="in-progress"></svg-icon>
                                                        clientList.series.inProgress
                                                    </div>
                                                    <div
                                                        class="badge not-started"
                                                        *ngIf="
                                                            video[0].Status ===
                                                            'notStarted'
                                                        "
                                                        translate>
                                                        <svg-icon
                                                            icon="not-started"></svg-icon>
                                                        clientList.series.notStarted
                                                    </div>
                                                    <p>
                                                        {{ video[0].Label }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-latest-videos-list"
                                            *ngIf="latestVideos.length == 0">
                                            <p class="center" translate>
                                                clientList.series.noLatestVideos
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-l-result__card app-l-assessment-assignments">
                <div class="app-l-result__card-header">
                    <div class="app-l-result__card-header-left">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.series.videoSeries
                        </h3>
                    </div>
                    <div
                        class="app-l-result__card-header-right"
                        *ngIf="assignedListLoaded">
                        <!-- <a class="action-view" routerLink="" translate
                            >View All</a
                        > -->
                        <button
                            class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                            (click)="showAssignModal('true')"
                            translate>
                            <img
                                alt="Assign"
                                src="./assets/img/icons-svg/assign-user-icon.svg" />
                            <span translate>clientList.assign</span>
                        </button>
                    </div>
                </div>

                <div class="app-l-result__card-body">
                    <div class="app-l-result-item__base">
                        <div class="app-l-result-item__card-block">
                            <ng-container
                                *ngIf="!assignedListLoaded"
                                [ngTemplateOutlet]="skeletonLoader"
                                [ngTemplateOutletContext]="{ height: '100px' }">
                            </ng-container>
                            <mat-tab-group
                                *ngIf="assignedListLoaded"
                                #tabGroup
                                disableRipple="true"
                                (selectedTabChange)="onTabChanged($event)">
                                <!-- Tab 1: Basic Details -->
                                <mat-tab label="Assigned">
                                    <!-- <div class="app-c-card"> -->
                                    <div
                                        class="app-c-card__body assignment-list">
                                        <div
                                            class="app-l-dsh__rp-wrap"
                                            *ngIf="
                                                assignmentList?.assigned
                                                    .length > 0
                                            ">
                                            <div
                                                tabindex="0"
                                                class="app-l-dsh__rp-card"
                                                *ngFor="
                                                    let assignment of assignmentList?.assigned
                                                ">
                                                <div
                                                    class="app-l-latest-videos-list">
                                                    <div class="additional-val">
                                                        <div
                                                            class="app-l-img-holder">
                                                            <img
                                                                [defaultImage]="
                                                                    './assets/img/course_image_default.svg'
                                                                "
                                                                [lazyLoad]="" />
                                                        </div>
                                                        <div
                                                            class="app-l-details">
                                                            <div
                                                                class="app-l-badge-wrapper">
                                                                <!-- <div
                                                                    class="badge completed"
                                                                    *ngIf="
                                                                        assignment.status
                                                                    ">
                                                                    <svg-icon
                                                                        icon="completed"></svg-icon>
                                                                    Completed
                                                                </div> -->
                                                                <div
                                                                    class="badge in-progress"
                                                                    *ngIf="
                                                                        assignment.status
                                                                    "
                                                                    translate>
                                                                    <svg-icon
                                                                        icon="in-progress"></svg-icon>
                                                                    clientList.series.inProgress
                                                                </div>
                                                                <div
                                                                    class="badge not-started"
                                                                    *ngIf="
                                                                        !assignment.status
                                                                    "
                                                                    translate>
                                                                    <svg-icon
                                                                        icon="not-started"></svg-icon>
                                                                    clientList.series.notStarted
                                                                </div>
                                                                <span>
                                                                    <div
                                                                        class="icon">
                                                                        <svg-icon
                                                                            icon="time"></svg-icon>
                                                                    </div>
                                                                    <span>
                                                                        {{
                                                                            assignment.Date
                                                                                | date
                                                                                    : 'MMM dd, yyyy'
                                                                        }}
                                                                    </span>
                                                                </span>
                                                                <div>
                                                                    <button
                                                                        tabindex="0"
                                                                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                                                                        (click)="deleteAssign(assignment.ID)"
                                                                        (keypress)="deleteAssign(assignment.ID)">
                                                                        <svg-icon
                                                                            icon="trash"
                                                                            class="w-100 h-100"
                                                                            alt="trash"></svg-icon>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <p>
                                                                {{
                                                                    assignment.Name
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-dsh__rp-wrap center"
                                            *ngIf="
                                                assignmentList?.assigned
                                                    .length == 0
                                            "
                                            translate>
                                            clientList.series.noPendingAssignment
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </mat-tab>

                                <mat-tab label="Completed">
                                    <!-- <div class="app-c-card"> -->
                                    <div class="app-c-card__body">
                                        <div
                                            class="app-l-dsh__rp-wrap"
                                            *ngIf="
                                                assignmentList?.completed
                                                    .length > 0
                                            ">
                                            <div
                                                tabindex="0"
                                                class="app-l-dsh__rp-card"
                                                *ngFor="
                                                    let assignment of assignmentList?.completed
                                                ">
                                                <div
                                                    class="app-l-latest-videos-list">
                                                    <div class="additional-val">
                                                        <div
                                                            class="app-l-img-holder">
                                                            <img
                                                                src="./assets/img/videoImg.png" />
                                                        </div>
                                                        <div
                                                            class="app-l-details">
                                                            <div
                                                                class="app-l-badge-wrapper">
                                                                <div
                                                                    class="badge completed"
                                                                    translate>
                                                                    <svg-icon
                                                                        icon="completed"></svg-icon>
                                                                    clientList.series.completed
                                                                </div>
                                                                <span>
                                                                    <div
                                                                        class="icon">
                                                                        <svg-icon
                                                                            icon="time"></svg-icon>
                                                                    </div>
                                                                    <span>
                                                                        {{
                                                                            assignment.UpdatedOnUtc
                                                                                | date
                                                                                    : 'dd MMMM YYYY'
                                                                        }}
                                                                    </span>
                                                                </span>
                                                            </div>

                                                            <p>
                                                                {{
                                                                    assignment.Name
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-dsh__rp-wrap center"
                                            *ngIf="
                                                assignmentList?.completed
                                                    .length == 0
                                            "
                                            translate>
                                            clientList.series.noCompletedAssignment
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
                <!-- <div
					class="app-l-no-mood-check"
					*ngIf="rfpEntryList.length === 0"
				>
					<div class="no-data-image">
						<svg-icon icon="room-for-positive" alt="room-for-positive"></svg-icon>
						<span
							class="app-l-no-mood__round app-l-no-mood__round1"
						></span>
						<span
							class="app-l-no-mood__round app-l-no-mood__round2"
						></span>
						<span
							class="app-l-no-mood__round app-l-no-mood__round3"
						></span>
					</div>
					<h5 class="app-c-body-text--16-m" translate>
						practice.rfp.title
					</h5>
					<p class="app-c-body-text--14" translate>
						practice.rfp.dashContent
					</p>
					<a role="tab" tabindex="0"
						class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
						(click)="createNewEntry()" (keypress)="createNewEntry()"
					>
						<svg-icon icon="plus" alt="plus"></svg-icon>
						<span translate>practice.rfp.addEntry</span>
					</a>
				</div> -->
            </div>
        </div>

        <div class="app-l-dashboard__wrap--right-area">
            <div class="app-l-dashboard__block app-l-video-series-warper">
                <div class="app-c-card__header">
                    <h3 class="app-l-card__title" translate>
                        clientList.series.allVideoSeries
                    </h3>
                    <div class="app-l-card__h-right">
                        <!-- <a
                            class="action-view"
                            (click)="viewAllSeries()"
                            translate
                            >menu.viewAll</a
                        > -->
                    </div>
                </div>
                <ng-container
                    *ngIf="!allSeriesLoaded"
                    [ngTemplateOutlet]="skeletonLoader"
                    [ngTemplateOutletContext]="{
                        height: '100px'
                    }">
                </ng-container>
                <div class="app-c-card__body" *ngIf="allSeriesLoaded">
                    <div class="app-l-dashboard-course">
                        <div class="app-l-dc__body">
                            <div class="app-l-dc__course-row series-list">
                                <div
                                    tabindex="0"
                                    class="app-l-dc__c-block"
                                    *ngFor="let course of recommendedcourses">
                                    <div
                                        class="app-l-dc__c-over"
                                        *ngIf="course[0].Enabled">
                                        <div
                                            class="app-l-completed-date inprogress"
                                            *ngIf="
                                                course[0].courseprogress ===
                                                'inprogress'
                                            ">
                                            <div class="icon">
                                                <svg-icon
                                                    icon="progress"></svg-icon>
                                            </div>
                                            <span translate
                                                >clientList.series.inProgress</span
                                            >
                                        </div>
                                        <div
                                            class="app-l-completed-date completed"
                                            *ngIf="
                                                course[0].completedcount ==
                                                    course[0].videoCount &&
                                                course[0].completed
                                            ">
                                            <div class="icon">
                                                <svg-icon
                                                    icon="tickIcon"></svg-icon>
                                            </div>
                                            <span translate
                                                >clientList.series.completedOn
                                                {{
                                                    course[0].completedon
                                                        | amUtc
                                                        | amDateFormat
                                                            : 'DD MMM YYYY'
                                                }}</span
                                            >
                                        </div>
                                        <div class="app-l-dc__c-image">
                                            <img
                                                [defaultImage]="
                                                    '../../../../assets/img/dashboard/no-image.svg'
                                                "
                                                [lazyLoad]="
                                                    course[0].CourseImage
                                                "
                                                alt="view-more-button" />
                                            <!-- <button
                                                class="app-l-dc__play"
                                                (click)="
                                                    gotoSeries(course[0].Key)
                                                ">
                                                <svg-icon
                                                    icon="play"
                                                    alt="play"
                                                    class="w-100 h-100"></svg-icon>
                                            </button> -->
                                        </div>

                                        <div class="app-l-dc__c-content">
                                            <h2 class="app-c-body-text--16-m">
                                                {{ course[0].Name }}
                                            </h2>
                                            <!-- <div class="app-l-dc__c-info-box">
                                                <div class="app-l-dc__c-info">
                                                    <span
                                                        class="app-l-dc__cat"
                                                        translate
                                                        >{{
                                                            'dashboard.' +
                                                                course.cardType
                                                        }}</span
                                                    >
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="app-l-footer">
                                            <div class="app-l-footer_completed">
                                                <span translate
                                                    >clientList.series.completed</span
                                                >
                                                <div class="badge">
                                                    {{
                                                        course[0].completedcount
                                                    }}
                                                </div>
                                            </div>
                                            <div class="app-l-footer_pending">
                                                <span translate
                                                    >clientList.series.pending</span
                                                >
                                                <div class="badge">
                                                    {{
                                                        course[0].videoCount -
                                                            course[0]
                                                                .completedcount
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div
                                    class="app-l-dc__c-block"
                                    *ngIf="recommendedcourses.length < 3">
                                    <div class="app-l-no-mood-check">
                                        <div class="no-data-image">
                                            <svg-icon
                                                icon="video-play"
                                                alt="video-play"></svg-icon>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round1"></span>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round2"></span>
                                            <span
                                                class="app-l-no-mood__round app-l-no-mood__round3"></span>
                                        </div>
                                        <h5
                                            class="app-c-body-text--16-m"
                                            translate>
                                            dashboard.checkcourse
                                        </h5>
                                        <a
                                            routerLink="/app/courses"
                                            class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                            <span translate
                                                >dashboard.courses</span
                                            >
                                            <svg-icon
                                                icon="angle-right"
                                                alt="angle-right"></svg-icon>
                                        </a>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-dashboard__block app-l-exercise-warper">
                <div class="app-c-card__header">
                    <h3 translate>clientList.series.worksheetClicks</h3>
                </div>
                <ng-container
                    *ngIf="!worksheetClicksLoaded"
                    [ngTemplateOutlet]="skeletonLoader"
                    [ngTemplateOutletContext]="{
                        height: '100px'
                    }">
                </ng-container>
                <div class="app-c-card__body" *ngIf="worksheetClicksLoaded">
                    <div
                        class="app-l-dsh__sa-wrap"
                        *ngIf="workSheetData.length > 0">
                        <div
                            class="app-l-dsh__sa-card"
                            *ngFor="let workSheet of workSheetData">
                            <h5>{{ workSheet.Title }}</h5>
                            <h3>
                                {{ workSheet.clickcount }}
                                <span translate>clientList.series.clicks</span>
                            </h3>
                            <div class="app-l-sa__time">
                                Last {{ dateRangeSelected }} days
                            </div>
                        </div>
                    </div>
                    <div
                        class="center"
                        style="margin-bottom: 20px"
                        *ngIf="workSheetData.length == 0"
                        translate>
                        clientList.series.noWorksheetData
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template let-height="height" #skeletonLoader>
    <mat-card class="skeleton-card" [ngStyle]="{ height: height }">
        <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card>
</ng-template>
