import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { Moodcheck } from '../../../models/moodcheck';
import { Activity } from '../../../models/activity';
import { UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../../../environments/all';
import { ModalService } from '../../../lib/modal.service';
import { Assessment } from '../../../models/assessment';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { ActionPlanDashboard } from 'app/models/action-plan';
import * as Chart from 'chart.js';
import { ChartData } from 'chart.js';
import { Organization } from 'app/models/organization';
import { UtilityService } from 'app/lib/utility.service';
import { ProfessionalService } from '../professional.service';
import { AssignEventList } from 'app/models/event';

@Component({
    selector: 'app-user-details-series',
    templateUrl: './user-details-series.component.html',
    styleUrls: ['./user-details-series.component.scss'],
})
export class UserDetailsSeriesComponent implements OnInit {
    user: User;
    userViewed: User;
    fullName: string;
    loginOn: string = '';
    id: number;
    paramsSub: any;
    activity: Array<Object> = [];
    moodcheckimage: string = '';
    avgmoodcheck: number = 0;
    error: any;
    showMore: boolean = false;
    appointment: boolean = false;
    alerts: number = 0;
    avg: number = 0;
    emotion: string = '';
    isAdmin = false;
    userType = '';
    organization: Organization;
    popup: any;
    assessments: Assessment[];
    showAssessment: boolean = false;
    isOrgadmin: boolean = false;
    showAssigned: boolean = true;
    showCompleted: boolean = false;
    @ViewChild('dasAssessmentChart') canvasRef;

    demographicDetail: any;
    dasAssessmentChart: Chart;
    dateRangeSelected: any = 30;
    currentDate: Date;
    rangeDate: Date;

    recommendedcourses = [];
    userActionPlans = [];
    chartData: ChartData;
    chartOptions;
    isLoaded: boolean = false;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    nameInitial: any;
    assignmentList: any;
    showEventAssignModal: boolean = false;
    workSheetData: Array<Object> = [];
    completedCount: number;
    latestVideos: Array<Object> = [];
    assignedListLoaded: boolean = false;
    allSeriesLoaded: boolean = false;
    worksheetClicksLoaded: boolean = false;
    constructor(
        private api: ApiService,
        private storage: StorageService,
        private router: Router,
        private log: LogService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private modalService: ModalService,
        private utilityService: UtilityService,
        private professionalService: ProfessionalService
    ) {
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
    }

    ngOnInit() {
        this.translate.stream('error').subscribe((res: any) => {
            this.error = res.title;
        });

        this.translate.stream('userDetails.popup').subscribe((res: any) => {
            this.popup = res;
        });

        if (this.router.url.slice(1, 6) === 'admin') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }

        if (this.userService.getUser().userType === 'orgadmin') {
            this.isOrgadmin = true;
        } else {
            this.isOrgadmin = false;
        }

        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.initData();
            this.getDemographic();

            this.getSnapshotData();
            this.getAssignment();
            this.getWorkSheet();
        });
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }
    
    getSeries() {
        this.api
            .get(`syncheduser/series/${this.organization.id}/${this.id}`)
            .subscribe(
                (result: any) => {
                    let courseList = result.data;
                    this.allSeriesLoaded = true;
                    this.recommendedcourses = courseList.filter(
                        series => series[0].Enabled
                    );
                    let count = 0;
                    this.recommendedcourses.forEach(enabled => {
                        if (
                            enabled[0].completedcount > 0 ||
                            enabled[0].inprogresscount > 0
                        ) {
                            count =
                                count +
                                enabled[0].completedcount +
                                enabled[0].inprogresscount;
                        }
                    });

                    this.completedCount = count;
                },
                error => {}
            );
    }
    getSnapshotData() {
        this.isLoaded = false;
        this.api
            .get('syncheduser/series/latestvideos/' + this.id, {
                limit: 4,
            })
            .subscribe((result: any) => {
                this.isLoaded = true;
                this.latestVideos = result.data;
            });
    }
    onTabChanged($event) {
        let tabSelected = $event.tab.textLabel;
        if (tabSelected == 'Assigned') {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    initData() {
        this.api.get('users/' + this.id, {}).subscribe(
            (result: any) => {
                this.user = new User(result.data);
                this.fullName = this.user.fullName;
                this.nameInitial = this.fullName.slice(0, 2);
                const utcDate = moment.utc(this.user.loginOn);
                this.loginOn = utcDate.local().format('YYYY-MM-DD, hh:mm:ss a');
                this.alerts = this.user.alertsCount;
                this.userType = this.user.userType;
                if (this.user.primaryOrganization) {
                    this.organization = this.user.primaryOrganization;
                    this.getSeries();
                }

                if (this.user.avgMoodcheck) {
                    this.avg = this.user.avgMoodcheck;
                    this.moodcheckimage =
                        './assets/img/moodcheck/' + (10 - this.avg) + '@2x.png';
                    this.emotion = this.user['avgMoodcheckText'];
                }
                this.log.event(AnalyticEvent.event.userDetailsLoaded);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error fetching user. ' + error.message);
            }
        );
        this.translate.stream('lang').subscribe((res: any) => {
            this.getActivity();
        });
    }

    getActivity() {
        this.api
            .post('users/activity', {
                UserID: this.id,
                Limit: 10,
            })
            .subscribe(
                (result: any) => {
                    this.activity = Activity.initializeArray(result.data);
                },
                (error: any) => {
                    this.log.error('Error fetching activity. ' + error.message);
                }
            );
    }

    onCancelShare(userId) {
        let confirmResult;
        this.modalService
            .showConfirmation('Cancel', this.popup.cancelSharing)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('counselors/' + userId).subscribe(
                        (result: any) => {
                            this.modalService.showAlert(
                                this.popup.successtitle,
                                this.popup.success
                            );
                            this.router.navigate(['professional/users']);
                        },
                        (error: any) => {
                            this.modalService.showAlert(
                                this.popup.errortitle,
                                this.popup.error
                            );
                        }
                    );
                }
            });
    }
    getDemographic() {
        this.api.get(`demographic/${this.id}`).subscribe(
            (result: any) => {
                this.demographicDetail = result.data[0];
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }
    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.getWorkSheet();
    }

    getAssignment() {
        this.assignedListLoaded = false;
        this.api
            .get('syncheduser/assignments/' + this.id, {
                type: 'series',
                Limit: 10,
            })
            .subscribe(
                (result: any) => {
                    let tempList = {
                        assigned: [],
                        completed: [],
                    };
                    tempList.assigned = AssignEventList.initializeArray(
                        result.assigned
                    );
                    tempList.completed = AssignEventList.initializeArray(
                        result.completed
                    );
                    this.assignmentList = tempList;
                    this.setAssignList(tempList);
                    this.log.debug('assignment fetch');
                    this.assignedListLoaded = true;
                },
                (error: any) => {
                    this.log.error('Error fetching activity. ' + error.message);
                    this.assignedListLoaded = true;
                }
            );
    }

    deleteAssign(aId){
        this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete this assignment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
                    this.api
                    .delete(
                        `syncheduser/assignment/delete/` + aId +
                            `?SynchedUserID=` + this.id
                    )
                    .subscribe(
                        (result: any) => {
                            this.getAssignment();
                            this.isLoaded = true;
                            this.assignedListLoaded = true;
                            this.modalService.showAlert(
                                "Success",
                                'Assignment deleted successfully'
                            );
                        },
                        (error: any) => {
                            this.log.error(
                                'Error getting delete.' + error.message
                            );
                            this.isLoaded = true;
                        }
                    );
                }
			});
        }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignment();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }
    showAssignModal(val) {
        this.showEventAssignModal = val;
    }
    getWorkSheet() {
        this.professionalService
            .getWorkSheet(this.id, this.rangeDate, this.currentDate)
            .subscribe((result: any) => {
                this.worksheetClicksLoaded = true;
                result.data.forEach(data => {
                    this.workSheetData.push(data[0]);
                });
            });
    }
    gotoSeries(key) {
        this.router.navigateByUrl('app/series-details/' + key);
    }
    viewAllSeries() {
        this.router.navigate(['/app/series']);
    }
    setAssignList(assignmentList) {
        let orderedList = [];
        assignmentList.assigned.sort(function (a, b) {
            return (
                new Date(a.UpdatedOnUtc).getTime() -
                new Date(b.UpdatedOnUtc).getTime()
            );
        });
        assignmentList.assigned.forEach(element => {
            let exist = orderedList.filter(list => list.Type === element.Type);
            if (exist.length === 0) {
                let filterList = assignmentList.assigned.filter(
                    ele => ele.Type === element.Type
                );
                for (let i = 0; i < filterList.length; i++) {
                    if (i === 0) {
                        filterList[i]['status'] = filterList[i].inProgress;
                    } else {
                        filterList[i]['status'] = 0;
                    }
                    orderedList.push(filterList[i]);
                }
            }
        });
        orderedList.sort(function (a, b) {
            return (
                new Date(a.UpdatedOnUtc).getTime() -
                new Date(b.UpdatedOnUtc).getTime()
            );
        });
        this.assignmentList.assigned = orderedList;
    }
}
