<div id="activity-select">
    <h3 translate>
        as.select.title
    </h3>

    <div style="padding: 0 16px;" class="form-wrapper">
        <div class="icon-input">
            <input type="text" placeholder="{{placeholder}}" [(ngModel)]="customOption" />
            <a (click)="onAddCustomOption()">
                <img alt="Plus icon" src="./assets/img/plus@2x.png" style="width: 30px; height: auto;">
            </a>
        </div>
    </div>
    <ul class="mcOptions">
        <li *ngFor="let activity of activities" [ngClass]="{'active': activity.isSelected}" (click)="toggleOption(activity)" (keypress)="toggleOption(activity)">
            <span>{{activity.text}}</span>
        </li>
    </ul>

</div>