<div class="app-l-page-wrap">
    <page-loader *ngIf="!isLoaded"></page-loader>
    <div *ngIf="isLoaded">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            [eventType]="'tools'"
            [selectedUser]="userDetail"
            [disableUserList]="true"
            [disableEventList]="true"
            class="app-l-modecheck__sidepanel"
            [userList]="userDetail"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
        <div class="app-l-main-heading">
            <h2 translate>clientList.tool.title</h2>
            <div class="app-l__actions-block">
                <button
                    (click)="showAssignModal('true')"
                    class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                    translate>
                    <img
                        alt="Assign"
                        src="./assets/img/icons-svg/assign-user-icon.svg" />clientList.assign
                </button>
                <div class="app-c-mat-select">
                    <img
                        alt="Assign"
                        src="./assets/img/icons-svg/calendarV1.svg" />
                    <mat-select
                        [(ngModel)]="dateRangeSelected"
                        class="app-l-dsh__rfp-select"
                        panelClass="app-l-dsh__rfp-panel"
                        (selectionChange)="onRangeChange()">
                        <mat-option
                            value="proud"
                            *ngFor="let days of dateRange"
                            translate
                            [value]="days.value"
                            >{{ days.title }}</mat-option
                        >
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
    <div class="title-section"></div>
    <div class="app-dm-das-assessment-list" *ngIf="isLoaded">
        <div class="app-l-summary__base">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card__header">
                        <div class="title-section app-l-card__h-left">
                            <h2 class="initial-image">
                                {{ getInitials(userDetail?.fullName) }}
                            </h2>
                            <h4>
                                {{ userDetail?.fullName }}<br />
                                <p>{{ userDetail?.email }}</p>
                            </h4>
                        </div>
                    </div>
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p translate>clientList.age</p>
                            <h4>{{ demographicData?.Age }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.ethinicity</p>
                            <h4>{{ demographicData?.Ethnicity }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gender</p>
                            <h4>{{ demographicData?.Gender }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gradLevel</p>
                            <h4>{{ demographicData?.GradeLevel }}</h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.referredBy</p>
                            <h4>{{ demographicData?.Referred }}</h4>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-l-result__card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.snapshot
                        </h3>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-completed-assessment">
                            <p translate>clientList.tool.noOfTools</p>
                            <h2 translate>
                                <span>{{ totalToolsCount }}</span>
                                clientList.tool.times
                            </h2>
                        </div>
                        <div class="app-l-additional-assessment">
                            <p class="assesment-p" translate>
                                clientList.tool.topTools
                            </p>
                            <div class="tool-val-main">
                                <div
                                    class="tool-val"
                                    *ngFor="let tool of toolsOrder">
                                    <ng-container>
                                        <h4>{{ tool.label }}</h4>
                                        <p>
                                            {{ toolsCount[tool.key] || 0 }}
                                            Times
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card app-l-assessment-assignments">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.title
                            </h3>
                        </div>
                        <div
                            class="app-l-result__card-header-right"
                            *ngIf="assignedListLoaded">
                            <!-- <a
                                class="action-view"
                                routerLink="/app/practice"
                                translate
                                >clientList.viewAll</a
                            > -->
                            <button
                                (click)="showAssignModal('true')"
                                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/user-icon.svg" />clientList.assign
                            </button>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <ng-container
                                    *ngIf="!assignedListLoaded"
                                    [ngTemplateOutlet]="skeletonLoader"
                                    [ngTemplateOutletContext]="{
                                        height: '100px'
                                    }">
                                </ng-container>
                                <mat-tab-group
                                    *ngIf="assignedListLoaded"
                                    mat-align-tabs="center"
                                    #tabGroup
                                    (focusChange)="onTabAssignChanged($event)"
                                    [selectedIndex]="0">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.assigned</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let assigned of assignedList
                                                ">
                                                <div>
                                                    <span translate>
                                                        {{
                                                            toolsConfig[
                                                                assigned.Type
                                                            ].title
                                                        }}
                                                    </span>
                                                </div>
                                                <!-- <div translate>
                                                    clientList.createdOn
                                                </div> -->
                                                <div translate>
                                                    <div
                                                        class="actionplandate-time">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>
                                                    {{
                                                        assigned.Date
                                                            | date: 'longDate'
                                                    }}
                                                </div>
                                                <div>
                                                    <button
                                                        tabindex="0"
                                                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                                                        (click)="deleteAssign(assigned.ID)"
                                                        (keypress)="deleteAssign(assigned.ID)">
                                                        <svg-icon
                                                            icon="trash"
                                                            class="w-100 h-100"
                                                            alt="trash"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList.length == '0'
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.completed</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completedList.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let completed of completedList
                                                ">
                                                <div>
                                                    {{ completed.Name }}
                                                </div>
                                                <div translate>
                                                    <!-- <span translate
                                                        >clientList.createdOn</span
                                                    > -->
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>
                                                    {{
                                                        completed.UpdatedOnUtc
                                                            | date: 'longDate'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completedList.length == 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment-results-percentage">
                <div class="app-l-result__card">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.zenroom
                            </h3>
                            <p translate>clientList.tool.recentlyPlayed</p>
                        </div>
                        <div class="app-l-result__card-header-right"></div>
                    </div>
                    <div class="border-line">
                        <div
                            class="app-l-result__card-body"
                            *ngIf="zenroom?.length != 0">
                            <div class="app-l-result-card-grid">
                                <div
                                    class="play-card"
                                    *ngFor="let zrlist of zenroom">
                                    <div class="play-card-image">
                                        <img
                                            [defaultImage]="
                                                './assets/img/NoImage.svg'
                                            "
                                            [lazyLoad]="zrlist.Image"
                                            class="img-fluid rounded-top"
                                            alt="{{
                                                getLowerCaseTitle(zrlist.Title)
                                            }}" />
                                        <h6>{{ zrlist.Title }}</h6>
                                    </div>
                                    <p class="play-footer">
                                        <span translate
                                            >clientList.tool.lastPlayed</span
                                        >:<span>{{
                                            zrlist.UpdatedOnUtc
                                                | date: 'dd MMM YYYY'
                                        }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="zenroom?.length === 0">
                            <div class="nodata-title" translate>
                                clientList.noData
                            </div>
                        </div>
                    </div>
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.thoughtDiary
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/thoughtdiary-listing/' + id
                                ">
                                <span translate>clientList.viewAll</span>
                                <!-- <svg-icon icon="angle-right"></svg-icon> -->
                            </a>
                        </div>
                    </div>
                    <div class="border-line">
                        <div
                            class="app-l-result__card-body"
                            *ngIf="filteredData.length !== 0">
                            <div class="app-dm-positive-cards-wrapper">
                                <div
                                    class="app-c-card"
                                    *ngFor="let diary of filteredData">
                                    <div class="app-c-card__header">
                                        <div class="app-l-card__h-left">
                                            <h2
                                                class="app-l-card__cardtitle app-c-body-text--14-m">
                                                {{
                                                    diary.thought.event ||
                                                        'Untitled'
                                                }}
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="app-c-card__content">
                                        <div class="app-c-card-main">
                                            <div
                                                class="feelings-cards-container">
                                                <div
                                                    class="feelings-card app-c-body-text--14"
                                                    [ngClass]="{
                                                        active:
                                                            diary.selectedIndex ==
                                                            itemIndex
                                                    }"
                                                    *ngFor="
                                                        let entry of diary.entries;
                                                        let itemIndex = index
                                                    "
                                                    (click)="
                                                        diary.selectedIndex =
                                                            itemIndex
                                                    ">
                                                    {{ entry.feeling }}
                                                </div>
                                                <div class="app-l-room__date">
                                                    <svg-icon
                                                        icon="calendar"></svg-icon>
                                                    <span>{{
                                                        diary.createdOn
                                                            | date: 'mediumDate'
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div
                                                class="feelings-cards-container-right">
                                                <p
                                                    class="app-c-body-text--14 text-content">
                                                    {{
                                                        diary.entries[
                                                            diary.selectedIndex
                                                        ]?.thought
                                                    }}
                                                </p>
                                                <div class="badges-container">
                                                    <div
                                                        class="badge app-c-body-text--12"
                                                        *ngFor="
                                                            let item of diary
                                                                .entries[
                                                                diary
                                                                    .selectedIndex
                                                            ]?.thinkingStyles
                                                                | filter
                                                                    : 'isSelected'
                                                                    : true
                                                        ">
                                                        {{ item.text }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="app-c-card__content_right" *ngIf="diary.thought.plan">
                                        <p class="app-c-card__content_right_content app-c-body-text--14">
                                            {{ diary.thought.plan }}
                                        </p>
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="filteredData.length === 0">
                            <p translate class="nodata-title">
                                clientList.noData
                            </p>
                        </div>
                    </div>

                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.roomPositive
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/room-for-positive/listing/' +
                                    id
                                ">
                                <span translate>clientList.viewAll</span>
                                <!-- <svg-icon icon="angle-right"></svg-icon> -->
                            </a>
                        </div>
                    </div>
                    <div class="app-l-room__tabs">
                        <mat-tab-group
                            mat-align-tabs="center"
                            disableRipple="true"
                            (selectedTabChange)="onTabChanged($event)">
                            <mat-tab
                                label="{{
                                    'practice.rfp.shortTitleProud' | translate
                                }}...">
                                <page-loader *ngIf="isLoading"></page-loader>
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="!isLoading">
                                    <div
                                        class="app-dm-positive-cards"
                                        *ngFor="let rfpEntry of rfpEntryList">
                                        <div class="app-l-room__date">
                                            <svg-icon
                                                icon="calendar"></svg-icon>
                                            <span>{{
                                                rfpEntry.created_at
                                                    | date: 'dd MMM YYYY'
                                            }}</span>
                                        </div>

                                        <h3>{{ rfpEntry.proudTitle }}</h3>
                                        <p>{{ rfpEntry.proudDescription }}</p>

                                        <button
                                            (click)="viewRfpEntry(rfpEntry)"
                                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                            <span translate
                                                >practice.rfp.viewDetails</span
                                            >
                                            <svg-icon
                                                icon="angle-right"></svg-icon>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !rfpEntryList.length && !isLoading
                                        ">
                                        <div class="empty-card">
                                            <div
                                                class="nodata-title"
                                                [translate]="
                                                    !this.isLoading
                                                        ? 'practice.rfp.noDataFound'
                                                        : ''
                                                "></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                            <mat-tab
                                label="{{
                                    'practice.rfp.shortTitleGrateful'
                                        | translate
                                }}...">
                                <page-loader *ngIf="isLoading"></page-loader>
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="!isLoading">
                                    <div
                                        class="app-dm-positive-cards"
                                        *ngFor="let rfpEntry of rfpEntryList">
                                        <div class="app-l-room__date">
                                            <svg-icon
                                                icon="calendar"></svg-icon>
                                            <span>{{
                                                rfpEntry.created_at
                                                    | date: 'dd MMM YYYY'
                                            }}</span>
                                        </div>

                                        <h3>{{ rfpEntry.gratefulTitle }}</h3>
                                        <p>
                                            {{ rfpEntry.gratefulDescription }}
                                        </p>
                                        <button
                                            (click)="viewRfpEntry(rfpEntry)"
                                            class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                            <span translate
                                                >practice.rfp.viewDetails
                                            </span>
                                            <svg-icon
                                                icon="angle-right"></svg-icon>
                                        </button>
                                    </div>
                                    <ng-container
                                        *ngIf="
                                            !rfpEntryList.length && !isLoading
                                        ">
                                        <div class="empty-card">
                                            <div
                                                class="nodata-title"
                                                [translate]="
                                                    !this.isLoading
                                                        ? 'practice.rfp.noDataFound'
                                                        : ''
                                                "></div>
                                        </div>
                                    </ng-container>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>

                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.tool.activityScheduler
                            </h3>
                            <p translate>clientList.tool.upcomingActivities</p>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <a
                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                [routerLink]="
                                    '/app/practice/activityscheduler-listing/' +
                                    id
                                ">
                                <span translate>clientList.viewAll</span>
                                <!-- <svg-icon icon="angle-right"></svg-icon> -->
                            </a>
                        </div>
                    </div>
                    <div #container>
                        <div class="border-line">
                            <div
                                class="app-c-accordion app-c-accordion--secondary"
                                *ngIf="activityschedules?.length != 0">
                                <div *ngFor="let activity of activityschedules">
                                    <div class="app-l-as__lists">
                                        <div class="app-l-as__block">
                                            <div class="app-l-as__bleft">
                                                <h4 class="app-c-heading--h4">
                                                    {{ activity.title }}
                                                </h4>
                                                <p>
                                                    {{ getType(activity.type) }}
                                                </p>
                                            </div>
                                            <div class="app-l-as__bright">
                                                <div class="app-l-as__b-info">
                                                    <div
                                                        class="app-l-as__b-time">
                                                        <span
                                                            class="app-c-body-text--14">
                                                            {{
                                                                activity.start
                                                                    | date
                                                                        : 'mediumDate'
                                                            }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p
                                                        class="inprogress-not-started"
                                                        *ngIf="
                                                            activity.status ===
                                                                'inprogress' &&
                                                            isNotStarted(
                                                                activity
                                                            )
                                                        ">
                                                        <svg-icon
                                                            icon="notstarted"></svg-icon>
                                                        <span translate
                                                            >clientList.tool.notStarted</span
                                                        >
                                                    </p>
                                                    <p
                                                        class="missed-not-satrted"
                                                        *ngIf="
                                                            isMissed(activity)
                                                        ">
                                                        <svg-icon
                                                            icon="missed"></svg-icon>
                                                        <span translate
                                                            >clientList.tool.missed</span
                                                        >
                                                    </p>
                                                    <p
                                                        class="Completed-started"
                                                        *ngIf="
                                                            activity.status ===
                                                            'complete'
                                                        ">
                                                        <svg-icon
                                                            icon="completedtools"></svg-icon>
                                                        <span translate
                                                            >clientList.tool.completed</span
                                                        >
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="activityschedules?.length === 0">
                                <div class="empty-card">
                                    <div
                                        class="nodata-title"
                                        [translate]="
                                            this.isLoaded
                                                ? 'as.noDataFound'
                                                : ''
                                        "></div>
                                </div>
                            </div>
                        </div>
                        <div class="app-l-result__card-header">
                            <div class="app-l-result__card-header-left">
                                <h3 class="app-c-heading--h3" translate>
                                    clientList.tool.thoughtDistortionQuiz
                                </h3>
                            </div>
                            <div class="app-l-result__card-header-right">
                                <a
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
                                    [routerLink]="
                                        '/app/practice/cd-quiz/' + id
                                    ">
                                    <span translate>clientList.viewAll</span>
                                    <!-- <svg-icon icon="angle-right"></svg-icon> -->
                                </a>
                            </div>
                        </div>

                        <div class="results-section" *ngIf="showScore">
                            <div>
                                <div class="answer-suggest">
                                    <span class="last-answer">
                                        <h2>
                                            {{ answerScore?.correct_answer }}
                                        </h2>
                                        <p translate>clientList.tool.correct</p>
                                    </span>
                                    <span class="before-answer">
                                        <h2>{{ answerScore?.wrong_answer }}</h2>
                                        <p translate>
                                            clientList.tool.inCorrect
                                        </p>
                                    </span>
                                </div>
                                <div class="results-card last-score-card">
                                    <div class="footer-content">
                                        <p class="app-c-heading--h5" translate>
                                            <img
                                                alt="Assign"
                                                src="./assets/img/icons-svg/chart-success.svg" />cdQuiz.last
                                        </p>
                                        <h3 id="last">
                                            {{ avgScore | number: '1.0-0'
                                            }}<span>%</span>
                                        </h3>
                                    </div>
                                    <div class="card-bg">
                                        <svg-icon
                                            icon="score-card-bg"></svg-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="border-line-quiz"></div>
                            <div class="results-card best-score-card">
                                <div class="footer-content">
                                    <p class="app-c-heading--h5" translate>
                                        <img
                                            alt="Assign"
                                            src="./assets/img/icons-svg/cup.svg" />cdQuiz.best
                                    </p>
                                    <h3 id="best">
                                        {{ bestScore | number: '1.0-0'
                                        }}<span>%</span>
                                    </h3>
                                </div>
                                <div class="card-bg">
                                    <svg-icon icon="score-card-bg"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!showScore">
                            <p translate class="nodata-title">
                                clientList.noData
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template let-height="height" #skeletonLoader>
    <mat-card class="skeleton-card" [ngStyle]="{ height: height }">
        <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card>
</ng-template>
