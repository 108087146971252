import { TranslateService } from '@ngx-translate/core';

export class TranslationServiceHelper {
  private static translateService: TranslateService;

  static setTranslateService(translateService: TranslateService): void {
    TranslationServiceHelper.translateService = translateService;
  }

  static translateLabel(label: string): string {
    if (!TranslationServiceHelper.translateService) {
      console.error('TranslationHelper: TranslateService not initialized.');
      return label; // Fallback to the original label if TranslateService is not available
    }
    return TranslationServiceHelper.translateService.instant(label);
  }
}