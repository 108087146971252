import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UrlService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrls: Observable<string> = this.previousUrl.asObservable();
    prevurl: string;
    courseprevurl: string;

  constructor() { }

  setPreviousUrl(previousUrl: string) { 
    this.prevurl = previousUrl
    this.previousUrl.next(previousUrl);
  }

  setCurrentUrl(url) {      
    this.prevurl = url;  
  } 

  setCurrentUrlCourse(url) {
    this.courseprevurl = url; 
  }

  getPreviousUrlCourse(){
    return this.courseprevurl;
  }

  getPreviousUrl(){
    return this.prevurl;
  }

}

export class DateService {
  private selectedDateSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  setSelectedDate(date: Date) {
    this.selectedDateSubject.next(date);
  }

  getSelectedDate(): Observable<Date> {
    return this.selectedDateSubject.asObservable();
  }
}

export class FooterService {
  private hideFooterSubject = new BehaviorSubject<boolean>(false);
  hideFooter$ = this.hideFooterSubject.asObservable();

  updateFooterVisibility(shouldHide: boolean) {
    this.hideFooterSubject.next(shouldHide);
  }
}