
import { Component, ViewChild, ViewEncapsulation, OnInit, Inject, OnChanges, SimpleChanges } from '@angular/core';
import {
	FormGroup,
	FormBuilder,
	Validators,
} from "@angular/forms";
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { ModalService } from '../../lib/modal.service';
import { Organization } from '../../models/organization';
import { User } from '../../models/user';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { config } from '../../../environments/all';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import * as _ from 'lodash';
import { MultiAutoItem } from '../../models/multiautoitem';
import { UserService } from 'app/lib/user.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
@Component({
  selector: 'app-relationship-manager-user-create',
  templateUrl: './relationship-manager-user-create.component.html',
  styleUrls: ['./relationship-manager-user-create.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {showError: true},
    },
  ],
})

export class RelationshipManagerUserCreateComponent implements OnInit {
  userEditForm: FormGroup;
  organizationDataForm:FormGroup;
  isloaded = false;
  roles: Array<Object> = [];
  organizations: Organization[];
  inputs: string[];
  errorPopup: any;
  successPopup: any;
  emptyPopup: any;
  tosPopup: any;
  fullName: string;
  email: string;
  password: string;
  notificationOn: boolean;
  user: User;
  role: number;
  orgId: number;
  status: string;
  lockRole: boolean;
  timezones: any;
  isLinear = false;
  passwordValidate: boolean = false;
  organizationIds = [];
  multiSelOrgs: Array<MultiAutoItem> = [];
  inviteType;
  passwordType:string = 'password';
  constructor(
    public dialogRef: MatDialogRef<RelationshipManagerUserCreateComponent>,
 	  private formBuilder: FormBuilder,
    private api: ApiService,
    private log: LogService,
    private modalService: ModalService,
    private translate: TranslateService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.user = this.userService.getUser();
    this.roles = [
      { 'id': 1, 'name': 'User' },
      { 'id': 2, 'name': 'Professional' },
      { 'id': 3, 'name': 'Executive' },
      { 'id': 4, 'name': 'Executive Professional' },
      { 'id': 5, 'name': 'Joint Professional' },
      { 'id': 6, 'name': 'Relationship Manager' },
    ];

    if (this.user.roleId === 2) {
      this.roles.push( { 'id': 7, 'name': 'Admin' })
    }



    // User Form
    this.userEditForm = this.formBuilder.group({
      fullName: ["", [Validators.required]],
      email: ["", [Validators.required,  Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/)]],
      status: ["approved"],
      notificationOn: [true],
      timezone: [""],
      roleId: [1],
      message: [""],
      lockedRole: [false],
      password: ["", [Validators.minLength(8), Validators.maxLength(32)]],
      orgId: [[],[]],
    });

    // Organization form
    this.organizationDataForm = this.formBuilder.group({
      orgId: [[],[]],
    });

    this.timezones = config.timezones;
  }



  ngOnInit() {
    if (Object.keys(this.data.data).length > 0) {
      this.inviteType = this.data.data.type;
      // Get all organization
      const apiUrl = this.inviteType === 'editUser' ? 'admin/organizations' : 'admin/organizations?parent=1'
      this.api.get(apiUrl).subscribe(
        (results: any) => {
          this.organizations = Organization.initializeArray(results.data);


          this.organizations.forEach((org) => {
            this.multiSelOrgs.push(new MultiAutoItem(org.id, org.name));
          });
          this.isloaded = true;
        },
        (error: any) => {

          this.log.error('Error loading. ' + error.message);
        }
      );
      if (this.inviteType === 'Editinvite' || this.inviteType === 'NewInvite'){
        if (this.user.userType === 'superadmin') {
          this.roles = [
            { 'id': 6, 'name': 'Relationship Manager' },
            { 'id': 7, 'name': 'Admin' }
          ];
        } else if (this.user.userType === 'relationshipmanager') {
          this.roles = [
            { 'id': 6, 'name': 'Relationship Manager' }
          ];
        }
      }
      if (this.inviteType === 'NewInvite' || this.inviteType === 'Editinvite' || this.inviteType === 'editUser') {
        this.userEditForm.get('password').setValidators([]);
        this.userEditForm.updateValueAndValidity();
        this.userEditForm.get('orgId').setValidators([]);
        this.userEditForm.updateValueAndValidity();
      }
      if(this.data.data.userId !== undefined) {
        let userId = this.data.data.userId;
        this.api.get('users/' + userId, {
        }).subscribe(
          (result: any) => {
            this.user = new User(result.data);
            if (this.user.primaryOrganization) {
              this.orgId = this.user.primaryOrganization.id;
            }

        this.userEditForm.patchValue({
          fullName: this.user.fullName,
          email: this.user.email,
          status: this.user.status,
          notificationOn: this.user.preferences.notificationOn,
          timezone: this.user.preferences.timezone,
          roleId: this.user.roleId,
          lockedRole: this.user.lockedRole,
          orgId: this.userEditForm.value.roleId !== 6 ? this.user.organizations?.map(organizations =>organizations.id)[0]:this.user.organizations?.map(organizations =>organizations.id)
        });
        this.organizationDataForm.patchValue({
          orgId: this.user.organizations?.map(organizations =>organizations.id)

        });
        this.log.debug(this.multiSelOrgs)
        this.log.debug(this.organizationDataForm)

            this.isloaded = true;
          },
          (error: any) => {
            this.log.error('Error fetching user. ' + error.message);
          }
        );
      }


    }
    this.translate.stream('signUp').subscribe((res: any) => {
      this.inputs = res.inputs;
      this.errorPopup = res.errorPopup;
      this.successPopup = res.successPopup;
      this.emptyPopup = res.emptyPopup;
      this.tosPopup = res.tosPopup;
    });


  }

  get f() {
	return this.userEditForm.controls;
  }

  // RM invite and create User
  doSave() {
    if (this.userEditForm.invalid || this.organizationDataForm.invalid) {
			return
		}
    if(this.inviteType === 'createUser'){
        this.api.post('admin/usercreate',
        {
          Name:  this.userEditForm.value.fullName, //this.user.fullName,
          Email: this.userEditForm.value.email,
          RoleID: this.userEditForm.value.roleId,
          Status: this.userEditForm.value.status,
          Password: this.userEditForm.value.password,
          OrgID: this.userEditForm.value.roleId === 6 ? this.organizationDataForm.value.orgId : this.userEditForm.value.orgId,
          NotificationOn: this.userEditForm.value.notificationOn,
          lockedRole: this.userEditForm.value.lockedRole,
          Timezone: this.userEditForm.value.timezone
        }
      ).subscribe(
        (data: any) => {
          this.modalService.showAlert('Success', 'User was successfully created');
          this.dialogRef.close({});
        },
        (error: any) => {
          this.modalService.showAlert('Error', error.message);
        }
      );
    } else if (this.inviteType === 'editUser' || this.inviteType === 'editRelationShipManager'){
        this.api.put('admin/usersave/' + this.user.id,
        {
          Name:  this.userEditForm.value.fullName, //this.user.fullName,
          Email: this.userEditForm.value.email,
          RoleID: this.userEditForm.value.roleId,
          Status: this.userEditForm.value.status,
          OrgID: this.userEditForm.value.roleId === 6 ? this.organizationDataForm.value.orgId : this.userEditForm.value.orgId,
          NotificationOn: this.userEditForm.value.notificationOn,
          lockedRole: this.userEditForm.value.lockedRole,
         Timezone: this.userEditForm.value.timezone,
        }
      ).subscribe(
        (data: any) => {
          this.modalService.showAlert('Success', 'User was successfully updated');
          this.dialogRef.close({});
        },
        (error: any) => {
          this.modalService.showAlert('Error', error.message);
        }
      );
    }
    else {
      let apiUrl = this.userEditForm.value.roleId === 6 ? 'invitations/rminvite' : 'invitations/admininvite';
      this.api.post(apiUrl, {
        Email: this.userEditForm.value.email,
        Message: this.userEditForm.value.message,
        OrgID: this.organizationDataForm.value.orgId,
        NotificationOn: this.userEditForm.value.notificationOn,
        lockedRole: this.userEditForm.value.lockedRole,
         Timezone: this.userEditForm.value.timezone,
      }).subscribe(
        (result: any) => {
          this.modalService.showAlert('success', result.message)
          this.log.event(AnalyticEvent.event.userInvitationSent);
          this.log.error(result.message);
          this.dialogRef.close({});
        },
        (error: any) => {
          this.modalService.showAlert('Error', error.message);
        }
      );
    }
  }

  onClose() {
    this.dialogRef.close({'status':'close'});
  }

  onChangePassword() {
		let validate = this.validatePassword(this.password);
		if (validate) {
			this.passwordValidate = true;
		}
		else {
			this.passwordValidate = false;
		}
  }

  validatePassword(value) {
		let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
		var RegularExp = new RegExp(reg);
		if (RegularExp.test(value)) {
			this.passwordValidate = true;
			return true;
		}
		else {
			return false;
		}
	}

  onSelectOrgs(items: MultiAutoItem[]) {
    this.organizationIds = [];
		this.organizationIds = items.map((item) => item.id);
		this.organizationDataForm.patchValue({
			orgId: this.organizationIds
		})
	}

  // Role change functionality
  onChange(fromPage){
    if (fromPage === 'fromInternalUserEdit') {
      this.inviteType = 'editUser';
    }
     if(this.userEditForm.value.roleId === 6){
      this.userEditForm.get('orgId').clearValidators();
      this.userEditForm.updateValueAndValidity();
      this.organizationDataForm.get('orgId').setValidators([Validators.required]);
      this.organizationDataForm.updateValueAndValidity();
    } else if(this.userEditForm.value.roleId === 7){
      this.userEditForm.get('orgId').clearValidators();
      this.organizationDataForm.get('orgId').clearValidators();
      this.userEditForm.get('orgId').setValidators([]);
      this.userEditForm.updateValueAndValidity();
      this.organizationDataForm.get('orgId').setValidators([]);
      this.organizationDataForm.updateValueAndValidity();
    } else {
      this.userEditForm.get('orgId').setValidators([Validators.required]);
      this.userEditForm.updateValueAndValidity();
      this.organizationDataForm.get('orgId').clearValidators();
      this.organizationDataForm.updateValueAndValidity();
    }
  }

  onClickPassword(){
		this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
	}

}

