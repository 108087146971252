import { Component, OnInit } from '@angular/core';
import { UserService } from '../../lib/user.service';
import { OrganizationService } from '../../lib/organization.service';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { User } from '../../models/user';
import { Organization, OrganizationCourse } from '../../models/organization';
import { Router } from '@angular/router';
import { UrlService } from '../../lib/url.service';
import { SvgIconComponent } from 'app/components/svg-icon/svg-icon.component';
import { Location } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ModalService } from 'app/lib/modal.service';

@Component({
    selector: 'app-theory',
    templateUrl: './theory.component.html',
    styleUrls: ['./theory.component.scss'],
})
export class TheoryComponent implements OnInit {
    user: User;
    organization: any;
    access: any;
    loaded: boolean;
    enabledCourses: OrganizationCourse[];
    defaultCourses: OrganizationCourse[];
    ssoLink: string;
    count: any;

    courseList: any = {};

    constructor(
        private userService: UserService,
        private api: ApiService,
        private logService: LogService,
        private organizationService: OrganizationService,
        private router: Router,
        private urlService: UrlService,
        private location: Location,
        private translate: TranslateService,
        private modalService: ModalService
    ) {
        this.loaded = false;
        this.access = {
            anxiety: true,
            depression: true,
            publicspeaking: true,
            disaster: true,
            purpose: true,
            mindfulness: true,
            sleep: false,
            work: false,
            depression_new: true,
        };
        this.urlService.setCurrentUrl(null);
    }

    ngOnInit() {
        this.defaultCourses = [];
        this.ssoLink = '';
        this.user = this.userService.getUser();

        this.loadCourses();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.defaultCourses = [];
            this.enabledCourses = [];
            this.courseList = [];
            this.loadCourses();
        });
    }

    loadCourses() {
        if (this.user.primaryOrganization && this.user.primaryOrganization.id) {
            this.api
                .get('organizations/' + this.user.primaryOrganization.id, {
                    fetchcourseimg: true,
                })
                .subscribe(
                    (result: any) => {
                        this.organization = new Organization(
                            result.data,
                            'full'
                        );
                        if (
                            this.organization.courses !== undefined &&
                            this.organization.courses.length > 0
                        ) {
                            this.enabledCourses = this.organization.courses;
                            this.unlock();
                        } else {
                            this.loadDefaultCourses();
                        }
                        if (
                            this.organization.integrations &&
                            this.organization.integrations.togetherAllSsoLink
                        ) {
                            this.ssoLink =
                                this.organization.integrations.togetherAllSsoLink;
                            localStorage.setItem('ssoLink', this.ssoLink);
                        } else {
                            localStorage.removeItem('ssoLink');
                        }
                    },
                    (error: any) => {
                        this.logService.error(
                            'Error getting organizations. ' + error.message
                        );
                    }
                );
        } else {
            this.loadDefaultCourses();
        }
    }

    loadDefaultCourses() {
        this.api.get('series/serieslisting/defaults').subscribe(
            (result: any) => {
                if (result.data) {
                    result.data.forEach(course => {
                        this.defaultCourses.push(
                            new OrganizationCourse({
                                id: course.ID,
                                courseId: course.ID,
                                key: course.CourseKey,
                                name: course.Name,
                                description: course.Description,
                                enabled: course.DefaultCourse,
                                videoCount: course.videoCount,
                                length: course.Length,
                                courseImage: course.CourseImage,
                                completed: course.completed,
                            })
                        );
                    });
                    this.unlock();
                }
            },
            (error: any) => {
                this.logService.error('Error getting Series. ' + error.message);
            }
        );
    }

    unlock() {
        if (this.enabledCourses && this.enabledCourses.length > 0) {
            this.setCoursePermissions(this.enabledCourses);
        } else {
            this.setCoursePermissions(this.defaultCourses);
            this.logService.debug('default Series');
            this.logService.debug(this.defaultCourses);
        }
    }

    setCoursePermissions(courses) {
        for (const course of courses) {
            if (course && course['enabled']) {
                this.courseList[course.key] = course;
            }
        }

        this.loaded = true;
        this.count = Object.keys(this.courseList).length;
        if (this.count == 0) {
            this.modalService.showAlert(
                'Alert',
                'Your organization has not enabled any Series'
            );
            this.router.navigate(['/app']);
        }
    }

    onAdditionalInfo() {}

    goToCourse(course) {
        if (!this.user.isFullAccess) {
            this.showBlocked();
        } else {
            this.router.navigateByUrl('app/series-details/' + course.key);
        }
    }
    async showBlocked() {
        // const modal = await this.modalCtrl.create({ component: BlockedModal });
        // await modal.present();
    }
    goBack() {
        this.location.back();
    }
}
