<page-header [headerText]="headerText" [backLink]="backLink" [cssClass]='cssClass' [backText]="backText"></page-header>

<div class="page-section no-padding">
    <div class="page-section-content clearfix">
        <div class="card-container col-xs-12 col-sm-6">
            <figure class="card">
                <div class="title"> Discussion Forum</div>
                <div class="description">
                    Join the anonymous community! Communicate, share, and safely resolve feelings you might be
                    experiencing
                    within your daily life.
                </div>
                <div class="logo-wrapper">
                    <img alt="Logo" src="./assets/img/additional-resources/togetherall.svg">
                </div>
                <div class="actions center">
                    <a class="btn btn-primary"
                        (click)="goToResource(ssoLink)">Go</a>
                </div>
            </figure>
        </div>
    </div>
</div>