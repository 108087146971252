<div class="confirm-popup text-center">
    <img
        *ngIf="['Success', 'Activity Scheduled', 'Activity Edited', 'Activity Edited', 'Congrats', 'Account created'].includes(title)"
        alt="Moodcheck activity icon"
        src="./assets/img/success.gif" />
    <h2 mat-dialog-title translate>{{title}}</h2>
    <p class="error-dialog-content" translate [innerHtml]="message"></p>
    <p class="error-dialog-content" translate [innerHtml]="message1"></p>
    <p class="error-dialog-content" translate [innerHtml]="message2"></p>
    <footer>
        <button
            mat-dialog-close
            class="app-c-btn app-c-btn--primary app-c-btn--curve">
            OK
        </button>
    </footer>
</div>
