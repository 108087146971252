<div id="payment-intro">

    <div id="intro">
        <h1 id="section-title" class="wt-access">Purchase WellTrack Boost</h1>
        <div class="logo">
            <a routerLink="/">
                <img src="./assets/img/logo_white@2x.png">
            </a>
        </div>
        <div class="inside">
            <div class="content">
                <h1 id="introline" translate>
                    payment.interactive
                </h1>
                <p translate>
                    payment.takeCharge
                </p>
                <a class="btn btn-primary" routerLink="/purchase/luminohealth" translate>
                    payment.tryWelltrack
                </a>
            </div>
            <img src="./assets/img/moodcheck_phone@2x.png">
        </div>
    </div>

    <div id="features">
        <div class="inside center clearfix">
            <h1 translate>payment.WelltrackIs</h1>

            <div class="col-md-4 feature">
                <img alt="Theory" src="./assets/img/payments/document.png">
                <div class="title" translate>payment.courses</div>
                <div class="description" translate>
                    payment.welltrackProvides
                </div>
            </div>

            <div class="col-md-4 feature">
                <img alt="Theory" src="./assets/img/payments/practice.png">
                <div class="title" translate>payment.tools</div>
                <div class="description" translate>
                    payment.weProvide
                </div>
            </div>
            <div class="col-md-4 feature">
                <img alt="" src="./assets/img/payments/community.png">
                <div class="title" translate>payment.sharing</div>
                <div class="description" translate>
                    payment.welltrackisGreat
                </div>
            </div>
        </div>

        <div class="inside center">
            <div class="divider">&nbsp;</div>

            <!--
                  <h1 translate>payment.pricing</h1>
                <div id="pricing-block">
                    <div class="strikethrough">
                        $6 per Month
                    </div>
                    <div class="pricing-or">or</div>
                    <div class="strikethrough">$60 per year</div>
                    <br>
                    <div>Sun Life Offer 50% off</div>
                    <div>$3 per Month</div>
                    <div class="pricing-or">or</div>
                    <div>$30 per Year</div>
                    <a class="btn btn-primary" routerLink="/purchase/sunlife50" translate>
                        payment.tryWelltrack
                    </a>
                </div>
            -->
            <div id="features-title" translate>payment.featuresTitle</div>
            <div id="top-features">
                <div class="row">
                    <div class="col-md-4 col-xs-12">
                        <img style="max-height:400px;" class="img-responsive" src="./assets/img/moodcheck-phone.png">
                    </div>
                    <div class="col-md-8 col-xs-12 feature">
                        <div class="title" [innerHTML]="'payment.features.moodcheck_title' | translate"></div>
                        <div class="description" [innerHTML]="'payment.features.moodcheck_description' | translate"></div>
                        <!--<div>MoodCheck is the key function of WellTrack Boost, it will allow us to collect the right data to provide you the right care at the right time</div>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8 col-xs-12 feature">
                        <div class="title" [innerHTML]="'payment.features.zenroom_title' | translate"></div>
                        <div class="description" [innerHTML]="'payment.features.zenroom_description' | translate"></div>
                        <!--<div>Completing the wellness assessment regularly will allow you to see progress in your levels for each category.</div>-->
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <img style="max-height:400px;" class="img-responsive" src="./assets/img/zenroom-phone.png">
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-4 col-xs-12">
                        <img style="max-height:400px;" class="img-responsive" src="./assets/img/thought_diary-phone.png">
                    </div>
                    <div class="col-md-8 col-xs-12 feature">
                        <div class="title" [innerHTML]="'payment.features.thoughtDiary_title' | translate"></div>
                        <div class="description" [innerHTML]="'payment.features.thoughtDiary_description' | translate"></div>
                        <!-- <div>Use the thought diary to analyze an event that caused you to feel depressed or anxious.</div>-->
                    </div>
                </div>
            </div>
            <div id="features-list">
                <!--
                    <div class="feature">
                        <div class="title" [innerHTML]="'payment.features.moodcheck_title' | translate"></div>
                        <div class="description" [innerHTML]="'payment.features.moodcheck_description' | translate"></div>
                        <div class="seperator"></div>
                    </div>
                    <div class="feature">
                        <div class="title" [innerHTML]="'payment.features.zenroom_title' | translate"></div>
                        <div class="description" [innerHTML]="'payment.features.zenroom_description' | translate"></div>
                        <div class="seperator"></div>
                    </div>
 
                <div class="feature">
                    <div class="title" [innerHTML]="'payment.features.thoughtDiary_title' | translate"></div>
                    <div class="description" [innerHTML]="'payment.features.thoughtDiary_description' | translate"></div>
                    <div class="seperator"></div>
                </div>
                -->
                <div class="feature">
                    <div class="title" [innerHTML]="'payment.features.activityScheduler_title' | translate"></div>
                    <div class="description" [innerHTML]="'payment.features.activityScheduler_description' | translate"></div>
                    <div class="seperator"></div>
                </div>
                <div class="feature">
                    <div class="title" [innerHTML]="'payment.features.funAchievement_title' | translate"></div>
                    <div class="description" [innerHTML]="'payment.features.funAchievement_description' | translate"></div>
                    <div class="seperator"></div>
                </div>
                <div class="feature">
                    <div class="title" [innerHTML]="'payment.features.wellness_title' | translate"></div>
                    <div class="description" [innerHTML]="'payment.features.wellness_description' | translate"></div>
                    <div class="seperator"></div>
                </div>
                <div class="feature">
                    <div class="title" [innerHTML]="'payment.features.dataSharing_title' | translate"></div>
                    <div class="description" [innerHTML]="'payment.features.dataSharing_description' | translate"></div>
                </div>
            </div>
        </div>
    </div>
    <div id="closing">
        <div class="inside center">
            <h1 translate>payment.numberPeople</h1>
            <p class="copy" translate>payment.psychologists</p>
            <div class="clear"></div>
            <h1 translate>Here's what people are saying</h1>
            <br>
            <iframe id="video" src="https://www.youtube.com/embed/ObeKqd-quyY?rel=0" frameborder="0" allow="autoplay; encrypted-media"
                allowfullscreen></iframe>
        </div>

    </div>

    <div id="post-closing" class="center">
        <div class="inside">
            <div class="images">
                <div class="col-sm-3 col-xs-12">
                    <img style="margin-top:-5px;" src="./assets/img/payments/Georgia_State_University_flame_logo.png">
                </div>

                <div class="col-sm-3 col-xs-12">
                    <img src="./assets/img/payments/boston-college-logo.png">
                </div>

                <div class="col-sm-3 col-xs-12 ">
                    <img src="./assets/img/payments/MUN_Logo.png">
                </div>

                <div class="col-sm-3 col-xs-12">
                    <img src="./assets/img/payments/santacruze.png">
                </div>
            </div>
            <div class="clear"></div>
            <div id="copyright">
                <span translate>payment.copyright</span> 2018
            </div>
        </div>

    </div>
</div>