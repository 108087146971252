<div class="app-c-modal-primary">
  <div class="app-c-modal-primary__header">
    <div class="app-c-modal-primary__header-content">
      <h2 class="app-l-room-create__title">
        Question {{ currentItemIndex + 1 }} / {{ 2 }}
      </h2>
      <div class="app-c-welltrack__progress">
        <div class="app-c-welltrack__progress-fill"  [style.width.%]="(currentItemIndex + 1) * 50"  ></div>
      </div>
    </div>
    <button tabindex="0" (click)="onClose()" (keypress)="onClose()"  class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-16">
      <svg-icon icon="close" class="w-100 h-100"></svg-icon>
  </button>
  </div>
  <div class="app-c-modal-primary__body">
  <!-- <h2 class="rfp-qtn-title" >
    <h4><span>0{{ currentItemIndex + 1 }}</span> {{ questions[currentItemIndex].questionTitle | translate }}</h4>
  </h2> -->
  <h3 class="app-l-room__question" >
    {{ questions[currentItemIndex].questionTitle | translate }}
  </h3>
  <div>
    <form [formGroup]="itemForms.controls[currentItemIndex]">
      <div class="input-conatiner app-room-for-positive-create">
        <div class="app-c-form-group">
            <div class="app-c-form-group__top">
              <div class="app-c-form-group__top-left">
                <label class="app-c-form--label" translate>practice.rfp.answerTitle</label>
              </div>
              <div class="app-c-form-group__top-right">
                <span class="app-c-form--hint" translate>practice.rfp.max100Char</span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-6"><mat-label translate>practice.rfp.answerTitle</mat-label></div>
              <div class="col-md-6 text-right"><mat-hint translate>practice.rfp.max100Char</mat-hint></div>
            </div> -->
            <mat-form-field appearance="outline">
                <input matInput formControlName="answerTitle" [maxlength]="100"  [placeholder]="'practice.rfp.titlePlaceholder' | translate" />
            </mat-form-field>
        </div>
        <div class="app-c-form-group">
            <div class="app-c-form-group__top">
              <div class="app-c-form-group__top-left">
                <label class="app-c-form--label" translate>practice.rfp.answerDescTitle</label>
              </div>
              <div class="app-c-form-group__top-right">
                <span class="app-c-form--hint" translate>practice.rfp.max100Words</span>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-6"><mat-label translate>practice.rfp.answerDescTitle</mat-label></div>
              <div class="col-md-6 text-right"><mat-hint translate>practice.rfp.max100Words</mat-hint></div>
            </div> -->
            <mat-form-field appearance="outline">
                <textarea aria-label="Enter description" title="Enter description" matInput  #text (keydown)="wordCounter($event)" formControlName="answerDescription"  [placeholder]="'practice.rfp.descriptionPlaceholder' | translate"></textarea>
            </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

    <div class="app-c-modal-primary__footer">
        <div class="app-c-modal-primary__footer-left" *ngIf="this.currentItemIndex > 0">
            <button
              class="app-c-btn app-c-btn--secondary app-c-btn--curve"
              [ngClass]="{ 'disabled': this.currentItemIndex == 0 }"
              translate
              (click)="previousItem()">
              <svg-icon icon="angle-left"></svg-icon>
              <span translate>practice.rfp.previous</span>
            </button>
        </div>
        <div class="app-c-modal-primary__footer-right">
            <button
                [ngClass]="{ 'disabled': !currentFormGroup.valid }"
                class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                (click)="nextItem()"
                *ngIf="this.currentItemIndex != this.itemForms.length - 1">
                <span translate>practice.rfp.next</span>
                <svg-icon icon="angle-right"></svg-icon>
            </button>
            <button
              [ngClass]="{ 'disabled': !currentFormGroup.valid }"
              class="app-c-btn app-c-btn--primary app-c-btn--curve"
              (click)="nextItem()"
              *ngIf="this.currentItemIndex == this.itemForms.length - 1"
              translate
              >practice.rfp.submit</button>
        </div>
    </div>
</div>