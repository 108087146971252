<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap" id="page-fun-achievement">
	<div class="app-l-page__navigation">
		<div class="app-l-page__previous">
			<button
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				(click)="goBack()"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) ">
				<li (click)="toHome()">Home</li>
				<li (click)="toTools()" translate>menu.tools</li>
				<li class="app-l-current-page" (click)="scrollToDiv('today')" translate>
					as.title
				</li>
			</ul>
			<ul *ngIf = "user.userType != 'user' && !showCreateBtn">
				<li (click)="toClienList()" translate>menu.clientList</li>
				<li class="app-l-current-page" (click)="scrollToDiv('today')" translate>
					as.title
				</li>
			</ul>
		</div>
	</div>
	<!-- <page-header [headerText]="headerText" [backLink]="'/app/practice'" [cssClass]="'tools-activityscheduler tools'" [backText]="backText"
        [headerImage]="'./assets/img/Rectangle 4350 (3).png'"></page-header> -->

	<!-- <div class="col-sm-12">
        <div class="page-section no-margin page-header-body">
            <span translate>
                Activity Scheduler
            </span>
            
        </div>
    </div> -->
	<div class="app-l-page-header-block">
		<div class="app-l-page-title-block">
			<h2 class="app-l-page-title" translate>as.table.scheduler</h2>
		</div>
		<div class="app-l-page-title-actions">
			<a
				tabindex="0"
				*ngIf="
					(activityschedules | filter : 'status' : 'complete')
						.length > 1000
				"
				(click)="loadMore()"
				translate
				>as.view</a
			>
			<button *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) "
				tabindex="0"
				(click)="scheduleActivity()"
				class="app-c-btn app-c-btn--primary app-c-btn--curve"
			>
				<svg-icon icon="plus"></svg-icon>
				<span translate>Add Activity</span>
			</button>
		</div>
	</div>
	<div class="app-l-as__wrap">
		<div class="app-l-as__left">
			<div class="app-l-as__calendar">
				<mat-calendar
					[(selected)]="selected"
					(selectedChange)="onSelect($event)"
				></mat-calendar>
			</div>
			<div class="app-c-card app-l-as__ua">
				<div class="app-c-card__header">
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title">Upcoming activities</h3>
					</div>
				</div>
				<div class="app-c-card__body">
					<div
						class="app-l-as__ua-wrap"
						*ngIf="upcomingActivities.length"
					>
						<div
							class="app-l-as__ua-block"
							*ngFor="let activity of upcomingActivities"
						>
							<h4 class="app-c-body-text--14-m">
								{{ activity.title }}
							</h4>
							<div class="app-l-as__ua-cnt">
								<div class="app-l-as__usc-left">
									<p class="app-c-body-text--12">
										{{ getType(activity.type) }}
									</p>
								</div>
								<div class="app-l-as__usc-right">
									<span class="app-c-body-text--12"
										>{{ activity.start | date : "hh:mm a" }}
										to
										{{
											activity.end | date : "hh:mma"
										}}</span
									>
								</div>
							</div>
						</div>
					</div>
					<div
						class="app-l-no-mood-check"
						*ngIf="!upcomingActivities.length"
					>
						<div class="no-data-image">
							<svg-icon icon="scheduled-activities"></svg-icon>
							<span
								class="app-l-no-mood__round app-l-no-mood__round1"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round2"
							></span>
							<span
								class="app-l-no-mood__round app-l-no-mood__round3"
							></span>
						</div>
						<p class="app-c-body-text--14">No planned activities</p>
					</div>
				</div>
			</div>
		</div>
		<div class="app-l-as__right">
			<div class="app-c-card app-c-card--border-off">
				<div class="app-c-card__header">
					<div class="app-l-card__h-left">
						<h3 class="app-l-card__title">
							Schedules for
							<span>{{ currentMonth | date : "MMMM y" }}</span>
						</h3>
					</div>
				</div>
				<div
					class="app-c-card__body"
					*ngIf="activityschedules.length"
					#container
				>
					<mat-accordion
						class="app-c-accordion app-c-accordion--secondary"
					>
						<mat-expansion-panel
							*ngFor="
								let date of activityMonthDates;
								let i = index
							"
							[id]="
								date.currentDate
									? 'today'
									: getDateId(date.date)
							"
							[disabled]="date.activities.length == 0"
							[expanded]="date.currentDate"
						>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ date.date | date : "MMM dd yyyy" }}
									<span
										class="date-label"
										[ngClass]="{ active: date.currentDate }"
										translate
										>{{ getDay(date) }}</span
									>
									<!-- <span *ngIf="" translate>Tomorrow</span> -->
								</mat-panel-title>
								<mat-panel-description>
									<div class="app-l-active__count">
										{{ date.activities.length
										}}<span translate>{{
											date.activities.length > 1
												? " as.activities"
												: " as.activity"
										}}</span>
									</div>
								</mat-panel-description>
							</mat-expansion-panel-header>
							<div class="app-l-as__lists">
								<div
									class="app-l-as__block"
									*ngFor="let activity of date.activities"
								>
									<div class="app-l-as__bleft">
										<h4 class="app-c-heading--h4">
											{{ activity.title }}
										</h4>
										<p class="app-c-body-text--16">
											{{ getType(activity.type) }}
										</p>
									</div>
									<div class="app-l-as__bright">
										<div class="app-l-as__b-info">
											<div
												class="app-l-as__b-alert"
												*ngIf="isMissed(activity)"
											>
												<span
													class="app-c-body-text--14"
													>Missed</span
												>
											</div>
											<div class="app-l-as__b-time">
												<span
													class="app-c-body-text--14"
													>{{
														activity.start
															| date : "hh:mm a"
													}}
													to
													{{
														activity.end
															| date : "hh:mma"
													}}</span
												>
											</div>
										</div>
										<div
											class="app-l-as__b-actions"
											*ngIf="
												activity.status === 'complete'
											"
										>
											<button
												class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm app-l-btn--completed"
											>
												<svg-icon
													icon="tick"
												></svg-icon>
												<span>Completed</span>
											</button>
										</div>
										<div
											class="app-l-as__b-actions"
											*ngIf="
												activity.status === 'inprogress'
											"
										>
											<button  *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) "
												class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
												(click)="
													setMarkAsCompleted(
														activity.id
													)
												"
											>
												<svg-icon
													icon="tick"
												></svg-icon>
												<span>Mark as complete</span>
											</button>
											<button  *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) "
												class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
												(click)="onEdit(activity.id)"
											>
												Edit activity
											</button>
										</div>
									</div>
								</div>
							</div>
						</mat-expansion-panel>
					</mat-accordion>
				</div>
				<div *ngIf="activityschedules.length === 0">
					<div class="empty-card">
						<div
							class="app-dm-positive-cards"
							[translate]="this.isLoaded ? 'as.noDataFound' : ''"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
