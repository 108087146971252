<div class="page-section">
    <div class="page-section-header">
        <h1>NPS Survey</h1>
    </div>
    <popup-loader *ngIf="isloaded"></popup-loader>
    <div class="page-section-content">
        <div class="form-wrapper">
            <form novalidate [formGroup]="npsSurveyForm" (ngSubmit)="doSave()">
                <div formArrayName="mainQuestion"
                    *ngFor="let qn of npsSurveyForm.get('mainQuestion')['controls']; let i = index">
                    <div [formGroupName]="i">
                        <div class="wt-scale-qn1">
                            <label>{{qn.value.comment}}</label>
                            <app-number-scale [scaleIndex]="i" [scaleOptionValues]="qn.value.options"
                                (onCompleted)="scaleSelectionCompleted($event)"></app-number-scale>
                            <mat-error  *ngIf="isSubmitted && !npsSUrveyFinalData[i]?.value">
                                Please select the range value
                            </mat-error> 
                        </div>

                        <div>
                            <div formArrayName="subQuestion"
                                *ngFor="let subQn of qn.get('subQuestion')['controls'];let j = index">
                                <div [formGroupName]="j">
                                    <mat-form-field *ngIf="subQn.value.show">
                                        <label for="fullName">{{subQn.value.comment}}</label>
                                        <textarea aria-label="fullName" matInput class="wt-mat-input" type="text" formControlName="answer"
                                            id="email"> </textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fixed-footer">
                    <button tabindex="0" class="btn-primary btn" >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>