<div id="notice" *ngIf="show">
	<div class="app-l-notice__wrap" *ngIf="user.noticeOn">
		<div class="app-l-notice__icon">
			<svg-icon icon="warning-circle" class="w-100 h-100"></svg-icon>
		</div>
		<div class="app-l-notice__content">
			<h3 class="app-c-heading--h3"><span translate>blocked.trialVersion</span>!</h3>
			<span class="app-l-notice__date">{{user.trialDays}} days left</span>
			<p translate class="app-c-body-text--12">blocked.noticeDescription</p>
			<a class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm" routerLink="/app/my-settings" translate
				>blocked.noticeline1</a
			>
		</div>
		<div class="app-l-notice__close">
			<a
				tabindex="0"
				(click)="onSetClose()"
				(keypress)="onSetClose()"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14"
				aria-label="Close notice"
			>
				<svg-icon icon="close" class="w-100 h-100"></svg-icon>
			</a>
		</div>
	</div>
	<div *ngIf="user.primaryOrganization && !user.primaryOrganization.active">
		<h2 translate>blocked.organizationDisabledTitle</h2>
		<p translate>blocked.organizationDisabledDescription.0</p>
		<p>
			<span translate>blocked.organizationDisabledDescription.1</span> <a routerLink="/app/my-settings">settings</a>
			<span translate>blocked.organizationDisabledDescription.2</span>
		</p>
	</div>
</div>
