<div class="app-works-main-container" *ngIf="showEmptyHeader">
    <div class="app-works-header">
        <img
            src="../../../assets/img/welltrack-logos/well-track-white-logo.svg"
            alt="welltrack-logo" />
        <div class="app-works-header-bg">
            <svg-icon icon="app-works-header-bg"></svg-icon>
        </div>
    </div>
</div>
<div class="app-l-page-wrap">
    <!-- <page-loader *ngIf="!isloaded"></page-loader> -->
    <div class="app-l-page__navigation" *ngIf="isloaded">
        <div class="app-l-page__previous">
            <button
                class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
                aria-label="Previous page"
                (click)="goBack()"
                (keypress)="goBack()">
                <svg-icon
                    icon="arrow-left"
                    class="w-100 h-100"
                    alt="arrow-left"></svg-icon>
            </button>
        </div>
        <div class="app-l-page__breadcrumbs">
            <ul>
                <li
                    translate
                    class="app-l-current-page"
                    *ngIf="type === 'dass'">
                    das.result.title
                </li>
                <li
                    translate
                    class="app-l-current-page"
                    *ngIf="type !== 'dass'">
                    {{'assessments.'+type+'.title' | translate: {value: type} }}
                </li>
            </ul>
        </div>
    </div>
    <div *ngIf="isloaded">
        <div class="app-c-card" *ngFor="let assessmentresult of assessment">
            <div class="app-c-card__header">
                <div class="app-l-card__h-left">
                    <h2
                        class="app-l-card__title"
                        translate
                        *ngIf="type === 'dass'">
                        das.result.title
                    </h2>
                    <h2 class="app-l-card__title" *ngIf="type != 'dass'">
                        {{'assessments.'+type+'.title' | translate: {value:
                        type} }}
                    </h2>
                    <div *ngIf="user.userType === 'user'">
                        <h2
                            class="recent-tabs-disclaimer"
                            translate
                            *ngIf="!showConnect()">
                            <span
                                [innerHtml]="'dynamicAssessment.disclaimerDesc1.0' | translate"></span>
                            <emergency-contact
                                [showAsButton]="true"
                                [isTemplateView]="true"
                                [templateType]="'assessmentDisclaimer'"
                                [cssClass]="'assessmentDisclaimer'"
                                [user]="user"></emergency-contact>
                            <span
                                [innerHtml]="'dynamicAssessment.disclaimerDesc1.1' | translate"></span>
                        </h2>
                        <h2
                            class="recent-tabs-disclaimer"
                            *ngIf="showConnect()"
                            translate>
                            <span
                                [innerHtml]="'dynamicAssessment.disclaimerDesc2.0' | translate"></span>
                            <emergency-contact
                                [showAsButton]="true"
                                [isTemplateView]="true"
                                [templateType]="'assessmentDisclaimer'"
                                [cssClass]="'assessmentDisclaimer'"
                                [user]="user"></emergency-contact>
                            <span
                                [innerHtml]="'dynamicAssessment.disclaimerDesc2.1' | translate"></span>
                            <a
                                class="course-link-title"
                                href="https://welltrack-connect.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                translate
                                >theory.welltrackConnect</a
                            >
                        </h2>
                    </div>
                </div>
                <div class="app-l-card__h-right">
					<div>
						<button
						tabindex="0"
						(click)="exportAssessemnt()"
						class="app-c-btn app-c-btn--primary app-c-btn--curve">
						<svg-icon icon="export"></svg-icon>
						<span translate>das.downloadPDF</span>
					</button>
					</div>
                    <div class="app-l-ac__time">
                        <svg-icon icon="calendar" alt="calendar"></svg-icon>
                        <div class="app-l-ac__time-print">
                            {{assessmentresult.created | date:'MM-dd-yyyy'}}
                            <span
                                >{{assessmentresult.created | date:'h:mm
                                a'}}</span
                            >
                        </div>
                    </div>
                    <div class="app-l-ac__help">
                        <ng-container>
                            <a
                                tabindex="0"
                                *ngIf="(gethelpbuttondas || gethelpbutton)  && contactGroupTelephone">
                                <button
                                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                    (click)="onCallContact($event, organization.contactGroup)"
                                    (keypress)="onCallContact($event, organization.contactGroup)">
                                    <svg-icon
                                        icon="get-help"
                                        alt="get-help"></svg-icon>
                                    <span translate>
                                        {{ 'contact.getHelpNow' | translate }}
                                    </span>
                                </button>
                            </a>

                            <a
                                tabindex="0"
                                *ngIf="(gethelpbuttondas || gethelpbutton) && showOther">
                                <button
                                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                    (click)="onHotline($event)"
                                    (keypress)="onHotline($event)">
                                    <svg-icon
                                        icon="get-help"
                                        alt="get-help"></svg-icon>
                                    <span>
                                        {{ 'contact.getHelpNow' | translate }}
                                    </span>
                                </button>
                            </a>
                        </ng-container>
                    </div>
                    <button
                        *ngIf="previousUrl == 'listing'"
                        tabindex="0"
                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                        (click)="delete()"
                        (keypress)="delete()">
                        <svg-icon
                            icon="trash"
                            class="w-100 h-100"
                            alt="trash"></svg-icon>
                    </button>
                </div>
            </div>
            <div class="app-c-card__body">
                <div class="app-l-assessment__blocks" *ngIf="type === 'dass'">
                    <ng-container
                        *ngFor="let resultItem of assessmentResult"
                        [ngTemplateOutlet]="dasAssessmentResultCard"
                        [ngTemplateOutletContext]="{value: resultItem}">
                    </ng-container>
                </div>
                <div class="app-l-assessment__block" *ngIf="type != 'dass'">
                    <ng-container
                        *ngFor="let resultItem of assessmentResult"
                        [ngTemplateOutlet]="AssessmentResultCard"
                        [ngTemplateOutletContext]="{value: resultItem}">
                    </ng-container>
                </div>
            </div>
            <div
                class="app-c-card__footer"
                *ngIf="filteredResourceSet(assessmentresult.resourceSet).length == 0">
                <button
                    class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    *ngIf="finish && assessment"
                    (click)="close()"
                    (keypress)="close()"
                    translate>
                    das.close
                </button>
            </div>
        </div>
        <div
            class="app-l-assessment__res-block"
            *ngIf="assessment && filteredResourceSet(assessment[0].resourceSet).length != 0">
            <div
                class="app-c-card app-l-assessment__resource-card"
                *ngFor="let assessmentresult of assessment">
                <div
                    class="app-c-card__header"
                    *ngIf="assessmentresult.resourceSet.length != 0">
                    <div class="app-l-card__h-left">
                        <h2 class="app-l-card__title" translate>
                            resources.recommendedResources
                        </h2>
                    </div>
                </div>
                <div class="app-l-assessment__resource-options">
                    <div class="app-l-assessment__resource-block">
                        <button
                            class="app-c-btn app-c-btn--tertiary"
                            [ngClass]="{ 'active': 'all' == selectedID  }"
                            (click)="showResourceSetGroup(assessmentresult,'true')"
                            (keypress)="showResourceSetGroup(assessmentresult,'true')"
                            translate>
                            resources.all
                        </button>
                    </div>
                    <div
                        class="app-l-assessment__resource-block"
                        *ngFor="let resource of assessmentresult.resourceSet | filter: 'language': currentLanguage">
                        <button
                            class="app-c-btn app-c-btn--tertiary"
                            [ngClass]="{ 'active': resource.id == selectedID  }"
                            (click)="showResourceSetGroup(resource,'false')"
                            (keypress)="showResourceSetGroup(resource,'false')">
                            {{resource.title}}
                        </button>
                    </div>
                </div>
                <div class="app-c-card__body">
                    <div *ngIf="!showall">
                        <div class="app-l-rr__items">
                            <ng-container
                                *ngFor="let resultItem of resourcegroup; let i = index;"
                                [ngTemplateOutlet]="dasAssessmentResources"
                                [ngTemplateOutletContext]="{value: resultItem}">
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="showall">
                        <div class="app-l-rr__wrap">
                            <ng-container
                                *ngFor="let resultItem of  assessmentresult.resourceSet  | filter: 'language': currentLanguage"
                                [ngTemplateOutlet]="dasAssessmentResourcesAll"
                                [ngTemplateOutletContext]="{value: resultItem}">
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div
                    class="app-c-card__footer"
                    [ngClass]="{'resources':assessment && assessment[0].resourceSet.length == 0}">
                    <button
                        class="app-c-btn app-c-btn--primary app-c-btn--curve"
                        *ngIf="finish && assessment"
                        (click)="close()"
                        (keypress)="close()"
                        translate>
                        das.close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-template let-resultData="value" #dasAssessmentResultCard>
        <div class="app-l-assessment__wrap" *ngIf="showdas">
            <div class="app-l-assessment__b-header">
                <div class="app-l-assessment__bh-left">
                    <h3 class="app-l-assessment__bh-title" translate>
                        {{resultData.title}}
                    </h3>
                </div>
                <div class="app-l-assessment__bh-right">
                    <ng-container
                        [ngTemplateOutlet]="assessmentlevelIndicationImg"
                        [ngTemplateOutletContext]="{level: resultData.level, data: resultData}">
                    </ng-container>
                    <!-- {{resultData['label']}} -->
                </div>
            </div>
            <div class="app-l-assessment__b-body">
                <p
                    [innerHTML]="resultData['levelText'] | translate : {value: resultData['label']} "></p>
                <p [innerHtml]="resultData['content1'] | translate"></p>
            </div>
            <div class="app-l-assessment__b-footer">
                <h5 translate>dynamicAssessment.dass.result.nextStep</h5>
                <button
                    class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    tabindex="0"
                    (click)="onLink(resultData['courseLink'])"
                    (keypress)="onLink(resultData['courseLink'])"
                    translate>
                    {{resultData['courseTitle']}}
                </button>
                <div
                    class="app-l-assessment__sub-info"
                    *ngIf="resultData['content2']">
                    <p [innerHtml]="resultData['content2'] | translate"></p>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template let-resultData="value" #AssessmentResultCard>
        <div class="app-l-assessment__blocks">
            <div class="app-l-assessment__wrap">
                <div class="app-l-assessment__b-header">
                    <div class="app-l-assessment__bh-left">
                        <h3 class="app-l-assessment__bh-title" translate>
                            {{resultData.title}}
                        </h3>
                    </div>
                    <div class="app-l-assessment__bh-right">
                        <ng-container
                            [ngTemplateOutlet]="assessmentlevelIndicationImg"
                            [ngTemplateOutletContext]="{level: resultData.levelKey, data: resultData}">
                        </ng-container>
                        <!-- {{resultData['label'] | translate}} -->
                    </div>
                </div>
                <div class="app-l-assessment__b-body">
                    <p
                        [innerHTML]="resultData['levelText'] | translate : {value: resultData['label']} "></p>
                    <p [innerHtml]="resultData['content1'] | translate"></p>
                    <p
                        *ngIf="resultData['content2']"
                        [innerHtml]="resultData['content2'] | translate"></p>

                    <p
                        *ngIf="resultData['content3']"
                        [innerHtml]="resultData['content3'] | translate"></p>
                </div>
                <div
                    class="app-l-assessment__b-footer"
                    *ngIf="resultData['courseLink']">
                    <h5 translate>dynamicAssessment.dass.result.nextStep</h5>
                    <button
                        class="app-c-btn app-c-btn--primary app-c-btn--curve"
                        tabindex="0"
                        (click)="onLink(resultData['courseLink'])"
                        (keypress)="onLink(resultData['courseLink'])"
                        translate>
                        {{resultData['courseTitle']}}
                    </button>
                </div>
                <div
                    class="app-l-assessment__b-footer"
                    *ngIf="resultData['showEmergencyContact'] || resultData['showDetailedEmergencyContact']">
                    <ng-container
                        [ngTemplateOutlet]="assessmentEmergencyContact"
                        [ngTemplateOutletContext]="{data: resultData}">
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template let-resultData="value" #dasAssessmentResources>
        <div class="app-l-rr__item-block">
            <h6 class="app-l-rr__title">{{resultData.title}}</h6>
            <p *ngIf="resultData.address" translate>
                resources.address <span>{{resultData.address}}</span>
            </p>
            <div class="app-l-rr__cnt" *ngIf="resultData.contact">
                <p translate>
                    resources.phoneNumber
                    <a href="tel:{{resultData.contact}}"
                        >{{resultData.contact}}</a
                    >
                </p>
            </div>
            <div class="app-l-rr__cnt" *ngIf="resultData.alternateContact">
                <p translate>
                    resources.alternateNumber
                    <a href="tel:{{resultData.alternateContact}}"
                        >{{resultData.alternateContact}}</a
                    >
                </p>
            </div>
            <div class="app-l-rr__cnt" *ngIf="resultData.website">
                <p translate>
                    resources.website
                    <a
                        (click)="onResourceClick(resultData)"
                        (keypress)="onResourceClick(resultData)"
                        >{{resultData.websiteTitle ? resultData.websiteTitle :
                        resultData.website }}</a
                    >
                </p>
            </div>
            <div class="app-l-rr__cnt-resource" *ngIf="resultData.internal">
                <p>
                    <button
                        tabindex="0"
                        (click)="onResourceInternalClick(resultData)"
                        (keypress)="onResourceInternalClick(resultData)"
                        class="app-c-btn app-c-btn--sm app-c-btn--secondary app-c-btn--curve"
                        translate>
                        resources.goToResource
                    </button>
                </p>
            </div>
        </div>
    </ng-template>

    <ng-template let-resultData="value" #dasAssessmentResourcesAll>
        <div class="app-l-rr__block">
            <h4 class="app-l-rr__title">{{resultData.title}}</h4>
            <div class="app-l-rr__items">
                <div
                    class="app-l-rr__item-block"
                    *ngFor="let contact of resultData.resourcesetGroup; let i = index; ">
                    <h6 class="app-l-rr__title">{{contact.title}}</h6>

                    <p *ngIf="contact.address" translate>
                        resources.address
                        <span>{{contact.address}}</span>
                    </p>
                    <div class="app-l-rr__cnt" *ngIf="contact.contact">
                        <p translate>
                            resources.phoneNumber
                            <a href="tel:{{contact.contact}}"
                                >{{contact.contact}}</a
                            >
                        </p>
                    </div>
                    <div class="app-l-rr__cnt" *ngIf="contact.alternateContact">
                        <p translate>
                            resources.alternateNumber
                            <a href="tel:{{contact.alternateContact}}"
                                >{{contact.alternateContact}}</a
                            >
                        </p>
                    </div>
                    <div class="app-l-rr__cnt" *ngIf="contact.website">
                        <p translate>
                            resources.website
                            <a
                                (click)="onResourceClick(contact)"
                                (keypress)="onResourceClick(contact)"
                                >{{contact.websiteTitle ? contact.websiteTitle :
                                contact.website }}</a
                            >
                        </p>
                    </div>
                    <div
                        class="app-l-rr__cnt-resource"
                        *ngIf="contact.internal">
                        <!-- Resources : -->
                        <button
                            tabindex="0"
                            (click)="onResourceInternalClick(contact)"
                            (keypress)="onResourceInternalClick(contact)"
                            class="app-c-btn app-c-btn--sm app-c-btn--secondary app-c-btn--curve"
                            translate>
                            resources.goToResources
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template
        let-level="level"
        let-data="data"
        #assessmentlevelIndicationImg>
        <div
            *ngIf="level == 4"
            class="app-l-assessment__badge app-l-badge__very-high">
            {{data['label'] | translate}}
        </div>
        <div
            *ngIf="level == 3"
            class="app-l-assessment__badge app-l-badge__high">
            {{data['label'] | translate}}
        </div>
        <div
            *ngIf="level == 2"
            class="app-l-assessment__badge app-l-badge__moderate">
            {{data['label'] | translate}}
        </div>
        <div
            *ngIf="level == 1"
            class="app-l-assessment__badge app-l-badge__mild">
            {{data['label'] | translate}}
        </div>
        <div
            *ngIf="level == 0"
            class="app-l-assessment__badge app-l-badge__good">
            {{data['label'] | translate}}
        </div>
        <!-- <img *ngIf="level == 4" src="./assets/img/assesment/icon-das-assessment-alert-badge-veryhigh.svg" />
		<img *ngIf="level == 3" src="./assets/img/assesment/icon-das-assessment-alert-badge-high.svg" />

		<img *ngIf="level == 2" src="./assets/img/assesment/icon-das-assessment-alert-badge-moderate.svg" />
		<img *ngIf="level == 1" src="./assets/img/assesment/icon-das-assessment-alert-badge-mild.svg" />
		<img *ngIf="level == 0" src="./assets/img/assesment/icon-das-assessment-alert-badge-good.svg" /> -->
    </ng-template>

    <ng-template let-title="title" #dasResourceItemImg>
        <div class="image-container" [ngSwitch]="true">
            <img
                *ngSwitchCase="title.toLowerCase().includes('academic')"
                src="./assets/img/assesment/resource-academic-icon.svg" />
            <img
                *ngSwitchCase="title.toLowerCase().includes('financial')"
                src="./assets/img/assesment/resource-financial-icon.svg" />
            <img
                *ngSwitchCase="title.toLowerCase().includes('medical') || title.toLowerCase().includes('emergency')"
                src="./assets/img/assesment/resource-medical-services-icon.svg" />
            <img
                *ngSwitchCase="title.toLowerCase().includes('safety') || title.toLowerCase().includes('security')"
                src="./assets/img/assesment/resource-safety-security-icon.svg" />
            <img
                *ngSwitchCase="title.toLowerCase().includes('substance') || title.toLowerCase().includes('addiction')"
                src="./assets/img/assesment/resource-substance-use-addiction-icon.svg" />
            <img
                *ngSwitchCase="title.toLowerCase().includes('sexual')"
                src="./assets/img/assesment/resource-sexual-violence-icon.svg" />
            <img
                *ngSwitchDefault
                src="./assets/img/assesment/resource-others-icon.svg" />
        </div>
    </ng-template>

    <ng-template let-resultData="data" #assessmentEmergencyContact>
        <div
            class="app-l-assessment__sub-info"
            *ngIf="resultData['showDetailedEmergencyContact'] && resultData['showEmergencyContact']">
            <h5 translate>assessments.emergencyContact.need</h5>
            <p translate>assessments.emergencyContact.profesionals</p>

            <ng-container
                *ngIf="!resultData['showDetailedEmergencyContact'] || resultData['showEmergencyContact']">
                <button
                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                    *ngIf="gethelpbutton  && contactGroupTelephone"
                    (click)="onCallContact($event, organization.contactGroup)"
                    (keypress)="onCallContact($event, organization.contactGroup)">
                    <svg-icon icon="get-help" alt="get-help"></svg-icon>
                    <span translate>{{'contact.getHelpNow' }}</span>
                </button>

                <button
                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                    (click)="onHotline($event)"
                    *ngIf="gethelpbutton && showOther"
                    translate>
                    <svg-icon icon="get-help" alt="get-help"></svg-icon>
                    <span translate>{{'contact.getHelpNow' }}</span>
                </button>
            </ng-container>
        </div>
    </ng-template>
</div>
