import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { TranslateService } from '@ngx-translate/core';
import { Assessment } from '../../models/assessment';
import { User } from '../../models/user';
import { UserService } from '../../lib/user.service';
import { ModalService } from '../../lib/modal.service';
import { GraphService } from '../../lib/graph.service';
import { Chart, ChartData, ChartConfiguration } from 'chart.js';
import { ASSESSMENT_TYPES_CONFIG } from '../../models/assessment';
@Component({
    selector: 'page-assessment-listing',
    templateUrl: 'das-assessment-listing.component.html',
    styleUrls: ['./das-assessment-listing.component.scss'],
})
export class DasAssessmentListingPage implements OnInit {
    title: string;
    back: string;
    user: User;
    assessmentList = ASSESSMENT_TYPES_CONFIG.ASSESSMENT_TYPE_LIST;
    isLoaded: boolean = false;
    popup: any;
    backLink: string;
    assessmentConfig = [];
    listingAssessments = [];
    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private translate: TranslateService,
        private userService: UserService,
        private modalService: ModalService,
        private graphService: GraphService
    ) {
        this.user = this.userService.getUser();
        if (
            this.user.primaryOrganization &&
            this.user.primaryOrganization.settings['assessment'] ===
                'resilience'
        ) {
            this.backLink == '/app/assessments';
        } else {
            this.backLink == '/app';
        }
    }

    ngOnInit() {
        this.translate.stream('assessmentTypeListing').subscribe((res: any) => {
            this.title = res.tabTitle;
        });
        this.setAssessmentConfig();
    }
    setAssessmentConfig() {
        if (this.user.primaryOrganization && this.user.primaryOrganization.id) {
            this.isLoaded = true;
            this.assessmentConfig =
                this.user.primaryOrganization.assessmentConfig;
            this.setConfigList();
        } else {
            this.getDefaultAssessmentList();
        }
    }

    getDefaultAssessmentList() {
        this.isLoaded = false;
        this.api.get('assessments/default').subscribe(
            (result: any) => {
                let assessmentList = [];
                if (result) {
                    result?.forEach((assessment: any) => {
                        assessmentList.push({
                            title:
                                ASSESSMENT_TYPES_CONFIG.ASSESSMENT_TYPE_LIST[
                                    assessment.AssessmentKey
                                ]?.title || assessment.Comment,
                            body:
                                ASSESSMENT_TYPES_CONFIG.ASSESSMENT_TYPE_LIST[
                                    assessment.AssessmentKey
                                ]?.description || '',
                            id: assessment.ID,
                            quizID: assessment.ID,
                            name: assessment.Comment,
                            enabled: assessment.DefaultAssessment,
                            default: assessment.DefaultAssessment,
                            key:
                                assessment.AssessmentKey == 'das'
                                    ? 'dass'
                                    : assessment.AssessmentKey,
                        });
                    });
                    this.assessmentConfig = assessmentList;
                    this.setConfigList();
                    this.isLoaded = true;
                }
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment default list. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }

    setConfigList() {
        this.assessmentList.forEach((defaultAssessment: any) => {
            const assessment = this.assessmentConfig.find(
                item => item.key === defaultAssessment.key
            );
            if (assessment && assessment.enabled) {
                defaultAssessment.enabled = assessment.enabled;
                this.listingAssessments.push(defaultAssessment);
            }
        });
    }
}
