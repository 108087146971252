<!-- <page-loader *ngIf="isLoaded"></page-loader> -->
<div id="page-user-listing" class="page-section" >
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
             Permission
            </ng-template>

            <div class="page-section">
                <div class="page-section-header">
                    <div class="page-section-buttons" >
                        <a tabindex="0" (click)="createPermission()" (keydown.enter)="createPermission()"
                            class="trigger-modal inviteshare btn btn-primary btn-with-icon pull-right" translate>
                            <img alt="Invite to Welltrack"
                                src="./assets/img/icons-svg/user-icon.svg">permissions.createPermission</a>
                    </div>
                </div>
                <div class="page-section-content">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Permission Name</th>
                                <th  style="width:14%;" *ngFor="let role of roles">
                                    <mat-checkbox [checked]="role.isAllChecked" (change)="selectAllPermission($event,role)">
                                    </mat-checkbox>
                                    {{role.Name}}
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr *ngFor="let allPerm of allPermission;let i = index">
                                <td class="strong">{{permission[i].Name}}</td>
                                <td *ngFor="let value of allPerm ">
                                    <mat-checkbox [checked]="value.PermissionValue" (change)="selectPermission($event,value)">
                                    </mat-checkbox>
                                </td> 
                            </tr>
                            <div>
                                <button  class="btn-primary btn" type="button" (click)="savePermission()">Save</button>
                            </div>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
             Config
            </ng-template>
            <div class="page-section">
                <app-config-listing></app-config-listing>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
