import {
	Component,
	OnInit,
	Input,
	ViewEncapsulation,
	ViewChild,
	ChangeDetectorRef,
	Output,
	EventEmitter,
} from "@angular/core";
import { Chart, ChartData, ChartConfiguration } from "chart.js";
import * as _ from "lodash";

import { ApiService } from "../../lib/api.service";
import { User } from "../../models/user";
import { UserService } from "../../lib/user.service";
import { Moodcheck } from "../../models/moodcheck";
import { LogService } from "../../lib/log.service";
import { ModalService } from "../../lib/modal.service";
import { AnalyticEvent } from "app/lib/analytic-event";
import * as moment from "moment";
import { DailyMoodcheckViewComponent } from "../../components/daily-moodcheck-view/daily-moodcheck-view.component";
import { MonthlyMoodcheckViewComponent } from "../../components/monthly-moodcheck-view/monthly-moodcheck-view.component";
import { WeeklyMoodcheckViewComponent } from "../../components/weekly-moodcheck-view/weekly-moodcheck-view.component";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { OverallViewMoodcheckComponent } from "../../components/overall-view-moodcheck/overall-view-moodcheck.component";
import { DateService } from "app/lib/url.service";
import { ActivityScheduler } from "../../models/activity-scheduler";
import { Assessment } from "app/models/assessment";
import { TranslateService } from "@ngx-translate/core";
import { AssessmentService } from "app/lib/assessment.service";
import { CrisisHotline, LocationService } from "app/lib/location.service";
import { StorageService } from "app/lib/storage.service";
import { Organization } from "app/models/organization";
import { Router } from "@angular/router";
import { ASSESSMENT_TYPES_CONFIG_HOME } from "../../models/assessment";
import { RoomForPositiveViewComponent } from "app/modules/practice/room-for-positive-view/room-for-positive-view.component";
import { RoomForPositiveCreateComponent } from "app/modules/practice/room-for-positive-create/room-for-positive-create.component";
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ActivitySchedulerDetails } from "app/modules/practice/activity-scheduler-details/activity-scheduler-details";
import { ActionPlanDashboard } from "app/models/action-plan";
@Component({
	selector: "app-home-dashboard",
	templateUrl: "./home-dashboard.component.html",
	styleUrls: ["./home-dashboard.component.scss"],
	providers: [DatePipe]
})
export class HomeDashboardComponent implements OnInit {
	@ViewChild("dasAssessmentChart") canvasRef;
	assessmentList = ASSESSMENT_TYPES_CONFIG_HOME.ASSESSMENT_TYPE_LIST;
	selectedMode: any;
	selectedMood: any;
	selectedDate: Date = new Date();
	changeDate: any;
	currentDate: Date;
	@Input() user: User;
	moodchecks: Moodcheck[];
	rfpEntryList: Array<object> = [];
	activityschedules: ActivityScheduler[];
	isEmpty: boolean;
	today: Date = new Date();
	@ViewChild("moodcheckDailyViewComp")
	moodcheckDailyViewComp: DailyMoodcheckViewComponent;
	@ViewChild("moodcheckWeeklyViewComp")
	moodcheckWeeklyViewComp: WeeklyMoodcheckViewComponent;
	@ViewChild("moodcheckMonthlyViewComp")
	moodcheckMonthlyViewComp: MonthlyMoodcheckViewComponent;
	@ViewChild("moodchecOverallViewComp")
	moodchecOverallViewComp: OverallViewMoodcheckComponent;
	@Output() selectedDateChange: EventEmitter<Date> = new EventEmitter<Date>();
	rfptab: string = "proud";
	dasAssessmentChart: Chart;
	chartData: ChartData;
	dateFilter: any = 30;
	dateFilterTitle: any = "last30days";
	activeLink = 'last30days';
	typeAssessment: string;
	assessments: Assessment[];
	selectedChartLegend = "all";
	hotline: CrisisHotline;
	contactGroupTelephone: string;
	organization: Organization;
	showOther: boolean;
	dialing: boolean;
	popupText: any;
	questionIndex: number = 0;
	emergencyContact: string;
	recommendedcourses = [];
	dateFilterOptions = [
		{
			key: "last30days",
			value: 30,
			isSelected: true,
		},
		{
			key: "last3Months",
			value: 90,
			isSelected: false,
		},
		{
			key: "last6Months",
			value: 180,
			isSelected: false,
		},
	];
	gethelpbuttondas: boolean = false;

	actionPlanLoaded: boolean = false;
	isRcCourseLoaded: boolean = false;
	userActionPlans = [];
	firstName: string;

	constructor(
		private api: ApiService,
		private log: LogService,
		private storage: StorageService,
		private dateservice: DateService,
		private modalService: ModalService,
		private router: Router,
		private locationService: LocationService,
		private translate: TranslateService,
		private assessmentService: AssessmentService,
		private userService: UserService,
		private datePipe: DatePipe
	) {
		this.currentDate = new Date();
		this.user = this.userService.getUser();
		this.firstName = this.user.firstName ? this.user.firstName : this.user.fullName;
	}

	ngOnInit(): void {
		this.getRecommendedCourses();
		this.getUserActionPlans();
		this.selectedMode = "summary";
		this.selectedMood = "Weekly";
		this.dateservice.getSelectedDate().subscribe((date) => {
			this.selectedDate = date;
		});
		this.refreshContent();
		this.getRoomForPositiveEntryList(
			moment().format("MM"),
			moment().format("YYYY")
		);
		this.getActivity();
		this.typeAssessment = "dass";
		this.getChartInit(this.dateFilter);
		this.log.event(AnalyticEvent.event.dashboard);

		this.translate.stream("lang").subscribe((res: any) => {
			if (res === "en") {
				this.getChartInit(this.dateFilter);
			} else {
				this.getChartInit(this.dateFilter);
			}
		});

		this.getAssessmentList();

		this.user = this.userService.getUser();

		this.log.event(AnalyticEvent.event.hotlineCalled);

		this.translate.get("contact").subscribe((res: any) => {
			this.popupText = res;
		});
		this.locationService.getLocation().subscribe((data: any) => {
			this.log.debug(data);
			this.hotline = this.locationService.getHelpline(
				data.country,
				data.regionName
			);
		});

		let orgId;
		if (this.storage.get("orgselect")) {
			orgId = this.storage.get("orgselect");
		} else {
			if (this.user.primaryOrganization) {
				orgId = this.user.primaryOrganization.id;
			}
		}

		if (orgId) {
			this.api.get("organizations/" + orgId).subscribe(
				(result: any) => {
					this.organization = new Organization(result.data);
					if (this.organization.contactGroup.telephone) {
						this.contactGroupTelephone =
							this.organization.contactGroup.telephone;
					} else {
						this.showOther = true;
					}
				},
				(error: any) => {
					this.log.error(
						"Error getting organization. " + error.message
					);
				},
				() => {}
			);
		}
	}

	openDatePicker(picker: MatDatepicker<Date>) {
		picker.open();
	  }

	getAssessmentList() {
		this.api.get("assessments").subscribe(
			(result: any) => {
				let assessmentResult = Assessment.initializeArray(result.data);
				this.assessments = assessmentResult;
				this.showHelpNow(this.assessments);
			},
			(error: any) => {
				this.log.error(
					"Error getting assessment questions. " + error.message
				);
			}
		);
	}

	showHelpNow(assessment) {
		if (this.typeAssessment === "dass") {
			this.gethelpbuttondas = assessment.some(
				(assess) =>
					assess.depressionlevel >= 3 ||
					assess.anxietylevel >= 3 ||
					assess.stresslevel >= 3
			);
		}
	}

	onHotline(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();

			this.modalService
				.showConfirmation(
					this.popupText.popTitle,
					this.popupText.popUpHotline
				)
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						this.log.event(
							AnalyticEvent.event.hotlineCalled
						);
						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent("click"));
					}
				});
		}
	}

	onCallContact(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();
			const hotLineText =
				this.user.primaryOrganization &&
				this.user.primaryOrganization.settings["assessment"] ===
					"resilience"
					? this.popupText.popupEmergencyTextVeteran
					: this.organization.contactGroup.getHelpDescription
					? this.organization.contactGroup.getHelpDescription
					: this.popupText.popupEmergencyHotline;

			this.modalService
				.showConfirmation(this.popupText.popTitle, hotLineText)
				.afterClosed()
				.subscribe((result) => {
					if (result) {
						if (this.contactGroupTelephone) {
							window.location.href =
								"tel:" + this.contactGroupTelephone;
						}
						this.log.event(
							AnalyticEvent.event.protocallNumberCalled
						);

						this.api
							.post("analytics/supportlineclick", {
								phoneNumber: this.contactGroupTelephone,
								contactGroupId:
									this.organization.contactGroup.id,
							})
							.subscribe(
								(result: any) => {
									this.log.debug("Activity logged");
								},
								(error: any) => {
									this.log.debug(
										"Something went wrong with the activity logger."
									);
								}
							);

						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent("click"));
					}
				});
		}
	}

	onSelectFilterData(selectedItem: any) {
		this.dateFilter = selectedItem.value;
		this.dateFilterTitle = selectedItem.key;
		this.activeLink = selectedItem.key;
		this.getChartInit(this.dateFilter);
	}

	getRoomForPositiveEntryList(month: string, year: string) {
		this.api
			.get("roomforpositive?month=" + month + "&year=" + year)
			.subscribe(
				(result: any) => {
					this.rfpEntryList = result.slice(0, 3);
				},
				(error: any) => {
					this.log.error("Error getting entries. " + error.message);
					this.rfpEntryList.length = 0;
				}
			);
	}

	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.getActivity();
			});
	}

	selectLegend(item) {
		this.selectedChartLegend = item;
		this.updateChart();
	}

	getChartInit(datefilter: any) {
		const Url =
			this.typeAssessment === "dass"
				? "assessments/graph/" + datefilter
				: `assessments/graph/` +
				  datefilter +
				  `?type=${this.typeAssessment}`;
		this.api.get(Url).subscribe(
			(result: any) => {
				this.assessments = Assessment.initializeArray(result.data);
				setTimeout(() => {
					this.initDasAssessmentChart();
					this.updateChart();
				}, 3000);
			},
			(error: any) => {
				this.log.error(
					"Error getting assessment questions. " + error.message
				);
			}
		);
	}

	initDasAssessmentChart() {
		if (this.assessments) {
			let canvas = this.canvasRef.nativeElement;
			let parent = canvas.parentElement;
			canvas.width = parent.clientWidth;
			canvas.height = parent.clientHeight;
			let chartOptions = {
				layout: {
					padding: {
						left: 10,
						right: 10,
						top: 0,
						bottom: 0,
					},
				},
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: true,
								lineWidth: 0,
								zeroLineWidth: 1,
								zeroLineBorderDash: [5, 5],
								zeroLineBorderDashOffset: 0.0,
							},
							type: "time",
							time: {
								unit: "day" as const,
								//   displayFormats: {
								//     day: "MMM D",
								//   },
								min: moment(new Date())
									.subtract(this.dateFilter, "days")
									.startOf("day")
									.format(),
								max: moment(new Date()).format(),
								stepSize: 10,
							},
							ticks: {
								fontSize: 12,
								fontColor: '#6F747D',
								family: 'Roboto',
								callback: (value, index) =>
									this.getXAxisLabel(value),
							},
						},
					],
					yAxes: [
						{
							gridLines: {
								drawBorder: false,
								lineWidth: 1,
							},
							ticks: {
								fontSize: 12,
								fontColor: '#6F747D',
								family: 'Roboto',
								max: this.getMaxYaxisTicks(),
								min: -0.5,
								stepSize: 1,
								callback: (value, index) =>
									this.getTranslatedLabelName(value, index),
							},
						},
					],
				},
			};
			this.chartData = {};
			let ctx = canvas.getContext("2d");
			this.dasAssessmentChart = new Chart(ctx, {
				type: "line",
				data: this.chartData,
				options: chartOptions,
			});
		}
	}

	getTranslatedLabelName = (value: string, index: number) => {
		const translationKey =
			"dynamicAssessment." +
			this.typeAssessment +
			".resultCategory." +
			value;
		switch (this.typeAssessment) {
			case "dass":
			case "depression":
				return Number(value) >= 0 && Number(value) <= 4
					? this.translate.instant(translationKey)
					: " ";
			case "resilience":
			case "alcoholuse":
				return Number(value) >= 0 && Number(value) <= 2
					? this.translate.instant(translationKey)
					: " ";
			case "anxiety":
				return Number(value) >= 0 && Number(value) <= 3
					? this.translate.instant(translationKey)
					: " ";
		}
	};

	getXAxisLabel(value) {
		return moment(new Date(value)).format(
			this.dateFilter <= 30 ? "MMM D" : "MMM"
		);
	}

	getMaxYaxisTicks() {
		switch (this.typeAssessment) {
			case "dass":
				return 4.5;
			case "resilience":
				return 2.5;
			case "alcoholuse":
				return 2.5;
			case "anxiety":
				return 3.5;
			case "depression":
				return 4.5;
		}
	}

	getChartData() {
		let dataset: any = [];
		if (this.assessments) {
			switch (this.typeAssessment) {
				case "dass": {
					dataset = this.getDassAssessmentDataSet();
					break;
				}
			}

			return {
				labels: [],
				datasets: dataset,
			};
		} else {
			return {};
		}
	}

	getDassAssessmentDataSet() {
		if (this.assessments) {
			let depressionData = [];
			let anxityData = [];
			let stressData = [];
			let dataset: any = [];
			this.assessments.forEach((assessment: any) => {
				depressionData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.depressionlevel,
				});
				anxityData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.anxietylevel,
				});
				stressData.push({
					x: moment
						.parseZone(assessment.created)
						.format("YYYY-MM-DD"),
					y: assessment.stresslevel,
				});
			});

			(this.selectedChartLegend == "depression" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Depression",
					data: depressionData,
					pointRadius: 4,
					pointBackgroundColor: '#53AADC',
					pointBorderColor:'#53AADC',
					pointBorderWidth: 1,
					borderWidth: 1,
					fill: true,
					lineTension: 0,
					backgroundColor: this.generateGradient("rgb(83, 170, 220)"),
					backgroundImage: "./assets/img/welltrack-boost-circle.png",
					borderColor: "rgb(83, 170, 220)",
				});
			(this.selectedChartLegend == "anxity" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Anxiety",
					data: anxityData,
					pointRadius: 4,
					borderWidth: 1,
					pointBackgroundColor: '#ED7AE1',
					pointBorderColor:'#ED7AE1',
					pointBorderWidth: 1,
					fill: true,
					lineTension: 0,
					borderColor: "rgb(237, 122, 225)",
					backgroundColor: this.generateGradient("rgb(237, 122, 225)"),
				});
			(this.selectedChartLegend == "stress" ||
				this.selectedChartLegend == "all") &&
				dataset.push({
					label: "Stress",
					data: stressData,
					pointRadius: 4,
					borderWidth: 1,
					pointBackgroundColor: '#F0AA77',
					pointBorderColor:'#F0AA77',
					pointBorderWidth: 1,
					fill: true,
					lineTension: 0,
					borderColor: "rgb(240, 170, 119)",
					backgroundColor: this.generateGradient("rgb(240, 170, 119)"),
				});
			return dataset;
		}
	}

	private generateGradient(color: string): CanvasGradient {
		const ctx = this.canvasRef.nativeElement.getContext("2d");
		const gradient = ctx.createLinearGradient(0, 0, 0, 250);
		gradient.addColorStop(1, `rgba(${color.slice(4, -1)}, 0)`);
		gradient.addColorStop(0, `rgba(${color.slice(4, -1)}, 0.3)`);
		return gradient;
	}

	updateChart() {
		this.dasAssessmentChart.data = this.getChartData();
		this.dasAssessmentChart.options.scales.xAxes[0].time.unit =
			this.dateFilter <= 30 ? "day" : "month";
		this.dasAssessmentChart.options.scales.xAxes[0].time.min =
			this.dateFilter <= 30
				? moment(new Date())
						.subtract(this.dateFilter, "days")
						.startOf("day")
						.format()
				: moment(new Date())
						.subtract(this.dateFilter / 30, "months")
						.startOf("day")
						.format();

		this.dasAssessmentChart.options.scales.xAxes[0].time.stepSize =
			this.dateFilter <= 30 ? 10 : 1;
		this.dasAssessmentChart.update();
	}

	setrfptab(tab: string, index) {
		this.questionIndex = index;
		this.rfptab = tab;
		this.rfpEntryList = [...this.rfpEntryList];
	}

	getActivity() {
		this.activityschedules = [];
		this.api
			.get("practice/activityscheduler/listing", { UserID: this.user.id })
			.subscribe(
				(result: any) => {
					const firstFourItems = result.data.slice(0, 3);
					this.activityschedules =
						ActivityScheduler.initializeArray(firstFourItems);
					this.log.event(
						AnalyticEvent.event.activitySchedulerListingLoaded
					);
				},
				(error: any) => {
					this.log.error(
						"Error getting activity schedule. " + error.message
					);
				},
				() => {}
			);
	}

	complete(id) {
		this.modalService
			.showConfirmation(
				"Mark as complete",
				"Are you sure you want to mark this activity as completed?"
			)
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.api
						.post(
							`practice/activityscheduler/updatestatus/${id}?Status=complete`,
							""
						)
						.subscribe(
							(result: any) => {
								this.getActivity();
								this.log.event(
									AnalyticEvent.event.markAsCompleteActivity
								);
							},
							(error: any) => {
								this.log.error("Error in updating status. ");
							}
						);
				}
			});
	}

	onDateSelected(event: MatDatepickerInputEvent<Date>) {
		this.selectedDate = event.value;
	}

	setMoodPercentageMode(mode) {
		if (mode == "summary") {
			this.selectedMode = mode;
			this.log.event(AnalyticEvent.event.dashboardMcSummaryView);
		  } else if (mode == "breakdown") {
			this.selectedMode = mode;
			this.log.event(AnalyticEvent.event.dashboardMcBreakdownView);
		  }
	}

	setMoodMode(mode) {
		this.selectedMood = mode;
		this.refreshContent();
	}

	setCalendarDate() {
		this.changeDate = moment(this.selectedDate)
			.parseZone()
			.format("YYYY-MM-DD");
		this.refreshContent();
	}

	refreshContent() {
		if (!this.user) {
			return;
		}
		this.refreshGraphWithDate();
	}

	refreshGraphWithDate() {
		let from = moment()
			.add(-1, "months")
			.startOf("month")
			.format("YYYY-MM-DD");
		let to = moment().add(1, "months").endOf("month").format("YYYY-MM-DD");
		this.api
			.get("moodcheck/" + this.user.id + "?From=" + from + "&To=" + to)
			.subscribe(
				(result: any) => {
					this.moodchecks = Moodcheck.initializeArray(result.data);
					this.isEmpty = this.moodchecks.length > 0 ? false : true;
					let currentDate = moment.parseZone().format(this.changeDate);
					if (this.selectedMood === "Daily") {
						this.moodcheckDailyViewComp.filterDailyMoodcheckData(
							this.moodchecks,
							currentDate
						);
						this.moodcheckDailyViewComp.setCalendarDate(
							this.selectedDate
						);
					}
					if (this.selectedMood === "Weekly") {
						this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
							this.moodchecks,
							currentDate
						);
					}
					if (this.selectedMood === "Monthly") {
						this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
							this.moodchecks,
							currentDate
						);
					}
				},
				(error: any) => {
					this.moodchecks = [];
					this.moodcheckDailyViewComp.filterDailyMoodcheckData(
						this.moodchecks
					);
					this.moodcheckWeeklyViewComp.filterWeeklyMoodcheckData(
						this.moodchecks
					);
					this.moodcheckMonthlyViewComp.filterMonthlyMoodcheckData(
						this.moodchecks
					);
					this.log.error(
						"Error getting moodchecks. " + error.message
					);
				}
			);
	}

	getRecommendedCourses() {
		this.isRcCourseLoaded = false;
		this.api.get("recommendedseries").subscribe(
			(result: any) => {
				this.isRcCourseLoaded = true;
				let courseList = [];
				if (
					result &&
					result["depression"] &&
					result["depression"].length
				) {
					result["depression"][0]["cardType"] = "depression";
					courseList.push(result["depression"][0]);
				}
				if (result && result["anxiety"] && result["anxiety"].length) {
					result["anxiety"][0]["cardType"] = "anxiety";
					courseList.push(result["anxiety"][0]);
				}
				if (result && result["stress"] && result["stress"].length) {
					result["stress"][0]["cardType"] = "stress";
					courseList.push(result["stress"][0]);
				}
				this.recommendedcourses = courseList;
			},
			(error) => {
				this.isRcCourseLoaded = true;
			}
		);
	}

	goToCourse(course) {
		this.router.navigateByUrl("app/series-details/" + course.CourseKey);
	}

	createNewEntry(){
		this.modalService.showComponent(RoomForPositiveCreateComponent, { mode: "create",data:{}}).afterClosed().subscribe(result => {
		  this.dateFilter = moment().format();
		  this.getRoomForPositiveEntryList(moment().format("MM"), moment().format("YYYY"));
		});
	  }

	viewRfpEntry(data: any) {
		this.modalService.showComponent(RoomForPositiveViewComponent, { questionIndex: this.questionIndex, objdata: data}).afterClosed().subscribe(result => {
		  this.dateFilter = moment().format();
		  this.getRoomForPositiveEntryList(moment().format("MM"), moment().format("YYYY"));
		});
	  }


	formatDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
		return date.toLocaleDateString('en-US', options);
	  }

	  getStartOfWeek(date: Date): string {
		const startOfWeek = new Date(date);
		startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust for Sunday as the start of the week
		return this.datePipe.transform(startOfWeek, 'mediumDate');
	  }

	  getEndOfWeek(date: Date): string {
		const endOfWeek = new Date(date);
		endOfWeek.setDate(date.getDate() + (6 - date.getDay())); // Adjust for Saturday as the end of the week
		return this.datePipe.transform(endOfWeek, 'mediumDate');
	  }
	  getUserActionPlans() {
		this.actionPlanLoaded = false;
		this.api.get("mydashboardactionplans").subscribe(
		  (result: any) => {
			this.actionPlanLoaded = true;
			if (result.data) {
			  this.userActionPlans = ActionPlanDashboard.initializeArray(
				result.data,
				this.user.id
			  );
			}
		  },
		  (error) => {
			this.actionPlanLoaded = true;
			this.userActionPlans = [];
			this.log.error("Error getting action plan. " + error.message);
		  }
		);
	  }

	  goToActionPlan(type: string, actionPlan?: any) {
		if (type == "list") {
		  this.router.navigate(["/app/practice/action-plan"]);
		} else {
		  let url = "/app/practice/action-plan/create";
		  if (actionPlan) {
			url = url + "/" + actionPlan.id + "/" + actionPlan.questionId;
		  }
		  this.router.navigate([url]);
		}
	  }

	  focusNextItem(event: KeyboardEvent, currentIndex: number) {
		if (event.key === 'Tab') {
			event.preventDefault();
			const nextIndex = (currentIndex + 1) % this.assessmentList.length;

			if (nextIndex === 0) {
				const elementOutsideLoop = document.querySelector('.app-l-dsh__rfp a');
				if (elementOutsideLoop) {
					(elementOutsideLoop as HTMLElement).focus();
				}
			} else {
				const nextItemElement = document.querySelector('.app-l-ass__block:nth-child(' + (nextIndex + 1) + ') a');
				if (nextItemElement) {
					(nextItemElement as HTMLElement).focus();
				}
			}
		}
	}

	assessmentPath(assessmentslistKey) {
		this.router.navigate(["/app/assessment/" + assessmentslistKey +"/listing"])
	}

	goToAssessment() {
		this.router.navigate(["/app/assessments/dass/new"]);
	  }
}
