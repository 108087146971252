import { Component, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { StorageService } from '../../lib/storage.service';
import { AuthService } from '../../lib/auth.service';

import { NavigationEnd, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
	selector: 'page-results',
	templateUrl: 'results-modal.component.html',
	styleUrls: ['./results-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ResultsComponent {
	score: number;
	answers: any;
	correctAnswers: Array<Object> = [];
	wrongAnswers: Array<Object> = [];

	constructor(
		public storage: StorageService,
		public auth: AuthService,
		private router: Router,
		// @Inject(MAT_DIALOG_DATA) public data: any,
		// public dialogRef: MatDialogRef<ResultsComponent>,
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
			  const navigation = this.router.getCurrentNavigation();
			  if (navigation && navigation.extras && navigation.extras.state) {
				this.score = navigation.extras.state.score;
				this.answers = navigation.extras.state.answers;
				this.answers.forEach((answer: any) => {
					if (answer.value) {
					  this.correctAnswers.push(answer);
					} else {
					  answer.true = answer.true.replaceAll(
						"<p>",
						""
					  );
					  this.wrongAnswers.push(answer);
					}
				  });
			  }
			}
		  });
		//using closeClassName to pass data in and out of the modal. kind of a hack.
		// this.score = data.data;
	}

	onFinish() {
		//mark the tutorial as finished
		// this.dialogRef.close();
		this.router.navigate(['/app/practice']);
	}
}
