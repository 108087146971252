<div id="dashboard" class="page-section">
    <div class="page-section-header">
        <h2 translate>Dashboard</h2>
    </div>
    <div class="page-section-content">
        <p>Super admin dashboard is being built.</p>
        <p>Logins per organization</p>
        <p>Send rates/mail stats</p>
        <p>Problematic setups</p>
    </div>
</div>