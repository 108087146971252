<div class="form-modal">
    <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
        aria-label="Close course feedback form">
        <img src="./assets/img/icons-svg/close-icon.svg">
    </a>
    <div class="form-wrapper-80">
        <div class="title-wrapper">
            <h2 translate>theory.courses.feedback.title</h2>
        </div>
        <p translate>
            theory.courses.feedback.description
        </p>
    </div>


    <div class="form-wrapper">

        <p translate>theory.courses.feedback.question</p>

        <mat-form-field>
            <mat-select [(ngModel)]="helpful" aria-required="true">
                <mat-option value="5" translate>theory.courses.feedback.options.5</mat-option>
                <mat-option value="4" translate>theory.courses.feedback.options.4</mat-option>
                <mat-option value="3" translate>theory.courses.feedback.options.3</mat-option>
                <mat-option value="2" translate>theory.courses.feedback.options.2</mat-option>
                <mat-option value="1" translate>theory.courses.feedback.options.1</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div class="clearfix"></div>
    <div class="fixed-footer">
        <a tabindex="0" aria-label="Save" class="btn-primary btn-bold btn" (click)="doSave()"
            (keypress)="doSave()" translate>theory.courses.feedback.save</a>
    </div>
</div>