import { I } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ResultsComponent } from 'app/components/results-modal/results-modal.component';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { Assessment } from 'app/models/assessment';
import Debounce from 'debounce-decorator'
@Component({
  selector: 'app-assessment-view',
  templateUrl: './assessment-view.component.html',
  styleUrls: ['./assessment-view.component.scss']
})
export class AssessmentViewComponent implements OnInit {
  isLoaded = false;
  assessmentList=[];
  offset: number = 0;
  assessmentId:number;
  constructor(
		private translate: TranslateService,
		private formBuilder: FormBuilder,
		private api: ApiService,
		private log: LogService,
		private modalService: ModalService,
		private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.params.subscribe((params: Params) => {
        this.assessmentId = parseInt(params['id'], 10);
      });
    }

  ngOnInit(): void {
   this.isLoaded = true;
    this.getAssessmentDetails();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.assessmentList=[];
      this.getAssessmentDetails();
    });
  }


  // Assessment Details API
  

  @Debounce(500)
	getAssessmentDetails(){
    this.api.get('form/surveyresults/'+this.assessmentId,
    {
			Limit: 10,
			Offset:  this.assessmentList.length
		}).subscribe(
      (result: any) => {
        this.isLoaded = false;
        if(result.length > 0){
          this.assessmentList = this.assessmentList.concat(result);
        }
      },
      (error: any) => {
      this.log.error('Error getting assessment. ' + error.message);
      this.isLoaded = false;
    });
  }

  LoadMore(){
    this.getAssessmentDetails();
  }


	}



