<div id="challenge-details" *ngIf="isLoaded">
    <h3>{{challenge.name}}
        <span></span>
    </h3>
    <p class="duration">{{challenge.duration}}</p>
    <div class="row">
        <div class="content-graph mt" [ngClass]="challenge.showTeams ? 'col-md-6 col-sm-12' : 'col-md-12 col-sm-12'">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th translate>challenges.past.number</th>
                            <th>
                                <span translate>challenges.past.total</span>
                                <span>{{challenge.totalMoodchecks}}</span>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="chart-holder">
                <canvas id="challenge" class="chart"></canvas>
            </div>
            <div class="chart-footer">
                <span translate>challenges.past.totalScore</span>
            </div>
        </div>

        <div class="mt col-md-6 col-sm-12" *ngIf="challenge.showTeams">
            <div class="table-responsive">

                <table class="table">
                    <thead>
                        <tr>
                            <th translate>challenges.past.headers.team</th>
                            <th class="" translate>challenges.past.headers.avg</th>
                            <th class="" translate>challenges.past.headers.total</th>
                        </tr>
                    </thead>
                    <tbody class="no-border">

                        <tr *ngFor="let team of challenge.winningTeams">
                            <td class="strong">
                                <span>{{team.name}}</span>
                            </td>
                            <td class="strong ">
                                <span>{{team.avg | number : '1.0-0' }}</span>
                            </td>
                            <td class="strong ">{{team.score}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div #endOfForProgress class="challenge-footer">
        <h3 class="challenge-footer-header" translate>challenges.past.how</h3>
        <p class="challenge-footer-body" [innerHtml]="challenge.description"></p>
    </div>

</div>
