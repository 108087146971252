<div class="inset-tile">
    <div class="chart-header">
        <h2 translate>{{header}} 
            <!--
                <a tabindex="0" class="smallinfo" [tooltip]="info" [tooltipDisabled]="false" [tooltipAnimation]="true"
                tooltipPlacement="right">?</a>
            -->
        </h2>
        <h5 translate>{{subheader}}</h5>
    </div>

    <canvas id="barchart" class="chart" width="100%"></canvas>
    <div class="legend">
        <div *ngFor="let row of legend; let i = index" class="item">
            <i class="bullet" [style.background-color]="colors[i]"></i>{{row}}</div>
    </div>
</div>
<div class="clearfix"></div>