import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanListingPage implements OnInit {
  user: User;
	isLoaded: boolean;
	backLink: string; 
	title: string;
	back: string;
  // paramsSub: any;
  id: number | null = null;
  private paramsSub: Subscription;
  showadmincreated: boolean = false;

  constructor(
    private userService: UserService,
		private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.user = this.userService.getUser();
    if(this.user.userType != 'user'){
      this.paramsSub = this.activatedRoute.params.subscribe((params) => {
        const paramId = params['id'];
        this.id = Number.isNaN(parseInt(paramId, 10)) ? null : parseInt(paramId, 10);
      });
    }
   }

  ngOnInit(): void {
    if(this.id){
      this.showadmincreated = true;
    }else{
      this.showadmincreated = false;
    }
    this.user = this.userService.getUser();
  }

}
