<div>
	<ul class="nav nav-tabs">
		<li
			*ngFor="let tab of tabs"
			(click)="selectTab(tab)"
			[class.active]="tab.active"
		>
			<a>{{ tab.title }}</a>
		</li>
	</ul>
	<ng-content></ng-content>
</div>
