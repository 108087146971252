import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { isUndefined } from 'lodash';
import { AddResourcesComponent } from '../../components/admin/add-resources/add-resources.component';
import { Organization, resourceSet, resourceSetOptions} from "../../models/organization";
@Component({
    selector: 'resources-edit',
    templateUrl: './resources-edit-component.html',
    styleUrls: ['./resources-edit-component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ResourcesEditComponent implements OnInit, OnChanges {
    @Input() resources: any;
    @Input() updatedData: any;
    @Output() onResourceSetChange: EventEmitter<any> = new EventEmitter();
    resourcesEditForm: FormGroup;
    organization: Organization;
    numberResourceGroups: number = 0;
    config: any;
    selectedResourceSetIndex: number;
    allLang=[
        'en',
        'fr',
        'es'
    ]
    Language:any;
    resourseSet: any;
    isLoaded :boolean = false;
    // default category associated with the mapped image !important
    resourceSetOptions: object = resourceSetOptions;
    expandedIndices: number[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private api: ApiService,
        private ref: ChangeDetectorRef,
        public storage: StorageService,
        private log: LogService,
    ) {
        this.config = {
            toolbar: [
              { name: 'formatting', items: ['PasteFromWord'] },
              { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'paragraph', items: ['NumberedList', 'BulletedList'] }
            ]
          };
          this.Language = 'en';
    }

    ngOnInit() {
        this.resourceProcess();
    }

    ngOnChanges() {
        if (this.updatedData !== undefined){
            this.patchUpdatedVideos();
        } else {
            this.resourceProcess();
        }
    }

    resourceProcess() {
        this.organization = {...this.resources};
        this.createresourcesEditForm(this.organization);
        this.resourcesEditForm.valueChanges.subscribe(val => {
            this.onResourceSetChange.emit({ resourceSet: this.resourcesEditForm.value, valid: this.resourcesEditForm.valid });
        });
        this.refreshnumberResourceGroups();
    }

    showResourceByLanguage(resource: any): boolean {
        return resource.get('language').value === this.Language;
    }

    /**
     * Creates a resources edit form with the given data.
     *
     * @param {any} data - the data to initialize the form with
     */
    createresourcesEditForm(data: any) {
       this.resourcesEditForm = this.formBuilder.group({
            enableResources: [data.enableResources || false],
            resources: [data.resources || ""],
            resourceSet: this.formBuilder.array([]),
        });

        data.resourceSet?.forEach((resource: any,index) => {
            this.addRessourceSet(resource,index);
        });
    }

    /**
     * Getter for the resource set.
     *
     * @return {FormArray} The resource set as a FormArray.
     */
    get resourceSet() {
        return this.resourcesEditForm.get("resourceSet") as FormArray;
    }

    /**
     * Add a resource set to the form.
     *
     * @param {any} data - the data to be added
     * @param {number} index - the index of the resource set
     */
    addRessourceSet(data: any, index: number) {
        const resourceList = this.resourcesEditForm.get('resourceSet') as FormArray;
        resourceList.push(this.createResourceList(data));

        if (data.resourcesetGroup) {
            data.resourcesetGroup.forEach((resourceGroup: any) => {
                this.addResourceGroup(resourceList.length - 1, resourceGroup);
            });
        }
        this.refreshnumberResourceGroups();
        if (isUndefined(index)) {
            this.expandedIndices = [...this.expandedIndices, this.numberResourceGroups - 1];
        }
    }

    /**
     * Check if the specified index is in the list of expanded indices.
     * 
     * @param {number} index - the index to check
     * @return {boolean} true if the index is in the list, false otherwise
     */
    isExpanded(index: number): boolean {
        return this.expandedIndices.includes(index);
    }

    /**
     * A function to add a resource group based on certain add resource parameter or edit resource parameter.
     *
     * @param {number} i - Index parameter.
     * @param {any} data - Data parameter.
     * @param {String} [action] - Optional action parameter.
     * @param {any} [resourseSet] - Optional resource set parameter.
     * @param {number} [j] - Optional j parameter.
     */
    addResourceGroup(i: number, data: any, action?: String, resourseSet?, j?: number) {
        if (action === 'add') {
            this.selectedResourceSetIndex = i;
            this.modalService.setCloseOnClickAway(false);
            const modal = this.modalService.showComponent(AddResourcesComponent, {
                resourceSetId: resourseSet.id,
                mode: 'add',
                organizationId: this.organization.id,
                index: i
            });
            modal.beforeClosed().subscribe((responseData: any) => {
                if (responseData) {
                    responseData.forEach(resourceData => {
                        this.getResourceGroup(i).push(this.createResourceGroupList(resourceData));
                    });
                }
            });
        } else {
            this.getResourceGroup(i).push(this.createResourceGroupList(data));
        }
    }

    createResourceList(data: any): FormGroup {
       return this.formBuilder.group({
            id: [data.id || null],
            title: [data.title || "", [Validators.required]],
            categories: [data.categories || []], // deprecated
            summary: [data.summary || ""], // description
            number: [data.number || 0],
            language:[data.language || this.Language],
            videos:this.formBuilder.array([]), // deprecated
            resourcesetGroup: this.formBuilder.array([]),
        });
    }

    /**
     * Create a form group for resource data.
     *
     * @param {any} data - the data to populate the Add/Edit resource form with
     * @return {FormGroup} the form group created
     */
    createResourceGroupList(data: any): FormGroup {
        return this.formBuilder.group({
            id: [data.id || null],
            title: [data.title|| "", [Validators.required]],
            contact: [data.contact || ""],
            alternateContact: [data.alternateContact || ""],
            websiteTitle: [data.websiteTitle || ""], // deprecated
            website: [data.website || ""],
            address: [data.address || ""],
            internal: [data.internal || ""],
            description: [data.description  || ""],
            active: [data.active],
            language:[data.language || this.Language],
        });
    }

    /**
     * Get the form group at the specified index in the resource set form array.
     *
     * @param {number} index - The index of the form group to retrieve.
     * @return {FormGroup} The form group at the specified index.
     */
    getResourceSetFormGroup(index: number): FormGroup {
        const resourceSetForm = this.resourceSet;
        const formGroup = resourceSetForm.controls[index] as FormGroup;
        return formGroup;
    }

    /**
     * Get the resource group at the specified index.
     *
     * @param {number} i - The index of the resource group to retrieve.
     * @return {FormArray} The resource group list at the specified index.
     */
    getResourceGroup(i: number) {
        const resourceList = this.resourcesEditForm.get('resourceSet') as FormArray;
        const resourceGroupList = resourceList.at(i).get("resourcesetGroup") as FormArray;
        return resourceGroupList;
    }

    /**
     * Get the FormGroup at a specific index within a FormArray of FormGroup.
     *
     * @param {number} i - index of the FormArray within the resourcesEditForm
     * @param {number} r - index of the FormGroup within the groupList
     * @return {FormGroup} the FormGroup at the specified index
     */
    getResourceSetGroupFormGroup(i: number, r: number): FormGroup {
        const formGroup = this.getResourceGroup(i).controls[r] as FormGroup;
        return formGroup;
    }

    /**
     * Deletes a resource set at the specified index.
     *
     * @param {number} i - The index of the resource set to be deleted.
     * @return {void} This function does not return anything.
     */
    onRemoveResourceset(i: number) {
        this.modalService.showConfirmation("Delete", "Are you sure you want to delete this Resource Set?").afterClosed().subscribe(result => {
			if (result) {
                const resourceList = this.resourcesEditForm.get('resourceSet') as FormArray;
                resourceList.removeAt(i);
                this.refreshnumberResourceGroups();
            }    
        });
    }

    /**
     * Refreshes the number of resource groups.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    refreshnumberResourceGroups() {
        const resourceList = this.resourcesEditForm.get('resourceSet') as FormArray;
        this.numberResourceGroups = resourceList.length;
    }

    /**
     * Remove a resource group from the given resource set at the specified indices.
     *
     * @param {type} resourceGroup - The resource group to be removed
     * @param {type} resourceSet - The set of resources
     * @param {number} i - The index of the resource group
     * @param {number} j - The index within the resource group
     * @return {void} 
     */
    removeResourceGroup(resourceGroup,resourceSet,i: number,j:number) {
        this.modalService.showConfirmation("Delete", "Are you sure you want to delete this Resource?").afterClosed().subscribe(result => {
			if (result) {
                const ctrl = <FormArray>(
                this.resourcesEditForm.get('resourceSet')['controls']
                );

                ctrl[i]
                .get('resourcesetGroup')
                .removeAt(j);
                return;
            }    
        });
    }


    /**
     * Remove/Delete Video | deprecated
     */
     onRemoveVideo(i:number,j) {
        this.modalService.showConfirmation("Delete", "Are you sure you want to delete this Video?").afterClosed().subscribe(result => {
			if (result) {
                const ctrl = <FormArray>(
            this.resourcesEditForm.get('resourceSet')['controls']
          );
          ctrl[i]
            .get('videos')
            .removeAt(0);
            }    
        });
    }

    /**
     * A function that handles the drag and drop event for a list of resource sets.
     *
     * @param {CdkDragDrop<resourceSet[]>} event - The drag and drop event object
     * @return {void} This function does not return anything
     */
    drop(event: CdkDragDrop<resourceSet[]>) {
        const resourceList = this.resourcesEditForm.get('resourceSet') as FormArray;
        const resource = resourceList.at(event.previousIndex);
        resourceList.removeAt(event.previousIndex);
        resourceList.insert(event.currentIndex, resource);
    }

    /**
     * Drops a group within a resource set based on drag and drop event.
     *
     * @param {CdkDragDrop<resourceSet[]>} event - The drag and drop event
     * @param {number} i - The index of the group within the resource set
     */
    dropGroup(event: CdkDragDrop<resourceSet[]>, i: number) {
        const resourceGroupList = this.getResourceGroup(i);
        const resourceGroup = this.getResourceGroup(i).at(event.previousIndex);
        resourceGroupList.removeAt(event.previousIndex);
        resourceGroupList.insert(event.currentIndex, resourceGroup);
    }

    /**
     * Edit a resource group.
     *
     * @param {any} resource - the resource to edit
     * @param {any} resourseSet - the resource set
     * @param {number} i - index parameter
     * @param {number} j - index parameter
     */
    editResourceGroup(resource, resourseSet, i: number, j: number) {
        this.selectedResourceSetIndex = i;
        resource['resourceSetId'] = resourseSet.id;
        resource['mode'] = 'update';
        resource['organizationId'] = this.organization.id;
        this.modalService.setCloseOnClickAway(false);
        const modal = this.modalService.showComponent(AddResourcesComponent, resource);
        modal.beforeClosed().subscribe((responseData: any) => {
            if (responseData) {
                const data = responseData[0];
                if (data) {
                    const ctrl = <FormArray>(
                        this.resourcesEditForm.get('resourceSet')['controls'][i].get('resourcesetGroup'));
                    ctrl['controls'][j].patchValue({
                        id: data.id,
                        title: data.title,
                        contact: data.contact,
                        alternateContact: data.alternateContact,
                        websiteTitle: data.websiteTitle,
                        website: data.website,
                        address: data.address,
                        internal: data.internal,
                        description: data.description,
                        active: data.active
                    })
                }
                if (responseData.length > 1) {
                    const resourceGroupList = this.getResourceGroup(i);
                    responseData.slice(1).forEach(resourceData => {
                        resourceGroupList.push(this.createResourceGroupList(resourceData));
                    });
                }
            }
        })
    }

     /**
      * Video upload
      */
     changeVideoUploadListener($event, i: number, j: number): void {
		this.readFile($event.target, i, j,  "mediaUpload", 'mpeg|mpg|mp4|mkv');
	}

    /**
      * Image upload
      */
	changeVideoImageUploadListener($event, i: number, j: number): void {
		this.readFile($event.target, i, j, "imageUpload", 'gif|jpg|jpeg|png');
	}

    /**
      * Caption upload
      */
	changeCaptionUploadListener($event, i: number, j: number): void {
		this.readFile($event.target, i, j,  "captionFileUpload", 'vtt');
	}
	

	readFile(
		inputValue: any,
		i: number,
		j: number,
		keyFileName: string,
		allowedTypes: string = "gif|jpg|jpeg|png|mpeg|mpg|mp4|mkv|vtt|mp3|pdf"
	): void {
		var file: File = inputValue.files[0];
		inputValue.files[0];
		let fileName = inputValue.files[0].name;
		var reader: FileReader = new FileReader();
		const extPattern = new RegExp(`\.(${allowedTypes})$`, "i");

		if (extPattern.test(fileName)) {
			reader.onloadend = (e) => {
            const videos = <FormArray>(
                this.resourcesEditForm
                .get('resourceSet')['controls'][i].get('videos')
            );

            videos['controls'][0].get(`${keyFileName}`).patchValue({
                fileUpload: reader.result,
                fileFilename: fileName,
            });
				
			};
		} else {
			this.modalService.showAlert(
				"Error",
				`The extension is invalid. Valid extension(s) are: ${allowedTypes}`
			);
			inputValue.value = "";
		}

		reader.readAsDataURL(file);
	}

  /**
   * Get resource Details
   */
   getResourceVideoDetails(form){
    return form.controls.videos.controls;
   }

    /**
   * Initalize client cost
   */
  initializeVideo(data?) {
   return this.formBuilder.group({
        id: [data.id || ''],
		label: [data.label || "", [Validators.required]],
		length: [data.length || ""],
		image: [data.image || ""],
		media: [data.media || ""],
		captionFile: [data.captionFile ||""],
		description: [data.description || ""],
		imageUpload: this.createFileUpload({}),
		mediaUpload: this.createFileUpload({}),
		captionFileUpload: this.createFileUpload({}),
        language:[this.Language],
    });
  }

  /**
   * Add video
   */
   addResourceVideo(i:number,data?:any){
    const ctrl = <FormArray>this.resourcesEditForm.get('resourceSet')['controls'][i].get('videos');
    ctrl.push(this.initializeVideo(data ? data[0] : ''));
   }

 

  createFileUpload(data?){
		return this.formBuilder.group({
			fileUpload: [data.fileUpload || ""],
			fileFilename: [data.fileFilename || ""],
		});
	} 

    /**
     * Patch updated videos
     */
     patchUpdatedVideos(data?:any){
        const videoData= this.updatedData.resourceSet[this.selectedResourceSetIndex].videos;
        if(videoData.length > 0){
            const ctrl = <FormArray>(
                this.resourcesEditForm
                .get('resourceSet')['controls'][this.selectedResourceSetIndex].get('videos')
            );
            this.log.debug(videoData[0].media)
             ctrl['controls'][0].patchValue({
                id:videoData[0].id,
                label:videoData[0].label,
                length:videoData[0].length,
                description:videoData[0].description,
                image: videoData[0].image,
                media: "",
                captionFile:videoData[0].caption,
                imageUpload :{
                    fileUpload: "",
                    fileFilename: "",
                },
                mediaUpload :{
                    fileUpload: "",
                    fileFilename: "",
                },
                captionFileUpload :{
                    fileUpload: "",
                    fileFilename: "",
                },
                language:videoData[0].language,
            });
            setTimeout(() => {
            ctrl['controls'][0].patchValue({
                media: videoData[0].media
            });
            this.ref.detectChanges();
            }, 300);
            
        }
     }

     /**
      * Language switch change
      */
      onSelectOrganization() {
        this.isLoaded = true;
        this.api.get('organizations/' + this.organization.id).subscribe(
            (result: any) => {
                this.resources = new Organization(result.data, 'full');
                this.resourseSet = {
                    enableResources: this.organization.enableResources,
                    language:this.organization.language,
                    resources: this.organization.resourceSet,
                    resourceSet: this.organization.resourceSet,
                };
                
                this.ngOnInit();
                this.isLoaded = false;
            },
            (error: any) => {
                this.log.error('Error getting organizations. ' + error.message);
                this.isLoaded = false;
            },
            () => {
                this.isLoaded = false;
            });
    }
}