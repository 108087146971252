import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { User } from '../../models/user';
import { UserService } from '../../lib/user.service';
import { MoodcheckService } from '../../lib/moodcheck.service';
import { LogService } from '../../lib/log.service';
import { ModalService } from '../../lib/modal.service';
import { PermissionsService } from '../../lib/permissions.service';
import { CalendarService } from '../../lib/calendar.service';
import { TutorialPage } from '../../components/tutorial/tutorial';
import { Invite } from '../../components/invite/invite';
import { MessagingService } from 'app/lib/message-service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicFormComponent } from '../../components/dynamic-form/dynamic-form.component';
import { DynamicFormDataService } from '../../components/dynamic-form/dynamic-form.service';
import { ConsentModalComponent } from 'app/components/consent-modal/consent-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MoodcheckRemindersComponent } from 'app/components/moodcheck-reminders/moodcheck-reminders.component';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardPage implements OnInit, AfterViewInit {
    user: User;
    showGeneralNotice: boolean = false;
    showNotice: boolean = false;
    usersPaired: User[];
    activeUsers: User[];
    activeUser: User;
    professionalLoaded: boolean = false;
    activeUsersIds: Array<number>;
    events: Event[];
    pickedDate: any;
    donotLoad: boolean = false;
    endUser: boolean = true;
    enableBuddy: boolean = false;
    enableScheduler: boolean = false;
    showAdminDashboard: boolean = false;
    showsuperAdminDashboard: boolean = false;
    showExecDashboard: boolean = false;
    showProfessionalDashboard: boolean = false;
    showEndUserDashboard: boolean = false;
    locked: boolean = false;
    message: any;
    dynamicFormField: any;
    dynamicForm: FormGroup;
    dynamicData: any;
    showRelationshipManagerDashboard: boolean = false;
    onBoardingStep: number;
    moodcheckFreq: number = 2;
    constructor(
        private api: ApiService,
        private userService: UserService,
        private permissionService: PermissionsService,
        private mcService: MoodcheckService,
        private modalService: ModalService,
        private logService: LogService,
        private storage: StorageService,
        private calendarService: CalendarService,
        private messagingService: MessagingService,
        private fb: FormBuilder,
        private dynamicFormDataService: DynamicFormDataService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private log: LogService
    ) {
        this.logService.screen('Dashboard');
    }

    ngOnInit() {
        // this.activatedRoute.params.subscribe((params) => {
        // 	if (params["segment"]) {
        // 		if (params["segment"] === "moodcheck") {
        // 			this.displayMoodcheck();
        // 		}
        // 	}
        // });

        //listen for data changes
        this.userService.watcher.subscribe((user: User) => {
            this.user = user;
            this.showNotice = false;
            this.moodcheckFreq =
                this.user.preferences.moodcheckNotificationFrequency || 0;
        });

        this.user = this.userService.getUser();
        this.getMoodcheckFreq();
        this.logService.debug('called');
        this.logService.debug(this.user);
        if (this.user.noticeOn) {
            this.showNotice = true;
        }

        if (!this.storage.get('challenge_notification')) {
            this.showGeneralNotice =
                this.user.totalChallengesCount > 0 ? true : false;
        }

        this.user.isFullAccess || this.user.userType === 'admin'
            ? (this.locked = false)
            : (this.locked = true);
        this.permissionService.setUser(this.user);

        if (this.user.userType === 'admin') {
            this.showAdminDashboard = true;
        } else if (this.user.userType === 'superadmin') {
            this.showsuperAdminDashboard = true;
        } else if (
            this.user.userType === 'orgadmin' &&
            this.user.permissions.clientList
        ) {
            this.api.get('userspaired').subscribe(
                (results: any) => {
                    this.activeUsers = User.initializeArray(results.data);
                    this.professionalLoaded = true;
                },
                (error: any) => {
                    this.logService.error('Error loading. ' + error.message);
                    this.professionalLoaded = true;
                }
            );
            this.enableScheduler = true;
            this.showExecDashboard = true;
        } else if (this.user.userType === 'orgadmin') {
            this.showExecDashboard = true;
        } else if (this.user.userType === 'relationshipmanager') {
            this.showRelationshipManagerDashboard = true;
        } else if (this.user.userType === 'professional') {
            this.showProfessionalDashboard = true;
        } else {
            this.showEndUserDashboard = true;
        }
    }

    ngAfterViewInit() {
        if (
            this.user.isFirstTimeLogin &&
            this.user.userType === 'user' &&
            !this.userService.isShowTutorialPage(this.user)
        ) {
            //show the web push notifications dialog
            const modal = this.modalService.showConfirmation(
                'Subscribe',
                'Do you want to subscribe to WellTrack push notifiations?'
            );
            modal.beforeClosed().subscribe((responseData: any) => {
                if (responseData) {
                    this.messagingService.requestPermission();
                }
                this.checkPopUps();
            });
        } else {
            this.checkPopUps();
        }
    }

    checkPopUps() {
        if (this.user.userType === 'user' && !this.locked) {
            if (
                this.storage.checkFlag('finished-tutorial-welcome') &&
                !this.userService.isShowTutorialPage(this.user)
            ) {
                this.showEndUserDashboard = true;
            }
            // let allowedRoles = ["admin", "relationshipmanager", "superadmin"];
            // const showConsent =
            // 	this.user.userType === "user" &&
            // 	this.user.primaryOrganization &&
            // 	this.user.primaryOrganization.settings &&
            // 	this.user.primaryOrganization.serviceType === 1 &&
            // 	this.user.primaryOrganization.settings.hasAssessmentAlerts &&
            // 	this.user.primaryOrganization.settings.enableAlerts &&
            // 	this.user.preferences.consent === null;

            // this.logService.debug("userType:" + this.user.userType);
            // this.logService.debug(
            // 	"userConsent:" + this.user.preferences.consent
            // );
            // this.logService.debug(
            // 	"hasAssessmentAlerts:" +
            // 		this.user.primaryOrganization.settings.hasAssessmentAlerts
            // );
            // this.logService.debug(
            // 	"enableAlerts:" +
            // 		this.user.primaryOrganization.settings.enableAlerts
            // );
            // this.logService.debug("showContent:" + showConsent);

            // if (showConsent) {
            // 	setTimeout(() => {
            // 		this.modalService
            // 			.showComponent(ConsentModalComponent, null, "", true)
            // 			.afterClosed()
            // 			.subscribe((result) => {
            // 				this.user.preferences.consent = result.consent;
            // 				this.userService.setUser(this.user);
            // 				this.checkPopUps();
            // 			});
            // 	}, 500);
            // } else if (
            // 	this.user.userType === "user" &&
            // 	this.user.showDemographic
            // ) {
            // 	setTimeout(() => {
            // 		this.router.navigate(['/app/surveys',this.user.demographicFormId, "Demographic"], { queryParams: { navPath: "/app" } });
            // 	}, 500);
            // } else if (
            // 	this.user.userType === "user" &&
            // 	this.user.forceAssessment
            // ) {
            // 	setTimeout(() => {
            // 		this.modalService.showComponent(
            // 			TutorialPage,
            // 			"assessmentforce",
            // 			"",
            // 			true
            // 		);
            // 	}, 500);
            // } else
            // if (!this.storage.checkFlag("finished-tutorial-welcome")) {
            // 	setTimeout(() => {
            // 		this.router.navigateByUrl('user-onboarding');
            // this.modalService
            // 	.showComponent(TutorialPage, "welcome")
            // 	.afterClosed()
            // 	.subscribe((result) => {
            // 		if (
            // 			!this.storage.checkFlag(
            // 				"finished-tutorial-moodcheck"
            // 			)
            // 		) {
            // 			this.storage.setFlag(
            // 				"finished-tutorial-moodcheck"
            // 			);
            // 			this.displayMoodcheck();
            // 		}
            // 	});
            // }, 500);
            // } else if (!this.storage.checkFlag("finished-tutorial-moodcheck")) {
            // 	this.storage.setFlag("finished-tutorial-moodcheck");
            // 	this.displayMoodcheck();
            // } else if (
            // 	!this.storage.checkReminder("reminder-practice") &&
            // 	this.user.primaryOrganization
            // ) {
            // 	setTimeout(() => {
            // 		this.modalService.showComponent(TutorialPage, "practice");
            // 	}, 500);
            // 	this.storage.setReminder("reminder-practice");
            // } else {
            //do nothing
            // }
        }
    }

    // displayMoodcheck() {
    // 	this.modalService
    // 		.showComponent(TutorialPage, "moodcheck")
    // 		.afterClosed()
    // 		.subscribe((result) => {
    // 			this.router.navigateByUrl("/app/mood-check");
    // 		});
    // }

    updateUsers(event) {
        this.activeUsers = event;
        this.activeUsersIds = [];
        this.activeUsers.forEach(user => {
            this.activeUsersIds.push(user.id);
        });
    }

    updateUser(event) {
        if (event) {
            this.activeUser = event;
        } else {
            this.activeUser = null;
        }
    }

    updateEvents(event) {
        this.events = event.events;
        this.pickedDate = event.date;
    }

    inviteShare() {
        let inviteInfo = {
            type: 'professional',
            endpoint: 'userinvite',
            forceShare: true,
            orgId: this.user.primaryOrganization.id,
        };
        this.modalService.showComponent(Invite, inviteInfo);
    }
    getMoodcheckFreq() {
        this.moodcheckFreq =
            this.user.preferences.moodcheckNotificationFrequency || 0;
        this.mcService.mcCountWatcher.subscribe((user: User) => {
            if (user.moodcheckCount == 1) {
                this.modalService
                    .showComponent(
                        MoodcheckRemindersComponent,
                        {
                            moodcheckFreq: this.moodcheckFreq,
                        },
                        'reminder-modal'
                    )
                    .afterClosed()
                    .subscribe(result => {
                        this.userService.watcher.subscribe((user: User) => {
                            this.user = user;
                            this.moodcheckFreq =
                                this.user.preferences
                                    .moodcheckNotificationFrequency || 0;
                        });
                    });
            }
        });
    }
}
