import { Component, ViewChild, ViewEncapsulation, OnInit, Input, Inject } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";
import { Organization, resourceSet, ResourceSetGroup, OrganizationCourse, Tenant } from '../../../models/organization';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../../lib/api.service';
import { LogService } from '../../../lib/log.service';
import { UtilityService } from '../../../lib/utility.service';
import { ModalService } from '../../../lib/modal.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { User } from "../../../models/user";
import { UserService } from "../../../lib/user.service";
import { forEach } from 'lodash';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ASSESSMENT_TYPES_CONFIG, TOOLS_CONFIG } from '../../../lib/constants';

@Component({
  selector: 'app-organization-admin-edit',
  templateUrl: './organization-admin-edit.component.html',
  styleUrls: ['./organization-admin-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  inputs: ['phoneNumber'],

})
export class OrganizationAdminEditComponent implements OnInit {
  @ViewChild('input') fileInput: any;
  orgEditForm: FormGroup;
  placeholders: any[];
  default: any;
  popups: any[];
  title: string;
  config: any;
  mode: string;
  organizations: Organization[];
  isLoaded: boolean = false;
  ressourcesets: Array<Object> = [];
  emptyressourceset: Object = { 'title': '', 'description': '', 'url': '', 'category': '' };
  numberResourceGroups: number = 6;
  logo: string;
  timezoneNames: any;
  urlPattern: string;
  phonePattern: string;
  commaSeparatedUrlPattern: string;
  authTypes: Array<string> = [];
  serviceType: Array<object>;
  @Input() organization: Organization;
  orgId: number;
  invitesExecutives: any = {};
  questionRemoveIds: any = [];
  resourcenRemoveIds: any = [];
  courses: any;
  submitButtonPressed: boolean = false;
  errors: string = "";
  userType: string;
  resourseSet: any;
  serviceTier: Array<object>;
  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private utility: UtilityService,
    private log: LogService,
    private modalService: ModalService,
    private userService: UserService,
    public dialogRef: MatDialogRef<OrganizationAdminEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    dialogRef.disableClose = true;
    this.orgId = data.data;

    this.urlPattern = this.utility.urlValidationPattern();
    this.phonePattern = this.utility.phoneValidationPattern();
    this.userType = this.userService.getUser().userType;

    if (this.orgId) {
      this.title = 'Edit this Organization';
      this.mode = 'update';
      this.organization = new Organization('', 'full');
    }
    else {
      this.organization = new Organization('', 'full');
      this.createOrgEditForm(this.organization);
      this.title = 'Create an Organization';
      this.mode = 'create';
    }


    this.resourseSet = {
      enableResources: this.organization.enableResources,
      resources: this.organization.resources,
      resourceSet: this.organization.resourceSet,
      // questionSet: this.organization.questionSet
    };


    this.createOrgEditForm(this.organization);

    this.config = {
      toolbar: [
        { name: 'formatting', items: ['PasteFromWord'] },
        { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
        { name: 'links', items: ['Link', 'Unlink'] },
        { name: 'paragraph', items: ['NumberedList', 'BulletedList'] }
      ]
    };

    this.authTypes = [
      "saml2",
      "oauth2-pkce"
    ];

  }

  ngOnInit() {
    this.serviceType = [
      { id: 1, name: 'Student' },
      { id: 2, name: 'EAP/Employer' },
      { id: 3, name: 'Community/State-wide Access (CMH)' },
      { id: 4, name: 'Managed Care' },
    ];
    this.serviceTier = [
      { id: 1, name: 'Good' },
      { id: 2, name: 'Better' },
      { id: 3, name: 'Best' }
    ];

    if (this.orgId) {
      this.api.get('organizations/' + this.orgId).subscribe(
        (result: any) => {
          if (!result.data.resourceSet) {
            result.data.resourceSet = [];
          }
          this.organization = new Organization(result.data, 'full');
          this.resourseSet = {
            enableResources: this.organization.enableResources,
            resources: this.organization.resources,
            resourceSet: this.organization.resourceSet,
            // questionSet: this.organization.questionSet
          };
          this.loadCourses();
          this.loadTenants();
          this.createOrgEditForm(this.organization);
          this.log.event(AnalyticEvent.event.organizationDetailsLoaded)
        },
        (error: any) => {
          this.log.error('Error getting organizations. ' + error.message);
        });
    } else {
      this.organization.courses = [];
      this.loadCourses();
      this.loadTenants();
	  this.getDefaultAssessmentList();
	  this.getDefaultToolsList();
    }

    this.translate.stream('organizationInfo').subscribe((res: any) => {
      this.default = res.default;
      this.placeholders = res.placeholders;
      this.popups = res.popups;
    });


    this.api.get('admin/organizations', { Active: true }).subscribe(
      (results: any) => {
        this.organizations = Organization.initializeArray(results.data);
        this.isLoaded = true;
        this.refreshnumberResourceGroups();
      },
      (error: any) => {

        this.log.error('Error loading. ' + error.message);
      }
    );

    // this.setOrganization();
  }

  loadCourses() {
    this.api.get("series/serieslisting").subscribe(
      (result: any) => {
        if (result.data) {
          const resCourses = result.data;
          for (var key in resCourses) {
            if (
              this.organization.courses.findIndex(
                (course) => course.courseId === resCourses[key].ID
              ) === -1
            ) {
              this.organization.courses.push(
                new OrganizationCourse({
                  courseId: resCourses[key].ID,
                  name: resCourses[key].Label,
                  enabled: resCourses[key].DefaultCourse,
                })
              );
            }
          }
          this.createOrgEditForm(this.organization);

        }
      },
      (error: any) => {
        this.log.error("Error getting organizations. " + error.message);
      }
    );
  }

  getDefaultAssessmentList() {
    this.api.get("assessments/default").subscribe(
      (assessmentList: any) => {
        if (assessmentList) {
          assessmentList?.forEach((assessment: any) => {
              this.organization.assessmentConfig.push(
                {
				  id: assessment.ID,
                  name: ASSESSMENT_TYPES_CONFIG.ASSESSMENT_LIST_LANG[assessment.AssessmentKey]?.title || assessment.Comment,
                  enabled: assessment.DefaultAssessment,
				  default: assessment.DefaultAssessment,
				  quizID: assessment.ID,
				  key: assessment.AssessmentKey
                }
              );
          });
          this.createOrgEditForm(this.organization);
        }
      },
      (error: any) => {
        this.log.error("Error getting organizations. " + error.message);
      }
    );
  }

  getDefaultToolsList() {
    this.api.get("tools/default").subscribe(
      (toolsList: any) => {
        if (toolsList) {
			toolsList?.forEach((tool: any) => {
              this.organization.toolsConfig.push(
                {
				  id: tool.ID,
				  name:TOOLS_CONFIG.TOOLS_LIST_LANG[tool.ToolKey]?.title || tool.Title,
                  enabled: tool.Default,
				  default: tool.Default,
				  toolID: tool.ID,
				  key: tool.ToolKey
                }
              );
          });
          this.createOrgEditForm(this.organization);
        }
      },
      (error: any) => {
        this.log.error("Error getting organizations. " + error.message);
      }
    );
  }

  loadTenants() {
    this.api.get("tenants").subscribe(
      (result: any) => {
        if (result.data) {
          const resTenants = result.data;

          this.log.debug(resTenants);
          for (var key in resTenants) {
            if (
              this.organization.tenants.findIndex(
                (tenant) => tenant.id === resTenants[key].ID
              ) === -1
            ) {

              this.organization.tenants.push(
                new Tenant({
                  id: resTenants[key].ID,
                  name: resTenants[key].Name,
                  enabled: !this.orgId ? resTenants[key].DefaultEnabled: false // only use defaults if new org. Otherwise, the missing values are purposeful
                })
              );
            }
          }
          this.log.debug(   this.organization.tenants);
          this.createOrgEditForm(this.organization);
        }
      },
      (error: any) => {
        this.log.error("Error getting organizations. " + error.message);
      }
    );

  }

  get f() {
    return this.orgEditForm.controls;
  }

  setOrganization() {
    if (this.orgId) {
      this.api.get('organizations/' + this.orgId).subscribe(
        (result: any) => {
          this.organization = new Organization(result.data, 'full');
          if (this.organization.courses.length === 0) {
            this.setDefaultCourses();
          }
          else {
            this.createOrgEditForm(this.organization);
          }
        },
        (error: any) => {
          this.log.error('Error getting organizations.' + error.message);
        });
    } else {
      this.setDefaultCourses();
    }
  }

  setDefaultCourses() {
    this.api.get('series/serieslisting/defaults').subscribe(
      (result: any) => {
        if (result.data) {
          this.organization.courses = [];
          result.data.forEach(course => {
            this.organization.courses.push(
              new OrganizationCourse({
                id: course.ID,
                courseId: course.ID,
                name: course.Name,
                enabled: course.Default,
              })
            );
          });
          this.createOrgEditForm(this.organization);
        }
      },
      (error: any) => {
        this.log.error('Error getting organizations. ' + error.message);
      });

  }

  createOrgEditForm(data: any) {
    this.orgEditForm = this.formBuilder.group({
      id: [data.id || null],
      name: [data.name || "", [Validators.required]],
      ServiceType: [data.serviceType || "", [Validators.required]],
      ServiceTier: [data.serviceTier || ""],
      parentOrgId: [data.parentOrgId || ""],
      demoStatus: [data.demoStatus || false],
      active: [data.active || true],
      logo: [data.logo || ""],
      logoUpload: [data.logoUpload || ""],
      logoPath: [data.logoPath || ""],
      address: [data.address || ""],
      phone: [data.phone || "", Validators.pattern(this.phonePattern)],
      website: [data.website || "", Validators.pattern(this.urlPattern)],
      authenticationType: [data.authenticationType || ""],
      clientID: [data.clientID || ""],
      redirectUrl: [data.redirectUrl || ""],
      authorizeEndPointUrl: [data.authorizeEndPointUrl || ""],
      oauthEndPointUrl: [data.oauthEndPointUrl || ""],
      emergencyContact: [data.emergencyContact || ""],
      protocallRefId: [data.protocallRefId || ""],
      description: [data.description || ""],
      contactGroup: this.formBuilder.group({
        telephone: [data.contactGroup.telephone || "", Validators.pattern(this.phonePattern)],
        title: [data.contactGroup.title || ""],
        description: [data.contactGroup.description || ""],
        getHelpDescription: [data.contactGroup.getHelpDescription || ""],
      }),
      settings: this.formBuilder.group({
        assessment: [data ? data.settings.assessment : ""],
        enableBuddyScheduling: [data ? data.settings.enableBuddyScheduling : false],
        hasCounselors: [data ? data.settings.hasCounselors : true],
        hasAssessmentAlerts: [data ? data.settings.hasAssessmentAlerts : false],
        disableFeedbackSurveys: [data ? data.settings.disableFeedbackSurveys : false],
        enableAlerts: [data ? data.settings.enableAlerts : true],
        enableDisasterCourse: [data ? data.settings.enableDisasterCourse : false],
        hideNotes: [data ? data.settings.hideNotes : false],
        customConfirm: [data ? data.settings.customConfirm : ""],
        showOldDashboard: [data ? data.settings.showOldDashboard : false],
        hasScheduledPushNotification: [data ? data.settings.hasScheduledPushNotification : false],
        forceAssessment: [data ? data.settings.forceAssessment : true],
        showWelltrackConnect: [data ? data.settings.showWelltrackConnect : true]
      }),
      auth: this.formBuilder.group({
        url: [data.auth.url || ""],
        xml: [data.auth.xml || ""],
      }),
      subdomain: [data.subdomain || "", [Validators.required, Validators.pattern('[0-9a-zA-Z]{2,}')]],
      allowedDomains: [data.allowedDomains || ""],
      enforceDomains: [data.enforceDomains || false],
      enableSso: [data.enableSso || false],
      courses: this.formBuilder.array([]),
      assessmentConfig: this.formBuilder.array([]),
      toolsConfig: this.formBuilder.array([]),
      integrations: this.formBuilder.group({
        togetherAllSsoLink: [data?.integrations?.togetherAllSsoLink || ""],
      }),
      tenants: this.formBuilder.array([]),
      brandingGroup: this.formBuilder.group({
        brandingClientLogo: [data?.brandingGroup?.brandingClientLogo || ""],
        clientLogoUpload: [data?.brandingGroup?.clientLogoUpload || ""],
        // clientLogoPath: [data?.brandingGroup?.clientLogoPath || ""],
        brandingCoverPhoto: [data?.brandingGroup?.brandingCoverPhoto || ""],
        coverPhotoUpload: [data?.brandingGroup?.coverPhotoUpload || ""],
        // coverPhotoPath: [data?.brandingGroup?.coverPhotoPath || ""],
        brandingDescription: [data?.brandingGroup?.brandingDescription || ""]
      }),
      crmGroup: this.formBuilder.group({
        sugar_id_c: [data?.crmGroup?.sugar_id_c || ""],
        sugar_name: [data?.crmGroup?.sugar_name || ""],
        connect_account_id_c: [data?.crmGroup?.connect_account_id_c || ""],
        boost_status_c: [data?.crmGroup?.boost_status_c || ""],
        connect_status_c: [data?.crmGroup?.connect_status_c || ""],
        assigned_user_name: [data?.crmGroup?.assigned_user_name || ""],
      })
    },

    );

    data.courses?.forEach((course: any) => {
      this.addCourse(course);
    });
	data.assessmentConfig?.forEach((assessment: any) => {
		this.addAssessment(assessment);
	  });
	  data.toolsConfig?.forEach((tool: any) => {
		this.addTool(tool);
	  });


    const tenantsList = this.orgEditForm.get('tenants') as FormArray;
    data.tenants?.forEach((tenant: any) => {
      tenantsList.push(
        this.formBuilder.group({
          id: [tenant.id || ""],
          name: [tenant.name || ""],
          enabled: [tenant.enabled || false],
        }));
    });
  }

  resourceSetChanged(data: any) {
    this.resourseSet = data.resourceSet;
    this.orgEditForm.markAsDirty();
    this.log.debug('resource set changed called');
  }

  doSave() {
    this.submitButtonPressed = true;

    this.organization = new Organization(this.orgEditForm.value, 'full');
    this.errors = '';

    if (!this.orgEditForm.get('name').valid) {
      this.log.debug("the org neees a name");
      this.errors = "The organization needs a name.";
      return;
    }

    if (!this.orgEditForm.get('subdomain').valid) {
      this.errors = "The organization needs a subdomain.";
      return;
    }

    if (!this.orgEditForm.get('phone').valid) {
      this.errors = "Invalid phone number.";
      return;
    }

    if (!this.orgEditForm.get('contactGroup').get('telephone').valid) {
      this.errors = "Invalid emergency contact phone number.";
      return;
    }

    if (this.resourseSet?.enableResources && this.resourseSet?.resourceSet) {
      this.resourseSet.resourceSet.every((resource: any, index: number) => {
        if (resource.title === "") {
          this.errors = `Title of resource set ${index + 1} is empty`;
          return false;
        }

        resource.resourcesetGroup.every((group: any, r: number) => {
          if (group.title === "") {
            this.errors = `Title of resourse group ${r + 1} of resource set ${index + 1} is empty`;
            return false;
          }

          return true;
        });

        if (this.errors !== "") {
          return false;
        }

        return true;
      });
    }

    if (this.organization.enableSso) {
      if (!this.organization.auth.url && !this.organization.auth.xml) {
        this.errors = 'The organization has sso but no configuration is specified. Disable sso until a configuration url or xml can be saved';
        return;
      }
    }


    this.organization = { ...this.organization, ...this.resourseSet };
    if (this.errors === "") {
      if (this.mode === 'update') {

        this.api.put('organizations/' + this.organization.id, Organization.forApi(this.organization), true, false).subscribe(
          (data: any) => {
            this.modalService.showAlert('Success', 'Organization has been updated');
            this.log.event(AnalyticEvent.event.organizationUpdated);
            this.dialogRef.close(this.organization);
          },
          (error: any) => {
            if (error.message) {
              this.modalService.showAlert('Error', error.message);
            }
            else {
              this.modalService.showAlert('Error', 'Something went wrong. ' + error.message);
            }
          }
        );

      }
      else {
        this.log.debug(this.organization);
        this.api.post('organizations', Organization.forApi(this.organization), true, false).subscribe(
          (data: any) => {
            this.modalService.showAlert('Success', 'Organization has been created');
            this.log.event(AnalyticEvent.event.organizationCreated);
            this.dialogRef.close();
          },
          (error: any) => {
            if (error.message) {
              this.modalService.showAlert('Error', error.message);
            }
            else {
              this.modalService.showAlert('Error', 'Something went wrong. ' + error.message);
            }
          }
        );
      }
    }
  }

  get contactGroup() {
    return this.orgEditForm.get("contactGroup") as FormGroup;
  }

  get auth() {
    return this.orgEditForm.get("auth") as FormGroup;
  }

  onClose() {
    if (this.orgEditForm.dirty) {
    this.modalService
				.showConfirmation(
					"Discard Changes?",
          "Are you sure you want to close it without saving the changes?",
				)
				.afterClosed()
				.subscribe((result) => {
					if (!result) {
            return;
          }
          this.dialogRef.close();
				});
      } else {
        this.dialogRef.close();
      }
  }

  changeListener($event, type?: string): void {
    this.readThis($event.target, type);
  }
  brandingUpload($event, type?: string) {
    var file: File = $event.target.files[0];
    $event.target.files[0]
    let fileName = $event.target.files[0].name;
    var reader: FileReader = new FileReader();
    const branding = this.orgEditForm.get('brandingGroup') as FormGroup;
    if ((/\.(jpg|jpeg)$/i).test(fileName)) {
      reader.onloadend = (e) => {
        if (type === 'clientLogo') {
          branding.patchValue({
            brandingClientLogo: fileName,
            clientLogoUpload: reader.result
          });
        } else {
          branding.patchValue({
            brandingCoverPhoto: fileName,
            coverPhotoUpload: reader.result
          })
        }
      }
    }
    else {
      this.fileInput.nativeElement.value = "";
      this.modalService.showAlert('Error', 'Please ensure that the file you are uploading is in .jpg format.');
    }

    reader.readAsDataURL(file);
  }

  readThis(inputValue: any, type?): void {

    var file: File = inputValue.files[0];
    inputValue.files[0]
    let fileName = inputValue.files[0].name;
    var reader: FileReader = new FileReader();

    if ((/\.(gif|jpg|jpeg|png)$/i).test(fileName)) {
      reader.onloadend = (e) => {
        this.orgEditForm.patchValue({
          logoUpload: reader.result,
          logo: fileName,
        });
      }
    }
    else {
      this.fileInput.nativeElement.value = "";
      this.modalService.showAlert('Error', 'Please ensure that the file you are uploading is in .jpg format.');
    }

    reader.readAsDataURL(file);
  }

  get courseList() {
    return this.orgEditForm.get("courses") as FormArray;
  }

  get assessmentList() {
    return this.orgEditForm.get("assessmentConfig") as FormArray;
  }
  get toolsList() {
    return this.orgEditForm.get("toolsConfig") as FormArray;
  }

  get tenantList() {
    return this.orgEditForm.get("tenants") as FormArray;
  }

  addCourse(data: any) {
    const coursesList = this.orgEditForm.get('courses') as FormArray;
    coursesList.push(this.createCourse(data));
  }
  addAssessment(data: any) {
    const assessmentList = this.orgEditForm.get('assessmentConfig') as FormArray;
    assessmentList.push(this.createAssessment(data));
  }
  addTool(data: any) {
    const toolsList = this.orgEditForm.get('toolsConfig') as FormArray;
    toolsList.push(this.createTool(data));
  }

  createAssessment(data: any): FormGroup {
    return this.formBuilder.group({
		id: [data.id || null],
		name: [data.name || ""],
		enabled: data.enabled,
		default: data.default,
		quizID: data.quizID,
		key:data.key

    });
  }

  createTool(data: any): FormGroup {
    return this.formBuilder.group({
      id: [data.id || null],
      name: [data.name || ""],
      enabled: data.enabled,
	  default: data.default,
	  toolID: data.toolID,
	  key:data.key
    });
  }

  createCourse(data: any): FormGroup {
    return this.formBuilder.group({
      id: [data.id || 0],
      courseId: [data.courseId || ""],
      name: [data.name || ""],
      enabled: [data.enabled || false],
    });
  }

  refreshnumberResourceGroups() {
    const resourceList = this.orgEditForm.get('resourceSet') as FormArray;

    if (resourceList) {
      this.numberResourceGroups = resourceList.length;
    }
  }

  doInviteAddExec() {
    this.invitesExecutives.push();
  }
}

