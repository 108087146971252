<page-loader *ngIf="!isLoaded"></page-loader>
<div id="page-user-listing" class="page-section client-list-page-section">
    <div class="page-section">
        <div class="app-l-page-wrap">
            <div class="app-l-page__navigation">
                <div class="app-l-page__previous" [routerLink]="['/app']">
                    <button
                        class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20">
                        <svg-icon
                            icon="arrow-left"
                            class="w-100 h-100"></svg-icon>
                    </button>
                </div>
                <div class="app-l-page__breadcrumbs">
                    <ul class="breadcrumbs-list">
                        <li translate class="breadcrumbs-list-item">
                            <a [routerLink]="['/app']" translate
                                >menu.dashboard</a
                            >
                        </li>
                        <li
                            class="breadcrumbs-list-item app-l-current-page"
                            translate>
                            Invites
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="page-section-header">
                <div class="page-section-buttons">
                    <a
                        autoFocus
                        tabindex="0"
                        (click)="invite()"
                        (keydown.enter)="invite()"
                        class="trigger-modal invite btn btn-primary btn-with-icon pull-right"
                        translate>
                        <img
                            alt="Invite new user"
                            src="./assets/img/icons-svg/user-icon.svg" />Invite</a
                    >
                </div>

                <div class="page-section-buttons" >
                    <a tabindex="0" (click)="inviteSubscribeUser()" (keydown.enter)="inviteSubscribeUser()"
                        class="trigger-modal inviteshare btn btn-primary btn-with-icon pull-right" translate>
                        <img alt="Invite to Welltrack"
                            src="./assets/img/icons-svg/user-icon.svg">userListing.inviteSubscribe</a>
                </div>

                <h1 translate>Invitations</h1>
            </div> -->

            <div class="app-l-room__wrap" *ngIf="isLoaded">
                <h2 class="app-c-body-text--20-bold client-list-heading">
                    Invites
                </h2>
                <div class="app-l-client_list">
                    <div class="app-l-list__actions">
                        <div class="app-l-list__actions-block">
                            <button
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                (click)="invite()"
                                (keydown.enter)="invite()"
                                translate>
                                <svg-icon
                                    icon="invite-user-org"
                                    class="button-icon"></svg-icon>
                                <span translate>Invite</span>
                            </button>
                        </div>
                    </div>
                    <div class="app-l-room__tabs client-list-tab-section">
                        <mat-tab-group
                            mat-align-tabs="left"
                            disableRipple="true"
                            (selectedTabChange)="onTabChanged($event)">
                            <mat-tab label="Accepted" class="tab-content">
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="invites || invites.length > 0">
                                    <div class="table-responsive">
                                        <table class="table list-custom-table">
                                            <thead
                                                class="list-custom-table-header">
                                                <tr>
                                                    <th
                                                        class="list-custom-table-head list-custom-table-head-first"
                                                        translate>
                                                        Name
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Role
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Email
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Status
                                                    </th>
                                                    <!-- <th
                                                        class="list-custom-table-head list-custom-table-head-last">
                                                        Actions
                                                    </th> -->
                                                </tr>
                                            </thead>
                                            <tbody
                                                class="list-custom-table-body">
                                                <tr
                                                    *ngFor="
                                                        let invite of invites
                                                            | filter
                                                                : 'Status'
                                                                : 'accepted'
                                                    "
                                                    [ngClass]="{
                                                        highlighted:
                                                            user.isPaired
                                                    }">
                                                    <td class="strong">
                                                        {{
                                                            invite.Name
                                                                ? invite.Name
                                                                : ''
                                                        }}
                                                    </td>
                                                    <td>{{ invite.Role }}</td>
                                                    <td>
                                                        {{
                                                            invite.RecipientEmail
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="invitation-status accepted">
                                                            {{
                                                                invite.Status ===
                                                                'accepted'
                                                                    ? 'Accepted'
                                                                    : 'Pending'
                                                            }}
                                                        </span>
                                                    </td>
                                                    <!-- <td>
                                                        <div
                                                            tabindex="0"
                                                            class="action-buttons-section"
                                                            translate>
                                                            <button
                                                                class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                                tabindex="0"
                                                                (click)="
                                                                    deleteInvitation(
                                                                        invite.ID
                                                                    )
                                                                "
                                                                (keypress)="
                                                                    deleteInvitation(
                                                                        invite.ID
                                                                    )
                                                                "
                                                                translate>
                                                                <svg-icon
                                                                    icon="trash"
                                                                    alt="trash"></svg-icon>
                                                            </button>
                                                        </div>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                            <mat-tab label="Pending">
                                <div
                                    class="app-dm-positive-cards-wrapper"
                                    *ngIf="invites || invites.length > 0">
                                    <div class="table-responsive">
                                        <table class="table list-custom-table">
                                            <thead
                                                class="list-custom-table-header">
                                                <tr>
                                                    <th
                                                        class="list-custom-table-head list-custom-table-head-first"
                                                        translate>
                                                        Name
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Role
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Email
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head"
                                                        translate>
                                                        Status
                                                    </th>
                                                    <th
                                                        class="list-custom-table-head list-custom-table-head-last">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody
                                                class="list-custom-table-body">
                                                <tr
                                                    *ngFor="
                                                        let invite of invites
                                                            | filter
                                                                : 'Status'
                                                                : 'sent'
                                                    "
                                                    [ngClass]="{
                                                        highlighted:
                                                            user.isPaired
                                                    }">
                                                    <td class="strong">
                                                        {{
                                                            invite.Name
                                                                ? invite.Name
                                                                : ''
                                                        }}
                                                    </td>
                                                    <td>{{ invite.Role }}</td>
                                                    <td>
                                                        {{
                                                            invite.RecipientEmail
                                                        }}
                                                    </td>
                                                    <td>
                                                        <span
                                                            class="invitation-status"
                                                            [ngClass]="
                                                                invite.Status ===
                                                                    'sent' &&
                                                                invite.ReminderCount >
                                                                    0
                                                                    ? 'resent'
                                                                    : invite.Status
                                                            ">
                                                            {{
                                                                invite.Status ===
                                                                    'sent' &&
                                                                invite.ReminderCount >
                                                                    0
                                                                    ? 'Resent'
                                                                    : 'Pending'
                                                            }}
                                                        </span>
                                                        <!-- <span
                                                            class="invitation-status rejected"
                                                            *ngIf="
                                                                invite.Status ===
                                                                'rejected'
                                                            ">
                                                            'Rejected'
                                                        </span> -->
                                                    </td>
                                                    <td>
                                                        <div
                                                            class="action-buttons-section">
                                                            <button
                                                                class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                                tabindex="0"
                                                                (click)="
                                                                    resendInvitation(
                                                                        invite.ID,
                                                                        invite.Role
                                                                    )
                                                                "
                                                                (keypress)="
                                                                    resendInvitation(
                                                                        invite.ID
                                                                    )
                                                                "
                                                                translate>
                                                                <svg-icon
                                                                    icon="resend"
                                                                    alt="resend"></svg-icon>
                                                            </button>

                                                            <button
                                                                class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                                tabindex="0"
                                                                (click)="
                                                                    deleteInvitation(
                                                                        invite.ID
                                                                    )
                                                                "
                                                                (keypress)="
                                                                    deleteInvitation(
                                                                        invite.ID
                                                                    )
                                                                "
                                                                translate>
                                                                <svg-icon
                                                                    icon="trash"
                                                                    alt="trash"></svg-icon>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                        <p
                            *ngIf="isLoaded && invites.length === 0"
                            class="center">
                            <span translate>userListing.noneInvites</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
