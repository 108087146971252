<div class="page-static public-portal-right-col" style="height: 100%;">
	<div class="form-screen">
		<div class="img-wrapper">
			<img alt="Welltrack" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
		</div>

		<div class="title-wrapper">
			
			<h2>WellTrack now has a new name</h2>
			
			<div style="text-align: left;">
				<p>
					We're so excited about our growth! In 2020 WellTrack App was acquired by ProtoCall Services. Two
					years later, we continue to evolve and now with a brand transformation. WellTrack App is now
					Welltrack Boost.
				</p>
				<p>
					The Welltrack name that you are used to now serves as the larger brand for our ecosystem of
					behavioral health solutions. Each solution within Welltrack is integrated with each other and
					supports clients and customers across the behavioral health continuum. Learn more about all
					Welltrack Solutions <a href="https://welltrack.com/solutions">here</a>.
				</p>
				<p>
					The name Welltrack Boost better aligns with our mission of empowering individuals to work on their
					mental health and wellness in the ways that work for them. Whether used in conjunction with
					traditional therapy or on its own, Boost gives individuals the accessible support and motivation to
					take the next step to improve their mental health.
				</p>
				<p>
					What does this name change mean for you?
				</p>
				<p>
					Your username and passwords WILL NOT be changing! Your app and web application links will
					automatically be updated to the new site. Moving forward our new website is welltrack-boost.com but
					in case you get lost there will still be a way to find us through <a href="https://welltrack.com">welltrack.com</a>.
				</p>
			</div>
		</div>
	</div>
</div>