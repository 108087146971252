<div class="form-modal"
    *ngIf="(inviteType === 'NewInvite' || inviteType === 'Editinvite') && inviteType !== 'createUser' && inviteType !== 'editUser'">
    <mat-dialog-content *ngIf="userEditForm.value.roleId === 6 ">
        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1>Invite </h1>
            </div>
        </div>
        <popup-loader *ngIf="!isloaded"></popup-loader>
        <div class="form-wrapper" *ngIf="isloaded">
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="userEditForm">
                    <form novalidate [formGroup]="userEditForm">
                        <ng-template matStepLabel>Please fill your details</ng-template>
                        <mat-form-field>
                            <label for="fullName">Full Name</label>
                            <input type="text" matInput class="wt-mat-input" formControlName="fullName" id="fullName"
                                required [readonly]="inviteType == 'Editinvite'" />
                            <mat-error *ngIf="
                                f.fullName.touched &&
                                f.fullName.errors?.required
                            ">
                                Fullname is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="fullName">Email</label>
                            <input matInput class="wt-mat-input" type="text" formControlName="email" id="email" required
                                [readonly]="inviteType == 'Editinvite'" />
                            <mat-error *ngIf="
                                f.email.touched &&
                                !f.email.valid
                            ">
                                Invalid email
                            </mat-error>
                        </mat-form-field>

                        <div class="form-field">
                            <label>Receive Notifications?</label>
                            <mat-checkbox matInput type="checkbox" value="" formControlName="notificationOn">
                            </mat-checkbox>
                        </div>

                        <mat-form-field>
                            <label for="timezone">Timezone</label>
                            <div>
                                <mat-select name="timezone" formControlName="timezone">
                                    <mat-option value="" selected="selected">Select this user's timezone.</mat-option>
                                    <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">
                                        {{timezone.text}}</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="orgId" translate>Role</label>
                            <div>
                                <mat-select formControlName="roleId" (selectionChange)="onChange()">
                                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>

                        <div class="form-field">
                            <mat-checkbox formControlName="lockedRole" translate>
                                <span translate>Lock role (useful for sso)</span>
                            </mat-checkbox>
                        </div>

                        <div class="label invitemessage" class="text-left" translate>invite.msg</div>
                        <mat-form-field>
                            <textarea matInput class="wt-mat-input" tabindex="0" formControlName="message"
                                name="message" placeholder="200 character max"></textarea>
                        </mat-form-field>

                        <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                    </form>
                </mat-step>
                <mat-step [stepControl]="organizationDataForm" [optional]="false">
                    <form [formGroup]="organizationDataForm">
                        <ng-template matStepLabel>Select Organization</ng-template>
                        <label for="orgId" translate>Organization</label>
                        <multiselect-autocomplete placeholder="Select organization" formControlName="orgId"
                            [items]="multiSelOrgs" [formValue]="organizationDataForm.value.orgId"
                            (onSelectItems)="onSelectOrgs($event)"></multiselect-autocomplete>

                        <div class="btn-stepper-group">
                            <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>

                            <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p>Completed</p>
                    <div>
                        <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>
                        <button mat-button type="button" tabindex="0" class="btn-primary btn" (click)="doSave()"
                            [ngClass]="{ active: userEditForm.invalid }" [disabled]="userEditForm.invalid">
                            Send
                        </button>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="userEditForm.value.roleId !== 6 ">

        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1>Invite </h1>
            </div>
        </div>
        <popup-loader *ngIf="!isloaded"></popup-loader>
        <div class="form-wrapper" *ngIf="isloaded">
            <form novalidate [formGroup]="userEditForm" (ngSubmit)="doSave()">
                <mat-form-field>
                    <label for="fullName">Full Name</label>
                    <input matInput class="wt-mat-input" type="text" formControlName="fullName" id="fullName" />
                    <mat-error *ngIf="
                            f.fullName.touched &&
                            f.fullName.errors?.required
                        ">
                        Fullname is required
                    </mat-error>
                </mat-form-field>
                <p>Use comma delimited list if creating multiples.</p>
                <mat-form-field>
                    <label for="fullName">Email</label>
                    <input matInput class="wt-mat-input" type="text" formControlName="email" id="email" />
                    <mat-error *ngIf="
                            f.email.touched &&
                            !f.email.valid
                        ">
                        Invalid email
                    </mat-error>
                </mat-form-field>

                <div class="form-field">
                    <label>Receive Notifications?</label>
                    <mat-checkbox matInput type="checkbox" value="" formControlName="notificationOn"></mat-checkbox>
                </div>

                <mat-form-field>
                    <label for="timezone">Timezone</label>
                    <div>
                        <mat-select name="timezone" formControlName="timezone">
                            <mat-option value="" selected="selected">Select this user's timezone.</mat-option>
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">{{timezone.text}}
                            </mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <mat-form-field>
                    <label for="orgId" translate>Role</label>
                    <div>
                        <mat-select formControlName="roleId" (selectionChange)="onChange()">
                            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <div class="label invitemessage" class="text-left" translate>invite.msg</div>
                <mat-form-field>
                    <textarea matInput class="wt-mat-input" tabindex="0" formControlName="message" name="message"
                        placeholder="200 character max"></textarea>
                </mat-form-field>

                <div class="form-field">
                    <mat-checkbox formControlName="lockedRole" translate>
                        <span translate>Lock role (useful for sso)</span>
                    </mat-checkbox>
                </div>

                <div class="fixed-footer">
                    <button tabindex="0" class="btn-primary btn" [ngClass]="{ active: userEditForm.invalid }">
                        Send
                    </button>
                </div>
            </form>
        </div>
    </mat-dialog-content>
</div>


<div class="form-modal" *ngIf="inviteType === 'createUser' || inviteType === 'editUser'">
    <!--relationShip Manager User create Stepper form-->
    <mat-dialog-content *ngIf="userEditForm.value.roleId === 6 ">
        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1 *ngIf="inviteType === 'createUser'">Create user</h1>
                <h1 *ngIf="inviteType === 'editUser' || inviteType === 'editRelationShipManager'">Edit user</h1>
            </div>
        </div>
        <popup-loader *ngIf="!isloaded"></popup-loader>
        <div class="form-wrapper" *ngIf="isloaded">
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="userEditForm">
                    <form novalidate [formGroup]="userEditForm">
                        <ng-template matStepLabel>Please fill your details</ng-template>
                        <mat-form-field>
                            <label for="fullName">Full Name</label>
                            <input type="text" matInput class="wt-mat-input" formControlName="fullName" id="fullName"
                                required />
                            <mat-error *ngIf="
                                  f.fullName.touched &&
                                  f.fullName.errors?.required
                              ">
                                Fullname is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="fullName">Email</label>
                            <input matInput class="wt-mat-input" type="text" formControlName="email" id="email"
                                required />
                            <mat-error *ngIf="
                                  f.email.touched &&
                                  !f.email.valid
                              ">
                                Invalid email
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field>
                            <label for="orgId" translate>Status</label>
                            <div>
                                <mat-select formControlName="status">
                                    <mat-option value="approved">Approved</mat-option>
                                    <mat-option value="disabled">Disabled</mat-option>
                                </mat-select>
                            </div>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="orgId" translate>Password</label>
                            <div class="icon-input password-input">
                                <input matInput class="wt-mat-input" tabindex="0" aria-label="Password" type="{{passwordType}}"
                                    formControlName="password" (ngModelChange)="onChangePassword()" />
                                 <i  class="icon-password-eye" (click)="onClickPassword()"  style="cursor: pointer;"[ngClass]="passwordType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"  ></i>
                            </div>
                            <mat-error *ngIf="
                                f.password.touched &&
                                !f.password.valid
                            ">
                                Invalid password
                            </mat-error>
                        </mat-form-field>
                        <div class="icon-input" style="margin:10px 0px;">
                            <label>Receive Notifications?</label>
                            <mat-checkbox value="" formControlName="notificationOn"></mat-checkbox>
                        </div>

                        <mat-form-field>
                            <label for="timezone">Timezone</label>
                            <mat-select name="timezone" formControlName="timezone">
                                <mat-option value="" selected="selected">Select this user's timezone.</mat-option>
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">{{timezone.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="orgId" translate>Role</label>
                            <mat-select formControlName="roleId" required>
                                <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p>
                            <mat-checkbox formControlName="lockedRole" translate>
                                <span translate>Lock role (useful in the case of sso)</span>
                            </mat-checkbox>
                        </p>
                        <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                    </form>
                </mat-step>
                <mat-step [stepControl]="organizationDataForm" [optional]="false">
                    <form [formGroup]="organizationDataForm">
                        <ng-template matStepLabel>Select Organization</ng-template>
                        <label for="orgId" translate>Organization</label>

                        <multiselect-autocomplete placeholder="Select organization" formControlName="orgId"
                            [items]="multiSelOrgs" [formValue]="organizationDataForm.value.orgId"
                            (onSelectItems)="onSelectOrgs($event)"></multiselect-autocomplete>
                        <div class="btn-stepper-group">
                            <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>

                            <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p>Completed</p>
                    <div>
                        <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>
                        <button mat-button type="button" tabindex="0" class="btn-primary btn" (click)="doSave()"
                            [ngClass]="{ active: userEditForm.invalid }" [disabled]="userEditForm.invalid">
                            Save
                        </button>
                    </div>
                </mat-step>
            </mat-vertical-stepper>


        </div>
    </mat-dialog-content>
    <mat-dialog-content *ngIf="userEditForm.value.roleId !== 6 ">

        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1 *ngIf="inviteType === 'createUser'">Create user</h1>
                <h1 *ngIf="inviteType === 'editUser' || inviteType === 'editRelationShipManager'">Edit user</h1>
            </div>
        </div>
        <popup-loader *ngIf="!isloaded"></popup-loader>
        <div class="form-wrapper" *ngIf="isloaded">
            <form novalidate [formGroup]="userEditForm" (ngSubmit)="doSave()">
                <mat-form-field>
                    <label for="fullName">Full Name</label>
                    <input matInput class="wt-mat-input" type="text" formControlName="fullName" id="fullName" />
                    <mat-error *ngIf="
                            f.fullName.touched &&
                            f.fullName.errors?.required
                        ">
                        Fullname is required
                    </mat-error>
                </mat-form-field>
                <p>Use comma delimited list if creating multiples.</p>
                <mat-form-field>
                    <label for="fullName">Email</label>
                    <input matInput class="wt-mat-input" type="text" formControlName="email" id="email" />
                    <mat-error *ngIf="
                            f.email.touched &&
                            !f.email.valid
                        ">
                        Invalid email
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <label for="orgId" translate>Status</label>
                    <div>
                        <mat-select formControlName="status">
                            <mat-option value="approved">Approved</mat-option>
                            <mat-option value="disabled">Disabled</mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <mat-form-field>
                    <label for="orgId" translate>Password</label>
                    <div class="icon-input password-input">
                        <input matInput class="wt-mat-input" tabindex="0" aria-label="Password" type="{{passwordType}}"
                            formControlName="password" (ngModelChange)="onChangePassword()" />
                            <i  class="icon-password-eye" (click)="onClickPassword()"  style="cursor: pointer;"[ngClass]="passwordType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"  ></i>
                    </div>
                    <mat-error *ngIf="
                            f.password.touched &&
                            !f.password.valid
                        ">
                        Invalid password
                    </mat-error>
                </mat-form-field>

                <div class="form-field">
                    <label>Receive Notifications?</label>
                    <mat-checkbox matInput type="checkbox" value="" formControlName="notificationOn"></mat-checkbox>
                </div>

                <mat-form-field>
                    <label for="timezone">Timezone</label>
                    <div>
                        <mat-select name="timezone" formControlName="timezone">
                            <mat-option value="" selected="selected">Select this user's timezone.</mat-option>
                            <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">{{timezone.text}}
                            </mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <mat-form-field>
                    <label for="orgId" translate>Role</label>
                    <div>
                        <mat-select formControlName="roleId" (selectionChange)="onChange()">
                            <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <mat-form-field *ngIf="userEditForm.value.roleId !== 7 ">
                    <label for="orgId" translate>Organization</label>
                    <div>
                        <mat-select formControlName="orgId" id="orgId">
                            <mat-option [value]="-1">None</mat-option>
                            <mat-option *ngFor="let organization of organizations" [value]="organization.id">
                                {{organization.name}}</mat-option>
                        </mat-select>
                    </div>
                </mat-form-field>

                <div class="form-field">
                    <mat-checkbox formControlName="lockedRole" translate>
                        <span translate>Lock role (useful for sso)</span>
                    </mat-checkbox>
                </div>

                <div class="fixed-footer">
                    <button tabindex="0" class="btn-primary btn" [ngClass]="{ active: userEditForm.invalid }">
                        Save
                    </button>
                </div>
            </form>
        </div>
    </mat-dialog-content>


</div>

<!---RelationShip manager Edit user-->
<div class="form-modal" *ngIf="inviteType === 'editRelationShipManager'">
    <!--relationShip Manager User create Stepper form-->
    <mat-dialog-content>
        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1>Edit user</h1>
            </div>
        </div>
        <popup-loader *ngIf="!isloaded"></popup-loader>
        <div class="form-wrapper" *ngIf="isloaded">
            <mat-vertical-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="userEditForm">
                    <form novalidate [formGroup]="userEditForm">
                        <ng-template matStepLabel>Please fill your details</ng-template>
                        <mat-form-field>
                            <label for="fullName">Full Name</label>
                            <input type="text" matInput class="wt-mat-input" formControlName="fullName" id="fullName"
                                required />
                            <mat-error *ngIf="
                                  f.fullName.touched &&
                                  f.fullName.errors?.required
                              ">
                                Fullname is required
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="fullName">Email</label>
                            <input matInput class="wt-mat-input" type="text" formControlName="email" id="email"
                                required />
                            <mat-error *ngIf="
                                  f.email.touched &&
                                  !f.email.valid
                              ">
                                Invalid email
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field style="display: none;">
                            <label for="orgId" translate>Password</label>
                            <div class="icon-input password-input">
                                <input matInput class="wt-mat-input" tabindex="0" aria-label="Password" type="{{passwordType}}"
                                    formControlName="password" (ngModelChange)="onChangePassword()" />
                                <i  class="icon-password-eye" (click)="onClickPassword()"  style="cursor: pointer;"[ngClass]="passwordType == 'password'? 'fa fa-eye' : 'fa fa-eye-slash'"  ></i>
          
                            </div>
                            <mat-error *ngIf="
                            f.password.touched &&
                            !f.password.valid
                          ">
                                Invalid password
                            </mat-error>
                        </mat-form-field>
                        <div class="icon-input" style="margin:10px 0px;">
                            <label>Receive Notifications?</label>
                            <mat-checkbox value="" formControlName="notificationOn"></mat-checkbox>
                        </div>

                        <mat-form-field>
                            <label for="timezone">Timezone</label>
                            <mat-select name="timezone" formControlName="timezone">
                                <mat-option value="" selected="selected">Select this user's timezone.</mat-option>
                                <mat-option *ngFor="let timezone of timezones" [value]="timezone.key">{{timezone.text}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <label for="orgId" translate>Role</label>
                            <mat-select formControlName="roleId" required
                                (selectionChange)="onChange('fromInternalUserEdit')">
                                <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <p>
                            <mat-checkbox formControlName="lockedRole" translate>
                                <span translate>Lock role (useful in the case of sso)</span>
                            </mat-checkbox>
                        </p>
                        <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                    </form>
                </mat-step>
                <mat-step [stepControl]="organizationDataForm" [optional]="false">
                    <form [formGroup]="organizationDataForm">
                        <ng-template matStepLabel>Select Organization</ng-template>
                        <label for="orgId" translate>Organization</label>
                        <multiselect-autocomplete placeholder="Select organization" formControlName="orgId"
                            [items]="multiSelOrgs" [formValue]="organizationDataForm.value.orgId"
                            (onSelectItems)="onSelectOrgs($event)"></multiselect-autocomplete>
                        <div class="btn-stepper-group">
                            <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>

                            <button mat-button class="btn-primary btn" matStepperNext>Next</button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Done</ng-template>
                    <p>Completed</p>
                    <div>
                        <button mat-button class="btn-primary btn" matStepperPrevious>Back</button>
                        <button mat-button type="button" tabindex="0" class="btn-primary btn" (click)="doSave()"
                            [ngClass]="{ active: userEditForm.invalid }" [disabled]="userEditForm.invalid">
                            Save
                        </button>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>
    </mat-dialog-content>
</div>