<menu-header></menu-header>
<!-- 
		Combine this and orgadmin menu template
	-->
<nav id="nav" role="navigation">
	<a tabindex="0" [ngClass]="{ active: '/app' == menuService.pathActive || '' == menuService.pathActive }"
		routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="menuService.nav('/app')"
		(keypress)="menuService.nav('/app')">
		<img alt="Dashboard" class="icon" src="/assets/img/icons/dashboard@2x.png" style="height: 20px" />
		<span translate>menu.dashboard</span>
	</a>

	<ng-container *accessControl="'clientList'">
		<a tabindex="0" [ngClass]="{ active: '/professional/users' == menuService.pathActive }"
			routerLinkActive="active" (click)="menuService.nav('/professional/users')"
			(keypress)="menuService.nav('/professional/users')">
			<img alt="Client list" class="icon" src="./assets/img/icons/user-icon@2x.png" style="height: 20px" />
			<span translate>menu.clientList</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'wellnessAlerts'">
		<a tabindex="0" [ngClass]="{
		active: '/app/alerts/listing' == menuService.pathActive,
		disabled: menuService.locked
	}" routerLinkActive="active" *ngIf="menuService.userType == 'orgadmin' && hasAssessmentAlerts"
			(click)="menuService.nav('/app/alerts/listing')" (keypress)="menuService.nav('/app/alerts/listing')">
			<img alt="Alerts" class="icon" src="./assets/img/icons/assessment_icon@2x.png" style="height: 20px" />
			<span translate>menu.alerts</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'orgDetails'">
		<a tabindex="0" [ngClass]="{
			active: '/executive/organization-edit' == menuService.pathActive,
			disabled: menuService.locked
		}" routerLinkActive="active" (click)="menuService.nav('/executive/organization-edit')"
			(keypress)="menuService.nav('/executive/organization-edit')">
			<img alt="Organization details" class="icon" src="./assets/img/icons/settings@2x.png"
				style="height: 20px" />
			<span translate>menu.orgDetails</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'moodcheck'">
		<a tabindex="0" [ngClass]="{ disabled: menuService.locked }" (click)="menuService.onMoodCheck()"
			(keypress)="menuService.onMoodCheck()">
			<img alt="Moodcheck" class="icon" src="/assets/img/icons/moodcheck@2x.png" style="height: 19px" />
			<span translate>menu.moodcheck</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'courses'">
		<a tabindex="0"
			[ngClass]="{ active:  ('/app/series' == menuService.pathActive || menuService.pathActive.search('course-listing') !== -1), disabled: menuService.locked }"
			routerLinkActive="active" (click)="menuService.nav('/app/series')"
			(keypress)="menuService.nav('/app/series')" [ngClass]="{ disabled: menuService.locked }">
			<img alt="Courses" class="icon" src="/assets/img/icons/practice@2x.png" style="height: 20px" />
			<span translate>menu.courses</span>
		</a>
	</ng-container>

	<!--
	<ng-container *accessControl="'podcasts'">
		<a tabindex="0" [ngClass]="{ active: '/app/podcasts' == menuService.pathActive, disabled: menuService.locked }"
			routerLinkActive="active" (click)="menuService.nav('/app/podcasts')"
			(keypress)="menuService.nav('/app/podcasts')" [ngClass]="{ disabled: menuService.locked }">
			<img alt="Courses" class="icon" src="/assets/img/icons/podcast-mic@2x.png" style="height: 20px" />
			<span translate>menu.podcasts</span>
		</a>
	</ng-container>
	-->
	<!--*ngIf="this.user.primaryOrganization.id === 2644"-->
	<ng-container *accessControl="'wellnessDomains'">
		<a tabindex="0" *ngIf="menuService.enableCoreContributors" [ngClass]="{
			active: '/app/corecontributors' == menuService.pathActive,
			disabled: menuService.locked
		}" routerLinkActive="active" (click)="menuService.nav('/app/corecontributors')"
			(keypress)="menuService.nav('/app/corecontributors')" [ngClass]="{ disabled: menuService.locked }">
			<img alt="Domains" class="icon" src="/assets/img/icons/resources@2x.png" style="height: 20px" />
			<span translate>menu.wellnessDomains</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'practice'">
		<a *accessControl="'practice'" tabindex="0"
			[ngClass]="{ active: '/app/practice' == menuService.pathActive, disabled: menuService.locked }"
			routerLinkActive="active" (click)="menuService.nav('/app/practice')"
			(keypress)="menuService.nav('/app/practice')" [ngClass]="{ disabled: menuService.locked }">
			<img alt="Tools" class="icon" src="/assets/img/icons/theory@2x.png" style="height: 20px" />
			<span translate>menu.tools</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'calendar'">
		<a tabindex="0" [ngClass]="{ active: '/app/calendar' == menuService.pathActive, disabled: menuService.locked }"
			routerLinkActive="active" (click)="menuService.nav('/app/calendar')"
			(keypress)="menuService.nav('/app/calendar')">
			<img alt="Calendar" class="icon" src="/assets/img/icons/calendar@2x.png" style="height: 20px" />
			<span translate>menu.calendar</span>
			<div class="notifications" *ngIf="menuService.user.eventCount > 0">
				{{ menuService.user.eventCount }}
			</div>
		</a>
	</ng-container>

	<!--
	<ng-container *accessControl="'assessment'">
		<a tabindex="0" [ngClass]="{
				active: '/app/assessments' == menuService.pathActive,
				disabled: menuService.locked
			}" routerLinkActive="active" *ngIf="menuService.defaultAssessment === 'resilience'"
			(click)="menuService.nav('/app/assessments')" (keypress)="menuService.nav('/app/assessments')">
			<img alt="Assessment" class="icon" src="./assets/img/icons/assessment_icon@2x.png" style="height: 20px" />
			<span translate>menu.assessments</span>
		</a>
	</ng-container>
-->
	<ng-container *accessControl="'assessment'">
		<a tabindex="0" [ngClass]="{
			active: '/app/assessment' == menuService.pathActive,
			disabled: menuService.locked
		}" routerLinkActive="active" *ngIf="menuService.defaultAssessment === 'das'"
			(click)="menuService.nav('/app/assessments/listing')"
			(keypress)="menuService.nav('/app/assessments/listing')">
			<img alt="Assessment" class="icon" src="./assets/img/icons/assessment_icon@2x.png" style="height: 20px" />
			<span translate>menu.assessment</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'emergencyContact'">
		<a tabindex="0" [ngClass]="{
			active: '/app/emergency-contact' == menuService.pathActive,
			disabled: menuService.locked
		}" class="secondary" routerLinkActive="active" (click)="menuService.nav('/app/emergency-contact')"
			(keypress)="menuService.nav('/app/emergency-contact')" *ngIf="menuService.user.primaryOrganization">
			<img alt="Emergency contact" class="icon" src="/assets/img/icons/hand@2x.png" style="height: 22px" />
			<span translate>menu.emergency</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'resources'">
		<a tabindex="0" [ngClass]="{ active: '/app/resources' == menuService.pathActive, disabled: menuService.locked }"
			class="secondary" routerLinkActive="active" *ngIf="menuService.enableResources"
			(click)="menuService.nav('/app/resources')" (keypress)="menuService.nav('/app/resources')">
			<img alt="Resources" class="icon" src="/assets/img/icons/resources@2x.png" style="height: 22px" />
			<span translate>menu.resources</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'settings'">
		<a tabindex="0" [ngClass]="{ active: '/app/settings' == menuService.pathActive, disabled: menuService.locked }"
			class="secondary" routerLinkActive="active" (click)="menuService.nav('/app/settings')"
			(keypress)="menuService.nav('/app/settings')">
			<img alt="Settings" class="icon" src="./assets/img/icons/settings@2x.png" style="height: 15px" />
			<span translate>menu.setting</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'support'">
		<a tabindex="0" [ngClass]="{ active: '/app/support' == menuService.pathActive, disabled: menuService.locked }"
			class="secondary" routerLinkActive="active" (click)="menuService.onSupportClick()"
			(keypress)="menuService.onSupportClick()">
			<i class="fa fa-envelope" aria-hidden="true"></i>
			<span translate>menu.support</span>
		</a>
	</ng-container>
</nav>
<menu-footer></menu-footer>