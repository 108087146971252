<div class="app-l-page-wrap">
    <div id="general" class="app-c-card">
        <div class="app-c-card__header">
            <div class="app-l-card__h-left">
                <h3 class="app-l-card__title" translate>resources.recommendedResources</h3>
            </div>
            <div class="app-l-card__h-right">
                <button class="app-c-btn app-c-btn--curve app-c-btn--secondary" (click)="menuService.nav('/app/emergency-contact')"
			(keypress)="menuService.nav('/app/emergency-contact')">
                    <svg-icon icon="get-help"></svg-icon>
                    <span translate>resources.getHelpNow</span>
                </button>
            </div>
        </div>
        <div class="app-l-resource__options">
            <ng-container *ngFor="let filter of filters">
                <button *ngIf="hasFilteredObjects(filter)"
                  class="app-c-btn app-c-btn--tertiary"
                  [ngClass]="{ active: selectedFilter == filter }"
                  (click)="filterChange(filter)"
                  translate
                >
                 {{ 'resources.filterTypes.' + filter | translate }}
                </button>
              </ng-container>
        </div>
        <div class="app-c-card__body" *ngIf="!isObjectEmpty()">
            
                <!-- <div class="content-header step{{resourceSet?.number}}" aria-live="assertive">
                    <h3>{{resourceSet?.title}}</h3>
                </div> -->
                <p [innerHTML]="resourceSet?.summary" *ngIf="resourceSet?.summary"></p>
                <div class="app-l-r__warning"
                    *ngIf="
                      resourceSet.className == 'sexual' ||
                      resourceSet.className == 'substance'
                    "
                >
                    <div class="app-l-r__w-icon">
                        <svg-icon icon="warning-circle" class="w-100 h-100"></svg-icon>
                    </div>
                    <div class="app-l-r__text app-c-body-text--14">
                        <span translate>resources.seekMedicalAttention</span>
                    </div>
                </div>
                <div class="app-l-resource-results">
                    <div *ngIf="resourceSet?.videos[0]?.length > 0" class="--d-flex">
                        <div class="resource-desc">
                        <div *ngIf="resourceSet?.videos[0].label" class="title">{{resourceSet?.videos[0].label}}</div>
                        <div *ngIf="resourceSet?.videos[0].length" class="title">{{resourceSet?.videos[0].length}}</div>
                        <p *ngIf="resourceSet?.videos[0].description" [innerHTML]="resourceSet?.videos[0].description" class="description"></p>
                        </div>
                        
                        <div class="preview video-preview column" *ngIf="resourceSet?.videos[0].media">
                            <wt-video [mediaUrl]="resourceSet?.videos[0].media"  [thumbnailUrl]="resourceSet?.videos[0].image"
                            [captionUrl]="resourceSet?.videos[0].captionFile"></wt-video>
                        </div>
                    </div>
                    <div class="app-l-resource__block" *ngFor="let contact of resourceSet?.resourcesetGroup; let i = index; ">
                        <div *ngIf="!contact.website || contact.websiteTitle" class="title">{{contact.title}}</div>
                        <h5 *ngIf="contact.website && !contact.websiteTitle" class="app-c-body-text--14-m"><a (click)="onResourceClick(contact)">{{contact.title}}</a></h5>
                        <p class="app-c-body-text--14" *ngIf="contact.description" [innerHTML]="contact.description"></p>
                        <p  class="app-c-body-text--14" *ngIf="contact.contact"> {{contact.contact}}</p>
                        <p  class="app-c-body-text--14" *ngIf="contact.alternateContact">{{contact.alternateContact}}</p>
                        <p  class="app-c-body-text--14" *ngIf="contact.address">{{contact.address}}</p>
                        <p  class="app-c-body-text--12" *ngIf="contact.website"><a (click)="onResourceClick(contact)">{{contact.websiteTitle ? contact.websiteTitle : contact.website }}</a></p>
                        <a *ngIf="contact.internal" tabindex="0" (click)="onResourceInternalClick(contact)" (click)="onResourceInternalClick(contact)" class="app-c-btn app-c-btn--text-primary app-c-btn--text" translate>Go to resource</a>
                    </div>
                </div>
        </div>
        <div *ngIf="isObjectEmpty()" class="app-c-card__body empty-content">
            <h2 class="content-header" translate>resources.empty</h2>
        </div>
        <!-- <div class="fixed-footer">
            <a tabindex="0" (click)="onFinish()" (keypress)="onFinish()" class="btn btn-primary" translate>Close</a>
        </div> -->
    </div>
</div>