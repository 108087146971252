import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "app/lib/user.service";
import { UtilityService } from "app/lib/utility.service";
import { User } from "app/models/user";
import * as moment from "moment";
import { Subject } from "rxjs";
import * as _ from "lodash";
import {
	MatCalendarCellClassFunction,
	MatDatepicker,
	MatDatepickerInputEvent,
} from "@angular/material/datepicker";
import { DatePipe } from "@angular/common";
import { ModalService } from "app/lib/modal.service";
import { ActivitySchedulerDetails } from "../../modules/practice/activity-scheduler-details/activity-scheduler-details";
import { ApiService } from "app/lib/api.service";
import { LogService } from "app/lib/log.service";
import { Event } from "../../models/event";
import { AnalyticEvent } from "app/lib/analytic-event";
@Component({
	selector: "app-weekly-calendar-view",
	templateUrl: "./weekly-calendar-view.component.html",
	styleUrls: ["./weekly-calendar-view.component.scss"],
	providers: [DatePipe],
})
export class WeeklyCalendarViewComponent implements OnInit {
	selected: Date | null;
	@Output() emitDailyViewData = new EventEmitter<any>();
	calendarData: any = [];
	user: User;
	hasData: boolean = false;
	isLoading: boolean = true;
	viewDate: Date = new Date();
	selectedDate: any = moment.parseZone().format();
	today: Date;
	refresh = new Subject<void>();
	startOfWeek: any;
	endOfWeek: any;
	selectedDateFormated: Date = new Date();
	showSideView: boolean = false;
	selectedFilter: string = "all";
	filterList = [
		"all",
		"activityscheduler",
		"courses",
		"appointment",
		"homework",
	];
	detailViewData: any;
	currentDateData: any;
	dateFilteredEvnets: any = [];
	calendarList: Event[];
	selectDate: MatCalendarCellClassFunction<Date>;
	displayDate: any = moment().format("MMM");
	weekNumber: any;
	weekStartDate: any;
	weekEndDate: any;

	constructor(
		private utility: UtilityService,
		private userService: UserService,
		private api: ApiService,
		private log: LogService,
		private router: Router,
		private datePipe: DatePipe,
		private modalService: ModalService
	) {
		this.user = this.userService.getUser();
		this.today = new Date();
	}

	ngOnInit(): void {
		this.weekStartDate = moment().startOf("week").format();
		this.weekEndDate = moment().endOf("week").format();
		this.weekNumber = this.getWeekNumber();
	}

	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.fetchEvents();
			});
	}

	fetchEvents(): void {
		this.api.get("calendar/all", { creatorView: true }).subscribe(
			(result: any) => {
				const events = Event.initializeArray(result.data, true);
				if (this.user.userType === "user") {
					events.forEach((eventClass, index) => {
						eventClass["cssClass"] = "";
					});
				}
				this.log.event(AnalyticEvent.event.calendarLoad);
				let currentDate = moment.parseZone().startOf("day").format();
				this.updateWeeklyCalendarView(events, currentDate);
			},
			(error: any) => {
				this.log.error(
					"Error getting calendar events." + error.message
				);
			},
			() => {}
		);
		this.refresh.next();
	}
	openDatePicker(picker: MatDatepicker<Date>) {
		picker.open();
	}

	updateWeeklyCalendarView(data: any, currentDate?: any) {
		this.calendarData = data;
		this.calendarData.forEach((data) => {
			data.end = "";
		});
		this.selectedDate = currentDate;
		this.selectedDateFormated = new Date(this.selectedDate);
		this.viewDate = UtilityService.convertToDate(
			moment(currentDate).format("YYYY-MM-DD")
		);
		this.displayDate = moment(this.selectedDate).format("MMM");
		this.startOfWeek = this.getStartOfWeek(this.selectedDateFormated);
		this.endOfWeek = this.getEndOfWeek(this.selectedDateFormated);
		this.weekNumber = this.getWeekNumber();
		this.dateFilteredEvnets = this.generateDateObjectOfEvents();
		this.setCurrentDateData();
		this.refresh.next();
	}

	dateSelected(event): void {
		this.selectDate = event;
		this.updateWeeklyCalendarView(
			this.calendarData,
			moment.parseZone(event).startOf("day").format()
		);
	}

	getWeekNumber() {
		const dateMoment = moment(this.selectedDate);
		const firstDayOfMonth = dateMoment.clone().startOf("month");
		const weekNumber = dateMoment.diff(firstDayOfMonth, "weeks") + 1;
		return weekNumber;
	}

	generateDateObjectOfEvents() {
		let dateObjOfEvents = {};
		for (let i = 0; i < this.calendarData.length; i++) {
			let eventStartDate = moment
				.parseZone(this.calendarData[i].start)
				.format("L");

			if (!dateObjOfEvents[eventStartDate]) {
				dateObjOfEvents[eventStartDate] = {
					data: [],
				};
			}
			dateObjOfEvents[eventStartDate].data.push(this.calendarData[i]);
		}
		return dateObjOfEvents;
	}

	setCurrentDateData() {
		this.detailViewData = [];
		let data = this.dateFilteredEvnets[
			moment(this.selectedDate).format("L")
		]
			? this.dateFilteredEvnets[moment(this.selectedDate).format("L")]
					.data
			: [];

		if (this.selectedFilter != "all") {
			if (
				["activityscheduler", "courses", "appointment"].includes(
					this.selectedFilter
				)
			) {
				this.detailViewData = _.filter(data, {
					category: this.selectedFilter as any,
				});
			} else {
				this.detailViewData = data.filter(
					(item) =>
						![
							"activityscheduler",
							"courses",
							"appointment",
						].includes(item.category)
				);
			}
		} else {
			this.detailViewData = data;
		}
		this.refresh.next();
	}

	setCalendarDate() {
		this.viewDate = UtilityService.convertToDate(
			moment(this.selectedDate).format("YYYY-MM-DD")
		);
		this.startOfWeek = this.getStartOfWeek(this.selectedDate);
		this.endOfWeek = this.getEndOfWeek(this.selectedDate);
	}

	openData(day) {
		this.emitDailyViewData.emit({
			date: moment(day.date).parseZone().startOf("day").format(),
		});
	}

	onEventSelect(eventType: string, event: any, index: number) {
		switch (event) {
			// case "activityscheduler": {
			//   this.openEditActivityModal(event,index);
			//   break;
			// }
			case "assessment": {
				this.router.navigateByUrl("app/assessment/dass");
				break;
			}
			case "courses": {
				this.goToCourse(event.courseKey);
				break;
			}
			case "moodcheck": {
				this.router.navigateByUrl("app/moodcheck");
				break;
			}
			case "zenroom": {
				this.goToZen();
				break;
			}
			case "quiz": {
				this.goToQuiz();
				break;
			}
			case "funachievement": {
				this.goToFun();
				break;
			}
			case "thoughtdiary": {
				this.goToThought();
				break;
			}
		}
	}

	disableTooltip(event: MouseEvent) {
		event.preventDefault();
	}

	onClickNextBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(7, "days").format();
		this.selectedDate = date;
		this.displayDate = moment(date).format("MMM");
		this.weekNumber = this.getWeekNumber();
		this.selectedDateFormated = new Date(this.selectedDate);
		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;
		this.startOfWeek = this.getStartOfWeek(new Date(this.selectedDate));
		this.endOfWeek = this.getEndOfWeek(new Date(this.selectedDate));
	}

	onClickPrevBtn() {
		if (this.showSideView) {
			this.showSideView = false;
		}
		let date = moment(this.selectedDate).add(-7, "days").format();
		this.selectedDate = date;
		this.displayDate = moment(date).format("MMM");
		this.weekNumber = this.getWeekNumber();
		this.selectedDateFormated = new Date(this.selectedDate);
		this.viewDate = UtilityService.convertToDate(
			moment(date).format("YYYY-MM-DD")
		);
		this.selectDate = this.selectedDate;
		this.startOfWeek = this.getStartOfWeek(this.selectedDateFormated);
		this.endOfWeek = this.getEndOfWeek(this.selectedDateFormated);
	}

	onDateSelected(event: MatDatepickerInputEvent<Date>) {
		this.selectedDate = event.value;
	}

	goToCourse(courseKey) {
		this.router.navigateByUrl("theory/series-details/" + courseKey);
	}
	goToAssessment() {
		this.router.navigate(["/assessment/das"]);
	}

	goToZen() {
		this.router.navigate(["/practice/zen-room"]);
	}

	goToThought() {
		this.router.navigate(["/practice/thought-diary"]);
	}

	goToQuiz() {
		this.router.navigate(["/practice/cognitive-quiz/listing"]);
	}

	goToFun() {
		this.router.navigate(["/practice/fun-achievement/listing"]);
	}

	getStartOfWeek(date: Date): string {
		const startOfWeek = new Date(date);
		startOfWeek.setDate(date.getDate() - date.getDay()); // Adjust for Sunday as the start of the week
		return this.datePipe.transform(startOfWeek, "mediumDate");
	}

	getEndOfWeek(date: Date): string {
		const endOfWeek = new Date(date);
		endOfWeek.setDate(date.getDate() + (6 - date.getDay())); // Adjust for Saturday as the end of the week
		return this.datePipe.transform(endOfWeek, "mediumDate");
	}

	toggleSideView(event, val) {
		this.selectedDate = this.datePipe.transform(
			event,
			"yyyy-MM-ddTHH:mm:ssZ"
		);
		this.onSelectFilter("all");
		if (val === "true") {
			this.showSideView = true;
		} else {
			this.showSideView = !this.showSideView;
		}
	}

	onSelectFilter(type) {
		this.selectedFilter = type;
		this.setCurrentDateData();
	}

	async openEditActivityModal(type, eventData: any, itemIndex: number) {
		this.modalService
			.showComponent(
				ActivitySchedulerDetails,
				{ activityId: eventData.data.id },
				"app-c-modal--cure",
				false,
				eventData.data.id ? "400px" : ""
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					switch (data.action) {
						case "save": {
							const inputDate = moment(
								data.activityData.start
							).startOf("day");
							let event =
								this.dateFilteredEvnets[
									moment(this.selectedDate).format("L")
								].data[itemIndex];
							const calendarIndex = this.calendarData.findIndex(
								(item) => item.id === event.id
							);
							if (calendarIndex !== -1) {
								this.calendarData[
									calendarIndex
								].activityStatus = data.activityData.status;
								this.calendarData[calendarIndex].data =
									data.activityData;
								this.calendarData[calendarIndex].start =
									data.activityData.start;
								this.calendarData[calendarIndex].startDate =
									data.activityData.start;
							}
							event.activityStatus = data.activityData.status;
							event.data = data.activityData;
							if (
								!moment(this.selectedDate)
									.startOf("day")
									.isSame(inputDate)
							) {
								this.dateFilteredEvnets[
									moment(this.selectedDate).format("L")
								].data.splice(itemIndex, 1);
								if (
									!this.dateFilteredEvnets[
										moment(inputDate).format("L")
									]
								) {
									this.dateFilteredEvnets[
										moment(inputDate).format("L")
									] = {
										data: [],
									};
								}
								this.dateFilteredEvnets[
									moment(inputDate).format("L")
								].data.push(event);
							}
							break;
						}
						case "delete": {
							this.calendarData[
								moment(this.selectedDate).format("L")
							].data.splice(itemIndex, 1);
							break;
						}
					}
					this.refresh.next();
				}
			});
	}

	async setMarkAsCompleted(type, eventData: any, itemIndex: number) {
		this.modalService
			.showConfirmation(
				"Mark as complete",
				"Are you sure you want to mark this activity as completed?"
			)
			.afterClosed()
			.subscribe((data) => {
				if (data) {
					this.api
						.post(
							`practice/activityscheduler/updatestatus/${eventData.data.id}?Status=complete`,
							""
						)
						.subscribe(
							(result: any) => {
								if (result) {
									this.api
										.get("calendar/all", {
											creatorView: true,
										})
										.subscribe((listData: any) => {
											this.calendarList =
												Event.initializeArray(
													listData.data,
													true
												);
											this.log.event(
												AnalyticEvent.event
													.markAsCompleteActivity
											);
											const calendarIndex =
												this.calendarList.findIndex(
													(item) =>
														item.id ===
														eventData.data.id
												);
											if (calendarIndex !== -1) {
												let event =
													this.dateFilteredEvnets[
														moment(
															this.selectedDate
														).format("L")
													].data[itemIndex];
												event.data.status =
													this.calendarList[
														calendarIndex
													].data.status;
												this.modalService.showAlert(
													"Success",
													result.message
												);
											}
										});
								}
							},
							(error: any) => {
								this.log.error("Error in updating status. ");
							}
						);
				}
			});
	}
}
