<div id="invite" [ngClass]="type">
    <!-- <div class="img-wrapper"> -->
    <!-- <img src="./assets/img/professional/flyingletter@2x.png" alt=""> -->
    <div class="clearfix"></div>
    <div class="title-wrapper">
        <h2 translate>Invitation</h2>
        <a
            tabindex="0"
            (click)="close()"
            (keypress)="close()"
            class="close-modal"
            aria-label="Close Invite form">
            <img src="./assets/img/icons-svg/close-icon.svg" />
        </a>
        <!-- <h2 *ngIf="!showShare" translate>invite.professional.titleShare</h2>
		<p translate *ngIf="showShare">invite.professional.body</p>
		<p translate *ngIf="!showShare">invite.professional.bodyShare</p> -->
    </div>
    <!-- </div> -->

    <form novalidate [formGroup]="inviteForm" (ngSubmit)="sendInvite()">
        <div class="form-wrapper">
            <label for="email" class="app-c-body-text--14-m" translate
                >Email</label
            >
            <mat-form-field appearance="outline" class="wt-mat-form-field">
                <img
                    alt="Email validation checkbox"
                    *ngIf="f.email.touched && !f.email.errors"
                    src="./assets/img/icons/check@2x.png"
                    class="float-right check animated fadeInUp"
                    item-content />
                <input
                    matInput
                    class="wt-mat-input"
                    tabindex="0"
                    formControlName="email"
                    name="email"
                    type="text"
                    placeholder="Enter the user's email" />
                <mat-error *ngIf="f.email.touched && f.email.errors?.required">
                    Please enter a valid email
                </mat-error>
            </mat-form-field>
            <!-- <div class="sharing" *ngIf="showShare">
				<mat-checkbox
					matInput
					name="share"
					type="checkbox"
					formControlName="share"
				></mat-checkbox>
				<img alt="Cloud icon" height="15" src="./assets/img/icons/cloud_orange.png">
				<label translate>invite.share</label>
			</div> -->
            <!-- <div class="label invitemessage" class="text-left" translate>
				invite.msg
			</div> -->
            <label for="role" class="app-c-body-text--14-m" translate
                >Role</label
            >
            <mat-form-field
                appearance="outline"
                class="wt-mat-form-field"
                [ngClass]="{
                    disable: this.inviteType === 'clientInvite'
                }">
                <mat-select
                    formControlName="role"
                    placeholder="Please select the role"
                    (selectionChange)="showShareOpt()">
                    <mat-option *ngFor="let role of roles" [value]="role.value">
                        {{ role.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.role.touched && f.role.errors?.required">
                    Please select a role
                </mat-error>
            </mat-form-field>
            <div class="sharing" *ngIf="showShare">
                <mat-checkbox
                    matInput
                    name="share"
                    type="checkbox"
                    formControlName="share"></mat-checkbox>
                <label translate>invite.share</label>
            </div>
            <!-- <label
                for="org"
                class="app-c-body-text--14-m"
                *ngIf="this.inviteType === 'professionalInvite'"
                translate
                >Organization</label
            >
            <mat-form-field
                appearance="outline"
                class="wt-mat-form-field"
                *ngIf="this.inviteType === 'professionalInvite'">
                <mat-select
                    formControlName="orgID"
                    placeholder="Organization Name">
                    <mat-option
                        *ngFor="let org of organizations"
                        [value]="org.id">
                        {{ org.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <label for="message" class="app-c-body-text--14-m" translate>
                invite.msgTitle
                <span>Max 200 words</span>
            </label>
            <mat-form-field appearance="outline" class="wt-mat-form-field">
                <textarea
                    matInput
                    class="wt-mat-input"
                    tabindex="0"
                    formControlName="message"
                    name="message"
                    [placeholder]="'invite.placeholder' | translate"></textarea>
            </mat-form-field>
        </div>
        <div class="fixed-footer">
            <a
                tabindex="0"
                (click)="sendInvite()"
                (keypress)="sendInvite()"
                class="btn btn-primary"
                translate
                >invite.send</a
            >
        </div>
    </form>
</div>
