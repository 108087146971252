import { Component, OnInit, } from '@angular/core';

@Component({
	selector: 'popup-loader',
	templateUrl: 'popup-loader.component.html',
	styleUrls: ['./popup-loader.component.scss'],
})
export class PopupLoader implements OnInit {
	ngOnInit(){}
}
