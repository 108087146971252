import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { CreatePermissionComponent } from 'app/components/create-permission/create-permission.component'
import { AnalyticEvent } from 'app/lib/analytic-event';
@Component({
  selector: 'app-system-permission',
  templateUrl: './system-permission.component.html',
  styleUrls: ['./system-permission.component.scss']
})
export class SystemPermissionComponent implements OnInit {
  isLoaded:boolean = false;
  roles: Array<Object> = [];
  permission:Array<Object> = [];
  permissionSelected= [];
  allPermission=[];
  permissionDataToSave= [];
  constructor(
		private api: ApiService,
    private log: LogService,
    private modalService: ModalService,) { 

    this.getAllRoles();
    this.getAllPermissions();
    }

  ngOnInit(): void {
  }

  // Get All Permissions
  getAllPermissions(){
    this.isLoaded = true;
    this.api.get(`getpermissions`).subscribe(
			(results: any) => {
        this.isLoaded = false;
        this.permission = results;
        this.getAllRoleAndPermissions();
			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isLoaded = false;
			}
		);
  }


   // Get All Roles
   getAllRoles(){
    this.api.get(`getroles`).subscribe(
			(results: any) => {
      this.roles = results;
      this.isLoaded = false;
			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isLoaded = false;
			}
		);
  }


  // Get All Permissions
  getAllRoleAndPermissions(){
    this.api.get(`getallpermissions`).subscribe(
			(results: any) => {
        results.filter(results =>{
          results['PermissionName'] = this.permission.find(perm => perm['ID'] === results.PermissionID)?.['Name'];
        });
      this.allPermission = this.groupByCategory(results);
      this.setSelectOrDeselectCheckbox();
			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isLoaded = false;
			}
		);
  }

  groupByCategory(data){
    let formattedArray = [];
    this.permission.forEach((element,index) => { 
      const permissinonExistArray = data.filter(allPerms =>allPerms.PermissionID === element['ID']);
      if (permissinonExistArray.length === 0) {
        let noDataArray = [];
           this.roles.forEach(role =>{
            noDataArray.push({
              "ID": null,
              "RoleID": role['RoleID'],
              "PermissionID": element['ID'],
              "PermissionValue": 0,
              "created_at": "",
              "updated_at": ""
            })
           });
           formattedArray[index] = noDataArray;
      
      } else {

        formattedArray.push(permissinonExistArray) 
      }
    });
    return formattedArray;
	}


  // Select or Deselect Permission
  selectPermission(ev,permission){
    const index = this.permissionDataToSave.findIndex(data =>data.PermissionId === permission.PermissionID && data.RoleId === permission.RoleID);
    if (index >= 0) {CreatePermissionComponent
      this.permissionDataToSave.splice(index,1);
    } 
     this.permissionDataToSave.push({
         'ID':permission.ID,
        'RoleId' :permission.RoleID,
        'PermissionId' : permission.PermissionID,
        'Checked': ev.checked
        })
  }

  savePermission(){
    this.api.post(`savepermissions`,{permissions: this.permissionDataToSave}).subscribe(
			(results: any) => {
        this.getAllPermissions();
        this.setSelectOrDeselectCheckbox();
        this.modalService.showAlert('Success', 'Permission updated created');
        this.log.event(AnalyticEvent.event.systemPermissionAdded);				
			},
			(error: any) => {
				this.log.error('Error loading. ' + error.message);
				this.isLoaded = false;
        this.log.debug('Inside API failure' ,this.isLoaded)
    
			}
		);
  }


  createPermission(){
    const modal = this.modalService.showComponent(CreatePermissionComponent,{
  });
    modal.beforeClosed().subscribe((responseData: any) => {
      if (responseData){
        this.permission = [];
        this.allPermission = [];
        this.getAllPermissions(); 
      }  
    });
  }

  /**
   * Select All Permission
   */
   selectAllPermission(ev,role){
    this.allPermission.forEach(permission =>{
      permission.forEach((rolePermission,index) =>{
        if(rolePermission['RoleID'] === role.RoleID) {
          rolePermission['PermissionValue'] = ev.checked ? 1 : 0;
          this.permissionDataToSave.push({
            'ID':rolePermission.ID,
           'RoleId' :rolePermission.RoleID,
           'PermissionId' : rolePermission.PermissionID,
           'Checked': ev.checked
           })
        }
      }); 
    })
   }


   setSelectOrDeselectCheckbox(){
     this.roles.forEach(role => {
      this.allPermission.forEach(permission =>{
        let ischecked = permission.filter(perm => perm.PermissionValue === 0 && perm.RoleID === role['RoleID']);
        role['isAllChecked'] = ischecked.length === 0 ? true : false
      })
     });
    
   }
}
