import { Component, OnInit, ViewChild } from "@angular/core";
import { DailyCalendarViewComponent } from "app/pages/daily-calendar-view/daily-calendar-view.component";
import { WeeklyCalendarViewComponent } from "app/pages/weekly-calendar-view/weekly-calendar-view.component";
import { MonthlyCalendarViewComponent } from "app/pages/monthly-calendar-view/monthly-calendar-view.component";
import { Event } from "../../models/event";
import { ApiService } from "../../lib/api.service";
import { ActivitySchedulerDetails } from "../../modules/practice/activity-scheduler-details/activity-scheduler-details";
import { StorageService } from "../../lib/storage.service";
import { LogService } from "../../lib/log.service";
import { Router } from "@angular/router";
import { ModalService } from "../../lib/modal.service";
import { AppointmentInviteComponent } from "../../components/appointment-invite/appointment-invite.component";
import { TranslateService } from "@ngx-translate/core";
import { CalendarEvent, CalendarMonthViewDay } from "angular-calendar";
import { Subject } from "rxjs";
import { User } from "../../models/user";
import { UserService } from "../../lib/user.service";
import { Appointment } from "../../models/appointment";
import { Invite } from "../../components/invite/invite";
import * as moment from "moment";
import * as _ from "lodash";
import { AnalyticEvent } from "app/lib/analytic-event";

@Component({
	selector: "app-user-calendar",
	templateUrl: "./user-calendar.component.html",
	styleUrls: ["./user-calendar.component.scss"],
})
export class UserCalendarComponent implements OnInit {
	@ViewChild("calendarDailyView")
	calendarDailyView: DailyCalendarViewComponent;
	@ViewChild("calendarMonthlyView")
	calendarMonthlyView: MonthlyCalendarViewComponent;
	@ViewChild("calendarWeeklyView")
	calendarWeeklyView: WeeklyCalendarViewComponent;
	selectedTab: string = "daily";
	title: string = "";
	view: string = "month";
	isOpen = false;
	viewDate: Date = new Date();
	currentDate: Date;
	events$: Array<CalendarEvent<any>>;
	activeEvents: any = [];
	nextWeek = new Date(this.viewDate.getTime() + 7 * 24 * 60 * 60 * 1000);
	pickedDate: Date;
	events: any;
	user: User;
	users: User[] = [];
	appointmentslisting: Array<Appointment> = [];
	appointments: Array<Appointment> = [];
	showpro: boolean;
	placeholder: string;
	refresh: Subject<any> = new Subject();
	activeDayIsOpen: boolean = true;
	host: boolean;
	textStr: any;
	inviteOpen: boolean = false;
	isLoaded: boolean = false;

	constructor(
		private api: ApiService,
		private modalService: ModalService,
		private log: LogService,
		private storage: StorageService,
		private router: Router,
		private translate: TranslateService,
		private userService: UserService
	) {
		this.pickedDate = new Date();
		this.title = "Calendar";
		this.user = this.userService.getUser();
		this.host = this.user.permissions.videohost;
	}

	ngOnInit(): void {
		this.translate.stream("calendar").subscribe((res: any) => {
			this.textStr = res;
		});
		this.fetchEvents(true);
	}

	fetchEvents(filterDay: boolean): void {
		this.isLoaded = false;
		this.api.get("calendar/all", { creatorView: true }).subscribe(
			(result: any) => {
				this.isLoaded = true;
				this.events = Event.initializeArray(result.data, true);
				if (this.user.userType === "user") {
					this.events.forEach((eventClass, index) => {
						eventClass["cssClass"] = "";
					});
				}
				if (filterDay) {
					this.eventsFilterDay(this.pickedDate);
				}
				this.log.event(AnalyticEvent.event.calendarLoad);
				let dateNotFilteredEvnets = this.events;
				let dateFilteredEvnets = this.generateDateObjectOfEvents();
				let currentDate = moment.parseZone().startOf("day").format();
				if (this.selectedTab === "daily") {
					this.calendarDailyView.updateDailyCalendarView(
						dateFilteredEvnets,
						dateNotFilteredEvnets,
						currentDate
					);
				}
				if (this.selectedTab === "monthly") {
					this.calendarMonthlyView.updateMonthlyCalendarView(
						this.events,
						currentDate
					);
				}
				if (this.selectedTab === "weekly") {
					this.calendarWeeklyView.updateWeeklyCalendarView(
						this.events,
						currentDate
					);
				}
			},
			(error: any) => {
				this.isLoaded = true;
				this.log.error(
					"Error getting calendar events." + error.message
				);
			},
			() => {}
		);
		this.refreshView();
	}

	generateDateObjectOfEvents() {
		let dateObjOfEvents = {};
		for (let i = 0; i < this.events.length; i++) {
			let eventStartDate = moment
				.parseZone(this.events[i].start)
				.format("L");

			if (!dateObjOfEvents[eventStartDate]) {
				dateObjOfEvents[eventStartDate] = {
					data: [],
				};
			}
			dateObjOfEvents[eventStartDate].data.push(this.events[i]);
		}
		return dateObjOfEvents;
	}

	refreshView(): void {
		this.refresh.next();
	}

	eventsFilterDay(date: any) {
		this.pickedDate = date;
		let eventStartDate = "";
		for (let i = 0; i < this.events.length; i++) {
			eventStartDate = moment
				.parseZone(this.events[i].start)
				.format("YYYY MM DD");
			if (
				eventStartDate === moment.parseZone(date).format("YYYY MM DD")
			) {
				this.activeEvents.push(this.events[i]);
				this.log.debug(this.activeEvents);
			}
		}
	}

	selectTab(tab: string) {
		this.selectedTab = tab;
		this.fetchEvents(true);
	}

	updateDailyView(data: any) {
		this.selectedTab = "daily";
		setTimeout(() => {
			this.calendarDailyView.updateDailyCalendarView(
				data,
				this.generateDateObjectOfEvents(),
				data.date
			);
		}, 1000);
	}

	scheduleActivity() {
		this.modalService
			.showComponent(ActivitySchedulerDetails, {}, "app-c-modal--cure")
			.afterClosed()
			.subscribe((result) => {
				this.fetchEvents(true);
			});
	}
}
