<div class="page-static public-portal-right-col" style="overflow-y: scroll; height: 100%;">
	<div class="center form-screen">
		<div class="img-wrapper">
			<img alt="Welltrack" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
		</div>

		<div class="title-wrapper">
			<h2>Terms & Conditions</h2>
			<div style="text-align: left;">
				<p>PROTOCALL SERVICES INC. ("
					<b>PROTOCALL</b>"/"
					<b>WE</b>"/"
					<b>US</b>") PROVIDES PROGRAMS AND SERVICES TO HELP USERS OVERCOME PHOBIAS, STRESS, ANXIETIES, AND
					DEPRESSION (THE "PROGRAMS") ON A SUBSCRIPTION BASIS VIA OUR WEBSITE, APP.WELLTRACK-BOOST.COM (THE
					"WEBSITE") (TOGETHER "THESERVICES") AND THROUGH SELECT CORPORATE PARTNERS AND AGENTS. THIS END USER
					LICENSE AGREEMENT ("TERMS") APPLY TO ALL USERS OF THE SERVICES WHETHER OBTAINED DIRECTLY THROUGH US
					OR THROUGH THIRD PARTIES LICENSED TO MAKE THE PROGRAMS AVAILABLE TO YOU.

				</p>

				<p>
					<b>BY ACCESSING OR USING THE SERVICES YOU ARE AGREEING TO DO SO ON THESE TERMS SO PLEASE READ THIS
						AGREEMENT CAREFULLY
						AS IT CONTAINS IMPORTANT LIMITATIONS AND TERMS GOVERNING YOUR USE OF THE SERVICES</b>. IF YOU DO
					NOT ACCEPT THESE TERMS,
					THEN DO NOT USE THE SERVICES.
				</p>
				<ol>
					<li>
						<b style="text-decoration: underline;">Important Service Terms.</b>
						<i>1. Please read the following carefully before accessing or using the Programs:
						</i>
						<ul>
							<li>The Programs are aimed at helping overcome phobias, stress, anxieties, and depression
								using systematic exposure, psychoeducation, self-help cognitive behavioral therapy
								(CBT), and learned relaxation techniques. This process may induce feelings of anxiety
								and other physical or emotional responses, some of which might not be anticipated by you
								before using the Program. If you are in any doubt about the reaction you might have to
								the Program, please first consult a qualified medical practitioner who is familiar with
								your medical records and health history;

							</li>
							<li>PROTOCALL SERVICES has no affiliation with any professional bodies or medical practices. The
								Programs are not intended as a substitute for obtaining appropriate medical advice from
								your doctor or other healthcare professional.


								<ul>
									<li>If you feel particularly distressed while in the middle of a program, please
										refer to the Emergency Contact section on the Website if you need more
										assistance.
									</li>
								</ul>
							</li>
						</ul>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">User Information.</span>
						</b>
						<p>To use the Services, you must create an account on the Website including providing your name,
							email address and choosing a password to log in and out of your profile. This applies
							whether you subscribe to the service directly or are granted access through a third party
							plan. This information will be stored and used by us to provide the Services in accordance
							with our Privacy Policy which you should read carefully before providing any personal
							information through the Website. However, for greater certain we will not share your
							personal information with third parties or any data from your use of the Programs except for
							anonymized aggregated data from across the Services which do not identify or refer to you
							personally.

						</p>
					</li>

					<li>
						<b style="text-decoration: underline;">Subscriptions.</b>
						<p>After you register and create a user account, you will be granted access to the Program you
							have selected if you have paid the subscription fee or the subscription has been paid on
							your behalf (e.g. if your access is through a third party such as a corporate plan).
							Subscription plans for end users are payable monthly or yearly in advance and you will be
							charged automatically each month or year unless/until you cancel your subscription. Payments
							are processed through PayPal or Stripe and all payments are due in US dollars unless
							indicated otherwise.

						</p>
					</li>

					<li>
						<b style="text-decoration: underline;">Conduct Rules.</b>
						<p>In using the Services you shall not:

						</p>
						<ul>
							<li>Copy, distribute or resell any content from the Website or Programs (including any
								content) without our prior consent alter or modify any part of the Services;
							</li>
							<li>impersonate any person or entity or misrepresent their affiliation with a person or
								entity;
							</li>
							<li>interfere with or disrupt the Website or servers or networks connected to the Website;

							</li>
							<li>disobey any requirements, procedures, policies or regulations of networks connected to
								the Website or probe, scan, or test the vulnerability of any system or network or breach
								or circumvent any security or authentication measures; and

							</li>
							<li>grant access to any third party under your account details to access the Services.
							</li>
						</ul>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Changes to the Services or Website.</span>
						</b>
						<p>We reserve the right to alter, suspend, or discontinue this Website and/or the Programs at
							any time and for any reason or no reason. In such cases, we will endeavour to give notice of
							such changes on the Website. The Services may also be unavailable from time to time due to
							maintenance or malfunction of computer or network equipment or other reasons. PROTOCALL SERVICES may
							periodically add or update the information and materials on this Website and the Programs
							without notice.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Feedback.</span>
						</b>
						<p>If You provide PROTOCALL SERVICES with any suggestions, comments or other feedback ("<b>Feedback</b>")
							relating to the Services (or any part of them), PROTOCALL SERVICES may use such Feedback in the
							Website, Programs or in any other PROTOCALL SERVICES products or services (collectively,
							"<b>PROTOCALL SERVICES
								Offerings</b>"). Accordingly, You agree that: (a) PROTOCALL SERVICES is not subject to any
							confidentiality obligations in respect to the Feedback, (b) the Feedback is not confidential
							or proprietary information of You or any third party and You have all of the necessary
							rights to disclose the Feedback to PROTOCALL SERVICES, (c) PROTOCALL SERVICES (including its successors and
							assigns and any successors and assigns of any of the PROTOCALL SERVICES Offerings) may freely use,
							reproduce, publicize, license, distribute, and otherwise commercialize Feedback in any
							PROTOCALL SERVICES Offerings.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Links to Third-Party Websites.</span>
						</b>
						<p>The Services may contain links to other websites that are not owned or controlled by
							PROTOCALL SERVICES. PROTOCALL SERVICES is not responsible for the content of any linked websites. Any
							third-party websites or services accessed from the Website are subject to the terms and
							conditions of those websites and or services and You are responsible for determining those
							terms and conditions and complying with them. The presence on the Website of a link to any
							non-PROTOCALL SERVICES websites does not imply that PROTOCALL SERVICES endorses or accepts any responsibility
							for the content or use of such websites.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Disclaimer.</span>
						</b>
						<p>THE INFORMATION AND MATERIALS PROVIDED THROUGH THE SERVICES ARE PROVIDED "AS IS."
							SPECIFICALLY, BUT WITHOUT LIMITATION, PROTOCALL SERVICES DOES NOT REPRESENT, WARRANT OR OFFER ANY
							CONDITIONS THAT: (I) THE INFORMATION OR MATERIALS PROVIDED ARE CORRECT, ACCURATE, RELIABLE
							OR COMPLETE; (II) THE FUNCTIONS CONTAINED ON THIS WEBSITE WILL BE UNINTERRUPTED OR
							ERROR-FREE; (III) DEFECTS WILL BE CORRECTED, OR (IV) THIS WEBSITE OR THE SERVER(S) THAT
							MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PROTOCALL SERVICES SPECIFICALLY
							DISCLAIMS ALL REPRESENTATIONS, WARRANTIES AND CONDITIONS, EITHER EXPRESS, IMPLIED,
							STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR OTHERWISE INCLUDING BUT NOT LIMITED TO
							ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR
							FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICES. USE OF THE SERVICES IS ENTIRELY AT YOUR
							OWN DISCRETION AND RISK. PROTOCALL SERVICES DOES NOT WARRANT, ENDORSE, GUARANTEE, PROVIDE ANY
							CONDITIONS OR REPRESENTATIONS, OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
							ADVERTISED OR OFFERED BY ANY THIRD PARTY THROUGH THE WEBSITE OR IN RESPECT TO ANY WEBSITE
							THAT CAN BE REACHED FROM A LINK ON THE WEBSITE OR FEATURED IN ANY BANNER OR OTHER
							ADVERTISING ON THE WEBSITE, AND PROTOCALL SERVICES SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU
							MAY ENTER INTO WITH ANY SUCH THIRD PARTY.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Limitation Of Liability.</span>
						</b>
						<p>UNDER NO CIRCUMSTANCES SHALL PROTOCALL SERVICES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
							CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY
							DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES THAT RESULT
							FROM (I) YOUR USE OF OR YOUR INABILITY TO USE, THIS WEBSITE OR ANY OF THE INFORMATION OR
							MATERIALS IN THE PROGRAMS, (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS, DATA,
							INFORMATION OR SERVICES, (III) ERRORS, MISTAKES, OR INACCURACIES IN THE MATERIALS ON THE
							WEBSITE, (IV) PERSONAL INJURY OR PROPERTY DAMAGE OF ANY KIND WHATSOEVER ARISING FROM OR
							RELATING TO YOUR USE OF THE SERVICES, ANY BUGS, VIRUSES, TROJAN HORSES, OR ANY OTHER FILES
							OR DATA THAT MAY BE HARMFUL TO COMPUTER OR COMMUNICATION EQUIPMENT OR DATA THAT MAY HAVE
							BEEN TRANSMITTED TO OR THROUGH THE SERVICES, OR (V) ANY ERRORS OR OMISSIONS IN ANY MATERIAL
							ON THE WEBSITE OR ANY OTHER LOSS OR DAMAGE OF ANY KIND ARISING FROM OR RELATING TO YOUR USE
							OF THE WEBSITE. THESE LIMITATIONS SHALL APPLY EVEN IF PROTOCALL SERVICES HAS BEEN ADVISED OF THE
							POSSIBILITY OF SUCH DAMAGES.

						</p>
						<p>IN NO EVENT SHALL PROTOCALL SERVICES'S AGGREGATE LIABILITY ARISING OUT OF FOR RELATED TO THIS
							AGREEMENT, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR UNDER ANY OTHER THEORY OF
							LIABILITY, EXCEED THE AMOUNTS ACTUALLY PAID BY YOU HEREUNDER IN THE TWELVE MONTHS PRECEDING
							THE INCIDENT GIVING RISE TO THE LIABILITY.

						</p>
						<p>THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Termination.</span>
						</b>
						<p>
							PROTOCALL SERVICES may, under certain circumstances and without prior notice, immediately terminate
							Your ability to access the Website or portions thereof. Cause for such termination shall
							include, but not be limited to, (a) breaches or violations of these Terms of Use, (b)
							requests by law enforcement or other government agencies, (c) a request by You, (d)
							discontinuance or material modification to the Website (or any part thereof), (e) unexpected
							technical, security or legal issues or problems, and/or (f) participation by You, directly
							or indirectly, in fraudulent or illegal activities. You acknowledge and agree that all
							terminations may be made by PROTOCALL SERVICES in its sole discretion and that PROTOCALL SERVICES shall not be
							liable to You or any third-party for any termination of Your access to the Services. Any
							termination of these terms of use by PROTOCALL SERVICES shall be in addition to any and all other
							rights and remedies that PROTOCALL SERVICES may have.

						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Enforcement of Terms.</span>
						</b>
						<p>This Agreement is governed by the laws of the Province of Ontario. If any part of these Terms
							is held to be unlawful, void, or unenforceable, that part shall be deemed severable and
							shall not affect the validity and enforceability of the remaining provisions. The failure of
							PROTOCALL SERVICES to exercise or enforce any right or provision under these Terms of Use shall not
							constitute a waiver of such right or provision. Any waiver of any right or provision by
							PROTOCALL SERVICES must be in writing and shall only apply to the specific instance identified in
							such writing. No waiver by PROTOCALL SERVICES of any provision of these Terms of Use shall be deemed
							to be a further or continuing waiver of such provision or any other provision.
						</p>
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">Copyright and Trademark Information.</span>
						</b>
						<p>This Website and the Programs, and the information and materials that they contain, are the
							property of PROTOCALL SERVICES and its licensors, and are protected from unauthorized copying and
							dissemination by copyright law, trademark law, international conventions and other
							intellectual property laws. All PROTOCALL SERVICES product names and logos are trademarks or
							registered trademarks of PROTOCALL SERVICES Inc. Nothing contained on this Website should be
							construed as granting, by implication, estoppel, or otherwise, any license or right to use
							this Website or any materials displayed on this Website, through the use of framing or
							otherwise, except: (a) as expressly permitted by these terms and conditions; or (b) with the
							prior written permission of PROTOCALL SERVICES.

						</p>
			
					</li>

					<li>
						<b>
							<span style="text-decoration: underline;">UPDATES.</span>
						</b>
						<p>THESE TERMS MAY BE AMENDED OR UPDATED BY PROTOCALL SERVICES FROM TIME TO TIME AND THE MOST RECENT
							VERSION WILL BE POSTED ON THE WEBSITE. THE TERMS MAY HAVE CHANGED SINCE YOUR LAST VISIT TO
							THIS WEBSITE, AND IT IS YOUR RESPONSIBILITY TO REVIEW THESE TERMS OF USE FOR ANY CHANGES.
							YOUR USE OF THE SERVICES AFTER ANY AMENDMENTS OR UPDATES OF THESE TERMS OF USE SHALL SIGNIFY
							YOUR ASSENT TO AND ACCEPTANCE OF SUCH REVISED TERMS. ANY NEW FEATURES THAT MAY BE ADDED TO
							THIS WEBSITE FROM TIME TO TIME WILL BE SUBJECT TO THESE TERMS OF USE, UNLESS STATED
							OTHERWISE. YOU SHOULD VISIT THIS PAGE PERIODICALLY TO REVIEW THESE TERMS OF USE.

						</p>
					</li>
					<li>
						<b>Copyright Ⓒ 2022 PROTOCALL SERVICES</b>
					</li>
				</ol>
				<b>LAST UPDATE</b>: OCTOBER, 2022

			</div>
		</div>
	</div>
</div>