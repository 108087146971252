<div class="app-l-mc__sidepanel-wrap">
	<div class="app-l-mc__head">
		<!-- <div>
                <label translate>moodcheck.options.add</label>
            </div> -->
		<div class="app-l-mc__new-activity app-c-form-group">
			<form (keydown.enter)="onAddCustomOption()">
				<input
					autofocus
					class="app-c-form-control ng-pristine ng-valid ng-touched"
					tabindex="0"
					type="text"
					placeholder="Enter a new activity"
					[(ngModel)]="customOption"
					name="customOption"
				/>
			</form>
		</div>
		<button
			class="app-c-btn app-c-btn--primary app-c-btn--curve"
			tabindex="0"
			(click)="onAddCustomOption()"
			translate
		>
			<svg-icon icon="plus"></svg-icon>
			<span>Add</span>
		</button>
	</div>
	<div class="app-l-mc__body">
		<div class="app-l-mc__tab">
			<mat-tab-group>
				<mat-tab *ngIf="customOptionlist.length">
					<ng-template mat-tab-label>
						<span translate>moodcheck.options.remove</span>
					</ng-template>
					<div class="app-l-mc__item-list">
						<div
							class="app-l-mc__item"
							*ngFor="let option of customOptionlist;let index = index"
						>
							<div
								[ngClass]="{'disabled': !option.enabled}"
								class="app-l-mc__item-block"
								tabindex="0"
							>
								<span class="tooltip"
									>{{option.name | truncate: 14}}
									<span class="tooltiptext"
										>{{option.name}}</span
									>
								</span>
								<button
									name="remove"
									class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-12"
									tabindex="{{index}}"
									(keydown.enter)="removeCustomOption(option)"
									(click)="removeCustomOption(option)"
								>
									<svg-icon
										icon="close"
										class="w-100 h-100"
									></svg-icon>
								</button>
							</div>
						</div>
					</div>
				</mat-tab>
				<mat-tab>
					<ng-template mat-tab-label>
						<span translate>moodcheck.options.default</span>
						<!-- <span class="dm-moodcheck-options-span-small" translate>moodcheck.options.canHide</span> -->
					</ng-template>
					<div class="app-l-mc__item-list">
						<div
							class="app-l-mc__item"
							*ngFor="let option of defaultOptionlist;let index = index"
						>
							<div
								[ngClass]="{'custom': option.custom, 'disabled': !option.enabled}"
								class="app-l-mc__item-block"
								tabindex="0"
							>
								<span class="tooltip"
									>{{option.name | truncate: 14}}
									<span class="tooltiptext"
										>{{option.name}}</span
									>
								</span>
								<div class="app-l-mc__toggle">
									<button
										*ngIf="!option.enabled"
										tabindex="{{index}}"
										(click)="hideDefaultOption(option)"
										(keydown.enter)="hideDefaultOption(option)"
										name="md-add"
										class="app-c-btn app-c-btn--text-primary app-c-btn--text"
										translate
									>
										moodcheck.options.unhide
									</button>
									<button
										*ngIf="option.enabled"
										tabindex="{{index}}"
										(click)="hideDefaultOption(option)"
										(keydown.enter)="hideDefaultOption(option)"
										(click)="hideDefaultOption(option)"
										name="md-remove"
										class="app-c-btn app-c-btn--text-primary app-c-btn--text"
										translate
									>
										moodcheck.options.hide
									</button>
								</div>
							</div>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
	</div>
	<div class="footer-popup">
		<!-- <div>
                    <span class="footer-span" translate>moodcheck.options.warning</span>
                </div> -->

		<button
			tabindex="0"
			class="app-c-btn app-c-btn--secondary app-c-btn--curve"
			(click)="cancel()"
			(keypress)="cancel()"
		>
			<!-- *ngIf="!optionChanged" -->
			<svg-icon icon="arrow-left-long"></svg-icon>
			<span translate>moodcheck.back</span>
		</button>
		<button
			class="app-c-btn app-c-btn--primary app-c-btn--curve"
			*ngIf="optionChanged"
			tabindex="0"
			(click)="confirm()"
		>
			<span translate> moodcheck.submit </span>
			<svg-icon icon="tick"></svg-icon>
		</button>
	</div>
</div>
<span class="app-l-mc__overlay" (click)="cancel()" (keypress)="cancel()"></span>
