<div class="access-modal">
    <mat-dialog-content>

        <!-- <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
          aria-label="Close organization edit form">
          <img src="./assets/img/icons-svg/close-icon.svg">
      </a> -->

        <!-- <popup-loader *ngIf="isloaded"></popup-loader> -->
        <div class="page-section no-margin">
            <div class="modal-header-container">
                <h2 class="modal-header-text app-c-heading--h4" translate>setting.wellTrack</h2>
                <p class="modal-header-content app-c-body-text--14" translate>loginMobile.getStarted</p>
                <div class="form-input-container">
                    <div class="input-section">
                        <input matInput class="form-input disabled" aria-label="Full Name" tabindex="0" id="fullname"
                            type="text" name="fullName" [(ngModel)]="email" readonly />
                        <button class="edit-button"><svg-icon icon="profile-edit-icon"></svg-icon></button>
                    </div>
                </div>
            </div>
            <div class="modal-inner-contents">
                <div class="page-section-header center" *ngIf="!activeOrg">
                    <h2 class="app-c-body-text--16-m" translate>signUp.toGainFullAccess</h2>
                </div>

                <div class="page-section-content">
                    <!-- User has an association. Allow user chance to dissassociate-->

                    <div padding *ngIf="activeOrg" class="center">
                        <p>
                            <span [translate]="'setting.paired'"></span>
                            <strong>{{user.primaryOrganization.name}}</strong>
                        </p>

                        <div>
                            <a tabindex="0" (click)="onUnpair()" (keypress)="onUnpair()" class="btn btn-bold"
                                translate>setting.disconnect</a>
                        </div>
                    </div>
                    <!-- User is not associated and not subscribed.  Allow user a change to associate-->
                    <div *ngIf="!activeOrg">
                        <form>
                            <div class="form-input-container">
                                <div class="form-label" translate>signUp.enterAccessCode</div>
                                <div class="input-section">
                                    <svg-icon icon="access-code-icon" class="input-icon"></svg-icon>
                                    <input matInput class="form-input input-with-icon" matInput aria-label="Access Code"
                                        tabindex="0" id="accesscode" type="text" placeholder="GHJK432"
                                        [(ngModel)]="accessCode" name="accessCode" />
                                </div>
                            </div>
                        </form>
                        <div class="divider app-c-body-text--12-m" translate>
                            <div class="line"></div>
                            accessCode.or
                            <div class="line"></div>
                        </div>
                        <form class="clearfix">

                            <div class="form-input-container">
                                <div class="form-label" translate>accessCode.orgEmailAddress</div>
                                <div class="input-section">
                                    <svg-icon icon="email-icon" class="input-icon"></svg-icon>
                                    <input matInput class="form-input input-with-icon" matInput
                                        aria-label="Organization Email" tabindex="0" id="orgemail" type="email"
                                        placeholder="john@unb.com" [(ngModel)]="emailAddress" name="emailAddress" />
                                </div>
                            </div>
                        </form>
                        <div class="modal-footer-button">
                            <button tabindex="0" (click)="onPair()" (keypress)="onPair()"
                                class="app-c-btn app-c-btn--primary app-c-btn--curve" translate>setting.pair</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>