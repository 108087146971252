<menu-header></menu-header>
<!--
	This will become an internal-user template rather than an "admin" template.
	The idea is that it would serve both the AR, admin, super admin roles and any other roles that would happen to come down the pipe.
-->

<nav id="nav" role="navigation">
	<a tabindex="0" [ngClass]="{active:'/app' == menuService.pathActive || '' == menuService.pathActive}"
		routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="menuService.nav('/app')"
		(keypress)="menuService.nav('/app')">
		<img alt="Dashboard" class="icon" src="/assets/img/icons/dashboard@2x.png" style="height: 20px" />
		<span translate>menu.dashboard</span>
	</a>

	<ng-container *accessControl="'adminUsers'">
		<a tabindex="0" [ngClass]="{
			active: '/admin/user-listing' == menuService.pathActive}" routerLinkActive="active"
			(click)="menuService.nav('/admin/user-listing')" (keypress)="menuService.nav('/admin/user-listing')">
			<i class="fa fa-users" aria-hidden="true"></i>
			<span translate>menu.users</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminOrganizations'">
		<a tabindex="0" [ngClass]="{active: '/admin/organization-listing' == menuService.pathActive}"
			routerLinkActive="active" (click)="menuService.nav('/admin/organization-listing')"
			(keypress)="menuService.nav('/admin/organization-listing')">
			<i class="fa fa-sitemap" aria-hidden="true"></i>
			<span translate>menu.orgs</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminScheduledPushNotification'">
		<a tabindex="0" [ngClass]="{'active': '/app/scheduled-push-notification' == menuService.pathActive}"
			routerLinkActive="active" (click)="menuService.nav('/app/scheduled-push-notification')"
			(keypress)="menuService.nav('/app/scheduled-push-notification')">
			<i class="fa fa-bell" aria-hidden="true"></i>
			<span translate>menu.scheduledPushNotification</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminResources'">
		<a tabindex="0" [ngClass]="{'active': '/admin/resources-import' == menuService.pathActive}"
			routerLinkActive="active" (click)="menuService.nav('/admin/resources-import')"
			(keypress)="menuService.nav('/admin/resources-import')">
			<i class="fa fa-book" aria-hidden="true"></i>
			<span translate>menu.resourcesAdmin</span>
		</a>
	</ng-container>

	
	<ng-container *accessControl="'adminCourseManagement'" >
		<a tabindex="0" [ngClass]="{'active': '/admin/course-listing' == pathActive, 'disabled': locked}"
			routerLinkActive="active" (click)="nav('/admin/course-listing')"
			(keypress)="nav('/admin/course-listing')">
			<img alt="Courses" class="icon" src="/assets/img/icons/practice@2x.png" style="height: 20px;">
			<span translate>menu.adminCourseListing</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminActivity'">
		<a tabindex="0" [ngClass]="{
			active: '/admin/activity' == menuService.pathActive}" routerLinkActive="active"
			(click)="menuService.nav('/admin/activity')" (keypress)="menuService.nav('/admin/activity')">
			<i class="fa fa-space-shuttle" aria-hidden="true"></i>
			<span translate>menu.activity</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminChallenges'">
		<a tabindex="0" [ngClass]="{active: '/admin/challenges' == menuService.pathActive}" routerLinkActive="active"
			(click)="menuService.nav('/admin/challenges')" (keypress)="menuService.nav('/admin/challenges')">
			<i class="fa fa-flag" aria-hidden="true"></i>
			<span translate>menu.challenges</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminReports'">
		<a tabindex="0" [ngClass]="{
			active: '/admin/reports' == menuService.pathActive}" routerLinkActive="active"
			(click)="menuService.nav('/admin/reports')" (keypress)="menuService.nav('/admin/reports')">
			<i class="fa fa-table" aria-hidden="true"></i>
			<span translate>menu.reports</span>
		</a>
	</ng-container>

	<!--This view should be a list of survey submissions/statistics on submissions.   Not something specific to the nps survey.
		I believe it was originally created for the user's nps submissions but there is no purpose for a user to be 
		able to view a list of their own listings. 
		An admin view is the only thing that's really useful and if there's an admin view, it should be for more than just
		nps survey listings. 
		It should also be for user feedback etc. 
		
		<ng-container *accessControl="'adminSurveys'">
			<a tabindex="0" [ngClass]="{ active: '/admin/survey-submissions' == menuService.pathActive}"
				routerLinkActive="active" (click)="menuService.nav('/admin/survey-submissions')"
				(keypress)="menuService.nav('/admin/survey-submissions')">
				<img alt="Survey List" class="icon" src="/assets/img/icons/survey.svg" style="height: 19px" />
				<span translate>menu.listSurvey</span>
			</a>
		</ng-container>
	-->
	<ng-container *accessControl="'adminAssessments'">
		<a tabindex="0" [ngClass]="{ active: '/admin/assessments-listing' == menuService.pathActive}"
			routerLinkActive="active" (click)="menuService.nav('/admin/assessments-listing')"
			(keypress)="menuService.nav('/admin/assessments-listing')">
			<img alt="Assessments listing" class="icon" src="/assets/img/icons/survey.svg" style="height: 19px; margin-right: 10px !important;" />
			<span translate>menu.asessmentAdminManage</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'adminAudit'">
		<a tabindex="0"
			[ngClass]="{ active: '/admin/auditListing' == menuService.pathActive || '' == menuService.pathActive }"
			[ngClass]="{ disabled: menuService.locked }" (click)="menuService.nav('/admin/auditListing')"
			(keypress)="menuService.nav('/admin/auditListing')">
			<i class="fa fa-book" aria-hidden="true"></i>
			<span translate>menu.audit</span>
		</a>
	</ng-container>

	<!--Shouldn't be covered by the accessControl directive since this is what controls the ability to adjust permissions in the first place.  
		If a user can revoke their own permission, they would essentially be locked out of most powerful role. 
	-->
	<ng-container *ngIf="user.userType === 'superadmin'">
		<a tabindex="0"
			[ngClass]="{ active: '/admin/system-permission' == menuService.pathActive, disabled: menuService.locked }"
			routerLinkActive="active" (click)="menuService.nav('/admin/system-permission')"
			(keypress)="menuService.nav('/admin/system-permission')">
			<img alt="Survey List" class="icon" src="/assets/img/icons/survey.svg" style="height: 19px; margin-right: 10px !important" />
			<span translate>menu.system</span>
		</a>
	</ng-container>

	<ng-container *accessControl="'settings'">
		<a tabindex="0" *accessControl="'settings'" [ngClass]="{
			active: '/app/settings' == menuService.pathActive,disabled: menuService.locked}" class="secondary"
			routerLinkActive="active" (click)="menuService.nav('/app/settings')"
			(keypress)="menuService.nav('/app/settings')">
			<img alt="Settings" class="icon" src="./assets/img/icons/settings@2x.png" style="height: 15px" />
			<span translate>menu.setting</span>
		</a>
	</ng-container>

</nav>
<menu-footer></menu-footer>