<div class="form-modal">
    <mat-dialog-content>
        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close organization edit form">
            <img src="./assets/img/icons-svg/close-icon.svg" />
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <!-- <h1>{{apiDAta['name']}}</h1> --><h1>{{'dynamicForm.dynamicTitle'+apiDAta['name'] | translate: {value: apiDAta['name']} }}</h1>
            </div>
        </div>
        <div class="intro-content">
            <ng-container *ngIf="userData.organizations[0].serviceType === 1; else servicetypetwo">
                <p translate>dynamicForm.contentStudent</p>
            </ng-container>
            <ng-template #servicetypetwo>
                <ng-container *ngIf="userData.organizations[0].serviceType === 2; else servicetypethree">
                    <p translate>dynamicForm.contentEmployer</p>
                </ng-container>
            </ng-template>
            <ng-template #servicetypethree>
                <ng-container *ngIf="userData.organizations[0].serviceType === 3; else servicetypefour">
                    <p translate>dynamicForm.contentCommunity</p>
                </ng-container>
            </ng-template>
            <ng-template #servicetypefour>
                <ng-container *ngIf="userData.organizations[0].serviceType === 4; else servicetypedefault">
                    <p translate>dynamicForm.contentManagedcare</p>
                </ng-container>              
            </ng-template>
            <ng-template #servicetypedefault>
                <p translate>dynamicForm.contentDefault</p>             
            </ng-template>
        </div>
        <div>
            <form novalidate [formGroup]="dynamicForm" (ngSubmit)="doSave()">
                <div class="form-wrapper-80">
                    <div *ngFor="let data of formData; let i = index" style="margin-top: 15px;">
                        <mat-form-field class="wt-mat-form-field" class="example-full-width" *ngIf="data['Type'] === 6">
                            <input class="wt-mat-input" matInput class="wt-mat-input" type="text"
                                [formControlName]="data['Key']" placeholder="{{data['Comment']}}"
                                (change)="valueChange(data,$event.target.value)">
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="wt-mat-form-field" class="example-full-width" *ngIf="data['Type'] === 8">
                            <input class="wt-mat-input" matInput class="wt-mat-input" type="text"
                                [formControlName]="data['Key']" placeholder="{{data['Comment']}}"
                                (change)="valueChange(data,$event.target.value)">
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                            <mat-error *ngIf="dynamicForm.get(data['Key']).hasError('notMatched')" >
                                {{'dynamicForm.invalid' | translate  : { minval: minval, maxval: maxval } }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="wt-mat-form-field" *ngIf="data['Type'] === 4">
                            <mat-select [formControlName]="data['Key']" aria-required="true"
                                (selectionChange)="valueChange(data,$event,opt)" placeholder="{{data['Comment']}}">
                                <mat-option *ngFor="let opt of data.options" [value]="opt">
                                    {{opt.Comment}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="wt-mat-form-field" *ngIf="data['Type'] === 5" class="wt-mat-form-field">
                            <textarea matInput placeholder="{{data['Comment']}}" value=""
                                [formControlName]="data['Key']" aria-required="true"
                                (change)="valueChange(data,$event.target.value,opt)"></textarea>
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <div class="wt-mat-form-field radio-section" *ngIf="data['Type'] === 1">
                            <div class="survey-question-block">{{data['Comment']}}</div>
                            <mat-radio-group [formControlName]="data['Key']" *ngIf="data['Type'] === 1">
                                <mat-radio-button *ngFor="let opt of data.options; let j = index"
                                    (change)="valueChange(data,opt)"
                                    value="{{opt.ID}}">{{opt.Comment}}</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                        </div>

                        <div class="wt-mat-form-field" *ngIf="data['Type'] === 2">
                            <label for="active" translate>{{data['Comment']}}</label>
                            <br />
                            <mat-checkbox [formControlName]="data['Key']" tabindex="0"
                                *ngFor="let opt of data.options; let j = index" (change)="valueChange(data,opt)"
                                value="{{opt.ID}}">{{opt.Comment}}
                            </mat-checkbox>
                            <mat-error *ngIf="isSubmitted && dynamicForm.get(data['Key']).hasError('required')" >
                                {{'dynamicForm.fieldRequired' | translate}}
                            </mat-error>
                        </div>

                        <!--Slide rand corresponding fields-->
                        <div *ngIf="data['Type'] === 7" class="nps-survey-block">
                            <div class="">
                                <div class="survey-question-block">{{data['Comment']}}</div>
                                <app-number-scale [scaleIndex]="i" [scaleOptionValues]="data.options"
                                    (onCompleted)="scaleSelectionCompleted($event)"></app-number-scale>

                                <mat-error *ngIf="isSubmitted  &&isMainQuestionAnswered(data)" >
                                    {{'dynamicForm.fieldRequired' | translate}}
                                </mat-error>
                            </div>

                            <div>
                                <div *ngFor="let subQn of data.sub;let j = index">
                                    <div *ngIf="subQn['show']">
                                        <div *ngIf="subQn['Type'] === 5">
                                            <mat-form-field class="wt-mat-form-field" class="example-full-width"
                                                *ngIf="subQn['Type'] === 5">
                                                <input class="wt-auto-demographic-age" matInput class="wt-mat-input"
                                                    type="text" [formControlName]="data['Key']"
                                                    placeholder="{{subQn['Comment']}}" value=""
                                                    (change)="valueChange(subQn,$event.target.value)">
                                            </mat-form-field>
                                            <div *ngIf="isSubmitted && isValid(subQn,i)" style="margin-top: -10px;
                                            margin-bottom: 1.25em;" >
                                                <mat-error >
                                                    {{'dynamicForm.fieldRequired' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div *ngIf="subQn['Type'] === 4">
                                            <mat-form-field class="wt-mat-form-field" style="padding-bottom: 0px;"
                                                *ngIf="subQn['Type'] === 4">
                                                <mat-select class="wt-auto-demographic-gender" [formControlName]=""
                                                    aria-required="true"
                                                    (selectionChange)="valueChange(subQn,$event,opt)"
                                                    placeholder="{{subQn['Comment']}}">
                                                    <mat-option *ngFor="let opt of subQn.options" [value]="opt">
                                                        {{opt.Comment}}
                                                    </mat-option>
                                                </mat-select>

                                            </mat-form-field>
                                            <div *ngIf="isSubmitted && isValid(subQn,i)" style="margin-top: -10px;
                                            margin-bottom: 1.25em;" >
                                                <mat-error >
                                                    {{'dynamicForm.fieldRequired' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div *ngIf="subQn['Type'] === 6">
                                            <mat-form-field>
                                                <textarea class="wt-mat-form-field" matInput
                                                    placeholder="{{subQn['Comment']}}" value=""
                                                    [formControlName]="data['Key']" aria-required="true"
                                                    (change)="valueChange(subQn,$event.target.value,opt)"></textarea>
                                            </mat-form-field>
                                            <div *ngIf="isSubmitted &&  isValid(subQn,i)" >
                                                <mat-error >
                                                    {{'dynamicForm.fieldRequired' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>


                                        <div class="wt-mat-form-field radio-section" *ngIf="subQn['Type'] === 1">
                                            <div class="survey-question-block">{{data['Comment']}}</div>
                                            <mat-radio-group [formControlName]="" *ngIf="subQn['Type'] === 1">
                                                <mat-radio-button *ngFor="let opt of subQn.options; let j = index"
                                                    (change)="valueChange(subQn,opt)" value="{{opt.ID}}">{{opt.Comment}}
                                                </mat-radio-button>
                                            </mat-radio-group>
                                            <div *ngIf="isSubmitted && isValid(subQn,i)" >
                                                <mat-error >
                                                    {{'dynamicForm.fieldRequired' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>

                                        <div class="wt-mat-form-field" *ngIf="subQn['Type'] === 2">
                                            <label for="active" translate>{{subQn['Comment']}}</label>
                                            <br />
                                            <mat-checkbox [formControlName]="" tabindex="0"
                                                *ngFor="let opt of subQn.options; let j = index"
                                                (change)="valueChange(subQn,opt)" value="{{opt.ID}}">{{opt.Comment}}
                                            </mat-checkbox>
                                            <div *ngIf="isSubmitted && isValid(subQn,i)">
                                                <mat-error >
                                                    {{'dynamicForm.fieldRequired' | translate}}
                                                </mat-error>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </form>
            <div class="fixed-footer">
                <a tabindex="0" class="btn-primary btn-inline btn" (click)="doSave()" (keypress)="doSave()"translate>dynamicForm.save</a>
            </div>
        </div>
    </mat-dialog-content>
</div>