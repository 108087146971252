<page-loader *ngIf="!isLoaded"></page-loader>
<div id="page-user-listing" class="page-section" *ngIf="isLoaded">
    <div class="page-section">
        <div class="page-section-header">
            <div class="page-section-buttons">
                
            </div>
            <h1 translate>Audit Listing</h1>
        </div>
        <div class="page-section-content">
            <div class="table-responsive">
                <page-loader *ngIf="usersLoading"></page-loader>
                <table class="table">
                    <thead>
                        <tr>
                            <th translate style="width:10%">audit.id</th>
                            <th translate>audit.action</th>
                            <th translate>audit.user</th>
                            <th translate>audit.editeddata</th>
                            <th translate>audit.date</th>
                            <th translate>audit.updated value</th>
                            <!-- <th style="width:9%"></th>
                            <th style="width:5%"></th> -->
                        </tr>
                        <tr>
                            <th></th>
                            <th>
                                <mat-form-field class="wt-mat-form-field">
                                    <mat-select name="userType" [(ngModel)]= "EditedDataID" (selectionChange)="actionValue(EditedDataID)">
                                        <mat-option [value]='""'>All</mat-option>
                                        <mat-option *ngFor="let audit of actionlist" [value]="audit.EditedDataID">
                                          {{audit.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </th>
                            <th>
                                <mat-form-field class="example-full-width" >
                                    <input type="text" class="wt-mat-input" matInput name="item_id" [(ngModel)]="cols[0]"
                                    #item_id="ngModel" (ngModelChange)="valueChange($event)" [matAutocomplete]="auto" >
                                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  [displayWith]="valueMapper">
                                      <!-- <mat-option [value]='""'>All</mat-option> -->
                                      <mat-option *ngFor="let item of activeusers" [value]="item.id">
                                        {{item.fullName}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody  *ngIf="!usersLoading && auditusers?.length > 0">
                        <tr *ngFor="let audit of auditusers">
                            <td class="strong">
                                {{audit.ID}}
                            </td>
                            <td class="strong">
                                {{audit.ActionName}}
                            </td>
                            <td class="strong">
                               <a tabindex="0" [routerLink]="['/admin/user', audit.UserID]"
                                translate> {{audit.UserName}}</a>
                            </td>
                            <td class="strong">
                                {{audit.EditedData}}
                            </td>
                            <td class="strong">
                                {{audit.UpdatedOnUtc | amUtc | amLocal | amDateFormat: 'MM/DD/YY' }}
                            </td>
                            <td (click)="viewprevalue(audit.ID)">
                                <a tabindex="0" class="pull-right" translate>audit.view</a>
                            </td>
                        </tr> 
                    </tbody>
                </table> 
                <a *ngIf="!loadMoreLoading" tabindex="0" (click)="loadMoreList()" (keypress)="loadMoreList()">Load more <i class="fa fa-plus"></i></a>
                <div *ngIf="loadMoreLoading">Loading...</div>
            </div>
        </div>
    </div>
</div>
