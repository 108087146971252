import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ApiService } from "../../../lib/api.service";
import { LogService } from "../../../lib/log.service";
import { RoomForPositive } from "../../../models/roomforpositive";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'app/lib/modal.service';
import { RoomForPositiveCreateComponent } from '../room-for-positive-create/room-for-positive-create.component';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';
@Component({
  selector: 'app-room-for-positive-view',
  templateUrl: './room-for-positive-view.component.html',
  styleUrls: ['./room-for-positive-view.component.scss']
})
export class RoomForPositiveViewComponent implements OnInit {
  user: User;
  pageMode: string;
  pageData;
  currentItemIndex: number = 0;
  questions;
  questionTypes;
  questionIndex: number;
  panelOpenState = false;
  rfpdata: any;
  openProudAccordian = false;
  openGratefulAccordian = false;
  id: any;
  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<RoomForPositiveViewComponent>,
    private translate: TranslateService,
    private api: ApiService,
    private log: LogService,
    private modalService: ModalService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public viewdata: any
  ) {
      this.user = this.userService.getUser();
      this.questions= RoomForPositive.ROOM_FOR_POSITIVE_QUESTIONS;
      this.questionTypes=  RoomForPositive.ROOM_FOR_POSITIVE_QUESTIONS_TYPES;
      dialogRef.disableClose = true;
      this.pageData = viewdata.data.objdata;
      this.questionIndex = viewdata.data.questionIndex.toString();
      this.id = viewdata.data.ID;
	  this.questionIndex == 0 ? this.openProudAccordian = true : this.openGratefulAccordian = true
     }

  ngOnInit(): void {
    this.translate.stream("practice.rfp").subscribe((res: any) => {
      this.rfpdata = res;
    });
  }

  deleteEntry() {
    this.modalService.showConfirmation(this.rfpdata.title, this.rfpdata.deleteBody, this.rfpdata.ok, this.rfpdata.cancel)
    .afterClosed().subscribe(data => {
      if (data) {
        this.deleteRfpEntry();
      }
    });

  }
  goBack(){
    // this.router.navigate(["/app/practice/room-for-positive/listing"]);
    this.dialogRef.close();
  }

  deleteRfpEntry() {
    this.api.delete("roomforpositive/" + this.pageData["ID"]).subscribe(
      (result: any) => {
        this.modalService.showAlert(this.rfpdata.successtitle,this.rfpdata.deleteSuccess);
        this.dialogRef.close();
        this.router.navigate(["/app/practice/room-for-positive/listing"]);
      },
      (error: any) => {
        this.modalService.showAlert(this.rfpdata.errortitle, error.message);
        this.log.error("Error deleting. "+ error.message);
      }
    );
  }

  editEntry() {
    this.modalService.showComponent(RoomForPositiveCreateComponent, {  data: {mode: "edit",data:this.pageData}}).afterClosed().subscribe(result => {
      this.dialogRef.close();
    });
  }
}

// accordiion modal

// const items = document.querySelectorAll(".accordion button");

// function toggleAccordion() {
//   const itemToggle = this.getAttribute('aria-expanded');

//   for (i = 0; i < items.length; i++) {
//     items[i].setAttribute('aria-expanded', 'false');
//   }

//   if (itemToggle == 'false') {
//     this.setAttribute('aria-expanded', 'true');
//   }
// }

// items.forEach(item => item.addEventListener('click', toggleAccordion));
