<div class="app-l-video__title-block">
	<div class="app-l-video__title">
		<h3 aria-live="polite">{{ video.label }}</h3>
	</div>
	<!-- <div class="app-l-video__view-count">
			<svg-icon icon="people"></svg-icon>
			<span>234 people have viewed this course</span>
		</div> -->
</div>
<div class="app-l-video-player">
	<wt-video #videoPlayer aria-hidden="true" [callbackArgs]="{ id: video.id }" [callbackUrl]="callbackUrl"
		(onCompleted)="video.isCompleted = true" (onEnded)="videoEnded(video)" [mediaUrl]="video.media"
		[thumbnailUrl]="video.image" [captionUrl]="video.captionFile" [videoId]="video.id"
		(progressUpdate)="updateProgress($event, video)" [autoplay]="autoplay"
		[currentTime]="video.courseProgress?.TimeCompleted || '0'"></wt-video>
	<div aria-hidden="true" *ngIf="video.isCompleted" class="video-status">
		<img alt="OK" src="./assets/img/icons-svg/OK.svg" />
	</div>
	<div class="video-play"></div>
</div>
<!-- <figcaption class="video-caption">
		<div aria-live="polite" tabindex="0" class="video-title">
			
		</div>
	</figcaption> -->
<!-- <a class="rate-video" (click)="onRateItem()"><i class="fa fa-star" aria-hidden="true"></i> Rate
		video.</a> -->

<div class="app-l-video__content">
	<h4 class="app-l-video__content-title" translate>theory.aboutCourse</h4>
	<div class="app-l-video__description" aria-live="polite" tabindex="0" *ngIf="video.description"
		[innerHtml]="video.description"></div>
</div>
<div class="app-l-vd__footer">
	<div class="app-l-vd__f-action" *ngIf="video.links || video.attachments">
		<div *ngFor="let attachment of video.attachments">
			<span class="app-c-btn app-c-btn--secondary app-c-btn--curve"  [attr.aria-label]="attachment.title" target="_blank"
				(click)="attachmentClicked(attachment)" (keypress)="attachmentClicked(attachment)">
				<svg-icon icon="download" alt="download"></svg-icon>
				<span>
					{{ attachment.title }}
				</span>
			</span>
		</div>
		<div *ngFor="let link of video.links">
			<button class="app-c-btn app-c-btn--secondary app-c-btn--curve" aria-label="link" (click)="onInternaLink(link.link)" (keypress)="onInternaLink(link.link)">
				<svg-icon  alt="link image" [icon]="
					link.label != 'Moodcheck'
						? 'book' : 'emoji'
					"></svg-icon>
				<span>{{ link.label }}</span>
			</button>
		</div>
	</div>
	<div class="app-l-vd__f-rate">
		<h4 translate>theory.rateVideoTitle</h4>
		<p translate>theory.rateVideoDesc</p>
		<a *accessControl="'favorites'" class="fav-items" aria-label="favorites" (click)="setFavourite(video)" (keypress)="setFavourite(video)"><img class="icon" alt="favoritesimage" [src]="
							video.isFavorite
								? '/assets/img/icons/icon_fav_filled.svg'
								: '/assets/img/icons/icon_fav_outline.svg'
						" /></a>
		<div class="app-l-vd__rate-wrap">
			<button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-16" aria-label="link" 
				*ngFor="let star of [1, 2, 3, 4, 5]; let i = index" [class.active]="i < selectedStarIndex"
				(click)="rateVideo(i + 1)" (keypress)="rateVideo(i + 1)">
				<svg-icon icon="star-fill" class="w-100 h-100" alt="star-fill"></svg-icon>
				<!-- Unicode character for a star -->
			</button>
		</div>
	</div>
	<!--
	<span *ngIf="video.displayLink" class="link" translate>
		theory.courses.welltrackConnect<br />
		<a class="btn btn-primary btn-inline" href="https://welltrack-connect.com/" target="_blank"
			translate>theory.welltrackConnectHeading</a>
	</span>
	-->
</div>