export class RoomForPositive {

    public static ROOM_FOR_POSITIVE_QUESTIONS_TYPES = ["proud", "grateful"];
  public static ROOM_FOR_POSITIVE_QUESTIONS = [
    {
      questionId: 0,
      questionTitle: "practice.rfp.proudQuestion",
      questionShortTitle: "practice.rfp.shortTitleProud",
    },
    {
      questionId: 1,
      questionTitle: "practice.rfp.gratefulQuestion",
      questionShortTitle: "practice.rfp.shortTitleGrateful",
    },
  ];
}
