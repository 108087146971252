<div class="app-c-number-scale-component">
	<div class="app-c-number-scale__header">
		<div class="app-c-number-scale__header__left">
			<span>Never</span>
		</div>
		<div class="app-c-number-scale__header__right">
			<span class="nps-slider-text">Strongly</span>
		</div>
	</div>
	<div class="app-c-number-scale__wrap" id="btn-group_{{ scaleIndex }}">
		<button
			type="button"
			(click)="selectButtonEvent($event, options)"
			id="{{ scaleIndex }}_{{ options.OptionValue }}"
			*ngFor="let options of scaleOptionValues"
			[value]="options.OptionValue"
			class="app-c-number-scale__item"
			[ngClass]="{'btn-scale':options.isSelected}"
		>
			{{ options.OptionValue }}
		</button>
	</div>
</div>
