<div>
	<div class="moodCheck-header">
		<h3 translate>moodcheck.notes.title</h3>
		<span translate>moodcheck.notes.thoughts</span>
	</div>
	<div class="app-l-note__wrap">
		<div class="app-c-form-group">
			<label for="mood-note-desc" class="app-c-form--label"
				>Description</label
			>
			<textarea
				name="mood-note-desc"
				class="app-c-form-control"
				tabindex="0"
				id="mood-note"
				(change)="onChange()"
				[(ngModel)]="note"
				maxlength="4000"
				aria-label="Type your description"
				placeholder="Type your description"
			></textarea>
		</div>
	</div>
</div>
<!-- <div class="page-section center" translate>
    thoughtDiary.disclaimer
</div> -->
