import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AssessmentService {
	assessmentListingReloadSubject = new BehaviorSubject<boolean>(false);
	constructor() {}

	reloadAssessmentListing() {
		this.assessmentListingReloadSubject.next(true);
	}
	getDasAssessmentResultArray(type: string, label: string, level: number) {
		let resultData = {
			title: "das.result." + type + ".title",
			level: level,
			levelText: "",
			label: label,
			courseLink: "",
			content1: "",
			content2: "",
			content3: "",
			courseTitle: "",
			showEmergencyContact: false,
		};
		switch (true) {
			case level < 1: {
				if (type == "depression") {
					resultData.levelText = "das.result.depression.good.level";
					resultData.courseLink = "/app/series-details/purpose";
					resultData.content1 = "das.result.depression.good.content1";
					resultData.content2 = "das.result.depression.good.content2";
					resultData.courseTitle =
						"das.result.depression.good.courseTitle";
				} else if (type == "anxiety") {
					resultData.levelText = "das.result.anxiety.good.level";
					resultData.courseLink = "/app/series-details/sleep";
					resultData.content1 = "das.result.anxiety.good.content1";
					resultData.content2 = "das.result.anxiety.good.content2";
					resultData.courseTitle =
						"das.result.anxiety.good.courseTitle";
				} else if (type == "stress") {
					resultData.levelText = "das.result.stress.good.level";
					resultData.courseLink = "/app/series-details/disaster";
					resultData.content1 = "das.result.stress.good.content1";
					resultData.courseTitle =
						"das.result.stress.good.courseTitle";
				}
				return resultData;
			}
			case level == 1: {
				if (type == "depression") {
					resultData.levelText = "das.result.depression.mild.level";
					resultData.courseLink =
						"/app/series-details/depression_new";
					resultData.content1 = "das.result.depression.mild.content1";
					resultData.courseTitle =
						"das.result.depression.mild.courseTitle";
				} else if (type == "anxiety") {
					resultData.levelText = "das.result.anxiety.mild.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 = "das.result.anxiety.mild.content1";
					resultData.courseTitle =
						"das.result.anxiety.mild.courseTitle";
				} else if (type == "stress") {
					resultData.levelText = "das.result.stress.mild.level";
					resultData.courseLink = "/app/practice/zen-room";
					resultData.content1 = "das.result.stress.mild.content1";
					resultData.courseTitle =
						"das.result.stress.mild.courseTitle";
				}
				return resultData;
			}
			case level == 2: {
				if (type == "depression") {
					resultData.levelText =
						"das.result.depression.moderate.level";
					resultData.courseLink =
						"/app/series-details/depression_new";
					resultData.content1 =
						"das.result.depression.moderate.content1";
					resultData.content2 =
						"das.result.depression.moderate.content2";
					resultData.courseTitle =
						"das.result.depression.moderate.courseTitle";
				} else if (type == "anxiety") {
					resultData.levelText = "das.result.anxiety.moderate.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 =
						"das.result.anxiety.moderate.content1";
					resultData.content2 =
						"das.result.anxiety.moderate.content2";
					resultData.courseTitle =
						"das.result.anxiety.moderate.courseTitle";
				} else if (type == "stress") {
					resultData.levelText = "das.result.stress.moderate.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 = "das.result.stress.moderate.content1";
					resultData.content2 = "das.result.stress.moderate.content2";
					resultData.courseTitle =
						"das.result.stress.moderate.courseTitle";
				}
				return resultData;
			}
			case level == 3: {
				if (type == "depression") {
					resultData.levelText = "das.result.depression.high.level";
					resultData.courseLink =
						"/app/series-details/depression_new";
					resultData.content1 = "das.result.depression.high.content1";
					resultData.content2 = "das.result.depression.high.content2";
					resultData.content3 = "das.result.depression.high.content3";
					resultData.courseTitle =
						"das.result.depression.high.courseTitle";
					resultData.showEmergencyContact = true;
				} else if (type == "anxiety") {
					resultData.levelText = "das.result.anxiety.high.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 = "das.result.anxiety.high.content1";
					resultData.content2 = "das.result.anxiety.high.content2";
					resultData.content3 = "das.result.anxiety.high.content3";
					resultData.courseTitle =
						"das.result.anxiety.high.courseTitle";
					resultData.showEmergencyContact = true;
				} else if (type == "stress") {
					resultData.levelText = "das.result.stress.high.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 = "das.result.stress.high.content1";
					resultData.content2 = "das.result.stress.high.content2";
					resultData.content3 = "das.result.stress.high.content3";
					resultData.courseTitle =
						"das.result.stress.high.courseTitle";
					resultData.showEmergencyContact = true;
				}
				return resultData;
			}
			case level == 4: {
				if (type == "depression") {
					resultData.levelText =
						"das.result.depression.veryHigh.level";
					resultData.courseLink =
						"/app/series-details/depression_new";
					resultData.content1 =
						"das.result.depression.veryHigh.content1";
					resultData.content2 =
						"das.result.depression.veryHigh.content2";
					resultData.content3 =
						"das.result.depression.veryHigh.content3";
					resultData.courseTitle =
						"das.result.depression.veryHigh.courseTitle";
					resultData.showEmergencyContact = true;
				} else if (type == "anxiety") {
					resultData.levelText = "das.result.anxiety.veryHigh.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 =
						"das.result.anxiety.veryHigh.content1";
					resultData.content2 =
						"das.result.anxiety.veryHigh.content2";
					resultData.content3 =
						"das.result.anxiety.veryHigh.content3";
					resultData.courseTitle =
						"das.result.anxiety.veryHigh.courseTitle";
					resultData.showEmergencyContact = true;
				} else if (type == "stress") {
					resultData.levelText = "das.result.stress.veryHigh.level";
					resultData.courseLink = "/app/series-details/anxiety";
					resultData.content1 = "das.result.stress.veryHigh.content1";
					resultData.content2 = "das.result.stress.veryHigh.content2";
					resultData.content3 = "das.result.stress.veryHigh.content3";
					resultData.courseTitle =
						"das.result.stress.veryHigh.courseTitle";
					resultData.showEmergencyContact = true;
				}
				return resultData;
			}
		}
	}

	getAssessmentScoreLevelForChart(assessmentType: string, score: number) {
		let resultScore: number = 0;
		switch (assessmentType) {
			case "resilience":
			case 'BRCS': {
				if (score >= 17) resultScore = 0;
				else if (score >= 14) resultScore = 1;
				else resultScore = 2;
				break;
			}
			case "alcoholuse":
			case 'AUDIT': {
				if (score >= 8) resultScore = 2;
				else if (score >= 4) resultScore = 1;
				else resultScore = 0;
				break;
			}
			case "anxiety":
			case 'GAD-7': {
				if (score >= 15) resultScore = 3;
				else if (score >= 10) resultScore = 2;
				else if (score >= 5) resultScore = 1;
				else resultScore = 0;
				break;
			}
			case "depression":
			case 'PHQ-9': {
				if (score >= 20) resultScore = 4;
				else if (score >= 15) resultScore = 3;
				else if (score >= 10) resultScore = 2;
				else if (score >= 5) resultScore = 1;
				else resultScore = 0;
				break;
			}
		}

		return resultScore;
	}

	getAssessmentScoreLevel(
		assessmentType: string,
		score: number,
		type?: string
	) {
		let resultScore: number = 0;
		switch (assessmentType) {
			case "resilience": {
				if (score >= 17)
					resultScore = type == "levelIndicationImg" ? 0 : 4;
				else if (score >= 14) resultScore = 1;
				else resultScore = type == "levelIndicationImg" ? 4 : 0;
				break;
			}
			case "alcoholuse": {
				if (score >= 8) resultScore = 4;
				else if (score >= 4) resultScore = 1;
				else resultScore = 0;
				break;
			}
			case "anxiety": {
				if (score >= 15) resultScore = 4;
				else if (score >= 10) resultScore = 2;
				else if (score >= 5) resultScore = 1;
				else resultScore = 0;
				break;
			}
			case "depression": {
				if (score >= 20) resultScore = 4;
				else if (score >= 15) resultScore = 3;
				else if (score >= 10) resultScore = 2;
				else if (score >= 5) resultScore = 1;
				else resultScore = 0;
				break;
			}
		}

		return resultScore;
	}

	getResilienceAssessmentResultArray(level: number, type: string) {
		let resultData = {
			title: "assessments." + type + ".result.title",
			level: level,
			levelText: "",
			levelKey: 0,
			label: "",
			courseLink: "",
			content1: "",
			content2: "",
			content3: "",
			courseTitle: "",
			showEmergencyContact: false,
			showDetailedEmergencyContact: false,
		};

		if (level >= 17) {
			resultData.courseLink = "/app/series-details/disaster";
			resultData.levelKey = 0;
			resultData.label = "assessments.resilience.result.high.label";
			resultData.levelText = "assessments.resilience.result.high.level";
			resultData.content1 = "assessments.resilience.result.high.content1";
			resultData.content2 = "assessments.resilience.result.high.content2";
			resultData.courseTitle =
				"assessments.resilience.result.high.courseTitle";
		} else if (level >= 14) {
			resultData.courseLink = "/app/series-details/disaster";
			resultData.levelKey = 3;
			resultData.label = "assessments.resilience.result.moderate.label";
			resultData.levelText =
				"assessments.resilience.result.moderate.level";
			resultData.content1 =
				"assessments.resilience.result.moderate.content1";
			resultData.content2 =
				"assessments.resilience.result.moderate.content2";
			resultData.courseTitle =
				"assessments.resilience.result.moderate.courseTitle";
		} else {
			resultData.courseLink = "/app/series-details/disaster";

			resultData.label = "assessments.resilience.result.low.label";
			resultData.levelKey = 4;
			resultData.levelText = "assessments.resilience.result.low.level";
			resultData.content1 = "assessments.resilience.result.low.content1";
			resultData.content2 = "assessments.resilience.result.low.content2";
			resultData.courseTitle =
				"assessments.resilience.result.low.courseTitle";
		}
		return resultData;
	}

	getAlcoholUseAssessmentResultArray(level: number, type: string) {
		let resultData = {
			title: "assessments." + type + ".result.title",
			level: level,
			levelText: "",
			levelKey: 0,
			label: "",
			courseLink: "",
			content1: "",
			content2: "",
			content3: "",
			courseTitle: "",
			showEmergencyContact: false,
			showDetailedEmergencyContact: false,
		};

		if (level >= 8) {
			resultData.courseLink = "";
			resultData.levelKey = 4;
			resultData.label = "assessments.alcoholuse.result.high.label";
			resultData.levelText = "assessments.alcoholuse.result.high.level";
			resultData.content1 = "assessments.alcoholuse.result.high.content1";
			resultData.content2 = "assessments.alcoholuse.result.high.content2";
			resultData.courseTitle =
				"assessments.alcoholuse.result.high.courseTitle";
			resultData.showEmergencyContact = true;
		} else if (level >= 4) {
			resultData.courseLink = "";
			resultData.levelKey = 3;
			resultData.label = "assessments.alcoholuse.result.moderate.label";
			resultData.levelText =
				"assessments.alcoholuse.result.moderate.level";
			resultData.content1 =
				"assessments.alcoholuse.result.moderate.content1";
			resultData.content2 =
				"assessments.alcoholuse.result.moderate.content2";
			resultData.courseTitle =
				"assessments.alcoholuse.result.moderate.courseTitle";
			resultData.showEmergencyContact = true;
		} else {
			resultData.courseLink = "/app/series-details/disaster";

			resultData.label = "assessments.alcoholuse.result.low.label";
			resultData.levelKey = 0;
			resultData.levelText = "assessments.alcoholuse.result.low.level";
			resultData.content1 = "assessments.alcoholuse.result.low.content1";
			resultData.content2 = "assessments.alcoholuse.result.low.content2";
			resultData.courseTitle =
				"assessments.alcoholuse.result.low.courseTitle";
		}
		return resultData;
	}

	getAnxietyAssessmentResultArray(level: number, type: string) {
		let resultData = {
			title: "assessments." + type + ".result.title",
			level: level,
			levelText: "",
			levelKey: 0,
			label: "",
			courseLink: "",
			content1: "",
			content2: "",
			content3: "",
			courseTitle: "",
			showEmergencyContact: false,
			showDetailedEmergencyContact: false,
		};

		if (level >= 15) {
			resultData.courseLink = "/app/series-details/anxiety";
			resultData.levelKey = 4;
			resultData.label = "assessments.anxiety.result.high.label";
			resultData.levelText = "assessments.anxiety.result.high.level";
			resultData.content1 = "assessments.anxiety.result.high.content1";
			resultData.content2 = "assessments.anxiety.result.high.content2";
			resultData.content3 = "assessments.anxiety.result.high.content3";
			resultData.showEmergencyContact = true;
			resultData.showDetailedEmergencyContact = true;
			resultData.courseTitle =
				"assessments.anxiety.result.high.courseTitle";
		} else if (level >= 10) {
			resultData.courseLink = "/app/series-details/anxiety";
			resultData.levelKey = 3;
			resultData.label = "assessments.anxiety.result.moderate.label";
			resultData.levelText = "assessments.anxiety.result.moderate.level";
			resultData.content1 =
				"assessments.anxiety.result.moderate.content1";
			resultData.content2 =
				"assessments.anxiety.result.moderate.content2";
			resultData.content3 =
				"assessments.anxiety.result.moderate.content3";
			resultData.showEmergencyContact = true;
			resultData.courseTitle =
				"assessments.anxiety.result.moderate.courseTitle";
		} else if (level >= 5) {
			resultData.courseLink = "/app/series-details/anxiety";

			resultData.label = "assessments.anxiety.result.mild.label";
			resultData.levelKey = 2;
			resultData.levelText = "assessments.anxiety.result.mild.level";
			resultData.content1 = "assessments.anxiety.result.mild.content1";
			resultData.content2 = "assessments.anxiety.result.mild.content2";
			resultData.courseTitle =
				"assessments.anxiety.result.mild.courseTitle";
		} else {
			resultData.courseLink = "/app/series-details/sleep";

			resultData.label = "assessments.anxiety.result.low.label";
			resultData.levelKey = 0;
			resultData.levelText = "assessments.anxiety.result.low.level";
			resultData.content1 = "assessments.anxiety.result.low.content1";
			resultData.content2 = "assessments.anxiety.result.low.content2";
			resultData.courseTitle =
				"assessments.anxiety.result.low.courseTitle";
		}
		return resultData;
	}

	getDepressionAssessmentResultArray(level: number, type: string) {
		let resultData = {
			title: "assessments." + type + ".result.title",
			level: level,
			levelText: "",
			levelKey: 0,
			label: "",
			courseLink: "",
			content1: "",
			content2: "",
			content3: "",
			courseTitle: "",
			showEmergencyContact: false,
			showDetailedEmergencyContact: false,
		};
		if (level >= 20) {
			resultData.courseLink = "/app/series-details/depression_new";
			resultData.levelKey = 4;
			resultData.label = "assessments.depression.result.veryHigh.label";
			resultData.levelText =
				"assessments.depression.result.veryHigh.level";
			resultData.content1 =
				"assessments.depression.result.veryHigh.content1";
			resultData.content2 =
				"assessments.depression.result.veryHigh.content2";
			resultData.content3 =
				"assessments.depression.result.veryHigh.content3";
			resultData.showEmergencyContact = true;
			resultData.showDetailedEmergencyContact = true;
			resultData.courseTitle =
				"assessments.depression.result.high.courseTitle";
		} else if (level >= 15) {
			resultData.courseLink = "/app/series-details/depression_new";
			resultData.levelKey = 3;
			resultData.label = "assessments.depression.result.high.label";
			resultData.levelText = "assessments.depression.result.high.level";
			resultData.content1 = "assessments.depression.result.high.content1";
			resultData.content2 = "assessments.depression.result.high.content2";
			resultData.content3 = "assessments.depression.result.high.content3";
			resultData.showEmergencyContact = true;
			resultData.showDetailedEmergencyContact = true;
			resultData.courseTitle =
				"assessments.depression.result.high.courseTitle";
		} else if (level >= 10) {
			resultData.courseLink = "/app/series-details/depression_new";
			resultData.levelKey = 2;
			resultData.label = "assessments.depression.result.moderate.label";
			resultData.levelText =
				"assessments.depression.result.moderate.level";
			resultData.content1 =
				"assessments.depression.result.moderate.content1";
			resultData.content2 =
				"assessments.depression.result.moderate.content2";
			resultData.content3 =
				"assessments.depression.result.moderate.content3";
			resultData.courseTitle =
				"assessments.depression.result.moderate.courseTitle";
		} else if (level >= 5) {
			resultData.courseLink = "/app/series-details/depression_new";

			resultData.label = "assessments.depression.result.mild.label";
			resultData.levelKey = 1;
			resultData.levelText = "assessments.depression.result.mild.level";
			resultData.content1 = "assessments.depression.result.mild.content1";
			resultData.content2 = "assessments.depression.result.mild.content2";
			resultData.courseTitle =
				"assessments.depression.result.mild.courseTitle";
		} else {
			resultData.courseLink = "/app/series-details/purpose";
			resultData.label = "assessments.depression.result.low.label";
			resultData.levelKey = 0;
			resultData.levelText = "assessments.depression.result.low.level";
			resultData.content1 = "assessments.depression.result.low.content1";
			resultData.content2 = "assessments.depression.result.low.content2";
			resultData.courseTitle =
				"assessments.depression.result.low.courseTitle";
		}
		return resultData;
	}
}
