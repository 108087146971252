<div id="organizationdropdown">
    <div class="dropdownorg" tabindex="0"  *ngIf="organizations.length <= 0 && !isloaded">
        <div class="dropdown-content-org app-l-header__btn-drp">
            <button class="app-l-header__btn">
                <mat-spinner *ngIf="!isloaded" diameter="18"></mat-spinner>
                <div class="app-l-header__btn-text">Loading...</div>
            </button>
        </div>
    </div>
    <div class="dropdownorg" tabindex="0" *ngIf="organizations.length > 0 && isloaded">
        <div class="dropdown-content-org app-l-header__btn-drp" (click)="display()" (keydown)="display()">
            <button class="app-l-header__btn">
                <div class="app-l-header__btn-text">{{organizations[selected].name | truncate: 40}}</div>
                <div class="app-l-header__btn-arrow">
                    <svg-icon icon="angle-down" class="w-100 h-100"></svg-icon>
                </div>
            </button>
            <div *ngIf="displayed" class="submenu-container organization-sub-menu-container show">
                <div *ngFor="let organization of organizations; let i=index" class="dropdown-content"
                    [ngClass]="{'selected': organization.id === organizations[selected].id}">
                    <a (click)="select(i, organization.id)" (keydown)="select(i, organization.id)">
                        <span class="submenu-title">
                            {{organization.name}}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>