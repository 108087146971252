<div id="courses-listing-page">
    <page-header [headerText]="title" [subheaderText]="subtitle" [backLink]="backLink" [cssClass]='cssModule'
        [backText]='backText'></page-header>
    <div class="page-section">


        <div class="page-section-content">

            <page-loader *ngIf="!isLoaded"></page-loader>
            <div class="table-responsive" *ngIf="isLoaded">


                <table class="table">
                    <thead>
                        <tr class="column-header">
                            <th translate>courseListing.chapterTitle</th>
                            <th translate>courseListing.timeTitle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let course of courses;let i = index ">
                            <td class="strong">
                                <a tabindex="0" class="course-name" (click)="resilienceNavigate(course.id, i)">{{course.label}}</a>
                                 <!-- [routerLink]="['/app/series-listing/', path, course.id]" -->
                            </td>
                            <td class="course-time">{{course.length}}</td>
                            <!--
                            <td>
                                <div class="synced" *ngIf="course.isCurrent">
                                    <span class="glyphicon glyphicon-triangle-bottom" aria-hidden="true"></span>
                                    <span>Your Track</span>
                                </div>
                            </td>
                            -->
                            <td class="completed-column">
                                <div class="completed" *ngIf="course.isCompleted">
                                    <div class="img-holder"></div>
                                    <span translate>courseListing.completed</span>
                                </div>
                            </td>

                            <td text-right class="rate">
                                <a *ngIf="course.isCompleted" class="rate-video" (click)="onRate(course)"><i
                                        class="fa fa-star" aria-hidden="true"></i> Rate chapter.</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="talk-about card-container" *ngIf="ssoLink && talkAboutLink">
                <div class="card">
                    <img class="together-all-logo" alt="Logo" src="./assets/img/additional-resources/togetherall.svg">
                    <div>
                        <h2>Discussion Forum on {{title}}</h2>
                        <div class="talk-about-link" [innerHtml]="talkAboutDescription"></div>
                        <a class="btn btn-primary btn-inline" (click)="onTalkAboutLink(talkAboutLink)" translate>theory.gotoDiscussion</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>