<div class="form-modal">
    <mat-dialog-content>
        <a tabindex="0" (click)="onClose()" class="close-modal" aria-label="Close add or edit resource form">
            <img src="./assets/img/icons-svg/close-icon.svg">
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1 *ngIf="resources.mode === 'update'">Edit Resource</h1>
                <h1 *ngIf="resources.mode === 'add'">Add Resource</h1>
            </div>
        </div>
        <page-loader *ngIf="isloaded"></page-loader>
        <form novalidate [formGroup]="resourceForm">
            <div id="resource-section" class="form-wrapper-80">
                <mat-form-field class="wt-mat-form-field">
                    <input cdkFocusInitial matInput class="wt-mat-input" placeholder="Title" tabindex="0" required
                        type="text" formControlName="title" id="resourcesettitle" />
                    <mat-error *ngIf="isSubmitted && resourceForm.get('title').hasError('required')">
                        Title is required
                    </mat-error>
                </mat-form-field>

                <p class="hint">Please fill out at least ONE of the following: Address, Website, Phone # or Email
                    Address</p>
                <mat-form-field class="wt-mat-form-field">
                    <input matInput class="wt-mat-input" placeholder="Address" tabindex="0" type="text"
                        formControlName="address" id="resourceSetAddress" />
                </mat-form-field>

                <mat-form-field class="wt-mat-form-field">
                    <input matInput class="wt-mat-input" placeholder="Website" tabindex="0" type="text"
                        formControlName="website" id="resourceSetWebsite" [pattern]="urlPattern" />
                    <mat-hint>Website should start with http:// or https:// </mat-hint>
                    <mat-error
                        *ngIf="resourceForm.controls['website'].touched && !resourceForm.controls['website'].valid">
                        Invalid website! Website should start with http:// or https://
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="wt-mat-form-field">
                    <input matInput class="wt-mat-input" placeholder="Phone #" tabindex="0" type="text"
                        formControlName="contact" id="resourceSetPhone" />
                    <mat-hint>Format: 123-456-7890 or +11234567890 (E.164)</mat-hint>
                    <mat-error
                        *ngIf="resourceForm.controls['contact'].touched && !resourceForm.controls['contact'].valid">
                        Invalid phone number! Format: 123-456-7890 or +11234567890 (E.164)
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="wt-mat-form-field">
                    <input matInput class="wt-mat-input" placeholder="Email Address" tabindex="0" type="text"
                        formControlName="alternateContact" id="resourceSetEmail" />
                    <mat-error
                        *ngIf="resourceForm.controls['alternateContact'].touched && !resourceForm.controls['alternateContact'].valid">
                        Invalid email!
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="wt-mat-form-field">
                    <input matInput class="wt-mat-input" placeholder="Description" tabindex="0" type="text"
                        formControlName="description" id="description" />
                </mat-form-field>
                <p>
                    <mat-checkbox formControlName="active" [value]="resourceForm.value.active" translate
                        [checked]="resourceForm.value.active">
                        <span translate>Active</span>
                    </mat-checkbox>
                </p>

                <div>
                    <ng-container *ngIf="isSubmitted">
                        <p class="hint" *ngIf="resourceForm.get('title').hasError('required')">Resource needs a title
                        </p>
                        <p class="hint" *ngIf="resourceForm.hasError('atLeastOne')">At least ONE of Address, Website,
                            Phone # or Email
                            Address is required! </p>
                    </ng-container>
                    <div class="btn-wrapper">
                        <button type="submit" tabindex="0" class="btn-primary btn-bold btn" [ngClass]="{
                            'btn-disabled': !resourceForm.dirty || !resourceForm.valid,
                            'btn-primary': resourceForm.dirty && resourceForm.valid
                        }" (click)="doSave()">Save</button>
                        <button type="submit" tabindex="0" class="btn-primary btn-bold btn" [ngClass]="{
                            'btn-disabled': !resourceForm.dirty || !resourceForm.valid,
                            'btn-primary': resourceForm.dirty && resourceForm.valid
                        }" (click)="doSaveAndAdd()">Save and Add Another</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-dialog-content>
</div>