<page-loader *ngIf="!isLoaded"></page-loader>
<div id="page-as-details" class="app-c-modal--secondary" *ngIf="isLoaded">
	<!-- <page-header [cssClass]="'activityscheduler'" [headerText]="title" [backText]="backText" [backLink]='backLink' [headerImage]="'./assets/img/icons/activity_scheduler@2x.png'"></page-header> -->

	<div class="app-c-modal-secondary__header">
		<div class="app-c-modal-secondary__header-left">
			<h3 class="app-c-heading--h3" *ngIf="!activityId" translate>
				as.add
			</h3>
			<h3 class="app-c-heading--h3" *ngIf="activityId" translate>
				as.edit
			</h3>
		</div>
		<div class="app-c-modal-secondary__header-right">
			<button
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14"
				(click)="cancel()"
			>
				<svg-icon icon="close" class="w-100 h-100"></svg-icon>
			</button>
		</div>
	</div>

	<div class="app-c-modal-secondary__body">
		<div class="app-l-select-type" *ngIf="!activityId">
			<h4 class="app-c-heading--h4" translate>as.selectType</h4>
			<div class="app-l-select__controls">
				<button
					[disabled]="isDisabled"
					class="app-c-btn app-c-btn--tertiary"
					*ngFor="let config of configListing"
					(click)="selectType(config.Value)"
					[ngClass]="{'active': isType(config.Value)}"
				>
					<span translate>{{config.Key}}</span>
				</button>
			</div>
		</div>
		<div *ngIf="!activityType && !activityId" class="app-l-no-mood-check">
			<div class="no-data-image">
				<svg-icon icon="room-for-positive"></svg-icon>
				<span class="app-l-no-mood__round app-l-no-mood__round1"></span>
				<span class="app-l-no-mood__round app-l-no-mood__round2"></span>
				<span class="app-l-no-mood__round app-l-no-mood__round3"></span>
			</div>
			<p class="app-c-body-text--14">
				Choose an activity type to select an activity
			</p>
		</div>
		<div *ngIf="activityType && !activityId" class="app-l-select__activity">
			<h4 class="app-c-heading--h4" translate>as.selectType</h4>
			<div class="app-l-select__activity-controls">
				<button
					[disabled]="isDisabled"
					class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
					*ngFor="let activity of activities"
					(click)="toggleOption(activity)"
					[ngClass]="{'activeActivity': activity.isSelected}"
				>
					<span translate>{{activity.Value}}</span>
				</button>
			</div>
		</div>
		<div
			class="app-l-select__new-activity"
			*ngIf="activityType && !activityId"
		>
			<button
				class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--sm"
				(click)="onAddCustomOption()"
			>
				<svg-icon icon="plus"></svg-icon>
				<span translate>select.add</span>
			</button>
		</div>
		<div class="app-l-select__schedule" *ngIf="!activityId">
			<h4 class="app-c-heading--h4" translate>Schedule</h4>
			<div class="app-l-ss__row" [ngClass]="{ disable: !activityType }">
				<div class="app-l-ss__blocks">
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> Start Date</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								(ngModelChange)="changeStartDateTime($event)"
								[owlDateTime]="dt1"
								[owlDateTimeTrigger]="dt1"
								placeholder="Start Date"
								[(ngModel)]="start"
								[min]="minDate"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="calendar"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'calendar'"
								#dt1
							></owl-date-time>
						</div>
					</div>
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> Start Time</label>
						<div class="app-c-timepicker app-c-form-group">
							<input
								class="app-c-form-control"
								[owlDateTimeTrigger]="dt4"
								[min]="minDate"
								placeholder="Start time"
								[owlDateTime]="dt4"
								[(ngModel)]="start"
								(ngModelChange)="changeStartDateTime($event)"
							/>
							<div class="app-c-timepicker__icon">
								<svg-icon icon="clock"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'timer'"
								[stepMinute]="15"
								[hour12Timer]="true"
								#dt4
							></owl-date-time>
						</div>
					</div>
				</div>
				<div class="app-l-ss__line"></div>
				<div class="app-l-ss__blocks">
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> End Date</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								(ngModelChange)="changeEndDateTime($event)"
								[owlDateTime]="dt2"
								[owlDateTimeTrigger]="dt2"
								placeholder="End Date"
								[(ngModel)]="end"
								[min]="start"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="calendar"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'calendar'"
								#dt2
							></owl-date-time>
						</div>
					</div>
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> End Time</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								[owlDateTimeTrigger]="dt5"
								placeholder="End time"
								[owlDateTime]="dt5"
								[(ngModel)]="end"
								(ngModelChange)="changeEndDateTime($event)"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="clock"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'timer'"
								[stepMinute]="15"
								[hour12Timer]="true"
								#dt5
							></owl-date-time>
						</div>
					</div>
				</div>
			</div>

			<div class="date-error" *ngIf="!isEndDateValid">
				<span class="app-c-body-text--14-m" translate
					>as.endDateValidationMsg</span
				>
			</div>
		</div>
		<div class="app-l-select__schedule" *ngIf="activityId">
			<h4 class="app-c-heading--h4">
				<span>{{activity.title}}</span
				><img
					src="./assets/img/icons-svg/delete-button-icon.svg"
					(click)="deleteActivity(activity.id)"
				/>
			</h4>
			<div class="app-l-ss__row">
				<div class="app-l-ss__blocks">
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> Start Date</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								(ngModelChange)="changeStartDateTime($event)"
								[owlDateTime]="dt1"
								[owlDateTimeTrigger]="dt1"
								placeholder="Start Date"
								[(ngModel)]="start"
								[min]="minDate"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="calendar"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'calendar'"
								#dt1
							></owl-date-time>
						</div>
					</div>
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> End Date</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								(ngModelChange)="changeEndDateTime($event)"
								[owlDateTime]="dt2"
								[owlDateTimeTrigger]="dt2"
								placeholder="End Date"
								[(ngModel)]="end"
								[min]="start"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="calendar"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'calendar'"
								#dt2
							></owl-date-time>
						</div>
					</div>
				</div>
				<!-- <div class="app-l-ss__line"></div> -->
				<div class="app-l-ss__blocks">
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> Start Time</label>
						<div class="app-c-timepicker app-c-form-group">
							<input
								class="app-c-form-control"
								[owlDateTimeTrigger]="dt4"
								[min]="minDate"
								placeholder="Start time"
								[owlDateTime]="dt4"
								[(ngModel)]="start"
								(ngModelChange)="changeStartDateTime($event)"
							/>
							<div class="app-c-timepicker__icon">
								<svg-icon icon="clock"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'timer'"
								[stepMinute]="15"
								[hour12Timer]="true"
								#dt4
							></owl-date-time>
						</div>
					</div>
					<div class="app-l-ss__block">
						<label class="app-c-body-text--14-m"> End Time</label>
						<div class="app-c-datepicker app-c-form-group">
							<input
								class="app-c-form-control"
								[owlDateTimeTrigger]="dt5"
								placeholder="End time"
								[owlDateTime]="dt5"
								[(ngModel)]="end"
								(ngModelChange)="changeEndDateTime($event)"
							/>
							<div class="app-c-datepicker__icon">
								<svg-icon icon="calendar"></svg-icon>
							</div>
							<owl-date-time
								[pickerType]="'timer'"
								[stepMinute]="15"
								[hour12Timer]="true"
								#dt5
							></owl-date-time>
						</div>
					</div>
				</div>
			</div>

			<div class="date-error" *ngIf="!isEndDateValid">
				<span class="app-c-body-text--14-m" translate
					>as.endDateValidationMsg</span
				>
			</div>
		</div>
	</div>
	<div class="app-c-modal-secondary__footer">
		<a
			tabindex="4"
			class="app-c-btn app-c-btn--secondary app-c-btn--curve"
			(click)="cancel()"
			(keypress)="cancel()"
			translate
			>Cancel</a
		>
		<a
			tabindex="6"
			class="app-c-btn app-c-btn--primary app-c-btn--curve"
			*ngIf="!activityId"
			(click)="onSchedule()"
			[ngClass]="{ 'btn-disabled': !isEndDateValid }"
			(keypress)="onSchedule()"
			translate
			>Schedule</a
		>
		<a
			tabindex="6"
			class="app-c-btn app-c-btn--primary app-c-btn--curve"
			*ngIf="activityId"
			(click)="save()"
			[ngClass]="{ 'btn-disabled': !isEndDateValid }"
			(keypress)="save()"
			translate
			>Save</a
		>
	</div>
</div>
