
<div id="page-user-listing" class="page-section">
    <div class="page-section">
        <div class="page-section-header">
            <h1 translate>Assessment Details</h1>
        </div>
        <div class="page-section-content">
            <div class="table-responsive">
                <page-loader *ngIf="isLoaded"></page-loader>
                <table class="table">
                    <thead *ngIf="assessmentList.length >0">
                        <tr>
                            <th translate style="width:25%" >UserName</th>
                            <th translate *ngFor="let header of assessmentList.length >0  && assessmentList[0].Questions; let i = index">
                             {{header.Question}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of assessmentList" >
                            <td  >
                                {{data.UserName}}
                            </td>
                            <td *ngFor="let answers of data.Questions; let i = index">
                                 <!-- {{answers | json}} -->
                                <span >{{answers.QuestionType == 5  || answers.QuestionType == 6 ||  answers.QuestionType == 7? answers.AnswerValue : answers.OptionComment}}</span>
                               <br/>
                                <span  *ngFor="let subQuestion of answers.Sub; let j = index">
                                {{subQuestion.Question}} &nbsp;&nbsp;{{subQuestion.AnswerValue}}

                               </span>
                               
                            </td>
                                
                        </tr>
                        <tr *ngIf="assessmentList.length === 0">
                            <td colspan="5" style="text-align: center;">No data to display</td>
                        </tr>
                    </tbody>
                    <div class="page-section-buttons" *ngIf="assessmentList.length > 0" (click)="LoadMore()">
                        <a tabindex="0" translate>Load More</a>
                    </div>
                </table>
            </div>
        </div>
    </div>
</div>
