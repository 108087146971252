import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { LogService } from "../../lib/log.service";
import { ApiService } from "../../lib/api.service";
import { Course } from "../../models/course";
import { User } from "../../models/user";
import { TranslateService, TranslationChangeEvent } from "@ngx-translate/core";
import { ModalService } from "../../lib/modal.service";
import { UserService } from "../../lib/user.service";
import { CourseCompleteFeedbackComponent } from "../../components/course-complete-feedback/course-complete-feedback.component";
import { AnalyticEvent } from "app/lib/analytic-event";
import { UrlService } from "../../lib/url.service";

@Component({
	selector: "app-courses-listing",
	templateUrl: "./courses-listing.component.html",
	styleUrls: ["./courses-listing.component.scss"],
})
export class CoursesListingComponent implements OnInit, OnDestroy {
	path: string;
	private sub: Subscription;
	modules: string[];
	courses: any[];
	title: string;
	subtitle: string;
	cssModule: string;
	backLink: string;
	isLoaded: boolean;
	backText: string;
	translateTitles: string[];
	talkAboutLink: string;
	talkAboutDescription: string;
	ssoLink: string;
	user: User;
	// previousUrl: Observable<string> = this.urlService.previousUrls;
	previousUrl: string = null;
	currentUrl: string = null;
	tempbackLink: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private log: LogService,
		private api: ApiService,
		private modalService: ModalService,
		private translate: TranslateService,
		private userService: UserService,
		private urlService: UrlService
	) {
		this.user = this.userService.getUser();
		this.backText = "Back to List";
		this.courses = [];
		this.subtitle = "";
		this.modules = [
			"anxiety",
			"anxiety_old",
			"depression",
			"phobia",
			"disaster",
			"resilience",
			"sleep",
			"wellbeing",
			"mindfulness",
			"purpose",
			"work",
			"depression_new",
		];
		this.translateTitles = [];
		if (this.urlService.getPreviousUrl() != null) {
			this.backLink = this.urlService.getPreviousUrl();
		} else {
			this.backLink = "/app/series/";
		}
	}

	ngOnInit() {
		this.urlService.setCurrentUrlCourse(this.router.url);

		this.translate.stream("courseListing").subscribe((res: any) => {
			this.backText = res.back;
			this.translateTitles = res.title;
		});

		this.sub = this.activatedRoute.params.subscribe((params) => {
			this.path = params["series"];
			this.api.get("serieslisting/" + this.path).subscribe(
				(result: any) => {
					const data = result.data;
					this.courses = Course.initializeArray(result.data);
					if (this.courses.length === 1) {
						this.router.navigateByUrl(
							"/app/series-listing/" +
								this.path +
								"/" +
								this.courses[0].id
						);
					}
					this.ssoLink = localStorage.getItem("ssoLink");
					this.talkAboutDescription =
						this.courses[0]?.TalkAboutDescription ||
						this.courses[0]?.talkAboutDescription;
					this.talkAboutLink =
						this.courses[0]?.TalkAboutLink ||
						this.courses[0]?.talkAboutLink;
					this.log.event(AnalyticEvent.event.courseDetailsLoaded);
					this.checkForCurrent();
					this.setFocus();
				},
				(error: any) => {
					this.log.error(
						"Error getting course data. " + error.message
					);
				},
				() => {
					this.isLoaded = true;
				}
			);
			this.cssModule = this.path;
		});
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	checkForCurrent() {
		let first = true;
		for (let i = 0; i < this.courses.length; i++) {
			if (this.courses[i].isCompleted === false && first) {
				this.courses[i].isCurrent = true;
				first = false;
			} else {
				this.courses[i].isCurrent = false;
			}
		}
	}

	setFocus() {
		setTimeout(function () {
			jQuery(".course-name").eq(0).focus();
		}, 500);
	}

	onRate(course) {
		this.modalService.setCloseonClick(false);
		this.modalService.showComponent(
			CourseCompleteFeedbackComponent,
			course
		);
	}
	onTalkAboutLink(link) {
		window.open(this.ssoLink + link, "_blank");
		this.api
			.post("analytics/additionalresources", {
				link: this.ssoLink + link,
				orgId: this.user.primaryOrganization.id,
			})
			.subscribe(
				(result: any) => {
					//do nothing
				},
				(error: any) => {
					this.log.error("Error logging link click");
				}
			);
	}
}
