import {
	Component,
	ViewChild,
	ViewEncapsulation,
	OnInit,
	Inject,
} from '@angular/core';
import { User } from '../../models/user';
import { Assessment, AssessmentPDF } from '../../models/assessment';
import { UserService } from '../../lib/user.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService } from 'app/lib/log.service';
import { StorageService } from 'app/lib/storage.service';
import { LocationService, CrisisHotline } from 'app/lib/location.service';
import { ApiService } from 'app/lib/api.service';
import { Organization } from '../../../app/models/organization';
import { ModalService } from 'app/lib/modal.service';
import { AssessmentService } from 'app/lib/assessment.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { UtilityService } from 'app/lib/utility.service';
import * as moment from 'moment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import * as _ from "lodash";
import { Column } from 'ng2-smart-table/lib/lib/data-set/column';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
import { Location } from '@angular/common';

@Component({
	selector: 'page-assessment-alert',
	templateUrl: 'assessment-alert.html',
	styleUrls: ['./assessment-alert.scss'],
})
export class AssessmentAlert implements OnInit {
    type: string;
    message: string;
    email: string;
    success = false;
    share = true;
    user: User;
    assessment: Assessment[];
	assessmentPdfView: AssessmentPDF[];
    stresstitle: string;
    depressiontitle: string;
    anxietytitle: string;
    anxietylevel: number;
    depressionlevel: number;
    stresslevel: number;
    intrusive: number;
    avoidance: number;
    hyperarousal: number;
    ptsd: number;
    ptsdalert: boolean = false;
    ptsdtitle: string;
    ptsdbody: string;
    ptsdlevels: any;
    finish: boolean;
    showdas: boolean;
    enableResources: boolean = false;
    assessmentResult: Array<any> = [];
    depressiondescription = 'Your depression level is ';
    anxietydescription = 'Your anxiety level is ';
    stressdescription = 'Your stress level is ';
    buttonText: string;
    quizType: string;
    cdkScrollable;
    contactGroup: {};
    gethelpbutton: boolean = false;
    hotline: CrisisHotline;
    dialing: boolean;
    popupText: any;
    emergencyContact: string;
    organization: Organization;
    isloaded: boolean = false;
    contactGroupTelephone: string;
    showOther: boolean;
    id: string;
    resourcegroup: any;
    customResourceSet: Assessment[];
    showall: boolean = true;
    selectedID: string;
    score: number;
    resourcesSetResult: boolean = false;
    previousUrl: string;
    levelScore: number;
    gethelpbuttondas: boolean = false;
    returnPath: any;
    showEmptyHeader: boolean = false;
	content: any[];
	base64LogoImage: any;
	base64HeaderBarImage: any;
	pdfObj = null;
	public dd = {
		content: [],
		styles: {
			header: {
				bold: true,
				fontSize: 15,
				margin: [0, 15]
			}
		},
		defaultStyle: {
			fontSize: 12
		}
	}

    syncid: string;
	translationData: any;
	translationResData: any;
	resourcesQuestions: any[];
    currentLanguage: any;
	translationResources: any;
    constructor(
        private api: ApiService,
        private log: LogService,
        private storage: StorageService,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private userService: UserService,
        private translate: TranslateService,
        private locationService: LocationService,
        private router: Router,
        private assessmentService: AssessmentService,
		private utilityService: UtilityService,
        private location: Location
    ) {
        this.user = this.userService.getUser();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.currentLanguage = this.assessment?.length
                ? this.getLangForResourceList(this.assessment[0])
                : this.storage.get('lang');
        });
        this.id = this.route.snapshot.paramMap.get('id');
        if  (this.user.userType !== 'user')  {
            this.syncid = this.route.snapshot.paramMap.get('sid');
        }
        this.type = this.route.snapshot.paramMap.get('type');
        this.route.queryParams.subscribe(params => {
            this.previousUrl = params['prevpage'];
      });
        this.selectedID = 'all';
        this.contactGroup = this.user.organizations[0]?.contactGroup;
        this.translate
            .stream('das.result.ptsd.levels')
            .subscribe((res: any) => {
                this.ptsdlevels = res;
            });
		this.translate.stream('dynamicAssessment').subscribe((res: any) => {
			this.translationData = res;
		});
		this.translate.stream('dynamicAssessments').subscribe((res: any) => {
			this.translationResData = res;
		});
		this.translate.stream('resources').subscribe((res: any) => {
			this.translationResources = res;
		});
		if (
			this.user.primaryOrganization &&
			this.user.primaryOrganization.enableResources
		) {
			this.enableResources =
				this.user.primaryOrganization.enableResources;
		}

		this.route.queryParams.subscribe(queryParams => {
			this.returnPath = queryParams['path'];
		});
		if (this.returnPath === 'onboarding') {
			this.showEmptyHeader = true;
		}
	}

    ngOnInit() {
        let url =
            this.type === 'dass'
                ? `assessment/${this.id}`
                : `assessment/${this.id}?type=${this.type}`;
        if  (this.syncid && this.user.userType !== 'user')  {
            url += `?SynchedUserID=${this.syncid}`;
        }
        this.api.get(url).subscribe(
            (result: any) => {
                this.isloaded = true;
                this.assessment = Assessment.initializeArray(result.data);
				if (result?.data) {
					this.pdfDataResult(this.assessment[0].userQuizId)
				}
                this.showHelpNow();
                this.currentLanguage = this.getLangForResourceList(
                    this.assessment[0]
                );
                this.quizType = this.assessment[0].type;
                if (this.quizType === 'ptsd') {
                    this.finish = false;
                    this.showdas = true;
                    if (
                        this.assessment[0].intrusive >= 1 &&
                        this.assessment[0].avoidance >= 3 &&
                        this.assessment[0].hyperarousal >= 2
                    ) {
                        this.ptsdalert = true;
                        this.ptsdtitle = this.ptsdlevels.hightitle;
                        this.ptsdbody = this.ptsdlevels.highbody;
                    } else {
                        this.ptsdtitle = this.ptsdlevels.lowtitle;
                        this.ptsdbody = this.ptsdlevels.lowbody;
                    }
                } else {
                    this.finish = true;
                    this.showdas = true;
                }
                this.depressiontitle = this.assessment[0].depressionlevellabel;
                this.depressionlevel = this.assessment[0].depressionlevel;
                this.anxietytitle = this.assessment[0].anxietylevellabel;
                this.anxietylevel = this.assessment[0].anxietylevel;
                this.stresstitle = this.assessment[0].stresslevellabel;
                this.stresslevel = this.assessment[0].stresslevel;
                this.intrusive = this.assessment[0].intrusive;
                this.score = this.assessment[0].score;
                this.generateDasAssessmentResult();
            },
            (error: any) => {
                this.log.error('Error getting assessment. ' + error.message);
            },
            () => {
                this.isloaded = true;
            }
        );

		this.translate.get('contact').subscribe((res: any) => {
			this.popupText = res;
		});
		this.locationService.getLocation().subscribe((data: any) => {
			this.log.debug(data);
			this.hotline = this.locationService.getHelpline(
				data.country,
				data.regionName
			);
		});

		let orgId;
		if (this.storage.get('orgselect')) {
			orgId = this.storage.get('orgselect');
		} else {
			if (this.user.primaryOrganization) {
				orgId = this.user.primaryOrganization.id;
			}
		}

		if (orgId) {
			this.api.get('organizations/' + orgId).subscribe(
				(result: any) => {
					if (result) {
						this.organization = new Organization(result.data,
							'view');
						if (this.type === 'dass') {
							this.orgQuestions();
						}
						if (this.organization.contactGroup.telephone) {
							this.contactGroupTelephone =
								this.organization.contactGroup.telephone;
						} else {
							this.showOther = true;
						}
					}
				},
				(error: any) => {
					this.log.error(
						'Error getting organization. ' + error.message
					);
				},
				() => {
					this.isloaded = true;
				}
			);
		}
	}

	orgQuestions() {
		// for each on question set.  loop and add answers
		if (
			this.organization.resourceSet &&
			this.organization.enableResources && this.assessment[0]?.resourceSet.length != 0
		) {
			this.resourcesQuestions = [];
			const questionObject = {
				Question: this.translationResData.resourceQuestionTitle,
				OptionComments: []
			};
			this.assessment[0].resourceSet.forEach(qs => {
				questionObject.OptionComments.push(qs.title);
			});
			this.resourcesQuestions.push(questionObject);
		}
	}
	generateDasAssessmentResult() {
		let result = [];
		if (this.type === 'dass') {
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'depression',
					this.depressiontitle,
					this.depressionlevel
				)
			);
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'anxiety',
					this.anxietytitle,
					this.anxietylevel
				)
			);
			result.push(
				this.assessmentService.getDasAssessmentResultArray(
					'stress',
					this.stresstitle,
					this.stresslevel
				)
			);
		} else {
			// let resultData = [];
			switch (this.type) {
				case 'resilience': {
					result.push(
						this.assessmentService.getResilienceAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'alcoholuse': {
					result.push(
						this.assessmentService.getAlcoholUseAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'anxiety': {
					result.push(
						this.assessmentService.getAnxietyAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				case 'depression': {
					result.push(
						this.assessmentService.getDepressionAssessmentResultArray(
							this.score,
							this.type
						)
					);
					break;
				}
				default: {
				}
			}
		}
		this.assessmentResult = [...result];
	}

	showHelpNow() {
		if (
			this.assessment[0].depressionlevel >= 3 ||
			this.assessment[0].anxietylevel >= 3 ||
			this.assessment[0].stresslevel >= 3
		) {
			this.gethelpbuttondas = true;
		}

		if (this.type === 'dass') {
			this.gethelpbuttondas = this.assessment.some(
				assess =>
					assess.depressionlevel >= 3 ||
					assess.anxietylevel >= 3 ||
					assess.stresslevel >= 3
			);
		} else {
			this.gethelpbutton = this.assessment.some(assess => {
				this.levelScore =
					this.assessmentService.getAssessmentScoreLevel(
						assess.type,
						assess.resultScore
					);
				switch (assess.type) {
					case 'resilience':
						return false;
					case 'alcoholuse':
						return this.levelScore >= 1;
					case 'anxiety':
						return this.levelScore >= 2;
					case 'depression':
						return this.levelScore >= 3;
					default:
						return false;
				}
			});
		}
	}

	goNext() {
		this.showdas = false;
		this.finish = true;
	}
	showResourceSetGroup(resource, val) {
		if (val === 'true') {
			this.selectedID = 'all';
			this.showall = true;
		} else {
			this.selectedID = resource.id;
			this.showall = false;
			this.resourcegroup = [];
			this.resourcegroup = resource.resourcesetGroup;
		}
	}

    close() {
        if (this.router.url.search('user-details') !== -1) {
            this.success = false;
        } else if (this.quizType === 'ptsd') {
            this.success = false;
            this.router.navigateByUrl('/app/resources');
        } else if (this.router.url.search('/app/alerts/listing') !== -1) {
            this.success = false;
            this.router.navigateByUrl('/app/alerts/listing');
        } else if (
            this.returnPath === 'onboarding' &&
            this.user.onboardingStep >= 3
        ) {
            this.success = false;
            this.router.navigate(['app']);
            // this.router.navigateByUrl('/user-onboarding');
        } else if (this.previousUrl === 'adminlisting') {
            this.success = false;
            this.router.navigateByUrl(
                '/app/assessment/' + this.type + '/listing/' + this.syncid
            );
        } else if (this.previousUrl === 'clientlist') {
            this.success = false;
            this.router.navigateByUrl(
                '/professional/user-details-dashboard/' + this.syncid
            );
        } else {
            this.success = false;
            this.router.navigateByUrl(
                '/app/assessment/' + this.type + '/listing'
            );
        }
    }

	goResources() {
		// this.dialogRef.close();
		this.router.navigateByUrl('/app/resources');
	}

	onCallContact(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();
			const hotLineText =
				this.user.primaryOrganization &&
					this.user.primaryOrganization.settings['assessment'] ===
					'resilience'
					? this.popupText.popupEmergencyTextVeteran
					: this.organization.contactGroup.getHelpDescription
						? this.organization.contactGroup.getHelpDescription
						: this.popupText.popupEmergencyHotline;

			this.modalService
				.showConfirmation(this.popupText.popTitle, hotLineText)
				.afterClosed()
				.subscribe(result => {
					if (result) {
						if (this.contactGroupTelephone) {
							window.location.href =
								'tel:' + this.contactGroupTelephone;
						}

						this.api
							.post('analytics/supportlineclick', {
								phoneNumber: this.contactGroupTelephone,
								contactGroupId:
									this.organization.contactGroup.id,
							})
							.subscribe(
								(result: any) => {
									this.log.debug('Activity logged');
								},
								(error: any) => {
									this.log.debug(
										'Something went wrong with the activity logger.'
									);
								}
							);

						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent('click'));
					}
				});
		}
	}

	onHotline(event: MouseEvent) {
		if (this.dialing) {
			this.dialing = false;
		} else {
			event.preventDefault();

			this.modalService
				.showConfirmation(
					this.popupText.popTitle,
					this.popupText.popUpHotline
				)
				.afterClosed()
				.subscribe(result => {
					if (result) {
						if (this.hotline.url) {
							window.location.href = this.hotline?.url;
						}
						/*
					this.api.post('analytics/supportlineclick', { phoneNumber: this.contactGroupTelephone, contactGroupId: this.organization.contactGroup.id }).subscribe(
						(result: any) => {
							this.log.debug('Activity logged');
						},
						(error: any) => {
							this.log.debug('Something went wrong with the activity logger.');
						}
					);
					*/
						//Add analytics event for general hotline dialed
						this.dialing = true;
						event.target.dispatchEvent(new MouseEvent('click'));
					}
				});
		}
	}

	delete() {
		this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete your assessment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
					const Url =
						this.type === 'dass'
							? 'assessment/' + this.assessment[0].userQuizId
							: `assessment/` +
							this.assessment[0].userQuizId +
							`?Type=${this.type}`;
					this.api.delete(Url).subscribe(
						(result: any) => {
							this.log.event(
								AnalyticEvent.event.assessmentDeleted
							);
							this.modalService.showAlert(
								'Success',
								'Assessment has been deleted successfully.'
							);
							this.router.navigate([
								'/app/assessment/' + this.type + '/listing',
							]);
						},
						(error: any) => {
							this.log.error('Error deleting.');
						}
					);
				}
			});
	}

	onResourceClick(contact) {
		window.open(contact.website, '_blank');
		this.api
			.post('analytics/resourceclick', {
				resourceGroup: contact,
			})
			.subscribe(
				(result: any) => { },
				(error: any) => {
					this.log.error('Error logging link click');
				}
			);
	}

	onResourceInternalClick(contact) {
		this.api
			.post('analytics/resourceclick', {
				resourceGroup: contact,
			})
			.subscribe(
				(result: any) => {
					this.router.navigateByUrl(contact.internal);
				},
				(error: any) => {
					this.log.error('Error logging link click');
				}
			);
	}

	onLink(link: string) {
		this.router.navigate([link]);
	}

	showConnect() {
		return (
			!!this.user.primaryOrganization &&
			this.keyProp(this.user.primaryOrganization.settings)
		);
	}

	keyProp(settings) {
		return settings?.hasOwnProperty('showWelltrackConnect')
			? settings['showWelltrackConnect']
			: true;
	}


	pdfDataResult(qId) {

		let url = 'assessment/answers?type='+this.type+'&userQuizId=' + qId
		if  (this.syncid && this.user.userType !== 'user')  {
			url += `&SynchedUserID=${this.syncid}`;
		}

		this.api.get(url).subscribe(
			(result: any) => {
				this.isloaded = true;
				this.assessmentPdfView = AssessmentPDF.initializeArray(result);
			},
			(error: any) => {
				this.log.error('Error getting assessment. ' + error.message);
			},
			() => {
				this.isloaded = true;
			});

	}


	async exportAssessemnt() {
		const base64LogoImage = await this.utilityService.loadImageFromAsset(
			"./assets/header-logo-1.jpg"
		);
		const base64HeaderBarImage = await this.utilityService.loadImageFromAsset(
			"./assets/header-bar.jpg"
		);

		let logoImage = `data:image/svg;base64,${base64LogoImage}`;
		let headerBarImage = `data:image/svg;base64,${base64HeaderBarImage}`;
		let content = [
			{
				image: logoImage,
				alignment: "center",
				margin: [0, 0, 0, 10],
				width: 300,
			},
			{
				image: headerBarImage,
				alignment: "center",
				width: 600,
			},

			{
				stack: [
					{
						text: this.translationData[this.type].title,
						style: "header",
						color: "#3a0165",
					},
					{
						text:
							"Created On: " +
							moment(
								this.assessment[0].created
							).format("MM-DD-YYYY"),
						style: "subheader",
						color: "#3a0165",
					},
				],
				alignment: "center",
				margin: [0, 20, 0, 20],
			},
		];
		this.createPdfData(content)
		var data = {
			footer: function (currentPage, pageCount) {
				return [
					{
						text: currentPage.toString() + " of " + pageCount,
						alignment: "center",
						opacity: 0.5,
					},
				];
			},

			content: content,

			styles: {
				mainheader: {
					fontSize: 18,
					bold: true,
					margin: [0, 20, 0, 10],
				},
				header: {
					fontSize: 16,
					bold: true,
				},
				subheader: {
					fontSize: 8,
					bold: true,
				},
				sectionHeader: {
					fontSize: 14,
					bold: true,
				},
				subSectionHeader: {
					fontSize: 12,
					bold: true,
					margin: [0, 7, 0, 6],
				},
				subContentData: {
					fontSize: 10,
					bold: false,
					margin: [0, 0, 0, 2],
				},
				subContentDataTitle:{
					fontSize: 10,
					bold: false,
					margin: [0, 1, 0, 2],
				},
				indentedContent: {
					fontSize: 10,
					alignment: 'left',
					margin: [0, 10, 0, 3] 
				},
				subAnswerTitle: {
					fontSize: 10,
					bold: true,
					margin: [0, 0, 0, 2],
				},
			},
		};
		const fileName = this.translationData[this.type].title +
			"_" +
			moment().format("MM-DD-YYYY_HH.mm.ss") +
			".pdf";
		pdfMake.createPdf(data).open();
	}

	createPdfData(content: any) {
		const questionTitleConfig = this.createSectionHeader("\n\n$questionTitle$\n\n", -25);
		const sectionTitleConfig = this.createSectionHeader("\n\n$sectionTitle$\n\n", -25);
		const resourceTitleConfig = this.createSectionHeader("\n\n$resourceTitle$\n\n", -15, 0);
	
		const dynamicTitle = this.translationData[this.type].questionTitle ;
	
		// Add question title
		const questionTitle = { ...questionTitleConfig, text: questionTitleConfig.text.replace("$questionTitle$", dynamicTitle) };
		content.push(questionTitle);
	
	// Initialize the combined list for questions and resources
    let combinedQuestions = [];

    // Generate regular questions (with answers) and add to the combined list
    if (this.assessmentPdfView && this.assessmentPdfView[0].questions) {
        combinedQuestions = combinedQuestions.concat(
            this.generateQuestions(this.assessmentPdfView[0].questions, 'question', 'optionComment', 'answer')
        );
    }

    // Generate resource questions (with answers) and append to the combined list
    if (this.resourcesQuestions) {
        combinedQuestions = combinedQuestions.concat(
            this.generateQuestions(this.resourcesQuestions, 'Question', 'OptionComments', 'Answer')
        );
    }

    // Add the combined list of questions (regular + resource) as a numbered list
    content.push({
        ol: combinedQuestions,  // 'ol' ensures the list is numbered
        start: 1  // Start numbering from 1 (will continue through the combined list)
    });
	
	const assessmentResultTitle = this.translationResData.assessmentResults ;
		// Add section title for results
		const sectionTitle = { ...sectionTitleConfig, text: sectionTitleConfig.text.replace("$sectionTitle$", assessmentResultTitle) };
		content.push(sectionTitle);
	
		// Add assessment results
		content.push(...this.generateAssessmentResults(this.assessmentResult));
	
		// Add resources if available
		if (this.assessment[0].resourceSet.length !== 0) {
			const recommendedResourceTitle = this.translationResources.recommendedResources ;
			const resourceTitle = { ...resourceTitleConfig, text: resourceTitleConfig.text.replace("$resourceTitle$", recommendedResourceTitle) };
			content.push(resourceTitle);
			content.push(...this.generateResourceSet(this.assessment[0].resourceSet));
		}
	}
	
	// Helper function to create section headers
	createSectionHeader(text: string, marginTop: number, marginBottom = 0) {
		return {
			text,
			style: "sectionHeader",
			color: "#3a0165",
			margin: [0, marginTop, 0, marginBottom]
		};
	}
	
	// Helper function to generate questions
	generateQuestions(questions: any[], questionKey = 'question', optionKey = 'optionComment', answerKey = 'answer') {
		const questionsList = [];
	
		// Loop through each question set
		for (const questionSet of questions) {
			const questionStack = [
				{ 
					text: questionSet[questionKey], 
					style: "subContentData", 
					alignment: 'left', 
					bold: false, 
					margin: [0, 5, 0, 0] 
				}
			];
	
			// Handle options (could be an array or a string)
			const optionsList = Array.isArray(questionSet[optionKey]) ? questionSet[optionKey] : [questionSet[optionKey]];
			optionsList.forEach(option => {
				if (option) {
					questionStack.push({ 
						text: option, 
						style: "subContentData", 
						alignment: 'left', 
						bold: true, 
						margin: [10, 2, 0, 0] 
					});
				}
			});
	
			// Handle the answer (if available)
			if (questionSet[answerKey]) {
				questionStack.push({
					text: `Answer: ${questionSet[answerKey]}`,
					style: "subContentData",
					alignment: 'left',
					bold: true,
					margin: [10, 5, 0, 10]  // Adjust margin for the answer
				});
			}
	
			questionsList.push({ stack: questionStack });
		}
	
		return questionsList;
	}
	
	// Helper function to generate assessment results
	generateAssessmentResults(results: any[]) {
		const subSectionData = [];
		
	
		results.forEach(result => {
			const translateVariable = this.type === 'dass' ? 'dynamicAssessments.' : '';
			// Wrap the content in `columns` array
			const subSectionContentData = {
				text: [
					{
						text: this.translate.instant(translateVariable + result.title),
						style: "subSectionHeader"
					},
					{
						text: '\u00A0' + '-' + '\u00A0',
					},
					{
						text: this.translate.instant(result.label),
						style: "subSectionHeader"
					}
				],
				margin: [0, 5, 0, 7]
			};
	
			subSectionData.push(subSectionContentData);
	
			// Add additional content if available
			if (result.levelText) subSectionData.push(this.createSubContent(translateVariable, result.levelText, result.label));
			if (result.content1) subSectionData.push(this.createSubContent(translateVariable, result.content1));
			if (result.content2) subSectionData.push(this.createSubContent(translateVariable, result.content2, undefined, true));
			if (result.content3) subSectionData.push(this.createSubContent(translateVariable, result.content3 ));
		});
	
		return subSectionData;
	}
	
	// Helper function to create sub content
	createSubContent(translateVariable: string, content: string, label?: string, indented = false) {
		return {
			style: indented ? "indentedContent" : "subContentDataTitle",
			text: this.parseFormattedString(this.translate.instant(translateVariable + content, { value: label }))
		};
	}
	
	// Helper function to generate resource set
	generateResourceSet(resourceSet: any[]): any[] {
		const resourceSectionData = [];
		
		resourceSet.forEach(result => {
			// Create the resource header (e.g., "Académique") as the first item in the stack
			const subResourceContentData = [
				{
					stack: [
						{ 
							text: result.title, 
							style: "subAnswerTitle", 
							alignment: 'left', 
							margin: [0, 7, 0, 7],  // Adjusted margin for better spacing
							lineHeight: 0.8
						}
					]
				}
			];
		
			// Loop through each resource group and add its title and contact details
			result.resourcesetGroup.forEach(resourceSetGrp => {
				const relevantProperties = [
					{ value: resourceSetGrp.title },
					{ value: resourceSetGrp.description },
					{ value: resourceSetGrp.address },
					{ value: resourceSetGrp.contact },
					{ value: resourceSetGrp.alternateContact },
					{ value: resourceSetGrp.websiteTitle || resourceSetGrp.website }
				];
	
				// Create a new array to hold the resource group details
				const subResourceSetGrp = relevantProperties.reduce((acc, { value }) => {
					if (value) {
						acc.push({
							text: value, // Just use the value directly
							style: "subContentData",
							alignment: "left",
							margin: [10, 2, 0, 0],
							lineHeight: 1
						});
					}
					return acc;
				}, []);
	
				// Push the collected resource group details into the stack
				if (subResourceSetGrp.length > 0) {
					subResourceContentData.push(...subResourceSetGrp);
				}
			});
	
			// Add the fully constructed resource content into the main data array
			resourceSectionData.push(...subResourceContentData);
		});
	
		return resourceSectionData;
	}

	parseFormattedString(input: string): (string | { text: string; fontSize: number; bold?: boolean; decoration?: string })[] {
		const result: (string | { text: string; fontSize: number; bold?: boolean; decoration?: string })[] = [];

		const regex = /(<strong>|<\/strong>|<u>|<\/u>)/g;
		const parts = input.split(regex);

		let isBold = false;
		let isUnderline = false;

		parts.forEach(part => {
			switch (part) {
				case '<strong>':
					isBold = true;
					break;
				case '</strong>':
					isBold = false;
					break;
				case '<u>':
					isUnderline = true;
					break;
				case '</u>':
					isUnderline = false;
					break;
				default:
					if (part) {
						if (isBold || isUnderline) {
							result.push({
								text: part,
								fontSize: 10,
								bold: isBold || undefined,
								decoration: isUnderline ? 'underline' : undefined,
							});
						} else {
							result.push(part);
						}
					}
					break;
			}
		});

		return result;
	}

    filteredResourceSet(resourceSet: any): any[] {
        return resourceSet
            ? resourceSet.filter(
                  resource => resource.language === this.currentLanguage
              ) // Replace 'status' with your actual condition
            : [];
    }

    getLangForResourceList(assessment: any) {
        let selectedLang = this.storage.get('lang');
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang, assessment.resourceSet)
        ) {
            selectedLang = 'en';
        }
        return selectedLang;
    }

    hasResourceByLangage(lang: string, resourceSet: any): boolean {
        return resourceSet.some(r => r['language'] === lang);
    }
    goBack() {
        this.location.back();
    }
}
