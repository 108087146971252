<page-loader *ngIf="!isLoaded"></page-loader>
<div id="page-user-listing" class="page-section client-list-page-section">
    <div class="page-section">
        <div class="app-l-page-wrap">
            <div class="app-l-page__navigation">
                <div class="app-l-page__previous" [routerLink]="['/app']">
                    <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20">
                        <svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
                    </button>
                </div>
                <div class="app-l-page__breadcrumbs">
                    <ul class="breadcrumbs-list">
                        <li translate class="breadcrumbs-list-item">
                            <a [routerLink]="['/app']" translate>menu.dashboard</a>
                        </li>
                        <li class="breadcrumbs-list-item app-l-current-page" translate>
                            menu.myTeam
                        </li>
                    </ul>
                </div>
            </div>
            <div class="app-l-room__wrap" *ngIf="isLoaded">
                <h2 class="app-c-body-text--20-bold client-list-heading" translate>
                    menu.myTeam
                </h2>
                <div class="app-l-client_list">
                    <div class="app-l-list__actions" *ngIf="myTeamList || myTeamList.length > 0">
                        <div class="app-l-list__actions-block">
                            <mat-select
                                class="app-l-dsh__rfp-select"
                                disableOptionCentering
                                [(value)]="selectedType"
                                (selectionChange)="getMyteam()"
                                panelClass="app-l-dsh__rfp-panel">
                                <mat-option value="all" translate
                                    >All</mat-option
                                >
                                <mat-option
                                    *ngFor="let users of userRole"
                                    [value]="users.key"
                                    translate>
                                    {{ users.title }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="app-l-room__tabs client-list-tab-section">
                        <div class="app-dm-positive-cards-wrapper" *ngIf="myTeamList || myTeamList.length > 0">
                            <div class="table-responsive">
                                <table class="table list-custom-table">
                                    <thead class="list-custom-table-header">
                                        <tr>
                                            <th class="list-custom-table-head list-custom-table-head-first" translate>
                                                myTeam.name
                                            </th>
                                            <th class="list-custom-table-head" translate>
                                                myTeam.role
                                            </th>
                                            <th class="list-custom-table-head" translate>
                                                myTeam.email
                                            </th>
                                            <th class="list-custom-table-head list-custom-table-head-last" *ngIf = "user.userType !== 'professional'" translate>
                                                myTeam.action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="list-custom-table-body">
                                        <ng-container *ngFor="let myteam of myTeamList; let i = index">
                                        <tr  *ngIf="(i === 0 || myTeamList[i - 1].UserType !== myteam.UserType || myTeamList[i - 1].Type !== myteam.Type) && selectedType === 'all'">
                                            <td colspan="4" class="role-header">
                                                {{ (getUserRoleTitle(myteam.UserType, myteam.Type) | lowercase) | titlecase }}
                                            </td>
                                        </tr>
                                        <tr [ngClass]="{
                                                        highlighted:
                                                            user.isPaired
                                                    }">
                                            <td class="strong">
                                                {{
                                                    myteam.Name
                                                ? myteam.Name
                                                : ''
                                                }}
                                            </td>
                                            <td>{{ getUserRoleTitle(myteam.UserType, myteam.Type) }}</td>
                                            <td>
                                                {{
                                                    myteam.Email
                                                }}
                                            </td>
                                            <td *ngIf = "user.userType !== 'professional'">
                                                <div class="action-buttons-section">
                                                    <button class="app-c-btn app-c-btn--icon app-c-btn--icon-primary"
                                                        tabindex="0" (click)="
                                                                    deleteInvitation(
                                                                        myteam.ID
                                                                    )
                                                                " (keypress)="
                                                                    deleteInvitation(
                                                                        myteam.ID
                                                                    )
                                                                " translate>
                                                        <svg-icon icon="trash" alt="trash"></svg-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p *ngIf="isLoaded && myTeamList.length === 0" class="center">
                            <span translate>myTeam.noDataFound</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>