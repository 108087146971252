import { Component, HostListener, OnInit } from '@angular/core';
import { MenuService } from '../../lib/menu.service';
import { UserService } from '../../lib/user.service';
import { User } from '../../models/user';
import { PermissionsService } from '../../lib/permissions.service';
import { DynamicFormComponent } from 'app/components/dynamic-form/dynamic-form.component';
import { ApiService } from 'app/lib/api.service';
import { ModalService } from 'app/lib/modal.service';
import { LogService } from 'app/lib/log.service';
import { NpsSurveyModalComponent } from '../../components/nps-survey-modal/nps-survey-modal.component';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { UtilityService } from 'app/lib/utility.service';

@Component({
    selector: 'app-auth-side-menu',
    templateUrl: './auth-side-menu.component.html',
    styleUrls: ['./auth-side-menu.component.scss'],
})
export class AuthSideMenuTemplate implements OnInit {
    user: User;
    showFeedback: boolean = false;
    dynamicData: any;
    showNps: boolean = false;
    userFeedbackSurvey: boolean = false;
    showShare: boolean = false;
    router: string;
    showFeedbackBtn: boolean = false;
    showFooter: boolean = false;
    assignDevDisclaimer: boolean = false;

    constructor(
        private logService: LogService,
        private menuservice: MenuService,
        private userService: UserService,
        private permissionService: PermissionsService,
        private api: ApiService,
        private modalService: ModalService,
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private utilityService: UtilityService
    ) {
        this.user = this.userService.getUser();
        this.menuService.setNavIn(false);
        this.menuService.setUser(this.userService.getUser());
        this.router = _router.url;

        this.userService.watcher.subscribe((user: User) => {
            this.menuservice.user = user;
            this.menuservice.user.isFullAccess ||
            this.menuservice.user.userType === 'admin'
                ? (this.menuservice.locked = false)
                : (this.menuservice.locked = true);
            this.menuservice.initPermissions();
            this.showNps = this.menuservice.getNpsShow();
            this.showFeedback = this.menuservice.getUserFeedbackShow();
            this.showShare = this.menuservice.getShowShare();
        });
        this.showRoute();
        this.menuService.setUserType(this.user.userType);
        this.menuservice.initPermissions();
        this.showNps = this.menuservice.getNpsShow();
        this.showFeedback = this.menuservice.getUserFeedbackShow();
        this.showShare = this.menuservice.getShowShare();
        this.utilityService.assignDevDisclaimerSubject.subscribe(data => {
            this.assignDevDisclaimer = data;
        });
    }

    ngOnInit() {
        this.permissionService.setUser(this.user);
        this.showNps = this.menuservice.getNpsShow();
        this.showFeedback = this.menuservice.getUserFeedbackShow();
    }

    get menuService(): MenuService {
        return this.menuservice;
    }

    showUserSurvey() {
        this._router.navigate([
            '/app/surveys',
            this.user.userFeedbackSurveyId,
            'UserFeedbackSurvey',
        ]);
    }

    showDemographicSurvey() {
        this._router.navigate([
            '/app/surveys',
            this.user.demographicFormId,
            'Demographic',
        ]);
    }

    showNPSSurvey() {
        this._router.navigate([
            '/app/surveys/',
            this.user.npsSurveyId,
            'UserRatingSurvey',
        ]);
    }
    showRoute() {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showFeedbackBtn =
                    this._router.url.includes('app/assessment') ||
                    this._router.url.includes('app/mood-check') ||
                    this._router.url.includes('app/surveys') ||
                    this._router.url.includes(
                        'app/practice/action-plan/create'
                    ) ||
                    this._router.url.includes(
                        'app/practice/action-plan/preview'
                    ) ||
                    this._router.url.includes('app/practice/zen-room') ||
                    this._router.url.includes('app/orgadmin-settings')
                        ? false
                        : true;
                this.showFooter = this._router.url.includes('app/my-settings')
                    ? true
                    : false;
            }
        });
    }

    // userOnboarding() {
    // 	this._router.navigateByUrl('/user-onboarding');
    // }

    @HostListener('document:click', ['$event'])
    clickOutside(event: Event) {
        if (event instanceof MouseEvent) {
            const target = event.target as HTMLElement;
            // const isClickHamburger = target.classList.contains(
            // 	"hamburger-container"
            // );
            // const isClickHamburgerBar1 = target.classList.contains("bar1");
            // const isClickHamburgerBar2 = target.classList.contains("bar2");
            // const isClickHamburgerBar3 = target.classList.contains("bar3");
            // const slidingMenu = target.closest(".sliding-menu");
            // const containsSubmenu = target.closest(".contains_submenu");
            // console.log("test", target.classList);
            // if (
            // 	isClickHamburger ||
            // 	containsSubmenu ||
            // 	isClickHamburgerBar1 ||
            // 	isClickHamburgerBar2 ||
            // 	isClickHamburgerBar3 ||
            // 	slidingMenu
            // ) {
            // 	return;
            // }
            target.classList.contains('overlay-sidemenu') &&
                this.menuService.toggleMenuOutside();
        }
    }

    closeAssignDevDisclaimer() {
        this.assignDevDisclaimer = false;
        this.utilityService.setAssignDevDisclaimerSubject(false);
    }
}
