<!-- <page-loader *ngIf="!isLoaded"></page-loader> -->
<div id="activity-select" class="app-c-modal--secondary">
    <div class="app-c-modal-secondary__header">
        <div class="app-c-modal-secondary__header-left">
            <h3 class="app-c-heading--h3" translate>
                as.select.title
            </h3>
        </div>
        <div class="app-c-modal-secondary__header-right">
            <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14" (click)="close()">
                <svg-icon icon="close" class="w-100 h-100"></svg-icon>
            </button>
        </div>
    </div>


    <div class="app-c-modal-secondary__body">
        <div class="app-l-new-activity" id="custom-option">
            <div class="app-l-na__left">
                <div class="app-c-form-group">
                    <input type="text" class="app-c-form-control" placeholder="{{placeholder}}" [(ngModel)]="customOption" />
                </div>
            </div>
            <div class="app-l-na__right">
                <button (click)="onAddCustomOption()" class="app-c-btn app-c-btn--curve app-c-btn--primary">
                    <svg-icon icon="plus"></svg-icon>
                    <span>Add</span>
                </button>
            </div>

        </div>
        <div class="app-l-activity__added" *ngIf="activities.length">
            <h5 class="app-c-body-text--14-m">Remove custom activity</h5>
            <div class="app-l-ad__row">
                <div class="app-l-ad__item" *ngFor="let activity of activities">
                    <span>{{activity.Value}}</span>
                    <button class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-14" (click)="deleteCustom(activity)">
                        <svg-icon icon="close" class="w-100 h-100"></svg-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>


</div>
