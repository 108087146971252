<div>
	<div [class]="moodClassName" id="moodcheck-wrapper">
		<!-- <img *ngIf="init" id="arrow-left" src="./assets/img/moodcheck-arrow-left.png">
        <img *ngIf="init" id="arrow-right" src="./assets/img/moodcheck-arrow-right.png"> -->
		<svg height="0" width="0">
			<defs>
				<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop
						offset="0%"
						style="stop-color: #f557e6; stop-opacity: 1"
					/>
					<stop
						offset="12.5%"
						style="stop-color: #e99152; stop-opacity: 1"
					/>
					<stop
						offset="25%"
						style="stop-color: #e7d656; stop-opacity: 1"
					/>
					<stop
						offset="37.5%"
						style="stop-color: #aad86a; stop-opacity: 1"
					/>
					<stop
						offset="50%"
						style="stop-color: #8dd6a8; stop-opacity: 1"
					/>
					<stop
						offset="62.5%"
						style="stop-color: #88b9f3; stop-opacity: 1"
					/>
					<stop
						offset="75%"
						style="stop-color: #1485bf; stop-opacity: 1"
					/>
					<stop
						offset="100%"
						style="stop-color: #b36ccd; stop-opacity: 1"
					/>
				</linearGradient>
			</defs>
		</svg>
		<svg height="0" width="0">
			<defs>
				<linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop
						offset="0%"
						style="stop-color: #b36ccd; stop-opacity: 1"
					/>
					<stop
						offset="12.5%"
						style="stop-color: #1485bf; stop-opacity: 1"
					/>
					<stop
						offset="25%"
						style="stop-color: #88b9f3; stop-opacity: 1"
					/>
					<stop
						offset="37.5%"
						style="stop-color: #8dd6a8; stop-opacity: 1"
					/>
					<stop
						offset="50%"
						style="stop-color: #aad86a; stop-opacity: 1"
					/>
					<stop
						offset="62.5%"
						style="stop-color: #e7d656; stop-opacity: 1"
					/>
					<stop
						offset="75%"
						style="stop-color: #e99152; stop-opacity: 1"
					/>
					<stop
						offset="100%"
						style="stop-color: #f557e6; stop-opacity: 1"
					/>
				</linearGradient>
			</defs>
		</svg>
		<div class="moodCheck-header">
			<h3 translate>moodcheck.mood.how</h3>
			<!-- <span translate>moodcheck.mood.instructions</span> -->
		</div>
		<div class="mood-bg">
			<div id="mood-slider"></div>
		</div>
	</div>

	<div id="mood-icon">
		<div class="mood-icon-img">
			<img [src]="moodIconUrl" />
		</div>
		<h4 class="mood-icon-text" translate>moodcheck.mood.instructions</h4>
	</div>
	<br />
	<div id="selected-mood"></div>
	<!-- <p id="moodcheck-instructions" class="center">
        <span id="enter-number" translate>moodcheck.enternumber</span>
    </p> -->
	<!-- <div id="selected-mood">
        <i tabindex="0" class="fa fa-arrow-up" (click)="onGoUp()" (keypress)="onGoUp()"></i>
        <i tabindex="0" class="fa fa-arrow-down" (click)="onGoDown()" (keypress)="onGoDown()"></i>

        <input class="single wt-auto-moodcheck-mood-value" aria-labelledby="enter-number" maxlength="2" tabindex="0"
            [(ngModel)]="actualMood" type="text" (ngModelChange)="checkRange($event)">
        <div class="informational">Mood selected:<span aria-live="polite">{{this.moodLabels}}</span>
        </div>
    </div> -->
	<div class="feelings">
		<a
			tabindex="{{i}}"
			class="tabs"
			*ngFor="let option of options;let i = index"
			(click)="checkRange(option.key)"
			(keypress)="checkRange(option.key)"
			role="radio"
			aria-labelledby="{{option.name}}"
		>
			<div class="mood-check">
				<label
					[for]="option.ID"
					class="box"
					[ngClass]="{'active':  option.name === this.moodLabels}"
				>
					<div class="plan">
						<span>{{option.name | truncate: 30}}</span>
					</div>
				</label>
			</div>
		</a>
		<!-- <a class="single wt-auto-moodcheck-mood-option" tabindex="0" *ngFor="let option of options"
            [ngClass]="{'active':  option.name === this.moodLabels}" (click)="checkRange(option.key)"
            (keypress)="checkRange(option.key)"><span>{{option.name  | truncate: 30}}</span></a> -->
		<!--<a href="" class="active">Happy</a>-->
		<!-- <a tabindex="0" (click)="onMore()" (keypress)="onMore()"
            class="single wt-auto-moodcheck-mood-more more featured"><span translate>moodcheck.more</span></a> -->
	</div>
</div>
