<div class="app-l-page-wrap">
    <div class="app-l-container">
        <div class="settings-main">
            <div class="user-profile-heading">
                <h3 class="app-c-heading--h4" translate>setting.myProfile</h3>
            </div>
            <mat-tab-group
                #tabGroup
                disableRipple="true"
                (selectedTabChange)="onTabChanged($event)"
                (keydown.arrowRight)="selectNextTab()"
                (keydown.arrowLeft)="selectPreviousTab()">
                <!-- Tab 1: Basic Details -->
                <mat-tab label="{{ 'setting.basicDetails' | translate }}">
                    <div class="profile col-md-4 col-sm-12">
                        <div class="profile-upload-container">
                            <div class="profile-upload-section">
                                <div class="default-profile-image">
                                    <h2 class="default-text">
                                        {{ profileLogoName }}
                                    </h2>
                                </div>
                                <!-- uncomment it for image upload section -->
                                <!-- <div class="uploaded-image-section">
                  <img src="/assets/img/profile-logo.svg" />
                </div> -->
                                <button
                                    class="edit-button"
                                    aria-label="profile-edit-icon">
                                    <svg-icon
                                        icon="profile-edit-icon"
                                        alt="profile-edit-icon"></svg-icon>
                                </button>
                            </div>
                        </div>
                        <button
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve signout-button"
                            (click)="menuService.onLogout()"
                            (keypress)="menuService.onLogout()"
                            translate
                            aria-label="signout-icon">
                            <svg-icon
                                icon="signout-icon"
                                alt="signout-icon"></svg-icon>
                            menu.signout
                        </button>
                    </div>
                    <div class="profile-details col-md-8 col-sm-12">
                        <form
                            *ngIf="!showEditPassword && !profileEditView"
                            class="form-section">
                            <div class="form-input-section">
                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.name
                                    </div>
                                    <div class="input-section">
                                        <input
                                            matInput
                                            class="form-input disabled"
                                            aria-label="Full Name"
                                            tabindex="0"
                                            id="fullname"
                                            type="text"
                                            name="fullName"
                                            [(ngModel)]="fullName"
                                            readonly />
                                        <button
                                            class="edit-button"
                                            (click)="editProfile()"
                                            (keypress)="editProfile()">
                                            <svg-icon
                                                icon="profile-edit-icon"
                                                alt="profile-edit-icon"></svg-icon>
                                        </button>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="form-input-section">
                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.email
                                    </div>
                                    <div class="input-section">
                                        <svg-icon
                                            icon="email-icon"
                                            class="input-icon"
                                            alt="email-icon"></svg-icon>
                                        <input
                                            matInput
                                            class="form-input input-with-icon"
                                            aria-label="Email"
                                            tabindex="0"
                                            id="email"
                                            type="text"
                                            name="email"
                                            [(ngModel)]="emailChange"
                                            readonly />
                                    </div>
                                </mat-form-field>
                            </div>
                            <div
                                class="form-input-section"
                                *ngIf="!disablePassword"
                                role="button"
                                tabindex="0"
                                (keypress)="forgetpassword()">
                                <div
                                    (click)="forgetpassword()"
                                    aria-label="forgetpassword"
                                    class="change-password-link"
                                    translate>
                                    setting.changePassword
                                </div>
                            </div>
                        </form>

                        <form
                            *ngIf="!showEditPassword && profileEditView"
                            class="form-section">
                            <div class="form-input-section">
                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.name
                                    </div>
                                    <div class="input-section">
                                        <input
                                            matInput
                                            class="form-input disabled"
                                            aria-label="Full Name"
                                            tabindex="0"
                                            id="fullname"
                                            type="text"
                                            name="fullName"
                                            [(ngModel)]="fullName" />
                                        <button
                                            class="edit-button"
                                            aria-label="profile-edit">
                                            <svg-icon
                                                icon="profile-edit-icon"
                                                alt="profile-edit-icon"></svg-icon>
                                        </button>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div class="form-input-section">
                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.email
                                    </div>
                                    <div class="input-section">
                                        <svg-icon
                                            icon="email-icon"
                                            alt="email-icon"
                                            class="input-icon"></svg-icon>
                                        <input
                                            matInput
                                            class="form-input input-with-icon"
                                            aria-label="Email"
                                            tabindex="0"
                                            id="email"
                                            type="text"
                                            name="email"
                                            [(ngModel)]="emailChange" />
                                    </div>
                                </mat-form-field>
                            </div>
                            <div
                                class="form-input-section"
                                *ngIf="!disablePassword"
                                role="button"
                                tabindex="0"
                                (keypress)="forgetpassword()">
                                <div
                                    (click)="forgetpassword()"
                                    aria-label="changePassword"
                                    class="change-password-link"
                                    translate>
                                    setting.changePassword
                                </div>
                            </div>

                            <div class="page-section-buttons">
                                <button
                                    aria-label="Cancel"
                                    autoFocus
                                    tabindex="0"
                                    (click)="onCancel()"
                                    (keypress)="onCancel()"
                                    class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                    translate>
                                    setting.cancel
                                </button>
                                <button
                                    aria-label="save"
                                    autoFocus
                                    tabindex="0"
                                    (click)="onSave()"
                                    (keypress)="onSave()"
                                    [ngClass]="{
                                        'disable-section': !(
                                            fullName != user?.fullName ||
                                            emailChange != user?.email
                                        )
                                    }"
                                    class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                    translate>
                                    setting.save
                                </button>
                            </div>
                        </form>

                        <div *ngIf="showEditPassword">
                            <form class="form-section">
                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.currentPassword
                                    </div>
                                    <div class="input-section">
                                        <svg-icon
                                            icon="lock-icon"
                                            alt="lock-icon"
                                            class="input-icon"></svg-icon>
                                        <input
                                            matInput
                                            class="form-input input-with-icon"
                                            aria-label="Password"
                                            tabindex="0"
                                            id="currentpassword"
                                            name="currentpassword"
                                            [(ngModel)]="currentpassword"
                                            name="currentpassword"
                                            [type]="
                                                passwordFieldType[
                                                    'currentPasswordType'
                                                ]
                                                    ? 'text'
                                                    : 'password'
                                            " />
                                        <i
                                            tabindex="0"
                                            [attr.aria-label]="
                                                passwordFieldType[
                                                    'currentPasswordType'
                                                ]
                                                    ? 'Show password'
                                                    : 'Hide password'
                                            "
                                            class="edit-button"
                                            (click)="
                                                onClickPassword(
                                                    'currentPasswordType'
                                                )
                                            "
                                            (keydown.enter)="
                                                onClickPassword(
                                                    'currentPasswordType'
                                                )
                                            "
                                            style="cursor: pointer"
                                            [ngClass]="
                                                passwordFieldType[
                                                    'currentPasswordType'
                                                ]
                                                    ? 'fa fa-eye'
                                                    : 'fa fa-eye-slash'
                                            "></i>
                                    </div>
                                </mat-form-field>

                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.newPassword
                                    </div>
                                    <div class="input-section">
                                        <svg-icon
                                            icon="lock-icon"
                                            alt="lock-icon"
                                            class="input-icon"></svg-icon>
                                        <input
                                            matInput
                                            class="form-input input-with-icon"
                                            aria-label="Password"
                                            tabindex="0"
                                            id="password"
                                            (keyup)="onChangePassword()"
                                            [type]="
                                                passwordFieldType[
                                                    'passwordType'
                                                ]
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            name="password"
                                            [(ngModel)]="password"
                                            name="password" />
                                        <i
                                            tabindex="0"
                                            [attr.aria-label]="
                                                passwordFieldType[
                                                    'passwordType'
                                                ]
                                                    ? 'Show password'
                                                    : 'Hide password'
                                            "
                                            class="edit-button"
                                            (click)="
                                                onClickPassword('passwordType')
                                            "
                                            (keydown.enter)="
                                                onClickPassword('passwordType')
                                            "
                                            style="cursor: pointer"
                                            [ngClass]="
                                                passwordFieldType[
                                                    'passwordType'
                                                ]
                                                    ? 'fa fa-eye'
                                                    : 'fa fa-eye-slash'
                                            "></i>
                                    </div>
                                    <div class="input-link-button">
                                        <!-- <button class="change-password-link">Generate Password</button> -->
                                    </div>
                                </mat-form-field>

                                <mat-form-field class="form-input-container">
                                    <div class="form-label" translate>
                                        setting.repeatPassword
                                    </div>
                                    <div class="input-section">
                                        <svg-icon
                                            icon="lock-icon"
                                            alt="lock-icon"
                                            class="input-icon"></svg-icon>
                                        <input
                                            matInput
                                            class="form-input input-with-icon"
                                            aria-label="Password repeat"
                                            tabindex="0"
                                            id="repeatpassword"
                                            [type]="
                                                passwordFieldType[
                                                    'passwordConfirmationType'
                                                ]
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            name="password"
                                            [(ngModel)]="passwordConfirmation"
                                            name="passwordConfirmation" />
                                        <i
                                            tabindex="0"
                                            [attr.aria-label]="
                                                passwordFieldType[
                                                    'passwordConfirmationType'
                                                ]
                                                    ? 'Show password'
                                                    : 'Hide password'
                                            "
                                            class="edit-button"
                                            (click)="
                                                onClickPassword(
                                                    'passwordConfirmationType'
                                                )
                                            "
                                            (keydown.enter)="
                                                onClickPassword(
                                                    'passwordConfirmationType'
                                                )
                                            "
                                            style="cursor: pointer"
                                            [ngClass]="
                                                passwordFieldType[
                                                    'passwordConfirmationType'
                                                ]
                                                    ? 'fa fa-eye'
                                                    : 'fa fa-eye-slash'
                                            "></i>
                                    </div>
                                    <div class="input-link-button">
                                        <!-- <button class="change-password-link">Copy Password</button> -->
                                    </div>
                                </mat-form-field>
                                <div class="requirements-container">
                                    <h2 class="app-c-body-text--16-m" translate>
                                        setting.passwordRequirements.title
                                    </h2>
                                    <div class="requirements-contents">
                                        <ul
                                            *ngIf="
                                                !disablePassword &&
                                                showEditPassword
                                            "
                                            class="input-conditions">
                                            <li
                                                [ngClass]="
                                                    passwordValidations.lowerLetters &&
                                                    passwordValidations.upperLetters
                                                        ? 'correct'
                                                        : 'wrong'
                                                "
                                                translate>
                                                <ion-icon
                                                    src="../../../../assets/img/icons-svg/green-tick.svg"
                                                    class="condition-icon"></ion-icon>
                                                setting.passwordRequirements.caseCheck
                                            </li>
                                            <li
                                                [ngClass]="
                                                    passwordValidations.numbers
                                                        ? 'correct'
                                                        : 'wrong'
                                                "
                                                translate>
                                                <ion-icon
                                                    src="../../../../assets/img/icons-svg/green-tick.svg"
                                                    class="condition-icon"></ion-icon>
                                                setting.passwordRequirements.digit
                                            </li>
                                            <li
                                                [ngClass]="
                                                    passwordValidations.symbols
                                                        ? 'correct'
                                                        : 'wrong'
                                                "
                                                translate>
                                                <ion-icon
                                                    src="../../../../assets/img/icons-svg/green-tick.svg"
                                                    class="condition-icon"></ion-icon>
                                                setting.passwordRequirements.specialChar
                                            </li>
                                            <li
                                                [ngClass]="
                                                    passwordValidations.pwdlength
                                                        ? 'correct'
                                                        : 'wrong'
                                                "
                                                translate>
                                                <ion-icon
                                                    src="../../../../assets/img/icons-svg/green-tick.svg"
                                                    class="condition-icon"></ion-icon>
                                                setting.passwordRequirements.length
                                            </li>
                                            <li
                                                [ngClass]="
                                                    passwordValidations.notIncludeWelltrack
                                                        ? 'correct'
                                                        : 'wrong'
                                                "
                                                translate>
                                                <ion-icon
                                                    src="../../../../assets/img/icons-svg/green-tick.svg"
                                                    class="condition-icon"></ion-icon>
                                                setting.passwordRequirements.notWelltrack
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="page-section-buttons">
                                    <button
                                        aria-label="cancel"
                                        autoFocus
                                        tabindex="0"
                                        (click)="onCancel()"
                                        (keypress)="onCancel()"
                                        class="app-c-btn app-c-btn--secondary app-c-btn--curve"
                                        translate>
                                        setting.cancel
                                    </button>
                                    <button
                                        aria-label="save"
                                        autoFocus
                                        tabindex="0"
                                        (click)="onSave()"
                                        (keypress)="onSave()"
                                        [ngClass]="{
                                            'disable-section':
                                                showEditPassword &&
                                                !isPasswordValid
                                        }"
                                        class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                        translate>
                                        setting.save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </mat-tab>

                <!-- Tab 2: Additional Details -->
                <mat-tab label="{{ 'setting.additionalDetails' | translate }}">
                    <div class="profile col-md-4 col-sm-12">
                        <div class="profile-upload-container">
                            <div class="profile-upload-section">
                                <div class="default-profile-image">
                                    <h2 class="default-text">
                                        {{ profileLogoName }}
                                    </h2>
                                </div>
                                <!-- uncomment it for image upload section -->
                                <!-- <div class="uploaded-image-section">
                              <img src="/assets/img/profile-logo.svg" />
                            </div> -->
                                <button
                                    class="edit-button"
                                    aria-label="profile edit button">
                                    <svg-icon
                                        icon="profile-edit-icon"
                                        alt="profile-edit-icon"></svg-icon>
                                </button>
                            </div>
                        </div>
                        <button
                            aria-label="Logout"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve signout-button"
                            (click)="menuService.onLogout()"
                            (keypress)="menuService.onLogout()"
                            translate>
                            <svg-icon
                                icon="signout-icon"
                                alt="signout-icon"></svg-icon>
                            menu.signout
                        </button>
                    </div>
                    <div
                        class="profile-details col-md-8 col-sm-12"
                        *ngIf="user.userType === 'user'">
                        <demographic-edit
                            *ngIf="
                                user.userType === 'user' && !surveyEditMode
                            "></demographic-edit>
                        <div
                            class="myinfo-Additional-details"
                            *ngIf="surveyEditMode">
                            <p translate>
                                setting.additionalDetailsContent.0
                                <br /><br />
                            </p>
                            <p translate>setting.additionalDetailsContent.1</p>
                        </div>
                        <div
                            class="additional-details-footer-button"
                            *ngIf="!surveyEditMode">
                            <button
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve edit-btn"
                                (click)="editSurvey()"
                                aria-label="survey edit"
                                (keypress)="editSurvey()">
                                Edit
                                <svg-icon
                                    icon="profile-edit-icon"
                                    alt="profile-edit-icon"></svg-icon>
                            </button>
                        </div>
                        <div
                            class="additional-details-footer-button survey"
                            *ngIf="surveyEditMode">
                            <button
                                aria-label="survey edit confirm"
                                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                *ngIf="surveyEditMode"
                                [routerLink]="[
                                    '/app/surveys',
                                    user.demographicFormId,
                                    'Demographic'
                                ]"
                                [queryParams]="{ path: 'app/my-settings' }"
                                translate>
                                setting.takeSurvey
                            </button>
                        </div>
                    </div>
                </mat-tab>

                <!-- Tab 3: Organizational Details -->
                <mat-tab label="{{ 'setting.orgDetails' | translate }}">
                    <div class="profile col-md-4 col-sm-12">
                        <div class="profile-upload-container">
                            <div class="profile-upload-section">
                                <div class="default-profile-image">
                                    <h2 class="default-text">
                                        {{ profileLogoName }}
                                    </h2>
                                </div>
                                <!-- uncomment it for image upload section -->
                                <!-- <div class="uploaded-image-section">
                                            <img src="/assets/img/profile-logo.svg" />
                                          </div> -->
                                <button
                                    class="edit-button"
                                    aria-label="profile edit">
                                    <svg-icon
                                        icon="profile-edit-icon"
                                        alt="profile-edit-icon"></svg-icon>
                                </button>
                            </div>
                        </div>
                        <button
                            aria-label="logout"
                            class="app-c-btn app-c-btn--secondary app-c-btn--curve signout-button"
                            (click)="menuService.onLogout()"
                            (keypress)="menuService.onLogout()"
                            translate>
                            <svg-icon
                                icon="signout-icon"
                                alt="profile-edit-icon"></svg-icon
                            >menu.signout
                        </button>
                    </div>
                    <div class="profile-details col-md-8 col-sm-12">
                        <div class="org-details">
                            <ng-container *ngIf="!showOrgList && !showAssocBtn">
                                <div
                                    class="organization-details-first-main-container">
                                    <p
                                        class="organization-details-first-label"
                                        [translate]="'setting.paired'"></p>
                                    <div
                                        class="organization-details-first-output">
                                        {{ user?.primaryOrganization?.name }}
                                        <!-- <button class="edit-organization-button" (click)="changeAssociation()"><svg-icon
                        icon="profile-edit-icon"></svg-icon></button> -->
                                    </div>
                                    <div
                                        class="change-association-button"
                                        *ngIf="showInitialBtn">
                                        <h2
                                            class="change-password-link"
                                            (click)="changeAssociation()"
                                            tabindex="0"
                                            (keypress)="changeAssociation()"
                                            translate>
                                            setting.changeAssociation
                                        </h2>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="showAssocBtn">
                                <div
                                    class="organization-details-first-main-container">
                                    <div
                                        class="change-association-button"
                                        *ngIf="!showAssocBtn">
                                        <h2
                                            class="change-password-link"
                                            (click)="changeAssociation()"
                                            tabindex="0"
                                            (keypress)="changeAssociation()"
                                            translate>
                                            setting.changeAssociation
                                        </h2>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    showOrgList &&
                                    !showAssocBtn &&
                                    organizations
                                ">
                                <div
                                    class="organization-details-first-main-container">
                                    <p
                                        class="organization-details-first-label"
                                        [translate]="'setting.paired'"></p>
                                    <mat-form-field
                                        class="select-field-main-container"
                                        tabindex="0">
                                        <mat-select
                                            [(ngModel)]="orgId"
                                            (selectionChange)="
                                                onChangeOrg($event.value)
                                            ">
                                            <mat-option
                                                *ngFor="
                                                    let organization of organizations
                                                "
                                                [value]="organization.id"
                                                class="options-container">
                                                {{ organization.name }}
                                            </mat-option>
                                            <mat-option
                                                [value]="'disconnect'"
                                                class="options-container"
                                                translate
                                                >{{
                                                    'setting.disconnect'
                                                        | translate
                                                }}</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>

                            <div
                                class="notification-wrap"
                                *ngIf="
                                    enableConsent &&
                                    !showAssocBtn &&
                                    user.userType === 'user'
                                "
                                [ngClass]="{ 'disable-section': showOrgList }">
                                <label
                                    class="checkbox-container app-c-body-text--14"
                                    translate
                                    >setting.consent
                                    <input
                                        tabindex="0"
                                        aria-label="Consent to share mental health alerts"
                                        type="checkbox"
                                        name="user-consent"
                                        id="user-consent"
                                        [(ngModel)]="consent"
                                        (ngModelChange)="
                                            onToggleReminders(
                                                'consent',
                                                consent
                                            )
                                        " />
                                    <span
                                        class="checkmark"
                                        tabindex="0"
                                        (keydown.enter)="
                                            onToggleReminders(
                                                'consent',
                                                consent
                                            )
                                        "></span>
                                </label>
                            </div>
                            <div class="associate-button-container">
                                <button
                                    tabindex="0"
                                    aria-label="association modal button"
                                    class="app-c-btn app-c-btn--primary app-c-btn--curve associate-button"
                                    *ngIf="showAssocBtn"
                                    (click)="showAssoModal()"
                                    (keypress)="showAssoModal()"
                                    translate>
                                    blocked.noticeline1
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
