import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InviteProfessional } from 'app/components/invite-professional/invite-professional.component';
import { InviteProfessionalsComponent } from 'app/components/invite-professionals/invite-professionals.component';
import { Invite } from 'app/components/invite/invite';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';
import * as _ from 'lodash';

@Component({
    selector: 'app-invite-professional-listing',
    templateUrl: './invite-professional-listing.component.html',
    styleUrls: ['./invite-professional-listing.component.scss'],
})
export class InviteProfessionalListingComponent implements OnInit {
    user: User;
    organization: Organization;
    invites = [];
    isLoaded: boolean = false;
    questionIndex: number = 0;
    constructor(
        private modalService: ModalService,
        private api: ApiService,
        private userService: UserService,
        private translate: TranslateService,
        private log: LogService,
        private storage: StorageService
    ) {
        this.user = this.userService.getUser();
        let orgId;
        // if (this.storage.get("orgselect")) {
        // 	orgId = this.storage.get("orgselect");
        // } else {
        // 	this.user.primaryOrganization;
        orgId = this.user.primaryOrganization.id;
        // }

        if (orgId) {
            this.api.get('organizations/' + orgId).subscribe(
                (result: any) => {
                    this.organization = new Organization(result.data);
                    this.getInvitations();
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organizations. ' + error.message
                    );
                },
                () => {}
            );
        } else {
            this.organization = this.user.primaryOrganization;
            this.getInvitations();
        }
    }

    ngOnInit(): void {}

    invite() {
        let inviteInfo = {
            type: 'professional',
            endpoint: 'userinvite',
            forceShare: false,
            orgId: this.user.primaryOrganization.id,
            key: 'professionalInvite',
        };
        this.modalService
            .showComponent(InviteProfessional, inviteInfo, 'app-c-modal--cure')
            .afterClosed()
            .subscribe(result => {
                if (result.status === 200) {
                    this.getInvitations();
                    this.modalService.showAlert('Success', result.message);
                }
            });
    }
    getInvitations() {
        this.api.get(`allinvites`).subscribe(
            (results: any) => {
                this.invites = results.data;
                this.log.debug(this.invites);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error loading. ' + error.message);
                this.isLoaded = true;
            }
        );
    }
    onTabChanged(event) {
        this.questionIndex = event.index;
    }
    resendInvitation(id, role) {
        this.modalService
            .showConfirmation(
                'Send Reminder',
                'Do you want to send reminder to this user?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api
                        .post('invitations/resend', {
                            ID: id,
                            Endpoint: role,
                        })
                        .subscribe(
                            (result: any) => {
                                let index = _.findIndex(this.invites, {
                                    ID: id,
                                });
                                this.invites[index].ReminderCount++;
                                this.modalService.showAlert(
                                    'Success',
                                    result.message
                                );
                            },
                            (error: any) => {
                                this.modalService.showAlert(
                                    'Error',
                                    error.message
                                );
                                this.log.error(
                                    'Error sending invite. ' + error.message
                                );
                            }
                        );
                }
            });
    }

    deleteInvitation(id) {
        this.modalService
            .showConfirmation(
                'Delete Invitation',
                'Do you want to delete this invitation?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('invitations/' + id).subscribe(
                        (result: any) => {
                            let index = _.findIndex(this.invites, { ID: id });
                            this.invites.splice(index, 1);
                            this.modalService.showAlert(
                                'Success',
                                result.message
                            );
                        },
                        (error: any) => {
                            this.modalService.showAlert('Error', error.message);
                            this.log.error(
                                'Error sending invite. ' + error.message
                            );
                        }
                    );
                }
            });
    }
}
