import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ApiService } from '../../lib/api.service';
import { LogService } from '../../lib/log.service';
import { VideoService } from '../../lib/video.service';
import { TranslateService } from '@ngx-translate/core';
import { Video } from 'app/models/course';

@Component({
    selector: 'wt-video',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
    @ViewChild('videoPlayer') videoPlayer: ElementRef;
    @ViewChild('media') media: ElementRef;
    api: VgApiService;
    showControls = false;
    hasCompleted = false;
    lang: string = 'en';
    video: Object;
    timer: any;
    isPlaying: boolean = false;

    @Input() videoId: number;
    @Input() mediaUrl: string;
    @Input() mediaType = 'video/mp4';
    @Input() thumbnailUrl: string;
    @Input() videoTitle: string;
    @Input() captionUrl: string;
    @Input() callbackUrl = '';
    @Input() callbackArgs: Object = {};
    @Input() currentTime: any = {};
    @Input() disableControls: boolean = false;
    @Input() autoplay: boolean = false;

    @Output() onPlay = new EventEmitter<any>();
    @Output() onPause = new EventEmitter<any>();
    @Output() onCompleted = new EventEmitter<any>();
    @Output() onEnded = new EventEmitter<any>();
    @Output() progressUpdate = new EventEmitter<any>();

    constructor(
        private log: LogService,
        private apiservice: ApiService,
        private translate: TranslateService,
        private videoService: VideoService
    ) {
        this.lang = this.translate.currentLang;
        this.videoService.setWatcher.subscribe(video => {
            if (video['id'] === this.videoId) {
                this.play();
            }
        });

        this.video = {
            id: this.videoId,
            mediaUrl: this.mediaUrl,
            mediaType: this.mediaType,
            thumbnailUrl: this.thumbnailUrl,
            captionUrl: this.captionUrl,
        };
    }

    onPlayerReady(api: VgApiService) {
        this.api = api;
        this.api.currentTime = +this.currentTime / 1000;
        this.autoplay && this.api.play();

        this.api.getDefaultMedia().subscriptions.play.subscribe(() => {
            this.log.debug('Playing started.');
            this.isPlaying = true;
            this.timer && clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.updateCurrentTimeProgress();
            }, 8000);
        });
        this.api.getDefaultMedia().subscriptions.playing.subscribe(() => {
            this.log.debug('Playing started.');
            this.showControls = true;
            this.onPlay.emit(this.video);
        });
        this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(eeee => {
            this.onPlay.emit(this.video);
        });

        this.api.getDefaultMedia().subscriptions.pause.subscribe(() => {
            this.log.debug('Playing pause.');
            //this.showControls = false;
            this.isPlaying = false;
            clearInterval(this.timer);
            this.onPause.emit(this.video);
        });

        this.api.getDefaultMedia().subscriptions.ended.subscribe(() => {
            if (this.api.fsAPI.isFullscreen && this.api.fsAPI.isAvailable) {
                this.api.fsAPI.toggleFullscreen();
            }
            this.log.debug('Playing end.');
            clearInterval(this.timer);
            this.isPlaying = false;
            this.showControls = true;
            this.onEnded.emit(this.video);
        });
        this.api.getDefaultMedia().subscriptions.timeUpdate.subscribe(() => {
            // this.log.debug('Progress called.');
            // if (this.hasCompleted) {
            // 	return;
            // }
            // let time = this.api.time;
            //Trigger completion if 10% is played
            // if (time.current / time.total > 0.10) {
            // 	if (this.callbackUrl !== '' && this.hasCompleted == false) {
            // 		this.apiservice.post(this.callbackUrl, this.callbackArgs).subscribe(
            // 			(result: any) => {
            // 				this.log.debug('Course progress saved successfully.');
            // 				//this.log.debug(this.diaries);
            // 			},
            // 			(error: any) => {
            // 				this.log.error('Error issuing callback. ');
            // 			}
            // 		);
            // 	}
            // 	this.hasCompleted = true;
            // 	this.onCompleted.emit(this.hasCompleted);
            // }
        });
    }

    play() {
        this.api.play();
    }

    ngOnInit() {
        this.lang = this.translate.currentLang;
        this.videoService.setWatcher.subscribe((video: any) => {
            if (video['id'] === this.videoId) {
                this.play();
            }
        });

        this.video = {
            id: this.videoId,
            mediaUrl: this.mediaUrl,
            mediaType: this.mediaType,
            thumbnailUrl: this.thumbnailUrl,
            captionUrl: this.captionUrl,
            videoTitle: this.videoTitle,
        };
    }

    changeVideo(video: Video) {
        const time = video.courseProgress?.TimeCompleted || '0';
        this.api.pause();
        this.videoPlayer.nativeElement.load();
        this.api.currentTime = +time / 1000;
    }

    onVideoPlay() {}

    onVideoPause() {}

    updateCurrentTimeProgress() {
        this.isPlaying && this.progressUpdate.emit(this.api.time);
    }
}
