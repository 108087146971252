import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corporate-policy',
  templateUrl: './corporate-policy.component.html'
})
export class CorporatePolicyPage implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}