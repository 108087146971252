<div class="app-l-page-wrap">
    <page-loader *ngIf="!isLoaded"></page-loader>
    <div id="courses-listing-page">
        <div class="app-l-page__navigation" *ngIf="isLoaded">
            <div class="app-l-page__previous">
                <button class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"  aria-label="Previous page"
                    (click)="goBack()" (keypress)="goBack()">
                    <svg-icon icon="arrow-left" class="w-100 h-100" alt="go back"></svg-icon>
                </button>
            </div>
            <div class="app-l-page__breadcrumbs">
                <ul>
                    <li translate (click)="gotoCourses()" (keypress)="gotoCourses()">breadcrumb.courses</li>
                    <li class="app-l-current-page">{{title}}</li>
                </ul>
            </div>
        </div>
        <!-- <page-header class="d-n" [headerText]="title" [backLink]="backLink" [cssClass]='cssModule'
            [backText]="backText"></page-header> -->

        <div class="" *ngIf="isLoaded">
            <div class="app-l-page-header-block">
                <div class="app-l-page-title-block">
                    <h2 id="section-title" class="app-l-page-title" translate>{{title}}</h2>
                    <span class="app-l-course__total">(<span
                            translate>theory.total<span>{{courseVideos.length}}</span></span>)</span>
                </div>
            </div>
            <page-loader *ngIf="!isLoaded"></page-loader>
            <div class="app-l-course__q-base">
                <div class="app-l-course__q-list">
                    <div class="app-l-course__q-block" *ngFor="let video of courseVideos">
                        <div class="app-lcourse__q-image" (click)="gotoVideo(video.id, 1)" (keypress)="gotoVideo(video.id, 1)">
                            <img [defaultImage]="'./assets/img/course_image_default.svg'" alt="video.label"
                                [lazyLoad]="video.image">
                            <button class="app-l-course__play" (click)="gotoVideo(video.id, 1)" (keypress)="gotoVideo(video.id, 1)"  aria-label="play">
                                <svg-icon icon="play" class="w-100 h-100" alt="play"></svg-icon>
                            </button>
                            <div translate class="app-l-course__video-status">
                                <div *ngIf="video.courseProgress.Status === 'inprogress'"
                                    class="app-l-course__badge app-l-course__badge-inprogress">
                                    <svg-icon icon="timer" class="app-l-course__badge-icon" alt="timer"></svg-icon>
                                    <span class="app-l-course__badge-text" translate>theory.inProgress</span>
                                </div>
                                <div *ngIf="video.courseProgress.Status === 'completed'"
                                    class="app-l-course__badge app-l-course__badge-completed">
                                    <svg-icon icon="tick-circle" class="app-l-course__badge-icon" alt="completed"></svg-icon>
                                    <span class="app-l-course__badge-text" translate>theory.completed</span>
                                </div>
                            </div>
                            <div class="app-l-course__video-length">
                                <span translate>theory.length
                                    <span class="app-l-length__duration">{{video.length}}</span>
                                </span>
                            </div>
                        </div>
                        <div class="app-l-course__q-body" (click)="gotoVideo(video.id, 0)" (keypress)="gotoVideo(video.id, 0)">
                            <h3>{{video.label}}</h3>
                            <!-- <a class="rate-video" (click)="onRateItem()"><i class="fa fa-star" aria-hidden="true"></i> Rate
                                video.</a> -->
                            <div class="app-l-course__q-description" aria-live="polite" tabindex="0"
                                *ngIf="video.description" [innerHtml]="video.description"></div>
                        </div>
                        <div class="app-l-course__q-footer" (click)="gotoVideo(video.id, 0)" (keypress)="gotoVideo(video.id, 0)">
                            <div class="app-l-course__f-left">
                                <button aria-label="viewMore"
                                    class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                    <span translate>theory.viewMore</span>
                                    <svg-icon icon="angle-right" alt="viewMore"></svg-icon>
                                </button>
                            </div>
                            <div class="app-l-course__f-right">
                                <button translate *ngIf="video.links" aria-label="book"
                                    class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-16">
                                    <svg-icon icon="book" class="w-100 h-100" alt="book"></svg-icon>
                                </button>
                                <button translate *ngIf="video.attachments" aria-label="download"
                                    class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-16">
                                    <svg-icon icon="download" class="w-100 h-100" alt="download"></svg-icon>
                                </button>
                                <button translate *ngIf="video.moodcheckLink" aria-label="emoji"
                                    class="app-c-btn app-c-btn--icon app-c-btn--icon-primary app-c-btn--icon-16">
                                    <svg-icon icon="emoji" class="w-100 h-100" alt="emoji"></svg-icon>
                                </button>
                            </div>
                        </div>
                        <div class="card-footer row" *ngIf="showConnect()">
                            <span class=" link" aria-label="connect" translate>
                                <hr />
                                theory.courses.welltrackConnect<br>
                                <a class="btn btn-primary btn-inline" aria-label="connect" href="https://welltrack-connect.com/"
                                    target="_blank" translate>theory.welltrackConnectHeading</a>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="talk-about card-container" *ngIf="ssoLink && talkAboutLink">
                    <div class="card">
                        <img class="together-all-logo" alt="Logo"
                            src="./assets/img/additional-resources/togetherall.svg">
                        <div>
                            <h2>Discussion Forum on {{moduleTitle}}</h2>
                            <div class="talk-about-link" [innerHtml]="talkAboutDescription"></div>
                            <a class="btn btn-primary btn-inline" aria-label="description" (click)="onTalkAboutLink(talkAboutLink)" (keypress)="onTalkAboutLink(talkAboutLink)"
                                translate>theory.gotoDiscussion</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>