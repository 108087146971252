<page-loader *ngIf="!isLoaded"></page-loader>
<div class="app-l-page-wrap" *ngIf="isLoaded">
	<div class="app-l-page__navigation" tabindex="0" aria-label="Breadcrumb">
		<div class="app-l-page__previous">
			<button
				tabindex="0"
				aria-label="Back button"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				(keypress)="goBack()"
				(click)="goBack()"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li translate>Home</li>
			</ul>
		</div>
	</div>
	<div id="moodcheck-modal" class="moodcheck-modal-section">
		<div class="app-l-mood-check--wrap">
			<h3 class="app-l-mood-check__title">Thought Diary</h3>
			<div class="app-l-mood-check--tab tab-content-main">
				<div class="tab-content">
					<div *ngFor="let num of steps" class="tab-item">
						<a
							class="tab-btn"
							[ngClass]="{
								'active-hidden':
									step > num &&
									(stepsCompleted[num - 1] ||
										this.diary.thought.event),
								active: step === num
							}"
							><span>{{ num }}</span>
							<svg-icon icon="tick"></svg-icon>
						</a>
						<span class="tab-btn-label"> {{ "Step " + num }}</span>
					</div>
				</div>
				<div *ngIf="isLoaded" class="app-l-mood-check__tab-body">
					<div
						*ngIf="step == 1"
						class="main-page-section"
						id="step-1"
					>
						<div class="thought-diary-header" tabindex="0">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.0.title
							</h3>
							<!-- <a mat-raised-button
                                matTooltip="Paul was assigned a new task and was assured it was easy, but he found he needed to ask for help.
                                This made him feel stupid and embarrassed. He was thinking
                                If Paula didn't have any trouble with this, but I can't understand it, it must be because I'm so stupid.
                                When people find out that I needed help with an easy task, I will lose their respect.
                                When Eric had to tell his parents that he hadn't yet found a job, he felt hopeless and like he wanted to
                                run away. He was thinking
                                If I haven't found a job yet, I don't think I'll ever find one
                                I can't bear to tell them and see how they're disappointed, I'd rather just not talk to them at all!"
                                aria-label="Button that displays a tooltip when focused or hovered over">
                                <svg-icon icon="info-circle"></svg-icon>
                            </a> -->
						</div>

						<p class="page-section-description" translate>
							thoughtDiary.steps.0.body
						</p>
						<div class="app-l-note__wrap">
							<div class="app-c-form-group">
								<label
									for="event"
									class="app-c-form--label"
									translate
									>thoughtDiary.steps.0.inputTitle</label
								>
								<textarea
									autoFocus
									[tabIndex]="0"
									role="textbox"
									id="event"
									class="app-c-form-control"
									name="event"
									[(ngModel)]="diary.thought.event"
									placeholder="{{
										'thoughtDiary.steps.0.inputText'
											| translate
									}}"
									[disabled]="isDisabled"
								></textarea>
							</div>
						</div>
					</div>
					<div
						*ngIf="step == 2"
						class="main-page-section list-your-feelings-section"
						id="step-2"
					>
						<div class="thought-diary-header">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.1.title
							</h3>
							<a
								(click)="onToggleHelpSection('feelings')"
								(keydown)="onToggleHelpSection('feelings')"
								mat-raised-button
								matTooltip=""
								aria-label="Button that displays a tooltip when focused or hovered over"
							>
								<svg-icon icon="info-circle"></svg-icon>
							</a>
						</div>

						<div
							class="help-section"
							[ngClass]="{ in: helpSections.feelings }"
						>
							<h6 translate>
								thoughtDiary.steps.1.examples.title
							</h6>
							<p translate>
								thoughtDiary.steps.1.examples.line1.0
								<b translate
									>thoughtDiary.steps.1.examples.line1.1</b
								>thoughtDiary.steps.1.examples.line1.2
								<b translate
									>thoughtDiary.steps.1.examples.line1.3</b
								>
							</p>
							<p translate>
								thoughtDiary.steps.1.examples.line2.0
								<b translate
									>thoughtDiary.steps.1.examples.line2.1</b
								>thoughtDiary.steps.1.examples.line2.2
								<b translate
									>thoughtDiary.steps.1.examples.line2.3</b
								>
							</p>
							<p translate>
								thoughtDiary.steps.1.examples.line3.0
								<b translate
									>thoughtDiary.steps.1.examples.line3.1</b
								>thoughtDiary.steps.1.examples.line3.2
								<b translate
									>thoughtDiary.steps.1.examples.line3.3</b
								>
							</p>
							<p translate>
								thoughtDiary.steps.1.examples.line4.0
								<b translate
									>thoughtDiary.steps.1.examples.line4.1</b
								>thoughtDiary.steps.1.examples.line4.2
								<b translate
									>thoughtDiary.steps.2.examples.line4.3</b
								>
							</p>
						</div>

						<p class="page-section-description" translate>
							thoughtDiary.steps.1.body
						</p>

						<div
							class="enter-feelings-main-container"
							*ngIf="!isDisabled"
						>
							<div class="enter-feelings-section">
								<div class="enter-feelings-main-container-left">
									<div
										class="input-group feeling-input-container"
									>
										<label
											for="feeling"
											class="app-c-body-text--14-m"
											translate
											>thoughtDiary.steps.1.inputTitle</label
										>
										<input
											tabindex="0"
											class="app-c-body-text--14"
											id="feeling"
											type="text"
											[disabled]="isDisabled"
											placeholder="{{
												'thoughtDiary.steps.1.inputTag'
													| translate
											}}"
											[(ngModel)]="feeling"
										/>
									</div>
								</div>
								<div
									class="enter-feelings-main-container-right"
								>
									<button
										class="app-c-btn app-c-btn--primary app-c-btn--curve"
										(click)="onCreateFeeling()"
										(keypress)="onCreateFeeling()"
										[ngClass]="{
											'btn-disabled':
												feeling == null || feeling == ''
										}"
									>
										<span translate
											>thoughtDiary.steps.1.add</span
										>
									</button>
								</div>
							</div>
							<div class="select-feelings-section">
								<h2 class="app-c-body-text--12" translate>
									thoughtDiary.steps.1.selectFeeling
								</h2>
								<div class="select-feelings-buttons-section">
									<button
										*ngFor="let item of presetFeelings"
										[ngClass]="{
											active:
												item == feeling ||
												item.toLowerCase() == feeling
										}"
										(click)="onCreateFeeling(item)"
									>
										{{ item }}
									</button>
								</div>
							</div>
						</div>

						<div
							class="feelings-slider-section"
							*ngIf="diaryEntry.feeling && !isDisabled"
						>
							<div class="feelings-slider-inner-container">
								<div class="feelings-first-slider">
									<div class="slider-header">
										<h2
											class="app-c-body-text--14"
											translate
										>
											thoughtDiary.steps.1.feeling
										</h2>
										<div class="feeling-badge">
											<p
												class="feeling-text app-c-body-text--16-m"
											>
												{{ diaryEntry.feeling }}
											</p>
											<p
												class="rating app-c-body-text--14-m"
											>
												<span>{{
													diaryEntry.intensity
												}}</span
												>/<span>10</span>
											</p>
										</div>
									</div>
									<div class="feeling-intensity">
										<div class="slider-header">
											<p
												class="app-c-body-text--14 slider-header-text"
												translate
											>
												thoughtDiary.steps.1.levelOfIntensity
											</p>
										</div>
										<div class="slider-container">
											<mat-slider
												min="0"
												max="10"
												step="1"
												showTickMarks
												[displayWith]="formatLabel"
												[(ngModel)]="
													diaryEntry.intensity
												"
												(ngModelChange)="
													validateInput($event, i)
												"
											>
												<input matSliderThumb />
											</mat-slider>
										</div>
										<div class="slider-labels-container">
											<p
												class="app-c-body-text--10"
												translate
											>
												thoughtDiary.steps.1.barelyAtAll
											</p>
											<p
												class="app-c-body-text--10"
												translate
											>
												thoughtDiary.steps.1.overwhelming
											</p>
										</div>
									</div>
								</div>
								<!-- <div class="divider"></div>
                                <div class="feelings-second-slider">
                                    <div class="app-c-form-group">
                                        <label for="event" class="app-c-form--label"
                                            translate>thoughtDiary.steps.2.boxInputTag</label>
                                        <textarea autoFocus tabindex="0" id="event" class="app-c-form-control"
                                            name="event" [(ngModel)]="diaryEntry.thought"
                                            placeholder="Share the Details of your thoughts"></textarea>
                                    </div>

                                    <div class="slider-section">
                                        <div class="feeling-intensity">
                                            <div class="slider-header">
                                                <p class="app-c-body-text--14 slider-header-text">Rate your thoughts</p>
                                            </div>
                                            <div class="slider-container">
                                                <mat-slider min="0" max="10" step="1" showTickMarks
                                                    [displayWith]="formatLabel" [(ngModel)]="diaryEntry.belief"
                                                    (ngModelChange)="validateInput($event, i)">
                                                    <input matSliderThumb>
                                                </mat-slider>
                                            </div>
                                            <div class="slider-labels-container">
                                                <p class="app-c-body-text--10">Barely at all</p>
                                                <p class="app-c-body-text--10">Overwhelming</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
							</div>
							<div class="feelings-footer">
								<button
									class="app-c-btn app-c-btn--secondary app-c-btn--curve"
									(click)="resetDiaryFeeingEntry()"
								>
									<span translate
										>thoughtDiary.steps.1.cancel</span
									>
								</button>
								<button
									tabindex="0"
									class="app-c-btn app-c-btn--primary app-c-btn--curve"
									(click)="onAddFeeling()"
								>
									<span translate
										>thoughtDiary.steps.1.add</span
									>
								</button>
							</div>
						</div>

						<div class="feelings-list-container">
							<div
								class="feelings-details"
								*ngFor="
									let entry of diary.entries;
									let index = index
								"
							>
								<div class="feelings-details-left-container">
									<div class="feeling-rating">
										<h2
											class="selected-item-heading app-c-body-text--12"
											translate
										>
											thoughtDiary.steps.1.feeling
										</h2>
										<p
											class="feeling-text app-c-body-text--14-m"
										>
											{{ entry.feeling }}
										</p>
									</div>
									<div class="feeling-rating-badge">
										<h2
											class="selected-item-heading app-c-body-text--12"
											translate
										>
											thoughtDiary.steps.1.intensity
										</h2>
										<p class="rating app-c-body-text--14-m">
											<span>{{ entry.intensity }}</span
											>/<span>10</span>
										</p>
									</div>
									<!-- <div class="feeling-thinking-details">
                                        <h2 class="selected-item-heading app-c-body-text--12">What are you thinking</h2>
                                        <p class="thinking-explanation app-c-body-text--12">{{entry.thought}}</p>
                                    </div> -->
								</div>
								<div
									class="feelings-details-right-container"
									*ngIf="!isDisabled"
								>
									<button
										class="delete-button"
										(click)="deleteFeeling(index)"
									>
										<svg-icon
											icon="delete-button-rounded"
										></svg-icon>
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						*ngIf="step == 3"
						class="main-page-section thinking-styles-section"
						id="step-3"
					>
						<div class="thought-diary-header">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.2.title
							</h3>
							<a
								mat-raised-button
								matTooltip=""
								tabindex="0"
								(click)="onToggleHelpSection('thoughts')"
								(keypress)="onToggleHelpSection('thoughts')"
							>
								<svg-icon icon="info-circle"></svg-icon>
							</a>
						</div>
						<div
							class="help-section"
							[ngClass]="{ in: helpSections.thoughts }"
						>
							<h6 translate>
								thoughtDiary.steps.2.examples.title
							</h6>
							<p translate>
								thoughtDiary.steps.2.examples.line1.0<b
									translate
									>thoughtDiary.steps.2.examples.line1.1</b
								>thoughtDiary.steps.2.examples.line1.2<b
									translate
									>thoughtDiary.steps.2.examples.line1.3</b
								>thoughtDiary.steps.2.examples.line1.4
							</p>
							<ul>
								<li>
									<i translate
										>thoughtDiary.steps.2.examples.line2.0</i
									>
								</li>
								<li>
									<i translate
										>thoughtDiary.steps.2.examples.line3.0</i
									>
								</li>
							</ul>
							<p translate>
								thoughtDiary.steps.2.examples.line4.0<b
									translate
									>thoughtDiary.steps.2.examples.line4.1</b
								>thoughtDiary.steps.2.examples.line4.2<b
									translate
									>thoughtDiary.steps.2.examples.line4.3</b
								>thoughtDiary.steps.2.examples.line4.4
							</p>
							<ul>
								<li>
									<i translate
										>thoughtDiary.steps.2.examples.line5.0</i
									>
								</li>
								<li>
									<i translate
										>thoughtDiary.steps.2.examples.line6.0</i
									>
								</li>
							</ul>
						</div>

						<p class="page-section-description" translate>
							thoughtDiary.steps.2.body
						</p>

						<div class="accordions-section">
							<mat-accordion
								class="custom-accordion"
								*ngFor="
									let entry of diary.entries;
									let dataIndex = index
								"
							>
								<mat-expansion-panel
									(opened)="panelOpenState = true"
									(closed)="panelOpenState = false"
								>
									<mat-expansion-panel-header>
										<div class="feelings-details">
											<div
												class="feelings-details-left-container"
											>
												<div class="feeling-rating">
													<h2
														class="selected-item-heading app-c-body-text--12"
														translate
													>
														thoughtDiary.steps.2.feeling
													</h2>
													<p
														class="feeling-text app-c-body-text--14-m"
													>
														{{ entry.feeling }}
													</p>
												</div>
												<div
													class="feeling-rating-badge"
												>
													<h2
														class="selected-item-heading app-c-body-text--12"
														translate
													>
														thoughtDiary.steps.2.intensity
													</h2>
													<p
														class="rating app-c-body-text--14-m"
													>
														<span>{{
															entry.intensity
														}}</span
														>/<span>10</span>
													</p>
												</div>
											</div>
										</div>
									</mat-expansion-panel-header>
									<div class="accordion-content">
										<!-- <div class="accordion-cards-section"> -->
										<div class="feelings-second-slider">
											<div class="app-c-form-group">
												<label
													for="event"
													class="app-c-form--label"
													translate
													>thoughtDiary.steps.2.boxInputTag</label
												>
												<textarea
													autoFocus
													tabindex="0"
													id="thought-{{ dataIndex }}"
													class="app-c-form-control"
													name="thought-{{
														dataIndex
													}}"
													[(ngModel)]="entry.thought"
													placeholder="{{
														'thoughtDiary.steps.2.boxInputText'
															| translate
													}}"
													[disabled]="isDisabled"
												></textarea>
											</div>

											<div class="slider-section">
												<div class="feeling-intensity">
													<div class="slider-header">
														<p
															class="app-c-body-text--14 slider-header-text"
															translate
														>
															thoughtDiary.steps.2.scaleInputTag
														</p>
													</div>
													<div
														class="slider-container"
														[ngClass]="{
															'btn-disable':
																entry.thought ==
																	null ||
																entry.thought ==
																	'' ||
																isDisabled
														}"
													>
														<mat-slider
															min="0"
															max="10"
															step="1"
															showTickMarks
															[displayWith]="
																formatLabel
															"
															[(ngModel)]="
																entry.belief
															"
															(ngModelChange)="
																validateInput(
																	$event,
																	i
																)
															"
															[disabled]="
																isDisabled
															"
														>
															<input
																matSliderThumb
															/>
														</mat-slider>
													</div>
													<div
														class="slider-labels-container"
													>
														<p
															class="app-c-body-text--10"
															translate
														>
															thoughtDiary.steps.2.barelyAtAll
														</p>
														<p
															class="app-c-body-text--10"
															translate
														>
															thoughtDiary.steps.2.overwhelming
														</p>
													</div>
												</div>
											</div>
										</div>
										<!-- </div> -->
										<!-- <div class="accordion-content-footer">
                                            <button class="app-c-btn app-c-btn--primary  app-c-btn--curve">Add</button>
                                        </div> -->
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>
					<div
						*ngIf="step == 4"
						class="main-page-section thinking-styles-section"
						id="step-4"
					>
						<div class="thought-diary-header">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.3.title
							</h3>
							<a
								mat-raised-button
								matTooltip=""
								aria-label="Button that displays a tooltip when focused or hovered over"
								tabindex="0"
								(click)="onToggleHelpSection('thinkingStyles')"
								(keypress)="
									onToggleHelpSection('thinkingStyles')
								"
							>
								<svg-icon icon="info-circle"></svg-icon>
							</a>
						</div>
						<div
							class="help-section"
							[ngClass]="{ in: helpSections.thinkingStyles }"
						>
							<p *ngFor="let key of thinkingStylesKeys">
								<strong>{{ thinkingStyles[key].info }}</strong>
								<span>{{ thinkingStyles[key].text }}</span>
							</p>
						</div>

						<p class="page-section-description" translate>
							thoughtDiary.steps.3.body
						</p>

						<div class="accordions-section">
							<mat-accordion
								class="custom-accordion"
								*ngFor="
									let entry of diary.entries;
									let dataIndex = index
								"
							>
								<mat-expansion-panel
									(opened)="panelOpenState = true"
									(closed)="panelOpenState = false"
								>
									<mat-expansion-panel-header>
										<div class="feelings-details">
											<div
												class="feelings-details-left-container"
											>
												<div class="feeling-rating">
													<h2
														class="selected-item-heading app-c-body-text--12"
														translate
													>
														thoughtDiary.steps.3.feeling
													</h2>
													<p
														class="feeling-text app-c-body-text--14-m"
													>
														{{ entry.feeling }}
													</p>
												</div>
												<div
													class="feeling-rating-badge"
												>
													<h2
														class="selected-item-heading app-c-body-text--12"
														translate
													>
														thoughtDiary.steps.3.intensity
													</h2>
													<p
														class="rating app-c-body-text--14-m"
													>
														<span>{{
															entry.intensity
														}}</span
														>/<span>10</span>
													</p>
												</div>
												<div
													class="feeling-thinking-details"
												>
													<h2
														class="selected-item-heading app-c-body-text--12"
														translate
													>
														thoughtDiary.steps.3.yourThought
													</h2>
													<p
														class="thinking-explanation app-c-body-text--12"
													>
														{{ entry.thought }}
													</p>
												</div>
											</div>
										</div>
									</mat-expansion-panel-header>
									<div class="accordion-content">
										<div class="accordion-cards-section">
											<div
												class="accordion-card app-c-body-text--14"
												[ngClass]="{
													active: thinkingStyle.isSelected,
													inactive:
														!thinkingStyle.isSelected,
													'answers-button':
														appName != 'NMConnect',
													'nm-answers-button':
														appName == 'NMConnect'
												}"
												*ngFor="
													let thinkingStyle of entry.thinkingStyles
												"
												(click)="
													onToggleThinkingStyle(
														thinkingStyle
													)
												"
											>
												{{
													thinkingStyles[
														thinkingStyle.value
													].info
												}}
												<button
													mat-raised-button
													[matTooltip]="
														thinkingStyles[
															thinkingStyle.value
														].text
													"
												>
													<svg-icon
														icon="info-icon-small"
													></svg-icon>
												</button>
											</div>
										</div>
										<!-- <div class="accordion-content-footer">
                                            <button class="app-c-btn app-c-btn--primary  app-c-btn--curve">Add</button>
                                        </div> -->
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>
					<div
						*ngIf="step == 5"
						class="main-page-section challenge-your-thoughts-section"
						id="step-5"
					>
						<div class="thought-diary-header">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.5.title
							</h3>
							<!-- <a mat-raised-button
                                matTooltip="Paul was assigned a new task and was assured it was easy, but he found he needed to ask for help.
                                This made him feel stupid and embarrassed. He was thinking
                                If Paula didn't have any trouble with this, but I can't understand it, it must be because I'm so stupid.
                                When people find out that I needed help with an easy task, I will lose their respect.
                                When Eric had to tell his parents that he hadn't yet found a job, he felt hopeless and like he wanted to
                                run away. He was thinking
                                If I haven't found a job yet, I don't think I'll ever find one
                                I can't bear to tell them and see how they're disappointed, I'd rather just not talk to them at all!"
                                aria-label="Button that displays a tooltip when focused or hovered over" tabindex="0"
                                (click)="onToggleHelpSection('thoughts')" (keypress)="onToggleHelpSection('thought')">
                                <svg-icon icon="info-circle"></svg-icon>
                            </a> -->
						</div>
						<p
							class="app-c-body-text--16 container-text-first"
							translate
						>
							thoughtDiary.steps.4.body.0
						</p>
						<p
							class="app-c-body-text--16 container-text-second"
							translate
						>
							thoughtDiary.steps.4.body.1
						</p>
						<ul class="container-list">
							<li class="app-c-body-text--16" translate>
								thoughtDiary.steps.4.list.0
							</li>
							<li class="app-c-body-text--16" translate>
								thoughtDiary.steps.4.list.1
							</li>
							<li class="app-c-body-text--16" translate>
								thoughtDiary.steps.4.list.2
							</li>
							<li class="app-c-body-text--16" translate>
								thoughtDiary.steps.4.list.3
							</li>
						</ul>
						<div class="accordions-section">
							<mat-accordion
								class="custom-accordion custom-accordion-thoughts"
								*ngFor="
									let entry of diary.entries;
									let dataIndex = index
								"
								[ngClass]="{ completed: entry.challenge }"
							>
								<mat-expansion-panel
									(opened)="panelOpenState = true"
									(closed)="panelOpenState = false"
								>
									<mat-expansion-panel-header>
										<div class="accordion-header-content">
											<div
												class="accordion-header-details"
											>
												<div class="completed-icon">
													<svg-icon
														icon="orange-tick-icon"
													></svg-icon>
												</div>
												<h2
													class="app-c-body-text--12-m"
													translate
												>
													thoughtDiary.steps.4.thought
												</h2>
											</div>
											<p class="app-c-body-text--14">
												{{ entry.thought }}
											</p>
										</div>
									</mat-expansion-panel-header>
									<div class="accordion-content-section">
										<div class="accordion-content">
											<div
												class="accordion-content-left-container"
											>
												<h2 translate>
													thoughtDiary.steps.4.said
												</h2>
												<div
													class="selected-badge app-c-body-text--14-m"
													*ngFor="
														let thinkingStyle of entry.thinkingStyles
															| filter
																: 'isSelected'
																: true
													"
												>
													{{
														thinkingStyles[
															thinkingStyle.value
														].info
													}}
												</div>
											</div>
											<div class="text-area-container">
												<label for="challenge" translate
													>thoughtDiary.steps.4.challenge</label
												>
												<textarea
													tabindex="0"
													id="challenge"
													name="challenge-{{
														dataIndex
													}}"
													[disabled]="isDisabled"
													placeholder="{{
														'thoughtDiary.steps.4.shareDetailsOfThinking'
															| translate
													}}"
													[(ngModel)]="
														entry.challenge
													"
												></textarea>
											</div>
										</div>
										<!-- <div class="accordion-content-footer">
                                            <button tabindex="0" class="app-c-btn app-c-btn--primary  app-c-btn--curve">
                                                <span translate>Add</span>
                                            </button>
                                        </div> -->
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>
					<div
						*ngIf="step == 6"
						class="main-page-section create-action-plan-section"
						id="step-6"
					>
						<div class="thought-diary-header">
							<h3 class="app-c-heading--h3" translate>
								thoughtDiary.steps.5.title
							</h3>
						</div>

						<p class="page-section-description" translate>
							thoughtDiary.steps.5.body
							<!-- <span class="app-c-body-text--16-m">
                                Know more
                            </span> -->
						</p>

						<div class="accordions-section">
							<mat-accordion
								class="custom-accordion custom-accordion-thoughts"
								[ngClass]="{ completed: diary.thought.plan }"
							>
								<mat-expansion-panel
									(opened)="panelOpenState = true"
									(closed)="panelOpenState = false"
								>
									<mat-expansion-panel-header>
										<div class="accordion-header-content">
											<div
												class="accordion-header-details"
											>
												<div class="completed-icon">
													<svg-icon
														icon="orange-tick-icon"
													></svg-icon>
												</div>
												<h2
													class="app-c-body-text--12-m"
													translate
												>
													thoughtDiary.steps.5.yourEvent
												</h2>
											</div>
											<p class="app-c-body-text--14">
												{{ diary.thought.event }}
											</p>
										</div>
									</mat-expansion-panel-header>
									<div class="accordion-content-section">
										<div class="accordion-content">
											<div
												class="accordion-content-left-container"
											>
												<h2 translate>
													thoughtDiary.steps.5.challengeForThought
												</h2>
												<p
													class="app-c-body-text--14-m"
													*ngFor="
														let entry of diary.entries;
														let i = index
													"
												>
													{{ entry.challenge }}
												</p>
											</div>
											<div class="text-area-container">
												<label for="challenge" translate
													>thoughtDiary.steps.5.yourThinking</label
												>
												<textarea
													tabindex="0"
													id="challenge"
													name="challenge"
													[(ngModel)]="
														diary.thought.plan
													"
													placeholder="{{
														'thoughtDiary.steps.5.shareDetailsOfThinking'
															| translate
													}}"
													[disabled]="isDisabled"
													x
												></textarea>
											</div>
										</div>
										<!-- <div class="accordion-content-footer">
                                            <button tabindex="0" class="app-c-btn app-c-btn--primary  app-c-btn--curve">
                                                <span translate>Add</span>
                                            </button>
                                        </div> -->
									</div>
								</mat-expansion-panel>
							</mat-accordion>
						</div>
					</div>
				</div>
				<div class="disclaimer" color="medium">
					<svg-icon icon="bulb-outline"></svg-icon>
					<p translate>thoughtDiary.disclaimer</p>
				</div>
				<div
					class="app-l-mood-check__tab-footer"
					style="display: block"
				>
					<div class="app-l-tab-footer__left">
						<button
							tabindex="0"
							class="app-c-btn app-c-btn--secondary app-c-btn--curve"
							(click)="prevStep()"
							(keypress)="prevStep()"
							[ngClass]="{ 'btn-disabled': step == 1 }"
						>
							<svg-icon icon="arrow-left-long"></svg-icon>
							<span translate>thoughtDiary.previous</span>
						</button>
					</div>
					<div class="app-l-tab-footer__right">
						<button
							tabindex="0"
							class="app-c-btn app-c-btn--primary app-c-btn--curve"
							*ngIf="step < totalSteps"
							(click)="onNext()"
							(keypress)="onNext()"
						>
							<span translate>thoughtDiary.next</span>
							<svg-icon icon="arrow-right-long"></svg-icon>
						</button>
						<button
							tabindex="0"
							*ngIf="
								step === totalSteps &&
								diary.status != 'complete'
							"
							(click)="onComplete()"
							(keypress)="onComplete()"
							class="app-c-btn app-c-btn--primary app-c-btn--curve"
						>
							<span translate>thoughtDiary.finish</span>
							<svg-icon icon="tick"></svg-icon>
						</button>
					</div>
					<!-- <a tabindex="0" (click)="onSaveForLater()" (keypress)="onSaveForLater()"
                        class="btn btn-inline btn-wide" translate>thoughtDiary.save</a>
                    <a tabindex="0" *ngIf="step < totalSteps" (click)="onNext()" (keypress)="onNext()"
                        class="btn btn-primary btn-inline btn-wide" translate>thoughtDiary.next</a>
                    <a tabindex="0" *ngIf="step === totalSteps" (click)="onComplete()" (keypress)="onComplete()"
                        class="btn btn-primary btn-inline btn-wide" translate>thoughtDiary.finish</a> -->
				</div>
			</div>
		</div>
	</div>
</div>
