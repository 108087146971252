import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { UserService } from 'app/lib/user.service';
import { UtilityService } from 'app/lib/utility.service';
import { User } from 'app/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent implements OnInit {
  @ViewChild('emailInput') emailInput: any;
  shareForm: FormGroup;
  isloaded = false;
  lang:any;
  section: any;
  isEditMode:boolean= false;
  isSubmitted:boolean= false;
  modalContent;
  user: User;
  apiEndPoint: string;
  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private utilityService: UtilityService,
    private modalService: ModalService,
    public dialogRef: MatDialogRef<ShareModalComponent>,
    private log: LogService,
    private translateService: TranslateService,
    private userService: UserService) { 
      
      this.shareForm = this.formBuilder.group({
        Email: ["", [Validators.required, Validators.email]],
        Message: ["", []],
      });
      
    this.user = this.userService.getUser();
     this.translateService.get('share').subscribe(res => {
      if (this.user.userType === "user") {
        this.modalContent = res.friend;
        this.apiEndPoint = "user/sharewithfriend";
      } 
      else if (this.user.userType === "orgadmin" || this.user.userType === "professional") {
        this.modalContent = res.counselor;
        this.apiEndPoint = "counselor/sharewithcolleague";
      } 
      else {
        this.modalContent = res.friend;
        this.apiEndPoint = "user/sharewithfriend";
      }
      });
     
    }

  ngOnInit(){
  }

  onClose(){
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    console.log(this.emailInput.nativeElement);
  }

  doSave(){
    this.isSubmitted = true;
    this.isloaded = true;
    if(this.isSubmitted && this.shareForm.valid){
      this.api.post(`${this.apiEndPoint}`,this.shareForm.value).subscribe(
        (results: any) => {
          this.modalService.showAlert('Success', this.modalContent.successMessage);
          this.isloaded = false;
          this.dialogRef.close(results);
        },
        (error: any) => {
          this.log.error('Error loading. ' + error.message);
          this.isloaded = false;
        }
      );
    } else {
      if (this.isSubmitted && this.shareForm.get('Email').invalid) {
        setTimeout(() => {
          this.emailInput.nativeElement.focus();
        });
      }
      this.isloaded = false;
    }
  }

}
