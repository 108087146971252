<div class="app-c-datebox">
    <button class="app-c-datebox__nav" (click)="onClickPrevBtn()">
        <svg-icon icon="angle-left" class="w-100 h-100"></svg-icon>
    </button>
    <div class="app-l-summary__date-label">
        <span>
            {{ selectedDate | date : "MMM" }}
            {{ selectedDate | date : "yyyy" }}
        </span>
    </div>
    <button class="app-c-datebox__nav" [ngClass]="{ disable: isCurrentMonth }"
        (click)="!isCurrentMonth && onClickNextBtn()">
        <svg-icon icon="angle-right" class="w-100 h-100"></svg-icon>
    </button>
</div>


<div class="app-l-monthly__calendar">
    <mwl-calendar-month-view [viewDate]="viewDate" [events]="calendarData" [cellTemplate]="customCellTemplate"
        class="monthly-calendar" [headerTemplate]="headerTemplate">
    </mwl-calendar-month-view>
</div>
<ng-template #headerTemplate>
    <div role="row" class="cal-cell-row cal-header">
        <div tabindex="0" role="columnheader" class="cal-cell cal-past cal-weekend custom-cal-header">
            Sun
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-past custom-cal-header">
            Mon
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-past custom-cal-header">
            Tue
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-past custom-cal-header">
            Wed
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-today custom-cal-header">
            Thu
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-future custom-cal-header">
            Fri
        </div>
        <div tabindex="0" role="columnheader" class="cal-cell cal-future cal-weekend custom-cal-header">
            Sat
        </div>
    </div>
</ng-template>
<ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
        <div class="app-l-monthly-cell">
            <!-- <div class="app-l-monthly__count" *ngIf="day.badgeTotal > 0">
                <span>{{ day.badgeTotal }}</span>
            </div> -->
            <div class="app-l-monthly__days" [ngClass]="{ isToday: day.isToday, isFuture: day.isFuture }"
                (click)="!day.isFuture && showMoodcheckDailyData(day, day.isToday)">
                <span class="app-l-monthly__day-count">{{ day.date | calendarDate : "monthViewDayNumber" : locale }}</span>
                <div class="app-l-monthly__day-indication" *ngFor="let event of day.events; let dataIndex = index">
                    <div *ngIf="event.mood == 'fantastic'" class="app-l-monthly__day-prog prog10"></div>
                    <div *ngIf="event.mood == 'veryHappy'" class="app-l-monthly__day-prog prog9"></div>
                    <div *ngIf="event.mood == 'happy'" class="app-l-monthly__day-prog prog8"></div>
                    <div *ngIf="event.mood == 'good'" class="app-l-monthly__day-prog prog7"></div>
                    <div *ngIf="event.mood == 'fine'" class="app-l-monthly__day-prog prog6"></div>
                    <div *ngIf="event.mood == 'ok'" class="app-l-monthly__day-prog prog5"></div>
                    <div *ngIf="event.mood == 'notGreat'" class="app-l-monthly__day-prog prog4"></div>
                    <div *ngIf="event.mood == 'bad'" class="app-l-monthly__day-prog prog3"></div>
                    <div *ngIf="event.mood == 'veryBad'" class="app-l-monthly__day-prog prog2"></div>
                    <div *ngIf="event.mood == 'awful'" class="app-l-monthly__day-prog prog1"></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
   

<ng-template class="modal-container" #moodcheckDailyModal>
    <div class="modal-container">
        <div class="app-l-moods__modal">
            <div class="app-l-moods__header">
                <h4 class="app-c-heading--h4">Moods of the day</h4>
                <div class="app-l-mood__date">
                    <span class="app-c-body-text--14-m">{{ modalDailySelectedDate | date : "EEEE" }}, {{ modalDailySelectedDate | date : "MMM dd" }}</span>
                </div>
                <button class="app-l-modal__close app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14" mat-dialog-close cdkFocusInitial>
                    <svg-icon icon="close" class="w-100 h-100"></svg-icon>
                </button>
            </div>

            <div *ngIf="hasSelectedDayData; else emptyMoodcheckDailyTemplate" class="progress-section">
                <div class="app-l-daily__mood" *ngFor="let item of dailyMoodcheckCategory">
                    <div class="app-l-daily-mood__left">
                        <span class="app-l-daily__m-label" translate>{{ "dashboard." + item.key }}</span>
                        <!-- <div class="progress-label-daily" translate> 
                            <img class="mood-check-img" [src]="'./assets/img/dashboard/'+ item.key +'.svg'">
                        </div> -->
                    </div>
                    <div class="app-l-daily-mood__right">
                        <span class="app-l-daily__amount">{{ item.value | number : "1.0-0" }}%</span>
                        <div class="app-l-daily__progress">
                            <!-- <mat-progress-bar class="progress-bar-slide" min="0" max="100" [value]="item.value"
                                [ngClass]="item.key + '-progress'" animate>
                            </mat-progress-bar> -->
                            <div class="app-c-welltrack__progress" [ngClass]="item.key + '-progress'" >
                                <div class="app-c-welltrack__progress-fill" [style.width]="item.value + '%'">
                                    <svg-icon [icon]="'emoji-' + item.iconIndex"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-mood-check__legends" *ngIf="hasSelectedDayData">
                <div class="app-l-mood-check__legend" [ngClass]="item.key + '-legend'" *ngFor="let item of dailyMoodcheckCategory; let dataIndex = index">
                    <span translate>{{ "dashboard." + item.key }}</span>
                </div>
            </div>
            <!-- <div class="legends-container">
                <div class="legends" *ngFor="let item of dailyMoodcheckCategory; let dataIndex = index">
                    <div class="dots" [ngClass]="'dot-' + (dataIndex + 1)"></div>
                    <p translate>{{ "dashboard." + item.key }}</p>
                </div>
            </div> -->
        </div>
    </div>
</ng-template>

<ng-template #emptyMoodcheckDailyTemplate>
    <div class="app-l-no-mood-check">
        <div class="no-data-image">
            <svg-icon icon="emoji-2"></svg-icon>
            <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
            <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
            <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
        </div>
        
        <h5 class="app-c-body-text--16-m" [translate]="
        isSelectedDayToday
            ? 'dashboard.daily.noMoodcheckYet'
            : 'dashboard.daily.noMoodcheckOnDay'
        "></h5>
        <p class="app-c-body-text--14" translate>dashboard.daily.moodcheckSummaryAppearHere</p>
    </div>
</ng-template>