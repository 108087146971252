import {
	Component,
	ViewChild,
	ViewEncapsulation,
	OnInit,
	Inject,
	Input,
	ElementRef,
} from "@angular/core";
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
	FormGroup,
	FormBuilder,
	FormControl,
	Validators,
} from "@angular/forms";
import { ApiService } from "app/lib/api.service";
import { LogService } from "app/lib/log.service";
import { UserService } from "../../lib/user.service";
import { User } from "../../models/user";
import { ModalService } from "app/lib/modal.service";
import { data } from "jquery";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { AnalyticEvent } from "app/lib/analytic-event";
import { Router, ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, RoutesRecognized } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { MenuService } from "app/lib/menu.service";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DATES_CONFIG } from "app/models/dynamicform";
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise  } from 'rxjs/operators';

@Component({
	selector: "app-dynamicform-page",
	templateUrl: "./dynamicform-page.component.html",
	styleUrls: ["./dynamicform-page.component.scss"],
})
export class DynamicformPageComponent implements OnInit {
	user: User;
	dynamicForm: FormGroup;
	formData = [];
	finalData = [];
	apiDAta: any;
	isSubmitted = false;
	isAgeValid = false;
	dataLength: number;
	userData: any;
	showInvalid: boolean = false;
	validvalue: any;
	maxval: any;
	minval: any;
	data: any;
	showUserSurvey: boolean;
	showNpsSurvey: boolean;
	showDemographSurvey: boolean;
	isNextBtnDisabled: boolean = false;
	isNextBtn: boolean = false;
	showSaveBtn: boolean = false;
	disableContinueBtn: boolean = true;
	isSaveBtnEnabled: boolean = false;
	optionType: any;
	active = 0;
	scaleData: any;
	@ViewChild("stepper") stepper: MatStepper;
	shownxtques: boolean = false;
	currentDataIndex: number = 0;
	panelOpenState = false;
	@ViewChild("stepsContainer", { static: true }) stepsContainer: ElementRef;
	slideIndex: number = 0;
	slideOpts = {
		slidesPerView: "auto",
	};
	customFormValidator: any = {};
	months = DATES_CONFIG.MONTHS;
	years: number[] = Array.from(
		{ length: 200 },
		(_, i) => new Date().getFullYear() - i
	);
	dates:string[] = [];
	surveyId: any;
	surveyName: any;
	navPath: string = '';
	menuName: string = '';
	surveyType: any;
	optionSelectArr: string[]= [];
	answers: Array<Object> = [];
	showBackbtn: boolean = false;
	translation: any;
	showEmptyHeader: boolean = false;
	showConsent: boolean;
	showAssessment: boolean;
	previousUrl: string;
	path: any;
	constructor(
		private fb: FormBuilder,
		private api: ApiService,
		private modalService: ModalService,
		private log: LogService,
		private userService: UserService,
		private router: ActivatedRoute,
		private _router: Router,
		private menuService: MenuService,
		private translate: TranslateService
	) {
		this.userData = this.userService.getUser();
		const data = history.state;
		const snapshot: ActivatedRouteSnapshot = this.router.snapshot;
		this.surveyId = snapshot.params['surveyId'];
		this.surveyName = snapshot.params['surveyType'];
		this.showConsent = this.userService.isShowConsentInOnboarding(this.userData);
    	this.showAssessment = this.userData && this.userData.forceAssessment;
		this.router.queryParams.subscribe(params => {
			this.navPath = params['navPath'];
			this.menuName = params['menuName'];
			this.path = params['path'];
		});
		if(this.navPath  === "/user-onboarding"){
			this.showEmptyHeader = true;
		}
		this.apiDAta = this.surveyName;
		this.api.get('form/' + this.surveyId).subscribe(response => {
			this.data = response['data'];
			const data = {
				data: this.data
			}
			this.initialize(data);
		});
	}

	ngOnInit(): void {
		this.translate.get("dynamicForm").subscribe((res) => {
			this.translation = res;
		  });
		const daysInMonth = new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).getDate();
		// this.dates = Array.from({ length: daysInMonth }, (_, i) => i + 1);
		this.dates = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString().padStart(2, "0")

    );
			this._router.events
			.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
			.subscribe((events: RoutesRecognized[]) => {
				this.previousUrl = events[0].urlAfterRedirects;
			});
	}

	initialize(data) {		
		this.formData = [];
		if (data["data"] != undefined) {
			let objs = data["data"];
			this.dataLength = objs.length;
			for (let obj of objs) {
				obj[0]["validations"] = JSON.parse(obj[0]["Validations"]);
				this.formData.push(obj[0]);
			}
			this.dynamicForm = this.generateFilterForm();
		}

		if (this.surveyName === "UserRatingSurvey") {
			this.showNpsSurvey = true;
		} else if (this.surveyName === "UserFeedbackSurvey") {
			this.showUserSurvey = true;
		} else {
			this.showDemographSurvey = true;
		}
		this.userData = this.userService.getUser();
	}



	generateFilterForm() {
		const group: any = {};
		this.formData.forEach((field, index) => {
			field["Key"] = "qn" + index;

			if (field.Type === 1) {
				group[field.Key] = field.Required
					? new FormControl(field.Value || null, Validators.required)
					: new FormControl(field.Value || null);
			}
			else if (field.Type === 2) {
				field["options"].forEach(((e, i) => {
					group[field["Key"] + '_' + i] = new FormControl("", Validators.nullValidator);
					this.answers.push({ value: e.ID, isAnswered: false });
				}))

				group[field.Key] = field.Required
					? new FormControl(field.Value || null, Validators.required)
					: new FormControl(field.Value || null);
			}
			else if (field.Type === 7) {
				group[field.Key] = new FormControl(field.Value || "");
				field["rangeSelectedBtnId"] = "";
				Object.assign(this.customFormValidator, {
					[field.Key]: field.Required ? false : true,
				});
				field.options.forEach((option, opIndex) => {
					option["isSelected"] = false;
				});
			} else if (field.Type === 9) { // field.Group === "age" removed
				group["selectedMonth"] = new FormControl("", Validators.required);
				group["selectedYear"] = new FormControl("", Validators.required);
				group["selectedDate"] = new FormControl("", Validators.required);
				group[field.Key] = field.Required
					? new FormControl(field.Value || "", Validators.required)
					: new FormControl(field.Value || "");
			}
			else if (field.Type === 8) {
				let validators = [
					Validators.min(field.validations["min"]),
					Validators.max(field.validations["max"]),
				];
				field.Required && validators.push(Validators.required);
				group[field.Key] = new FormControl(field.Value || "", validators);
			}
			else {
				group[field.Key] = field.Required
					? new FormControl(field.Value || "", Validators.required)
					: new FormControl(field.Value || "");
			}
			if (field.hasOwnProperty("sub")) {
				field.sub.forEach((subQuestion, subIndex) => {
					subQuestion["show"] = false;
					//subQuestion["Key"] = "qn" + index;
					subQuestion["Key"] = "qn" + index + "sub" + subIndex;
				});
			}
			else{
				field["sub"]=[{"Key":"qn" + index + "sub0","Required":0,"Type":"NA","range":[1,2,3,4,5,6,7,8,9,10]}];
			}
			
		});
		return new FormGroup(group);
	}

	doSave() {
		this.isSubmitted = true;
		this.dynamicForm.markAllAsTouched();
		if (
			(this.apiDAta === "UserRatingSurvey" && (this.isFormValid() || this.dynamicForm.valid)) ||
			(this.apiDAta !== "UserRatingSurvey" && this.dynamicForm.valid)
		) {
			this.callDynamicFormSave();
		}
	}

	// Isform Valids
	isValid(subQuestion, index, ev) {
		let isSubQuestionAnswerExist = false;
		this.finalData = this.finalData.filter((data) => data.value);
		if (this.isSubmitted && subQuestion.Required) {
			this.dynamicForm.markAllAsTouched();
			const isSubQuestionAnswer = this.finalData.filter((data) =>
				subQuestion.options.map((sub) => sub.ID).includes(data.name)
			);
			isSubQuestionAnswerExist =
				isSubQuestionAnswer.length == 0 ? true : false;
			return isSubQuestionAnswerExist;
		}
		return isSubQuestionAnswerExist;
	}

	isMainQuestionAnswered(mainQn) {
		let mainQuestionAnswered = false;
		if (this.isSubmitted && mainQn.Required) {
			const mainQnAns = this.finalData.filter(
				(data) => data.id == mainQn.ID
			);
			mainQuestionAnswered = mainQnAns.length == 0 ? true : false;
			return mainQuestionAnswered;
		}
		return mainQuestionAnswered;
	}

	checkValidDigit(data) {
		let validDigit = false;
		if (data.Required && data.Type === 8) {
			const ageQn = this.finalData.filter(
				(findata) => findata.id === data.ID
			);

			if (ageQn.length == 0) {
				this.dynamicForm.get(data.Key).markAsTouched();
				this.dynamicForm.get(data.Key).setErrors({
					notMatched: validDigit,
				});
				return;
			}
			let validvalue = JSON.parse(data.Validations);
			this.maxval = validvalue.max;
			this.minval = validvalue.min;
			validDigit = ageQn[0].value >= validvalue.min &&
				ageQn[0].value <= validvalue.max
				? true : false;


			if (!validDigit) {

				this.dynamicForm.get(data.Key).markAsTouched();
				this.dynamicForm.get(data.Key).setErrors({
					notMatched: true,
				});
			} else {

				this.dynamicForm.get(data.Key).markAsUntouched();
				this.dynamicForm.get(data.Key).setErrors(null);
			}
		}

	}


	isNotRequiredQuestion(data) {
		let optionId = this.getOptionId(data);
		if ((data.hasOwnProperty("Required") && data.Required) || optionId) {
			if (optionId != null) {
				let subData = this.getRequiredSubQuestion(optionId, data);
				if (subData === undefined) return true;
				if (subData != null) {
					return this.isNotRequiredQuestion(subData);
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return true;
		}
	}

	getOptionId(data) {
		let finalDetails = this.finalData.filter(
			(finalValue) => finalValue.id === data.ID
		);
		return finalDetails.length > 0 ? finalDetails[0].value : null;
	}

	getRequiredSubQuestion(questionValue, data) {
		if (!data.hasOwnProperty("sub")) {
			//to do - Need to check the options value in the final data
			return;
		}
		let subData = data.sub.filter((subOption) =>
			subOption.range.includes(questionValue)
		);
		return subData.length > 0 ? subData[0] : null;
	}

	// is NPS slider selection and subQuestion validation
	isFormValid() {
		let isAnswered = true;
		for (let i = 0; i < this.formData.length; i++) {
			if (!this.isNotRequiredQuestion(this.formData[i])) {
				isAnswered = false;
				break;
			}
		}
		return isAnswered;
	}

	// Save APi
	callDynamicFormSave() {
		let finalData = {
			Type: this.surveyId,
			Data: JSON.stringify(this.finalData),
		};
		this.isSubmitted = false;
		this.log.debug(JSON.stringify(this.finalData));
		this.api.post("form/" + this.surveyId, finalData).subscribe(
			(result: any) => {
				this.userService.reloadUser();
				this.user = this.userService.getUser();
				if (this.apiDAta === "Demographic") {
					this.modalService.showAlert("Success", this.translation.demoSuccess);
					if (this.navPath  === "/user-onboarding") {
						this.user.demographicCount = this.user.demographicCount > 0 ? this.user.demographicCount : 1;
						if (!this.showConsent && !this.showAssessment) {
							this.user.onboardingStep = 3;
							this.userService.setUser(this.user);
							this.menuService.postDemogrphicSurvey(
								"app",
								this.menuName
							);
						  } else {
							this.user.onboardingStep = 1;
							this.userService.setUser(this.user);
							this.menuService.postDemogrphicSurvey(
								"/user-onboarding",
								this.menuName
							);
						  }
					} else if(this.path == "app/my-settings"){
						this.userService.setUser(this.user);
							this.menuService.postDemogrphicSurvey(
								this.path,
								this.menuName
							);
					} else {
						this.menuService.postDemogrphicSurvey(
							this.navPath,
							this.menuName
						);
					}
					
				} else if (this.apiDAta === "UserFeedbackSurvey") {
					this._router.navigate(['/app/surveys', this.user.npsSurveyId, 'UserRatingSurvey']);
					this.modalService.showAlert("Success", this.translation.feedbackSuccess);
					this.showUserSurvey = false;
					this.showNpsSurvey = true;
					this._router.events.subscribe(event => {
						if (event instanceof NavigationEnd) {
							this.showNPSSurvey();
						}
					});
				} else if (this.apiDAta === "UserRatingSurvey") {
					this.modalService.showAlert("Success", this.translation.RatingSuccess);
					this.user = this.userService.getUser();
					this._router.navigateByUrl("/app");
				}
				this.log.event(
					this.apiDAta +
					" " +
					AnalyticEvent.event.updated
				);
			},
			(error: any) => {
				this.modalService.showAlert("Error", error.message);
			},
			() => { }
		);
	}

	showNPSSurvey() {
		this.showNpsSurvey = true;
		this.surveyId = this.router.snapshot.params['surveyId'];
		this.surveyName = this.router.snapshot.params['surveyType'];
		this.apiDAta = this.surveyName;
		this.api.get('form/' + this.surveyId).subscribe(response => {
			this.data = response['data'];
			const data = {
				data: this.data
			}
			this.initialize(data);
		});
	}

	// Field type change
	valueChange(data, ev, opt,checkBoxIndex) {
		let valueSelected: any;
		this.isSubmitted = false;

		switch (data.Type) {
			case 1: {
				this.optionType = ev.ID;
				valueSelected = {
					value: data.ID,
					name: ev.ID,
					id: ev.QuestionID,
				};
				this.dynamicForm.get(data.Key).setValue(data.ID);
				this.dynamicForm.get(data.Key).updateValueAndValidity();
				const index = this.finalData.findIndex((fd) => {
					return (
						fd.id === ev.QuestionID
					);
				});
				if (index >= 0) {
					this.finalData.splice(index, 1);
				}
				else {
					this.finalData.push(valueSelected);
				}
				setTimeout(() => {
					this.onNext()
				}, 100);
				break;
			}
			case 2: {
				
				opt.checked = !opt.checked;
				valueSelected = {
					value: data.ID,
					name: ev.ID,
					id: ev.QuestionID,
				};
				this.dynamicForm.get(data.Key).setValue(data.ID);
				const index = this.finalData.findIndex((fd) => {
					return (
						fd.name === ev.ID
					);
				});
				if (index >= 0) {
					this.finalData.splice(index, 1);					
					this.optionSelectArr.splice(index, 1);					
				} else {
					this.finalData.push(valueSelected);					
					this.optionSelectArr.push(checkBoxIndex);
				}
				const idx = this.answers.findIndex((fd) => {
					return (
						fd['value'] === ev.ID
					);
				});
				this.answers[idx]['isAnswered']=!this.answers[idx]['isAnswered'];
				const hasAnswer = this.finalData.find((fd) => {
					return fd.id === data.ID;
				});
				hasAnswer && this.dynamicForm.get(data.Key).setValue(true);
				if (data.Required && !hasAnswer) {
					this.dynamicForm.get(data.Key).setErrors({ required: true });
				}

				break;
			}
			case 4: {
				this.optionType = opt.ID;
				this.dynamicForm.get(data.Key).setValue(opt);
				valueSelected = {
					value: data.ID,
					name: opt.ID,
					id: opt.QuestionID,
				};
				const index = this.finalData.findIndex((fd) => {
					return fd.id === opt.QuestionID;
				});
				if (index >= 0) {
					this.finalData.splice(index, 1);
				}
				this.finalData.push(valueSelected);
				setTimeout(() => {
					this.onNext()
				}, 200);
				break;
			}
			case 5:
			case 6:
			case 8: {
				let value: string;

				value = ev;
				this.dynamicForm.get(data.Key).setValue(value);
				valueSelected = {
					value: value,
					name: data.ID,
					id: data.ID,
				};
				const index = this.finalData.findIndex((fd) => {
					return fd.name === data.ID;
				});
				if (index >= 0) {
					this.finalData.splice(index, 1);
					if (value != "") {
						this.finalData.push(valueSelected);
					}
				}
				else {
					(value != "") && this.finalData.push(valueSelected);
				}
				(data.Type == 8) && this.checkValidDigit(data);
				break;
			}
			case 9: {
				let value: string;
				(opt == "selectedYear" || opt == "selectedMonth") &&
					this.updateDates(data, opt);
				if (
					this.dynamicForm.get("selectedYear").valid &&
					this.dynamicForm.get("selectedMonth").valid &&
					this.dynamicForm.get("selectedDate").valid
				) {
					value =
						this.dynamicForm.get("selectedYear").value +
						"-" +
						this.dynamicForm.get("selectedMonth").value +
						"-" +
						this.dynamicForm.get("selectedDate").value;
					this.dynamicForm.get(data.Key).setValue(value);

					valueSelected = {
						value: value,
						name: data.options[0].ID,
						id: data.options[0].QuestionID,
					};
					const index = this.finalData.findIndex((fd) => {
						return fd.name === data.options[0].ID;
					});
					if (index >= 0) {
						this.finalData.splice(index, 1);
					}
					this.finalData.push(valueSelected);
				}
				break;
			}
			default: {
				valueSelected = {
					value: data.ID,
					name: ev.value.ID,
					id: ev.value.QuestionID,
				};
				const index = this.finalData.findIndex((fd) => {
					return (
						fd.id === ev.value.QuestionID
					);
				});
				if (index >= 0) {
					this.finalData.splice(index, 1);
				}
				else {
					this.finalData.push(valueSelected);
				}
				break;
			}
		}
	}

	isAnyOptionSelected(id): boolean {
		
		const index = this.answers.findIndex((fd) => {
			return fd['value'] === id;
		});
		return this.answers[index]['isAnswered'];
	  }

	nextData() {
		this.disableContinueBtn = true;
		if (this.currentDataIndex < this.formData.length - 1) {
			this.currentDataIndex++;
		}
			this.showBackbtn = true;
			if (this.currentDataIndex === this.formData.length) {
				this.isSaveBtnEnabled = true;
			} 
	}

	prevData(){
		this.currentDataIndex--;
		if (this.currentDataIndex === 0) {
			this.showBackbtn = false;
		}else{
			this.showBackbtn = true;
		}
	}

	// NPS number scale selection
	scaleSelectionCompleted(data) {
		this.scaleData = data;
		this.shownxtques = false;
		this.isSaveBtnEnabled = false;
		this.showScaleQuestions();
	}

	showScaleQuestions() {
		this.shownxtques = true;
		if (this.currentDataIndex === this.formData.length - 1) {
			this.isSaveBtnEnabled = true;
		}  
		let data = this.scaleData;
		this.formData[this.currentDataIndex].options.forEach((option,index) =>{
			if(option.ID === this.scaleData.options.ID){
				option = this.scaleData.options;
			}
		})
		if (this.formData[this.currentDataIndex].sub) {

		this.formData[this.currentDataIndex].sub.forEach((subQn, subIndex) => {
			// check the selecetd value exist in the range
			const isAvailable = this.dynamicForm.contains(
				"qn" + this.currentDataIndex + "sub" + subIndex
			  );

			if (subQn.range.includes(data.options.OptionValue)) {
				subQn.show = true;
				!isAvailable &&
				this.dynamicForm.addControl(
				  "qn" + this.currentDataIndex + "sub" + subIndex,
				  subQn.Required
					? new FormControl(subQn.Value || null, [Validators.required])
					: new FormControl(subQn.Value || null)
				);
			} else {
				isAvailable &&
          this.dynamicForm.removeControl(
            "qn" + this.currentDataIndex + "sub" + subIndex
          );
				subQn.show = false;
			}
		});
	}else{
		this.dynamicForm.get(this.formData[this.currentDataIndex].Key).setValue(true);
	}
		this.customFormValidator[this.formData[this.currentDataIndex].Key] = true;
		const valueSelected = {
			value: data.options.OptionValue,
			name: data.options.ID,
			id: data.options.QuestionID,
		};
		const index = this.finalData.findIndex((fd) => {
			return fd.id === data.options.QuestionID;
		});
		if (index >= 0) {
			this.finalData.splice(index, 1);
		}
		this.finalData.push(valueSelected);

		// const hasAnswer = this.finalData.find((fd) => {
		// 	return fd.id === data.options.QuestionID;
		// });
		// if (dataForm.Required && !hasAnswer) {
		// 	this.dynamicForm.get(data.Key).setErrors({ required: true });
		// }
	}


	onNext() {	

		if (this.slideIndex === this.formData.length - 1) {
			return;
		}
		this.slideIndex++;
		const index = this.finalData.findIndex((fd) => {
			return fd.id === this.formData[this.slideIndex].ID;
		});
		this.optionType = this.finalData[index] && this.finalData[index].name ? this.finalData[index].name : '';
	}

	onPrevious() {
		this.slideIndex--;
		const index = this.finalData.findIndex((fd) => {
			return fd.id === this.formData[this.slideIndex].ID;
		});		
		// this.optionType = this.finalData[index].name;
		this.optionType = this.finalData[index] && this.finalData[index].name ? this.finalData[index].name : '';
	}

	updateDates(data, field) {
		const selectedYear = this.dynamicForm.get("selectedYear").value;
		const selectedMonth = this.dynamicForm.get("selectedMonth").value;
		const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
		// this.dates = Array.from({ length: daysInMonth }, (_, i) => i + 1);
		this.dates = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString().padStart(2, "0"));
		field == "selectedMonth" &&
		this.dynamicForm.get("selectedDate").setValue("");
		this.dynamicForm.get(data.Key).setErrors({ required: true });
	}

}
