<div id="page-assertion" class="public-portal-right-col">
    <h1 id="section-title" class="wt-access">Confirm</h1>
    <div id="confirm-header">
        <img alt="Welltrack badge" src="./assets/img/welltrack-boost-circle.png" class="animated fadeInDown">
    </div>

    <div style="padding-bottom: 0;">
        <img alt="Welltrack secondary logo" id="text-logo" src="./assets/img/welltrack_gray@2x.png">
    </div>

    <div class="center padding" style="margin-top: 100px;">
        <code>{{decodedText}}</code>
    </div>
</div>