<div class="app-works-main-container" *ngIf="showEmptyHeader">
	<div class="app-works-header">
		<img src="../../../assets/img/welltrack-logos/well-track-white-logo.svg" alt="welltrack-logo" />
		<div class="app-works-header-bg">
			<svg-icon icon="app-works-header-bg"></svg-icon>
		</div>
	</div>
</div>
<div class="app-l-page-wrap">
	<mat-accordion class="app-c-accordion app-c-accordion__survey">
		<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
			<mat-expansion-panel-header>
				<mat-panel-title>About this Survey</mat-panel-title>
			</mat-expansion-panel-header>
			<ng-container *ngIf=" userData.primaryOrganization &&
            userData.primaryOrganization.serviceType === 1; else servicetypetwo">
				<p translate>dynamicForm.contentStudent</p>
			</ng-container>
			<ng-template #servicetypetwo>
				<ng-container *ngIf=" userData.primaryOrganization &&
				userData.primaryOrganization.serviceType === 2; else servicetypethree">
					<p translate>dynamicForm.contentEmployer</p>
				</ng-container>
			</ng-template>
			<ng-template #servicetypethree>
				<ng-container *ngIf=" userData.primaryOrganization &&
				userData.primaryOrganization.serviceType === 3; else servicetypefour">
					<p translate>dynamicForm.contentCommunity</p>
				</ng-container>
			</ng-template>
			<ng-template #servicetypefour>
				<ng-container *ngIf=" userData.primaryOrganization &&
				userData.primaryOrganization.serviceType === 4; else servicetypedefault">
					<p translate>dynamicForm.contentManagedcare</p>
				</ng-container>
			</ng-template>
			<ng-template #servicetypedefault>
				<p translate>dynamicForm.contentDefault</p>
			</ng-template>
		</mat-expansion-panel>
	</mat-accordion>
	<div class="app-l-feedback-container">
		<h3 class="app-l-feedback__title">
			{{
			"dynamicForm.dynamicTitle" + apiDAta
			| translate : { value: apiDAta }
			}}
		</h3>

		<div class="app-c-stepper">
			<!--STEPPERS START-->
			<div class="steps-container" #stepsContainer *ngIf="showUserSurvey || showDemographSurvey">
				<ul class="progressbar" *ngIf="formData.length > 1">
					<ng-container *ngFor="let data of formData; let dataIndex = index">
						<li [ngClass]="{
								active: slideIndex === dataIndex,
								completed:
									slideIndex != dataIndex &&
									dynamicForm.get(data.Key).value
							}">
							{{ "Step " + (dataIndex + 1) }}
						</li>
					</ng-container>
				</ul>
			</div>
			<!--STEPPERS END-->
			<div *ngIf="showUserSurvey || showDemographSurvey" class="app-c-stepper__content__wrap">
				<div *ngFor="let data of formData; let i = index" [ngClass]="{ active: active === i }"
					class="formContainer">
					<ng-container [ngTemplateOutlet]="formFieldTemplate"
						[ngTemplateOutletContext]="{ value: data, index: i }">
					</ng-container>
				</div>
			</div>

			<!--NPS START-->
			<div *ngIf="showNpsSurvey" class="app-c-number-scale-blk">
				<!-- Slide rand corresponding fields -->

				<div class="app-c-number-scale-content">
					<div *ngFor="
							let data of [formData[currentDataIndex]];
							let i = index
						" class="app-c-number-scale-content__inner">
						<!-- Slide rand corresponding fields -->
						<div *ngIf="data['Type'] === 7; else otherType" class="nps-survey-block">
							<h3 class="app-c-stepper__question-block">
								{{ data["Comment"] }}
							</h3>
							<app-number-scale [scaleIndex]="i" [scaleOptionValues]="data.options"
								(onCompleted)="scaleSelectionCompleted($event)"></app-number-scale>

							<p *ngIf="
									isSubmitted && isMainQuestionAnswered(data)
								">
								{{ "dynamicForm.fieldRequired" | translate }}
							</p>

							<div *ngIf="shownxtques && data.sub">
								<div *ngFor="
										let subQn of data.sub;
										let j = index
									">
									<div *ngIf="subQn['show']">
										<ng-container [ngTemplateOutlet]="
												formFieldTemplate
											" [ngTemplateOutletContext]="{
												value: subQn,
												index: j
											}">
										</ng-container>
									</div>
								</div>
							</div>

						</div>
						<ng-template #otherType>
							<ng-container [ngTemplateOutlet]="formFieldTemplate"
								[ngTemplateOutletContext]="{ value: data, index: i }">
							</ng-container>
						</ng-template>
					</div>
				</div>

			</div>
			<!--NPS END-->
		</div>

		<!--USER SURVEY AND DEMOGRAPHIC START-->

		<ng-template let-data="value" let-index="index" #formFieldTemplate>
			<form [formGroup]="dynamicForm" novalidate>
				<div *ngIf="index === slideIndex || showNpsSurvey" class="app-c-stepper__content__wrap">
					<div class="app-c-stepper__content radio-section likeDislikeContent formContainer_body"
						*ngIf="data['Type'] === 1">
						<h3 class="app-c-stepper__question-block">
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.chooseOptionAppliesYou
						</h6>
						<mat-radio-group [formControlName]="data['Key']" *ngIf="data['Type'] === 1">
							<input matInput style="display: none" type="text" [formControlName]="data['Key']" />
							<div *ngFor="let opt of data.options; let j = index" class="app-c-radio__like"
								[ngClass]="{ active: optionType === opt.ID }" (click)="valueChange(data, opt)">
								<div *ngIf="
										opt['Comment'].toLowerCase() == 'yes'
									">
									<svg-icon icon="like"></svg-icon>
								</div>
								<div *ngIf="opt['Comment'].toLowerCase() == 'no'">
									<svg-icon icon="dislike"></svg-icon>
								</div>
								<span>
									{{ opt.Comment }}
								</span>
							</div>
						</mat-radio-group>

						<p *ngIf="
								isSubmitted &&
								dynamicForm
									.get(data['Key'])
									.hasError('required')
							">
							{{ "dynamicForm.fieldRequired" | translate }}
						</p>
					</div>
					<div class="app-c-stepper__content checkbox-section formContainer_body" *ngIf="data['Type'] === 2">
						<h3 class="app-c-stepper__question-block" translate>
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.chooseOptionAppliesYou
						</h6>
						<div class="app-c-radio__horizontal__list">
							<div class="app-c-radio__horizontal" *ngFor="let opt of data.options; let j = index"
								[ngClass]="{ active: isAnyOptionSelected(opt.ID) }">
								<mat-checkbox class="custom-checkbox" slot="end" [checked]="opt.checked"
									[formControlName]="data['Key'] + '_' + j"
									(change)="valueChange(data, opt, $event,j)" [value]="opt"
									[ngClass]="{ active: isAnyOptionSelected(opt.ID) }">
									<span>{{ opt["Comment"] }}</span>
								</mat-checkbox>
								<svg-icon [ngClass]="{ active: isAnyOptionSelected(opt.ID) }"
									icon="tick-circle"></svg-icon>
							</div>
						</div>
						<p *ngIf="
								isSubmitted &&
								dynamicForm
									.get(data['Key'])
									.hasError('required')
							">
							{{ "dynamicForm.fieldRequired" | translate }}
						</p>
					</div>

					<div class="app-c-stepper__content formContainer_body" *ngIf="data['Type'] === 4">
						<h3 class="app-c-stepper__question-block">
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.chooseOptionAppliesYou
						</h6>
						<input matInput style="display: none" type="text" [formControlName]="data['Key']" />
						<div class="app-c-radio__horizontal__list">
							<div *ngFor="let opt of data.options" class="app-c-radio__horizontal"
								(click)="valueChange(data, $event, opt)" [ngClass]="{'active': optionType === opt.ID}">
								<span> {{ opt.Comment }}</span>
								<svg-icon [ngClass]="{
										active: optionType === opt.ID
									}" icon="tick-circle"></svg-icon>
							</div>
						</div>
					</div>

					<div class="text-area-input-container formContainer_body app-c-stepper__content"
						*ngIf="data['Type'] === 5">
						<h3 class="app-c-stepper__question-block">
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.writeYourSuggestions
						</h6>
						<div class="formContainer_body_textarea">
							<div class="wt-mat-form-field">
								<textarea [attr.aria-label]="data['Comment']" matInput title="{{ data['Comment'] }}"
									label="{{ data['Comment'] }}" placeholder="{{ data['Comment'] }}"
									[name]="data['Key']" class="textArea_custom" [formControlName]="data['Key']"
									(change)="
										valueChange(data, $event.target.value)
									" [attr.aria-label]="data['Comment']"></textarea>
								<p *ngIf="
										isSubmitted &&
										dynamicForm
											.get(data['Key'])
											.hasError('required')
									">
									{{
									"dynamicForm.fieldRequired" | translate
									}}
								</p>
							</div>
						</div>
					</div>

					<div class="text-area-input-container formContainer_body app-c-stepper__content"
						*ngIf="data['Type'] === 6">
						<h3 class="app-c-stepper__question-block">
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.textDesc
							<span translate>{{ data["Comment"] }}</span>
						</h6>
						<div class="formContainer_body_textarea">
							<div class="wt-mat-form-field">
								<textarea [attr.aria-label]="data['Comment']" matInput
									placeholder="{{ data['Comment'] }}" [name]="data['Key']" class="textArea_custom"
									[formControlName]="data['Key']" (change)="
										valueChange(data, $event.target.value)
									"></textarea>
								<p *ngIf="
										isSubmitted &&
										dynamicForm
											.get(data['Key'])
											.hasError('required')
									">
									{{
									"dynamicForm.fieldRequired" | translate
									}}
								</p>
							</div>
						</div>
					</div>

					<div class="app-c-stepper__content formContainer_body" *ngIf="data['Type'] === 9">
						<h3 class="app-c-stepper__question-block">
							{{ data["Comment"] }}
						</h3>
						<h6 class="app-c-stepper__question-desc" translate>
							dynamicForm.provideDOB
						</h6>
						<div class="app-l-dob-blk">
							<div class="app-l-dob-blk__item">
								<mat-form-field appearance="outline">
									<mat-label translate>dynamicForm.month</mat-label>
									<mat-select class="dob-dropdown" placeholder="{{
											'dynamicForm.month' | translate
										}}" formControlName="selectedMonth" (selectionChange)="
											valueChange(
												data,
												$event,
												'selectedMonth'
											)
										">
										<mat-option *ngFor="let month of months" [value]="month.value">{{ month.label
											}}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="app-l-dob-blk__item">
								<mat-form-field appearance="outline">
									<mat-label translate>dynamicForm.date</mat-label>
									<mat-select class="dob-dropdown" placeholder="Date" formControlName="selectedDate"
										(selectionChange)="
											valueChange(
												data,
												$event,
												'selectedDate'
											)
										">
										<mat-option *ngFor="let date of dates" [value]="date">{{ date }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<div class="app-l-dob-blk__item">
								<mat-form-field appearance="outline">
									<mat-label translate>dynamicForm.year</mat-label>
									<mat-select class="dob-dropdown" placeholder="{{
											'dynamicForm.year' | translate
										}}" formControlName="selectedYear" (selectionChange)="
											valueChange(
												data,
												$event,
												'selectedYear'
											)
										">
										<mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<div *ngIf="data['Group'] != 'age'">
							<div class="input-item age-input-section">
								<mat-input placeholder="{{
										'dynamicForm.date' | translate
									}}" [name]="data['Key']" floating type="text" [formControlName]="data['Key']"
									(change)="valueChange($event, data)"></mat-input>
							</div>
							<p *ngIf="
									isSubmitted &&
									dynamicForm
										.get(data['Key'])
										.hasError('required')
								" class="form-error error red">
								*{{ "dynamicForm.fieldRequired" | translate }}
							</p>
						</div>
					</div>

					<div class="viewlabel formContainer_body" *ngIf="data['Type'] === 8">
						<label>{{ data["Comment"] }}</label>
						<div class="formContainer_body_inputNumber">
							<mat-form-field class="wt-mat-form-field example-full-width" [floatLabel]="'never'">
								<mat-label translate>dynamicForm.textDesc
									<span>{{ data["Comment"] }}</span>
								</mat-label>
								<input matInput class="wt-mat-input" type="number"
									[min]="data['validations']['min'] || 0" [max]="data['validations']['max'] || 0"
									[formControlName]="data['Key']" placeholder="Enter" (keyup)="
										valueChange(data, $event.target.value)
									" />
								<mat-error *ngIf="
										dynamicForm
											.get(data['Key'])
											.hasError('required')
									">
									{{
									"dynamicForm.fieldRequired" | translate
									}}
								</mat-error>
								<mat-error *ngIf="data['Key'].invalid">
									{{
									"dynamicForm.fieldRequired" | translate
									}}
								</mat-error>
								<mat-error *ngIf="
										dynamicForm
											.get(data['Key'])
											.hasError('notMatched')
									">
									{{
									"dynamicForm.invalid"
									| translate
									: {
									minval: minval,
									maxval: maxval
									}
									}}
								</mat-error>
							</mat-form-field>
						</div>
					</div>

					<!--FOOTER START showUserSurvey || showDemographSurvey-->
					<div class="app-c-stepper__footer" *ngIf="showUserSurvey || showDemographSurvey">
						<div class="app-c-stepper__footer__left">
							<button tabindex="0" (click)="onPrevious()" (keypress)="onPrevious()"
								*ngIf="slideIndex != 0"
								class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--with-icon" translate>
								<svg-icon icon="arrow-left"></svg-icon>
								<span>
									{{ "dynamicForm.back" | translate }}</span>
							</button>
						</div>
						<div class="app-c-stepper__footer__right">
							<button class="app-c-btn app-c-btn--primary app-c-btn--curve app-c-btn--with-icon"
								(click)="onNext()" *ngIf="slideIndex < formData.length - 1" [ngClass]="{
									'btn-disabled': !dynamicForm.get(
										formData[slideIndex].Key
									).valid
								}" translate>
								<span>
									{{ "dynamicForm.continue" | translate }}
								</span>
								<svg-icon icon="arrow-right"></svg-icon>
							</button>
							<button class="app-c-btn app-c-btn--primary app-c-btn--curve app-c-btn--with-icon"
								[ngClass]="{
									'btn-disabled': !dynamicForm.valid
								}" *ngIf="slideIndex == formData.length - 1" (click)="doSave()" translate>
								<span>
									{{ "dynamicForm.finish" | translate }}
								</span>
								<svg-icon icon="arrow-right"></svg-icon>
							</button>
						</div>
					</div>
					<!--FOOTER END-->
					<!--FOOTER START showNpsSurvey-->
					<div class="app-c-stepper__footer" *ngIf="showNpsSurvey">
						<div class="app-c-number-scale__footer__left">
							<button tabindex="0" *ngIf="showBackbtn" (click)="prevData()" (keypress)="prevData()"
								class="app-c-btn app-c-btn--secondary app-c-btn--curve app-c-btn--with-icon" translate>
								<svg-icon icon="arrow-left"></svg-icon>
								<span> {{ "dynamicForm.back" | translate }}</span>
							</button>
						</div>
						<div class="app-c-stepper__footer__right">
							<div class="app-c-stepper__footer__right">
								<button class="app-c-btn app-c-btn--primary app-c-btn--curve" (click)="nextData()"
									(keypress)="nextData()" *ngIf="currentDataIndex < formData.length - 1"
									[disabled]="!dynamicForm.get(data['Key']).valid" translate>
									{{ "dynamicForm.continue" | translate }}
									<svg-icon icon="arrow-right"></svg-icon>
								</button>
							</div>
							<button
								class="app-c-btn app-c-btn--primary app-c-btn--with-icon app-c-btn--curve app-c-btn--with-icon"
								*ngIf="currentDataIndex === formData.length - 1 "
								[disabled]="!dynamicForm.get(data['Key']).valid" (click)="doSave()" translate>
								<span translate> dynamicForm.finish </span>
								<svg-icon icon="arrow-right"></svg-icon>
							</button>
						</div>
					</div>
					<!--FOOTER END-->
				</div>
			</form>
		</ng-template>
		<!--USER SURVEY AND DEMOGRAPHIC END-->
	</div>
</div>
