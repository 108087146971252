<page-loader *ngIf="!isLoaded"></page-loader>
<div *ngIf="isLoaded" class="app-l-page-wrap app-l-tile-view__wrap">
	<div class="app-l-page__navigation">
		<div class="app-l-page__previous">
			<button  *ngIf="user.userType != 'user' && showadmincreated" [routerLink]="['/professional/user-details-dashboard/'+ this.endUserId]"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				tabindex="0"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
			<button  *ngIf="!showadmincreated" [routerLink]="['/app/practice']"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
				tabindex="0"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li *ngIf="user.userType != 'user' && showadmincreated" [routerLink]="['/professional/user-details-dashboard/'+ this.endUserId]" translate>menu.clientList</li>
				<li *ngIf="user.userType === 'user' && !showadmincreated" [routerLink]="['/app/practice']" translate>menu.tools</li>
				<li *ngIf="user.userType === 'user' || (user.userType != 'user' && !showadmincreated)" [routerLink]="['/app/practice/action-plan']" translate>actionPlan.actionPlan</li>
				<li
					tabindex="0"
					*ngIf="prevPage == 'submit'"
					(click)="edit(0)"
					translate
				>
					actionPlan.create
				</li>
				<li>{{ actionPlanPreviewData?.title }}</li>
			</ul>
		</div>
	</div>
	<div class="app-c-card app-l-action-plan-preview-header">
		<div class="app-c-card__header">
			<div class="app-l-card__h-left">
				<div class="app-l-card__titlewrap">
					<h2 class="app-l-card__title">
						{{ actionPlanPreviewData?.title }}
					</h2>
					<svg-icon  *ngIf="user.userType === 'user' || (user.userType != 'user' && !actionPlanPreviewData?.isAdminCreated) || prevPage === 'submit'"
						tabindex="0"
						icon="edit"
						(click)="edit(0)"
						(keypress)="edit(0)"
					></svg-icon>
				</div>
				<div class="app-l-ac__time">
					<div class="app-l-ac__time-print">
						<span translate>actionPlan.createdOn</span>
						{{
							actionPlanId
								? (actionPlanPreviewData.created
								  | date : "MMM dd, yyyy")
								: (currentDate | date : "MMM dd, yyyy")
						}}
					</div>
				</div>
			</div>
			<div class="app-l-card__h-right">
				<button
					tabindex="0"
					(click)="deleteActionPlan()"  *ngIf="user.userType === 'user' || (user.userType != 'user' && !actionPlanPreviewData?.isAdminCreated) || prevPage === 'submit'"
					class="app-c-btn app-c-btn--secondary app-c-btn--curve"
				>
					<svg-icon icon="delete"></svg-icon>
					<span translate>actionPlan.delete</span>
				</button>
				<button
					tabindex="0"
					(click)="exportActionPlan()"
					class="app-c-btn app-c-btn--primary app-c-btn--curve"
				>
					<svg-icon icon="export"></svg-icon>
					<span translate>actionPlan.export</span>
				</button>
			</div>
		</div>
	</div>
	<div class="app-l-room__tabs">
		<div class="app-dm-positive-cards-wrapper">
			<div
				class="app-dm-positive-cards"
				*ngFor="
					let question of actionPlanQuestionData;
					let qnIndex = index
				"
			>
				<div class="app-l-action-plan__tile-header">
					<h3>{{ question.questionTitle }}</h3>
					<button  *ngIf="user.userType === 'user' || (user.userType != 'user' && !actionPlanPreviewData?.isAdminCreated) || prevPage === 'submit'"
						tabindex="0"
						(click)="edit(question.id)"
						class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
					>
						<span translate>actionPlan.edit</span>
					</button>
				</div>
				<div
					class="app-l-action-plan__tile-body"
					*ngIf="question.isAddMore"
				>
					<ng-container
						*ngFor="let answer of question.groupAddAnswerList"
					>
						<div
							*ngIf="!answer.isGroupAdd"
							class="add-more-content"
						>
							<p
								class="card-content-text"
								[innerHTML]="
									answer.items.value || '' | newlineToBr
								"
							></p>
						</div>
						<div *ngIf="answer.isGroupAdd">
							<ul>
								<li
									*ngFor="
										let item of answer?.items;
										let i = index
									"
								>
									<div *ngIf="item.value">
										<h5
											*ngIf="
												question.showEmergencyContact
											"
										>
											{{ item.value }}
										</h5>
										<h5
											*ngIf="
												i == 0 &&
												answer?.items.length > 1
											"
										>
											{{ item.value }}
										</h5>
										<p
											*ngIf="
												i == 0 &&
												answer?.items.length == 1
											"
											[innerHTML]="
												item.value || '' | newlineToBr
											"
										></p>
										<p
											*ngIf="
												i > 0 &&
												answer?.items.length > 1
											"
											[innerHTML]="
												item.value || '' | newlineToBr
											"
										></p>
									</div>
								</li>
							</ul>
						</div>
					</ng-container>
				</div>
				<div
					class="app-l-action-plan__tile-body"
					*ngIf="!question.isAddMore"
				>
					<ul>
						<li *ngFor="let option of question.options">
							<div *ngIf="option.value">
								<h5 *ngIf="question.showEmergencyContact">
									{{ option.optionTitle }}
								</h5>
								<p
									[innerHTML]="
										option.value || '' | newlineToBr
									"
								></p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="app-c-card__footer" *ngIf="prevPage == 'submit'">
		<div class="app-l-aq__f-wrap">
			<div class="app-l-aq__f-right">
				<button
					tabindex="0"
					(click)="submitActionPlan()"
					class="app-c-btn app-c-btn--primary app-c-btn--curve"
				>
					<span translate>actionPlan.finish</span>
					<svg-icon icon="arrow-right"></svg-icon>
				</button>
			</div>
		</div>
	</div>
</div>
