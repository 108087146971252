import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nmconnect-component',
  templateUrl: './nmconnect.component.html',
  styleUrls: ['./nmconnect.component.scss'],
})
export class NMConnectPage implements OnInit {

  appStore: string;
  playStore: string;

  constructor() { }

  ngOnInit() {
    this.appStore = 'https://apps.apple.com/us/app/nmconnect/id1505881354';
    this.playStore = 'https://play.google.com/store/apps/details?id=com.nmcrisisline.app';
  }

}
