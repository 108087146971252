<div class="app-l-page-wrap">
    <page-loader *ngIf="!isLoaded"></page-loader>
    <div class="app-l-main-heading" *ngIf="isLoaded">
        <h2 translate>menu.assessments</h2>
        <div class="app-l__actions-block">
            <button
                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                (click)="showAssignModal('true')"
                translate>
                <img
                    alt="Assign"
                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                <span translate>clientList.assign</span>
            </button>
            <div class="app-c-mat-select">
                <img alt="Assign" src="./assets/img/icons-svg/calendarV1.svg" />
                <mat-select
                    [(ngModel)]="dateRangeSelected"
                    class="app-l-dsh__rfp-select"
                    panelClass="app-l-dsh__rfp-panel"
                    (selectionChange)="onRangeChange()">
                    <mat-option
                        value="proud"
                        *ngFor="let days of dateRange"
                        translate
                        [value]="days.value"
                        >{{ days.title }}</mat-option
                    >
                </mat-select>
            </div>
        </div>
    </div>
    <div class="app-dm-das-assessment-list" *ngIf="isLoaded">
        <page-user-event-assign
            *ngIf="showEventAssignModal"
            [eventType]="'assessment'"
            [selectedUser]="userDetail"
            [disableUserList]="true"
            [disableEventList]="true"
            class="app-l-modecheck__sidepanel"
            [userList]="users"
            (closeModal)="onCloseOptions($event)"></page-user-event-assign>
        <div class="app-l-summary__base">
            <div class="app-l-result-summary">
                <div class="app-l-result__card-dm">
                    <div class="app-c-card__header">
                        <div class="title-section app-l-card__h-left">
                            <h2 class="initial-image">
                                {{ getInitials(userDetail?.fullName) }}
                            </h2>
                            <h4>
                                {{ userDetail?.fullName }}<br />
                                <p>{{ userDetail?.email }}</p>
                            </h4>
                        </div>
                    </div>
                    <div class="demo-result-card">
                        <div class="demo-val">
                            <p translate>clientList.age</p>
                            <h4>
                                {{
                                    demographicData?.Age
                                        ? demographicData?.Age
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.ethinicity</p>
                            <h4>
                                {{
                                    demographicData?.Ethnicity
                                        ? demographicData?.Ethnicity
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gender</p>
                            <h4>
                                {{
                                    demographicData?.Gender
                                        ? demographicData?.Gender
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.gradLevel</p>
                            <h4>
                                {{
                                    demographicData?.GradeLevel
                                        ? demographicData?.GradeLevel
                                        : '-'
                                }}
                            </h4>
                        </div>
                        <div class="demo-val">
                            <p translate>clientList.referredBy</p>
                            <h4>
                                {{
                                    demographicData?.Referred
                                        ? demographicData?.Referred
                                        : '-'
                                }}
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card-dm">
                    <div class="app-l-result__card-header">
                        <h3 class="app-c-heading--h3" translate>
                            clientList.snapshot
                        </h3>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-completed-assessment">
                            <p translate>
                                clientList.assessment.completedAssessment
                            </p>
                            <h2>
                                {{
                                    countData?.assessment.dass.count
                                        ? countData?.assessment.dass.count
                                        : 0
                                }}
                                DASS <span translate>menu.assessments</span>
                            </h2>
                        </div>
                        <div class="app-l-additional-assessment">
                            <p translate>
                                clientList.assessment.additionalAssessment
                            </p>
                            <div
                                class="additional-val"
                                *ngFor="
                                    let name of assessmentNames.slice(1);
                                    let i = index
                                ">
                                <ng-container>
                                    <p>
                                        {{
                                            getCountByName(name)?.count
                                                ? getCountByName(name)?.count
                                                : 0
                                        }}
                                    </p>
                                    <p>{{ name }}</p>
                                    <p>
                                        {{
                                            getCountByName(name)?.count > 0
                                                ? getLevel(
                                                      name,
                                                      getCountByName(name)
                                                          ?.score
                                                  )
                                                : ''
                                        }}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="assessment-chart">
                    <div class="app-l-result__card">
                        <div class="app-l-result__card-header">
                            <div class="app-l-result__card-header-left">
                                <h3 class="app-c-heading--h3" translate>
                                    das.dasResultSummary
                                </h3>
                            </div>
                            <div class="app-l-result__card-header-right">
                                <!-- <mat-form-field appearance="fill">
                                    <mat-select [(value)]="selectedFilter"
                                        (selectionChange)="onSelectFilterData($event.value, option)">
                                        <mat-option *ngFor="let option of dateFilterOptions" [value]="option">
                                            {{"das.dateFilter." + option.key
                                            | translate}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                                <div class="app-l-chart__legends">
                                    <button
                                        *ngIf="typeAssessment === 'dass'"
                                        [ngClass]="{
                                            active:
                                                selectedChartLegend === 'all'
                                        }"
                                        (click)="selectLegend('all')"
                                        translate>
                                        das.all
                                    </button>
                                    <button
                                        class="app-l-legend__depression"
                                        *ngIf="
                                            typeAssessment === 'dass' ||
                                            typeAssessment === 'depression'
                                        "
                                        [ngClass]="{
                                            active:
                                                selectedChartLegend ===
                                                'depression'
                                        }"
                                        (click)="selectLegend('depression')"
                                        translate>
                                        das.depression
                                    </button>
                                    <button
                                        class="app-l-legend__anxity"
                                        *ngIf="
                                            typeAssessment === 'dass' ||
                                            typeAssessment === 'anxiety'
                                        "
                                        [ngClass]="{
                                            active:
                                                selectedChartLegend === 'anxity'
                                        }"
                                        (click)="selectLegend('anxity')"
                                        translate>
                                        das.anxiety
                                    </button>
                                    <button
                                        class="app-l-legend__stress"
                                        *ngIf="typeAssessment === 'dass'"
                                        [ngClass]="{
                                            active:
                                                selectedChartLegend === 'stress'
                                        }"
                                        (click)="selectLegend('stress')"
                                        translate>
                                        das.stress
                                    </button>
                                    <button
                                        class="app-l-legend__resilience"
                                        *ngIf="typeAssessment === 'resilience'"
                                        [ngClass]="{
                                            actve:
                                                selectedChartLegend ===
                                                'resilience'
                                        }"
                                        (click)="selectLegend('resilience')"
                                        translate>
                                        {{ assessmentTranslation.resilience }}
                                    </button>
                                    <button
                                        class="app-l-legend__alcoholuse"
                                        *ngIf="typeAssessment === 'alcoholuse'"
                                        [ngClass]="{
                                            actve:
                                                selectedChartLegend ===
                                                'alcoholuse'
                                        }"
                                        (click)="selectLegend('alcoholuse')"
                                        translate>
                                        {{ assessmentTranslation.alcoholuse }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="app-l-result__card-body">
                            <div class="chart-container">
                                <canvas
                                    #dasAssessmentChart
                                    id="dasAssessmentChart"></canvas>
                            </div>
                        </div>
                        <div class="app-l-assessment-results">
                            <div class="title-section">
                                <h3 translate>
                                    clientList.assessment.recentHighScore
                                </h3>
                                <a
                                    class="action-view"
                                    [routerLink]="
                                        '/app/assessment/dass/listing/' + id
                                    "
                                    translate
                                    >clientList.viewAll</a
                                >
                            </div>
                            <div class="app-l-result__card">
                                <div
                                    class="app-l-result-item__base"
                                    *ngIf="assessments?.length !== 0">
                                    <div
                                        class="app-l-result-item__card-block"
                                        *ngFor="
                                            let assessment of assessments
                                                | paginate
                                                    : {
                                                          itemsPerPage:
                                                              itemsPerPage,
                                                          currentPage:
                                                              currentPage,
                                                          totalItems: totalCount
                                                      };
                                            let i = index
                                        ">
                                        <div
                                            class="app-l-result-item__card-header">
                                            <div class="app-l-result-item__hl">
                                                <div
                                                    class="app-l-result-item__icon">
                                                    <svg-icon
                                                        icon="calendar1"
                                                        class="w-100 h-100"></svg-icon>
                                                </div>
                                                <h3
                                                    class="app-c-body-text--14-m">
                                                    {{
                                                        assessment.created
                                                            | date: 'MM-dd-yyyy'
                                                    }}
                                                </h3>
                                                <span
                                                    class="app-l-result-item__time">
                                                    ({{
                                                        assessment.created
                                                            | date: 'h:mm a'
                                                    }})
                                                </span>
                                                <!-- <span class="app-l-result-item__badge">
                                                    Very High
                                                </span> -->
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-result-item__card-body">
                                            <div
                                                class="app-l-rs__item-base"
                                                *ngIf="
                                                    typeAssessment === 'dass'
                                                ">
                                                <div
                                                    class="app-l-rs__item-block">
                                                    <h6
                                                        class="app-l-rs__title"
                                                        translate>
                                                        das.tableHeaders.0
                                                    </h6>
                                                    <p class="app-l-rs__status">
                                                        {{
                                                            assessment.depressionlevellabel
                                                        }}
                                                    </p>
                                                    <ng-container
                                                        [ngTemplateOutlet]="
                                                            daslevelIndicationImg
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            level: assessment.depressionlevel
                                                        }">
                                                    </ng-container>
                                                </div>
                                                <div
                                                    class="app-l-rs__item-block">
                                                    <h6
                                                        class="app-l-rs__title"
                                                        translate>
                                                        das.tableHeaders.1
                                                    </h6>
                                                    <p class="app-l-rs__status">
                                                        {{
                                                            assessment.anxietylevellabel
                                                        }}
                                                    </p>
                                                    <ng-container
                                                        [ngTemplateOutlet]="
                                                            daslevelIndicationImg
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            level: assessment.anxietylevel
                                                        }">
                                                    </ng-container>
                                                </div>
                                                <div
                                                    class="app-l-rs__item-block">
                                                    <h6
                                                        class="app-l-rs__title"
                                                        translate>
                                                        das.tableHeaders.2
                                                    </h6>
                                                    <p class="app-l-rs__status">
                                                        {{
                                                            assessment.stresslevellabel
                                                        }}
                                                    </p>
                                                    <ng-container
                                                        [ngTemplateOutlet]="
                                                            daslevelIndicationImg
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            level: assessment.stresslevel
                                                        }">
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <div
                                                class="app-l-rs__item-base"
                                                *ngIf="
                                                    typeAssessment != 'dass'
                                                ">
                                                <div
                                                    class="app-l-rs__item-block">
                                                    <h6
                                                        class="app-l-rs__title"
                                                        translate>
                                                        {{
                                                            assessmentTranslation[
                                                                typeAssessment
                                                            ]
                                                        }}
                                                    </h6>
                                                    <p class="app-l-rs__status">
                                                        {{
                                                            getLevel(
                                                                assessment.type,
                                                                assessment.resultScore
                                                            )
                                                        }}
                                                    </p>
                                                    <ng-container
                                                        [ngTemplateOutlet]="
                                                            daslevelIndicationImg
                                                        "
                                                        [ngTemplateOutletContext]="{
                                                            level: assessmentService.getAssessmentScoreLevel(
                                                                assessment.type,
                                                                assessment.resultScore
                                                            )
                                                        }">
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="app-l-result-item__base"
                                    *ngIf="assessments?.length === 0">
                                    <p translate>clientList.noData</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment-results-percentage">
                <div class="app-l-result__card">
                    <div class="title-section">
                        <h3 translate>clientList.assessment.dassAssessments</h3>
                        <a
                            translate
                            class="action-view"
                            [routerLink]="'/app/assessment/dass/listing/' + id"
                            >clientList.viewAll</a
                        >
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <div class="title-section">
                                    <h4 translate>
                                        clientList.assessment.mostRecentDass
                                    </h4>
                                    <a
                                        *ngIf="
                                            assessmentMostRecent?.StressScore.level ||
                                            assessmentMostRecent?.AnxietyScore.level ||
                                            assessmentMostRecent?.DepressionScore.level
                                        "
                                        translate=""
                                        class="action-view"
                                        [routerLink]="[
                                            '/app/assessment/result',
                                            assessmentMostRecent?.ID,
                                            'dass',
                                            id
                                        ]"
                                        [queryParams]="{
                                            prevpage: 'clientlist'
                                        }"
                                        ><svg-icon
                                            icon="right-nav-arrow"
                                            class="w-100 h-100"></svg-icon
                                    ></a>
                                </div>
                                <div class="app-l-result-item__card-body">
                                    <div
                                        class="app-l-rs__item-base app-l-da__item-base">
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.depression
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentMostRecent
                                                        ?.DepressionScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentMostRecent
                                                        ?.DepressionScore
                                                        .position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentMostRecent?.Depression
                                                        ? assessmentMostRecent?.Depression
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.anxiety
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentMostRecent
                                                        ?.AnxietyScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentMostRecent
                                                        ?.AnxietyScore.position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentMostRecent?.Anxiety
                                                        ? assessmentMostRecent?.Anxiety
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.stress
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentMostRecent
                                                        ?.StressScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentMostRecent
                                                        ?.StressScore.position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentMostRecent?.stress
                                                        ? assessmentMostRecent?.stress
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <div class="title-section">
                                    <h4 translate>
                                        clientList.assessment.initialDASS
                                    </h4>
                                    <a
                                        *ngIf="
                                            assessmentInitial?.StressScore.level ||
                                            assessmentInitial?.AnxietyScore.level ||
                                            assessmentInitial?.DepressionScore.level
                                        "
                                        translate=""
                                        class="action-view"
                                        [routerLink]="[
                                            '/app/assessment/result',
                                            assessmentInitial?.ID,
                                            'dass',
                                            id
                                        ]"
                                        [queryParams]="{
                                            prevpage: 'clientlist'
                                        }"
                                        ><svg-icon
                                            icon="right-nav-arrow"
                                            class="w-100 h-100"></svg-icon
                                    ></a>
                                </div>

                                <div class="app-l-result-item__card-body">
                                    <div
                                        class="app-l-rs__item-base app-l-da__item-base">
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.depression
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentInitial
                                                        ?.DepressionScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentInitial
                                                        ?.DepressionScore
                                                        .position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentInitial?.Depression
                                                        ? assessmentInitial?.Depression
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.anxiety
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentInitial
                                                        ?.AnxietyScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentInitial
                                                        ?.AnxietyScore.position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentInitial?.Anxiety
                                                        ? assessmentInitial?.Anxiety
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.stress
                                            </h6>
                                            <h3 class="app-l-rs__status">
                                                {{
                                                    assessmentInitial
                                                        ?.StressScore.level
                                                }}
                                            </h3>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentInitial
                                                        ?.StressScore.position
                                                }">
                                            </ng-container>
                                            <h3 class="app-l-rs__count">
                                                {{
                                                    assessmentInitial?.stress
                                                        ? assessmentInitial?.stress
                                                        : 0
                                                }}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="app-l-result__card-body app-l-mr__item-base">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <div class="title-section">
                                    <h4 translate>
                                        clientList.assessment.mostRecent
                                    </h4>
                                </div>

                                <div class="app-l-result-item__card-body">
                                    <div class="app-l-rs__item-base">
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.depression
                                            </h6>
                                            <div class="card-result-ms">
                                                <p>
                                                    {{
                                                        Math.abs(
                                                            depressionDifference
                                                        )
                                                    }}
                                                </p>
                                                <div
                                                    class="card-ms-status-right">
                                                    <p>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.Depression >=
                                                                assessmentMostRecent?.Depression
                                                            "
                                                            icon="improved"></svg-icon>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.Depression <
                                                                assessmentMostRecent?.Depression
                                                            "
                                                            icon="declined"></svg-icon>
                                                        {{
                                                            depressionChange !=
                                                            'Infinity'
                                                                ? Math.round(
                                                                      Math.abs(
                                                                          depressionChange
                                                                      )
                                                                  ) + '%'
                                                                : '100%'
                                                        }}
                                                    </p>
                                                    <div
                                                        class="card-ms-status"
                                                        [ngClass]="
                                                            assessmentInitial?.Depression >=
                                                            assessmentMostRecent?.Depression
                                                                ? 'improved'
                                                                : 'declined'
                                                        ">
                                                        {{
                                                            assessmentInitial?.Depression >=
                                                            assessmentMostRecent?.Depression
                                                                ? 'Improved'
                                                                : 'Declined'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.anxiety
                                            </h6>
                                            <div class="card-result-ms">
                                                <p>
                                                    {{
                                                        Math.abs(
                                                            anxietyDifference
                                                        )
                                                    }}
                                                </p>
                                                <div
                                                    class="card-ms-status-right">
                                                    <p>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.Anxiety >=
                                                                assessmentMostRecent?.Anxiety
                                                            "
                                                            icon="improved"></svg-icon>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.Anxiety <
                                                                assessmentMostRecent?.Anxiety
                                                            "
                                                            icon="declined"></svg-icon>
                                                        {{
                                                            anxietyChange !=
                                                            'Infinity'
                                                                ? Math.round(
                                                                      Math.abs(
                                                                          anxietyChange
                                                                      )
                                                                  ) + '%'
                                                                : '100%'
                                                        }}
                                                    </p>
                                                    <div
                                                        class="card-ms-status"
                                                        [ngClass]="
                                                            assessmentInitial?.Anxiety >=
                                                            assessmentMostRecent?.Anxiety
                                                                ? 'improved'
                                                                : 'declined'
                                                        ">
                                                        {{
                                                            assessmentInitial?.Anxiety >=
                                                            assessmentMostRecent?.Anxiety
                                                                ? 'Improved'
                                                                : 'Declined'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                clientList.assessment.stress
                                            </h6>
                                            <div class="card-result-ms">
                                                <p>
                                                    {{
                                                        Math.abs(
                                                            stressDifference
                                                        )
                                                    }}
                                                </p>
                                                <div
                                                    class="card-ms-status-right">
                                                    <p>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.stress >=
                                                                assessmentMostRecent?.stress
                                                            "
                                                            icon="improved"></svg-icon>
                                                        <svg-icon
                                                            *ngIf="
                                                                assessmentInitial?.stress <
                                                                assessmentMostRecent?.stress
                                                            "
                                                            icon="declined"></svg-icon>
                                                        {{
                                                            stressChange !=
                                                            'Infinity'
                                                                ? Math.round(
                                                                      Math.abs(
                                                                          stressChange
                                                                      )
                                                                  ) + '%'
                                                                : '100%'
                                                        }}
                                                    </p>
                                                    <div
                                                        class="card-ms-status"
                                                        [ngClass]="
                                                            assessmentInitial?.stress >=
                                                            assessmentMostRecent?.stress
                                                                ? 'improved'
                                                                : 'declined'
                                                        ">
                                                        {{
                                                            assessmentInitial?.stress >=
                                                            assessmentMostRecent?.stress
                                                                ? 'Improved'
                                                                : 'Declined'
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="app-l-result__card app-l-assessment-assignments">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                clientList.assessment.assessmentAssignments
                            </h3>
                        </div>
                        <div
                            class="app-l-result__card-header-right"
                            *ngIf="assignedListLoaded">
                            <button
                                class="app-c-btn app-c-btn--primary-v2 app-c-btn--curve"
                                (click)="showAssignModal('true')"
                                translate>
                                <img
                                    alt="Assign"
                                    src="./assets/img/icons-svg/assign-user-icon.svg" />
                                <span translate>clientList.assign</span>
                            </button>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div class="app-l-result-item__base">
                            <div class="app-l-result-item__card-block">
                                <ng-container
                                    *ngIf="!assignedListLoaded"
                                    [ngTemplateOutlet]="skeletonLoader"
                                    [ngTemplateOutletContext]="{
                                        height: '100px'
                                    }">
                                </ng-container>
                                <mat-tab-group
                                    *ngIf="assignedListLoaded"
                                    mat-align-tabs="center"
                                    #tabGroup
                                    (focusChange)="onTabChanged($event)"
                                    [selectedIndex]="0">
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <div translate>
                                                clientList.assigned
                                            </div>
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let assigned of assignedList
                                                ">
                                                <div>
                                                    <!-- <span
                                                        *ngIf="
                                                            assigned.Type ===
                                                                'assessment';
                                                            else otherType
                                                        "
                                                        translate>
                                                        {{ 'DASS ' }}
                                                    </span> -->
                                                    <span translate>
                                                        {{
                                                            assigned.Name
                                                        }}</span
                                                    >
                                                </div>
                                                <ng-template #otherType>
                                                    <span translate>
                                                        {{
                                                            assigned.Type + ' '
                                                        }}
                                                    </span>
                                                </ng-template>
                                                <!-- <div translate>
                                                    clientList.createdOn
                                                </div> -->
                                                <div translate>
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>

                                                    {{
                                                        assigned.Date
                                                            | date: 'longDate'
                                                    }}
                                                </div>
                                                <div>
                                                    <button
                                                        tabindex="0"
                                                        class="app-c-btn app-c-btn--icon-primary app-c-btn--icon app-c-btn--icon-20"
                                                        (click)="deleteAssign(assigned.ID)"
                                                        (keypress)="deleteAssign(assigned.ID)">
                                                        <svg-icon
                                                            icon="trash"
                                                            class="w-100 h-100"
                                                            alt="trash"></svg-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showAssigned &&
                                                assignedList.length == '0'
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                    <mat-tab>
                                        <ng-template mat-tab-label>
                                            <span translate
                                                >clientList.completed</span
                                            >
                                        </ng-template>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completedList.length != 0
                                            ">
                                            <div
                                                class="app-l-assignment-ls"
                                                *ngFor="
                                                    let completed of completedList
                                                ">
                                                <div>
                                                    <!-- <span
                                                        *ngIf="
                                                            completed.Type ===
                                                                'assessment';
                                                            else otherType
                                                        "
                                                        translate>
                                                        {{ 'DASS ' }}
                                                    </span> -->
                                                    <span translate>
                                                        {{
                                                            completed.Name
                                                        }}</span
                                                    >
                                                </div>
                                                <ng-template #otherType>
                                                    <div translate>
                                                        <span>{{
                                                            completed.Type + ' '
                                                        }}</span>
                                                        clientList.assessment.title
                                                    </div>
                                                </ng-template>
                                                <div translate>
                                                    <div class="icon">
                                                        <svg-icon
                                                            icon="time"></svg-icon>
                                                    </div>

                                                    {{
                                                        completed.UpdatedOnUtc
                                                            | date: 'longDate'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="app-l-assignment-ls-container"
                                            *ngIf="
                                                showCompleted &&
                                                completedList.length == 0
                                            ">
                                            <div class="app-l-assignment-ls">
                                                <div>
                                                    <h3
                                                        translate
                                                        class="nodata-title">
                                                        clientList.noData
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-level="level" #daslevelIndicationImg>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high"
        *ngIf="level == 4">
        <div class="app-c-welltrack__progress-fill" style="width: 65%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__high"
        *ngIf="level == 3">
        <div class="app-c-welltrack__progress-fill" style="width: 50%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__moderate"
        *ngIf="level == 2">
        <div class="app-c-welltrack__progress-fill" style="width: 25%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__mild"
        *ngIf="level == 1">
        <div class="app-c-welltrack__progress-fill" style="width: 10%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__good"
        *ngIf="level == 0">
        <div class="app-c-welltrack__progress-fill" style="width: 35%"></div>
    </div>
</ng-template>

<ng-template let-height="height" #skeletonLoader>
    <mat-card class="skeleton-card" [ngStyle]="{ height: height }">
        <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card>
</ng-template>
