<div class="form-modal">
    <mat-dialog-content>
        <a (click)="onClose()" (keypress)="onClose()" class="close-modal"
            aria-label="Close push notificatioin edit form">
            <img src="./assets/img/icons-svg/close-icon.svg" />
        </a>
        <div class="img-wrapper">
            <div class="clearfix"></div>
            <div class="title-wrapper">
                <h1>{{ title }}</h1>
            </div>
        </div>
        <form novalidate [formGroup]="resTableViewForm" (ngSubmit)="doSave()">
            <div id="resource-section" class="form-wrapper-80">
                <h3 *ngIf="finalData[0]['dataToAdd'].length > 0">To be added</h3>
                <table mat-table [dataSource]="finalData[0]['dataToAdd']" class="mat-elevation-z8 import-list"
                    *ngIf="finalData[0]['dataToAdd'].length > 0">

                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef> <mat-checkbox (change)="selectAllDataToAdd($event)"
                                [checked]="allChecked"></mat-checkbox><span class="resource-select">Select All</span>
                        </th>
                        <td mat-cell *matCellDef="let row let rowIndex = index">
                            <mat-checkbox (change)="selectDataToAdd($event,row)"
                                [checked]="row.data.status === true ? true : false"></mat-checkbox>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="resource">
                        <th mat-header-cell *matHeaderCellDef> Resource </th>
                        <td mat-cell *matCellDef="let element let rowIndex = index">{{element.data.title}}</td>
                    </ng-container>

                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <h3 *ngIf="finalData[0]['dataToUpdate'].length > 0">To be replaced</h3>
                <table mat-table [dataSource]="this.finalData[0]['dataToUpdate']" class="mat-elevation-z8 import-list"
                    *ngIf="finalData[0]['dataToUpdate'].length > 0">

                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef> <mat-checkbox (change)="selectAllDataToUpdate($event)"
                                [checked]="allCheckedUpdate"></mat-checkbox><span class="resource-select">Select
                                All</span> </th>
                        <td mat-cell *matCellDef="let row let rowIndex = index">
                            <mat-checkbox (change)="selectDataToUpdate($event,row)"
                                [checked]="row.data.status === true ? true : false"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="resource">
                        <th mat-header-cell *matHeaderCellDef> Resource </th>
                        <td mat-cell *matCellDef="let element let rowIndex = index">{{element.data.title}}</td>
                    </ng-container>

                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <h3 *ngIf="finalData[0]['dataToDelete'].length > 0">To be deleted</h3>
                <table mat-table [dataSource]="this.finalData[0]['dataToDelete']" class="mat-elevation-z8 import-list"
                    *ngIf="finalData[0]['dataToDelete'].length > 0">

                    <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="selectAllDataToDelete($event)"
                                [checked]="allCheckedDelete"></mat-checkbox><span class="resource-select">Select
                                All</span>
                        </th>
                        <td mat-cell *matCellDef="let row let rowIndex = index">
                            <mat-checkbox (change)="selectDataToDelete($event,row)"
                                [checked]="row.data.status === true ? true : false"></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="resource">
                        <th mat-header-cell *matHeaderCellDef> Resource </th>
                        <td mat-cell *matCellDef="let element let rowIndex = index">{{element.data.title}}</td>
                    </ng-container>

                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef> Category </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <div class="fixed-footer">
                <button tabindex="0" class="btn-primary btn">
                    Save
                </button>
            </div>
        </form>
    </mat-dialog-content>
</div>