<div class="app-l-page-wrap">
    <page-loader *ngIf="!isLoaded"></page-loader>
    <div class="app-l-page__navigation">
        <div class="app-l-page__previous" (click)="goBack()">
            <button
                class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20">
                <svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
            </button>
        </div>
        <div class="app-l-page__breadcrumbs">
            <ul>
                <li
                    translate
                    *ngIf="loggedInUser.userType === 'professional'"
                    (click)="goBack()">
                    clientList.userDashboard
                </li>
                <li
                    translate
                    *ngIf="loggedInUser.userType === 'user'"
                    routerLink="/app/assessments/listing">
                    assessmentTypeListing.tabTitle
                </li>
                <li class="app-l-current-page" translate>
                    {{ 'assessments.' + typeAssessment + '.title' }}
                </li>
            </ul>
        </div>
    </div>
    <div class="app-dm-das-assessment-list" *ngIf="isLoaded">
        <div class="app-l-summary__base">
            <div class="app-l-result-summary">
                <div class="app-l-result__card">
                    <div class="app-l-result__card-header">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                das.resultsSummary
                            </h3>
                            <div *ngIf="user.userType === 'user'">
                                <h2
                                    class="recent-tabs-disclaimer"
                                    translate
                                    *ngIf="!showConnect()">
                                    <span
                                        [innerHtml]="
                                            'dynamicAssessment.disclaimerDesc1.0'
                                                | translate
                                        "></span>
                                    <emergency-contact
                                        [showAsButton]="true"
                                        [isTemplateView]="true"
                                        [templateType]="'assessmentDisclaimer'"
                                        [cssClass]="'assessmentDisclaimer'"
                                        [user]="user"></emergency-contact>
                                    <span
                                        [innerHtml]="
                                            'dynamicAssessment.disclaimerDesc1.1'
                                                | translate
                                        "></span>
                                </h2>
                                <h2
                                    class="recent-tabs-disclaimer"
                                    *ngIf="showConnect()"
                                    translate>
                                    <span
                                        [innerHtml]="
                                            'dynamicAssessment.disclaimerDesc2.0'
                                                | translate
                                        "></span>
                                    <emergency-contact
                                        [showAsButton]="true"
                                        [isTemplateView]="true"
                                        [templateType]="'assessmentDisclaimer'"
                                        [cssClass]="'assessmentDisclaimer'"
                                        [user]="user"></emergency-contact>
                                    <span
                                        [innerHtml]="
                                            'dynamicAssessment.disclaimerDesc2.1'
                                                | translate
                                        "></span>
                                    <a
                                        class="course-link-title"
                                        href="https://welltrack-connect.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        translate
                                        >theory.welltrackConnect</a
                                    >
                                </h2>
                            </div>
                        </div>
                        <div
                            class="app-l-result__card-header-right"
                            *ngIf="
                                loggedInUser.userType === 'user' ||
                                (loggedInUser.userType != 'user' && !syncUserId)
                            ">
                            <button
                                class="app-c-btn app-c-btn--primary app-c-btn--curve"
                                [routerLink]="
                                    '/app/assessments/' +
                                    typeAssessment +
                                    '/new'
                                "
                                translate>
                                das.startAssessment
                            </button>
                        </div>
                    </div>

                    <div class="app-l-result__card-body">
                        <div class="app-l-result-summary__control">
                            <div class="app-l-result-summary__tab">
                                <div class="app-c-tab--primary">
                                    <nav
                                        mat-tab-nav-bar
                                        [backgroundColor]="background"
                                        [tabPanel]="tabPanel">
                                        <a
                                            mat-tab-link
                                            (click)="
                                                onSelectFilterData(
                                                    dateFilterOptions[0]
                                                )
                                            "
                                            [active]="
                                                activeLink ==
                                                dateFilterOptions[0].key
                                            "
                                            translate
                                            >{{
                                                'das.dateFilter.' +
                                                    dateFilterOptions[0].key
                                                    | translate
                                            }}</a
                                        >
                                        <a
                                            mat-tab-link
                                            (click)="
                                                onSelectFilterData(
                                                    dateFilterOptions[1]
                                                )
                                            "
                                            [active]="
                                                activeLink ==
                                                dateFilterOptions[1].key
                                            "
                                            translate
                                            >{{
                                                'das.dateFilter.' +
                                                    dateFilterOptions[1].key
                                                    | translate
                                            }}</a
                                        >
                                        <a
                                            mat-tab-link
                                            (click)="
                                                onSelectFilterData(
                                                    dateFilterOptions[2]
                                                )
                                            "
                                            [active]="
                                                activeLink ==
                                                dateFilterOptions[2].key
                                            "
                                            translate
                                            >{{
                                                'das.dateFilter.' +
                                                    dateFilterOptions[2].key
                                                    | translate
                                            }}</a
                                        >
                                    </nav>
                                    <mat-tab-nav-panel
                                        #tabPanel></mat-tab-nav-panel>
                                </div>
                            </div>
                        </div>
                        <div class="chart-container">
                            <canvas
                                #dasAssessmentChart
                                id="dasAssessmentChart"></canvas>
                        </div>
                        <div class="app-l-chart__legends">
                            <button
                                *ngIf="typeAssessment === 'dass'"
                                [ngClass]="{
                                    active: selectedChartLegend === 'all'
                                }"
                                (click)="selectLegend('all')"
                                translate>
                                das.all
                            </button>
                            <button
                                class="app-l-legend__depression"
                                *ngIf="
                                    typeAssessment === 'dass' ||
                                    typeAssessment === 'depression'
                                "
                                [ngClass]="{
                                    active: selectedChartLegend === 'depression'
                                }"
                                (click)="selectLegend('depression')"
                                translate>
                                das.depression
                            </button>
                            <button
                                class="app-l-legend__anxity"
                                *ngIf="
                                    typeAssessment === 'dass' ||
                                    typeAssessment === 'anxiety'
                                "
                                [ngClass]="{
                                    active: selectedChartLegend === 'anxity'
                                }"
                                (click)="selectLegend('anxity')"
                                translate>
                                das.anxiety
                            </button>
                            <button
                                class="app-l-legend__stress"
                                *ngIf="typeAssessment === 'dass'"
                                [ngClass]="{
                                    active: selectedChartLegend === 'stress'
                                }"
                                (click)="selectLegend('stress')"
                                translate>
                                das.stress
                            </button>
                            <button
                                class="app-l-legend__resilience"
                                *ngIf="typeAssessment === 'resilience'"
                                [ngClass]="{
                                    actve: selectedChartLegend === 'resilience'
                                }"
                                (click)="selectLegend('resilience')"
                                translate>
                                {{ assessmentTranslation.resilience }}
                            </button>
                            <button
                                class="app-l-legend__alcoholuse"
                                *ngIf="typeAssessment === 'alcoholuse'"
                                [ngClass]="{
                                    actve: selectedChartLegend === 'alcoholuse'
                                }"
                                (click)="selectLegend('alcoholuse')"
                                translate>
                                {{ assessmentTranslation.alcoholuse }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment-results">
                <div class="app-l-result__card">
                    <div
                        class="app-l-result__card-header"
                        *ngIf="typeAssessment === 'dass'; else otherAssessment">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                {{ 'das.dasAssessmentResults' }}
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <button
                                *ngIf="
                                    gethelpbuttondas && contactGroupTelephone
                                "
                                (click)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="get-help"></svg-icon>
                                <span translate>{{
                                    'contact.getHelpNow' | translate
                                }}</span>
                            </button>
                            <button
                                *ngIf="gethelpbuttondas && showOther"
                                (click)="onHotline($event)"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="get-help"></svg-icon>
                                <span translate>{{
                                    'contact.getHelpNow' | translate
                                }}</span>
                            </button>
                        </div>
                    </div>

                    <div
                        class="app-l-result__card-header"
                        *ngIf="typeAssessment != 'dass'">
                        <div class="app-l-result__card-header-left">
                            <h3 class="app-c-heading--h3" translate>
                                {{
                                    'assessments.' +
                                        typeAssessment +
                                        '.titleResult'
                                        | translate: { value: typeAssessment }
                                }}
                            </h3>
                        </div>
                        <div class="app-l-result__card-header-right">
                            <button
                                *ngIf="gethelpbutton && contactGroupTelephone"
                                (click)="
                                    onCallContact(
                                        $event,
                                        organization.contactGroup
                                    )
                                "
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="get-help"></svg-icon>
                                <span translate>{{
                                    'contact.getHelpNow' | translate
                                }}</span>
                            </button>
                            <button
                                *ngIf="gethelpbutton && showOther"
                                (click)="onHotline($event)"
                                class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                                <svg-icon icon="get-help"></svg-icon>
                                <span translate>{{
                                    'contact.getHelpNow' | translate
                                }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="app-l-result__card-body">
                        <div
                            class="app-l-result-item__base"
                            *ngIf="assessmentsList">
                            <div
                                class="app-l-result-item__card-block"
                                *ngFor="
                                    let assessment of assessmentsList
                                        | paginate
                                            : {
                                                  itemsPerPage: itemsPerPage,
                                                  currentPage: currentPage,
                                                  totalItems: totalCount
                                              };
                                    let i = index
                                ">
                                <div class="app-l-result-item__card-header">
                                    <div class="app-l-result-item__hl">
                                        <div class="app-l-result-item__icon">
                                            <svg-icon
                                                icon="calendar"
                                                class="w-100 h-100"></svg-icon>
                                        </div>
                                        <h3 class="app-c-body-text--14-m">
                                            {{
                                                assessment.created
                                                    | date: 'MM-dd-yyyy'
                                            }}
                                        </h3>
                                        <span class="app-l-result-item__time"
                                            >({{
                                                assessment.created
                                                    | date: 'h:mm a'
                                            }})</span
                                        >
                                    </div>
                                    <div class="app-l-result-item__hr">
                                        <button
                                            *ngIf="
                                                loggedInUser.userType ===
                                                    'user' ||
                                                (loggedInUser.userType !=
                                                    'user' &&
                                                    !syncUserId)
                                            "
                                            class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
                                            [routerLink]="[
                                                '/app/assessment/result',
                                                assessment.id,
                                                typeAssessment
                                            ]"
                                            [queryParams]="{
                                                prevpage: 'listing'
                                            }">
                                            <svg-icon
                                                icon="angle-right"
                                                class="w-100 h-100"></svg-icon>
                                        </button>
                                        <button
                                            *ngIf="
                                                loggedInUser.userType !=
                                                    'user' && syncUserId
                                            "
                                            class="app-c-btn app-c-btn--secondary app-c-btn--icon-border app-c-btn--icon-20"
                                            [routerLink]="[
                                                '/app/assessment/result',
                                                assessment.id,
                                                typeAssessment,
                                                syncUserId
                                            ]"
                                            [queryParams]="{
                                                prevpage: 'adminlisting'
                                            }">
                                            <svg-icon
                                                icon="angle-right"
                                                class="w-100 h-100"></svg-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="app-l-result-item__card-body">
                                    <div
                                        class="app-l-rs__item-base"
                                        *ngIf="typeAssessment === 'dass'">
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                das.tableHeaders.0
                                            </h6>
                                            <p class="app-l-rs__status">
                                                {{
                                                    assessment.depressionlevellabel
                                                }}
                                            </p>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessment.depressionlevel
                                                }">
                                            </ng-container>
                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    assessment.depressionlevel <
                                                    1
                                                "
                                                routerLink="/app/series-listing/purpose/130"
                                                translate>
                                                {{
                                                    'theory.courses.purpose.title'
                                                        | translate
                                                        | slice: 0 : 17
                                                }}...
                                            </button>
                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    assessment.depressionlevel ==
                                                        1 ||
                                                    assessment.depressionlevel ==
                                                        2 ||
                                                    assessment.depressionlevel ==
                                                        3 ||
                                                    assessment.depressionlevel ==
                                                        4
                                                "
                                                routerLink="/app/series-listing/depression_new/133">
                                                {{
                                                    'theory.courses.depression_new.title'
                                                        | translate
                                                        | slice: 0 : 17
                                                }}...
                                            </button>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                das.tableHeaders.1
                                            </h6>
                                            <p class="app-l-rs__status">
                                                {{
                                                    assessment.anxietylevellabel
                                                }}
                                            </p>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessment.anxietylevel
                                                }">
                                            </ng-container>
                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    assessment.anxietylevel < 1
                                                "
                                                routerLink="/app/series-listing/sleep/127">
                                                {{
                                                    'theory.courses.sleep.title'
                                                        | translate
                                                        | slice: 0 : 17
                                                }}...
                                            </button>

                                            <span
                                                *ngIf="
                                                    !assessment.anxietylevel ==
                                                    0
                                                ">
                                                <button
                                                    class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                    *ngIf="
                                                        assessment.anxietylevel ==
                                                            1 ||
                                                        assessment.anxietylevel ==
                                                            2 ||
                                                        assessment.anxietylevel ==
                                                            3 ||
                                                        assessment.anxietylevel ==
                                                            4 ||
                                                        assessment.stresslevel ==
                                                            2 ||
                                                        assessment.stresslevel ==
                                                            3 ||
                                                        assessment.stresslevel ==
                                                            4
                                                    "
                                                    routerLink="/app/series-listing/anxiety/132">
                                                    {{
                                                        'theory.courses.anxiety.title'
                                                            | translate
                                                            | slice: 0 : 17
                                                    }}...
                                                </button>
                                            </span>
                                        </div>
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                das.tableHeaders.2
                                            </h6>
                                            <p class="app-l-rs__status">
                                                {{
                                                    assessment.stresslevellabel
                                                }}
                                            </p>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessment.stresslevel
                                                }">
                                            </ng-container>
                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    assessment.stresslevel < 1
                                                "
                                                routerLink="/app/series-listing/mindfulness/128">
                                                {{
                                                    'theory.courses.mindfulness.title'
                                                        | translate
                                                        | slice: 0 : 17
                                                }}...
                                            </button>

                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    assessment.stresslevel == 1
                                                "
                                                routerLink="/app/practice/zen-room">
                                                {{
                                                    'zenRoom.title'
                                                        | translate
                                                        | slice: 0 : 17
                                                }}
                                            </button>

                                            <span
                                                *ngIf="
                                                    assessment.stresslevel > 1
                                                ">
                                                <button
                                                    class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                    *ngIf="
                                                        assessment.stresslevel ==
                                                            2 ||
                                                        assessment.stresslevel ==
                                                            3 ||
                                                        assessment.stresslevel ==
                                                            4
                                                    "
                                                    routerLink="/app/series-listing/anxiety/132">
                                                    {{
                                                        'theory.courses.anxiety.title'
                                                            | translate
                                                            | slice: 0 : 17
                                                    }}...
                                                </button>
                                            </span>
                                        </div>
                                    </div>

                                    <div
                                        class="app-l-rs__item-base"
                                        *ngIf="typeAssessment != 'dass'">
                                        <div class="app-l-rs__item-block">
                                            <h6
                                                class="app-l-rs__title"
                                                translate>
                                                {{
                                                    assessmentTranslation[
                                                        typeAssessment
                                                    ]
                                                }}
                                            </h6>
                                            <p class="app-l-rs__status">
                                                {{
                                                    getLevel(
                                                        assessment.type,
                                                        assessment.resultScore
                                                    )
                                                }}
                                            </p>
                                            <ng-container
                                                [ngTemplateOutlet]="
                                                    daslevelIndicationImg
                                                "
                                                [ngTemplateOutletContext]="{
                                                    level: assessmentService.getAssessmentScoreLevel(
                                                        assessment.type,
                                                        assessment.resultScore
                                                    )
                                                }">
                                            </ng-container>
                                            <button
                                                class="app-c-btn app-c-btn--secondary app-c-btn--sm app-c-btn--curve"
                                                *ngIf="
                                                    getButtonLabel(
                                                        assessment.type,
                                                        assessment.resultScore
                                                    )
                                                "
                                                (click)="
                                                    getButtonLink(
                                                        assessment.type,
                                                        assessment.resultScore
                                                    )
                                                ">
                                                {{
                                                    getButtonLabel(
                                                        assessment.type,
                                                        assessment.resultScore
                                                    )
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="app-l-result-item__base"
                            *ngIf="assessments.length === 0">
                            <div class="app-l-result-item__card-block">
                                <div>
                                    <h3 translate class="nodata-title">
                                        clientList.noData
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template let-level="level" #daslevelIndicationImg>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__very-high"
        *ngIf="level == 4">
        <div class="app-c-welltrack__progress-fill" style="width: 65%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__high"
        *ngIf="level == 3">
        <div class="app-c-welltrack__progress-fill" style="width: 50%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__moderate"
        *ngIf="level == 2">
        <div class="app-c-welltrack__progress-fill" style="width: 25%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__mild"
        *ngIf="level == 1">
        <div class="app-c-welltrack__progress-fill" style="width: 10%"></div>
    </div>
    <div
        class="app-c-welltrack__progress app-c-welltrack__progress-medium app-l-status__good"
        *ngIf="level == 0">
        <div class="app-c-welltrack__progress-fill" style="width: 35%"></div>
    </div>
</ng-template>
