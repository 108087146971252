<div class="page-static public-portal-right-col" style="overflow-y: scroll; height: 100%;">
	<div class="form-screen">
		<div class="img-wrapper">
			<img alt="Welltrack" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
		</div>

		<div class="title-wrapper">
			<h1 class="title text-center" style="margin-bottom:40px;">ProtoCall Services Notice of Privacy Practices</h1>

			<h2>1. Overview</h2>

			<p>ProtoCall Services collects data from its websites and mobile applications including:
				protocallservices.com, welltrack-boost.com, the WellTrack Boost &reg; mobile application, welltrack-connect.com, and
				nmcrisisline.com.</p>

			<p>We aim to collect, use, and disclose only that information reasonably required to enable us to provide
				the services you have requested, to maintain our customer/visitor lists and/or to respond to your
				inquiries or feedback and for other purposes further described in this Notice of Privacy Practices.</p>

			<p>Except as set forth in this Notice of Privacy Practices, your Personal Information will not be used for
				any other purpose without your consent. We will not actively collect Personal Information for the
				purpose of sales or marketing in a way that specifically identifies the individual. We do not sell
				customer lists.</p>

			<p><strong>1.1 Changes to our Notice of Privacy Practices</strong></p>

			<p>ProtoCall Services reserves the right to modify the terms of the Notice of Privacy Practices at any time
				and in our sole discretion, without notice. When this Notice of Privacy Practices is changed, modified,
				and/or amended, the revised Notice of Privacy Practices will be posted on all of our websites and mobile
				applications outlined above. You accept any such modifications to this Notice of Privacy Practices by
				continued use of any of our sites and applications outlined above.</p>

			<p><strong>1.2. Contact us</strong></p>

			<p>If you would like to update your personal information, delete your account, change your preferences or
				have any concerns or questions about your privacy you may either contact us at <a
					href="mailto:info@protocallservices.com">info@protocallservices.com</a> or you may reach us at
				contact information contained in each of our platforms outlined above.</p>

			<h2>2. Collection of Information</h2>

			<p><strong>2.1 protocallservices.com</strong></p>

			<p>We may collect Personal Information from customers and prospective customers through sub-pages on our
				website. Customers may log into our Customer Portal which asks users to authenticate themselves before
				accessing data in the Portal. Prospective customers may use a plug-in for Calendly to schedule an
				appointment for a sales call. The information collected is only used in relation to provide the response
				and information desired by the user.</p>

			<p><strong>2.2 WellTrack Boost</strong> &reg;<strong> mobile application and the welltrack-boost.com website:</strong>
			</p>

			<p>Two types of information may be collected: Personal Information and Non-Personal Information. At the time
				of collection, we will set out the information that we are collecting and the purposes for which we use
				it. You may also provide some optional information to us, but it is always your choice whether to
				provide Personal Information.</p>

			<p>When you register to use WellTrack Boost we require you to provide and basic contact details such as your name
				and an email address. We may also collect Personal Information through customer support features,
				information on your use of WellTrack Boost &reg; and information through surveys if you choose to participate
				in the same.</p>

			<p>This Notice of Privacy Practices does not restrict our use of Non-Personal Information.</p>

			<p>Non-Personal Information is collected or derived by us in the course of operating WellTrack Boost &reg;. For
				example, our web servers may automatically collect Non- Personal Information which is provided through
				your browser or stored on a Cookie when you visit the Website.</p>

			<p>ProtoCall&rsquo;s WellTrack Boost&reg; &nbsp;application uses cookies to help it compile aggregate statistics
				about usage of this Website, such as how many users visit the Website, how long users spend viewing the
				Website, and what pages are viewed most often. This information is used to improve the content of the
				Website. You can set your browser to notify you when you are sent a cookie. This gives you the chance to
				decide whether to accept it. If you disable cookies however, you may not be able to take advantage of
				all the features of this Website or our mobile app more generally.</p>

			<p><strong>2.3 WellTrack Connect website (welltrack-connect.com)</strong></p>

			<p>When you access The WellTrack Connect website, we may ask you to voluntarily provide us certain information.
			</p>

			<p>For Student/Patient Users: We collect information to create your account and identify services,
				including: (1) Contact data (such as your e-mail address and phone number); (2) demographic data (such
				as your gender, your date of birth and your zip code); (3) insurance carrier information (4)
				notifications to your school counseling center (5) other identifying information that you voluntarily
				choose to provide to us, including without limitation unique identifiers such as passwords, and
				information in emails, letters, or other communications that you send to us.</p>

			<p>For university and other professional users: We collect information that will be used to create your
				profile in the directory, such as name, address, email address, as well as such professional information
				as your qualifications, photographs you may upload, certifications, the demographic region in which you
				practice, and your fields of specialty. We collect additional information for our records that may not
				be displayed as part of your directory profile but is necessary to check the information you provide.
			</p>

			<p>We collect this information when you use the website to perform certain tasks, such as:</p>

			<p>a) When you send an email to us (such as your name and e-mail address)</p>

			<p>b) When you send an email to a professional listed in one of the professional directories (such as your
				name and email address)</p>

			<p>c) When you register to be listed in our directories</p>

			<p>d) When you open an email communication from us in your email program, we may use a software technology
				called &ldquo;web beacons&rdquo;.</p>

			<p>e) Information We Collect Automatically:</p>

			<p>We also automatically collect certain data when you use the Services, such as (1) IP address; (2) domain
				server; (3) type of device(s) used to access the Services; (4) web browser(s) used to access the
				Services; (5) referring webpage or other source through which you accessed the Services; (6) geolocation
				information, with your consent in order to help identify professionals in your area; and (7) other
				statistics and information associated with the interaction between your browser or device and the
				Services (collectively &ldquo;Traffic Data&rdquo;). We may combine this information with other
				information that we have collected about you, including, where applicable, your name and other personal
				information.</p>

			<p><strong>2.4 New Mexico Crisis and Access Line website (nmcrisisline.com)</strong></p>

			<p>The New Mexico Crisis and Access Line (NMCAL) website is used to provide New Mexicans with information
				and resources for accessing behavioral health-related support. Users may submit an order for promotional
				materials using a Google Form plug-in embedded on the site. The Form requires entry of your Personal
				Information needed to fulfill the order. Users may also sign up for our Newsletter by entering their
				email address. Personal Information will only be used for the purposes of fulfilling your requests for
				materials and/or to be added to our Newsletter.</p>

			<h2>3. How Information is Used, Stored, and Changed</h2>

			<p><strong>3.1 protocallservices.com</strong></p>

			<p>Personal Information you provide in order to access our Customer Portal page is used to authenticate you
				as an approved user to access sensitive and protected content. ProtoCall stores this information in our
				administrative module of our Portal in order to authenticate users and assist customers with adding and
				terminating approved users.</p>

			<p><strong>3.1.a. Outside Parties and Sites</strong></p>

			<p>Personal Information collected through the Calendly plug in is used to create a calendar event in
				ProtoCall&rsquo;s sales team&rsquo;s calendars. You may modify or request a change to your Calendly
				content by emailing <a href="mailto:info@protocallservices.com">info@protocallservices.com</a>.</p>

			<p><strong>3.1.b. Security</strong></p>

			<p>ProtoCall Services Inc. is committed to ensuring that your identifiable and non-identifiable personal
				information is secure. We have put in place suitable physical, electronic and managerial procedures to
				prevent unauthorized access, modification, disclosure, or loss of your identifiable personal
				information. In addition, the third parties that also collect information, like Google, also have
				security measures in place which you may learn about in their own privacy policy statements.&nbsp;
				However, we cannot assure your identifiable and non-identifiable personal information will never be
				disclosed in a nature that conflicts with this Notice of Privacy Practices. Additionally, we are not
				responsible for any breach of security or actions undertaken by any third parties that receive the
				information, nor for any malicious hacking attempts.</p>

			<p><strong>3.2 WellTrack Boost mobile application and website</strong></p>

			<p>The WellTrack Boost mobile application and/or website collects Personal Information for the purposes of
				contacting and corresponding with you, facilitating, and enhancing your use of WellTrack Boost, understanding
				your needs and developing and improving our products and services and in order to meet legal and
				regulatory requirements.</p>

			<p><strong>3.2.a Additional Privacy Protections for Minors.</strong></p>

			<p>We recognize that children, including young teens, may not be able to make informed choices about
				Personal Information requested online. ProtoCall&rsquo;s WellTrack Boost application does not solicit or
				collect Personal Information targeted at minors and does not permit use of WellTrack Boost unless you are an
				adult or have parental authorization to sign up for and use WellTrack Boost with adult supervision.</p>

			<p><strong>3.2.b. Disclosures and Transfers</strong></p>

			<p>We have put in place contractual and other organizational safeguards to ensure a proper level of
				protection of your Personal Information. In addition to those measures, we will not disclose or transfer
				your Personal Information to third parties without your permission, except as specified in this Notice
				of Privacy Practices. As at the date of this Notice of Privacy Practices, we do not share Personal
				Information about you with any third parties.</p>

			<p>From time to time, we may employ third parties to help us improve WellTrack Boost. These third parties may have
				limited access to databases of Website user information or registered member information solely for the
				purpose of helping us to improve WellTrack Boost and they will be subject to contractual restrictions
				prohibiting them from using the information about our users for any other purpose.</p>

			<p>At all times, and as noted above, ProtoCall&rsquo;s WellTrack Boost application will limit the use and
				disclosure of your personal information to those employees and agents who need to know the information
				to carry out the purposes for which the information was collected or received.</p>

			<p><strong>3.2.c. Important Exceptions for Corporate Users</strong></p>

			<p>If you are an end user under a corporate plan, your healthcare, professional, or corporate plan owner may
				request information about you and your use of WellTrack Boost. This information may include whether you
				completed a WellTrack Boost Program and when and for how long you accessed it. However, we will only provide
				such information if you have consented to such disclosure.</p>

			<p><strong>3.2.d. Lawful Disclosures</strong></p>

			<ul>
				<li>We may disclose your Personal Information to third parties without your consent if:</li>
				<li>The disclosure is required by a person conducting an audit or reviewing an application for
					accreditation or reviewing an accreditation, if the audit or review relates to the services provided
					by ProtoCall&rsquo;s WellTrack Boost application;</li>
				<li>The disclosure is to a person who requires the Personal Information to carry out an audit for, or to
					provide legal services, error management services or risk management services to ProtoCall&rsquo;s
					WellTrack Boost application;</li>
				<li>The disclosure is to a potential successor of ProtoCall&rsquo;s WellTrack Boost application for the
					purpose of allowing the potential successor to assess or evaluate the operations of
					ProtoCall&rsquo;s WellTrack Boost application, on condition that the potential successor first enters into
					an agreement with ProtoCall&rsquo;s WellTrack Boost application to keep the Personal Information
					confidential and secure and to retain the information only so long as is necessary for the purpose
					of the assessment or evaluation;</li>
				<li>The disclosure is in connection with a corporate re-organization, a merger or amalgamation with
					another entity, a sale of all or a substantial portion of our assets or stock, including any due
					diligence exercise carried out in relation to the same, provided that the information disclosed
					continues to be used for the purposes permitted by this Privacy Policy by the entity acquiring the
					information;</li>
				<li>The ProtoCall&rsquo;s WellTrack Boost application reasonably believes that the disclosure is required to
					prevent or reduce a risk of serious harm to the mental or physical health or safety of the
					individual to whom the information relates or another individual;</li>
				<li>The ProtoCall&rsquo;s WellTrack Boost application reasonably believes that the disclosure is required to
					prevent or reduce risk of significant harm to the health or safety of the public or a group of
					people, the disclosure of which is clearly in the public interest;</li>
				<li>The disclosure is to a body with statutory responsibility for the discipline of health care
					providers or to regulate the quality or standards of professional services provided by health care
					providers, including for the purpose of an investigation by that body;</li>
				<li>The disclosure is for the purpose of complying with a summons, subpoena, warrant, order or similar
					requirement issued by a court, person or entity with jurisdiction to compel the production of
					Personal Information or for the purpose of complying with the Rules of Court concerning the
					production of Personal Information in a proceeding;</li>
				<li>The disclosure is for the purpose of a proceeding or contemplated proceeding in which
					ProtoCall&rsquo;s WellTrack Boost application, or its directors, managers, employees, agents or
					affiliates, is expected to be a party or a witness if the information relates to a matter in issue
					in the proceeding or contemplated proceeding;</li>
				<li>The disclosure is to a committee referred to in the Evidence Act for the purpose of peer&nbsp;
					review or quality assurance activities;</li>
				<li>The disclosure is to a proposed litigation guardian, committee or legal representative of the
					individual for the purpose of having the person o appointed as a litigation guardian, committee or
					legal representative;</li>
				<li>The disclosure is to a litigation guardian, committee or a legal representative who is authorized
					under the Rules of Court to commence , defend or continue a proceeding on behalf of the individual
					or to represent the individual in a proceeding;</li>
				<li>The disclosure is for the purpose of laying an information or making an application for an order if
					the personal health information relates to or is a matter in issue in the information or
					application;</li>
				<li>ProtoCall&rsquo;s WellTrack Boost application has reason to believe that disclosing the Personal
					Information is necessary to identify, contact or bring legal action against someone who may be
					causing injury to or interference with (either intentionally or unintentionally) ProtoCall&rsquo;s
					WellTrack Boost application&#39;s rights or property, other Website members, other users of the Services,
					or anyone else (including the rights or property of anyone else) that could be harmed by such
					activities. ProtoCall&rsquo;s WellTrack Boost application may disclose Personal Information when it
					believes, in good faith, that such disclosure is required by and in accordance with the law;</li>
				<li>The disclosure is necessary to comply with the Personal Health Information Privacy and Access Act,
					including an investigation under that Act; or</li>
				<li>The disclosure is required by another Act of the Legislature or the Parliament of Canada.</li>
			</ul>

			<p><strong>3.2.e. Security</strong></p>

			<p>The security of your Personal Information is important to us. We use commercially reasonable efforts to
				ensure that your Personal Information is stored and maintained in a secure environment. You can help
				protect the security of your Personal Information. For instance, never give out your log in details and
				password to another person and remember to log out of your account and close your browser window when
				you finish using the Website, so that other people using the same computer won&#39;t have access to your
				Personal Information. We will never ask you for your password in an unsolicited phone call or in an
				unsolicited e-mail.</p>

			<p><strong>3.2.f. Retention</strong></p>

			<p>ProtoCall&rsquo;s WellTrack Boost application will keep your Personal Information only for as long as it
				remains necessary for the identified purposes in Section 3.1 or as required by law, which may extend
				beyond the termination of our relationship with you. You acknowledge and agree that if you request that
				your name be removed from our databases, it may not be possible to completely delete all your Personal
				Information due to technological and legal constraints.</p>

			<p><strong>3.2.g. Access and Accuracy</strong></p>

			<p>You have the right to access your Personal Information to verify the Personal Information that we have
				collected in respect of you. We will provide you with a copy of your information no later than 30 days
				after receiving a written request which contains sufficient detail to permit ProtoCall&rsquo;s WellTrack Boost
				application to locate the record. We will make every reasonable effort to keep your Personal Information
				accurate and up-to-date, and we will enable you to revise the Personal Information as appropriate.
				Having accurate Personal Information about you enables us to give you the best possible service.</p>

			<p><strong>3.2.h. Outside Parties and Sites</strong></p>

			<p>Through us, you may be introduced to third party vendors and websites. The privacy policies of these
				third parties are not under our control and may differ from ours. The use of any information that you
				may provide to any third party, or the use of &quot;cookie&quot; technology by any third party, will be
				governed by the privacy policy of the operator of the website that you are visiting. If you have any
				doubts about the privacy of the information you are providing on another website, we recommend that you
				contact that website directly for more information and review its privacy policy.</p>

			<p>To properly process online orders, you will be directed to a third party credit card processing or other
				payment processing website (e.g. Stripe). The privacy policies of these third parties are not under our
				control and may differ from ours. If you have any doubts about the privacy of the information you are
				providing on another website, we recommend that you contact that website directly for more information
				and review its privacy policy.</p>

			<p><strong>3.2.i. Your rights</strong></p>

			<p>European data protection laws give you certain rights regarding your personal information. You may ask us
				to take the following actions in relation to your personal information that we hold:</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Opt-out. Stop sending you direct marketing
				communications. You may continue to receive Service-related and other non-marketing emails.</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access. Provide you with information about our
				processing of your personal information and give you access to your personal information.</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Correct. Update or correct inaccuracies in your
				personal information.</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Delete. Delete your personal information.</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Transfer. Transfer a machine-readable copy of
				your personal information to you or a third party of your choice.</p>

			<p>o&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Object. Object to our reliance on our legitimate
				interests as the basis of our processing of your personal information that impacts your rights.</p>

			<p>You can submit these requests by email to info@welltrack-boost.com . We may request specific information from
				you to help us confirm your identity and process your request. Applicable law may require or permit us
				to decline your request. If we decline your request, we will tell you why, subject to legal
				restrictions. If you would like to submit a complaint about our use of your personal information or
				response to your requests regarding your personal information, you may contact us as described in
				Section 13 of our Privacy Policy or submit a complaint to the data protection regulator in your
				jurisdiction. You can find your data protection regulator here.</p>

			<p><strong>3.2.j. Cross-Border Data Transfer</strong></p>

			<p>Whenever we transfer your personal information out of the EEA to countries not deemed by the European
				Commission to provide an adequate level of personal information protection, the transfer will be based
				on one of the safeguards recognized by the European Commission as providing adequate protection for
				personal information, where required by EU data protection legislation.</p>

			<p><strong>3.3 The Shrink Space</strong></p>

			<p>The Shrink Space uses information we collect in the following ways:</p>

			<p>a) To provide our services to you, to communicate with you about your use of our services, to respond to
				your inquiries, and for other customer service purposes.</p>

			<p>b) To tailor the content and information that we may send or display to you, to offer location
				customization, and personalized help and instructions, and to otherwise personalize your experiences
				while using the Services.</p>

			<p>c) To better understand how users access and use our Services, both on an aggregated and individualized
				basis, in order to improve our Services, respond to user desires and preferences, and for other research
				and analytical purposes.</p>

			<p>d) For marketing and promotional purposes in accordance with applicable law. For example, we may use your
				information, such as your email address, to send you news and newsletters, special offers, and
				promotions, or to otherwise contact you about products or information we think may interest you. We also
				may use the information that we learn about you to assist us in advertising our services on third party
				websites.</p>

			<p>e) We share information with third parties only when needed to deliver to you products or services that
				you request. Including, but not limited to, for students voluntarily choosing to update their school
				counseling center, The Shrink Space with your consent can disclose your treatment status to your school
				counseling center. This information will be shared with the account manager of your school counseling
				center. Once this information is disclosed to your school counseling center The Shrink Space is not
				responsible for how the school counseling center maintains this information. For professionals wishing
				to list their services may have the information provided used to check with state licensing boards,
				information shared with our service providers who perform operational services, and/or who make certain
				services, features or functionality available to our users on our behalf.</p>

			<p>f) We may occasionally contact you to gather customer service information to help us determine how we can
				improve our services and products to better meet your needs or to conduct surveys or questionnaires. We
				may de-identify this data to be used for research, service and product development.</p>

			<p>g) We may release your personal information to third parties as required by law, when we believe
				disclosure is necessary to comply with a legal or regulatory requirement, judicial proceedings, court
				order or legal process served on us, to protect the safety, rights or property of patients, customers,
				the public, or the Company or defend the Company and its officers, directors, employees, attorneys,
				agents, contractors and partners, in connection with any legal action, claim, or dispute.</p>

			<p>h) We may transfer your information to another company in connection with a merger, sale, acquisition or
				other change of ownership or control by or of the Company (whether in whole or in part).</p>

			<p><strong>3.3.a. Our Use of Cookies and Other Tracking Mechanisms</strong></p>

			<p>We and our third party service providers use cookies and other tracking mechanisms to track information
				about your use of our Services. We may combine this information with other information we collect from
				you (and our third party service providers may do so on our behalf).</p>

			<p>We use Cookies. Some cookies allow us to make it easier for you to navigate our Site, while others are
				used to enable a faster log-in process or to allow us to track your activities at our Site. Most web
				browsers automatically accept cookies, but if you prefer, you can edit your browser options to block
				them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your
				computer from accepting new cookies, how to have the browser notify you when you receive a new cookie,
				or how to disable cookies altogether. If you don&#39;t have your browser set to accept cookies, certain
				areas of the website may not function properly.</p>

			<p>Web beacons are tiny graphics with a unique identifier, similar in function to cookies. In contrast to
				cookies, which are stored on your computer&rsquo;s hard drive, web beacons are embedded invisibly on web
				pages. We may use web beacons (a.k.a. clear GIFs, web bugs or pixel tags), in connection with our
				Services to, among other things, track the activities of visitors, help us manage content, and compile
				statistics about how the Services are used. We and our third party service providers use web beacons
				that let us know which emails have been opened by the recipients, and allows us to analyze how many
				people are using the Services. This allows us to know how well customers respond to certain
				communications and the effectiveness of our marketing.</p>

			<p>We maintain logs of site usage for purposes such as to evaluate our users&#39; interest level in areas of
				the website, which and how many pages they look at, how long they stay on the website, and what Web
				browsers are used. This data is tracked in aggregate form, not at an individual user level, and is not
				uniquely identifying. The aggregate level data may be shared with third parties and the public.</p>

			<p>We also use automated devices and applications to evaluate usage of our Services. We also may use other
				analytic means to evaluate our Services. We use these tools to help us improve our services, performance
				and user experiences. These entities may use cookies and other tracking technologies to perform their
				services.</p>

			<p><strong>3.3.b. Do-Not-Track</strong></p>

			<p>Currently, our systems do not recognize browser &ldquo;do-not-track&rdquo; requests. You may, however,
				disable certain tracking as discussed in this section (e.g., by disabling cookies).</p>

			<p><strong>3.3.c. Children&rsquo;s Privacy</strong></p>

			<p>The Services are not intended for use by children and children are prohibited from using the Services.
				The Shrink Space does not knowingly collect any information from children, nor are the Services directed
				to children. If we discover that a child under 13 has provided us with personal information, we will
				delete such information from our systems.</p>

			<p>If you are between age thirteen (13) and seventeen (17), you may use the Services only with the consent
				of or under the supervision of your parent or legal guardian.</p>

			<p><strong>3.3.d. Public Information</strong></p>

			<p>For university and other professional users, the materials you post on the website including directory
				and profile information are made available to be seen and read by other users of the website. No such
				materials are treated confidentially. We will remove your materials from the website at your request,
				but we cannot give any assurances that such materials are not still available from servers maintained by
				others (such as search engines or proxy servers) or that others have not already downloaded and stored
				such materials. What you have written may be seen and/or collected by third parties and may be used by
				others in ways we are unable to control or predict.</p>

			<p><strong>3.3.e. Information Security and Storage</strong></p>

			<p>The security of your information is important to us. We have implemented reasonable security measures to
				protect the information submitted to us, both during transmission and in storage. The Shrink Space may
				use third party vendors and hosting partners to provide the necessary hardware, software, networking,
				storage and related technology required to operate the services.</p>

			<p>Despite our efforts, we cannot guarantee the security of your information, nor can we guarantee that
				information that you provide will not be intercepted. It is important for you to protect against
				unauthorized access to your password and to your computer or device. It is your responsibility to sign
				off when you finish using a shared computer or device.</p>

			<p><strong>3.3.f. Updating Your Information and Closing Your Account</strong></p>

			<p>If you are a registered user of the Services, you can modify certain account information by logging in
				and accessing your account. If you wish to close your account please e-mail us at
				contact@welltrack-connect.com. Please note, however, that The Shrink Space reserves the right to retain
				information from closed accounts, including to comply with law, prevent fraud, resolve disputes, and
				enforce the Privacy Policy and take other actions permitted by law. You must promptly notify us if any
				of your account data is lost, stolen or used without permission.</p>

			<p><strong>3.3.g. Other Websites</strong></p>

			<p>The Services contain links to or embedded content from third party websites. A link to or embedded
				content from a third party website does not mean that we endorse that website, the quality or accuracy
				of information presented on that website or the persons or entities associated with that website. When
				you click on links that take you to third party websites, the third party may collect information about
				you at their Website. The third party may have its&rsquo; own privacy policies that differ from ours. We
				encourage you to read the posted privacy statement whenever interacting with any website you visit.</p>

			<p><strong>3.4 New Mexico Crisis and Access Line website (nmcrisisline.com)</strong></p>

			<p>ProtoCall may use information collected using Google Forms to process requests for NMCAL marketing
				materials.</p>

			<p><strong>3.4.1 Storage</strong></p>

			<p>We use Google Forms to process contact forms for requests for marketing materials.&nbsp; From
				Google&rsquo;s privacy policy page regarding local storage: &ldquo;We may collect and store information
				(including personal information) locally on your device using mechanisms such as browser web storage
				(including HTML 5) and application data caches.&rdquo;</p>

			<p>We use hosting services, Google Analytics, and other third-party companies for data storage.&nbsp; This
				is standard practices for online businesses.</p>

			<p><strong>3.4.2. Security</strong></p>

			<p>ProtoCall Services Inc. is committed to ensuring that your identifiable and non-identifiable personal
				information is secure. We have put in place suitable physical, electronic and managerial procedures to
				prevent unauthorized access, modification, disclosure, or loss of your identifiable personal
				information. In addition, the third parties that also collect information, like Google, also have
				security measures in place which you may learn about in their own privacy policy statements.&nbsp;
				However, we cannot assure your identifiable and non-identifiable personal information will never be
				disclosed in a nature that conflicts with this Notice of Privacy Practices. Additionally, we are not
				responsible for any breach of security or actions undertaken by any third parties that receive the
				information, nor for any malicious hacking attempts.</p>

			<p><strong>3.4.3. How long we retain your data</strong></p>

			<p>If you complete a request for materials through our Google Form on the nmcrisisline.com website, the
				information and its metadata are retained indefinitely. This is so we can track request data and
				patterns and plan our material inventory accurately from year to year. Only administrators have access
				to that information solely for distribution and inventory purposes.</p>

			<p><strong>3.4.4. What rights you have over your data</strong></p>

			<p>If you have ever requested materials, you can request to receive an exported file of the personal data we
				hold about you, including any data you have provided to us. You can also request that we erase any
				personal data we hold about you. This does not include any data we are obliged to keep for
				administrative, legal, or security purposes.</p>

			LAST UPDATE: March, 2022
		</div>
	</div>
</div>