<div id="page-forgot-password" class="form-screen-main">
    <div id="page-login" class="form-screen-container">
        <div class="img-wrapper login-header">
            <div class="animated fadeInDown login-logo"><svg-icon icon="welltrack-logo"></svg-icon></div>
        </div>

        <div class="title-wrapper">
            <h3 class="app-c-heading--h2" translate>forgotPassword.title</h3>
            <p class="app-c-body-text--16" translate>forgotPassword.body</p>
        </div>

        <div class="form-wrapper">
            <form (keydown.enter)="onResetPassword()">
                <div class="custom-input-container">
                    <label for="forget-email" class="app-c-body-text--14-m">Email Id</label>
                    <div class="custom-input-section">
                        <input autoFocus class="wt-auto-login-email" aria-label="Email address" tabindex="0"  (ngModelChange)="onChangeEmail($event)"
                            aria-label="Email" type="email" placeholder="john@unb.ca" [(ngModel)]="emailAddress"
                            name="emailAddress" id="forget-email" />
                        <svg-icon icon="email-logo"></svg-icon>
                    </div>
                </div>
            </form>
        </div>

        <div class="footer">
            <a tabindex="0" (click)="onResetPassword()" (keypress)="onResetPassword()"  [ngClass]="{'btn-disabled': !emailValidate}"
                class="app-c-btn app-c-btn--primary app-c-btn--curve  wt-auto-forgotpassword-submit"
                translate>forgotPassword.email</a>
            <p class="sign-in-link" translate>forgotPassword.back<a class="wt-auto-forgotpassword-back" routerLink="/"
                    translate>forgotPassword.sign</a></p>
        </div>
    </div>
</div>