import {
    Component,
    ViewChild,
    OnInit,
    Input,
    AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { Moodcheck } from '../../../models/moodcheck';
import { Activity } from '../../../models/activity';
import { UserService } from '../../../lib/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { config } from '../../../../environments/all';
import { ModalService } from '../../../lib/modal.service';
import { Assessment } from '../../../models/assessment';
import { AnalyticEvent } from 'app/lib/analytic-event';
import * as moment from 'moment';
import { ActionPlanDashboard } from 'app/models/action-plan';
import * as Chart from 'chart.js';
import { ChartData } from 'chart.js';
import { Organization } from 'app/models/organization';
import { ProfessionalService } from '../professional.service';
import { OverallViewMoodcheckComponent } from 'app/components/overall-view-moodcheck/overall-view-moodcheck.component';
import { UtilityService } from 'app/lib/utility.service';
import { AssignEventList } from 'app/models/event';
@Component({
    selector: 'app-user-details-overview',
    templateUrl: './user-details-overview.component.html',
    styleUrls: ['./user-details-overview.component.scss'],
})
export class UserDetailsOverviewComponent implements OnInit {
    isloaded: boolean = false;
    user: User;
    userViewed: User;
    fullName: string;
    loginOn: string = '';
    id: number;
    paramsSub: any;
    activity: Array<Object> = [];
    moodchecks: Array<Object> = [];
    moodcheckimage: string = '';
    avgmoodcheck: number = 0;
    error: any;
    showMore: boolean = false;
    appointment: boolean = false;
    alerts: number = 0;
    avg: number = 0;
    emotion: string = '';
    isAdmin = false;
    userType = '';
    organization: Organization;
    popup: any;
    assessments: Assessment[];
    showAssessment: boolean = false;
    isOrgadmin: boolean = false;
    showAssigned: boolean = true;
    showCompleted: boolean = false;
    @ViewChild('dasAssessmentChart') canvasRef;
    dasAssessmentChart: Chart;
    seriesList = [];
    userActionPlansData = [];
    chartData: ChartData;
    chartOptions;
    isLoaded: boolean = false;
    @Input() userActionPlans: any;
    @Input() countData: any = {};
    @Input() demographicData: any;
    @Input() userDetail: any;
    @ViewChild('moodchecOverallViewComp')
    moodchecOverallViewComp: OverallViewMoodcheckComponent;
    showEventAssignModal: boolean = false;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    assignmentTypes = [
        {
            title: 'Assessment',
            key: 'assessment',
        },
        {
            title: 'MoodCheck',
            key: 'moodcheck',
        },
        {
            title: 'Series',
            key: 'series',
        },
        {
            title: 'Tools',
            key: 'tools',
        },
    ];
    dateRangeSelected: any = 30;
    nameInitial: any;
    currentDate: Date;
    rangeDate: Date;
    assignmentList: any;
    assignedListLoaded: boolean = false;
    resourceData: Array<object> = [];
    selectedType: any = 'all';
    assigned = [];
    completed = [];
    latestDassData: any = {};
    latestDassDataLoaded: boolean = false;
    seriesLoaded: boolean = false;
    resourceClicksLoaded: boolean = false;
    countDataLoaded: boolean = true;

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private router: Router,
        private log: LogService,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private modalService: ModalService,
        private professionalService: ProfessionalService,
        private utilityService: UtilityService
    ) {}

    ngOnInit() {
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.initDasAssessmentChart();
        this.translate.stream('error').subscribe((res: any) => {
            this.error = res.title;
        });

        this.translate.stream('userDetails.popup').subscribe((res: any) => {
            this.popup = res;
        });

        if (this.router.url.slice(1, 6) === 'admin') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }

        if (this.userService.getUser().userType === 'orgadmin') {
            this.isOrgadmin = true;
        } else {
            this.isOrgadmin = false;
        }

        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
            this.translate.stream('lang').subscribe((res: any) => {
                this.getAssignment();
                setTimeout(() => {
                    this.getUserActionPlans(this.userActionPlans);
                }, 100);
            });
            this.isLoaded = true;
        });
        if (this.userDetail.primaryOrganization) {
            this.organization = this.userDetail.primaryOrganization;
            this.getSeries();
            this.getResourseData();
            this.getFirstLastAssessment();
        }
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.getResourseData();
        });
    }
    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignment();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    getSeries() {
        let currentDate = this.currentDate.toISOString().substring(0, 10);
        let rangeDate = this.rangeDate.toISOString().substring(0, 10);
        this.api
            .get(`syncheduser/series/${this.organization.id}/${this.id}`, {
                lastviewed: 1,
                From: rangeDate,
                To: currentDate,
            })
            .subscribe(
                (result: any) => {
                    this.seriesLoaded = true;
                    let seriesData = [];
                    let courseList = result.data.filter(
                        series => series[0].courseprogress !== 'notstarted'
                    );
                    courseList.forEach(course => {
                        seriesData.push(course[0]);
                    });
                    this.sortSeries(seriesData);
                },
                error => {}
            );
    }

    sortSeries(seriesData) {
        seriesData.sort(function (a, b) {
            return (
                new Date(b.lastviewed).getTime() -
                new Date(a.lastviewed).getTime()
            );
        });
        this.seriesList = seriesData.slice(0, 3);
    }
    getUserActionPlans(actionPlan) {
        if (actionPlan.length != 0) {
            this.userActionPlansData = actionPlan;
        }
    }

    onTabChanged($event) {
        let tabSelected = $event.tab.textLabel;
        if (tabSelected == 'Assigned') {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onCancelShare(userId) {
        let confirmResult;
        this.modalService
            .showConfirmation('Cancel', this.popup.cancelSharing)
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    this.api.delete('counselors/' + userId).subscribe(
                        (result: any) => {
                            this.modalService.showAlert(
                                this.popup.successtitle,
                                this.popup.success
                            );
                            this.router.navigate(['professional/users']);
                        },
                        (error: any) => {
                            this.modalService.showAlert(
                                this.popup.errortitle,
                                this.popup.error
                            );
                        }
                    );
                }
            });
    }
    initDasAssessmentChart() {}
    getFirstLastAssessment() {
        this.api.get('syncheduser/getlastfirstassessment/' + this.id).subscribe(
            (result: any) => {
                this.latestDassDataLoaded = true;
                this.latestDassData = result.last[0];
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment count. ' + error.message
                );
                this.isLoaded = true;
            }
        );
    }
    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.updateCount();
        this.updateMoodcheck();
        this.getResourseData();
    }

    updateCount() {
        this.countDataLoaded = false;
        this.professionalService
            .getCounts(this.id, '1-3-13-21', this.rangeDate, this.currentDate)
            .subscribe(result => {
                this.countData = result;
                this.countDataLoaded = true;
            });
    }
    updateMoodcheck() {
        this.moodchecOverallViewComp.getMoodcheckData(this.dateRangeSelected);
    }
    getAssignment() {
        this.assignedListLoaded = false;
        let payload = {
            Limit: 10,
        };
        if (this.selectedType != 'all') {
            payload['type'] = this.selectedType;
        }
        this.api.get('syncheduser/assignments/' + this.id, payload).subscribe(
            (result: any) => {
                this.assignmentList = result;
                this.assigned = AssignEventList.initializeArray(
                    this.assignmentList.assigned
                );
                this.completed = AssignEventList.initializeArray(
                    this.assignmentList.completed
                );
                // this.changeType(this.selectedType);
                this.log.debug('assignment fetch');
                this.assignedListLoaded = true;
            },
            (error: any) => {
                this.log.error('Error fetching activity. ' + error.message);
                this.assignedListLoaded = true;
            }
        );
    }

    deleteAssign(aId){
        this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete this assignment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
                    this.api
                    .delete(
                        `syncheduser/assignment/delete/` + aId +
                            `?SynchedUserID=` + this.id
                    )
                    .subscribe(
                        (result: any) => {
                            this.getAssignment();
                            this.isLoaded = true;
                            this.assignedListLoaded = true;
                            this.modalService.showAlert(
                                "Success",
                                'Assignment deleted successfully'
                            );
                        },
                        (error: any) => {
                            this.log.error(
                                'Error getting delete.' + error.message
                            );
                            this.isLoaded = true;
                        }
                    );
                }
			});
        }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }
    getResourseData() {
        let currentDate = this.currentDate.toISOString().substring(0, 10);
        let rangeDate = this.rangeDate.toISOString().substring(0, 10);
        this.api
            .get(`syncheduser/resourceclicks/${this.id}`, {
                OrgId: this.organization.id,
                From: rangeDate,
                To: currentDate,
            })
            .subscribe(
                (result: any) => {
                    if (result) {
                        this.resourceData = result;
                        this.resourceClicksLoaded = true;
                    }
                },
                error => {
                    this.log.error(
                        'Error getting action plan. ' + error.message
                    );
                }
            );
    }
    goToActionPlan(plan?: any) {
        let url = '/app/practice/action-plan/create';
        this.router.navigate([url], { queryParams: { userId: this.id } });
    }
}
