<div id="answer" class="answer-main-container">
    <div class="modal-header">
        <button class="close-button" (click)="onFinish()">
            <svg-icon icon="modal-close-button"></svg-icon>
        </button>
    </div>
    <div class="content">
        <div [ngClass]="value ? 'correct-answer-section' : 'wrong-answer-section'">
            <div class="app-l-no-mood-check">
                <div class="no-data-image">
                    <svg-icon icon="wrong-info-icon" class="wrong-info-icon"></svg-icon>
                    <svg-icon icon="correct-info-icon" class="correct-info-icon"></svg-icon>
                    <span class="app-l-no-mood__round app-l-no-mood__round1"></span>
                    <span class="app-l-no-mood__round app-l-no-mood__round2"></span>
                    <span class="app-l-no-mood__round app-l-no-mood__round3"></span>
                </div>
                <p class="title-text">{{title}}</p>
            </div>
        </div>
        <!-- <div class="results-info-container">
            <p class="app-c-body-text--14">Natalie is leaving a store without having purchased anything. As she is about
                to pass through the
                anti-theft devices at
                the door, she thinks,</p>
            <p class="app-c-body-text--14">"What if the alarm sounds, even though I haven't taken anything? What if
                security comes and they accuse
                me of stealing?
                What if I'm treated like I did something wrong?" What kind of distortion is Natalie using?</p>
        </div>
        <div class="results-explanation">
            <p class="app-c-body-text--14">Sed ut perspiciatis unde omnis iste natus error
                Sit voluptatem accusantium doloremque Laudantium, totam rem aperiam, eaque ipsa quae</p>
            <p class="app-c-body-text--14">Sed ut perspiciatis unde omnis iste natus error
                Sit voluptatem accusantium doloremque Laudantium, totam rem aperiam, eaque ipsa quae</p>
        </div> -->
        <div class="results">
            <p class="answer-content" [innerHTML]="innerhtml">
            </p>

        </div>
    </div>


    <div class="fixed-footer">
        <button tabindex="0" class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="onFinish()"
            (keypress)="onFinish()">
            <span translate>cdQuiz.popups.answer.button</span>
            <svg-icon icon="arrow-right"></svg-icon>
        </button>
    </div>
</div>