<div id="auth-portal-wrapper" [ngClass]="showFooter ? 'my-settings-container' : ''">
	<div id="menu" [ngClass]="{ navIn: menuService.navIn }" role="main" aria-labelledby="section-title">
		<app-top-navbar-menu></app-top-navbar-menu>
	</div>
	<div id="page" [ngClass]="{ navIn: menuService.navIn }" aria-labelledby="content-section-title">
		<div class="banner" *ngIf="showFeedbackBtn">
			<span translate class="npsIconClass" (click)="showUserSurvey()" *ngIf="showFeedback" translate>
				Give us your feedback
				<img alt="menu.userSurvey" matTooltip="User Survey" title="{{ 'menu.userSurvey' | translate }}"
					src="/assets/img/icons/npsSurvey_Icon.svg" />
			</span>
			<span translate class="npsIconClass" (click)="showNPSSurvey()" *ngIf="showNps" translate>
				Give us your feedback
				<img alt="menu.npsSurvey" matTooltip="NPS Survey" title="{{ 'menu.npsSurvey' | translate }}"
					src="/assets/img/icons/npsSurvey_Icon.svg" />
			</span>
		</div>
		<div class="app-l-alert" *ngIf="assignDevDisclaimer">
			<div class="alert_info">
				<svg-icon icon="alert-info"></svg-icon>
			</div>
			<span translate>dashboard.assignDevDisclaimerMsg</span>
			<div class="alert-close" (click)="closeAssignDevDisclaimer()">
				<svg-icon icon="alert-close"></svg-icon>
			</div>
		</div>
		<main id="content" class="main-content-wrapper">
			<div id="demo-notice" *ngIf="menuService.demoMode" [hidden]="menuService.demoClose">
				<div class="inside">
					<a *ngIf="menuService.showDemoClose" tabindex="0" (click)="menuService.onDemoClose()"
						(keypress)="menuService.onDemoClose()" class="close-modal" aria-label="Close notice">
						<img src="./assets/img/icons-svg/close-icon.svg" />
					</a>
					<div class="content">
						<h2 translate>This site is in demo mode</h2>
						<p>
							Feel free to browse through the site. Existing
							information cannot be modified and new information
							cannot be saved while the site is in demo mode.
						</p>
					</div>
				</div>
			</div>
			<router-outlet></router-outlet>
		</main>
		<footer *ngIf="showFooter" class="my-settings-footer">
			<h2 class="app-c-body-text--14-m">Powered by Welltrack Boost</h2>
		</footer>
	</div>
</div>
