import {
    Component,
    OnInit,
    Input
} from '@angular/core';
import { ApiService } from '../../../lib/api.service';
import { User } from '../../../models/user';
import { UserService } from '../../../lib/user.service';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ActionPlanList } from 'app/models/action-plan';
import { UtilityService } from 'app/lib/utility.service';
import { AssignEventList } from 'app/models/event';

@Component({
    selector: 'app-action-plan-view',
    templateUrl: './action-plan-view.component.html',
    styleUrls: ['./action-plan-view.component.scss'],
    providers: [DatePipe],
})
export class ActionPlanViewComponent implements OnInit {
    loggedInUser: User;
    user: User;
    isLoaded: boolean = false;
    demographicDetail: any;
    id: number;
    paramsSub: any;
    actionPlanList: ActionPlanList[] = [];
    actionPlan: ActionPlanList[] = [];
    actionPlanDataCount: number = 0;
    @Input() countData: any;
    @Input() demographicData: any;
    @Input() userDetail: any;
    @Input() userActionPlans: any;
    showEventAssignModal: boolean = false;
    tabselecteddays: string = '30';
    activedays: string = '30';
    popups: any;
    assignedList: any;
    completeList: any;
    copingList: any;
    warningList: any;
    dateRange = [
        {
            title: 'Last 30 days',
            value: 30,
        },
        {
            title: 'Last 60 days',
            value: 60,
        },
        {
            title: 'Last 90 days',
            value: 90,
        },
    ];
    dateRangeSelected: any = 30;
    currentDate: Date;
    rangeDate: Date;
    showCompleted: boolean = false;
    showAssigned: boolean = true;
    showCoping: boolean = true;
    showWarning: boolean = false;
    assignedListLoaded: boolean = false;
    constructor(
        private api: ApiService,
        private log: LogService,
        private translate: TranslateService,
        private modalService: ModalService,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private utilityService: UtilityService
    ) {
        this.loggedInUser = this.userService.getUser();
    }

    ngOnInit(): void {
        this.paramsSub = this.activatedRoute.params.subscribe(params => {
            this.id = parseInt(params['id'], 10);
        });
        this.currentDate = new Date();
        this.rangeDate = new Date(
            new Date().setDate(this.currentDate.getDate() - 30)
        );
        this.translate.stream('actionPlan').subscribe((res: any) => {
            this.popups = res.popups;
        });

        this.translate.stream('lang').subscribe((res: any) => {
            if (res === 'en') {
                setTimeout(() => {
                    this.getactionPlanDataCount(this.userActionPlans);
                }, 10);
                this.getCopingAndWarningList(30);
                this.getAssignedList();
            } else {
                setTimeout(() => {
                    this.getactionPlanDataCount(this.userActionPlans);
                }, 10);
                this.getCopingAndWarningList(30);
                this.getAssignedList();
            }
        });
    }

    setdayschart(tab: string) {
        if (this.user.userType != 'user') {
            if (this.activedays !== tab) {
                this.activedays = tab;
                this.tabselecteddays = tab;
            }
        }
    }

    getInitials(name): string {
        return this.utilityService.getInitials(name);
    }

    onRangeChange() {
        this.rangeDate = new Date(
            new Date().setDate(
                this.currentDate.getDate() - this.dateRangeSelected
            )
        );
        this.getCurrentMonthActionPlanLength(this.dateRangeSelected);
        this.getCopingAndWarningList(this.dateRangeSelected);
    }

    getactionPlanDataCount(userActionPlans) {
        this.isLoaded = false;
        if (userActionPlans.length != 0) {
            this.actionPlanList = userActionPlans;
            this.getCurrentMonthActionPlanLength(30);
            this.log.event(AnalyticEvent.event.actionPlanListingView);
            this.isLoaded = true;
        }
    }

    getCurrentMonthActionPlanLength(dayRange: number = 30): void {
        const currentDate = new Date();

        const pastDateForActionPlan = new Date();
        pastDateForActionPlan.setDate(currentDate.getDate() - dayRange);

        const actionPlansWithinRange = this.actionPlanList.filter(
            actionPlan => {
                const actionPlanDate = new Date(actionPlan.created);
                return (
                    actionPlanDate >= pastDateForActionPlan &&
                    actionPlanDate <= currentDate
                );
            }
        );

        const startOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );
        const endOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
        );

        const actionPlansForDataCount = this.actionPlanList.filter(
            actionPlan => {
                const actionPlanDate = new Date(actionPlan.created);
                return (
                    actionPlanDate >= startOfMonth &&
                    actionPlanDate <= currentDate
                ); // Only current month
            }
        );
        this.actionPlan = actionPlansWithinRange;
        this.actionPlanDataCount = actionPlansForDataCount.length || 0;
    }

    getCopingAndWarningList(dayRange) {
        let currentDate = new Date();
        let pastDate = new Date(
            new Date().setDate(currentDate.getDate() - dayRange)
        );
        let formatDate = date => {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        this.isLoaded = false;
        this.api
            .get(
                `syncheduser/actionplan/copingskills/` +
                    this.id +
                    `?limit=4&From=` +
                    formatDate(pastDate)
            )
            .subscribe(
                (result: any) => {
                    this.isLoaded = true;
                    if (result) {
                        this.copingList = result.copingskils;
                        this.warningList = result.warningsigns;
                    }
                },
                error => {
                    this.isLoaded = true;
                    this.log.error(
                        'Error getting action plan. ' + error.message
                    );
                }
            );
    }

    getAssignedList() {
        this.assignedListLoaded = false;
        this.api
            .get(
                `syncheduser/assignments/` +
                    this.id +
                    `?type=actionplan` +
                    `&limit=10`
            )
            .subscribe(
                (result: any) => {
                    this.assignedList = AssignEventList.initializeArray(
                        result.assigned
                    );
                    this.completeList = AssignEventList.initializeArray(
                        result.completed
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                },
                (error: any) => {
                    this.log.error(
                        'Error getting assessment questions. ' + error.message
                    );
                    this.isLoaded = true;
                    this.assignedListLoaded = true;
                }
            );
    }

    onTabChanged($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showAssigned = true;
        } else {
            this.showCompleted = true;
        }
    }

    onTabChangedList($event) {
        let tabSelected = $event.index;
        if (tabSelected == 0) {
            this.showCoping = true;
        } else {
            this.showWarning = true;
        }
    }

    goToActionPlan(plan?: any) {
        let url = '/app/practice/action-plan/create';
        this.router.navigate([url], { queryParams: { userId: this.id } });
    }

    previewActionplan(plan: any) {
        this.router.navigate(['/app/practice/action-plan/preview/' + plan.id], {
            queryParams: { userId: this.id },
        });
    }

    onCloseOptions(event: any) {
        this.showEventAssignModal = false;
        event && this.getAssignedList();
        setTimeout(function () {
            jQuery('#moodcheck-modal .featured:visible').focus();
        }, 500);
    }

    showAssignModal(val) {
        this.showEventAssignModal = val;
    }

    delete(aId){
        this.modalService
			.showConfirmation(
				'Delete',
				'Are you sure you want to delete this assignment?'
			)
			.afterClosed()
			.subscribe(result => {
				if (result) {
                    this.api
                    .delete(
                        `syncheduser/assignment/delete/` + aId +
                            `?SynchedUserID=` + this.id
                    )
                    .subscribe(
                        (result: any) => {
                            this.getAssignedList();
                            this.isLoaded = true;
                            this.assignedListLoaded = true;
                            this.modalService.showAlert(
                                "Success",
                                'Assignment deleted successfully'
                            );
                        },
                        (error: any) => {
                            this.log.error(
                                'Error getting delete.' + error.message
                            );
                            this.isLoaded = true;
                        }
                    );
                }
			});
        }
}
