<page-loader *ngIf="!isLoaded"></page-loader>
<div  class="app-l-room__wrap" *ngIf="isLoaded">

    <div class="app-c-card app-l-action-plan-preview-header">
        <div class="app-c-card__header" *ngIf="!showAsAdmin">
            <div class="app-l-card__h-right">
                <button tabindex="0" (click)="goToActionPlan()" class="app-c-btn app-c-btn--secondary app-c-btn--curve">
                    <span translate>actionPlan.createNewActionPlan</span>
                    <svg-icon icon="plus"></svg-icon>
                </button>
            </div>
        </div>

        <div class="app-c-card app-l-action-plan-preview-body">
            <div class="app-l-room__pos">
                <div class="app-l-room__tabs">
                    <mat-tab-group mat-align-tabs="left" disableRipple="true"
                         #tabGroup (focusChange)="onTabChanged($event)" [selectedIndex]="0">
                        <mat-tab>
                        <ng-template mat-tab-label>
                            <span translate>actionPlan.all <span>({{ getFilteredItems("All").count }})</span></span>
                          </ng-template>           
                            <div class="app-dm-positive-cards-wrapper" *ngIf="filteredItems.length">
                                <div class="app-c-card app-l-activity-card" *ngFor="let actionplan of filteredItems">
                                    <div class="app-c-card__content">
                                        <div class="app-l-card__h-left" (click)="previewActionplan(actionplan)">
                                            <div class="app-l-card__h-top-block">
                                                <h2 class="app-l-card__cardtitle app-c-body-text--14-m"
                                                    >
                                                    {{actionplan.title}}</h2>
                                                <div class="badges-container" *ngIf="(user.userType === 'user' && actionplan.isAdminCreated) || (user.userType != 'user' && !actionplan.isAdminCreated && showAdminActionplan)">
                                                    <div class="badge app-c-body-text--12" translate>
                                                        actionPlan.createCounselor
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="app-c-body-text--14 text-content">
                                                <span [translate]="
                                        actionplan.isUpdated
                                              ? 'actionPlan.lastUpdatedOn'
                                              : 'actionPlan.createdOn'
                                          "></span>
                                                {{
                                                actionplan.isUpdated
                                                ? (actionplan.updated | date : "MMM dd, yyyy")
                                                : (actionplan.created | date : "MMM dd, yyyy")
                                                }}
                                            </p>
                                        </div>

                                        <div class="app-l-card__h-right" *ngIf="user.userType === 'user' || (user.userType != 'user' && !actionplan.isAdminCreated)">
                                            <a (click)="deleteActionPlan(actionplan)" 
                                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                                <svg-icon icon="delete"></svg-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <span *ngIf="user.userType != 'user' && showAdminActionplan" translate>actionPlan.createCounselor <span>({{ getFilteredItems("Counselor Created").count }}) </span></span>
                                <span *ngIf="user.userType === 'user' || (user.userType != 'user' && !showAdminActionplan)" translate>actionPlan.createByMe <span>({{ getFilteredItems("Created by Me").count }}) </span></span>
                            </ng-template>   
                            <div class="app-dm-positive-cards-wrapper" *ngIf="filteredItems.length">
                                <div class="app-c-card app-l-activity-card" *ngFor="let actionplan of filteredItems">
                                    <div class="app-c-card__content">
                                        <div class="app-l-card__h-left" (click)="previewActionplan(actionplan)">

                                            <div class="app-l-card__h-top-block">
                                                <h2 class="app-l-card__cardtitle app-c-body-text--14-m">
                                                    {{actionplan.title}}</h2>
                                                    <div class="badges-container" *ngIf="(user.userType === 'user' && actionplan.isAdminCreated) || (user.userType != 'user' && !actionplan.isAdminCreated && showAdminActionplan)">
                                                        <div class="badge app-c-body-text--12" translate>
                                                            actionPlan.createCounselor
                                                        </div>
                                                    </div>
                                            </div>
                                            <p class="app-c-body-text--14 text-content">
                                                <span [translate]="
                                        actionplan.isUpdated
                                              ? 'actionPlan.lastUpdatedOn'
                                              : 'actionPlan.createdOn'
                                          "></span>
                                                {{
                                                actionplan.isUpdated
                                                ? (actionplan.updated | date : "MMM dd, yyyy")
                                                : (actionplan.created | date : "MMM dd, yyyy")
                                                }}
                                            </p>
                                        </div>

                                        <div class="app-l-card__h-right" *ngIf="user.userType === 'user' || (user.userType != 'user' && !actionplan.isAdminCreated)">
                                            <a (click)="deleteActionPlan(actionplan)"
                                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                                <svg-icon icon="delete"></svg-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab *ngIf = "user.userType === 'user' || (user.userType != 'user' && showAdminActionplan)">
                            <ng-template mat-tab-label>
                                <span *ngIf="user.userType != 'user'" translate>actionPlan.createByUser <span>({{ getFilteredItems("Created by Me").count }})</span></span>
                                <span *ngIf="user.userType === 'user'" translate>actionPlan.counselorCreated <span>({{ getFilteredItems("Counselor Created").count }})</span></span>
                            </ng-template>
                            <div class="app-dm-positive-cards-wrapper" *ngIf="filteredItems.length">
                                <div class="app-c-card app-l-activity-card" *ngFor="let actionplan of filteredItems">
                                    <div class="app-c-card__content">
                                        <div class="app-l-card__h-left" (click)="previewActionplan(actionplan)">

                                            <div class="app-l-card__h-top-block">
                                                <h2 class="app-l-card__cardtitle app-c-body-text--14-m">
                                                    {{actionplan.title}}</h2>
                                                <div class="badges-container" *ngIf="(user.userType === 'user' && actionplan.isAdminCreated) || (user.userType != 'user' && !actionplan.isAdminCreated)">
                                                    <div class="badge app-c-body-text--12" translate>
                                                        actionPlan.createCounselor
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="app-c-body-text--14 text-content">
                                                <span [translate]="
                                        actionplan.isUpdated
                                              ? 'actionPlan.lastUpdatedOn'
                                              : 'actionPlan.createdOn'
                                          "></span>
                                                {{
                                                actionplan.isUpdated
                                                ? (actionplan.updated | date : "MMM dd, yyyy")
                                                : (actionplan.created | date : "MMM dd, yyyy")
                                                }}
                                            </p>
                                        </div>

                                        <div class="app-l-card__h-right" *ngIf="(user.userType === 'user' && actionplan.isAdminCreated) || (user.userType != 'user' && !actionplan.isAdminCreated)">
                                            <a (click)="deleteActionPlan(actionplan)"
                                                class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon">
                                                <svg-icon icon="delete"></svg-icon>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                <div *ngIf="filteredItems.length <= 0">
                    <div class="empty-card">
                        <div class="app-dm-positive-cards"
                            [translate]="!this.isLoading ? 'actionPlan.noDataFound' : ''">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>