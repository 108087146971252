import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";

import "rxjs/Rx";
import { Subject } from "rxjs";
//import { DeviceService } from './device.service';
import { MessagingService } from "./message-service";
import amplitude from "amplitude-js";
import { AnalyticEvent } from "./analytic-event";
import { LogService } from "./log.service";
import { UserService } from "./user.service";
@Injectable()
export class AuthService {
	Authenticator = new Subject();
	redirectUrl: string;
	userData: any;

	constructor(
		private storage: StorageService,
		private messageService: MessagingService,
		private log: LogService,
		private user: UserService
	) {
		this.userData = this.user.getUser();
	}

	authenticate(accessToken, minutes = 60 * 24) {
		this.storage.set("accessToken", accessToken);
		/* expires in ten minutes */
		this.storage.set("isAuthenticated", true, false, minutes);
		this.Authenticator.next(true);
	}

	checkAuthentication() {
		this.Authenticator.next(true);
	}

	logout() {
		//this.deviceService.deregister();
		this.userData = this.user.getUser();
		this.log.event(
			this.userData.userType.toUpperCase() +
				" " +
				AnalyticEvent.event.logout
		);
		this.messageService.deregister();
		this.storage.clearLogin();
		this.Authenticator.next(false);
	}

	isAuthenticated(): boolean {
		amplitude.getInstance().setUserId(null); // not string 'null'
		amplitude.getInstance().regenerateDeviceId();
		return this.storage.get("isAuthenticated") ? true : false;
	}
}