import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { Organization, titleClassNameDict } from '../../models/organization';
import { MenuService } from 'app/lib/menu.service';

@Component({
    selector: 'app-resources-detail',
    templateUrl: './resources-detail.component.html',
    styleUrls: ['./resources-detail.component.scss'],
})
export class ResourcesDetailComponent implements OnInit {
    innerhtml: string;
    title: string;
    value: boolean;
    organization: Organization;
    resourceSet: any;
    question: any;
    resources: any;
    selectedFilter: string = '';
    filters: string[] = Object.keys(titleClassNameDict);
    fitlers = this.filters.push('default');

    constructor(
        private router: Router,
        private api: ApiService,
        private log: LogService,
        private menuservice: MenuService,
        private _router: ActivatedRoute
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const navigation = this.router.getCurrentNavigation();
                if (
                    navigation &&
                    navigation.extras &&
                    navigation.extras.state
                ) {
                    // this.resources = navigation.extras.state.resources;
                    this.resources = navigation.extras.state.resources.map(
                        resource => {
                            resource.className = resource.className
                                ? resource.className
                                : 'default';
                            return resource;
                        }
                    );
                    this.resourceSet = navigation.extras.state.resourceSet;
                    this.selectedFilter = this.resourceSet.className;
                }
            }
        });
    }

    ngOnInit() {}

    onFinish() {}

    onResourceClick(contact) {
        window.open(contact.website, '_blank');
        this.api
            .post('analytics/resourceclick', {
                resourceGroup: contact,
            })
            .subscribe(
                (result: any) => {},
                (error: any) => {
                    this.log.error('Error logging link click');
                }
            );
    }

    onResourceInternalClick(contact) {
        this.api
            .post('analytics/resourceclick', {
                resourceGroup: contact,
            })
            .subscribe(
                (result: any) => {
                    this.router.navigateByUrl(contact.internal);
                    // this.dialogRef.close();
                },
                (error: any) => {
                    this.log.error('Error logging link click');
                }
            );
    }
    filterChange(filter: string) {
        let match = this.resources.find(rs => rs.className === filter);
        this.resourceSet = match ? match : {};
        this.selectedFilter = filter;
    }
    isObjectEmpty(): boolean {
        return Object.keys(this.resourceSet).length === 0;
    }

    hasFilteredObjects(filter: string): boolean {
        const match = this.resources.find(rs => rs.className === filter);
        return match !== undefined;
    }
    get menuService(): MenuService {
        return this.menuservice;
    }
}
