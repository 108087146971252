<div id="page-thought-diary">
    <page-header [cssClass]="'addiction'" [headerText]="title" [backText]="back" [backLink]="backLink" [headerImage]="'./assets/img/icons/resources/substance.png'"></page-header>
    <div class="col-sm-12">
        <div class="page-section no-margin page-header-body">
            <p translate>
                Have you decided to stop drinking or abusing substances? Use the addiction helper to analyze your situation and set goals.
            </p>
            <a autoFocus tabindex="0" routerLink="new" class="btn btn-primary btn-inline btn-wide" translate>Begin addiction helper</a>
        </div>
  
    </div>

</div>