<div id="support-page" class="app-c-modal--secondary">
            <div class="app-c-modal-secondary__header">
                <div class="app-c-modal-secondary__header-left">
                    <h3 class="app-c-heading--h3" translate>support.title</h3>
                </div>
                <div class="app-c-modal-secondary__header-right">
                    <button aria-label="Close support form" class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-14" (click)="onClose()">
                        <svg-icon icon="close" class="w-100 h-100"></svg-icon>
                    </button>
                </div>
            </div>

            <div class="app-c-modal-secondary__body" *ngIf="user">
                <h4 class="app-c-heading--h4" translate>support.help</h4>
                <form [formGroup]="supportForm" (ngSubmit)="onSubmitSupportRequest()">
                    <div class="input-conatiner app-room-for-positive-create">
                        <div class="app-c-form-group">
                            <div class="app-c-form-group">
                                <div class="app-c-form-group__top">
                                  <div class="app-c-form-group__top-left">
                                    <label class="app-c-form--label" translate>support.description</label>
                                  </div>
                                </div>
                                <mat-form-field appearance="outline">
                                    <textarea aria-label="hint-description" title="Please describe in detail what happened." matInput autoFocus #text aria-live="polite"  tabindex="0" aria-describedby="hint-description" formControlName="supportRequest" name="supportRequest" id="supportRequest" 
                                    [placeholder]="'support.description' | translate"></textarea>
                                </mat-form-field>
                                <mat-error aria-live="assertive" *ngIf="f.supportRequest.touched && f.supportRequest.errors?.required">
                                    {{popups[0].body}}
                                </mat-error>
                                <span class="helper-text" id="hint-description" aria-live="polite" tabindex="0" translate>support.hintdescription</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="app-c-modal-secondary__body" *ngIf="!user">
                <h4 class="app-c-heading--h4" translate>support.help</h4>
                <form [formGroup]="supportForm" (ngSubmit)="onSubmitSupportRequest()">
                    <div class="input-conatiner app-room-for-positive-create">
                        
                        <div class="app-c-form-group">
                            <div class="app-c-form-group__top">
                              <div class="app-c-form-group__top-left">
                                <label class="app-c-form--label" translate>support.name</label>
                              </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="name" aria-label="Name" tabindex="0" id="name" type="text" name="name"
                                [placeholder]="'support.name' | translate" />
                                <mat-error *ngIf="(submitButtonPressed || f.name.touched) && f.name.errors?.required">
                                    {{popups[1].body}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="app-c-form-group">
                            <div class="app-c-form-group__top">
                              <div class="app-c-form-group__top-left">
                                <label class="app-c-form--label" translate>support.email</label>
                              </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <input matInput formControlName="email" aria-label="Email" tabindex="0" id="email" type="text" name="email"
                                [placeholder]="'support.email' | translate" />
                                <mat-error *ngIf="(submitButtonPressed || f.email.touched) && f.email.errors?.required">
                                    {{popups[2].body}}
                                </mat-error>
                                <mat-error *ngIf="f.email.touched && f.email.errors?.pattern">
                                    {{popups[3].body}}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="app-c-form-group">
                            <div class="app-c-form-group__top">
                              <div class="app-c-form-group__top-left">
                                <label class="app-c-form--label" translate>support.description</label>
                              </div>
                            </div>
                            <mat-form-field appearance="outline">
                                <textarea [attr.aria-label]="'support.description'| translate" matInput  #text formControlName="supportRequest" name="supportRequest" id="supportRequest" 
                                [placeholder]="'support.description' | translate"></textarea>
                            <mat-error
                                *ngIf="(submitButtonPressed || f.supportRequest.touched) && f.supportRequest.errors?.required">
                                {{popups[0].body}}
                            </mat-error>
                            </mat-form-field>
                            <span class="helper-text" translate>support.hintdescription</span>
                        </div>
                    </div>
                </form>
            </div>
            
            <div class="app-c-modal-secondary__footer">
                <span class="red" *ngIf="submitButtonPressed && !supportForm.valid && errors === ''">Please check the errors</span>
                <a tabindex="4" class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="onCancelSupportRequest()" (keypress)="onCancelSupportRequest()" translate>support.cancel</a>
                <a tabindex="6" class="app-c-btn app-c-btn--primary app-c-btn--curve" (click)="onSubmitSupportRequest()" (keypress)="onSubmitSupportRequest()" translate>support.submit</a>
            </div>
    <!-- </mat-dialog-content> -->
</div>