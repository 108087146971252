<div class="page-section-content">
	<ng-template mat-tab-label> Export Resources </ng-template>
	<div class="form-wrapper">
		<h3>- Select organization to export -</h3>
		<form
			novalidate
			[formGroup]="exportResourceForm"
			(ngSubmit)="exportToExcel()"
		>
			<div class="icon-input">
				<label for="parentId" translate>Source Org</label>
				<div>
					<mat-select
						id="parentId"
						formControlName="organizationID"
						required
						placeholder="Select Organization"
					>
						<mat-option [value]="null">None</mat-option>
						<mat-option
							*ngFor="let org of organizations"
							[value]="org.id"
						>
							{{ org.name }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="orgError !== ''">
						{{ orgError }}
					</mat-error>
					<mat-error
						*ngIf="
							orgError === '' &&
							f.organizationID.touched &&
							f.organizationID.errors?.required
						"
					>
						Please select an organization
					</mat-error>
				</div>
			</div>

			<button
				tabindex="0"
				class="btn-primary btn-bold btn"
				[ngClass]="{ active: exportResourceForm.invalid }"
				[disabled]="exportResourceForm.invalid"
			>
				Export
			</button>
		</form>
	</div>
</div>
