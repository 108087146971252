<div class="row text-center" id="nav-buttons">
    <div class="col-md-4 col-xs-12">
        <div tabindex="0" id="previous" class="btn btn-inline" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false" translate>
            calendar.previous
        </div>
    </div>
    <div class="col-md-4 col-xs-12">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4 col-xs-12">
        <div tabindex="0" id="next" class="btn btn-inline" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="activeDayIsOpen = false" translate>
            calendar.next
        </div>
    </div>
</div>

<!-- <div class="mat-spinner">
    <mat-spinner *ngIf="loading"></mat-spinner>
</div> -->

<div id="above-calendar"></div>
<mwl-calendar-month-view *ngIf="!loading" [viewDate]="viewDate" [events]="events" (dayClicked)="dayClicked($event)">
</mwl-calendar-month-view>
<div id="below-calendar"></div>

<!--
    <strong>Scheduled events:</strong><br>
    <div class="mini-legend">
        <div class="item scheduled" translate>
            <i class="bullet"></i>calendar.legend.scheduled
        </div>
    </div>
-->
<strong>Completed events:</strong><br>
<legend-component></legend-component>
<div class="clearfix"></div>