import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ProfessionalRoutingModule } from './professional-routing.module';
import { DashboardPageExecutive } from './dashboard-executive/dashboard-executive.component';
import { UserDetailsPage } from './user-details/user-details.component';
import { DasAssessmentListingPage } from '../../pages/das-assessment-listing/das-assessment-listing.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MomentModule } from 'angular2-moment';
import { ActivitySchedulerListingPage } from '../practice/activity-scheduler-listing/activity-scheduler-listing.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxTablePaginationModule } from 'ngx-table-pagination';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserDetailsOverviewComponent } from './user-details-overview/user-details-overview.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AssessmentViewComponent } from './assessment-view/assessment-view.component';
import { UserDetailsDashboardComponent } from './user-details-dashboard/user-details-dashboard.component';
import { MoodcheckViewComponent } from './moodcheck-view/moodcheck-view.component';
import { ActionPlanViewComponent } from './action-plan-view/action-plan-view.component';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserDetailsSeriesComponent } from './user-details-series/user-details-series.component';
import { ProfessionalService } from './professional.service';
import { ToolsViewComponent } from './tools-view/tools-view.component';

@NgModule({
    declarations: [
        DashboardPageExecutive,
        UserDetailsPage,
        DasAssessmentListingPage,
        ActivitySchedulerListingPage,
        UserDetailsOverviewComponent,
        AssessmentViewComponent,
        MoodcheckViewComponent,
        UserDetailsDashboardComponent,
        ActionPlanViewComponent,
        UserDetailsSeriesComponent,
        ToolsViewComponent,
    ],
    imports: [
        CommonModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ProfessionalRoutingModule,
        SharedModule,
        MomentModule,
        MatTabsModule,
        MatCardModule,
        MatTableModule,
        NgxPaginationModule,
        MatPaginatorModule,
        NgxTablePaginationModule,
        MatExpansionModule,
        LazyLoadImageModule,
        MatTableModule,
        MatSortModule,
        MatProgressSpinnerModule,
    ],
    providers: [ProfessionalService],
    exports: [],
    entryComponents: [],
})
export class ProfessionalModule {}
