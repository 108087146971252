<div id="page-forgot-password" class="public-portal-right-col">
    <h2 id="section-title" class="wt-access">Page Not Found</h2>
    <div class="center form-screen">
        <div class="img-wrapper">
            <img alt="Welltrack badge" src="./assets/img/welltrack-boost-circle.png" style="width: 122px; height: auto;" alt="">
        </div>

		<h1 class="not-found-title">404</h1>
        <div class="title-wrapper">
            <h3 translate>notFound.title</h3>
            <p translate>notFound.body</p>
        </div>

        <div class="footer">
            <p translate>notFound.back<a class="wt-auto-forgotpassword-back" routerLink="/" translate>notFound.sign</a></p>
        </div>

    </div>
</div>
