<div class="main-container-section">
    <div class="app-l-moods__select" *ngIf="user.userType === 'user'">
        <mat-select
            disableOptionCentering
            [(ngModel)]="selectedMoodcheckCategory"
            class="{{ selectedMoodcheckCategory }}"
            panelClass="app-l-moods-selectbox__wrapper">
            <mat-select-trigger class="app-l-moods-select__trigger">
                <svg-icon [icon]="'emoji-' + indexselected"></svg-icon>
                <div class="app-l-moods-select__label">
                    {{ 'dashboard.' + selectedMoodcheckCategory | translate }}
                </div>
            </mat-select-trigger>
            <mat-option
                *ngFor="let mood of moods; let index = index"
                [value]="mood.key"
                class="{{ mood.key }}"
                (click)="changeMoodcheckCategory(mood.key, mood.iconIndex)"
                [ngClass]="mood">
                <div class="app-l-selected__row">
                    <div class="app-l-selected__icon">
                        <!-- <svg-icon icon="emoji-10" class="w-100 h-100"></svg-icon> -->
                        <svg-icon
                            [icon]="'emoji-' + mood.iconIndex"
                            class="w-100 h-100"></svg-icon>
                    </div>
                    <div class="app-l-selected__cnt">
                        {{ 'dashboard.' + mood.key | translate }}
                    </div>
                </div>
            </mat-option>
        </mat-select>
    </div>
    <div class="app-l-moods__select-role" *ngIf="user.userType != 'user'">
        <mat-select
            disableOptionCentering
            [(ngModel)]="selectedMoodcheckCategory"
            class="{{ selectedMoodcheckCategory }}"
            panelClass="app-l-moods-selectbox__wrapper">
            <mat-select-trigger class="app-l-moods-select__trigger">
                <svg-icon [icon]="'emoji-' + indexselected"></svg-icon>
                <div class="app-l-moods-select__label">
                    {{ 'dashboard.' + selectedMoodcheckCategory | translate }}
                </div>
            </mat-select-trigger>
            <mat-option
                *ngFor="let mood of moods; let index = index"
                [value]="mood.key"
                class="{{ mood.key }}"
                (click)="changeMoodcheckCategory(mood.key, mood.iconIndex)"
                [ngClass]="mood">
                <div class="app-l-selected__row">
                    <div class="app-l-selected__icon">
                        <!-- <svg-icon icon="emoji-10" class="w-100 h-100"></svg-icon> -->
                        <svg-icon
                            [icon]="'emoji-' + mood.iconIndex"
                            class="w-100 h-100"></svg-icon>
                    </div>
                    <div class="app-l-selected__cnt">
                        {{ 'dashboard.' + mood.key | translate }}
                    </div>
                </div>
            </mat-option>
        </mat-select>
    </div>
    <div class="app-l-overall-tab">
        <div class="app-c-tab--static">
            <div class="app-c-tab--static-header scrollbar">
                <div class="app-c-tab--static-header-list">
                    <div
                        class="app-c-tab--static-item"
                        tabindex="0"
                        [ngClass]="{ active: selectedTab == 'activities' }"
                        (keypress)="tabChange('activities')">
                        <a tabindex="0" (click)="tabChange('activities')"
                            ><span translate>dashboard.activity</span></a
                        >
                    </div>
                    <div
                        class="app-c-tab--static-item"
                        tabindex="0"
                        [ngClass]="{ active: selectedTab == 'people' }"
                        (keypress)="tabChange('people')">
                        <a tabindex="0" (click)="tabChange('people')"
                            ><span translate>dashboard.people</span></a
                        >
                    </div>
                    <div
                        class="app-c-tab--static-item"
                        tabindex="0"
                        [ngClass]="{ active: selectedTab == 'places' }"
                        (keypress)="tabChange('places')">
                        <a tabindex="0" (click)="tabChange('places')"
                            ><span translate>dashboard.places</span></a
                        >
                    </div>
                    <div
                        class="app-c-tab--static-item"
                        tabindex="0"
                        [ngClass]="{ active: selectedTab == 'notes' }"
                        (keypress)="tabChange('notes')">
                        <a tabindex="0" (click)="tabChange('notes')"
                            ><span translate>dashboard.thoughts</span></a
                        >
                    </div>
                </div>
            </div>
            <div class="app-c-tab--static-body">
                <!--tab section start-->
                <div
                    class="cards-container scrollbar"
                    *ngIf="moodDetailedData.length">
                    <!--fantastic card-->
                    <ng-container *ngFor="let item of moodDetailedData">
                        <div
                            class="card"
                            *ngIf="selectedTab != 'notes'"
                            [ngClass]="selectedMoodcheckCategory + '-card'">
                            <div class="app-l-card__avatar">
                                <img
                                    [defaultImage]="
                                        '../../../assets/img/dashboard/no-image.svg'
                                    "
                                    [lazyLoad]="item.imgUrl" />
                            </div>
                            <div class="app-l-card__content">
                                <div class="app-l-card__cnt-top">
                                    <div class="app-l-cc__left">
                                        <h5 class="app-c-body-text--12-m">
                                            {{ item.name }}
                                        </h5>
                                    </div>
                                    <div class="app-l-cc__right">
                                        <div class="app-l-cc__percentage">
                                            {{
                                                (item.count / totalCount) * 100
                                                    | number: '1.0-0'
                                            }}<span>%</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="app-l-card__cnt-bottom"></div>
                            </div>
                            <div class="app-l-card__icon">
                                <svg-icon
                                    [icon]="
                                        'emoji-' + indexselected
                                    "></svg-icon>
                            </div>
                        </div>
                        <div
                            class="card note-card"
                            *ngIf="selectedTab == 'notes'"
                            [ngClass]="selectedMoodcheckCategory + '-card'">
                            <div class="app-l-card__content">
                                <div>
                                    <p
                                        class="note-card-details"
                                        [innerHTML]="
                                            item.name | newlineToBr
                                        "></p>
                                </div>
                            </div>
                            <!-- <div class="app-l-card__icon">
                  <svg-icon [icon]="'emoji-' + indexselected"></svg-icon>
                </div> -->
                        </div>
                    </ng-container>
                </div>
                <div
                    class="app-l-no-mood-check"
                    *ngIf="!moodDetailedData.length">
                    <div class="no-data-image">
                        <svg-icon icon="emoji-8"></svg-icon>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round1"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round2"></span>
                        <span
                            class="app-l-no-mood__round app-l-no-mood__round3"></span>
                    </div>
                    <h5 class="app-c-body-text--16-m">{{ 'No Data' }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>
