import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { config } from '../../../environments/all';
import { ModalService } from '../../lib/modal.service';
import { SupportComponent } from '../../components/support/support';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../lib/storage.service';
import { FooterService } from 'app/lib/url.service';

import * as _ from 'lodash';

@Component({
	selector: 'app-public-portal',
	templateUrl: './public-portal.component.html',
	styleUrls: ['./public-portal.component.scss'],
	providers: [{ provide: CarouselConfig, useValue: { interval: false, noPause: true } }]
})
export class PublicPortalTemplate implements OnInit {

	navIn: boolean;
	playStore: string;
	appStore: string;
	language: string;
	containerClass: string;
	showLanguage: boolean;
	showBottom: boolean = false;
	showTop: boolean;

	constructor(
		private router: Router,
		private modalService: ModalService,
		private translateService: TranslateService,
		private storage: StorageService,
		private activatedRoute: ActivatedRoute,
		private _router: Router,
		private ftService: FooterService
	) {
		this.navIn = false;
		this.appStore = config.appStore;
		this.playStore = config.playStore;
		this.language = 'en'
	}

	ngOnInit() {
		this.showBottom = true;
		this.showTop = true;

		this.translateService.stream('lang').subscribe((res: any) => {
			if (res === 'en') {
				this.language = 'En'
				this.onChangeLanguage('en');
			} else if(res === 'es'){
				this.language = 'Es'
				this.onChangeLanguage('es');
			} else {
				this.language = 'Fr'
				this.onChangeLanguage('fr');
			}
		});

		let segment = this.router.url;

		if (
			segment.search('medical-resources') !== -1 ||
			segment.search('privacy-policy') !== -1 ||
			segment.search('subscribe') !== -1 ||
			segment.search('purchase') !== -1 ||
			segment.search('rename') !== -1 ||
			segment.search('terms-and-conditions') !== -1
		) {
			this.showLanguage = false;
			this.containerClass = 'larger-container';
			this.showBottom = false;
		}
		else {
			this.showLanguage = true;
			this.containerClass = 'small-container';
		}

		if(segment.search('referral') !== -1 ){
			this.showBottom = false;
		}


		this.activatedRoute.queryParams.subscribe(params => {

			if (params['auth_type']) {
				if (params['type'] !== null && params['type'] ==='mobile' ) {
					this.showBottom = false;
					this.showTop = false;
				}
			}
		});

		this.ftService.hideFooter$.subscribe(shouldHide => {
			if(segment.search('rename') !== -1 ){
				this.showBottom = false;
			}else{
				this.showBottom = shouldHide;
			}
		  });
		
	}

	onChangeLanguage(value: string) {
		let lang = value.toLowerCase();

		if (lang === 'en') {
			this.language = _.capitalize('en');
		} else if (lang === 'es'){
			this.language = _.capitalize('es');
		} else {
			this.language = _.capitalize('fr');
		}

		this.translateService.use(lang);
		this.translateService.setDefaultLang(lang);
		this.storage.set('lang', lang, false);
	}

	onNavToggle() {
		this.navIn = !this.navIn;
	}

	onNavClose() {
		this.navIn = false;
	}

	onShowSupportPage() {
		this.modalService.showComponent(SupportComponent, {}, "app-c-modal--cure");
	}

	onSkip() {
		setTimeout(function () {
			jQuery('.public-portal-right-col input').first().focus();
		}, 500);
	}

	getDate(){
		return new Date().getFullYear();
	}
}
