import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../../lib/storage.service';
import { AfterViewChecked } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import * as _ from 'lodash';

@Component({
    selector: 'activity-select',
    templateUrl: 'activity-select.html',
    styleUrls: ['./activity-select.scss'],
})
export class ActivitySelect implements OnInit, AfterViewChecked {
    activities: any = [];
    pleasure: any = [];
    social: any = [];
    achievement: any = [];
    allactivities: any = [];
    placeholder = '';
    customOption = '';
    // isLoaded: boolean = false;
    selected: any;
    activityType: any;
    activeOptions: any = { pleasure: '', social: '', achievement: '' };
    activeOption: string;
    selectedActivity = '';
    popups: any;
    customOptionChangeList = {
        add: [],
        delete: [],
    };
    activityData: any;
    customActivityPopups: any;
    constructor(
        private translate: TranslateService,
        private storage: StorageService,
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modalService: ModalService,
        public dialogRef: MatDialogRef<ActivitySelect>,
        private log: LogService
    ) {
        this.activityType = this.data.data.activityType;
        this.activityData = this.data.data.activityData;
        this.activities = this.activityData.Activities.filter(
            activity => !activity.isDefault
        );
    }

    ngAfterViewChecked() {}

    ngOnInit() {
        // this.activatedRoute.params.subscribe(params => {
        // 	if(params['id']) {
        // 		this.api.get(`practice/activityscheduler/${params['id']}`).subscribe((res: any) => {
        // 			this.selectedActivity = res.data.Title;
        // 		});
        // 	}
        // });
        this.translate.get('moodcheck').subscribe((res: any) => {
            this.placeholder = res.customOption;
            this.popups = res.popups;
        });
        // this.getConfig();
        // this.translate.get('lang').subscribe((res: any) => {
        // 	let all;
        // 	if (this.storage.get('activityOptions', true)) {
        // 		all = this.storage.get('activityOptions', true);
        // 		this.activeOptions.pleasure = all.pleasure;
        // 		this.activeOptions.social =	all.social;
        // 		this.activeOptions.achievement = all.achievement;
        // 	}
        // 	else {
        // 		all = config;
        // 		if (res === 'en') {
        // 			this.activeOptions.pleasure = all.activities.pleasure_en;
        // 			this.activeOptions.social = all.activities.social_en;
        // 			this.activeOptions.achievement = all.activities.achievement_en;
        // 		}
        // 		else {
        // 			this.activeOptions.pleasure = all.activities.pleasure_fr;
        // 			this.activeOptions.social = all.activities.social_fr;
        // 			this.activeOptions.achievement = all.activities.achievement_fr;
        // 		}
        // 	}

        // 	let index = -1;
        // 	let foundindex = -1;

        // 	if ((index = _.findIndex(this.activeOptions.pleasure, { text: this.selectedOption })) !== -1) {
        // 		this.activities = this.activeOptions.pleasure;
        // 		this.activeOption = 'pleasure';
        // 		foundindex = index;
        // 	}

        // 	if ((index = _.findIndex(this.activeOptions.social, { text: this.selectedOption })) !== -1) {
        // 		this.activities = this.activeOptions.social;
        // 		this.activeOption = 'social';
        // 		foundindex = index;
        // 	}

        // 	if ((index = _.findIndex(this.activeOptions.achievement, { text: this.selectedOption })) !== -1) {
        // 		this.activities = this.activeOptions.achievement;
        // 		this.activeOption = 'achievement';
        // 		foundindex = index;
        // 	}

        // 	if (foundindex === -1) {
        // 		if (this.activityType === 1) {
        // 			this.activities = this.activeOptions.pleasure;
        // 		}
        // 		else if (this.activityType === 2) {
        // 			this.activities = this.activeOptions.social;
        // 		}
        // 		else if (this.activityType === 3) {
        // 			this.activities = this.activeOptions.achievement;
        // 		}
        // 	}

        // 	for (let i = 0; i < this.activities.length; i++) {
        // 		if (i === foundindex) {
        // 			this.activities[i].isSelected = true;
        // 		}
        // 		else {
        // 			this.activities[i].isSelected = false;
        // 		}
        // 	}

        // });

        this.translate.get('as').subscribe(res => {
            this.placeholder = res.select.placeholder;
            this.customActivityPopups = res.customActivityPopup;
        });
    }
    getConfig() {
        this.api.get('config/activityscheduler').subscribe((result: any) => {
            // this.isLoaded = true;
            const configData = result.default;
            if (configData !== null) {
                const configListing = Object.values(configData);
                this.activityData = configListing.find(
                    (activity: any) =>
                        JSON.parse(activity.Value) === this.activityType
                );
                this.activities = this.activityData.Activities;
                this.activities.forEach(element => {
                    if (element.Value === this.selectedActivity) {
                        element.isSelected = true;
                    } else {
                        element.isSelected = false;
                    }
                });
            }
        });
    }

    onAddCustomOption() {
        if (!this.customOption) {
            return;
        } else {
            let existing = _.find(this.activities, {
                name: this.customOption.trim() as any,
            });
            if (existing) {
                this.modalService.showAlert(
                    this.popups.alert,
                    this.popups.optionAlreadyExist
                );
                return;
            } else {
                var option = {
                    Value: this.customOption,
                    isDefault: false,
                    Key: this.customOption,
                };
                this.activities.unshift(option);
                this.customOptionChangeList['add'].push({
                    option: this.activityData.Key.toLowerCase().replace(
                        /\s/g,
                        ''
                    ),
                    value: this.customOption,
                });
                let activity = { Value: this.customOption, isSelected: false };
                this.toggleOption(activity);
                delete this.customOption;
            }
        }
    }

    toggleOption(activity: any) {
        for (let i = 0; i < this.activities.length; i++) {
            if (activity.Value === this.activities[i].Value) {
                this.activities[i].isSelected = true;
            } else {
                this.activities[i].isSelected = false;
            }
        }
        activity.isSelected = true;
        this.selected = activity;
        this.sync();
        this.saveOptions('add');
    }
    saveOptions(type: string) {
        let payloadData = {
            custom: this.customOptionChangeList,
        };

        this.api.post('config/addcustomoptions', payloadData).subscribe(
            (result: any) => {
                this.customOptionChangeList.add = [];
                this.customOptionChangeList.delete = [];
                if (type == 'add') {
                    this.modalService.showAlert(
                        this.customActivityPopups.customActivityAddSuccessTitle,
                        this.customActivityPopups.customActivityAddSuccessBody
                    );
                } else {
                    this.modalService.showAlert(
                        this.customActivityPopups
                            .customActivityDeleteSuccessTitle,
                        this.customActivityPopups
                            .customActivityDeleteSuccessBody
                    );
                }
                this.dialogRef.close(this.selected);
            },
            (error: any) => {
                this.log.error('Error fetching user. ' + error.message);
            }
        );
    }
    sync() {
        this.activeOptions[this.activeOption] = this.activities;
        this.storage.set('activiyScheduler', this.activeOptions, true);
    }
    deleteCustom(activity) {
        this.customOptionChangeList['delete'].push({
            id: activity.ID,
            option: activity.Option,
            value: activity.Value,
        });
        this.saveOptions('delete');
    }
    close() {
        this.dialogRef.close();
    }
}
