import { Component, ViewChild, ViewEncapsulation, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { data } from 'jquery';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { UserService } from 'app/lib/user.service';
import { AnalyticEvent } from 'app/lib/analytic-event';
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  dynamicForm: FormGroup;
  formData = []
  finalData = [];
  apiDAta: any;
  isSubmitted = false;
  isAgeValid = false;
  dataLength: number;
  userData: any;
  showInvalid: boolean = false;
  validvalue: any;
  maxval: any;
  minval: any;
  constructor(public dialogRef: MatDialogRef<DynamicFormComponent>,
    private fb: FormBuilder,
    private api: ApiService,
    private modalService: ModalService,
    private log: LogService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.apiDAta = data.data;
    let objs = data['data'].data;
    this.dataLength = objs.length;
    for (let obj of objs) {
      this.formData.push(obj[0]);
    }

    this.userData = this.userService.getUser();
  }

  ngOnInit(): void {
    this.dynamicForm = this.generateFilterForm();
  }

  // Form generation
  generateFilterForm() {
    const group: any = {};
    this.formData.forEach((field, index) => {
      field['Key'] = 'qn' + index;
      group[field.Key] = field.Required ? new FormControl(field.Value || '', Validators.required)
        : new FormControl(field.Value || '');
        if(field.hasOwnProperty('sub')) {
          field.sub.forEach(subQuestion =>{
            subQuestion['show'] = false;
          });
        }
    });

    return new FormGroup(group);
  }

  // Save APi
  doSave() {
    this.isSubmitted = true;

    this.dynamicForm.markAllAsTouched();
    if (this.apiDAta.name === "NPS Survey" && this.isFormValid() || this.apiDAta.name !== "NPS Survey"  && this.dynamicForm.valid) {
     this.callDynamicFormSave();
    }
  }

  // Isform Valids
  isValid(subQuestion, index,ev) {
    let isSubQuestionAnswerExist = false;
    this.finalData = this.finalData.filter(data =>data.value);
    if (this.isSubmitted && subQuestion.Required) {
      this.dynamicForm.markAllAsTouched();
      const isSubQuestionAnswer = this.finalData.filter(data => subQuestion.options.map(sub => sub.ID).includes(data.name));
      isSubQuestionAnswerExist = isSubQuestionAnswer.length == 0 ? true : false;
      return isSubQuestionAnswerExist;
    }
    return isSubQuestionAnswerExist;
  }

  isMainQuestionAnswered(mainQn){
    let mainQuestionAnswered = false;
    if (this.isSubmitted && mainQn.Required) {
     const mainQnAns = this.finalData.filter(data => data.id == mainQn.ID);
     mainQuestionAnswered = mainQnAns.length == 0 ? true : false;
      return mainQuestionAnswered;
    }
    return mainQuestionAnswered;
  }

  showInvalidAge(data) {
    let validAge = false;
    if (data.Required && data.Type === 8) {
      const ageQn = this.finalData.filter(findata => findata.id === data.ID);
      let validvalue = JSON.parse(data.Validations)
      this.maxval = validvalue.max; this.minval = validvalue.min;
      if((validvalue.min != null || 0) && (validvalue.max != null || 0)){
        validAge = (ageQn[0].value >= validvalue.min && ageQn[0].value <= validvalue.max)?false:true;
      }else {
        validAge = (ageQn[0].value >= validvalue.min || ageQn[0].value <= validvalue.max)?false:true;
      }
      this.isAgeValid = validAge;
      if(validAge){
        this.dynamicForm.get(data.Key).markAsTouched();
        this.dynamicForm.get(data.Key).setErrors({
          notMatched: validAge
       });
      }
      else{
        this.dynamicForm.get(data.Key).markAsUntouched();
        this.dynamicForm.get(data.Key).setErrors(null);
      }
    }
    this.isAgeValid = validAge;
  }


  isNotRequiredQuestion(data) {
    let optionId = this.getOptionId(data)
    if((data.hasOwnProperty('Required') && data.Required) || optionId){

      if (optionId != null){
         let subData = this.getRequiredSubQuestion(optionId,data)
         if(subData === undefined)return true;
         if (subData != null){
          return this.isNotRequiredQuestion(subData)
         }else{
           return false
         }

      }else{
        return false
      }
   }else{
     return true
   }

  }

   getOptionId(data){
    let finalDetails = this.finalData.filter(finalValue => finalValue.id === data.ID);

    return finalDetails.length > 0 ? finalDetails[0].value : null;

   }

   getRequiredSubQuestion(questionValue,data){
    if(!data.hasOwnProperty("sub")){
      //to do - Need to check the options value in the final data

      return
    }
    let subData = data.sub.filter(subOption => subOption.range.includes(questionValue))
    return subData.length > 0 ? subData[0] : null;
   }

  // is NPS slider selection and subQuestion validation
  isFormValid() {
    let isAnswered = true;
      for(let i = 0; i< this.formData.length; i++) {
        if(!this.isNotRequiredQuestion(this.formData[i])){
          isAnswered = false;
          break
        }
      }
      return isAnswered

  }



  // Save APi
  callDynamicFormSave() {
    let finalData = {
      Type: this.apiDAta.id,
      Data: JSON.stringify(this.finalData)
    };
    this.isSubmitted = false;
    this.log.debug(JSON.stringify(this.finalData));
    this.api.post('form/' + this.apiDAta.id, finalData).subscribe(
      (result: any) => {
        this.modalService.showAlert('Success', result.message);
        this.dialogRef.close(true);
        this.userService.reloadUser();
        this.log.event(this.apiDAta.name + ' ' + AnalyticEvent.event.updated)
      },
      (error: any) => {
        this.modalService.showAlert('Error', error.message);
        this.dialogRef.close();
      },
      () => {
      });

  }

  // Field type change
  valueChange(data, ev, opt) {
    let valueSelected: any;
   if (data.Type === 5 || data.Type === 6 || data.Type === 8) {
      valueSelected = {
        "value": ev,
        "name": data.options[0].ID,
        "id": data.options[0].QuestionID
      }

      const index = this.finalData.findIndex(fd => {
        return fd.name === data.options[0].ID
      });
      if (index >= 0) {
        this.finalData.splice(index, 1)
      }
    } else {
      valueSelected = {
        "value": data.ID,
        "name": (data.Type === 1 || data.Type === 2) ? ev.ID : ev.value.ID,
        "id": (data.Type === 1 || data.Type === 2) ? ev.QuestionID : ev.value.QuestionID,
      }
      const index = this.finalData.findIndex(fd => {
        return fd.id === ((data.Type === 1 || data.Type === 2) ? ev.QuestionID : ev.value.QuestionID)
      });
      if (index >= 0) {
        this.finalData.splice(index, 1)
      }
    }
    this.finalData.push(valueSelected);
    this.showInvalidAge(data);
  }

  onClose() {
    this.dialogRef.close();
  }


  // NPS number scale selection
  scaleSelectionCompleted(data) {
    const subQuestion = this.formData[data.index].sub;
    this.formData[data.index].sub.forEach((subQn, subIndex) => {
      // Make all question hide on number selection
      subQn.show = false;
      // check the selecetd value exist in the range
      if (subQn.range.includes(data.options.OptionValue)) {
        subQn.show = true;
      }
    });
    const valueSelected = {
      "value": data.options.OptionValue,
      "name": data.options.ID,
      "id": data.options.QuestionID,
    }
    const index = this.finalData.findIndex(fd => {
      return fd.id === data.options.QuestionID
    });
    if (index >= 0) {
      this.finalData.splice(index, 1)
    }
    this.finalData.push(valueSelected);
  }


}
