import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { InviteProfessional } from 'app/components/invite-professional/invite-professional.component';
import { InviteProfessionalsComponent } from 'app/components/invite-professionals/invite-professionals.component';
import { Invite } from 'app/components/invite/invite';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { Organization } from 'app/models/organization';
import { User } from 'app/models/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss']
})
export class MyTeamComponent implements OnInit {
  user: User;
    organization: Organization;
    myTeamList = [];
    isLoaded: boolean = false;
    questionIndex: number = 0;
    selectedType: any = 'all';
    userRole = [
      {
          title: 'OrgAdmin',
          key: 'orgadmin',
          roleType:'orgadmin',
          type: 3
      },
      {
          title: 'OrgAdmin Pro',
          key: 'orgadminpro',
          roleType:'orgadmin',
          type: 4
      },
      {
          title: 'Professional',
          key: 'professional',
          roleType:'professional',
          type: 2
      }
  ];
    orgId: any;
    constructor(
        private modalService: ModalService,
        private api: ApiService,
        private userService: UserService,
        private translate: TranslateService,
        private log: LogService,
        private storage: StorageService
    ) {
        this.user = this.userService.getUser();
    }

    ngOnInit(): void {
        let orgId;
        if (this.storage.get('orgselect')) {
            this.orgId = this.storage.get('orgselect');
        } else {
            this.user.primaryOrganization;
            this.orgId = this.user.primaryOrganization.id;
        }
        if (this.orgId) {
            this.api.get('organizations/' + this.orgId).subscribe(
                (result: any) => {
                    this.organization = new Organization(result.data);
                    this.getMyteam();
                },
                (error: any) => {
                    this.log.error(
                        'Error getting organizations. ' + error.message
                    );
                },
                () => {}
            );
        } else {
            this.organization = this.user.primaryOrganization;
            this.getMyteam();
        }
    }

    getMyteam() {
        let apiUrl = `organization/adminusers/${this.orgId}`;

        if (this.selectedType !== 'all') {
            apiUrl += `?usertype=${this.selectedType}`;
          }

        this.api.get(apiUrl).subscribe(
            (results: any) => {
                this.myTeamList = results;
                this.log.debug(this.myTeamList);
                this.isLoaded = true;
            },
            (error: any) => {
                this.log.error('Error loading. ' + error.message);
                this.isLoaded = true;
            }
        );
    }

    getUserRoleTitle(userType: string, type: number): string {
        const role = this.userRole.find(role => role.roleType === userType && role.type === type);
        return role ? role.title : 'Unknown Role';
    }

    deleteInvitation(id) {
        this.modalService.showConfirmation(
                'Delete User',
                'Are you sure you want to remove this user and revoke their admin privileges?'
            )
            .afterClosed()
            .subscribe(result => {
                if (result) {
                    let payload = {
                    };
                    this.api.post("adminuser/delete/" + id, payload).subscribe(
                        (result: any) => {
                            let index = _.findIndex(this.myTeamList, { ID: id });
                            this.myTeamList.splice(index, 1);
                            this.modalService.showAlert(
                                'Success',
                                result.message
                            );
                        },
                        (error: any) => {
                            this.modalService.showAlert('Error', error.message);
                            this.log.error(
                                'Error sending invite. ' + error.message
                            );
                        }
                    );
                }
            });
    }
}
