
<div class="legend">
    <div class="item appointment-bullet" translate>
        <i class="bullet"></i>calendar.legend.appointment
    </div>

    <div class="item moodcheck-bullet" translate>
        <i class="bullet"></i>calendar.legend.moodcheck
    </div>

    <div class="item assessment-bullet" translate>
        <i class="bullet"></i>calendar.legend.assessment
    </div>

    <div class="item course-bullet" translate>
    <i class="bullet"></i>calendar.legend.course
    </div>

    <!-- <div class="item depression-bullet" translate>
        <i class="bullet"></i>calendar.legend.depression
    </div>

    <div class="item anxiety-bullet" translate>
        <i class="bullet"></i>calendar.legend.anxiety
    </div>

    <div class="item resilience-bullet" translate>
        <i class="bullet"></i>calendar.legend.resilience
    </div>

    <div class="item sleep-bullet" translate>
        <i class="bullet"></i>calendar.legend.sleep
    </div>

    <div class="item work-bullet" translate>
        <i class="bullet"></i>calendar.legend.work
    </div>

    <div class="item mindfulness-bullet" translate>
        <i class="bullet"></i>calendar.legend.mindfulness
    </div>

    <div class="item purpose-bullet" translate>
        <i class="bullet"></i>calendar.legend.purpose
    </div> -->

    <div class="item thoughtdiary-bullet" translate>
        <i class="bullet"></i>calendar.legend.thoughtdiary
    </div>

    <div class="item funachievement-bullet" translate>
        <i class="bullet"></i>calendar.legend.funachievement
    </div>

    <div class="item activityscheduler-bullet" translate>
        <i class="bullet"></i>calendar.legend.activityscheduler
    </div>

    <div class="item zenroom-bullet" translate>
        <i class="bullet"></i>calendar.legend.zenroom
    </div>

</div>