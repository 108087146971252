import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';

@Component({
  selector: 'app-nps-survey',
  templateUrl: './nps-survey.component.html',
  styleUrls: ['./nps-survey.component.scss']
})
export class NpsSurveyComponent implements OnInit {
  npsSurveyForm: FormGroup
  isloaded: boolean = true;
  surveyData: any;
  npsSUrveyFinalData: any = [];
  isSubmitted:boolean = false;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private modalService: ModalService,
    private log: LogService,
  ) {
    
    this.getAllSurveyQuestions();
  }

  ngOnInit(): void {
    this.createForm();
   
  }


  // Create Form
  createForm(){
    this.npsSurveyForm = this.fb.group({
      mainQuestion: this.fb.array([]),
    });
  }


  // Get All Survey Questions
  getAllSurveyQuestions(){
    this.surveyData = [];
    this.isloaded = true;
    this.api.get('form/12').subscribe(response => {
      //this.surveyData = response['data'];
      let objs = response['data'];
      for (let obj of objs) {
        this.surveyData.push(obj[0]);
      }
      this.isloaded = false;
      this.createQuestions();
    },error =>{
      this.log.error("Error loading. " + error.message);
      this.isloaded = false;
      
    });

    

  }

  // Create Questions
  createQuestions() {
    if (this.surveyData.length > 0) {
      // Populate Main Question
      this.surveyData.forEach((element, index) => {
        const ctrl = <FormArray>(this.npsSurveyForm.get('mainQuestion'));
        ctrl.push(this.initializeMainQuestion());
        ctrl['controls'][index].patchValue({
          id: element.ID,
          comment: element.Comment,
          options: element.options
        });

        // Populate sub Questions
        const subQuestion = element.sub;
        if (subQuestion.length > 0) {
          subQuestion.forEach((subQn, subQnIndex) => {
            const subQuestionCtrl = <FormArray>(
              this.npsSurveyForm
                .get('mainQuestion')
              ['controls'][index].get('subQuestion')
            );
            subQuestionCtrl.push(this.initializeSubQuestion());
            subQuestionCtrl['controls'][subQnIndex].patchValue({
              id: subQn.ID,
              comment: subQn.Comment,
              range: subQn.range,
              answer: '',
              show: false,
              options: subQn.options
            });
          });
          this.isloaded = false;
        }
      });




    }
  }

  // Initialise Questions
  initializeSubQuestion() {
    return this.fb.group({
      id: ['', []],
      comment: ['', []],
      range: ['', []],
      answer: ['', []],
      show: [false, []],
      options: ['', []]

    });
  }


  // Initialise SUb Questions
  initializeMainQuestion() {
    return this.fb.group({
      comment: ['', []],
      id: ['', []],
      options: ['', []],
      subQuestion: this.fb.array([]),
      value: ['', [Validators.required]],

    });
  }


  /**
   * Range Selector Selection output event
   */
  scaleSelectionCompleted(data) {

    this.npsSurveyForm.get('mainQuestion')['controls'][data.index].patchValue({
      value : data.options.OptionValue
    });
    this.surveyData[data.index].sub.forEach((subQn, subIndex) => {
      // Make all question hide on number selection
      const subQuestionCtrl = <FormArray>(
        this.npsSurveyForm
          .get('mainQuestion')
        ['controls'][data.index].get('subQuestion')
      );
      
      subQuestionCtrl['controls'][subIndex].patchValue({
        show: false
      });

      // check the selecetd value exist in the range
      if (subQn.range.includes(data.options.OptionValue)) {
        subQuestionCtrl['controls'][subIndex].patchValue({
          show: true
        });
      }
    })

    this.npsSUrveyFinalData[data.index] = {
      "value": data.options.OptionValue,
      "name": data.options.ID,
      "id": data.options.QuestionID,
    };
    this.log.debug('Final Data',JSON.stringify(this.npsSUrveyFinalData));


  }



  /**
   * Survey save
   */
  doSave() {
    this.isSubmitted = true;
    if(this.npsSurveyForm.valid){
      this.isSubmitted = false;
      this.npsSUrveyFinalData.splice(this.npsSurveyForm.value.mainQuestion.length, this.npsSUrveyFinalData.length);
      // format APT data
      this.npsSurveyForm.value.mainQuestion.forEach((mainQn, mainQnIndex) => {
        const selectedQn = mainQn.subQuestion.find(subQn => subQn.show === true);
        this.log.debug('Selected Question',selectedQn)
        this.npsSUrveyFinalData.push({
          "value": selectedQn?.answer,
          "name": selectedQn?.options[0].ID,
          "id": selectedQn?.options[0].QuestionID,
        })
      });
  
      this.npsSUrveyFinalData = {
        Type: 'nps_survey',
        Data: JSON.stringify(this.npsSUrveyFinalData)
      }
      this.api.post('form/nps_survey', this.npsSUrveyFinalData).subscribe(
        (result: any) => {
          this.modalService.showAlert('Success', 'Organization has been updated');
          this.npsSUrveyFinalData = [];
          this.npsSurveyForm.reset();
          this.createForm();
          this.getAllSurveyQuestions();
        },
        (error: any) => {
          this.isloaded = false;
          this.modalService.showAlert('Error', error.message);
        },
        () => {
          this.isloaded = false;
        });
    }
    
  }

}
