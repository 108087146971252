<div class="app-works-main-container" *ngIf ="showEmptyHeader">
	<div class="app-works-header">
		<img src="../../../assets/img/welltrack-logos/well-track-white-logo.svg" alt="welltrack-logo" />
		<div class="app-works-header-bg">
			<svg-icon icon="app-works-header-bg"></svg-icon>
		</div>
	</div>
</div>

<div class="app-l-page-wrap">
    <div id="page-assessment" class="page-assessment-section">
        <div class="app-l-assessment__instruction">
            <div class="app-l-assessment__i-body">
                <div class="app-l-assessment__content">
                    <h3 class="app-c-heading--h3" translate> consent.consentTitle </h3>
                    <div class="app-l-assessment__content-block">
                    <ng-container>
                        <p translate> consent.consentDesc </p>
                    </ng-container>
                    </div>
                </div>
            </div>
            <div class="app-l-assessment__i-footer">
                <button class="app-c-btn app-c-btn--secondary app-c-btn--curve" (click)="consentSave(0) ">
                    <!-- <svg-icon icon="arrow-left"></svg-icon> -->
                    <span translate>consent.declined</span>
                </button>
                <button autofocus aria-live="polite" tabindex="0" (click)="consentSave(1)" class="app-c-btn app-c-btn--primary app-c-btn--curve"
                    translate>consent.consent</button>
            </div>


        </div>
    </div>
</div>