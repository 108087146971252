<div class="row text-center" id="nav-buttons">
    <div class="col-md-4 col-xs-12">
        <div tabindex="0" id="previous" class="btn btn-inline" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" (viewDateChange)="activeDayIsOpen = false" translate>
            calendar.previous
        </div>
    </div>
    <div class="col-md-4 col-xs-12">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4 col-xs-12">
        <div tabindex="0" id="next" class="btn btn-inline" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="activeDayIsOpen = false" translate>
            calendar.next
        </div>
    </div>
</div>

<mwl-calendar-month-view [viewDate]="viewDate" [events]="events" (dayClicked)="dayClicked($event)">
</mwl-calendar-month-view>

<legend-component></legend-component>