<div id="scheduler-modal">
    <div class="modal-header">
        <h3 translate>as.title</h3>
    </div>

    <div class="wt-tab-body">

        
        <div class="type-select" *ngIf="currentStep===0">
            <h3 translate>as.selectType</h3>
            <div tabindex="1" class="btn btn-grey btn-wide btn-inline" [ngClass]="{'active': isType(1)}" (click)="selectType(1);" (keypress)="selectType(1);">
                <span translate>as.pleasurable</span>
            </div>
            <div tabindex="2" class="btn btn-grey btn-wide btn-inline" [ngClass]="{'active': isType(2)}" (click)="selectType(2);" (keypress)="selectType(2);">
                <span translate>as.social</span>
            </div>

            <div tabindex="3" class="btn btn-grey btn-wide btn-inline" [ngClass]="{'active': isType(3)}" (click)="selectType(3);" (keypress)="selectType(3);">
                <span translate>as.achievement</span>
            </div>
        </div>
        <div id="activity-select" *ngIf="currentStep === 1">
            <activity-select tabindex="0" [activityType]="activityType" (activity)="activityChange($event)"></activity-select>
        </div>
        <div class="btn-box" *ngIf="currentStep===2">
            <h3 translate>as.start</h3>

        </div>
        <div class="btn-box" *ngIf="currentStep===3">
            <h3 translate>as.end</h3>
            Time
   
        </div>
    </div>

    <div class="modal-footer">
        <div class="buttons">
            <a *ngIf="currentStep  > 0" tabindex="4" class="btn btn-bold" (click)="back()" (keypress)="back()" translate>as.back</a>
            <a tabindex="5" class="btn btn-bold" (click)="next()" (keypress)="next()" *ngIf="currentStep !== 3" translate>moodcheck.next</a>
            <a tabindex="6" class="btn btn-primary" (click)="onSubmit()" (keypress)="onSubmit()" *ngIf="currentStep === 3" translate>moodcheck.save</a>
        </div>
    </div>

</div>