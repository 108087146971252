import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticEvent } from 'app/lib/analytic-event';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import { StorageService } from 'app/lib/storage.service';
import { UserService } from 'app/lib/user.service';
import { User } from 'app/models/user';
import { NgxObjectDiffModule, NgxObjectDiffService } from 'ngx-object-diff';

@Component({
	selector: 'app-organization-associate',
	templateUrl: './organization-associate.component.html',
	styleUrls: ['./organization-associate.component.scss']
})
export class OrganizationAssociateComponent implements OnInit {
	email: any;
	showOrgAsso: any;
	activeOrg: boolean;
	user: User;
	accessCode: string;
	utilityService: any;
	emailAddress: string;
	popups: any;
	popupsEmail: any;

	constructor(
		public dialogRef: MatDialogRef<OrganizationAssociateComponent>,
		private api: ApiService,
		private log: LogService,
		private modalService: ModalService,
		private userService: UserService,
		private translate: TranslateService,
		public storage: StorageService,
		private objectDiff: NgxObjectDiffService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.api.get('users/me').subscribe(
			(result: any) => {
				this.user = new User(result.data);
				this.userService.setUser(this.user);
			})

	}

	ngOnInit(): void {
		this.email = this.data.data.email;
		this.activeOrg = this.user?.primaryOrganization !== null ? this.user?.primaryOrganization.active : false;
		this.showOrgAsso = this.data.data.orgAssociation;
		this.translate.stream("start.popups").subscribe((res: any) => {
			this.popupsEmail = res;
		});
		this.translate.stream("setting.popups").subscribe((res: any) => {
			this.popups = res;
		});
		this.log.event(AnalyticEvent.event.userAssociationPage);
	}

	onPair() {
		//validate
		if (!this.accessCode && !this.emailAddress) {
			this.modalService.showAlert(this.popups[8].title, this.popups[8].body);
			return;
		}

		if (this.accessCode && this.emailAddress) {
			this.modalService.showAlert(this.popups[9].title, this.popups[9].body);
			return;
		}

		// if (this.utilityService.demoMode()) {
		// 	return;
		// }

		if (this.accessCode && !this.emailAddress) {
			//make call to server
			this.api.post('users/updateorganization', {
				Code: this.accessCode
			}).subscribe(
				(result: any) => {
					this.api.get('users/me').subscribe(
						(result: any) => {
							this.user = new User(result.data);
							this.userService.setUser(this.user);
						});
					const event = 'accesscode';
					this.storage.setFlag('entered-access-code');
					this.dialogRef.close({result,event});
					this.log.event(AnalyticEvent.event.accessCodeComplete);
				},
				(error: any) => {

					this.log.error('Error submitting access code. ' + error.message);
					this.modalService.showAlert(this.popups[7].title, error.message);
				}
			);
		}

		if (!this.accessCode && this.emailAddress) {
			//make call to server
			this.api.post('sendaccesscode', {
				Email: this.emailAddress
			}).subscribe(
				(result: any) => {
					const event = 'email';
					this.modalService.showAlert(this.popupsEmail.success, this.popupsEmail.inbox);
					this.log.event(AnalyticEvent.event.accessCodeEmailed);
					this.dialogRef.close({result,event});
				},
				(error: any) => {
					this.modalService.showAlert(this.popupsEmail.error, error.message);
					this.log.error('Error emailing access code. ' + error.message);
				}
			);
		}
	}


}
