import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../lib/auth.service';
import { ApiService } from '../../../lib/api.service';
import { StorageService } from '../../../lib/storage.service';
import { LogService } from '../../../lib/log.service';
import { ModalService } from '../../../lib/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../../../environments/all';
import { ApiError } from '../../../lib/api-error';
import { UtilityService } from '../../../lib/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginAPIService } from "../lib/login-api.services";
import { Organization } from "../../../models/organization";

//import * as flat from 'flat';

@Component({
	selector: 'page-sso-initiate',
	templateUrl: 'sso-initiate.html',
	styleUrls: ['./sso-initiate.scss']
})

export class SsoInitiate implements OnInit {
	authType: string;
	assertion: string;
	popup: any;
	message: any;
	organization: Organization;

	constructor(
		private api: ApiService,
		private storage: StorageService,
		private log: LogService,
		private modalService: ModalService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public auth: AuthService,
		private translate: TranslateService,
		private loginApi: LoginAPIService
	) {

	}

	ngOnInit() {
		this.translate.stream('loginMobile').subscribe((res: any) => {
			this.popup = res.popups;
		});

		this.activatedRoute.params.subscribe(params => {
			const segment = params['segment'];
			if (!segment) {
				this.modalService.showAlert('Error', 'The url is invalid. Please contact IT.');
			}
			else {
				this.loginApi.getOrganizations(segment).subscribe(
					(results: any) => {
						this.organization = new Organization(results.data);
						if(this.organization.sso){
							window.location.href = this.organization.sso;
						}
						else{
							this.modalService.showAlert('Error', 'The url is invalid. Please contact IT.');
						}
						
					}
				);
			}

		});
	}
}
