import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseFeedbackComponent } from 'app/components/course-feedback/course-feedback.component';
import { CourseVideoCardComponent } from 'app/components/course-video-card/course-video-card.component';
import { ApiService } from 'app/lib/api.service';
import { LogService } from 'app/lib/log.service';
import { ModalService } from 'app/lib/modal.service';
import * as _ from 'lodash';

import { Course, Video } from '../../../app/models/course';

@Component({
    selector: 'app-course-video',
    templateUrl: './course-video.component.html',
    styleUrls: ['./course-video.component.scss'],
})
export class CourseVideoComponent implements OnInit {
    @ViewChild('videoCard') videoCard: CourseVideoCardComponent;
    courseVideos: any = [];
    courseKey: any;
    id: any;
    title: string;
    videoData: any;
    isLoaded: boolean = false;
    backLink: string;
    backText: string;
    cssModule: string;
    courseData: Course[];
    autoplay: boolean = false;
    selectedVideo: any;
    progress: any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private api: ApiService,
        private log: LogService,
        private modalService: ModalService,
        private router: Router,
        private location: Location
    ) {
        this.backText = 'Back to List';
        this.cssModule = 'anxiety';
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.courseKey = params['series'];
            this.backLink = '/app/series-details/' + this.courseKey;
            this.id = params['videoId'];
            this.autoplay = JSON.parse(params['autoplay']) ? true : false;
        });
        this.getCourseVideos();
    }
    getCourseVideos() {
        this.api.get('serieslisting/' + this.courseKey).subscribe(
            (result: any) => {
                this.isLoaded = true;
                this.courseData = Course.initializeArray(result.data);
                this.courseData.forEach(course => {
                    if (course.videos.length) {
                        this.courseVideos = [
                            ...this.courseVideos,
                            ...course.videos,
                        ];
                    }
                });
                this.getVideoData(this.id);
            },
            (error: any) => {
                // this.loader.close();
                this.log.error('Error getting course data. ' + error.message);
            },
            () => {
                // this.loader.close();
            }
        );
    }
    getVideoData(id: any) {
        this.videoData = this.courseVideos.find(x => x.id === JSON.parse(id));
        this.title = this.videoData?.label || '';
    }
    onRate(video) {
        this.modalService.setCloseonClick(false);
        this.modalService.showComponent(CourseFeedbackComponent, {
            video: video,
        });
    }
    onClick(attachment) {
        if (attachment.fileFullPath) {
            window.open(attachment.fileFullPath, '_blank');
            this.api
                .post('analytics/seriesattachmentclick', {
                    attachment: attachment,
                })
                .subscribe(
                    (result: any) => {
                        //do nothing
                    },
                    (error: any) => {
                        this.log.error('Error logging link click');
                    }
                );
        } else {
            this.modalService.showAlert(
                'Error',
                'Could not load this link. Please contact info@welltrack-boost.com'
            );
        }
    }
    changeCourseVideo(video: any) {
        this.router.navigate([
            'app/series-details/' + this.courseKey + '/' + video.id + '/' + 1,
        ]);
        this.selectedVideo = video;
        this.videoCard.changeCourseVideo(this.selectedVideo);
    }
    updateCourseVideoList(progressData) {
        let tempVideo = this.courseVideos.find(
            video => video.id == this.videoData.id
        );
        if (tempVideo) {
            let status =
                Object.keys(this.videoData.courseProgress).length > 0
                    ? this.videoData.courseProgress.Status
                    : 'inprogress';
            tempVideo.courseProgress = {
                TimeCompleted:
                    progressData.left == 0 ? 0 : progressData.current,
                Status: status,
            };
            tempVideo.videoRating = this.videoData.videoRating;
            this.videoData = JSON.parse(JSON.stringify(this.selectedVideo));
        }
    }
    getCompleted() {
        let completed = 0;
        this.courseVideos.forEach(x => {
            if (x.courseProgress.Status === 'completed') {
                completed++;
            }
        });
        this.progress = (completed / this.courseVideos.length) * 100;
        return completed;
    }
    goBack() {
        this.location.back();
    }
    toCourse() {
        this.router.navigate([this.backLink]);
    }
    toCourseList() {
        this.router.navigate(['app/series']);
    }
}
