import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from '../../lib/api.service';
import { StorageService } from '../../lib/storage.service';
import { LogService } from '../../lib/log.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ModalService } from '../../lib/modal.service';
import { AssessmentAlert } from '../../components/assessment-alert/assessment-alert';
import { AssessmentResilienceAlert } from '../../components/assessment-resilience-alert/assessment-resilience-alert';
import { AssessmentCoreContributorAlert } from '../../components/assessment-core-contributor-alert/assessment-core-contributor-alert';
import { User } from '../../../app/models/user';
import { Organization } from '../../../app/models/organization';
import { Assessment } from '../../../app/models/assessment';
import { UserService } from '../../../app/lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as jQuery from 'jquery';

interface Answer {
    answerType: string;
    value: number;
    isAnswered: boolean;
    id: string;
    // add any other properties that the object has
}
@Component({
    selector: 'page-assessment',
    templateUrl: 'das-assessment.html',
    styleUrls: ['./das-assessment.scss'],
})
export class DasAssessmentPage implements OnInit {
    isLoaded = false;
    questions: Array<Object> = [];
    answers: Array<Object> = [];
    active = 0;
    count = 0;
    alerts: string;
    user: User;
    popup: any;
    quizType: string = 'dass';
    showExplain: boolean = false;
    showScore: boolean = false;
    showIntro: boolean = true;
    assessment: Assessment;
    organization: Organization;
    forced: boolean;
    submitting: boolean;
    title: string;
    back: string;
    backLink: string;
    cssClass: string;
    headerImage: string;
    resiliencyLevel: string;
    resiliencyDescription: string;
    alert: boolean;
    buffer = 0;
    progress = 0;
    progressValue: any;
    optionType: any;
    showAnimation: boolean = false;
    typeAssessment: string;
    assessmenttranslation: any;
    returnPath: any;
    showEmptyHeader: boolean = false;
    constructor(
        private api: ApiService,
        private modalService: ModalService,
        private log: LogService,
        private storage: StorageService,
        private router: Router,
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.typeAssessment = this.route.snapshot.paramMap.get('assessment');
        this.user = this.userService.getUser();

        this.submitting = false;
    }

    ngOnInit() {
        this.translate.stream('error').subscribe((res: any) => {
            this.popup = res.title;
        });

        this.activatedRoute.params.subscribe((params: Params) => {
            if (params['assessment']) {
                this.quizType = params['assessment'];
            } else if (
                this.user.primaryOrganization &&
                this.user.primaryOrganization.settings
            ) {
                this.quizType = this.user.primaryOrganization.settings[
                    'assessment'
                ]
                    ? this.user.primaryOrganization.settings['assessment']
                    : 'dass';
            } else {
                this.quizType = 'dass';
            }

            if (this.quizType === 'dass') {
                this.headerImage =
                    './assets/img/icons-svg/assessments/assessment_type_' +
                    this.quizType +
                    '.svg';
            } else if (this.quizType === 'resilience') {
                this.headerImage =
                    './assets/img/icons-svg/assessments/assessment_type_' +
                    this.quizType +
                    '.svg';
            } else if (this.quizType === 'alcoholuse') {
                this.headerImage =
                    './assets/img/icons-svg/assessments/assessment_type_' +
                    this.quizType +
                    '.svg';
            } else if (this.quizType === 'depression') {
                this.headerImage =
                    './assets/img/icons-svg/assessments/assessment_type_' +
                    this.quizType +
                    '.svg';
            } else if (this.quizType === 'anxiety') {
                this.headerImage =
                    './assets/img/icons-svg/assessments/assessment_type_' +
                    this.quizType +
                    '.svg';
            } else {
                this.headerImage = './assets/img/assessment_icon@2x.png';
            }
            this.cssClass = 'assessment-header';

            if (
                ['resilience', 'alcoholuse', 'depression', 'anxiety'].includes(
                    this.quizType
                )
            ) {
                this.translate
                    .stream('assessments.' + this.quizType)
                    .subscribe((res: any) => {
                        this.assessmenttranslation = res;
                        this.title = res.title;
                        this.back = res.back;
                    });
            }
            if (this.quizType === 'dass') {
                this.translate.stream(this.quizType).subscribe((res: any) => {
                    this.title = res.title;
                    this.back = res.back;
                });
                this.activatedRoute.queryParams.subscribe(queryParams => {
                    this.forced = queryParams['forced'];
                    this.returnPath = queryParams['path'];
                });
                if (this.returnPath === 'onboarding') {
                    this.showEmptyHeader = true;
                }
            }
            this.backLink = '/app/assessment/' + this.quizType + '/listing';
        });

        this.questions = [];
        this.answers = [];
        const apiEndpoint =
            this.quizType === 'dass'
                ? 'assessmentquestions'
                : 'newassessmentquestions';
        const type = this.quizType === 'dass' ? 'das' : this.quizType;
        this.api.get(apiEndpoint + '/' + type).subscribe(
            (result: any) => {
                const objs = result.data;
                for (const obj of objs) {
                    obj[0].questionType = 'assessmentQuestion';
                    this.questions.push(obj[0]);
                    this.answers.push({
                        answerType: 'assessmentAnswer',
                        isAnswered: false,
                        value: -1,
                        values: {},
                        name: -1,
                        id: obj[0].ID,
                        type: obj[0].Type,
                    });
                    this.count++;
                }

                if (
                    this.user.primaryOrganization &&
                    this.user.primaryOrganization.id &&
                    this.quizType === 'dass'
                ) {
                    this.api
                        .get(
                            'organizations/' + this.user.primaryOrganization.id
                        )
                        .subscribe(
                            (result: any) => {
                                this.organization = new Organization(
                                    result.data,
                                    'view'
                                );
                                // if (this.organization.settings.assessment !== 'resilience') {
                                this.orgQuestions();
                                // }
                            },
                            (error: any) => {
                                this.progressValue = 100 / this.count;
                                this.progress = this.progressValue;
                                this.buffer = this.progressValue;
                                this.log.error(
                                    'Error getting organizations. ' +
                                        error.message
                                );
                            }
                        );
                }

                this.isLoaded = true;
                this.progressValue = 100 / this.count;
                this.progress = this.progressValue;
            },
            (error: any) => {
                this.log.error(
                    'Error getting assessment questions. ' + error.message
                );
            }
        );

        // this.focusOnFirst();
    }

    orgQuestions() {
        // for each on question set.  loop and add answers
        let lang = this.getLangForResourceList();
        if (
            this.organization.resourceSet.length &&
            this.organization.enableResources
        ) {
            let resourcesQuestions = [];
            this.organization.resourceSet.forEach(qs => {
                if (qs.language == lang) {
                    resourcesQuestions.push({
                        Type: 3,
                        Comment: qs.title,
                        Group: 'Organization',
                        ID: qs.id,
                        options: [
                            {
                                OptionValue: 0,
                                Comment: 'No',
                            },
                            {
                                OptionValue: 1,
                                Comment: 'Yes',
                            },
                        ],
                    });

                    this.answers.push({
                        answerType: 'resourceAnswer',
                        isAnswered: true,
                        value: 0,
                        values: {},
                        name: -1,
                        id: qs.id,
                        type: 3,
                    });
                }
            });
            if (resourcesQuestions.length) {
                resourcesQuestions.push(this.addNoneResourceQuestion());
                this.questions.push({
                    questionType: 'resourceQuestion',
                    resourceQuestions: resourcesQuestions,
                });
                this.count++;
            }
        }
        this.progressValue = 100 / this.count;
        this.progress = this.progressValue;
    }

    addNoneResourceQuestion() {
        this.answers.push({
            answerType: '',
            isAnswered: false,
            value: 0,
            values: {},
            name: -1,
            id: 'none',
            type: 3,
        });
        return {
            Type: 3,
            Comment: 'None',
            Group: 'Organization',
            ID: 'none',
            options: [
                {
                    OptionValue: 0,
                    Comment: 'No',
                },
                {
                    OptionValue: 1,
                    Comment: 'Yes',
                },
            ],
        };
    }

    onSubmit() {
        if (this.answers[this.active]['value'] === -1) {
            return;
        }
        let answerData = [...this.answers];
        answerData = answerData.filter(
            (obj: Answer) => 'id' in obj && obj.id !== 'none'
        );
        this.submitting = true;
        this.showAnimation = true;
        if (this.quizType === 'dass') {
            const type = this.quizType === 'dass' ? 'das' : this.quizType;
            this.api
                .post('assessment', {
                    Assessment: this.assessment,
                    Type: type,
                    Data: JSON.stringify(answerData),
                    OrgQuestions: {},
                })
                .subscribe(
                    (results: any) => {
                        this.api.get('users/me').subscribe(
                            (result: any) => {
                                this.showAnimation = false;
                                this.user = new User(result.data);
                                this.userService.setUser(this.user);
                                this.assessment = new Assessment(results.data);
                                if (this.returnPath === 'onboarding') {
                                    this.user.onboardingStep = 3;
                                    this.user.forceAssessment = false;
                                    this.userService.setUser(this.user);
                                    this.router.navigate(
                                        [
                                            '/onboarding-assessment/result',
                                            this.assessment.id,
                                            this.quizType,
                                        ],
                                        {
                                            queryParams: {
                                                path: this.returnPath,
                                            },
                                        }
                                    );
                                } else {
                                    this.router.navigate([
                                        '/app/assessment/result',
                                        this.assessment.id,
                                        this.quizType,
                                    ]);
                                }
                                this.submitting = false;
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error fetching user profile: ' +
                                        error.message
                                );
                            }
                        );
                    },
                    (error: any) => {
                        this.modalService.showAlert(this.popup, error.message);
                        this.log.error('Error registering. ' + error.message);
                        this.submitting = false;
                    }
                );
        } else {
            this.api
                .post('assessment/save', {
                    Assessment: this.assessment,
                    Type: this.quizType,
                    Data: JSON.stringify(answerData),
                    OrgQuestions: {},
                })
                .subscribe(
                    (results: any) => {
                        this.api.get('users/me').subscribe(
                            (result: any) => {
                                this.showAnimation = false;
                                this.user = new User(result.data);
                                this.userService.setUser(this.user);
                                this.assessment = new Assessment(results.data);
                                // if (this.quizType === 'resilience') {
                                // 	this.modalService.showComponent(AssessmentCoreContributorAlert, this.assessment, null, true);
                                // } else {
                                this.router.navigate([
                                    '/app/assessment/result',
                                    this.assessment.id,
                                    this.assessment.type,
                                ]);
                                // this.modalService.showComponent(AssessmentAlert, this.assessment, null, true);
                                // }
                                this.submitting = false;
                            },
                            (error: any) => {
                                this.log.error(
                                    'Error fetching user profile: ' +
                                        error.message
                                );
                            }
                        );
                    },
                    (error: any) => {
                        this.modalService.showAlert(this.popup, error.message);
                        this.log.error('Error registering. ' + error.message);
                        this.submitting = false;
                    }
                );
        }
    }

    scrollToTop() {
        let element = document.getElementById('page-assessment');
        element.scrollIntoView();
    }

    countAssoc() {
        return Object.keys(this.answers[this.active]['values']).length;
    }

    setNextActive() {
        if (
            this.questions[this.active]['Type'] === 2 ||
            this.answers[this.active]['value'] !== -1
        ) {
            this.active = this.active + 1;
            this.scrollToTop();
        }

        if (this.active === 1) {
            this.showIntro = false;
        }

        if (this.active === 6 && this.quizType === 'resilience') {
            this.resilienceCalculate();
            this.showScore = true;
        }

        if (this.buffer < this.progress) {
            this.buffer += this.progressValue;
        }

        this.progress += this.progressValue;
        if (this.answers[this.active]['isAnswered']) {
            this.optionType = this.answers[this.active]['name'];
        }
    }

    setExplain(show) {
        this.showScore = false;
        this.showExplain = show;
    }

    setIntro(intro) {
        this.showIntro = intro;
        // this.focusOnFirst();
    }

    setPreviousActive() {
        if (this.active === 0) {
            return;
        } else {
            this.active = this.active - 1;
            if (this.active > 0) {
                this.showScore = this.showIntro = this.showExplain = false;
            }
            this.scrollToTop();
            this.progress -= this.progressValue;
            if (this.answers[this.active]['isAnswered']) {
                this.optionType = this.answers[this.active]['name'];
            }
        }
    }
    /*
		question position, answer position,  type of question (1,2), question ID
		type 1: single answer
		type 2: multiple answers
		type 3: yes/no
	*/
    onSelectionChange(i, value, type, optionID, questionType?) {
        this.optionType = optionID;
        setTimeout(() => {
            if (type === 2) {
                if (this.answers[i]['values'][optionID] === value) {
                    delete this.answers[i]['values'][optionID];
                } else {
                    this.answers[i]['values'][optionID] = value;
                    this.answers[i]['isAnswered'] = true;
                }
            } else if (type === 3) {
                if (questionType == 'resourceQusetion') {
                    this.answers[i]['value'] =
                        this.answers[i]['value'] == 0 ? 1 : 0;
                } else {
                    this.answers[i]['value'] = value;
                }
                if (this.answers[i]['id'] == 'none') {
                    if (this.answers[i]['value'] == 1) {
                        this.answers.forEach((element: any) => {
                            element.answerType == 'resourceAnswer'
                                ? (element.value = 0)
                                : '';
                        });
                        this.answers[i]['isAnswered'] = true;
                    } else {
                        this.answers[i]['isAnswered'] = false;
                    }
                } else {
                    if (this.answers[i]['answerType'] == 'resourceAnswer') {
                        let hasItem = this.answers.some(
                            (element: Answer) =>
                                element.answerType == 'resourceAnswer' &&
                                element.value == 1
                        );
                        hasItem
                            ? (this.answers[this.answers.length - 1]['value'] =
                                  0)
                            : '';
                        this.answers[this.answers.length - 1]['isAnswered'] =
                            hasItem ? true : false;
                    } else {
                        this.answers[i]['isAnswered'] = true;
                    }
                }
                if (value === 0 && i < this.count - 1) {
                    this.setNextActive();
                }
            } else {
                this.answers[i]['value'] = value;
                this.answers[i]['name'] = optionID;
                this.answers[i]['isAnswered'] = true;

                if (i < this.count - 1) {
                    this.setNextActive();
                } else if (this.buffer < 100) {
                    this.buffer += this.progressValue;
                }
            }
            // if(this.buffer < this.progressValue){
            // 	this.buffer += this.progressValue;
            // }

            // this.focusOnFirst();
        }, 100);
    }

    checkAllAnswered() {
        let hasItem = this.answers.some(
            (item: Answer) => item.isAnswered == false
        );
        return hasItem ? false : true;
    }

    onCancel() {
        this.router.navigateByUrl('/app/assessment');
    }

    onResourceClick(contact) {
        window.open(contact.website, '_blank');
        this.api
            .post('assessment/resourceclick', {
                resourceGroup: contact,
            })
            .subscribe(
                (result: any) => {},
                (error: any) => {
                    this.log.error('Error logging link click');
                }
            );
    }

    onResourceInternalClick(contact) {
        this.api
            .post('assessment/resourceclick', {
                resourceGroup: contact,
            })
            .subscribe(
                (result: any) => {
                    window.open(contact.internal, '_blank');
                },
                (error: any) => {
                    this.log.error('Error logging link click');
                }
            );
    }

    isDisable(active) {
        if (active === 0) {
            return true;
        } else {
            return false;
        }
    }

    resilienceCalculate() {
        this.translate.get('resilience').subscribe((res: any) => {
            let score = 0;
            const totalQuestions = 6;
            let i = 0;
            for (let answer of this.answers) {
                if (i === totalQuestions) break;
                i++;
                score += answer['value'];
            }
            if (score / 6 <= 2.99) {
                this.resiliencyLevel = res.alert.titles.reactive;
                this.resiliencyDescription = res.alert.descriptions.reactive;
                this.alert = true;
            } else if (score / 6 <= 4.3) {
                this.resiliencyLevel = res.alert.titles.inthemoment;
                this.resiliencyDescription = res.alert.descriptions.inthemoment;
                this.alert = false;
            } else if (score / 6 <= 5.0) {
                this.resiliencyLevel = res.alert.titles.proactive;
                this.resiliencyDescription = res.alert.descriptions.proactive;
                this.alert = false;
            } else {
                this.resiliencyLevel = res.alert.titles.reactive;
                this.resiliencyDescription = res.alert.descriptions.reactive;
                this.alert = true;
            }
        });
    }

    goBack() {
        this.location.back();
    }

    getLangForResourceList() {
        let selectedLang = this.storage.get('lang');
        if (
            (selectedLang === 'fr' || selectedLang === 'es') &&
            !this.hasResourceByLangage(selectedLang)
        ) {
            selectedLang = 'en';
        }
        return selectedLang;
    }

    hasResourceByLangage(lang: string): boolean {
        return this.organization.resourceSet.some(r => r['language'] === lang);
    }
}
