<div class="app-l-page-wrap">
	<div class="app-l-page__navigation">
		<div class="app-l-page__previous">
			<button *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) "
				tabindex="0"  [routerLink]="['/app/practice']"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
			<button *ngIf = "user.userType != 'user' && !showCreateBtn"
				tabindex="0"  [routerLink]="['/professional/user-details-dashboard/' + id]"
				class="app-c-btn app-c-btn--icon app-c-btn--icon-secondary app-c-btn--icon-20"
			>
				<svg-icon icon="arrow-left" class="w-100 h-100"></svg-icon>
			</button>
		</div>
		<div class="app-l-page__breadcrumbs">
			<ul>
				<li translate *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) ">
					<a [routerLink]="['/app/practice']" translate>menu.tools</a>
				</li>
				<li translate *ngIf = "user.userType != 'user' && !showCreateBtn ">
					<a [routerLink]="['/professional/user-details-dashboard/' + id]" translate>menu.clientList</a>
				</li>
				<li class="app-l-current-page" translate>practice.rfp.title</li>
			</ul>
		</div>
	</div>
	<div id="page-room-for-positive" class="app-l-room__wrap">
		<div class="app-l-room__pos">
			<div class="app-l-room__actions">
				<div
					class="app-l-room__actions-block app-dm-form-field-wrapper"
					tabindex="0"
					(keypress)="openDatePicker()"
					(click)="openDatePicker()"
				>
					<mat-form-field appearance="outline">
						<mat-datepicker-toggle
							matIconSuffix
							[for]="dp"
							disableRipple="true"
							tabindex="-1"
						>
							<mat-icon matDatepickerToggleIcon>
								<svg-icon
									icon="calendar-digit"
									class="w-100 h-100"
								></svg-icon>
							</mat-icon>
						</mat-datepicker-toggle>
						<input
							matInput
							[matDatepicker]="dp"
							readonly
							[(ngModel)]="dateFilter"
							[max]="maxDate"
							tabindex="-1"
						/>
						<mat-datepicker
							#dp
							startView="multi-year"
							(monthSelected)="dateFilterChange($event, dp)"
							panelClass="example-month-picker"
						>
						</mat-datepicker>
					</mat-form-field>
				</div>
				<div class="app-l-room__actions-block" *ngIf = "user.userType === 'user'  || (user.userType != 'user' && showCreateBtn) ">
					<button
						(click)="createNewEntry()"
						(keypress)="createNewEntry()"
						class="app-c-btn app-c-btn--secondary app-c-btn--curve"
					>
						<svg-icon icon="plus"></svg-icon>
						<span translate>practice.rfp.addEntry</span>
					</button>
				</div>
			</div>
			<div class="app-l-room__tabs">
				<mat-tab-group
					mat-align-tabs="left"
					disableRipple="true"
					(selectedTabChange)="onTabChanged($event)"
				>
					<mat-tab
						label="{{
							'practice.rfp.shortTitleProud' | translate
						}}..."
					>
						<page-loader *ngIf="isLoading"></page-loader>
						<div
							class="app-dm-positive-cards-wrapper"
							*ngIf="!isLoading"
						>
							<div
								class="app-dm-positive-cards"
								*ngFor="let rfpEntry of rfpEntryList"
							>
								<div class="app-l-room__date">
									<svg-icon icon="calendar"></svg-icon>
									<span>{{
										rfpEntry.created_at
											| date : "dd MMM YYYY"
									}}</span>
								</div>

								<h3>{{ rfpEntry.proudTitle }}</h3>
								<p>{{ rfpEntry.proudDescription }}</p>

								<button
									(click)="viewRfpEntry(rfpEntry)"
									class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
								>
									<span translate
										>practice.rfp.viewDetails</span
									>
									<svg-icon icon="angle-right"></svg-icon>
								</button>
							</div>
						</div>
					</mat-tab>
					<mat-tab
						label="{{
							'practice.rfp.shortTitleGrateful' | translate
						}}..."
					>
						<page-loader *ngIf="isLoading"></page-loader>
						<div
							class="app-dm-positive-cards-wrapper"
							*ngIf="!isLoading"
						>
							<div
								class="app-dm-positive-cards"
								*ngFor="let rfpEntry of rfpEntryList"
							>
								<div class="app-l-room__date">
									<svg-icon icon="calendar"></svg-icon>
									<span>{{
										rfpEntry.created_at
											| date : "dd MMM YYYY"
									}}</span>
								</div>

								<h3>{{ rfpEntry.gratefulTitle }}</h3>
								<p>{{ rfpEntry.gratefulDescription }}</p>
								<button
									(click)="viewRfpEntry(rfpEntry)"
									class="app-c-btn app-c-btn--text-primary app-c-btn--text app-c-btn--text-with-icon"
								>
									<span translate
										>practice.rfp.viewDetails
									</span>
									<svg-icon icon="angle-right"></svg-icon>
								</button>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
			<ng-container *ngIf="!rfpEntryList.length && !isLoading">
				<div class="empty-card">
					<div
						class="app-dm-positive-cards"
						[translate]="
							!this.isLoading ? 'practice.rfp.noDataFound' : ''
						"
					></div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
